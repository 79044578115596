import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { AsyncMatSelectService } from './async-mat-select.service';
import { takeWhile } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

export class Options {
  tag: string;
  data: number;
}

export class OptionsResult {
  options: Options[];
  loadID: number;
  error: string;
}

@Component({
  selector: 'async-mat-select',
  templateUrl: './async-mat-select.component.html',
  styleUrls: ['./async-mat-select.component.scss'],
  providers: [AsyncMatSelectService],
  encapsulation: ViewEncapsulation.None,
})
export class AsyncMatSelectComponent implements OnInit {
  
  private alive = false;
  private optionsSubject: BehaviorSubject<OptionsResult> = new BehaviorSubject<OptionsResult>(null);
  options$: Observable<OptionsResult> = this.optionsSubject.asObservable();

  private _failureMessage = 'failed to get data';
  set failureMessage(msg: string) {
    this._failureMessage = msg;
  }
  get failureMessage() {
    return this._failureMessage;
  }

  private _hint = 'list of options';
  set hint(msg: string) {
    this._hint = msg;
  }
  get hint() {
    return this._hint;
  }

  private _placeholder = 'Choose one';
  set placeholder(msg: string) {
    this._placeholder = msg;
  }
  get placeholder() {
    return this._placeholder;
  }

  constructor(
    private optionService: AsyncMatSelectService
  ) { }

  @ViewChild('selectControl') selectControl;

  options: Options[] = [];
  selected = new FormControl(null, [Validators.required]);

  savedValue: number | null = null;
  isLoading = false;
  isOpen = false;
  loadID = 0;

  ngOnInit() {
    this.alive = true;
    this.optionService.options
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        res => {
          console.log('%c async mat select res: ', 'background: #fae552; color: #323232;', res);
          if (res && this.isLoading && this.loadID === res.loadID) {
            this.options = res.options === null ? [] : res.options;
            this.isLoading = false;
            if (this.options.length && this.savedValue !== null && this.options.some(option => option.data == this.savedValue)) {
              this.selected.setValue(this.savedValue);
            }
            if (res.error) {
              this.selected.setValue(this.savedValue);
              this.selected.setErrors({ 'serviceFail': res.error });
              this.selectControl.close();
            }
          }
        }
      );
  }

  getErrorMessage() {
    if (this.selected.hasError('serviceFail')) {
      return this.selected.getError('serviceFail');
    }
    else if (this.selected.hasError('required')) {
      return 'this field is required';
    }
  }

  openChanged(event) {
    this.isOpen = event;
    this.isLoading = event;
    if (event) {
      this.savedValue = this.selected.value;
      this.options = [];
      this.selected.reset();
      this.optionService.getOptions(++this.loadID, this.failureMessage);
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
