import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LotRoutes, BlockchainRoutes } from '@app/core-v2/api-routes-v2.enum';

@Injectable()
export class BlockchainService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getBlockchain(lot_id) {
    return this.http.get(this.BASE_URL + BlockchainRoutes.BASE_URL + BlockchainRoutes.LOT + '/' + lot_id);
  }

}