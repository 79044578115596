import { BextAuthenticationService } from '@app/core/services';
import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { checkIfEmailDomainIsDev, checkIfEmailDomainIsPvh, checkIfEmailDomainMatchesAppUser } from '@app/shared/utils/regex-utils';
import { Subscription } from 'rxjs';


@Component({

  selector: 'sa-navigation',
  styleUrls: ['./navigation.component.scss'],
  templateUrl: './navigation.component.html'
})
export class NavigationComponent {

  currentUser;
  isDevEnv = environment.release_package.local || environment.release_package.dev ? true : false;
  isHpcUser = false;
  isPvhUser = false;
  isTtiUser = false;
  showApiNavItem = false;
  showEcommerce = false;
  authServiceChangeEventCount = 0;
  appUserSubscription: Subscription;
  
  constructor(
    private authService: BextAuthenticationService
  ) {

  }

  ngOnInit() {
    // this.authService.authSubject$.subscribe(
    //   res => {
    //     this.authServiceChangeEventCount++;
    //     console.groupCollapsed('%c ----- auth token subject(change event: ' + this.authServiceChangeEventCount + ') ----- ', 'background: #ff00ff; color: #ffffff;')
    //     console.log(res);
    //     console.groupEnd();

    //     return;
    //    },
    //   error => {
    //     console.log('error from auth subject: ', error);
        
    //     return;
    //   },
    //   () => {
    //     // console.log('--- subscription complete ---');
        
    //   }
    // );
    this.appUserSubscription = this.authService.AppUserState$.subscribe(
      res => {
        if (res.email && res.email !== null) {
          this.isHpcUser = checkIfEmailDomainMatchesAppUser('heronpreston', res.email)
          this.isPvhUser = checkIfEmailDomainMatchesAppUser('pvh', res.email)
          this.isTtiUser = checkIfEmailDomainMatchesAppUser('tti', res.email)
        }
      }
    )
    
  }

}
