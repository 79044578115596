import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../../../models/field-config.interface';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bext-ngx-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class NgxInputComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }
}
