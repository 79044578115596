import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ReportRoutes } from '@app/core-v2/api-routes-v2.enum';


@Injectable()
export class ReportingService {

  BASE_URL = environment.baseApiUrl;

  private _retailAccordianSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  get retailAccordianSubject() {
    return this._retailAccordianSubject;
  }
  get getRetailReportAccordianNodeChange() {
    return this._retailAccordianSubject.asObservable();
  }

  constructor(private http: HttpClient) {}

  getReport(reportType = 'lotHistory', config): Observable<any> {
    // return this.http.get(`${this.BASE_URL}${ReportRoutes.BASE_URL_V2}/${reportType}/${config}`);
    return this.http.get(`${this.BASE_URL}${ReportRoutes.BASE_URL_V2}/${reportType}/${config}`);
  }

  updateRetailReportAccordianNode(node) {
    this.retailAccordianSubject.next(node);
  }

  
  

}