import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[focusable]'
})
export class FocusableDirective {

  constructor(
    private host: ElementRef,
    private renderer: Renderer2  
  ) { }

  ngAfterViewInit() {
    this.host.nativeElement.focus();
    
  }

}
