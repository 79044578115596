import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartadminFormsModule } from '../forms/smartadmin-forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmartadminWidgetsModule } from '../widgets/smartadmin-widgets.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DndModule } from 'ngx-drag-drop';
import { OrganizationService } from '@app/core/services/backend/organization.service';
import { BootstrapModule } from '../bootstrap.module';
import { OrganizationFormComponent } from './form/form.component';
import {MaterialModule} from "@app/shared/angular-material.module";

@NgModule({
  imports: [
    CommonModule,
    SmartadminFormsModule,
    FormsModule,
    ReactiveFormsModule,
      MaterialModule
  ],
  providers: [
    OrganizationService
  ],
  declarations: [OrganizationFormComponent],
  exports: [OrganizationFormComponent]
})
export class SharedOrganizationModule { }
