import { Observable } from 'rxjs';

export interface CapsulesWithZipCode {
  Zipcode: string;
  Weight: number;
  WeightUnit: string;
  NumberOfDeliveries: number;
  Name: string;
  Company: string;
  City: string;
  State: string;
  Group: string;
}

export interface CapsuleReports {
  Company: string;
  Group: string;
  Name: string;
  State: string;
  City?: null;
  Zipcode: string;
  YTD2019: number;
  YTD2018: number;
  YEAR2018: number;
  Jan2019: number;
  Feb2019: number;
  Mar2019: number;
  Apr2019: number;
  May2019: number;
}


export interface CapsuleRecords {
  Zipcode: string;
  Weight: number;
  Name: string;
  Company: string;
  DeliveryDate: string;
  TrackingNumber: string;
  City: string;
  State: string;
  Group: string;
}


export abstract class CapsuleReportData {
  abstract getCapsulesWithZipCodes(): Observable<CapsulesWithZipCode[]>;
  abstract getCapsuleReport(reportType: string, id: string): Observable<CapsuleReports[]>;
  abstract getCapsuleRecords(reportType: string, id: string): Observable<CapsuleRecords[]>;
}