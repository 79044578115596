import { VideosDialogComponent } from "@app/shared-v2/dialogs/videos-dialog/videos-dialog.component";
import { ImagesDialogComponent } from "@app/shared-v2/dialogs/images-dialog/images-dialog.component";
import { DocumentsDialogComponent } from "@app/shared-v2/dialogs/documents-dialog/documents-dialog.component";
import { SustainabilityDialogComponent } from "@app/shared-v2/dialogs/sustainability/sustainability.component";
import { CreateSupplyChainComponent } from "@app/shared-v2/util-components/create-supply-chain/create-supply-chain.component";
import { AddPersonComponent } from "@app/shared-v2/person/add-person/add-person.component";
import { ScanTransferQrLotComponent } from "@app/shared-v2/lots/scan-transfer-qr-lot/scan-transfer-qr-lot.component";
import { LocationDialogComponent } from "@app/shared-v2/dialogs/location-dialog/location-dialog.component";
import { ReportListDialogComponent } from "@app/shared-v2/dialogs/report-list-dialog/report-list-dialog.component";
import { CustomFormDialogComponent } from "@app/shared-v2/dialogs/custom-form-dialog/custom-form-dialog.component";
import { CreateRequisitionComponent } from "@app/shared-v2/dialogs/create-requisition/create-requisition.component";
import { AddProductComponent } from "@app/shared-v2/product/add-product/add-product.component";
import { switchMap, take, map, tap } from "rxjs/operators";
import { SimpleAddOrganizationComponent } from "@app/shared-v2/organizations/simple/simple.component";
import { Component, OnInit, Input } from "@angular/core";
import { isNullOrUndefined } from "util";
import { LocationListV2Component } from "@app/features-v2/settings/location/list/list.component";
import { MatDialog, MatSnackBar, MatDialogConfig } from "@angular/material";
import {
  LocationServiceV2,
  OrganizationService,
  NodeService,
} from "@app/core-v2/services";
import { LotService } from "@app/core/services/backend/lot.service";
import { AddUserComponent } from "@app/shared/user/add/add.component";
import { forkJoin, Observable, of } from "rxjs";
import { PrintLotQrComponent } from "../lots/print-lot-qr/print-lot-qr.component";
import { DeleteEntityDialogComponent } from "../dialogs/delete-entity-dialog/delete-entity-dialog.component";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "quick-links",
  templateUrl: "./quicklinks.component.html",
  styleUrls: ["./quicklinks.component.scss"],
})
export class QuicklinksComponent implements OnInit {
  private _quickLinks: any;
  @Input()
  set quickLinks(val) {
    this._quickLinks = val;
  }
  get quickLinks() {
    return this._quickLinks;
  }

  private _entityType: string;
  @Input()
  set entityType(val) {
    this._entityType = val;
  }
  get entityType() {
    return this._entityType;
  }

  private _entityId: string;
  @Input()
  set entityId(val) {
    this._entityId = val;
  }
  get entityId() {
    return this._entityId;
  }

  private _entityName: string;
  @Input()
  set entityName(val) {
    this._entityName = val;
  }
  get entityName() {
    return this._entityName;
  }

  nodeIdFromURL: string;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private locationService: LocationServiceV2,
    private organizationService: OrganizationService,
    private nodeService: NodeService,
    private lotService: LotService
  ) {}

  ngOnInit() {
    // console.log("IN QUICKLINKS:", this.quickLinks);
  }

  onQuickLink(action, modalTitle?) {
    switch (action.ActionType) {
      case "modal":
        switch (action.ActionKey) {
          case "add-person":
            return this.openAddPersonModal();
          case "add-organization":
            return this.openAddOrganizationDialog();
          case "add-product":
            return this.dialog.open(AddProductComponent, {
              data: { test: true },
            });
          case "add-requisition":
            return this.openAddRequisitionModal();
          case "custom-form":
            if (Object.keys(action).some((v) => isNullOrUndefined(v)))
              return this.openCustomFormModal();
            else {
              let queryParams = action.Parameters.reduce(
                (p, c, i) =>
                  (p += (i === 0 ? `?` : `&`) + `${c.Name}=${c.Value}`),
                ""
              );
              let method = action.Method;
              let endpoint = `/${action.Controller}/${action.EndPoint}${queryParams}`;
              return this.openCustomFormModal(method, endpoint);
            }
          case "location-list":
            return this.openLocationModal();
          case "location-add":
            return this.openAddLocationModal();
          case "add-user":
            return this.openAddUserModal();
          case "add-supplychain":
            return this.openCreateSupplyChainModal();
          case "scan-qr":
            return this.openScanQRCodeModal();
          case "all-documents":
            return this.openDocumentsDialog(this.entityType, this.entityId);
          case "image-documents":
            return this.openImageDocumentsDialog(
              this.entityType,
              this.entityId
            );
          case "video-documents":
            return this.openVideoDocumentsDialog(
              this.entityType,
              this.entityId
            );
          case "sustainability-list":
            let isNodeEntity = action.Parameters.find(
              (p) => p.Name === "Entity" && p.Value === "node"
            );
            console.log(
              "%c IS NODE ENTITY: ",
              "background: #fae552; color: #323232;",
              isNodeEntity
            );
            if (isNodeEntity) {
              let node_id = action.Parameters.find((p) => p.Name === "EntityId")
                .Value;
              console.log(
                "%c NODE ENTITY-ID: ",
                "background: #fae552; color: #323232;",
                node_id
              );
              return this.openSustainabilityDialog(node_id);
            }
          case "report-list":
            let queryParams = action.Parameters.reduce(
              (p, c, i) =>
                (p += (i === 0 ? `?` : `&`) + `${c.Name}=${c.Value}`),
              ""
            );
            let method = action.Method;
            let endpoint = `/${action.Controller}/${action.EndPoint}${queryParams}`;
            let title = modalTitle;
            return this.openReportListModal(method, endpoint, title);
          case "delete-entity":
            let enId = action.Parameters.find((p) => p.Name === "EntityId")
              .Value;
            let enValue = action.Parameters.find((p) => p.Name === "Entity")
              .Value;
            const enName = this.entityName ? this.entityName : null;
            return this.openDeleteEntityDialog(enId, enValue, enName);
          case "print-qr-code-entity":
            enId = action.Parameters.find((p) => p.Name === "EntityId").Value;
            enValue = action.Parameters.find((p) => p.Name === "Entity").Value;
            return this.openPrintQRCodeEntity(enId, enValue);
          default:
            console.log(
              "%c NO SWITCH CASE FOUND FOR - ActionKey: ",
              "background: #fae552; color: #323232;",
              action.ActionKey
            );
        }
        break;
    }
  }

  openLocationModal() {
    this.locationService.getLocations().subscribe((res) => {
      if (res && res.length) {
        this.dialog.open(LocationListV2Component, { data: { locations: res } });
      }
    });
  }

  openAddOrganizationDialog() {
    let ref = this.dialog.open(SimpleAddOrganizationComponent);

    ref.componentInstance.onCreateOrganization
      .pipe(
        take(1),
        switchMap((res) => this.organizationService.addOrganization(res))
      )
      .subscribe(
        (res: any) => {
          console.log("response: ", res);
          ref.close();
          this.snackBar.open(
            "Success! " + res.OrganizationName + "- Organization Created",
            null,
            { duration: 5000 }
          );
          return;
        },
        (error) => {
          console.log("error: ", error);

          return;
        },
        () => {
          // console.log('--- subscription complete ---');
        }
      );
  }

  openAddRequisitionModal() {
    this.dialog.open(CreateRequisitionComponent);
  }

  openCustomFormModal(method?: string, endpoint?: string, title?: any) {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.data =
      !method || !endpoint ? undefined : { method: method, endpoint: endpoint };
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = "50%";
    this.dialog.open(CustomFormDialogComponent, dialogConfig);
  }

  openReportListModal(
    method?: string,
    endpoint?: string,
    title?: string,
    selectedReport?: any
  ) {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.data =
      !method || !endpoint
        ? undefined
        : {
            method: method,
            endpoint: endpoint,
            title: title,
            selectedReport: selectedReport,
          };
    // dialogConfig.autoFocus = false;
    // dialogConfig.minWidth = '50%';
    this.dialog.open(ReportListDialogComponent, dialogConfig);
  }

  openAddUserModal() {
    this.dialog.open(AddUserComponent);
  }

  openAddLocationModal() {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.height = "90vh";
    dialogConfig.width = "90vw";
    dialogConfig.panelClass = "custom-location-dialog";
    dialogConfig.autoFocus = false;
    let dialogRef = this.dialog.open(LocationDialogComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe()
      .subscribe((res) => {
        console.log(
          "%c LOCATION DIALOG CLOSED: ",
          "background: #fae552; color: #323232;",
          res
        );
        if (res) {
          this.snackBar.open(`${res.data.Name.toUpperCase()} saved`, "close", {
            duration: 2500,
          });
        }
      });
  }

  openScanQRCodeModal() {
    this.dialog.open(ScanTransferQrLotComponent, {
      autoFocus: false,
    });
  }

  openAddPersonModal() {
    forkJoin(
      this.locationService.getLocations(),
      this.organizationService.getOrganizations()
    ).subscribe(([locations, organizations]) => {
      this.dialog.open(AddPersonComponent, {
        data: { locations: locations, organizations: organizations },
      });
    });
  }

  openCreateSupplyChainModal() {
    this.dialog.open(CreateSupplyChainComponent, {
      autoFocus: false,
    });
  }

  openSustainabilityDialog(node_id) {
    this.nodeService
      .getNodeById(node_id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.dialog.open(SustainabilityDialogComponent, {
            autoFocus: false,
            data: { node: res },
            minWidth: "400px",
          });
        },
        (error) => {
          console.log(
            "%c GET node for Sustainability modal error: ",
            "background: #ff0000; color: #ffffff;",
            error
          );
        }
      );
  }

  openDocumentsDialog(entityType, entityId, event?) {
    let documentDialogRef = this.dialog.open(DocumentsDialogComponent, {
      data: {
        entityType: entityType,
        id: entityId,
        dialogHeaderContent: {
          entityType: entityType,
          entityName: "",
          dialogType: "Documents",
        },
      },
      autoFocus: false,
      minWidth: "400px",
      disableClose: true,
    });

    documentDialogRef
      .afterClosed()
      .pipe(map((res) => res))
      .subscribe(
        (res) => {
          // console.log('%c document dialog afterclosed success: ', 'background: #41ff6b; color: #ff4700;', res);
        },
        (error) => {
          // console.log('%c document dialog afterclosed error: ', 'background: #ff0000; color: #ffffff;', error);
        }
      );
  }

  openImageDocumentsDialog(entityType, entityId, event?) {
    let imageDialogRef = this.dialog.open(ImagesDialogComponent, {
      data: {
        entityType: entityType,
        id: entityId,
        dialogHeaderContent: {
          entityType: entityType,
          entityName: "",
          dialogType: "Images",
        },
      },
      autoFocus: false,
      minWidth: "400px",
      disableClose: true,
    });

    imageDialogRef
      .afterClosed()
      .pipe(map((res) => res))
      .subscribe(
        (res) => {
          // console.log('%c image dialog afterclosed success: ', 'background: #41ff6b; color: #ff4700;', res);
        },
        (error) => {
          // console.log('%c image dialog afterclosed error: ', 'background: #ff0000; color: #ffffff;', error);
        }
      );
  }

  openVideoDocumentsDialog(entityType, entityId, event?) {
    let imageDialogRef = this.dialog.open(VideosDialogComponent, {
      data: {
        entityType: entityType,
        id: entityId,
        dialogHeaderContent: {
          entityType: entityType,
          entityName: "",
          dialogType: "Videos",
        },
      },
      autoFocus: false,
      minWidth: "400px",
      disableClose: true,
    });

    imageDialogRef
      .afterClosed()
      .pipe(map((res) => res))
      .subscribe(
        (res) => {
          // console.log('%c image dialog afterclosed success: ', 'background: #41ff6b; color: #ff4700;', res);
        },
        (error) => {
          // console.log('%c image dialog afterclosed error: ', 'background: #ff0000; color: #ffffff;', error);
        }
      );
  }

  // printQR(lot) {
  //   this.dialog.open(PrintLotQrComponent, {
  //     data: { lot: lot, node: this.currentNodeDetails }
  //   });
  // }

  openDeleteEntityDialog(
    entityId: string,
    entityValue: string,
    entityName: string
  ) {
    let deleteEntityObservable: Observable<any>;
    const checkValue = entityValue.toLowerCase().trim();
    // switch(checkValue) {
    //   case "'lot'":
    //     deleteEntityObservable = this.deleteLot(entityId)
    //   default:
    //     console.log(checkValue)
    //     deleteEntityObservable = of(`Delete ${entityValue} with id ${entityId}`)
    // }

    //SHOULD BE SWITCH-CASE AND ADD MORE OBSERVABLE FOR OTHER ENTITY VALUES/TYPES
    if (checkValue === "lot") {
      deleteEntityObservable = this.deleteLot(entityId);
    } else {
      deleteEntityObservable = of(
        `Fake Delete ${entityValue} with id ${entityId}`
      );
    }

    let imageDialogRef = this.dialog.open(DeleteEntityDialogComponent, {
      data: {
        deleteEntityObservable,
        entityValue,
        entityName,
        entityId,
      },
      autoFocus: false,
      minWidth: "400px",
      disableClose: true,
    });

    imageDialogRef
      .afterClosed()
      .pipe(map((res) => res))
      .subscribe(
        (res) => {
          // console.log('%c image dialog afterclosed success: ', 'background: #41ff6b; color: #ff4700;', res);
        },
        (error) => {
          // console.log('%c image dialog afterclosed error: ', 'background: #ff0000; color: #ffffff;', error);
        }
      );
  }

  deleteLot(lotId): Observable<any> {
    return this.lotService.deleteLot(lotId).pipe(
      tap((data) => {
        console.log("DELETING lot: ", lotId);
      })
    );
    // .subscribe(
    //   res => {

    //     // this.nodeStore.getNodeLotsV2(this.currentNodeDetails.id).subscribe();
    //     // this.nodeStore.loadingSpinnerHide();
    //     // this.afterLotDeleted.emit()
    //     return;
    //   },
    //   error => {
    //     console.log('DELETE lot failed: ', error);
    //     this.nodeStore.loadingSpinnerHide();
    //     return;
    //   }
    // );
  }

  openPrintQRCodeEntity(entityId: string, entityValue: string) {
    if (entityValue.toLowerCase() == "lot") {
      this.lotService
        .getLotSummary("v2/" + entityId)
        .pipe(take(1))
        .subscribe((res) => {
          if (res && res.LotId && res.NodeId && res.LotName) {
            this.dialog.open(PrintLotQrComponent, {
              data: {
                lotId: res.LotId,
                nodeId: res.NodeId,
                lotName: res.LotName,
              },
            });
          }
        });
    }
  }
}
