import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TransferOneManyComponent } from './transfer-one-many.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/shared/angular-material.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule
  ],
  declarations: [TransferOneManyComponent],
  exports: [TransferOneManyComponent],
  providers: [DecimalPipe]
})
export class TransferOneManyModule { }
