import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateOrganizationV2Component } from './create/create-organization.component';
import { OrganizationFormV2Component } from './form/form.component';
import { EditOrganizationV2Component } from './edit/edit.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "@app/shared/angular-material.module";
import {SharedModule} from "@app/shared/shared.module";
import {OrganizationService} from "@app/core/services/backend/organization.service";
import {LocationService} from "@app/core/services/backend/locations.service";
import { SimpleAddOrganizationComponent } from './simple/simple.component';
import { LoadersModule } from '../loaders/loaders.module';

const SHARED_COMPONENTS = [
  CreateOrganizationV2Component,
  OrganizationFormV2Component,
  EditOrganizationV2Component,
  SimpleAddOrganizationComponent,
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    LoadersModule
  ],
  declarations: [...SHARED_COMPONENTS],
  exports: [...SHARED_COMPONENTS],
  entryComponents: [...SHARED_COMPONENTS],
  providers: [OrganizationService, LocationService]
})
export class OrganizationsSharedV2Module { }

