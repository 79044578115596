import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { LotRoutes, MarkerRoutes, ReportRoutes, OrganizationRoutes, LocationRoutes, AccountRoutes, AppUserRoutes, NodeRoutes, SupplyChainRoutes } from '@app/core/api-routes.enum';

@Injectable()
export class ApiTestService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getLot(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + LotRoutes.BASE_URL + '/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getSimpleLot(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.SIMPLELOT_URL + '/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getLotTokens(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + LotRoutes.BASE_URL + '/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getLotMarkers(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + MarkerRoutes.BASE_URL + '/GetLotMarkers/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getLotReport(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + ReportRoutes.LOTPREVIOUSNEXT_URL + '/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getOrganization(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + OrganizationRoutes.BASE_URL + '/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getLocation(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + LocationRoutes.BASE_URL + '/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getAccount(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + AccountRoutes.BASE_URL + '/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getUser(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + AppUserRoutes.BASE_URL + '/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getUserByEmail(email): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + AppUserRoutes.BASE_URL + '/Email/' + email, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getNode(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + NodeRoutes.BASE_URL + '/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getNodeLots(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + NodeRoutes.BASE_URL + `/${id}/lots`, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getSupplyChain(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + SupplyChainRoutes.BASE_URL + '/' + id, { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  getSupplyChainNodeData(id): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.BASE_URL + SupplyChainRoutes.BASE_URL + '/' + id + '/nodedatawithlocations', { observe: 'response' })
    .pipe(
      tap(resp => console.log('header', resp.headers.get('ReturnStatus')))
    );
  }

  flattenObject(o: object): any {
    var flatten = function (obj) {
      var toReturn = {};

      for (var i in obj) {

        if (obj[i] === null) {
          toReturn[i] = obj[i];
        }
        else {
          if (!obj.hasOwnProperty(i)) continue;

          if ((typeof obj[i]) == 'object') {
            var flatObject = flatten(obj[i]);
            for (var x in flatObject) {
              if (!flatObject.hasOwnProperty(x)) continue;

              toReturn[i + '.' + x] = flatObject[x];
            }
          } else {
            toReturn[i] = obj[i];
          }
        }
      }
      return toReturn;
    };
    return flatten(o);
  }

  countNullValues(o) {
    let nfa = [];
    let count = 0;
    for (const key in o) {
      const val = o[key];
      const isIdOrHash = (key.substring(key.length - 2).toLowerCase() === 'id' || key.substring(key.length - 2).toLowerCase() === 'hash') ? true : false;
      if (isIdOrHash && val === null) {
        count++;
        nfa.push(key)
      }
    }
    return {count: count, nullFields: nfa};
  }

  returnNullFields(o) {
    return Object.keys(o).reduce((prev, key, i) => {
      if (o[key] === null) {
        prev.push({ fieldKey: key, isIdField: (key.substring(key.length - 2).toLowerCase() === 'id' || key.substring(key.length - 2).toLowerCase() === 'hash') ? true : false})
      }
      return prev;
    }, []);
  }

  copyObject(object) {
    let el = document.createElement('textarea');
    el.value = object;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  clearObject(obj, form) {
    obj.body = undefined;
    obj.status = undefined;
    obj.statusText = undefined;
    obj.url = undefined;
    obj.nullCount = 0;
    form.reset();
  }

  checkIfEmail(string) {
    var str = string;
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var match = re.test(string);
    return match;
  }

  

  // getLotsByNodeId(node_id): Observable<any> {
  //   return this.http.get<any>(this.BASE_URL + '/nodes/' + node_id + this.LOTS_URL);
  // }

  // getLotTokens(type, id): Observable<any> {
  //   return this.http.get<any>(this.BASE_URL + '/lots/tokendashboard/' + type + '/' + id);
  // }

  // getLotSummary(id, type): Observable<any> {
  //   return this.http.get<any>(this.BASE_URL + this.LOTS_SUMMARY_URL + '/' + id + '/' + type.toLowerCase());
  // }

  // transferLots(type, payload): Observable<any> {
  //   return this.http.post<any>(this.BASE_URL + this.LOTS_URL + this.TRANSFER_URL + '/' + type, payload);
  // }

  // transferSplitLots(type, payload): Observable<any> {
  //   return this.http.post<any>(this.BASE_URL + this.LOTS_URL + this.TRANSFER_SPLIT_URL + '/' + type, payload);
  // }

  // editLot(id, lotType, payload): Observable<any> {
  //   return this.http.put<any>(this.BASE_URL + this.LOTS_URL + '/' + id + '/' + lotType, payload);
  // }

  // createLot(lotType, payload): Observable<any> {
  //   return this.http.post<any>(this.BASE_URL + this.LOTS_URL + '/' + lotType, payload);
  // }

  // deleteLot(id, lotType): Observable<any> {
  //   return this.http.delete<any>(this.BASE_URL + this.LOTS_URL + '/' + id + '/' + lotType);
  // }

  // createAbsorbedFromLots(payload): Observable<any> {
  //   return this.http.post<any>(this.BASE_URL + this.LOTS_URL + this.ABSORBED_URL, payload);
  // }

  // addLotMarker(lot, payload) {
  //   return this.http.post<any>(this.BASE_URL + this.LOTS_URL + '/AddMarker/' + lot.id + '/' + lot.LotType, payload);
  // }

  // verifyLotMarker(lot, payload) {
  //   return this.http.post<any>(this.BASE_URL + this.LOTS_URL + '/VerifyMarker/' + lot.id + '/' + lot.LotType, payload)
  // }

  // deleteLotMarker(lot, marker) {
  //   return this.http.delete(this.BASE_URL + this.LOTS_URL + '/DeleteAddedMarker/' + lot.id + '/' + lot.LotType + '/' + marker.MarkerMetaData.MarkerDataId + '/' + marker.AddMarkerId)
  // }

  // getLotMarkers(lot) {
  //   return this.http.get<any>(this.BASE_URL + this.LOTS_URL + '/GetMarkers/' + lot.id + '/' + lot.LotType);
  // }

  // verifySpecifMarkerHardCodedForTest(payload) {
  //   return this.http.post<any>('https://bext360apidevelopment.azurewebsites.net/api/lots/VerifyMarker/cd1989f0-b3db-4bdf-9ecb-e2ffc0d26db8/Storage', payload)
  // }

  // blockchain(id) {
  //   return this.http.get<any>(this.BASE_URL + '/blockchains/' + id);
  // }

}
