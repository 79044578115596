import { NgModule, ModuleWithProviders, APP_INITIALIZER, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthGuard } from "@app/core-v2/guards/auth.guard";
import { BextAuthenticationHttpInterceptor } from '@app/core-v2/services/authentication.httpinterceptor';

import { throwIfAlreadyLoaded } from '@app/core/guards/module-import.guard';
import { SERVICES } from '@app/core-v2/services';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    // StoreModule.forRoot(fromStore.reducers, {
    //   metaReducers: fromStore.metaReducers
    // }),
    // !environment.production ? StoreDevtoolsModule.instrument() : [],
    // EffectsModule.forRoot([...fromStore.effects, AppEffects])
  ],
  providers: [
    AuthGuard,
    ...SERVICES,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BextAuthenticationHttpInterceptor,
      multi: true
    }
  ]
})
export class CoreV2Module {
  constructor(@Optional() @SkipSelf() parentModule: CoreV2Module) {
    throwIfAlreadyLoaded(parentModule, 'CoreV2Module');
  }

}