import { PartnerOrganizationRoutes } from '@app/core/api-routes.enum';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PartnerOrganizationService {

    BASE_URL = environment.baseApiUrl;

    constructor(private http: HttpClient) { }

    getVisiblePartnerOrganizations(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.VISIBLE_PARTNERS);
    }

    getPendingSentRequests(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.SENT_REQUESTS + PartnerOrganizationRoutes.PENDING_STATUS);
    }

    getExpiredSentRequests(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.SENT_REQUESTS + PartnerOrganizationRoutes.EXPIRED_STATUS);
    }

    getAcceptedSentRequests(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.SENT_REQUESTS + PartnerOrganizationRoutes.ACCEPTED_STATUS);
    }

    getAcceptedReceivedRequests(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.RECEIVED_REQUESTS + PartnerOrganizationRoutes.ACCEPTED_STATUS);
    }

    getDeniedSentRequests(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.SENT_REQUESTS + PartnerOrganizationRoutes.DENIED_STATUS);
    }

    getDeniedReceivedRequests(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.RECEIVED_REQUESTS + PartnerOrganizationRoutes.DENIED_STATUS);
    }

    getExpiredReceivedRequests(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.RECEIVED_REQUESTS + PartnerOrganizationRoutes.EXPIRED_STATUS);
    }

    getPendingReceivedRequests(): Observable<any> {
        return this.http.get<any>(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.RECEIVED_REQUESTS + PartnerOrganizationRoutes.PENDING_STATUS);
    }

    addRequest(request): Observable<any> {
        return this.http.post<any>(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.REQUEST, request);
    }

    updateReceivedRequestStatus(requestID, status): Observable<any> {
        return this.http.post(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.CHANGE_RECEIVED_REQUEST_STATUS + `/${status}/${requestID}`, {} )
    }

    updateSentRequestStatus(requestID, status): Observable<any> {
        return this.http.post(this.BASE_URL + PartnerOrganizationRoutes.BASE_URL + PartnerOrganizationRoutes.CHANGE_SENT_REQUEST_STATUS + `/${status}/${requestID}`, {} )
    }
}
