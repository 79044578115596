import { trigger, transition, animate, style } from '@angular/animations'
import { Component, OnInit, Input, HostListener, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { GlobeConfig, IGlobeConfig } from '@app/shared-v2/models/globe/globe-config.model';
import { Router } from '@angular/router';
import { GlobeService } from '@app/core-v2/services';

declare var Earth: any;

@Component({
  selector: 'bext-story-globe',
  templateUrl: './story-globe.component.html',
  styleUrls: ['./story-globe.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('1000ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('1000ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class StoryGlobeComponent {

  private _loadAsync: boolean = true;
  @Input('loadAsync')
  set loadAsync(bool: boolean) {
    this._loadAsync = bool;
  }
  get loadAsync() {
    return this._loadAsync;
  }

  private _globeConfig: object;
  @Input('globeConfig')
  set globeConfig(config: IGlobeConfig) {
    this._globeConfig = new GlobeConfig(config);
  }
  get globeConfig() {
    return this._globeConfig;
  }

  private _markers: any[];
  @Input('markers')
  set markers(markers: any[]) {
    this._markers = markers;
  }
  get markers() {
    return this._markers;
  }

  private _title: string = 'Supply Chain Overview';
  @Input('title')
  set title(title: string) {
    this._title = title;
  }
  get title() {
    return this._title;
  }

  private _listHeader: string = 'Nodes';
  @Input('listHeader')
  set listHeader(listHeader: string) {
    this._listHeader = listHeader;
  }
  get listHeader() {
    return this._listHeader;
  }

  public scriptLoaded = new BehaviorSubject<boolean>(false);

  public star_mesh = 'o Star\nv 0.169 0.000 0.087\nv 0.442 0.000 -0.111\nv 0.104 0.000 -0.111\nv -0.000 0.000 -0.433\nv -0.104 0.000 -0.111\nv -0.443 0.000 -0.111\nv -0.169 0.000 0.087\nv -0.274 0.000 0.409\nv -0.000 0.000 0.210\nv 0.273 0.000 0.409\nv 0.115 0.104 0.070\nv 0.303 0.104 -0.065\nv 0.071 0.104 -0.065\nv -0.000 0.104 -0.286\nv -0.071 0.104 -0.065\nv -0.303 0.104 -0.065\nv -0.116 0.104 0.070\nv -0.187 0.104 0.290\nv -0.000 0.104 0.154\nv 0.187 0.104 0.290\nvn 0.00 -1.00 0.00\nvn 0.00 1.00 -0.00\nvn 0.00 0.99 -0.02\nvn 0.53 0.39 0.74\nvn 0.87 0.39 -0.28\nvn 0.00 0.39 -0.91\nvn -0.87 0.39 -0.28\nvn -0.87 0.39 -0.28\nvn -0.53 0.39 0.74\nvn 0.87 0.39 -0.28\nvn -0.53 0.39 0.74\ns off\nf 8//1 1//1 9//1\nf 15//2 13//2 14//2\nf 17//2 15//2 16//2\nf 17//2 13//2 15//2\nf 17//2 12//2 13//2\nf 17//2 11//2 12//2\nf 18//2 11//2 17//2\nf 18//3 19//3 11//3\nf 19//2 20//2 11//2\nf 9//4 18//4 8//4\nf 1//5 20//5 10//5\nf 3//6 12//6 2//6\nf 7//7 18//7 17//7\nf 5//8 14//8 4//8\nf 5//6 16//6 15//6\nf 7//9 16//9 6//9\nf 1//4 12//4 11//4\nf 3//10 14//10 13//10\nf 9//11 20//11 19//11\nf 9//4 19//4 18//4\nf 1//5 11//5 20//5\nf 3//6 13//6 12//6\nf 7//7 8//7 18//7\nf 5//8 15//8 14//8\nf 5//6 6//6 16//6\nf 7//9 17//9 16//9\nf 1//4 2//4 12//4\nf 3//10 4//10 14//10\nf 9//11 10//11 20//11\n';
  public goto_transition: boolean;
  public current_cluster: any;
  public tooltip: any;
  public currentMarkerSelection: any;
  public showLocationDetails = false;

  constructor(
    private globeService: GlobeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.globeService.loadEarthJs()
      .then(val => {
        // console.log('%c LOAD EARTH: ', 'background: #41ff6b; color: #ff4700;', val);
        this.scriptLoaded.next(true);
        this.scriptLoaded.complete();
      })
  }

  ngAfterViewInit() {
    this.scriptLoaded
      .pipe(
        filter((loaded) => !loaded)
      )
      .subscribe(
        res => {
          // console.log('%c globe config: ', 'background: #fae552; color: #323232;', this.globeConfig);
          // console.log('%c title: ', 'background: #fae552; color: #323232;', this.title);
          console.log('%c markers: ', 'background: #fae552; color: #323232;', this.markers);
          let element = document.getElementById('globe--earth');
          let earth = new Earth('globe--earth', this.globeConfig)
          this.globeService.setEarthInstance(earth, element);
          this.registerOnEarthReady(earth);
          // console.log('EARTH OBJECT: ', earth);
        }
      )
  }

  public expandCluster = (event) => {
    // collapse other clusters
    let componentRef = this;
    let marker = event.target;
    let earthInstance = this.globeService.globeState.earthInstance;
    if (this.current_cluster) {
      this.collapseCluster(earthInstance);
    }

    // shrink cluster marker
    marker.animate('scale', 0.001, { duration: 200 });

    // go to and zoom earth
    earthInstance.draggable = false;
    componentRef.goto_transition = true;
    earthInstance.goTo(marker.location, {
      zoom: 1.8,
      duration: 400,
      easing: 'out-quad',
      complete: function () {
        marker.scale = 0.5;
        componentRef.goto_transition = false;
      }
    });


    // show clusteredMarkers
    for (var i = 0; i < marker.clusteredMarkers.length; i++) {
      marker.clusteredMarkers[i].visible = true;
      marker.clusteredMarkers[i].animate('location', marker.clusteredMarkers[i].markerLocation, { duration: 400, easing: 'out-quad' });
    }

    this.current_cluster = marker;
    document.body.classList.add('globe--cluster-open');
  }

  public enterMarker = (event) => {
    let marker = event.target;
    marker.opacity = 1.0;
    this.tooltip.location = marker.location;
    this.tooltip.content = marker.title;
    this.tooltip.element.style.marginTop = '-' + String(marker.scale * 0.5 + 0.75) + 'em';
    this.tooltip.visible = true;
  }

  public leaveMarker = (event) => {
    let marker = event.target;
    marker.opacity = 0.5;
    this.tooltip.visible = false;
  }

  public openDetails = (event) => {
    this.currentMarkerSelection = event.target.rawData;
    let componentRef = this;
    let earthInstance = event.target.earth;
    let marker = event.target;
    marker.opacity = 1.0;
    componentRef.goto_transition = true;
    earthInstance.goTo(marker.location, {
      zoom: 1.8,
      duration: 800,
      easing: 'in-out-quad',
      complete: function () {
        componentRef.goto_transition = false;
      }
    });
    console.log('%c openDetails event: ', 'background: #41ff6b; color: #ff4700;', event.target.rawData);
    // let url = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/#/' + event.target.link;
    // this.router.navigateByUrl(url);
  }

  registerOnEarthReady(earthInstance) {

    let getRandomInRange = (from, to, fixed = 7) => {
      return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
    }

    let onReady = (event) => {
      this.tooltip = earthInstance.addOverlay({
        className: 'globe--overlay-tooltip',
        visible: false,
        elementScale: 0.2,
        zoomScale: 0,
        depthScale: 0.4
      });

      Earth.addMesh(this.star_mesh);

      let markers = this.markers;

      for (let i = 0; i < markers.length; i++) {

        let currentMarker = markers[i];
        currentMarker['lat'] = currentMarker.Latitude && currentMarker.Latitude !== null ? currentMarker.Latitude : getRandomInRange(-90, 90)
        currentMarker['lng'] =  currentMarker.Longitude && currentMarker.Longitude !== null ? currentMarker.Longitude : getRandomInRange(-180, 180)

        let nextMarker = markers[i + 1];

        if (!!nextMarker) {

          nextMarker['lat'] = nextMarker.Latitude && nextMarker.Latitude !== null ? nextMarker.Latitude : getRandomInRange(-90, 90)
          nextMarker['lng'] = nextMarker.Longitude && nextMarker.Longitude !== null ? nextMarker.Longitude : getRandomInRange(-180, 180)

          var dashedLine = earthInstance.addLine({
            locations: [
              { lat: nextMarker.lat, lng: nextMarker.lng },
              { lat: currentMarker.lat, lng: currentMarker.lng }
            ],
            dashed: true,
            dashSize: 0.25,
            color: '#ffffff',
            dashRatio: 0.5,
            width: 0.3
          })

          dashedLine.animate(
            'dashOffset',
            1,
            {
              loop: true,
              duration: 5000,
              easing: 'in-out-cubic'
            }
          )

        }
        else {
          earthInstance.addLine({
            locations: [
              { lat: currentMarker.lat, lng: currentMarker.lng },
              { lat: currentMarker.lat, lng: currentMarker.lng }
            ],
            color: '#e7e7e7',
            offsetFlow: 2,
            offsetEasing: 'linear',
            width: 0.25,
            endWidth: 2
          })

          earthInstance.addOverlay({
            content: `
              <div class="final-node-overlay">
                <h1>${currentMarker.NodeName}</h1>
                <h3>final node</h3>
              </div>
            `,
            location: { lat: currentMarker.lat, lng: currentMarker.lng },
            className: 'final-node-occulded'
          })
        }

        if (!currentMarker.clusteredMarkers) { // single marker

          let marker = earthInstance.addMarker({

            mesh: "Marker",
            color: '#ffcc00',
            opacity: 0.5,
            location: { lat: currentMarker.lat, lng: currentMarker.lng },
            scale: 0.35,
            hotspotRadius: 0.75,
            hotspotHeight: 0.1,

            // custom properties
            title: currentMarker.NodeName,
            link: `v2/node/${currentMarker.NodeId}`,
            rawData: currentMarker,
            markerId: currentMarker.id
          });

          marker.addEventListener('click', this.openDetails);
          marker.addEventListener('mouseover', this.enterMarker);
          marker.addEventListener('mouseout', this.leaveMarker);


        }
        else { // cluster of markers

          let cluster = earthInstance.addMarker({

            mesh: "Star",
            color: '#ffcc00',
            location: { lat: currentMarker.lat, lng: currentMarker.lng },
            scale: 0.5 + currentMarker.clusteredMarkers.length * 0.03,
            hotspotRadius: 0.5,
            hotspotHeight: 0.1,

            // custom properties
            originalScale: 0.4 + currentMarker.clusteredMarkers.length * 0.03,
            title: currentMarker.NodeName,
            clusteredMarkers: []

          });

          

          cluster.addEventListener('click', this.expandCluster);


          // add clusteredMarkers

          for (let j = 0; j < currentMarker.clusteredMarkers.length; j++) {

            let marker = earthInstance.addMarker({

              mesh: "Star",
              color: '#ffcc00',
              location: { lat: currentMarker.lat, lng: currentMarker.lng },
              scale: 0.2,
              hotspotRadius: 0.5,
              hotspotHeight: 0.1,
              visible: false,

              // custom properties
              title: currentMarker.clusteredMarkers[j].title,
              link: currentMarker.clusteredMarkers[j].link,
              clusterLocation: currentMarker.location,
              markerLocation: currentMarker.clusteredMarkers[j].location

            });

            marker.addEventListener('click', this.openDetails);
            marker.addEventListener('mouseover', this.enterMarker);
            marker.addEventListener('mouseout', this.leaveMarker);

            cluster.clusteredMarkers.push(marker);

          }
        }
      };  
      this.globeService.updateEarthInstance(earthInstance);
    };

    let onClick = (event) => {
      if (this.current_cluster) {
        this.zoomOut(event.target)
      }
    };

    earthInstance.addEventListener('click', onClick);
    earthInstance.addEventListener('ready', onReady);
  }

  collapseCluster(earthInstance) {
    
    if (!this.current_cluster) return;
    // reveal cluster marker
    this.current_cluster.animate('scale', this.current_cluster.originalScale, { duration: 400, easing: 'out-quad' });
    // hide clusteredMarkers
    for (var i = 0; i < this.current_cluster.clusteredMarkers.length; i++) {
      this.current_cluster.clusteredMarkers[i].animate(
        'location',
        this.current_cluster.clusteredMarkers[i].clusterLocation,
        {
          duration: 400,
          easing: 'out-quad',
          complete: function () {
            this.visible = true;
          }
        }
      );
    }

    this.current_cluster = false;

    document.body.classList.remove('globe--cluster-open');
    earthInstance.draggable = true;
  }

  zoomOut(earthInstance = this.globeService.globeState.earthInstance) {
    this.collapseCluster(earthInstance);
    earthInstance.animate('zoom', 1.15, { relativeDuration: 100 });
  }

  selectMarker(marker) {
    let earthInstance = this.globeService.globeState.earthInstance
    let componentRef = this;
    let markerLocation = {
      lat: marker.lat,
      lng: marker.lng
    }
    this.currentMarkerSelection = marker;
    console.log('SELECTED MARKER: ', this.currentMarkerSelection);
    this.goto_transition = true;
    earthInstance.goTo(
      markerLocation,
      {
        zoom: 1.8,
        duration: 800,
        easing: 'in-out-quad',
        complete: function () {
          componentRef.goto_transition = false;
        }
      }
    );
  }

  createRandomLocationCoordinate(from, to, fixed = 7) {
    return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
  }

}

const markers = [
  {
    "location": {
      "lat": 47.5,
      "lng": 10
    },
    "clusteredMarkers": [
      {
        "title": "Great Britain",
        "link": "https://en.wikipedia.org/wiki/Great_Britain",
        "location": {
          "lat": 51.5,
          "lng": -0.24
        }
      },
      {
        "title": "Germany",
        "link": "https://en.wikipedia.org/wiki/Germany",
        "location": {
          "lat": 52.5,
          "lng": 13.2
        }
      },
      {
        "title": "France",
        "link": "https://en.wikipedia.org/wiki/France",
        "location": {
          "lat": 48.8,
          "lng": 2.27
        }
      },
      {
        "title": "Spain",
        "link": "https://en.wikipedia.org/wiki/Spain",
        "location": {
          "lat": 40.4,
          "lng": -3.7
        }
      },
      {
        "title": "Netherlands",
        "link": "https://en.wikipedia.org/wiki/Netherlands",
        "location": {
          "lat": 52.3,
          "lng": 4.8
        }
      },
      {
        "title": "Italy",
        "link": "https://en.wikipedia.org/wiki/Italy",
        "location": {
          "lat": 41.9,
          "lng": 12.3
        }
      },
      {
        "title": "Portugal",
        "link": "https://en.wikipedia.org/wiki/Portugal",
        "location": {
          "lat": 38.7,
          "lng": -9.1
        }
      },
      {
        "title": "Belgium",
        "link": "https://en.wikipedia.org/wiki/Belgium",
        "location": {
          "lat": 50.8,
          "lng": 4.3
        }
      },
      {
        "title": "Austria",
        "link": "https://en.wikipedia.org/wiki/Austria",
        "location": {
          "lat": 48.2,
          "lng": 16.2
        }
      },
      {
        "title": "Denmark",
        "link": "https://en.wikipedia.org/wiki/Denmark",
        "location": {
          "lat": 55.6,
          "lng": 12.5
        }
      },
      {
        "title": "Ireland",
        "link": "https://en.wikipedia.org/wiki/Ireland",
        "location": {
          "lat": 53.3,
          "lng": -6.3
        }
      },
      {
        "title": "Sweden",
        "link": "https://en.wikipedia.org/wiki/Sweden",
        "location": {
          "lat": 59.3,
          "lng": 18
        }
      },
      {
        "title": "Finnland",
        "link": "https://en.wikipedia.org/wiki/Finnland",
        "location": {
          "lat": 60.5,
          "lng": 24.9
        }
      },
      {
        "title": "Estonia",
        "link": "https://en.wikipedia.org/wiki/Estonia",
        "location": {
          "lat": 59,
          "lng": 24.7
        }
      },
      {
        "title": "Latvia",
        "link": "https://en.wikipedia.org/wiki/Latvia",
        "location": {
          "lat": 56.9,
          "lng": 24.1
        }
      },
      {
        "title": "Lithuania",
        "link": "https://en.wikipedia.org/wiki/Lithuania",
        "location": {
          "lat": 54.6,
          "lng": 25.2
        }
      },
      {
        "title": "Luxembourg",
        "link": "https://en.wikipedia.org/wiki/Luxembourg",
        "location": {
          "lat": 49.6,
          "lng": 6.1
        }
      },
      {
        "title": "Greece",
        "link": "https://en.wikipedia.org/wiki/Greece",
        "location": {
          "lat": 37.9,
          "lng": 23.7
        }
      },
      {
        "title": "Poland",
        "link": "https://en.wikipedia.org/wiki/Poland",
        "location": {
          "lat": 52.2,
          "lng": 21
        }
      },
      {
        "title": "Czech Republic",
        "link": "https://en.wikipedia.org/wiki/Czech_Republic",
        "location": {
          "lat": 50,
          "lng": 14.4
        }
      },
      {
        "title": "Malta",
        "link": "https://en.wikipedia.org/wiki/Malta",
        "location": {
          "lat": 35.8,
          "lng": 14.4
        }
      },
      {
        "title": "Slovakia",
        "link": "https://en.wikipedia.org/wiki/Slovakia",
        "location": {
          "lat": 48.2,
          "lng": 17.8
        }
      },
      {
        "title": "Bulgaria",
        "link": "https://en.wikipedia.org/wiki/Bulgaria",
        "location": {
          "lat": 42.6,
          "lng": 23.3
        }
      },
      {
        "title": "Slovenia",
        "link": "https://en.wikipedia.org/wiki/Slovenia",
        "location": {
          "lat": 46,
          "lng": 14.4
        }
      },
      {
        "title": "Croatia",
        "link": "https://en.wikipedia.org/wiki/Croatia",
        "location": {
          "lat": 45.8,
          "lng": 16.2
        }
      },
      {
        "title": "Hungary",
        "link": "https://en.wikipedia.org/wiki/Hungary",
        "location": {
          "lat": 47.4,
          "lng": 19
        }
      },
      {
        "title": "Romania",
        "link": "https://en.wikipedia.org/wiki/Romania",
        "location": {
          "lat": 44.4,
          "lng": 26
        }
      },
      {
        "title": "Cyprus",
        "link": "https://en.wikipedia.org/wiki/Cyprus",
        "location": {
          "lat": 35,
          "lng": 33
        }
      }
    ]
  },
  {
    "location": {
      "lat": 33,
      "lng": -20
    },
    "clusteredMarkers": [
      {
        "title": "Canary Islands (ES)",
        "link": "https://en.wikipedia.org/wiki/Canary_Islands",
        "location": {
          "lat": 28,
          "lng": -15.7
        }
      },
      {
        "title": "Madeira (PT)",
        "link": "https://en.wikipedia.org/wiki/Madeira",
        "location": {
          "lat": 32.6,
          "lng": -16.9
        }
      },
      {
        "title": "Azores (PT)",
        "link": "https://en.wikipedia.org/wiki/Azores",
        "location": {
          "lat": 38.5,
          "lng": -28.5
        }
      }
    ]
  },
  {
    "location": {
      "lat": 13,
      "lng": -62
    },
    "clusteredMarkers": [
      {
        "title": "Aruba (NL)",
        "link": "https://en.wikipedia.org/wiki/Aruba",
        "location": {
          "lat": 12.5,
          "lng": -70
        }
      },
      {
        "title": "Curaçao (NL)",
        "link": "https://en.wikipedia.org/wiki/Curaçao",
        "location": {
          "lat": 12.1,
          "lng": -68.8
        }
      },
      {
        "title": "Saint-Martin (FR/NL)",
        "link": "https://en.wikipedia.org/wiki/Saint_Martin",
        "location": {
          "lat": 18,
          "lng": -63
        }
      },
      {
        "title": "Guadeloupe (FR)",
        "link": "https://en.wikipedia.org/wiki/Guadeloupe",
        "location": {
          "lat": 16.2,
          "lng": -61.5
        }
      },
      {
        "title": "Martinique (FR)",
        "link": "https://en.wikipedia.org/wiki/Martinique",
        "location": {
          "lat": 14.6,
          "lng": -61
        }
      },
      {
        "title": "French Guiana (FR)",
        "link": "https://en.wikipedia.org/wiki/French_Guiana",
        "location": {
          "lat": 4.8,
          "lng": -52
        }
      }
    ]
  },
  {
    "location": {
      "lat": -19,
      "lng": 52
    },
    "clusteredMarkers": [
      {
        "title": "Mayotte (FR)",
        "link": "https://en.wikipedia.org/wiki/Mayotte",
        "location": {
          "lat": -12.8,
          "lng": 45
        }
      },
      {
        "title": "Réunion (FR)",
        "link": "https://en.wikipedia.org/wiki/Réunion",
        "location": {
          "lat": -21.1,
          "lng": 55.5
        }
      }
    ]
  },
  {
    "title": "Greenland (DK)",
    "link": "https://en.wikipedia.org/wiki/Greenland",
    "location": {
      "lat": 64.1,
      "lng": -51.6
    }
  },
  {
    "title": "Falkland Islands (GB)",
    "link": "https://en.wikipedia.org/wiki/Falkland_Islands",
    "location": {
      "lat": -51.7,
      "lng": -60
    }
  },
  {
    "title": "French Polynesia (FR)",
    "link": "https://en.wikipedia.org/wiki/French_Polynesia",
    "location": {
      "lat": -17.6,
      "lng": -149.5
    }
  },
  {
    "title": "Wallis and Futuna (FR)",
    "link": "https://en.wikipedia.org/wiki/Wallis_and_Futuna",
    "location": {
      "lat": -14.2,
      "lng": -178.1
    }
  },
  {
    "title": "New Caledonia (FR)",
    "link": "https://en.wikipedia.org/wiki/New_Caledonia",
    "location": {
      "lat": -21.2,
      "lng": 165.5
    }
  }
];