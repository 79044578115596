import { SupplyChainRoutes } from '@app/core-v2/api-routes-v2.enum';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SampleValuations, ValuationModel, FakeValuation } from '@app/@core/data/sample.valuations.model';

@Injectable()
export class SupplyChainService {
  
  BASE_URL = environment.baseApiUrl;
  public sampleValuations: Array<ValuationModel>;

  constructor(private http: HttpClient) {

    let valuationsList = []

    for (let x = 0; x < 6; x++) {
      let tempVal = JSON.parse(JSON.stringify(FakeValuation));
      tempVal.ValuationName += new String(x);
        valuationsList.push(tempVal);
    }

    this.sampleValuations = new SampleValuations(valuationsList)['ValuationList'];
  }
  
  getSupplychains(): Observable<any> {
    return this.http.get<any>(this.BASE_URL + SupplyChainRoutes.BASE_URL);
  }

  getSupplychain(supply_chain_id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + SupplyChainRoutes.BASE_URL + '/' + supply_chain_id);
  }

  getSupplychainNodeData(supply_chain_id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + SupplyChainRoutes.BASE_URL + '/' + supply_chain_id + '/nodedatawithlocations');
  }
  
  addSupplychain(supplychain): Observable<any> {
    return this.http.post<any>(this.BASE_URL + SupplyChainRoutes.BASE_URL, supplychain);
  }

  deleteSupplychain(id): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + SupplyChainRoutes.BASE_URL + '/' + id);
  }

  getValuation(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + SupplyChainRoutes.BASE_URL + SupplyChainRoutes.VALUATION_URL + '/' + id);
  }

  addValuation(id, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + SupplyChainRoutes.BASE_URL + SupplyChainRoutes.VALUATION_URL + '/' + id, payload);
  }

  getPrimarySupplyChain() : Observable<any> {
    return this.http.get(this.BASE_URL + SupplyChainRoutes.BASE_URL + '/primarysupplychain');
  }

  getSupplyChainValuations(): Observable<any> {
    return of(this.sampleValuations);
  }
}