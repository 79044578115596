import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OrganizationService } from '@app/core/services/backend/organization.service';

@Component({
  selector: 'bext-add-organization',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss']
})
export class SimpleAddOrganizationComponent implements OnInit {

  private _isLoading = false;
  get isLoading() {
    return this._isLoading;
  }
  set isLoading(bool) {
    this._isLoading = bool;
  }

  @Output('onCreateOrganization') onCreateOrganization: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  constructor(private fb: FormBuilder, private organizationSerivce: OrganizationService) {
    this.form = this.fb.group({
      'OrganizationName': new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
  }

  createOrganization() {
    if (this.form.valid) {
      this.isLoading = true;
      this.onCreateOrganization.emit(this.form.value);
    }
    else {
      return;
    }
  }

}
