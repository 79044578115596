import { Component, Input } from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntil, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'bext-logo-loader',
  templateUrl: './logo-loader.component.html',
  styleUrls: ['./logo-loader.component.scss']
})
export class LogoLoaderComponent {

  private _animate = false;

  @Input('animate')
  set animate(animate) {
    this._animate = animate;
  }
  get animate() {
    return this._animate;
  }

  constructor() { }

  ngOnInit() {

    // let letterNodeList = document.querySelectorAll('#loader #loader-shell #center-sphere .sphere-text p.letter');
    // console.log('letter node list: ', letterNodeList);
    // letterNodeList.forEach((letter) => letter.setAttribute('class', 'letter-animation'));
    this.animateLoaderText();
  }

  animateLoaderText() {

    var sphereNode = document.querySelector('#loader #loader-shell #center-sphere .sphere-text');
    var letterNodeList = document.querySelectorAll('#loader #loader-shell #center-sphere .sphere-text p.letter');
    var iteration = 0;
    var id = setInterval(frame, 100);

    function frame() {
      if (iteration > 6) {
        // console.log('iteration end on -: ', iteration);
        sphereNode.classList.add('letter-animation-pulse');
        clearInterval(id);
      } else {
        // console.log('iteration: ', iteration);
        letterNodeList[iteration].classList.add('letter-animation');
        iteration++;
      }
    };

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

  

}
