import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QuicklinksComponent } from "./quicklinks.component";
import {
  MatListModule,
  MatButtonModule,
  MatTooltipModule,
  MatIconModule,
  MatExpansionModule,
  MatMenuModule,
  MatDialogModule,
  MatSnackBarModule,
} from "@angular/material";
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatExpansionModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    RouterModule,
  ],
  declarations: [QuicklinksComponent],
  exports: [QuicklinksComponent],
})
export class QuicklinksModule {}
