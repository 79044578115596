import { tap } from 'rxjs/operators';
import { Injectable, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';

@Injectable()
export class CSVFileService {

  customCsvParsingString;

  BASE_URL = environment.baseApiUrl;
  LOCATIONS_URL = '/locations';
  APPUSER_URL = '/appusers';
  PERSONS_URL = '/persons';
  ORGANIZATION_URL = "/organizations";

  public ORG_ID = '(organization ids can be found in the email you received from bext360)' || '914b3dcb-6b72-4b69-992e-f5aba2a45ebb';
  public csvTypes = {
    location: "Name,Country,City,State,Latitude,Longitude,Elevation,ElevationUnit,Address,Description",
    user: "Name,EmailAddress,OrganizationIds,\n,," + this.ORG_ID,
    person: "Name,PersonType,LocationName,NameOfICS,Notes,FarmerId,ImageUrl,,locations to choose from,\n John Doe,Farmer or Collector,abc123,Some notes about the farmer or collector,,,Responsible Quinoa HQ,\n,,,,,,Responsible Corporation HQ,\n,,,,,,Peru Cooperative,\n,,,,,,Peru Dry Mill,\n,,,,,,Allegro HQ,\n,,,,,,ZAKLAD PIERZARSKI KONRAD OZGO - Textile Mill,\n,,,,,,Kahawa 1893 HQ,\n,,,,,,Amazing Corporation HQ,\n,,,,,,Nolawee Test,\n,,,,,,Bext360 HQ,\n,,,,,,Sinkhedi Cotton Farm,\n,,,,,,La Virgen Coffee Estate",
    organization: "OrganizationName,OrganizationType,TrustLineAccountName,FundingAccountName,TestAccount,BlockChainName,FundingAmount,LocationName,\n,Company,TestTrustLineAccount,TestFundingAccount,true,Stellar,2000",
    node: "UPLOADING NODE DATA VIA CSV IS NOT AVAILABLE AT THIS TIME. PLEASE CHECK BACK SOON.",
    supplychain: "UPLOADING SUPPLY CHAIN DATA VIA CSV IS NOT AVAILABLE AT THIS TIME. PLEASE CHECK BACK SOON.",
    lot: [
      {
        "LotName": "Lot1",
        "StartWeight": "44",
        "StartWeightUnit": "KG",
        "EndWeight": "97",
        "EndWeightUnit": "LB",
        "Varietal": "Bourbon",
        // "StartProcess": this.getTimeStamp(),
        "Brix": "1.9",
        "Notes": "A natural mutation of Typica, Bourbon is a high-quality, medium-yield coffee known for its sweet taste. It has, however, low resistance to leaf rust, coffee berry borer, and other diseases and pests. It’s commonly grown in Burundi and Rwanda, as well as throughout Latin America."
      }
    ]
  }

  constructor(private http: HttpClient) {
    
  }


  public getCsvFile(fileType, fileName, customCsvParsingString?) {
    let parsedResponse = customCsvParsingString || this.csvTypes[fileType];
    let blob = new Blob([parsedResponse], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    return window.URL.revokeObjectURL(url);
  }


  public getExcelFile(fileType, fileName) {
    let json = this.csvTypes[fileType];
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet['!cols'] = this.getColumnWidths(json[0])
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    XLSX.writeFile(workbook, this.toExportFileName(fileName));
  }

  private toExportFileName(excelFileName: string) {
    let d = new Date();
    return `${excelFileName}_${d.getFullYear()}${d.getMonth()}${d.getDate()}.xlsx`;
  }

  private getColumnWidths(dataObject) {
    let columns = Object.keys(dataObject);
    return columns.reduce((prev, curr) => {
      if (dataObject[curr].length > curr.length) {
        prev.push({wch: dataObject[curr].length})
      }
      else {
        prev.push({wch: curr.length});
      }
      return prev;
    },[]);
  }

  private getTimeStamp() {
    let d = new Date();
    return d.getFullYear() + '-' + ((d.getMonth() < 10 ? '0' : '') + (d.getMonth() + 1)) + '-' + ((d.getDate() < 10 ? '0' : '') + d.getDate()) + 'T' + (d.getHours() < 10 ? '0' : '') + d.getHours() + ':' + ((d.getMinutes() < 10 ? '0' : '') + d.getMinutes())
  }

  public uploadCsv(csvType, data) {
    switch (csvType) {
      case 'location':
        var url = this.BASE_URL + this.LOCATIONS_URL;
        return this.createObject(url, data).pipe(map(res => res));
      case 'user':
        var url = this.BASE_URL + this.APPUSER_URL;
        return this.createObject(url, data).pipe(map(res => res));
      case 'organization':
        var url = this.BASE_URL + this.ORGANIZATION_URL;
        return this.createObject(url, data).pipe(map(res => res));
      case 'person':
        var url = this.BASE_URL + this.PERSONS_URL;
        return this.createObject(url, data).pipe(map(res => res));

      default:
        break;
    }
  }

  private createObject(url, payload): Observable<any> {
    return this.http.post<any>(url, payload);
  }


}