import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatIconModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatProgressSpinnerModule } from '@angular/material';
import { DownloadFileButtonComponent } from './download-file-button/download-file-button.component';
import { FileNameDialogComponent } from './download-file-button/file-name-dialog/file-name-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { CreateSupplyChainComponent } from './create-supply-chain/create-supply-chain.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';

// V1 CODE REFERENCES
import { SharedNodesModule } from '@app/shared/nodes/nodes.module';
import { SmartadminUiModule } from '@app/shared/ui/smartadmin-ui.module';
import { AlertModule, ModalModule } from 'ngx-bootstrap';
import { DndModule } from 'ngx-drag-drop';
import { AsyncMatSelectComponent } from './async-mat-select/async-mat-select.component';
// END REFERENCES

const UTIL_COMPONENTS = [
  DownloadFileButtonComponent,
  FileNameDialogComponent,
  CreateSupplyChainComponent,
  AsyncMatSelectComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    ModalModule,
    AngularDualListBoxModule,
    DndModule,
    SharedNodesModule,
    SmartadminUiModule,
    NgxSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    ...UTIL_COMPONENTS
  ],
  exports: [
    ...UTIL_COMPONENTS
  ],
  entryComponents: [
    FileNameDialogComponent,
    CreateSupplyChainComponent,
    AsyncMatSelectComponent
  ],
  providers: [
    NgxSpinnerService
  ]
})
export class UtilComponentsModule { }
