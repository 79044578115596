import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportV2Routes } from '@app/core-v2/api-routes-v2.enum';
import { flattenObject } from '@app/shared-v2/utils/object-utils';

@Injectable()

export class ReportsService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getNespressoChartData(): Observable<any> {
    return this.http.get(this.BASE_URL + '/reports/nespressocapsulereport/dashlinereport');
  }

  getNespressoTopComparisons(): Observable<any> {
    return this.http.get(this.BASE_URL + '/reports/nespressodashboardtable');
  }
  
  getNespressoZipCodes(): Observable<any> {
    return this.http.get(this.BASE_URL + '/reports/nespressocapsulereport/getjsonfile');
  }

  getReportsList(): Observable<any> {
    return this.http.get(this.BASE_URL + '/reportsv2/reportslist');
  }

  getReportConfig(report_id, owner_org_id) {
    return this.http.get(this.BASE_URL + '/reportsv2/reportconfig/' + report_id + '/' + owner_org_id);
  }

  getExcelReport(report_id, queryParamRequestString) {
    return this.http.get(this.BASE_URL + '/reportsv2/downloadreport/excel/' + report_id + queryParamRequestString);
  }

  getPdfReport(report_id, queryParamRequestString) {
    return this.http.get(this.BASE_URL + '/reportsv2/downloadreport/pdf/' + report_id + queryParamRequestString);
  }

  emailPdfReport(report_id, queryParamRequestString) {
    return this.http.get(this.BASE_URL + '/reportsv2/emailreport/pdf/' + report_id + queryParamRequestString);
  }

  emailExcelReport(report_id, queryParamRequestString) {
    return this.http.get(this.BASE_URL + '/reportsv2/emailreport/excel/' + report_id + queryParamRequestString)
  }

  saveCustomReport(report) {
    return this.http.post(this.BASE_URL + '/api/reportsV2/savecustomreport', report);
  }

  getReport(report_id, queryParamRequestString) {
    return this.http.get(this.BASE_URL + '/reportsv2/' + report_id + queryParamRequestString);
  }

  getReportList(endpoint) {
    return this.http.get(this.BASE_URL + endpoint);
  }

  getLotHistoryReport(topLevelLotID, maxLots, maxLevels) {
    return this.http.get<any>(this.BASE_URL + ReportV2Routes.BASE_URL + ReportV2Routes.RECURSIVE_LOT_HISTORY_LIST_URL + '&Lot.LotId=' + topLevelLotID + '&Lot.MaxLots=' + maxLots + '&Lot.MaxLevels=' + maxLevels);
  }

}