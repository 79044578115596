import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'filterByStatus' })
export class FilterByStatusPipe implements PipeTransform {

  transform(lots: any, lotStatus: boolean): any[] {
    if (lots) {
      return lots.filter((obj: any) => {
        if (obj.LotStatus === null || obj.LotStatus.Open === lotStatus) {
          return obj
        }
      });
    }
  }
}