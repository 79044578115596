import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransferManyOneComponent } from './transfer-many-one.component';
import { MaterialModule } from '@app/shared/angular-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
  ],
  declarations: [TransferManyOneComponent],
  exports: [TransferManyOneComponent]
})
export class TransferManyOneModule { }
