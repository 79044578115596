import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


@Component({
  selector: 'bext-upload-user-image-dialog',
  templateUrl: './upload-user-image-dialog.component.html',
  styleUrls: ['./upload-user-image-dialog.component.scss']
})
export class UploadUserImageDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadUserImageDialogComponent>
  ) {
    console.log('Upload User Image Dialog - data: ', this.data);
  }
    
  ngOnInit() {
  }

  closeDialog(results?) {
    this.dialogRef.close(results);
  }

  previewUserImage(e) {
    
  }

  uploadUserImage(e) {

  }

}
