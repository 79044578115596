import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonsFormComponent } from './form/persons-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonsService } from '@app/core/services/backend/persons.service';
import { UploadService } from '@app/core/services/backend/uploads.service';
import { LocationService } from '@app/core/services/backend/locations.service';
import { NgxSpinnerService } from 'ngx-spinner';
@NgModule({
  declarations: [      PersonsFormComponent ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ], exports: [
    PersonsFormComponent
  ],
  providers: [
    PersonsService,
    UploadService,
    LocationService,
    NgxSpinnerService
  ]
})
export class SharedPersonsModule { }
