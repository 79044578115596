import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { takeWhile, take, map, mergeMap, tap, switchMap, skip } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bext-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent implements OnInit {

  private _location: any;
  @Input()
  set location(val) {
    this._location = val;
  }
  get location() {
    return this._location;
  }

  private _eventLoading: BehaviorSubject<any> = new BehaviorSubject<any>({data: undefined, loading: false, complete: false});
  get eventLoading() {
    return this._eventLoading;
  }
  get eventLoading$() {
    return this._eventLoading.asObservable();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LocationDialogComponent>,
  ) { }

  ngOnInit() {
    if (this.data && this.data.location) {
      this.location = this.data.location;
    }
  
    this.eventLoading$
      .pipe(
        skip(1)
      )
      .subscribe(
        res => {
          console.log('%c event loading: ', 'background: #fae552; color: #323232;', res);
          if (res.loading === false && res.complete) this.dialogRef.close(res)
        }
      )

  }

  locationActionEvent(e) {
    switch (e.eventType) {
      case 'CANCEL':
        this.dialogRef.close()
        break;

      case 'CRUD':
        let eventLoadingState = this.eventLoading.getValue();
        eventLoadingState.loading = e.loading;
        eventLoadingState.data = e.data;
        eventLoadingState.complete = e.loading === false;
        this.eventLoading.next(eventLoadingState)
        break;
    
      default:
        break;
    }

  }

}
