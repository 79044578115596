import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ProductsService } from "@app/core/services/backend/product.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

@Component({
  selector: "sa-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.css"]
})
export class AddProductComponent implements OnInit {
  public productName: string = "";
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productService: ProductsService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {
    this.form = this.fb.group({
      ProductName: new FormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(10)
      ])
    });
  }

  ngOnInit() {}

  get ProductNameControl() {
    return this.form.get("ProductName") as FormGroup;
  }

  onSubmit() {
    console.log(this.form.valid, this.form);
    if (this.form.valid) {
      this.productService.addProduct(this.form.value).subscribe(res => {
        if (res) {
          this.dialogRef.close();
          this._snackBar.open("Product created successfully", "OK", {
            duration: 3000
          });
        }
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
