import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFormDialogComponent } from './custom-form-dialog.component';
import { BextArchwizardModule } from '@app/shared-v2/archwizard/archwizard.module';
import { MatProgressSpinnerModule, MatButtonModule, MatIconModule, MatDialogModule } from '@angular/material';
import { LoadersModule } from '@app/shared-v2/loaders/loaders.module';

@NgModule({
  imports: [
    CommonModule,
    BextArchwizardModule,
    LoadersModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule
  ],
  declarations: [CustomFormDialogComponent],
  exports: [CustomFormDialogComponent],
  entryComponents: [CustomFormDialogComponent]
})
export class CustomFormDialogModule { }
