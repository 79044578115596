import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { OrganizationService } from '@app/core/services/backend/organization.service';
import { LocationService } from '@app/core/services/backend/locations.service';
import { Subscription, from, forkJoin } from 'rxjs';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { NodeService } from '@app/core/services/backend/node.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductTypes } from './productTypes';
import { uiObject } from '@app/shared/models/uimeta-data.entity';
import { DndDropEvent } from 'ngx-drag-drop';

import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { ToggleAction } from '@ngrx/store-devtools/src/actions';
import { pipe } from '@angular/core/src/render3';
import { map, tap, switchMap, retry, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'sa-create-node',
  templateUrl: './create-node.component.html',
  styleUrls: ['./create-node.component.css']
})
export class CreateNodeComponent implements OnInit, OnDestroy {

  private alive = true;

  public organizations;
  public formModel: any;
  public organizationsSub: Subscription;
  public locations;
  public locationsSub: Subscription;
  public selectedNodeType = new FormControl('');
  public locationForm;
  public organizationForm;
  public newNode: any = {hideMarkers: true, AddMarkers: [], TestAccount: true, NodeName: '', LocationId: 'Select Location', NodeType: 'Select Node Type', NodeDetailType: 'Select Node Detail Type', DefaultProductType: 'Select Commodity'};
  public nodeTypes:any = [];
  public spinnerMessages = [{text: 'Loading...', display: true}, {text :'Creating Node Accounts...', display: false}, { text: 'Creating Trust Lines for Value Tokens...'}, {text: 'Creating Trust Lines for Traceability Tokens and Funding Account...'}];
  public currentSpinnerMessage = this.spinnerMessages[0];
  public uiMetadataFields;
  public myUiObject = uiObject;
  public template;
  public templates;
  public addOrganization;
  public sections;
  public customFieldsSectionAdded;
  public fileUploadModalSection;
  public fileUpload = new FileUpload();
  public nodeDetailTypes = [];
  public productTypes = [];
  public showLocationName = false;
  public inputTypes = ['text', 'radio', 'checkbox', 'select', 'number', 'date', 'textarea', 'datetime-local'];
  public nodeDetailTypesByProduct = (new ProductTypes()).TypesByProduct;
  public activeTab = "ss0";
  public addSelectOption = {};
  public selectedLocation;
  public forbiddenNodeNames: any[] = [];
  public showDefaultDetailTypes = true;
  public showOrganizationDetailTypes = false;
  public filteredDetailTypes = [];

  @Input() isInModal = false;
  @Output() wizardCompleted: EventEmitter<any> = new EventEmitter();
  @ViewChild('locationModal') public locationModal: ModalDirective;
  @ViewChild('organizationModal') public organizationModal: ModalDirective;
  @ViewChild('createNodeForm') createNodeForm: any;

  constructor(private organizationService: OrganizationService, private locationService: LocationService, private nodeService: NodeService, private router: Router, private fb: FormBuilder, private spinnerService: NgxSpinnerService) { 
    this.nodeService.getNodes()
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(res => {
        this.forbiddenNodeNames = res.map(node => node.NodeData.NodeName.toLowerCase());
        this.createNodeForm.controls['NodeName'].setValidators([Validators.required, this.forbiddenNames.bind(this)])
      })
  }

  ngOnInit() {
    // this.loadOrganizations();
    this.loadLocations();
    this.locationForm = this.fb.group({
      addLocationForm: this.fb.group({
          Name: this.fb.control('', [Validators.required]),
          Country: this.fb.control('', [Validators.required]),
          City: this.fb.control('', [Validators.required]),
          State: this.fb.control('', [Validators.required]),
          Latitude: this.fb.control('', []),
          Longitude: this.fb.control('', []),
          Elevation: this.fb.control('', []),
          ElevationUnit: this.fb.control('feet', []),
          Address: this.fb.control('', []),
          Description: this.fb.control('', []),
      })
    })
    this.setNodeProductTypes();
    // this.organizationForm = this.fb.group({
    //   addOrganizationForm: this.fb.group({
    //     OrganizationName: this.fb.control('', [Validators.required]),
    //     OrganizationType: this.fb.control('Company', [Validators.required]),
    //     TrustLineAccountName: this.fb.control('TestTrustLineAccount', [Validators.required]),
    //     FundingAccountName: this.fb.control('TestFundingAccount', [Validators.required]),
    //     TestAccount: this.fb.control(true, [Validators.required]),
    //     BlockChainName: this.fb.control('Stellar', [Validators.required]),
    //     FundingAmount: this.fb.control(2000, [Validators.required]),
    //     LocationId: this.fb.control('', [Validators.required]),
    //     LocationName: this.fb.control('', [Validators.required]),
    //   })
    // });
    this.setNodeTypes();
  }

  titleCase(str) {
    if (str && str.length) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  } else {
    return '';
  }
 }

 getNodeDetailTypes(e) {
  this.nodeService.getNodeDetailTypes(this.newNode.DefaultProductType, e).subscribe((res:any ) => {
       this.nodeDetailTypes = res && res.NodeDetailTypes ? res.NodeDetailTypes : [];
       this.filterDetailTypes();
  });
}

filterDetailTypes() {
  this.filteredDetailTypes = this.nodeDetailTypes.filter(type => {
    if (this.showDefaultDetailTypes && !this.showOrganizationDetailTypes) {
      return type.DefaultTemplate;
    } else if (this.showOrganizationDetailTypes && !this.showDefaultDetailTypes) {
      return type.OrganizationTemplate;
    } else if (this.showDefaultDetailTypes && this.showOrganizationDetailTypes) {
      return type.DefaultTemplate || type.OrganizationTemplate;
    } else if (!this.showDefaultDetailTypes && !this.showOrganizationDetailTypes) {
      return true;
    }
  })
}

  loadTemplates() {

  if (this.newNode.NodeDetailType && this.newNode.NodeDetailType.length) {
    this.spinnerService.show();
    this.nodeService.getNodeTemplate(this.newNode.NodeDetailType).subscribe(res => {
      this.sections = res.UiMetaDatas.filter(obj => (obj.Type == 'CreateLot') || (obj.Type == 'Create Lot'))[0]['Sections']
      this.sections.forEach(section => {
        section.Fields.forEach(field => {
          field.label = this.titleCase(field.label);
        })
      })
      // this.sections.splice(0,0,{SectionName: 'Required', Fields: [], defaultRequired: true})
      // let indexesToDelete = [];
      // this.sections[1].Fields.forEach((field, index) => {
      //   if ((field.validation.create.required || field.validation.analysis.required || field.validation.transfer.required)) {
      //     this.sections[0].Fields.push(field);
      //     indexesToDelete.push(index);
      //     field.disableVisibleRequired = true;
      //   }
      // })
      // indexesToDelete.reverse().forEach(ind => {
      //   this.sections[1].Fields.splice(ind, 1);
      // });
      this.spinnerService.hide();
    }, err => {
      this.spinnerService.hide();
      console.error('error getting NodeTempate :', err);
    });
  } else {
    console.error('node detail type not selected, can\'t load templates')
  }
  }

  loadOrganizations() {
    this.organizationsSub = this.organizationService.getOrganizations()
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(res => {
        this.organizations = res;
      });
  }

  loadLocations() {
    this.locationsSub = this.locationService.getLocations()
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(res => {
        this.locations = res;
      });
  }

  autoCompleteLocation(e) {
    let addLocationFormGroup = this.locationForm.get('addLocationForm')
    addLocationFormGroup.patchValue(e);
    this.formModel = e;
  }

  getLocation(id) {
    if (this.locations && !this.selectedLocation) {
    return this.locations.find((location) => {
      let condition = location.id == id;
      if (condition) {
        this.selectedLocation = location;
        return true;
      } else {
        return condition;
      }
    });
  } else if (this.locations && this.selectedLocation) {
    return this.selectedLocation;
  }
  }

  ngOnDestroy() {
    // this.organizationsSub.unsubscribe();
    this.alive = false;
  }

  onWizardComplete(e) {
    if (e.TestAccount) {
      e.TestAccount = true;
    } else {
      e.TestAccount = false;
    }
    delete e.hideMarkers;
    delete e['radio-toggle'];
    e.UiData = [{ Type: 'Create Lot', Sections: this.sections }];
    this.spinnerService.show();

    this.spinnerMessages[0].display = false;
    this.spinnerMessages[1].display = true;
    this.spinnerMessages[2].display = true;
    this.spinnerMessages[3].display = true;
    console.log('%c create node payload: ', 'background: #41ff6b; color: #ff4700;', e);

    this.nodeService.addNodeV1(e)
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        res => {
          console.log('POST /CreateNodeV1 successful: ', res);
          this.currentSpinnerMessage = this.spinnerMessages[0];
          if (!this.isInModal) {
            this.router.navigateByUrl('/v2/settings/nodelist');
          } else {
            this.wizardCompleted.emit(true);
          }
          return;
         },
        error => {
          console.log('POST /CreateNodeV1 failed: ', error);
          this.wizardCompleted.emit(true);
          return;
        },
        () => {
          // console.log('--- subscription complete ---');
          
        }
      );
      
    
    // const addNodeV1$ = this.nodeService.addNodeV1(e)
    //   .pipe(
    //     takeWhile(() => this.alive),
    //     switchMap((e) => this.nodeService.addNodeV1(e)),
    //     retry(1)
    //   );

    // const addNode$ = this.nodeService.addNode(e)
    //   .pipe(
    //     takeWhile(() => this.alive),
    //     switchMap((e) => this.nodeService.addNode(e)),
    //     retry(1)
    //   );

    // addNodeV1$.subscribe({
    //     next: res => {
    //       console.log('%c 1: POST /CreateNodeV1 successful: ', 'background: #41ff6b; color: #ff4700;', res);
    //       this.currentSpinnerMessage = this.spinnerMessages[0];
    //       if (!this.isInModal) {
    //         this.router.navigateByUrl('/nodes/main');
    //       } else {
    //         this.wizardCompleted.emit(true);
    //       }
    //     },
    //     error: error => {
    //       console.log('%c 2: POST /CreateNodeV1 failed => retry with /NodesV1: ', 'background: #ff0000; color: #ffffff;', error);
    //       addNode$.subscribe({
    //         next: res => {
    //           console.log('%c 3: POST /NodesV1 successful: ', 'background: #41ff6b; color: #ff4700;', res);
    //           this.currentSpinnerMessage = this.spinnerMessages[0];
    //           if (!this.isInModal) {
    //             this.router.navigateByUrl('/nodes/main');
    //           } else {
    //             this.wizardCompleted.emit(true);
    //           }
    //         },
    //         error: error => {
    //           console.log('%c 4: POST with /NodesV1 failed: ', 'background: #ff0000; color: #ffffff;', error);
    //         }
    //       });
    //     }
    //   });
  }

  openLocationModal() {
    this.locationModal.show();
  }

  openOrganizationModal() {
    this.organizationModal.show();
  }

  addLocation() {
    if (this.locationForm.valid){
      this.setFormControlValuesBasedOnCondition('', ['Latitude', 'Longitude', 'Elevation'], 0, this.locationForm.value.addLocationForm);

      this.locationService.addLocation(this.locationForm.value.addLocationForm)
        .pipe(
          takeWhile(() => this.alive)
        )
        .subscribe(res => {
            this.loadLocations();
            this.locationModal.hide();
        }, error => {
          console.error(error);
        });
    }
  }

  setFormControlValuesBasedOnCondition(condition, propsToCheckArray, defaultValue, formValuesObject) {
    for (const key in formValuesObject) {
      if (formValuesObject.hasOwnProperty(key)) {
        const value = formValuesObject[key];
        const propMatch = propsToCheckArray.includes(key);
        formValuesObject[key] = (propMatch && value === condition) ? defaultValue : value;
      }
    }
    return formValuesObject;
  }

  stepChanged(e) {
    if (e.step == 3) {
      this.loadTemplates();
    } else if (e.step == 5) {
      this.showLocationName = true;
    }
  }

  setNodeProductTypes() {
    this.nodeService.getNodeProductTypes()
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(res => {
        this.productTypes = res['Products'] || [];
      })
  }

  setNodeTypes() {
    this.spinnerService.show();
    this.nodeService.getNodeTypes()
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(res => {
        this.nodeTypes = res['NodeTypes'] || [];
        this.newNode.NodeType = 'Select Node Type';
        this.newNode.NodeDetailType = 'Select Node Detail Type';
        this.spinnerService.hide();
      })
    // forkJoin(this.nodeService.getNodeProductTypes(), this.nodeService.getNodeTypes())
    //   .pipe(
    //     map(res => ( { Products: res[0]['Products'], NodeTypes: res[1]['NodeTypes'] } ) ),
    //     tap(res => {
    //       this.productTypes = res && res.Products ? res.Products : [];
    //       this.nodeTypes = res && res.NodeTypes ? res.NodeTypes : [];
         
    //     })
    //   )
    // .subscribe(
    //   res => {
    //     console.log('%c setNodeTypes response: ', 'background: #41ff6b; color: #ff4700;', res);
    //   }
    // )
  }

  setNodeDetailTypes() {
    this.nodeDetailTypes = this.nodeTypes.filter(nodeType => nodeType.node == this.newNode.NodeType)[0].detail;
  }

  deleteSectionAndMoveFieldsUp(index) {
    this.sections[index].Fields.forEach(field => {
      this.sections[index - 1].Fields.push(field);
    });
    this.sections.splice(index, 1);
  }

  hasVisibleFields(section) {
    let hasVisibleFields = false;
    if(section.Fields && section.Fields.length) {
      section.Fields.forEach(field => hasVisibleFields = (field.visible ? field.visible : hasVisibleFields))
    }
    return hasVisibleFields;
  }

  forbiddenNames(control): { [s: string]: boolean } {
    if (!!control.value && this.forbiddenNodeNames.indexOf(control.value.toLowerCase()) !== -1) {
      return { 'nameAlreadyExists': true };
    }
    return null;
  }
}

export class FileUpload { 
  type;
  humanName;
  validation;
  visible;
  fileTypes;
  value;
  label;
  name;
  public constructor() {
    this.name = '';
    this.value = '';
    this.label = '';
    this.type = 'fileUpload'; 
    this.humanName = '';
    this.validation = { create: {required: false}, analysis: {required: false}, transfer: {required: false}};
    this.visible = true;
    this.fileTypes = [{type: 'png', accepted: true}, {type: 'pdf', accepted: true}, {type: 'jpg', accepted: true},{type: 'jpeg', accepted: true}]
  }
}
