import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatCardModule, MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatTabsModule } from '@angular/material';
import { MccColorPickerModule, MccColorPickerService } from 'material-community-components';

import { ColorPickerComponent } from './color-picker/color-picker.component';
import { FontPickerComponent } from './font-picker/font-picker.component';
import { ImagePickerComponent } from './image-picker/image-picker.component';

const FORMATTER_COMPONENTS = [
  ColorPickerComponent,
  FontPickerComponent,
  ImagePickerComponent,
]

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MccColorPickerModule
  ],
  declarations: [...FORMATTER_COMPONENTS],
  providers: [MccColorPickerService],
  exports: [...FORMATTER_COMPONENTS]
})
export class RetailReportFormattersModule { }
