import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { DndDropEvent } from 'ngx-drag-drop';
import { ModalDirective } from 'ngx-bootstrap';
import { FileUpload } from '../create/create-node.component';
import { MatDialog } from '@angular/material';
import { UiMetadataCustomFieldDialogComponent } from '@app/shared-v2/dialogs/ui-metadata-custom-field-dialog/ui-metadata-custom-field-dialog.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'ui-metadata',
    templateUrl: './ui-metadata.component.html',
    styleUrls: ['./ui-metadata.component.css']
  })
  export class UiMetadataComponent {
    sections;
    customFieldsSectionAdded = false;
    fileUpload;
    fileUploadModalSection;
    addToSection;
    addSelectOption = {};
    DocTypeDefaultOrCustom;

    customOption;

    ADProperties = [
      "Type",
      "Name",
      "Method",
      "Group",
      "Unit",
      "Measure",
      "StartMeasureTime",
      "EndMeasureTime",
      "MeasureTime"
    ]

    customField: any = {
      name: '',
      ADProperty: 'Measure',
      humanName: '',
      label: '',
      validation: {
        create: {
        required: false
      },
      analysis: {
        required: false
      },
      transfer: {
        required: false
      }
    },
      type: "text",
      value: '',
      errorMessage: '',
      visible: true,
      options: []
    };

    inputTypes = ['text', 'number', 'date', 'datetime-local', 'select', 'radio']
    documentTypes = [
      { value: "GCC.Measure", label: 'GCC'},
      { value: "FabricTest.Measure", label: 'Fabric Test'},
      { value: "OekoTex.Measure", label: 'OekoTex'},
      { value: "CommericalInvoice.Measure", label: 'Commercial Invoice'},
      { value: "BCI.Measure", label: 'BCI'},
      { value: "Supima.Measure", label: 'Supima'},
      { value: "Gots.Measure", label: 'GOTS'},
      { value: "BillOfLading.Measure", label: 'Bill Of Lading'}
    ];
    @ViewChild('addFileUploadModal') public addFileUploadModal: ModalDirective;
    @ViewChild('customFieldModal') public customFieldModal: ModalDirective;

    @Input('sections')
    get getSections() {
      return this.sections;
    };
    set getSections(val) {
      this.sections = val;
    }

    @Input() isInModal = false;

    @Output() sectionsChange = new EventEmitter();

    activeTab: any = 'ss0';

    draggable = {
      data: "myDragData",
      effectAllowed: "all",
      disable: false,
      handle: false
    };
    constructor(
      private dialog: MatDialog
    ) {

    }

    ngOnInit() {
      this.sections.forEach((section) => {
        let allVisible = true;
        section.Fields.forEach((field) => {
          if (!field.visible) {
            allVisible = false;
          }
        })
        section.allVisible = allVisible;
      });
    }

    onDragStart(event:DragEvent, section: any, field: any, index) {
        console.log(event, section, field);
        console.log("drag started", JSON.stringify(event, null, 2));
      }

   myIndexOf(o) {    
        for (var i = 0; i < this.sections.length; i++) {
            if (this.sections[i].x == o.x && this.sections[i].y == o.y) {
                return i;
            }
        }
        return -1;
    }
      
      onDragEnd(event:DragEvent, section: any, field, index) {
        console.log("drag ended", JSON.stringify(event, null, 2));
      }
      
      onDraggableCopied(event:DragEvent) {
        
        console.log("draggable copied", JSON.stringify(event, null, 2));
      }
      
      onDraggableLinked(event:DragEvent) {
          
        console.log("draggable linked", JSON.stringify(event, null, 2));
      }
        
      onDraggableMoved(event:DragEvent, item, section) {
        let sectionIndex = this.sections.indexOf(section);
        let fieldIndex = this.sections[sectionIndex].Fields.indexOf( item );
        this.sections[sectionIndex].Fields.splice(fieldIndex, 1)
        console.log("draggable moved", JSON.stringify(event, null, 2));
      }
          
      onDragCanceled(event:DragEvent) {
        
        console.log("drag cancelled", JSON.stringify(event, null, 2));
      }
      
      onDragover(event:DragEvent) {
        
        console.log("dragover", JSON.stringify(event, null, 2));
      }
      
      onDrop(event:DndDropEvent, section) {
        let index = this.sections.indexOf(section);
        this.sections[index].Fields.splice(event.index, 0, event.data);
    
        console.log("dropped", JSON.stringify(event, null, 2));
      }

      onDrop2(event, section) {
        let index = this.myIndexOf(event.data);
        this.sections.splice(index, 1);
        this.sections.splice(event.index, 0, event.data);
      }

      deleteSectionAndMoveFieldsUp(index) {
        this.sections[index].Fields.forEach(field => {
          this.sections[index - 1].Fields.push(field);
        });
        this.sections.splice(index, 1);
      }
    
      hasVisibleFields(section) {
        let hasVisibleFields = false;
        if(section.Fields && section.Fields.length) {
          section.Fields.forEach(field => hasVisibleFields = (field.visible ? field.visible : hasVisibleFields))
        }
        return hasVisibleFields || !section.Fields.length;
      }

      addSection() {
        this.sections.push({SectionName: 'New Section', Fields: []})
      }
    
      addFileUpload(section) {
        this.fileUploadModalSection = section;
        this.fileUpload = new FileUpload();
        this.addFileUploadModal.show();
      }
    
      addInputField() {
        if (!this.customFieldsSectionAdded) {
        this.sections.push({SectionName: 'Custom Fields', Fields: [{humanName: '', label: '', validation: { create: {required: false},analysis: {required: false}, transfer: {required: false}}, type: "text", value: '', errorMessage: '', visible: true, options: []}]})
        this.customFieldsSectionAdded = true;
        } else {
          this.sections.filter(obj => obj.SectionName == 'Custom Fields')[0].Fields.push({humanName: '', label: '', type: "text", validation: { create: {required: false},analysis: {required: false}, transfer: {required: false}}, value: '', errorMessage: '', visible: true, options: []})
        }
      }
    
      resetFileUploadModal() {
        this.addFileUploadModal.hide();
        this.fileUpload = new FileUpload;
        this.fileUploadModalSection = false;
      }
    
      pushFileUploadToSection() {
        // this.fileUpload.name = (this.fileUpload.label ? this.fileUpload.label.replace(/\s/g, ''): this.fileUpload.humanName.replace(/\s/g, '')) + 'Upload';
        if (!this.fileUpload.name.includes('.Measure')) {
          this.fileUpload.name += '.Measure';
        }
        this.fileUploadModalSection.Fields.push(Object.assign({}, this.fileUpload));
        this.resetFileUploadModal();
      }
    
      buildFileTypesString() {
        this.fileUpload.value = '';
        this.fileUpload.fileTypes.forEach(type =>{
          this.fileUpload.value += type.accepted ? '.' + type.type + '/' : '';
        });
        this.fileUpload.value = this.fileUpload.value.substring(0, this.fileUpload.value.length -1);
      }

      setSectionFieldsVisible(sections) {
        var allTrue = true;
        sections[0].Fields.forEach((field) => {
          if (!field.visible) {
            allTrue = false;
          }
        })
        if (allTrue) {
          sections[0].Fields.forEach((field) => {
            field.visible = false;
          })
        } else {
          sections[0].Fields.forEach((field) => {
            field.visible = true;
          })
        }
      }

      setAllRequired(type, section) {
        var allTrue = true;
        section.Fields.forEach((field) => {
          if (!field.validation[type].required) {
            allTrue = false;
          }
        })
        if (allTrue) {
          section.Fields.forEach((field) => {
            field.validation[type].required = false;
          })
        } else {
          section.Fields.forEach((field) => {
            field.validation[type].required = true;
          });
        }
      }

      allRequiredChecked(section, type) {
        let allTrue = true;
        section.Fields.forEach((field) => {
          if (!field.validation[type].required) {
              allTrue = false;
          }
        })
        return allTrue;
      }

      openUiMetaDataCustomFieldDialog() {
        let dialogRef = this.dialog.open(UiMetadataCustomFieldDialogComponent, {
          data: {}
        });

        dialogRef.afterClosed()
          .pipe(
            take(1)
          )
          .subscribe(
            res => {
              console.log('UiMetaData Custom Field dialog closed: ', res);
            }
          )
      }

      resetCustomFieldModal() {
        this.customField = {ADProperty: 'Measure', name: '', humanName: '', label: '', validation: { create: {required: false},analysis: {required: false}, transfer: {required: false}}, type: "text", value: '', errorMessage: '', visible: true, options: []};
        this.customFieldModal.hide()
      }

      pushCustomFieldToSection() {
        this.customField.humanName = this.customField.name;
        if (this.customField.type == 'datetime-local') {
          this.customField.name = this.customField.name.replace(/\s/g, '') + '.' + this.customField.ADProperty + 'Time';
        } else {
          this.customField.name = this.customField.name.replace(/\s/g, '') + '.' + this.customField.ADProperty;
        }
        delete this.customField.ADProperty;

        console.log('custom field: ', JSON.parse(JSON.stringify(this.customField)));

        this.addToSection.Fields.push(JSON.parse(JSON.stringify(this.customField)));
        this.resetCustomFieldModal();
      }
    }