import { LocationRoutes } from '@app/core/api-routes.enum';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Router} from "@angular/router";

@Injectable()
export class HelpService {

    BASE_URL = environment.baseApiUrl;
    tutorials = [
        { name: 'Creating Node Templates', route:'v2/settings/create-node-template', storageKey: 'create-node-template.component.intro!', id: 'create-node-template' },
        { name: 'Dashboard Overview', route:'v2', storageKey: 'home.component.intro!', id: 'home' }

    ];

    constructor(private http: HttpClient, private router: Router) { }

   getTutorials() {
        return Observable.of(this.tutorials)
   }

   addTutorial(tutorial) {
        this.tutorials.push(tutorial);
   }

   startTutorial(tutorial) {
        localStorage.removeItem(tutorial.storageKey);
        this.router.navigate([tutorial.route]);
   }
}