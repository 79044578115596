import { Component, Input, SimpleChanges, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'overlay-page',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.css']
})
export class OverlayComponent {
  @ViewChild('overlay') overlay: ElementRef;
  @Input() visible: boolean;
  @Input() opacity: number;
  @Input() backgroundColor: string[];
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    let background = this.backgroundColor || ['255','255','255']
    let opacity = this.opacity || 1;
    let backgroundColor = "rgba(" + background[0] + ", " + background[1] + ", " + background[2] + ", " + opacity + ")";
    this.overlay.nativeElement.style.backgroundColor = backgroundColor;
    if (!changes.visible.currentValue) {
      this.overlay.nativeElement.style.width = "0";
    } else {
      this.overlay.nativeElement.style.width = "100%";
    }
  }


  onClose() {
    let o = {closed: true};
    this.close.emit(o);
  }
}
