import { environment } from "@env/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ProductRoutes } from "@app/core-v2/api-routes-v2.enum";
import { IProductSummaryDto } from "@app/shared-v2/models/product/product.interface";

@Injectable()
export class ProductService {
  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) {}

  //New Routes for Dtos
  getProductSummaryDtos(): Observable<IProductSummaryDto[]> {
    return this.http.get<IProductSummaryDto[]>(
      this.BASE_URL + ProductRoutes.BASE_URL + ProductRoutes.PRODUCT_LIST_SUMMARY_DTOS
    );
  }

  // getPersonTypeDtos(): Observable<IPersonTypeDto[]> {
  //   return this.http.get<IPersonTypeDto[]>(
  //     this.BASE_URL + PersonsRoutes.BASE_URL + PersonsRoutes.PERSONTYPEDTOS
  //   );
  // }

  // getPersonGroupDtos(): Observable<IPersonGroupDto[]> {
  //   return this.http.get<IPersonGroupDto[]>(
  //     this.BASE_URL + PersonsRoutes.BASE_URL + PersonsRoutes.PERSONGROUPDTOS
  //   );
  // }

  // getPersonDetailById(personId: string, personOrganizationId: string): Observable<IPersonDetailDto> {
  //   let params = new HttpParams();
  //   params = params.append('_personId', personId);
  //   params = params.append('_personOrganizationId', personOrganizationId);
  //   return this.http.get<IPersonDetailDto>(
  //     this.BASE_URL + PersonsRoutes.BASE_URL + PersonsRoutes.PERSON_DETAIL_DTO, {params:params}
  //   );
  // }


  // addPerson(person): Observable<any> {
  //   return this.http.post<any>(this.BASE_URL + PersonsRoutes.BASE_URL + "/add", person);
  // }

  // deletePerson(person: any): Observable<any> {
  //   return this.http.delete<any>(this.BASE_URL + PersonsRoutes.BASE_URL + '/' + person.id)
  // }

  // editPerson(personDetailDto): Observable<any> {
  //   return this.http.put<any>(this.BASE_URL + PersonsRoutes.BASE_URL + '/update/' + personDetailDto.Id, personDetailDto)
  // }

}
