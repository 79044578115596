import { environment } from "@env/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class DataService {
  BASE_URL = environment.baseApiUrl;
  DATA_URL = "/data";
  LOT_URL = "/lot";
  ASSET_URL = "/asset";

  constructor(private http: HttpClient) {}

  getLotData(params) {
    return this.http.get(
      this.BASE_URL + this.LOT_URL + this.DATA_URL + "/" + params
    );
  }

  //need getAssetData for the requested titles on LucidChart
  // getAssetOverviewData(params) {
  //   return this.http.get(this.BASE_URL + this.ASSET_URL + this.DATA_URL + '/' + params)
  // }
}
