import { AnalyticData } from '@app/shared-v2/models/analytic-data.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { takeWhile, take, map, mergeMap, tap, switchMap } from 'rxjs/operators';
import { DocumentService } from '@app/core-v2/services/document.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { timer, BehaviorSubject, of, forkJoin } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { UploadService, ImageService } from '@app/core-v2/services';
import * as _ from 'lodash';

@Component({
  selector: 'bext-videos-dialog',
  templateUrl: './videos-dialog.component.html',
  styleUrls: ['./videos-dialog.component.scss']
})
export class VideosDialogComponent implements OnInit {

  @ViewChild('file') file
  public files: Set<File> = new Set()

  private alive = true;

  private _isLoading = false;
  get loading() {
    return this._isLoading;
  }
  set loading(bool) {
    this._isLoading = bool;
  }

  private _showAddDocumentForm = false;
  get showAddDocumentForm() {
    return this._showAddDocumentForm;
  }
  set showAddDocumentForm(bool) {
    this._showAddDocumentForm = bool;
  }

  private _showEditDocumentForm = false;
  get showEditDocumentForm() {
    return this._showEditDocumentForm;
  }
  set showEditDocumentForm(bool) {
    this._showEditDocumentForm = bool;
  }

  private _selectedDocTemplate;
  get selectedDocTemplate() {
    return this._selectedDocTemplate;
  }
  set selectedDocTemplate(doc) {
    this._selectedDocTemplate = doc;
  }

  private _selectedDocument;
  get selectedDocument() {
    return this._selectedDocument;
  }
  set selectedDocument(doc) {
    this._selectedDocument = doc;
  }

  public isUpdating = false;
  public isSavingDocument = false;
  public formValid = false;
  public runFormValidation$ = new BehaviorSubject<boolean>(false);
  public newDocumentForm;
  public documents: any[];
  public documentsLoading = false;
  public docTypeForm: FormGroup;
  public docTemplates: any = null;
  public progress;
  public canBeClosed = true
  public showCancelButton = true
  public uploading = false
  public uploadSuccessful = false
  public uploadedVideos: any[] = [];
  public uploadsVideoHistory: any[] = [];
  public headerContent: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideosDialogComponent>,
    private documentService: DocumentService,
    private uploadService: UploadService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private imageService: ImageService
  ) {
    // console.log('dialog data: ', this.data);
    this.docTypeForm = this.fb.group({
      type: new FormControl(null, [Validators.required])
    })
  }

  get docTypeFormCtrl() {
    return this.docTypeForm.get('type') as FormControl;
  }

  ngOnInit() {
    this.loading = true;
    this.headerContent = this.data.dialogHeaderContent;
    this.documentService.getVideoDocuments(this.data.entityType, this.data.id)
      .pipe(
        takeWhile(() => this.alive),
        map((docs: any[]) => {
          if (isNullOrUndefined(docs)) return [];
          return docs.map((doc) => {
            doc['template'] = null;
            return doc;
          })
        })
      )
      .subscribe(
        res => {

          this.documents = res.map((doc) => {
            if (Array.isArray(doc.Images) && doc.Images.length) {
              doc.Images.forEach((image) => {
                image['ImageThumbnailURL'] = this.imageService.getThumbnailUrl(image.ImageURL)
                image['ImageExtension'] = this.imageService.getImageExtension(image.ImageURL)
              });
            }
            return doc;
          });
          this.loading = false;
        },
        error => {
          console.log('%c GET documentsV2 - document dialog for - ' + this.data.entityType + ': ', 'background: #fae552; color: #323232;', error);
          this.loading = false;
        }
      )

  }

  docTypeSelectionChange(e) {
    console.log('DOC TYPE SELECTION CHANGE: ', e);
    this.selectedDocTemplate = undefined;
    this.progress = undefined;
    this.canBeClosed = true;
    this.showCancelButton = true;
    this.uploading = false;
    this.uploadSuccessful = false;
    this.files = new Set();
    this.selectedDocTemplate = e.value;
  }

  startNewDocumentProcess() {
    this.showAddDocumentForm = true;
    if (this.docTemplates) return;
    this.documentsLoading = true;

    timer(1000)
      .pipe(
        takeWhile(() => this.alive),
        map(() => VideoTemplate)
      )
      .subscribe(
        res => {
          console.log('%c Video template: ', 'background: #fae552; color: #323232;', res);
          this.documentsLoading = false;
          this.docTemplates = res;
          this.docTypeFormCtrl.setValue(this.docTemplates[0])
          this.docTypeSelectionChange({ value: this.docTemplates[0] })
        },
        error => {
          console.log('GET document templates - error: ', error);
        }
      );

  }

  getErrorMessage() {
    if (this.docTypeFormCtrl.hasError('required')) {
      return 'please make a selection';
    }
  }

  editDocument(doc) {
    this.showEditDocumentForm = false;
    this.selectedDocument = undefined;
    doc.UiData[0].Sections = doc.UiData[0].Sections.map((section) => {
      section.Fields = section.Fields.map((field) => {
        let documentDataIndex = doc.DocumentDatas.findIndex(d => (d.Name === field.name || d.Key === field.name));
        let analyticDataObject = doc.DocumentDatas[documentDataIndex];
        let analyticDataObjectValue = analyticDataObject ? analyticDataObject[field.name.split('.')[field.name.split('.').length - 1]] : undefined;
        field.value = analyticDataObjectValue ? analyticDataObjectValue : field.value;
        return field;
      });
      return section;
    });
    this.selectedDocument = doc;
    setTimeout(() => {
      this.showEditDocumentForm = true;
    }, 300);
    // this.runFormValidation$.next(true);
  }

  onAddDocumentFormChanges(values) {
    this.newDocumentForm = values;
  }

  onFormValidityChanges(e) {
    this.formValid = e;
  }

  onEditFormChanges(values) {
    let documentDatas = this.selectedDocument.DocumentDatas;
    Object.keys(values).forEach((k) => {
      let keyMatch = (datum) => (datum.Name === k || datum.Key === k);
      let adIndex = documentDatas.findIndex(keyMatch);
      let keyValueName = k.split('.')[k.split('.').length - 1];
      let path = `DocumentDatas[${adIndex}].${keyValueName}`;
      _.set(this.selectedDocument, path, values[k]);
    });

  }

  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
      }
    }
    this.file.nativeElement.value = '';
    this.uploadFiles()
  }

  uploadFiles(selectedDocument?) {

    // IF STATEMENT allows for disabling Add More Files
    // if (this.uploadSuccessful) {
    //   return;
    // }

    // set the component state to "uploading"
    this.uploading = true;

    // start the upload and save the progress map
    this.progress = this.uploadService.uploadV2(this.files);
    for (const key in this.progress) {
      this.progress[key].progress
        .subscribe(
          val => {
            if (typeof val === 'object') {
              if (val.status === 200 && val.body) {
                val.body['ImageThumbnailURL'] = this.imageService.getThumbnailUrl(val.body.ImageURL)
                val.body['ImageExtension'] = this.imageService.getImageExtension(val.body.ImageURL)
                console.log('response: ', val.body);
                this.uploadedVideos.push(val.body);
                this.uploadsVideoHistory = [...this.uploadedVideos];
                if (selectedDocument) {
                  this.selectedDocument.Videos.push(val.body)
                }
                console.log('updated images array: ', this.uploadedVideos);
                console.log('this.selectedDocument: ', this.selectedDocument);
              }
            }
            else {
              console.log('progress: ', val)
            }
          },
          error => {
            this.canBeClosed = true;

            // ... the upload was successful...
            this.uploadSuccessful = true;

            // ... and the component is no longer uploading
            this.uploading = false;

            // ... and reset progress
            this.progress = undefined;

            // ... and reset file Set
            this.files.clear();
            let snackBarRef = this.openSnackBar('failed to upload file', 'CLOSE');
          }
        );
    }

    // convert the progress map into an array
    let allProgressObservables = [];
    for (let key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
    }

    // Adjust the state variables

    // The dialog should not be closed while uploading
    this.canBeClosed = false;

    // Hide the cancel-button
    this.showCancelButton = false;

    // When all progress-observables are completed...
    forkJoin(allProgressObservables).subscribe(end => {
      // ... the dialog can be closed again...
      this.canBeClosed = true;

      // ... the upload was successful...
      this.uploadSuccessful = true;

      // ... and the component is no longer uploading
      this.uploading = false;

      // ... and reset progress
      this.progress = undefined;

      // ... and reset file Set
      this.files.clear();

    });
  }

  onRemoveFile(file) {
    this.files.forEach((f) => {
      if (f.name === file.name) this.files.delete(file);
    })
  }

  saveNewDocument() {

    if (!this.formValid) {
      this.runFormValidation$.next(true);
      return;
    }

    let bextDocument = new BextDocumentPayload();
    Object.keys(this.newDocumentForm).forEach((key) => {
      let value = this.newDocumentForm[key];
      bextDocument.addAnalyticData(key, value);
    });
    bextDocument.IsVideo = this.selectedDocTemplate.BextDocumentTemplateType === 'Video';
    bextDocument.Images = [...this.uploadedVideos];
    bextDocument.DocumentType = this.selectedDocTemplate.BextDocumentTemplateType;
    bextDocument.DocumentTemplateId = this.selectedDocTemplate.id;
    bextDocument.UiData = [...this.selectedDocTemplate.UiData];
    bextDocument.UiData[0].Sections.forEach((section) => {
      section.Fields.forEach((field) => {
        field.value = this.newDocumentForm[field.name];
      });
    });
    console.log('%c bextdocument payload: ', 'background: #41ff6b; color: #ff4700;', bextDocument);
    this.documentService.addDocument(this.data.entityType, this.data.id, bextDocument)
      .pipe(
        take(1)
      )
      .subscribe(
        (res: any) => {
          console.log('%c POST document success: ', 'background: #41ff6b; color: #ff4700;', res)
          let updatedDocument = res;
          if (Array.isArray(res.Images) && res.Images.length) {
            updatedDocument.Images = res.Images.map((image) => {
              image['ImageThumbnailURL'] = this.imageService.getThumbnailUrl(image.ImageURL)
              image['ImageExtension'] = this.imageService.getImageExtension(image.ImageURL)
              return image;
            });
          }
          this.documents = [...this.documents, updatedDocument];
          this.cancelAddDocument();
        },
        error => console.log('%c POST document failed: ', 'background: #ff0000; color: #ffffff;', error)
      )

  }

  saveDocument() {
    this.isSavingDocument = true;
    this.documentService.editDocument(this.selectedDocument.id, this.selectedDocument)
      .subscribe(
        (res: any) => {
          let originalDocumentIndex = this.documents.findIndex(doc => doc.id === this.selectedDocument.id);
          let updatedDocument = res;
          if (Array.isArray(res.Images) && res.Images.length) {
            updatedDocument.Images = res.Images.map((image) => {
              image['ImageThumbnailURL'] = this.imageService.getThumbnailUrl(image.ImageURL)
              image['ImageExtension'] = this.imageService.getImageExtension(image.ImageURL)
              return image;
            });
          }
          this.documents.splice(originalDocumentIndex, 1, updatedDocument);
          this.isSavingDocument = false;
          this.cancelEditDocument();
        },
        error => {
          this.isSavingDocument = false;
        }
      )
  }

  deleteDocument(doc) {
    let snackBarRef = this.openSnackBar('Are you sure? Action cannot be undone.', 'DELETE');
    snackBarRef.afterDismissed()
      .pipe(
        take(1),
        switchMap((dismissal) => {
          if (dismissal.dismissedByAction) {
            return this.documentService.deleteDocument(this.data.entityType, this.data.id, doc.id);
          } else {
            return of(dismissal);
          }
        })
      )
      .subscribe(
        (res: any) => {
          this.documents = this.documents.filter((d) => d.id !== doc.id);
        },
        error => console.log('%c snackbar dismissal failed: ', 'background: #ff0000; color: #ffffff;', error),
        () => {
          this.isUpdating = false;
        }
      )
  }

  deleteVideo(vid) {
    vid['deleting'] = true;
    this.uploadService.deleteVideo(vid.id)
      .subscribe(
        res => {
          let imageIndex = this.uploadedVideos.findIndex(i => i.id === vid.id);
          this.uploadedVideos.splice(imageIndex, 1);
          this.uploadedVideos = [...this.uploadedVideos];
          // if (this.uploadedVideos.length === 0) {
          //   this.uploadSuccessful = false;
          // }
        },
        error => {
          console.log('%c delete image failed: ', 'background: #ff0000; color: #ffffff;', error);
          // Remove document EVEN IF error occurs - strictly for testing
          this.uploadedVideos = this.uploadedVideos.filter((v) => v.id !== vid.id);
          // if (this.uploadedVideos.length === 0) {
          //   this.uploadSuccessful = false;
          // }
          //
          vid['deleting'] = false;
        }
      )
  }

  openSnackBar(message: string, action: string, config = {}): MatSnackBarRef<SimpleSnackBar> {
    let snackBarRef = this._snackBar.open(message, action, {
      duration: config['duration'] ? config['duration'] : 5000,
      data: config['data'] ? config['data'] : undefined
    });

    return snackBarRef;
  }

  closeDialogRef() {
    this.dialogRef.close();
  }


  submitDialogRef(obj) {
    this.dialogRef.close(obj);
  }

  cancelAddDocument() {
    this.showAddDocumentForm = false;
    this.formValid = false;
    this.progress = undefined;
    this.canBeClosed = true;
    this.showCancelButton = true;
    this.uploading = false;
    this.uploadSuccessful = false;
    this.files = new Set();
    this.uploadsVideoHistory = [...this.uploadedVideos];
    this.uploadedVideos = [];
  }

  cancelEditDocument() {
    this.selectedDocument = undefined;
    this.showEditDocumentForm = false;
    this.formValid = false;
    this.progress = undefined;
    this.canBeClosed = true;
    this.showCancelButton = true;
    this.uploading = false;
    this.uploadSuccessful = false;
    this.files = new Set();
    this.uploadsVideoHistory = [...this.uploadedVideos];
    this.uploadedVideos = [];
  }

  ngOnDestroy(): void {
    this.alive = false;
    // console.log('DOCUMENTS DIALOG DESTROYED');
    // this.documentDialogService.destroy();
  }

}

class BextDocumentPayload {
  public DocumentName: string;
  public DocumentType: string;
  public DocumentTemplateId: string;
  public Images: any[] = [];
  public DocumentDatas: any[] = [];
  public UiData: any;
  public IsVideo: boolean;

  constructor() { }

  addAnalyticData(name, value, label?) {
    let ad = new AnalyticData(name, value, '', label);
    this.DocumentDatas.push(ad);
  }
}

const VideoTemplate = [
  {
    "type": "BextDocumentTemplate",
    "id": "31ecfb9b-644c-40dd-bbdd-cb7a5a6655e8",
    "BextDocumentTemplateName": "Video",
    "Products": [
      {
        "id": null,
        "Label": null,
        "ProductName": "All",
        "TokenName": null,
        "ProductOwnerType": "BextMaster",
        "ProductDatas": [],
        "Images": [],
        "type": null,
        "LocationId": null,
        "DateCreated": "2020-03-10T20:52:44.1002721Z",
        "DateModified": "2020-03-10T20:52:44.1002734Z",
        "UiData": null,
        "BextDocumentSummaries": [],
        "EntityDocument": null,
        "EntityPerson": null,
        "OwnerOrganizationId": null,
        "Deleted": false
      }
    ],
    "BextDocumentTemplateType": "Video",
    "OwnerId": null,
    "BaseTemplate": true,
    "UiData": [
      {
        "Type": "Document",
        "Version": "V2",
        "Sections": [
          {
            "SectionName": "Video Information",
            "Fields": [
              {
                "type": "input",
                "label": "Video Name",
                "inputType": "text",
                "name": "DocumentName.Measure",
                "value": null,
                "validations": [
                  {
                    "name": "required",
                    "validator": "Validators.required",
                    "message": "Name Required"
                  },
                  {
                    "name": "pattern",
                    "validator": "^[A-Za-z0-9,-_.\\s]+$",
                    "message": "Cannot be empty"
                  }
                ],
                "visible": false,
                "options": []
              }
            ]
          }
        ]
      }
    ],
    "LocationId": null,
    "DateCreated": "2019-04-03T00:07:00.9483316Z",
    "DateModified": "2019-04-03T00:07:00.9483328Z",
    "BextDocumentSummaries": [],
    "EntityDocument": null,
    "EntityPerson": null,
    "OwnerOrganizationId": "cccccccc-cccc-cccc-cccc-cccccccccccc",
    "Deleted": false
  }
]