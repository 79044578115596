export class AnalyticData {
  AttributeTypeId: number | null;
  Type?: string | null;
  Label?: string | null;
  Key?: string | null;
  Default: boolean | null;
  Name: string | null;
  Method?: string | null;
  Group?: string | null;
  Unit?: string | null;
  Measure?: string | null;
  StartMeasureTime?: any;
  EndMeasureTime?: any;
  MeasureTime?: any;
  StartMeasureTimeDt: string;
  EndMeasureTimeDt: string;
  MeasureTimeDt: string;

  constructor(Name, Value, Key?, Label?, setValue = true) {
    this.AttributeTypeId = 0;
    this.Type = null;
    this.Label = null;
    this.Key = Label ? Label : Key ? Key : Name;
    this.Default = false;
    this.Name = !Key ? Name : Key;
    this.Method = null;
    this.Group = null;
    this.Unit = null;
    this.Measure = null;
    this.StartMeasureTime = null;
    this.EndMeasureTime = null;
    this.MeasureTime = null;
    this.StartMeasureTimeDt = "";
    this.EndMeasureTimeDt = "";
    this.MeasureTimeDt = "";
    if (setValue) this.setFieldValue(this, Value);
  }

  private setFieldValue(self, value) {
    let key = self.Key;
    let keyArray = key.split('.');
    let field = keyArray[keyArray.length - 1];
    let fieldValue = value;
    if (self.hasOwnProperty(field)) self[field] = fieldValue;
  }
}

export interface IAnalyticData {
  AttributeTypeId: number | null;
  Type?: string | null;
  Label?: string | null;
  Key?: string | null;
  Default: boolean | null;
  Name: string | null;
  Method?: string | null;
  Group?: string | null;
  Unit?: string | null;
  Measure?: string | null;
  StartMeasureTime?: any;
  EndMeasureTime?: any;
  MeasureTime?: any;
  StartMeasureTimeDt: string;
  EndMeasureTimeDt: string;
  MeasureTimeDt: string;
}
