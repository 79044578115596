import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../../../models/field-config.interface';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bext-ngx-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class NgxButtonComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }
}
