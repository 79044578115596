import { BehaviorSubject, Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { takeWhile, tap, distinctUntilChanged, debounceTime, skipWhile, skip } from 'rxjs/operators';
import { NgxFormService } from '@app/core-v2/services/ngx-form.service';

@Component({
  exportAs: 'ngxForm',
  selector: 'ngx-form',
  templateUrl: './ngx-form.component.html',
  styleUrls: ['./ngx-form.component.scss']
})
export class NgxFormComponent implements OnInit {

  private alive = true;
  private _emitInitialFormValues = false;
  private _multiSectional = false;
  private _tabLayout = false;
  private _readOnly = false;

  @Input('runValidation') runValidation$: Observable<any>;
  @Output('formChanges') formChanges = new EventEmitter<any>();
  @Output('formValidity') formValidity = new EventEmitter<any>();
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  @Input() config;

  @Input('emitInitialFormValues')
  set emitInitialFormValues(bool: boolean) {
    this._emitInitialFormValues = bool;
  }
  get emitInitialFormValues() {
    return this._emitInitialFormValues;
  }
  
  @Input('multiSectional')
  set multiSectional(bool) {
    this._multiSectional = bool;
  }
  get multiSectional() {
    return this._multiSectional;
  }

  @Input('tabLayout')
  set tabLayout(bool) {
    this._tabLayout = bool;
  }
  get tabLayout() {
    return this._tabLayout;
  }

  @Input('readOnly')
  set readOnly(bool) {
    this._readOnly = bool;
  }
  get readOnly() {
    return this._readOnly;
  }

  basicForm = true;

  form: FormGroup;

  constructor(private ngxFormService: NgxFormService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.runValidation && !changes.runValidation.firstChange) {
      
    }
  }

  ngOnInit() {
    if (this.readOnly) {
      console.log('%c READ ONLY MODE: ', 'background: #fae552; color: #323232;', this.config);
    }
    if (this.multiSectional) {
      this.form = this.ngxFormService.createFormV2(this.config, this.readOnly);
    }
    else if (Array.isArray(this.config)) {
      this.form = this.ngxFormService.createForm(this.config);
      if (this.form && this.emitInitialFormValues) {
        this.formChanges.emit(this.form.value)
      }
    }
    else if (typeof this.config === 'object') {
      this.basicForm = false;
      this.form = this.ngxFormService.createControlFromFormattedUiData(this.config.sections);
    }
    else {
      // add logic if form config is not provided
    }


    this.ngxFormService.formValueChanges
      .pipe(
        takeWhile(() => this.alive),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(
        res => {
          this.formChanges.emit(res);
          this.formValidity.emit(this.form.valid);
        }
      );

    if (this.runValidation$) {
      this.runValidation$
        .pipe(
          takeWhile(() => this.alive),
          skip(1)
        )
        .subscribe(
          res => {
            console.log('%c run validation: ', 'background: #fae552; color: #323232;', res);
            if (res) this.validateAllFormFields(this.form);
           },
          error => {
            console.log('run form validation Observable - error: ', error);
          }
        );
    }
      
      
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    
    if (this.form.valid) {
      let payload = this.ngxFormService.tempFileUploadFields ? this.ngxFormService.patchFileUploadFormFields(this.form.value) : this.form.value;
      this.submit.emit(payload);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.controls[field];
      control.markAsTouched({ onlySelf: true });
    });
  }

  ngOnDestroy() {
    console.log('ngx form destroyed');
    this.form = undefined;
    this.alive = false;
  }

}
