import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { GoogleMapService } from '@app/core/services/google-map.service';
import { MapStyleService } from '@app/features/maps/shared/map-style.service';
import { GeoLocationService } from '@app/core/services/geo-location.service';
import { Conversion } from '@app/shared/utils/conversion-utils';
import { takeWhile } from 'rxjs/operators';

declare var google: any;

@Component({
  selector: 'sa-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})
export class LocationMapComponent implements OnInit {

  private alive = true;

  @Output('mapLoaded') mapLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output('foundLocation') foundLocation: EventEmitter<any> = new EventEmitter<any>();

  constructor(private googleMapService: GoogleMapService, private styleService: MapStyleService, private geoLocationService: GeoLocationService) {
     
  }

  public styles = [
    { key: 'colorful', name: 'Colorful', url: '/maps/colorful.json' },
    { key: 'greyscale', name: 'Greyscale', url: '/maps/greyscale.json' },
    { key: 'metro', name: 'Metro', url: '/maps/metro.json' },
    { key: 'mono-color', name: 'Mono-color', url: '/maps/mono-color.json' },
    { key: 'monochrome', name: 'Monochrome', url: '/maps/monochrome.json' },
    { key: 'nightvision', name: 'Nightvision', url: '/maps/nightvision.json' },
    { key: 'nightvision-highlight', name: 'Nightvision Highlight', url: '/maps/nightvision-highlight.json' },
    { key: 'old-paper', name: 'Old Paper', url: '/maps/old-paper.json' }
  ];

  public activeStyle: any;

  public map: any;


  ngOnInit() {
    this.initGmap(this.foundLocation, this.geoLocationService, this.alive)
  }

  initGmap(foundLocationEventEmitter, geoLocationService, alive) {
    this.googleMapService.loadAPI.then((google: any) => {

      var map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: 39.739236, lng: -104.984862 },
        zoom: 6,
        mapTypeControl: false,
        fullScreenControl: false
      });
      var card = document.getElementById('pac-card');
      var input = document.getElementById('pac-input');
      var types = document.getElementById('type-selector');
      var strictBounds = document.getElementById('strict-bounds-selector');

      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);

      var autocomplete = new google.maps.places.Autocomplete(input);

      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo('bounds', map);
      // console.log('%c google map and autocomplete: ', 'background: #fae552; color: #323232;', map, autocomplete);
      // Set the data fields to return when the user selects a place.
      autocomplete.setFields(
        ['address_components', 'geometry', 'icon', 'name']);

      var infowindow = new google.maps.InfoWindow();
      var infowindowContent = document.getElementById('infowindow-content');
      infowindow.setContent(infowindowContent);
      var marker = new google.maps.Marker({
        map: map,
        anchorPoint: new google.maps.Point(0, -29)
      });

      autocomplete.addListener('place_changed', function () {
        infowindow.close();
        marker.setVisible(false);
        var place = autocomplete.getPlace();
        console.log('autocomplete place - : ', place);
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);  // Why 17? Because it looks good.
        }
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);

        var address = '';
        if (place.address_components) {
          address = [
            (place.address_components[0] && place.address_components[0].short_name || ''),
            (place.address_components[1] && place.address_components[1].short_name || ''),
            (place.address_components[2] && place.address_components[2].short_name || ''),
            (place.address_components[4] && place.address_components[4].short_name + ',' || ''),
            (place.address_components[5] && place.address_components[5].short_name || ''),
            (place.address_components[6] && place.address_components[6].short_name || '')
          ].join(' ');
        }

        var createFormModel = function (place) { 

          var model = {
            Name: place.name,
            Country: '',
            City: '',
            State: '',
            Latitude: place.geometry.location.lat(),
            Longitude: place.geometry.location.lng(),
            Elevation: undefined,
            ElevationUnit: 'feet',
            Address: '',
            Description: ''
          };

          for (let i = 0; i < place.address_components.length; i++) {
            const addressComp = place.address_components[i];
            switch (addressComp.types[0]) {
              case "street_number":
                model.Address += addressComp.long_name;
                break;

              case "route":
                model.Address += ' ' + addressComp.long_name;
                break;

              case "locality":
                model.Address += ' ' + addressComp.long_name;
                model.City = addressComp.long_name;
                break;

              case "administrative_area_level_1":
                model.Address += ', ' + addressComp.short_name;
                model.State = addressComp.long_name;
                break;

              case "country":
                model.Address += ' ' + addressComp.short_name;
                model.Country = geoLocationService.locationCountryNameMapping(addressComp.long_name);
                break;

              case "postal_code":
                model.Address += ' ' + addressComp.short_name;
                break;

              default:
                break;
            }
          }
          return model;
        };

        var formModel = createFormModel(place);
        var elevationInFeet: number;
        var elevationInMeters: number;

        geoLocationService.getGeoData(formModel.Latitude, formModel.Longitude)
          .pipe(
            takeWhile(() => alive)
          )
          .subscribe(
            res => {
              console.log('get GEO data: ', res);
              elevationInMeters = res.elevations[0].elevation;
              elevationInFeet = Conversion.metersToFeet(elevationInMeters);
              return;
            },
            error => {
              console.log('error: ', error);
              return;
            },
            () => {
              // console.log('--- subscription complete ---');
              infowindowContent.children['place-icon'].src = place.icon;
              infowindowContent.children['place-name'].textContent = place.name;
              infowindowContent.children['place-address'].textContent = formModel.Address;
              infowindowContent.children['place-coords'].textContent = formModel.Latitude + ',' + formModel.Longitude;
              if (elevationInFeet) {
                formModel.Elevation = elevationInFeet;
                infowindowContent.children['place-elevation'].textContent = Math.round(elevationInFeet) + ' ft (' + elevationInMeters + 'm)';
              } else {
                formModel.Elevation = '';
              }
              infowindow.open(map, marker);
              foundLocationEventEmitter.emit(formModel)
            }
          );
      });
      // }
      // Sets a listener on a radio button to change the filter type on Places
      // // Autocomplete.
      // function setupClickListener(id, types) {
      //   var radioButton = document.getElementById(id);
      //   radioButton.addEventListener('click', function () {
      //     autocomplete.setTypes(types);
      //   });
      // }

      // setupClickListener('changetype-all', []);
      // setupClickListener('changetype-address', ['address']);
      // setupClickListener('changetype-establishment', ['establishment']);
      // setupClickListener('changetype-geocode', ['geocode']);

      // document.getElementById('use-strict-bounds')
      //   .addEventListener('click', function (e) {
      //     console.log('Checkbox clicked! New state=' + e.target['checked']);
      //     autocomplete.setOptions({ strictBounds: e.target['checked']});
      //   });
      this.mapLoaded.emit({map: map, loaded: true});
    },
    error => {
      console.log('error getting gmap: ', error);
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.alive = false;
  }

}
