import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotMarkerReportComponent } from './lot-marker-report.component';
import {SharedModule} from "@app/shared/shared.module";
import {MaterialModule} from "@app/shared/angular-material.module";
import {MarkerService} from "@app/core/services/backend/marker.service";
import {LotService} from "@app/core/services/backend/lot.service";
import {NodeService} from "@app/core/services/backend/node.service";

@NgModule({
  declarations: [LotMarkerReportComponent],
  imports: [
    CommonModule,
      SharedModule,
      MaterialModule
  ],
  entryComponents: [LotMarkerReportComponent],
  providers: [MarkerService, LotService, NodeService]
})
export class LotMarkerReportModule { }
