export enum AccountRoutes {
  BASE_URL = '/accounts',
}

export enum AppUserRoutes {
  BASE_URL = '/appusers',
}

export enum LocationRoutes {
  BASE_URL = '/locations',
}

export enum LotRoutes {
  BASE_URL = '/lotsV1',
  ABSORBED_URL = '/absorbed/absorbed',
  LOTS_SUMMARY_URL = '/lotsummary',
  TRANSFER_URL = '/transfer',
  TRANSFER_SPLIT_URL = '/transfersplit',
  TRANSFER_LOT_LIST_URL = '/gettransferlotlist',
  CREATE_TRANSFER_URL = '/CreateAndTransfer',
}

export enum MarkerRoutes {
  BASE_URL = '/marker',
  DELETEMARKER_URL = '/deleteLotMarkers',
  EDITMARKER_URL = '/editLotMarkers',
}

export enum NodeRoutes {
  BASE_URL = '/nodesV1',
  NODE_TEMPLATE_URL = '/NodeTemplate',
  NODE_LOTS_URL = '/nodelot',
}

export enum OpenCageRoutes {
  BASE_URL = 'https://api.opencagedata.com/geocode/v1',
}

export enum OrganizationRoutes {
  BASE_URL = '/organizations',
}

export enum ReportRoutes {
  BASE_URL = '/reports',
  LOTPREVIOUSNEXT_URL = '/lotnextprevious01V1',
  LOTTIMELINE_URL = '/timeline01',
  LOTTIMELINE_MARKER_URL = '/timelinemarker01',
  NESPRESSO_CAPSULE_RECORDS_URL = '/nespressoCapsuleRecords',
  NESPRESSO_CAPSULE_REPORT_URL = '/nespressoCapsuleReport',
  NESPRESSO_CAPSULE_ZIP_CODE_REPORT_URL = '/nespressoCapsuleZipCodeReport',
  SIMPLELOT_URL = '/simpleLot',
  RETAIL_SINGLELOT_URL = '/retailsinglelot',
  RETAIL_IMGS_FARMER_URL = '/retailfarmerimages',
  RETAIL_IMGS_SUPPLYCHAIN_URL = '/retailsupplychainimages',
  RETAIL_IMGS_NODE_URL = '/retailnodeimages',
}

export enum SupplyChainRoutes {
  BASE_URL = '/supplychains',
  VALUATION_URL = '/valuation',
}

export enum SustainabilityRoutes {
  BASE_URL = '/sustainability',
  BASE_GROUP_INSTANCE_URL = '/sustainabilitygroupinstances',
  ADD_GROUP_INSTANCE_URL = '/addsustainabilitygroupinstancetonode',
  ADD_GROUP_URL = '/addgroup',
  ADD_LEVEL_URL = '/addLevel',
  ADD_CRITERIA_URL = '/addcriteria',
  GROUPS_URL = '/groups',
  SUSTAINABILITYGROUP_URL = '/sustainabilitygroup',
  SUSTAINABILITYGROUP_REPORT_URL = '/groupreport01',
  UPLOAD_GROUP_URL = '/groups/upload',
  GROUP_INSTANCE_NODE_URL = '/node',
}

export enum UploadRoutes {
  BASE_URL = '/uploads',
  UPLOAD_URL = '/upload',
}

export enum UpsRoutes {
  BASE_URL = '/ups',
}

export enum UserRoutes {

}

export enum WizardRoutes {
  BASE_URL = '/wizard',
}

export enum DocumentRoutes {
  BASE_URL = '/BextDocument',
  TEMPLATES_URL = '/BextDocumentTemplate',
}

export enum PersonsRoutes {
  BASE_URL = '/Persons',
  FARMER_URL = '/typeandfarmerid/Farmer/',
  COLLECTOR_URL = '/typeandfarmerid/Collector/',
}
