import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'bext-file-name-dialog',
  templateUrl: './file-name-dialog.component.html',
  styleUrls: ['./file-name-dialog.component.scss']
})
export class FileNameDialogComponent implements OnInit {

  fileName: FormControl;

  constructor(
    public dialogRef: MatDialogRef<FileNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.fileName = new FormControl(this.data.fileName, [Validators.required])
  }

  onNoClick() {
    this.dialogRef.close();
  }

  saveFile() {
    this.dialogRef.close(this.fileName.value + this.data.fileType)
  }

  getErrorMessage() {
    return this.fileName.hasError('required') ? 'You must enter a filename' : '';
  }

}
