import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProductRoutes, RequisitionRoutes } from '../../api-routes.enum';
@Injectable()
export class ProductsService {

    BASE_URL = environment.baseApiUrl;
    PRODUCTS_URL = '/products';
   

  constructor(private http: HttpClient) { }

  getProducts() {
    return this.http.get(this.BASE_URL + ProductRoutes.BASE_URL)
  }

  addProduct(payload) {
    return this.http.post(this.BASE_URL + ProductRoutes.BASE_URL, payload);
  }

  addRequisition(payload) {
    return this.http.post(this.BASE_URL + RequisitionRoutes.BASE_URL + '/create ', payload);
  }

  deleteProduct(id) {
    return this.http.delete(this.BASE_URL + ProductRoutes.BASE_URL + '/' + id);
  }

  editProduct(id, payload) {
    return this.http.put(this.BASE_URL + ProductRoutes.BASE_URL + '/' + id, payload);
  }
}