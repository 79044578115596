export const environment = {

  release_package: {
    local: false,
    dev: true,
    prod: false,
    app_version: '2.5.1'
  },

  production: true,


  debug: false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  
  GOOGLE_API_KEY: 'AIzaSyCEzGJbXqtzx-BqdFGrxrWKbSJIofTsgJg',
  IPAPI_KEY: '422987704630aba935aa94893f364afb',
  OPENCAGE_API_KEY: '9bd955e358b94f64a8a7e3c39037c611',
  MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoiY2FyZXl3aWxsZXR0ZSIsImEiOiJjamk1cHZmOWwwMzl3M3Jwa25pbGhsNWpqIn0.1I7VBsyJ9WhiOF78s5gkcg',
  ELEVATION_API_IO_KEY: 'ZLk4W3d3W030f7l270z67hei423b2R',

  baseApiUrl: "https://bext360apiproduction.azurewebsites.net/api",

  baseFrontEndUrl: "https://login.bext360.com",

  stellarApiUrl: "https://horizon-testnet.stellar.org",

  ad_config: {
    tenant: 'bext360B2CDev.onmicrosoft.com',
    clientID: '79572546-98d6-4496-8256-f48bd7fb2f86',
    signUpSignInPolicy: 'B2C_1_SiUpIn',
    b2cScopes: ['https://bext360B2CDev.onmicrosoft.com/WebApiB2cAd/user_impersonation'],
    webApi: 'https://bext360apiproduction.azurewebsites.net/api',
    replyUrl: 'https://login.bext360.com/'
  }
  
};
