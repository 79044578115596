import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bext-kpi-tabs',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})
export class TabComponent implements OnInit {

  displayKpi = 'hnt'

  socialKpis = [
    { title: 'Clean, accessible water', target: 100, value: 50, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Child labor mitigation', target: 100, value: 50, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Women’s land ownership', target: 100, value: 50, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Access to public schools', target: 100, value: 50, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Microfinance', target: 100, value: 50, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Women’s leadership training', target: 100, value: 50, date_verified: '10/18/2017', date_expires: '10/18/2018' }
  ];

  economincKpis = [
    { title: 'Living wage', target: 100, value: 95, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Payment timeliness', target: 100, value: 95, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Access to financial institution', target: 100, value: 95, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Transparent payments', target: 100, value: 95, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Access to insurance', target: 100, value: 95, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Speciality premiums', target: 100, value: 95, date_verified: '10/18/2017', date_expires: '10/18/2018' }
  ];

  environmentKpis = [
    { title: 'Water treatment & recycling', target: 100, value: 70, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Soil management plan', target: 100, value: 70, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Reduced fossil fuel usage', target: 100, value: 70, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Water runoff', target: 100, value: 70, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Integrated pest management', target: 100, value: 70, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Native Vegetation', target: 100, value: 70, date_verified: '10/18/2017', date_expires: '10/18/2018' }
  ];

  humanTrafficKpis = [
    { title: 'Commitment and Governance', target: 100, value: 66, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Purchasing Practices', target: 100, value: 44, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Worker Voice', target: 100, value: 9, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Remedy', target: 100, value: 38, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Recruitment', target: 100, value: 8, date_verified: '10/18/2017', date_expires: '10/18/2018' },
    { title: 'Monitoring', target: 100, value: 80, date_verified: '10/18/2017', date_expires: '10/18/2018' }
  ];

  constructor() { }

  ngOnInit() {
  }

  onKpiSelected(kpiName) {
    console.log('%c on Kpi Selected: ', 'background: #41ff6b; color: #ff4700;', kpiName);
    this.displayKpi = kpiName
  }

}
