import { GeoLocationService } from '@app/core/services/geo-location.service';
import { Component, Input, SimpleChanges } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { CountryList } from '@app/shared/location/country-state/list';
import { Conversion } from '@app/shared/utils/conversion-utils';

@Component({
  selector: 'sa-location-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class LocationFormComponent {
  @Input() existingLocation: any;
  @Input() autoCompletedLocation: any;

  public countries;
  public states;
  private elevation_long_value: number;
  
  constructor(public controlContainer: ControlContainer, private geoLocationService: GeoLocationService) {
    this.countries = CountryList;
  }

  ngOnInit() {
    this.locationElevation.valueChanges.subscribe(
      res => {
        console.log('value changes', res);
        this.elevation_long_value = res;
        return;
       },
      error => {
        console.log('error: ', error);
        
        return;
      },
      () => {
        // console.log('--- subscription complete ---');
        
      }
    );

    this.locationElevationUnit.valueChanges.subscribe(
      res => {
        console.log('response: ', res);
        
        return;
       },
      error => {
        // console.log('error: ', error);
        
        return;
      },
      () => {
        // console.log('--- subscription complete ---');
        
      }
    );
    
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.autoCompletedLocation && !changes.autoCompletedLocation.firstChange) {
      let place = changes.autoCompletedLocation.currentValue;
      this.onCountrySelect(place.Country);
      console.log('place object: ', place);
      this.locationState.setValue(place.State)
      this.elevation_long_value = place.Elevation;
      this.locationElevation.setValue(Math.round(this.elevation_long_value));
    }
  }

  get locationElevation() {
    return this.controlContainer.control['controls']['Elevation'];
  }

  get locationElevationUnit() {
    return this.controlContainer.control['controls']['ElevationUnit'];
  }

  get locationState() {
    return this.controlContainer.control['controls']['State'];
  }

  ngAfterContentInit(): void {
    let CountryCtrl = this.controlContainer.control['controls']['Country'];
    if (CountryCtrl.value) {
      this.states = this.getStateList(CountryCtrl.value);
    }
  }

  toggleElevationUnit(e) {
    this.elevation_long_value = !this.elevation_long_value ? this.locationElevation.value : this.elevation_long_value;
    if (e) {
      this.locationElevationUnit.setValue('meters');
      if (this.locationElevation.value) {
        console.log('long value: ', this.elevation_long_value);
        this.locationElevation.setValue(Math.round(Conversion.feetToMeters(this.elevation_long_value)));
      }
    }
    else {
      this.locationElevationUnit.setValue('feet')
      if (this.locationElevation.value) {
        console.log('long value: ', this.elevation_long_value);
        this.locationElevation.setValue(Math.round(Conversion.metersToFeet(this.elevation_long_value)));
      }
    }
    
  }

  onCountrySelect(c) {
    let country = this.geoLocationService.locationCountryNameMapping(c);
    this.states = this.getStateList(country)
    this.locationState.setValue(this.states[0])
  }

  getStateList(countryName) {
    let fnCountry = c => c.name === countryName;
    let countryObj = CountryList.filter(fnCountry);
    return countryObj[0].states;
  }

}
