import { NodesStoreService } from "@app/core-v2/services/nodes-store.service";
import { TransferLotHelperService } from "@app/core-v2/services/transfer-lot-helper.service";
import { NgxFormService } from "@app/core-v2/services/ngx-form.service";
import { FeedbackService } from "@app/core-v2/services/feedback.service";
import { EditLotStoreService } from "@app/core-v2/services/edit-lot.service";
import { SupplyChainStoreService } from "@app/core-v2/services/supply-chain-store.service";
import { SustainabilityStoreService } from "@app/core-v2/services/sustainability-store.service";
import { ReportingService } from "@app/core-v2/services/reporting.service";
import { PageFormatterService } from "@app/core-v2/services/page-formatter.service";
import { ArchwizardBuilderService } from "@app/core-v2/services/archwizard-builder.service";
import { ApiFakerService } from "@app/core-v2/services/api-faker.service";
import { ReportsService } from "@app/core-v2/services/reports.service";
import { BlockchainService } from "@app/core-v2/services/blockchain.service";
import { NodeService } from "@app/core-v2/services/node.service";
import { PersonService } from "@app/core-v2/services/person.service";
import { ProductService } from "@app/core-v2/services/product.service";
import { DocumentService } from "@app/core-v2/services/document.service";
import { UploadService } from "@app/core-v2/services/upload.service";
import { PartnerOrganizationService } from "@app/core-v2/services/partner-organization.service";
import { LotService } from "@app/core-v2/services/lot.service";
import { ValuationService } from "@app/core-v2/services/valuation.service";
import { WindowRefService } from "@app/core-v2/services/window-ref.service";
import { SupplyChainService } from "@app/core-v2/services/supply-chain.service";
import { VerifyDataService } from "@app/core-v2/services/verify-data.service";
import { BextAuthenticationService } from "@app/core-v2/services/authentication.service";
import { ImageService } from "@app/core-v2/services/image.service";
import { OrganizationService } from "@app/core-v2/services/organization.service";
import { NotificationService } from "@app/core-v2/services/notification.service";
import { GlobeService } from "@app/core-v2/services/globe.service";
import { QuickLinksService } from "@app/core-v2/services/quicklinks.service";
import { AssetService } from "@app/core-v2/services/asset.service";
import { MarketPlaceService } from "@app/core-v2/services/marketplace.service";
import { UtilitiesService } from "@app/core-v2/services/utilities.service";
import { LocationServiceV2 } from "@app/core-v2/services/locations.service";
import { DataService } from "@app/core-v2/services/data.service";

export const SERVICES = [
  ApiFakerService,
  NodesStoreService,
  TransferLotHelperService,
  NgxFormService,
  FeedbackService,
  EditLotStoreService,
  SupplyChainStoreService,
  SustainabilityStoreService,
  ReportingService,
  PageFormatterService,
  ArchwizardBuilderService,
  ReportsService,
  BlockchainService,
  NodeService,
  PersonService,
  ProductService,
  DocumentService,
  UploadService,
  PartnerOrganizationService,
  LotService,
  ValuationService,
  WindowRefService,
  SupplyChainService,
  VerifyDataService,
  BextAuthenticationService,
  ImageService,
  OrganizationService,
  NotificationService,
  GlobeService,
  QuickLinksService,
  AssetService,
  MarketPlaceService,
  UtilitiesService,
  LocationServiceV2,
  DataService,
];

export * from "@app/core-v2/services/archwizard-builder.service";
export * from "@app/core-v2/services/api-faker.service";
export * from "@app/core-v2/services/nodes-store.service";
export * from "@app/core-v2/services/transfer-lot-helper.service";
export * from "@app/core-v2/services/ngx-form.service";
export * from "@app/core-v2/services/feedback.service";
export * from "@app/core-v2/services/edit-lot.service";
export * from "@app/core-v2/services/supply-chain-store.service";
export * from "@app/core-v2/services/sustainability-store.service";
export * from "@app/core-v2/services/reporting.service";
export * from "@app/core-v2/services/page-formatter.service";
export * from "@app/core-v2/services/reports.service";
export * from "@app/core-v2/services/blockchain.service";
export * from "@app/core-v2/services/node.service";
export * from "@app/core-v2/services/document.service";
export * from "@app/core-v2/services/upload.service";
export * from "@app/core-v2/services/partner-organization.service";
export * from "@app/core-v2/services/lot.service";
export * from "@app/core-v2/services/valuation.service";
export * from "@app/core-v2/services/window-ref.service";
export * from "@app/core-v2/services/supply-chain.service";
export * from "@app/core-v2/services/verify-data.service";
export * from "@app/core-v2/services/authentication.service";
export * from "@app/core-v2/services/image.service";
export * from "@app/core-v2/services/organization.service";
export * from "@app/core-v2/services/notification.service";
export * from "@app/core-v2/services/globe.service";
export * from "@app/core-v2/services/quicklinks.service";
export * from "@app/core-v2/services/asset.service";
export * from "@app/core-v2/services/marketplace.service";
export * from "@app/core-v2/services/utilities.service";
export * from "@app/core-v2/services/locations.service";
export * from "@app/core-v2/services/data.service";
