import {Component, OnInit, Input} from '@angular/core';
import {Router} from "@angular/router";
import { NotificationService } from "@app/core/services/notification.service";
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import { BextAuthenticationService } from '@app/core/services';
import { environment } from '@env/environment';

declare var $: any;

@Component({
  selector: 'sa-header',
    styles: [`
    #header {
      max-width:1665px;
    }
    `],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @Input() appUser: any;

  user: any;
  organizationName: any;

  isDevEnv = environment.release_package.local || environment.release_package.dev ? true : false;

  constructor(  
    private router: Router,
    private notificationService: NotificationService,
    private authService: BextAuthenticationService
    ) {
  }

  ngOnInit() {
    // console.log('appUser: ', this.appUser);
  }

  searchMobileActive = false;

  toggleSearchMobile(){
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  showPopup() {
    this.notificationService.smartMessageBox(
      {
        title:
          "<i class='fas fa-sign-out-alt txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'><strong>" + (this.appUser && this.appUser.name ? this.appUser.name : '') + "</strong></span> ?",
        content:
          "You can improve your security further after logging out by closing this opened browser",
        buttons: "[No][Yes]"
      },
      ButtonPressed => {
        if (ButtonPressed == "Yes") {
          return this.logout();
        }
      }
    );
  }

  logout() {
    this.authService.logout();
  }

  checkUserToken() {
    console.log('%c GET msal user: ', 'background: #ff00ff; color: #ffffff;', this.authService.getUser());
  }

  navigateHome() {
    return this.router.navigate(["/dashboard/bext360"])
  }

}
