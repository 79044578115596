import { Pipe, PipeTransform } from '@angular/core';
import { LanguageSelectorService } from '@app/core/services/language-selector.service';

@Pipe({
  name: 'i18n',
  pure: false
})
export class I18nPipe implements PipeTransform {

  constructor(private languageSelectorService: LanguageSelectorService){}

  transform(phrase: any, args?: any): any {
    return this.languageSelectorService.getTranslation(phrase);
  }

}
