export const MachineBextCherry = {
  "LotName": {
    "label": "Lot Name",
    "type": "text",
    "value": "",
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "MachineBextCherry.Farm": {
    "label": "Farm",
    "type": "text",
    "value": "",
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "MachineBextCherry.FarmerName": {
    "label": "Farmer Name",
    "type": "text",
    "value": "",
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "MachineBextCherry.VarietalName": {
    "label": "Varietal Name",
    "type": "text",
    "value": "",
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "MachineBextCherry.BuyerName": {
    "label": "Buyer Name",
    "type": "text",
    "value": "",
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "MachineBextCherry.CollectorName": {
    "label": "Collector Name",
    "type": "text",
    "value": "",
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "LotStartWeight": {
    "label": "Lot Start Weight",
    "type": "number",
    "value": "",
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "LotStartWeightUnit": {
    "label": "Lot Start Weight Unit",
    "type": "radio",
    "value": "",
    "options": [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "Open": {
    "label": "Lot Open or Closed",
    "type": "radio",
    "value": "",
    "options": [
      { 'label': 'True', 'value': 'true' },
      { 'label': 'False', 'value': 'false' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const ProcessingWashing = {
  'ProcessingWashing.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
      },
    'errorMessage': ''
  },
  'ProcessingWashing.ProcessingStartEnd.StartMeasureTime': {
    'label': 'Start Process',
    'value': '',
    'type': 'datetime-local'
    },
  'ProcessingWashing.WashingData.Type': {
    'label': 'Wash Type',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'Innoculation', 'value': 'Innoculation'},
      { 'label': 'Microbiome', 'value': 'Microbiome'}
    ]
  }
}

export const ProcessingFermenting = {
  'ProcessingFermenting.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': true
    },
    'errorMessage': 'lot name is required'
  },
  'ProcessingFermenting.ProcessingMethod.Method': {
    'label': 'Processing Method',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'Washed', 'value': 'Washed' },
      { 'label': 'Honey', 'value': 'Honey' },
      { 'label': 'Natural', 'value': 'Natural' },
      { 'label': 'Other', 'value': 'Other' }
    ],
    'validation': {
      'required': true
    },
    'errorMessage': 'please select a wash type'
  },
  'ProcessingFermenting.ProcessingStartEnd.StartMeasureTime': {
    'label': 'Start Process',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeight': {
    'label': 'Lot Start Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeightUnit': {
    'label': 'Lot Start Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFermenting.FermentationTankId.Name': {
    'label': 'Fermentation Tank Id',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFermenting.FermentationTime.Measure': {
    'label': 'Fermentation Time',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFermenting.FermentationTime.Unit': {
    'label': 'Fermentation Time Unit',
    'type': 'select',
    'value': 'F',
    'options': [
      { 'label': 'Hours', 'value': 'Hours' },
      { 'label': 'Minutes', 'value': 'Minutes' }
    ],
    'validation': {
      'required': false
    },
  },
  'ProcessingFermenting.PhStart.Measure': {
    'label': 'Start pH',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFermenting.WashingTemperatureStart.Measure': {
    'label': 'Start Wash Temperature',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFermenting.WashingTemperatureStart.Unit': {
    'label': 'Start Wash Temperature Unit',
    'type': 'select',
    'value': 'F',
    'options': [
      { 'label': 'F', 'value': 'F' },
      { 'label': 'C', 'value': 'C' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFermenting.Yeast.Type': {
    'label': 'Yeast Type',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'Natural Microbiome', 'value': 'Natural Microbiome' },
      { 'label': 'Inoculation', 'value': 'Inoculation' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFermenting.PhEnd.Measure': {
    'label': 'End pH',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFermenting.WashingTemperatureEnd.Measure': {
    'label': 'End Wash Temperature',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFermenting.WashingTemperatureEnd.Unit': {
    'label': 'End Wash Temperature Unit',
    'type': 'select',
    'value': 'F',
    'options': [
      { 'label': 'F', 'value': 'F' },
      { 'label': 'C', 'value': 'C' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeight': {
    'label': 'Lot End Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeightUnit': {
    'label': 'Lot End Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFermenting.ProcessingStartEnd.EndMeasureTime': {
    'label': 'End Process',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const ProcessingFloating = {
  'ProcessingFloating.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFloating.ProcessingStartEnd.StartMeasureTime': {
    'label': 'Start Process',
    'value': '',
    'type': 'datetime-local'
  },
  'LotStartWeight': {
    'label': 'Lot Start Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeightUnit': {
    'label': 'Lot Start Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFloating.ProcessingMethod.Method': {
    'label': 'Processing Method',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'Washed', 'value': 'Washed' },
      { 'label': 'Honey', 'value': 'Honey' },
      { 'label': 'Natural', 'value': 'Natural' },
      { 'label': 'Other', 'value': 'Other' }
    ],
    'validation': {
      'required': true
    },
    'errorMessage': 'please select a wash type'
  },
  'ProcessingFloating.FloatingFloat.Measure': {
    'label': 'Float Percent',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFloating.FloatingSink.Measure': {
    'label': 'Sink Percent',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeight': {
    'label': 'Lot End Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeightUnit': {
    'label': 'Lot End Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingFloating.ProcessingStartEnd.EndMeasureTime': {
    'label': 'End Process',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const ProcessingDensity = {
  'ProcessingDensity.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDensity.ProcessingStartEnd.StartMeasureTime': {
    'label': 'Start Process',
    'value': '',
    'type': 'datetime-local'
  },
  'LotStartWeight': {
    'label': 'Lot Start Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeightUnit': {
    'label': 'Lot Start Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDensity.FloatResult.Measure': {
    'label': 'Float Result',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'Float', 'value': 'Float' },
      { 'label': 'Sink', 'value': 'Sink' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': 'please select a wash type'
  },
  'LotEndWeight': {
    'label': 'Lot End Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeightUnit': {
    'label': 'Lot End Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDensity.ProcessingStartEnd.EndMeasureTime': {
    'label': 'End Process',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const ProcessingDrying =  {
  'ProcessingDrying.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.ProcessingStartEnd.StartMeasureTime': {
    'label': 'Start Process',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeight': {
    'label': 'Lot Start Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeightUnit': {
    'label': 'Lot Start Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.DryingRack': {
    'label': 'Drying Rack',
    'type': 'number',
    'value': '0',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.TraysUsed.Measure': {
    'label': 'Trays Used',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.BatchDate.MeasureTime': {
    'label': 'Batch Date',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.MoistureReads.0.Measure': {
    'label': 'Moisture Read 1',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingDrying.MoistureReads.0.Unit": {
    "label": "Unit",
    "type": "radio",
    "value": "%",
    "options": [
      { 'label': '%', 'value': '%' },
      { 'label': 'number', 'value': 'number' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.MoistureReads.1.Measure': {
    'label': 'Moisture Read 2',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingDrying.MoistureReads.1.Unit": {
    "label": "Unit",
    "type": "radio",
    "value": "%",
    "options": [
      { 'label': '%', 'value': '%' },
      { 'label': 'number', 'value': 'number' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.MoistureReads.2.Measure': {
    'label': 'Moisture Read 3',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingDrying.MoistureReads.2.Unit": {
    "label": "Unit",
    "type": "radio",
    "value": "%",
    "options": [
      { 'label': '%', 'value': '%' },
      { 'label': 'number', 'value': 'number' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.MoistureReads.3.Measure': {
    'label': 'Moisture Read 4',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingDrying.MoistureReads.3.Unit": {
    "label": "Unit",
    "type": "radio",
    "value": "%",
    "options": [
      { 'label': '%', 'value': '%' },
      { 'label': 'number', 'value': 'number' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.MoistureReads.4.Measure': {
    'label': 'Moisture Read 5',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingDrying.MoistureReads.4.Unit": {
    "label": "Unit",
    "type": "radio",
    "value": "%",
    "options": [
      { 'label': '%', 'value': '%' },
      { 'label': 'number', 'value': 'number' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.MoistureReads.5.Measure': {
    'label': 'Moisture Read 6',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingDrying.MoistureReads.5.Unit": {
    "label": "Unit",
    "type": "radio",
    "value": "%",
    "options": [
      { 'label': '%', 'value': '%' },
      { 'label': 'number', 'value': 'number' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.MoistureReads.6.Measure': {
    'label': 'Moisture Read 7',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingDrying.MoistureReads.6.Unit": {
    "label": "Unit",
    "type": "radio",
    "value": "%",
    "options": [
      { 'label': '%', 'value': '%' },
      { 'label': 'number', 'value': 'number' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.MoistureReads.7.Measure': {
    'label': 'Moisture Read 8',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingDrying.MoistureReads.7.Unit": {
    "label": "Unit",
    "type": "radio",
    "value": "%",
    "options": [
      { 'label': '%', 'value': '%' },
      { 'label': 'number', 'value': 'number' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.FinalMoisture.Measure': {
    'label': 'Final Moisture Read',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingDrying.FinalMoisture.Unit": {
    "label": "Unit",
    "type": "radio",
    "value": "%",
    "options": [
      { 'label': '%', 'value': '%' },
      { 'label': 'number', 'value': 'number' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeight': {
    'label': 'Lot End Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeightUnit': {
    'label': 'Lot End Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingDrying.ProcessingStartEnd.EndMeasureTime': {
    'label': 'End Process',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const ProcessingMilling = {
  'ProcessingMilling.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeight': {
    'label': 'Lot Start Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeightUnit': {
    'label': 'Lot Start Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingMilling.HullingStartEnd.StartMeasureTime': {
    'label': 'Hulling Start Date',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingMilling.Grade.0.Measure': {
    'label': 'Grade',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingMilling.Defects.Measure': {
    'label': 'Defects',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingMilling.Weight.Measure': {
    'label': 'Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingMilling.Weight.Unit': {
    'label': '',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingMilling.HullingStartEnd.EndMeasureTime': {
    'label': 'Hulling End Date',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeight': {
    'label': 'Lot End Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeightUnit': {
    'label': 'Lot End Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const QualityCupping = {
  'QualityCupping.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeight': {
    'label': 'Lot Start Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeightUnit': {
    'label': 'Lot Start Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'QualityCupping.LocalCoffeeGrade': {
    'label': 'Local Coffee Grade',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'QualityCupping.CuppingScore.Measure': {
    'label': 'Cupping Score',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'QualityCupping.CuppingDate.MeasureTime': {
    'label': 'Cupping Date',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'QualityCupping.Notes': {
    'label': 'Cupping Notes',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'QualityCupping.MCValue.Measure': {
    'label': 'MC Value',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'QualityCupping.SC15Percent.Measure': {
    'label': 'SC15 percent',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'QualityCupping.CupperName': {
    'label': 'Cupper Notes',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeight': {
    'label': 'Lot End Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeightUnit': {
    'label': 'Lot End Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const TransportationExport = {
  'TransportationExport.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeight': {
    'label': 'Lot Start Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeightUnit': {
    'label': 'Lot Start Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.ProcessingStartEnd.StartMeasureTime': {
    'label': 'Start Transportation',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.Carrier': {
    'label': 'Carrier',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.ExportId.Measure': {
    'label': 'Export Id',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.BuyerName': {
    'label': 'Buyer Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.InvoiceFileName': {
    'label': 'Invoice File Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.StartLocationName': {
    'label': 'Start Location Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.TruckNumber': {
    'label': 'Truck Number',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.TruckType': {
    'label': 'Truck Type',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.TrackerId': {
    'label': 'Tracker Id',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.EndLocationName': {
    'label': 'End Location Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.ProcessingStartEnd.EndMeasureTime': {
    'label': 'End Transportation',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'TransportationExport.ShipDate.MeasureTime': {
    'label': 'Ship Date',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeight': {
    'label': 'Lot End Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeightUnit': {
    'label': 'Lot End Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const ProcessingRoasting = {
  'ProcessingRoasting.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeight': {
    'label': 'Lot Start Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeightUnit': {
    'label': 'Lot Start Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingRoasting.RoasterName': {
    'label': 'Roaster Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingRoasting.RoastDate.MeasureTime': {
    'label': 'Roast Date',
    'type': 'datetime-local',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingRoasting.RoastTemperature.Measure': {
    'label': 'Roast Temperature',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingRoasting.RoastTemperature.Unit': {
    'label': 'Roast Temperature Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'F', 'value': 'F' },
      { 'label': 'C', 'value': 'C' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeight': {
    'label': 'Lot End Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeightUnit': {
    'label': 'Lot End Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const SalesRetail = {
  'SalesRetail.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeight': {
    'label': 'Lot Start Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotStartWeightUnit': {
    'label': 'Lot Start Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'SalesRetail.PackingLotName': {
    'label': 'Packing Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'SalesRetail.Consumer': {
    'label': 'Consumer',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeight': {
    'label': 'Lot End Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeightUnit': {
    'label': 'Lot End Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const CollectionHuman = {
  'CollectionHuman.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': true
    },
    'errorMessage': 'please give this lot a namet'
  },
  'LotStartWeight': {
    'label': 'Lot Start Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': true
    },
    'errorMessage': 'must have an initial weight'
  },
  'LotStartWeightUnit': {
    'label': 'Lot Start Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'CollectionHuman.Species.Measure': {
    'label': 'Species',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'CollectionHuman.Type.Measure': {
    'label': 'Type',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'CollectionHuman.OrganicCertification.Measure': {
    'label': 'Organic Certification',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'CollectionHuman.FarmerId.Measure': {
    'label': 'Farmer Id',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'CollectionHuman.FarmerName.Measure': {
    'label': 'Farmer Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'CollectionHuman.CollectionType.Measure': {
    'label': 'CollectionType',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'CollectionHuman.VarietalName.Measure': {
    'label': 'Varietal Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'CollectionHuman.Block.Measure': {
    'label': 'Block',
    'type': 'text',
    'value': '',
    'validation': {
      'required': true
    },
    'errorMessage': 'please include a block'
  },
  'CollectionHuman.QualityScore.Measure': {
    'label': 'Quality Score',
    'type': 'text',
    'value': '',
    'validation': {
      'required': true
    },
    'errorMessage': 'must have a quality value'
  },
  'LotEndWeight': {
    'label': 'Lot End Weight',
    'type': 'number',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'LotEndWeightUnit': {
    'label': 'Lot End Weight Unit',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const ProcessingGinning = {
  'ProcessingGinning.LotName': {
    'label': 'Lot Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.GinName.Measure': {
    'label': 'Gin Name',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.GinCapacity.Measure': {
    'label': 'Gin Capacity',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.GinTicket.Measure': {
    'label': 'Gin Ticket',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.BaleId.Measure': {
    'label': 'Bale Id',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.MoistureContent.Measure': {
    'label': 'Moisture Content',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.CottonTrader.Measure': {
    'label': 'Cotton Trader',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.SeedWeight.Measure': {
    'label': 'Seed Weight',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.SeedWeight.Unit': {
    'label': 'Seed Weight',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.LintWieght.Measure': {
    'label': 'Lint Wieght',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.LintWieght.Unit': {
    'label': 'Lint Weight',
    'type': 'select',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.TrashWeight.Measure': {
    'label': 'Trash Weight',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.TrashWeight.Unit': {
    'label': 'Trash Unit',
    'type': 'text',
    'value': '',
    'options': [
      { 'label': 'KG', 'value': 'KG' },
      { 'label': 'LB', 'value': 'LB' }
    ],
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.BaleQuantity.Measure': {
    'label': 'Bale Quantity',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingGinning.PricePerBail.Measure': {
    'label': 'Price Per Bail',
    'type': 'text',
    'value': '',
    'validation': {
      'required': false
    },
    'errorMessage': ''
  }
}

export const ProcessingFinishing = {
  "ProcessingFinishing.LotNamee": {
    "label": "lot name",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.ScouringTreatments.Measure": {
    "label": "scouring treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.WashingTreatments.Measure": {
    "label": "washing treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.BleachingTreatments.Measure": {
    "label": "bleaching treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.ProceTreatments.Measure": {
    "label": "price treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.GassingTreatments.Measure": {
    "label": "gassing treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.StenteringTreatments.Measure": {
    "label": "stentering treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.SanforisingTreatments.Measure": {
    "label": "sanforising treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.CalendaringTreatments.Measure": {
    "label": "calendaring treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.DyeingTreatments.Measure": {
    "label": "dyeing treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.PermanentPressTreatments.Measure": {
    "label": "permanent press treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.WaterRepellencyTreatments.Measure": {
    "label": "water repellency treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.FireRetardentTreatments.Measure": {
    "label": "fire retardent treatments",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.ScouringTemperature.Measure": {
    "label": "scouring temperature",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.ScouringTemperature.Unit": {
    "label": "scouring temperature unit",
    "type": "select",
    "value": "",
    "options": [
      { 'label': 'C', 'value': 'C' },
      { 'label': 'F', 'value': 'F' }
    ]
  },
  "ProcessingFinishing.WashingTemperature.Measure": {
    "label": "washing temperature",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.WashingTemperature.Unit": {
    "label": "washing temperature unit",
    "type": "select",
    "value": "",
    "options": [
      { 'label': 'C', 'value': 'C' },
      { 'label': 'F', 'value': 'F' }
    ]
  },
  "ProcessingFinishing.BleachingTemperature.Measure": {
    "label": "bleaching temperature",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.BleachingTemperature.Unit": {
    "label": "bleaching temperature unit",
    "type": "select",
    "value": "",
    "options": [
      { 'label': 'C', 'value': 'C' },
      { 'label': 'F', 'value': 'F' }
    ]
  },
  "ProcessingFinishing.ProceTemperature.Measure": {
    "label": "proce temperature",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.ProceTemperature.Unit": {
    "label": "proce temperature unit",
    "type": "select",
    "value": "",
    "options": [
      { 'label': 'C', 'value': 'C' },
      { 'label': 'F', 'value': 'F' }
    ]
  },
  "ProcessingFinishing.PressingTemperature.Measure": {
    "label": "pressing temperature",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.PressingTemperature.Unit": {
    "label": "pressing temperature unit",
    "type": "select",
    "value": "",
    "options": [
      { 'label': 'C', 'value': 'C' },
      { 'label': 'F', 'value': 'F' }
    ]
  },
  "ProcessingFinishing.WaterUsage.Measure": {
    "label": "water usage",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingFinishing.DyeType.Type": {
    "label": "dye type",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  }
}

export const ProcessingKnitting = {
  "ProcessingKnitting.LotName": {
    "label": "lot name",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  "ProcessingKnitting.WeaveType.Measure": {
    "label": "weave type",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  }
}

export const ProcessingSpinning = {
  'ProcessingSpinning.LotName': {
    "label": "lot name",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingSpinning.SpinningMethod.Measure': {
    "label": "spinning method",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingSpinning.OutputType.Measure': {
    "label": "output type",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingSpinning.MachineName.Measure': {
    "label": "machine name",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingSpinning.Gassing.Measure': {
    "label": "gassing",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingSpinning.PricePerKg.Measure': {
    "label": "price per kg",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingSpinning.ConditioningTemperature.Measure': {
    "label": "conditioning temperature",
    "type": "text",
    "value": "",
    "validation": {
      'required': false
    },
    'errorMessage': ''
  },
  'ProcessingSpinning.ConditioningTemperature.Unit': {
    "type": "select",
    "label": "conditioning temperature unit",
    "value": "",
    "options": [
      { 'label': 'C', 'value': 'C' },
      { 'label': 'F', 'value': 'F' }
    ]
  }
}

export const uiObject = {
  'MachineBextCherry': MachineBextCherry, 
  'ProcessingWashing': ProcessingWashing,
  'ProcessingDrying': ProcessingDrying,
  'ProcessingMilling': ProcessingMilling,
  'QualityCupping': QualityCupping,
  'TransportationExport': TransportationExport,
  'ProcessingRoasting': ProcessingRoasting,
  'SalesRetail': SalesRetail,
  'CollectionHuman': CollectionHuman,
  'ProcessingGinning': ProcessingGinning,
  'ProcessingFinishing': ProcessingFinishing,
  'ProcessingKnitting': ProcessingKnitting,
  'ProcessingSpinning': ProcessingSpinning
}

// export const ProcessingFermenting = {
//   'ProcessingFermenting.LotName': {
//     "label": "Lot Name",
//     "type": "text",
//     "value": "",
//     "validation": {
//       'required': false
//     },
//     'errorMessage': ''
//   },
//   'ProcessingFermenting.PulpedByName': {
//     "label": "Lot Name",
//     "type": "text",
//     "value": "",
//     "validation": {
//       'required': false
//     },
//     'errorMessage': ''
//   },
// }