import { IAnalyticData } from './analytic-data.model';
export class BaseLot {
  constructor(
    public LotMetaData: any = {
      "LotName": "",
      "LotType": "",
      "LotDetailType": "",
      "NodeId": "",
      "SupplyChainId": "",
      "OrganizationId": "",

      "LotStartWeight": {
        "AttributeTypeId": 0,
        "Type": "",
        "Label": "",
        "Key": "",
        "Default": false,
        "Name": "",
        "Method": "",
        "Group": "",
        "Unit": "",
        "Measure": "",
        "StartMeasureTime": "",
        "EndMeasureTime": "",
        "MeasureTime": "",
        "StartMeasureTimeDt": "",
        "EndMeasureTimeDt": "",
        "MeasureTimeDt": ""
      },
        "LotCurrentWeight": {
            "AttributeTypeId": 0,
            "Type": "",
            "Label": "",
            "Key": "",
            "Default": false,
            "Name": "",
            "Method": "",
            "Group": "",
            "Unit": "",
            "Measure": "",
            "StartMeasureTime": "",
            "EndMeasureTime": "",
            "MeasureTime": "",
            "StartMeasureTimeDt": "",
            "EndMeasureTimeDt": "",
            "MeasureTimeDt": ""
        },
      "LotEndWeight": {
        "AttributeTypeId": 0,
        "Type": "",
        "Label": "",
        "Key": "",
        "Default": false,
        "Name": "",
        "Method": "",
        "Group": "",
        "Unit": "",
        "Measure": "",
        "StartMeasureTime": "",
        "EndMeasureTime": "",
        "MeasureTime": "",
        "StartMeasureTimeDt": "",
        "EndMeasureTimeDt": "",
        "MeasureTimeDt": ""
      },
      "QualityScore": {
        "AttributeTypeId": 0,
        "Type": "",
        "Label": "",
        "Key": "",
        "Default": false,
        "Name": "",
        "Method": "",
        "Group": "",
        "Unit": "",
        "Measure": "",
        "StartMeasureTime": "",
        "EndMeasureTime": "",
        "MeasureTime": "",
        "StartMeasureTimeDt": "",
        "EndMeasureTimeDt": "",
        "MeasureTimeDt": ""
      },
      "Tier": ""
    },
    public LotDatas: IAnalyticData[] = [],
    public Notes: string = ''
  ) { }
}
