import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TransferLotHelperObject, NodesStoreService, TransferLotHelperService } from '@app/core-v2/services';
import { NodeService } from '@app/core/services/backend/node.service';
import { takeWhile, tap, pluck, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSelectionListChange } from '@angular/material';
import { TransferLotEntityV2 } from '@app/shared-v2/models/transfers/transfer-lot-payload.model';
import { LotService } from '@app/core/services/backend/lot.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'bext-transfer-one-many',
  templateUrl: './transfer-one-many.component.html',
  styleUrls: ['./transfer-one-many.component.scss']
})
export class TransferOneManyComponent implements OnInit {

  private alive = true;
  private _nodeList: any;

  form: FormGroup;

  maxShrinkage;
  totalShrinkage;

  transferObject: TransferLotHelperObject;
  currentNodeDetails: any;
  transferToNode: any;
  transferToNodeDetails: any;
  selectedLot: any;

  payload = new TransferLotEntityV2(
    {
      "TransferType": "OneLotToManyNewLots",
      "ForwardTransfer": true,
      "FullTransfer": true,
      "CloseTransferredLots": true,
      "Shrinkage": null
    },
    []
  )

  @Input('nodeList')
  set nodeList(object) {
    this._nodeList = object;
  }
  get nodeList() {
    return this._nodeList;
  }

  @Output('submitTransfer') submitTransfer = new EventEmitter<any>();

  constructor(private nodeStore: NodesStoreService, private transferLotHelperService: TransferLotHelperService, private nodeService: NodeService, private lotService: LotService, private fb: FormBuilder, private decimalPipe: DecimalPipe) {
    this.form = this.fb.group({
      'newLots': this.fb.array([])
    })
  }
  
  get newLotsArray() {
    return this.form.get('newLots') as FormArray;
  }
  
  formStatus() {
    return !this.newLotsArray.length || this.form.status === 'INVALID' ? true : false;
  }

  ngOnInit() {
    this.transferObject = this.transferLotHelperService.transferLotSubject;
    this.selectedLot = this.transferLotHelperService.selectedLots[0];
    this.maxShrinkage = this.selectedLot.LotStartWeightDb;
    this.currentNodeDetails = this.nodeStore.currentNodeDetails;

    this.transferLotHelperService.transferLotChange
      .pipe(
        takeWhile(() => this.alive),
        tap((res) => {
          // console.log('transfer lot object change: ', res);
          return;
        })
      ).subscribe();

    this.payload.addAbsorbedFromExistingLot(this.selectedLot);

    this.form.valueChanges
        .pipe(
          takeWhile(() => this.alive),
          debounceTime(300),
          distinctUntilChanged()
        )
        .subscribe(
          res => {
            let totalWeight = (prev, curr) => prev += Number(curr.Measure);
            this.totalShrinkage = res.newLots.reduce(totalWeight,0);
            return;
           },
          error => {
            // console.log('error: ', error);
            
            return;
          },
          () => {
            // console.log('--- subscription complete ---');
            
          }
        );
  } 

  transformNumber(number) {
    return this.decimalPipe.transform(number, '1.0-2');
  }

  createNewLot(measure) {
    return this.fb.group({
      'LotName': new FormControl('', Validators.required),
      'Measure': new FormControl(measure, Validators.required),
      'Unit': new FormControl('', Validators.required)
    });
  }

  addNewLot() {
    if (!this.newLotsArray.length) {
      this.newLotsArray.insert(0, this.createNewLot(this.transformNumber(this.selectedLot.LotStartWeightDb / 2)));
      let totalWeight = (prev, curr) => prev += Number(curr.Measure);
      this.totalShrinkage = this.form.value.newLots.reduce(totalWeight, 0);
    }
    else if (this.newLotsArray.length) {
      let remainingBalance = this.maxShrinkage - this.totalShrinkage;
      this.newLotsArray.insert(0, this.createNewLot(this.transformNumber(remainingBalance)));
    }
  }

  removeNewLot(index) {
    this.newLotsArray.removeAt(index);
  }

  resetTransferSubject() {
    this.transferLotHelperService.reInstantiateTransferLotSubject();
  }

  transferToNodeSelected(event) {
    let node = event.value;
    this.transferToNode = node;
    while(this.newLotsArray.length !== 0) {
      this.newLotsArray.removeAt(0)
    };
    this.addNewLot();
    this.addNewLot();
    this.nodeService.getNodeById(node.NodeId)
      .pipe(
        takeWhile(() => this.alive),
        tap((node) => {
          this.transferLotHelperService.addTransferToNode(node);
        })
      ).subscribe();

  }

  transferToLotSelected(event: MatSelectionListChange) {
    let lot = event.option.value
    this.payload.addTransferredToExistingLot(lot);
  }

  submit() {

    let newLots = this.form.value.newLots;

    let payload = this.payload;

    newLots.forEach(lot => {
      payload.addTransferredToManyLot(lot, this.transferToNode);
    });

    this.submitTransfer.emit(payload.payloadValue);
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
