import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WizardService } from '@app/core/services/backend/wizard.service';
import { UploadService } from '@app/core/services/backend/uploads.service';

@Component({
  selector: 'wizard-instance',
  templateUrl: './wizard-instance.component.html',
  styleUrls: ['./wizard-instance.component.css']
})
export class WizardInstanceComponent implements OnInit {
  @ViewChild('formDir') formDir;
  @Input() wizardInstance;
  @Input() disableDefaultSendAction = false;
  @Input() type = 'create';
  @Output() actionTaken: EventEmitter<any> = new EventEmitter();
  currentStep;
  form;
  showLoader = false;
  section;
  sectionName;
  level;
  fileInput;
  date;
  
  constructor(private wizardService: WizardService, private uploadService: UploadService) {
    
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log('ngOnChanges WI');
  //   if (!changes.wizardInstance.firstChange) {
  //     this.form.patch(changes.wizardInstance.currentValue)
  //   }
  // }

  ngOnInit() {
    // console.log('init WI');
    this.setCurrentStep(true);
  }

  ngOnChanges(changes: SimpleChanges) {
   if (changes.wizardInstance) {
     this.setCurrentStep(false);
    }
  }

  setCurrentStep(bind?)  {
    let index = this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep;
    this.currentStep = this.wizardInstance.WizardFrontEnd.WizardStepDatas[index];
    if (!bind) this.bindFormData();
  }

  bindFormData() {
    const formGroup = {};
    this.wizardInstance.WizardFrontEnd.WizardStepDatas.forEach((step) => {
      step.WizardStepFields.forEach(section => {
        section.Fields.forEach(field => {
          if (field.visible || (!field.visible && (field.validation.create.required || field.validation.analysis.required || field.validation.transfer.required))) {
            formGroup[field.name] = new FormControl(field.value || '', this.mapValidators(field.validation[this.type]));
            formGroup[field.name].valueChanges.subscribe(val => {
              field.value = val;
            });
          }
        })
      })
    })
    
    // console.log('%c Initial form data: ', 'background: #41ff6b; color: #ff4700;', formGroup);
    this.form = new FormGroup(formGroup);
  }

  showStep(step) {
    let show = false;
    if (step && step.WizardStepMetaData && step.WizardStepMetaData.StepConditions && step.WizardStepMetaData.StepConditions.length) {
      step.WizardStepMetaData.StepConditions.forEach((condition) => {
          condition.StepConditionFields.forEach((field) => {
            let fieldName = field.FieldName;
            show = show || field.Values.find(value => {
              return value == this.form['controls'][fieldName].value
            });
          });
      });
      return show;
    } else return true;
  }

  private mapValidators(validators) {
    const formValidators = [];
    if (validators) {
      for (const validation of Object.keys(validators)) {
        if (validation === 'required' && validators['required']) {
          formValidators.push(Validators.required);
        } else if (validation === 'min') {
          formValidators.push(Validators.min(validators[validation]));
        }
      }
    }
    return formValidators;
  }

  hasVisibleFields(section) {
    let hasVisibleFields = false;
    if(section.Fields && section.Fields.length) {
      section.Fields.some((field) => { 
        hasVisibleFields = ((field.visible || (!field.visible && (field.validation.create.required || field.validation.analysis.required || field.validation.transfer.required))) ? field.visible : hasVisibleFields); return hasVisibleFields});
    }
    // return true;
    return hasVisibleFields;
  }

  onFileSelected(event, level) {
    console.log('on file selected $event: ', event);
    let fd = new FormData();
    [<File>event.target.files[0]].forEach(document => {
      console.log('document: ', document)
      fd.append('files', document, document.name);
    });

    this.uploadService.upload(fd).subscribe(res => {
      this.form.controls[level].setValue(res.ImageURL);
      console.log('upload service res: ', res);
    });
  }
  
  openImage(img) {
    console.log('image on click: ', img);
  }

  sendAction(action, step) {
    switch (action.Action) {
      case 'NEXT': 
      if (this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep < this.wizardInstance.WizardFrontEnd.WizardStepDatas.length) {
        this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep = step.WizardStepMetaData.Sequence + 1;
        this.currentStep = this.wizardInstance.WizardFrontEnd.WizardStepDatas.find(step => (step.WizardStepMetaData.Sequence == this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep) && (this.showStep(step)));
        if (!this.currentStep) {
          this.sendAction(action, {
            WizardStepMetaData: {
              Sequence: step.WizardStepMetaData.Sequence + 1
            }
          });
        } else {
          this.wizardInstance.WizardInstanceStatus = 'InProgress';
          this.wizardService.updateWizardInstance(this.wizardInstance.id, this.wizardInstance).subscribe(res => {
            console.log('success saving wizard instance');
            this.actionTaken.emit(action.Action);
          });
        }
      }
        break;
      case 'PREV': 
        if ( this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep > 0) {
        this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep = step.WizardStepMetaData.Sequence - 1;
        this.currentStep = this.wizardInstance.WizardFrontEnd.WizardStepDatas.find(step => (step.WizardStepMetaData.Sequence == this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep) && (this.showStep(step)));
        if (!this.currentStep) {
          this.sendAction(action, {
            WizardStepMetaData: { 
              Sequence: step.WizardStepMetaData.Sequence - 1 
            }
          });
        } else {
          this.wizardInstance.WizardInstanceStatus = 'InProgress';
          this.wizardService.updateWizardInstance(this.wizardInstance.id, this.wizardInstance).subscribe(res => {
            console.log('success saving wizard instance');
            this.actionTaken.emit(action.Action);
          });
        }
      }
        break;
      case 'FINISH': 
          this.wizardInstance.WizardInstanceStatus = 'Complete';
          this.wizardService.updateWizardInstance(this.wizardInstance.id, this.wizardInstance).subscribe(res => {
            this.actionTaken.emit(action.Action);
          });
    }
  }

  onWizardComplete(e) {
    console.log('wizard complete $event: ', e);
  }

  getVisibleSteps() {
    return this.wizardInstance.WizardFrontEnd.WizardStepDatas.filter(step => this.showStep(step));
  }

  sendWizardPayload(action, step) {
    switch(action.Action) {
      case 'NEXT':
        if (this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep < this.wizardInstance.WizardFrontEnd.WizardStepDatas.length) {
          this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep = step.WizardStepMetaData.Sequence + 1;
          this.currentStep = this.wizardInstance.WizardFrontEnd.WizardStepDatas.find(step => (step.WizardStepMetaData.Sequence == this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep) && (this.showStep(step)));
          if (!this.currentStep) {
            this.sendWizardPayload(action, {
              WizardStepMetaData: {
                Sequence: step.WizardStepMetaData.Sequence + 1
              }
            });
          }
        }
      break;

      case 'PREV':
        if (this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep > 0) {
          this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep = step.WizardStepMetaData.Sequence - 1;
          this.currentStep = this.wizardInstance.WizardFrontEnd.WizardStepDatas.find(step => (step.WizardStepMetaData.Sequence == this.wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep) && (this.showStep(step)));
          if (!this.currentStep) {
            this.sendWizardPayload(action, {
              WizardStepMetaData: {
                Sequence: step.WizardStepMetaData.Sequence - 1
              }
            });
          }
        }
      break;

      case 'FINISH':
        this.wizardInstance.WizardInstanceStatus = 'Complete';
        this.actionTaken.emit(this.formDir.form.value);
      break;
    }
  }

  getTimeStamp(e, form, name) {
    // e.stopPropagation();
    this.date = new Date();
    // console.log('%c get time stamp: ', 'background: #41ff6b; color: #ff4700;', this.date);
    form.controls[name].setValue(this.date.getFullYear() + '-' + ((this.date.getMonth() < 10 ? '0' : '') + (this.date.getMonth() + 1)) + '-' + ((this.date.getDate() < 10 ? '0' : '') + this.date.getDate()) + 'T' + (this.date.getHours() < 10 ? '0' : '') + this.date.getHours() + ':' + ((this.date.getMinutes() < 10 ? '0' : '') + this.date.getMinutes()));
  }

 }
