import { UploadService } from '@app/core/services/backend/uploads.service';
import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autoRotateImage]'
})
export class ImageRotationDirective {

  @Input('autoRotateImage') image: any;

  constructor() {
    // console.log('%c IMAGE OBJECT: ', 'background: #fae552; color: #323232;', this.image);
  }
  
  ngOnInit() {
    // this.getOrientation(, function (orientation) {
    //   alert('orientation: ' + orientation);
    // });
  }

  getOrientation(file, callback) {
  var reader = new FileReader();
  reader.onload = function (e) {

    var view = new DataView(e.target['result']);
    if (view.getUint16(0, false) != 0xFFD8) {
      return callback(-2);
    }
    var length = view.byteLength, offset = 2;
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
      var marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xFFE1) {
        if (view.getUint32(offset += 2, false) != 0x45786966) {
          return callback(-1);
        }

        var little = view.getUint16(offset += 6, false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;
        for (var i = 0; i < tags; i++) {
          if (view.getUint16(offset + (i * 12), little) == 0x0112) {
            return callback(view.getUint16(offset + (i * 12) + 8, little));
          }
        }
      }
      else if ((marker & 0xFF00) != 0xFF00) {
        break;
      }
      else {
        offset += view.getUint16(offset, false);
      }
    }
    return callback(-1);
  };
  reader.readAsArrayBuffer(file);
}

}
