import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bext-node-card-list',
  templateUrl: './node-card-list.component.html',
  styleUrls: ['./node-card-list.component.css']
})
export class NodeCardListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
