import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "sa-print-lot-qr",
  templateUrl: "./print-lot-qr.component.html",
  styleUrls: ["./print-lot-qr.component.css"],
})
export class PrintLotQrComponent implements OnInit {
  qrData = "test";
  @ContentChild(PrintLotQrComponent) printLotQrComponent: PrintLotQrComponent;
  @ViewChild("svg") svg;
  canvas;
  imageData;
  printCharacteristic = null;
  index;
  data2;
  letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  numbers = "1234567890";
  letterAndNumbers = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      lotId: string;
      nodeId: string;
      lotName: string;
    }
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.qrData = this.randomString(6, "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890");
    // this.qrData = this.data.lotId + ' ' + this.data.nodeId + ',' + this.data.lotName; //ownerId + ',' + entityValue + ',' + entityId
    this.cd.detectChanges();
  }

  randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  sendQRCode = () => {
    this.index = 0;
    this.data2 = this.getImagePrintData();
    var that = this;
    return new Promise(function (resolve, reject) {
      that.sendNextImageDataBatch(resolve, reject);
    });
  };

  getImagePrintData() {
    if (this.imageData == null) {
      console.log("No image to print!");
      return new Uint8Array([]);
    }
    // Each 8 pixels in a row is represented by a byte
    let printData = new Uint8Array(
      (this.canvas.width / 8) * this.canvas.height + 8
    );
    let offset = 0;
    // Set the header bytes for printing the image
    printData[0] = 29; // Print raster bitmap
    printData[1] = 118; // Print raster bitmap
    printData[2] = 48; // Print raster bitmap
    printData[3] = 0; // Normal 203.2 DPI
    printData[4] = this.canvas.width / 8; // Number of horizontal data bits (LSB)
    printData[5] = 0; // Number of horizontal data bits (MSB)
    printData[6] = this.canvas.height % 256; // Number of vertical data bits (LSB)
    printData[7] = this.canvas.height / 256; // Number of vertical data bits (MSB)
    offset = 7;
    // Loop through image rows in bytes
    for (let i = 0; i < this.canvas.height; ++i) {
      for (let k = 0; k < this.canvas.width / 8; ++k) {
        let k8 = k * 8;
        //  Pixel to bit position mapping
        printData[++offset] =
          this.getDarkPixel(k8 + 0, i) * 128 +
          this.getDarkPixel(k8 + 1, i) * 64 +
          this.getDarkPixel(k8 + 2, i) * 32 +
          this.getDarkPixel(k8 + 3, i) * 16 +
          this.getDarkPixel(k8 + 4, i) * 8 +
          this.getDarkPixel(k8 + 5, i) * 4 +
          this.getDarkPixel(k8 + 6, i) * 2 +
          this.getDarkPixel(k8 + 7, i);
      }
    }
    return printData;
  }

  sendNextImageDataBatch(resolve, reject) {
    // Can only write 512 bytes at a time to the characteristic
    // Need to send the image data in 512 byte batches
    let writeValue = this.data2.slice(this.index, this.index + 20);
    if (this.index + 20 < this.data2.length) {
      this.printCharacteristic
        .writeValue(writeValue)
        .then(() => {
          this.index += 20;
          this.sendNextImageDataBatch(resolve, reject);
        })
        .catch((error) => reject(error));
    } else {
      // Send the last bytes
      if (this.index < this.data2.length) {
        let writeValue2 = this.data2.slice(this.index, this.data2.length);
        this.printCharacteristic
          .writeValue(writeValue2)
          .then(() => {
            resolve();
          })
          .catch((error) => reject(error));
      } else {
        resolve();
      }
    }
  }

  getDarkPixel(x, y) {
    // Return the pixels that will be printed black
    let red = this.imageData[(this.canvas.width * y + x) * 4];
    let green = this.imageData[(this.canvas.width * y + x) * 4 + 1];
    let blue = this.imageData[(this.canvas.width * y + x) * 4 + 2];
    return red + green + blue > 0 ? 0 : 1;
  }
  connectBluetoothPrinter() {
    // this.qrData = this.data.lot;
    this.cd.detectChanges();
    let image: any = document.querySelector("sa-print-lot-qr svg");
    this.canvas = document.createElement("canvas");
    this.canvas.width = 240;
    this.canvas.height = 240;
    let context = this.canvas.getContext("2d");

    let svg_xml = new XMLSerializer().serializeToString(image);

    var img = new Image();
    img.src = "data:image/svg+xml;base64," + btoa(svg_xml);

    img.onload = () => {
      context.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);
      this.imageData = context.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      ).data;
      if (this.printCharacteristic == null) {
        navigator["bluetooth"]
          .requestDevice({
            filters: [
              {
                services: ["000018f0-0000-1000-8000-00805f9b34fb"],
              },
            ],
          })
          .then((device) => {
            console.log("> Found " + device.name);
            console.log("Connecting to GATT Server...");
            return device.gatt.connect();
          })
          .then((server) =>
            server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb")
          )
          .then((service) =>
            service.getCharacteristic("00002af1-0000-1000-8000-00805f9b34fb")
          )
          .then((characteristic) => {
            // Cache the characteristic
            this.printCharacteristic = characteristic;
            var writeChunks = async (chunks) => {
              for (const chunk of chunks) {
                await chunk().then(() => {});
              }
              return Promise.resolve();
            };

            var writeChunks2 = async () => {
              await writeChunks([this.sendQRCode]).then(() => {});

              return Promise.resolve();
            };

            writeChunks2().then(() => {});

            // this.sendQRCode().then(() => {
            //   console.log('done');
            //   this.sendFormData().then(() => {
            //     console.log('done form');
            //     this.sendFormData2().then(() => {
            //       this.sendFormData3().then(() => {
            //         let test = new Uint8Array([0x0a, 0x1d, 0x56, 0x42, 0x01, 0x0a]);
            //         this.printCharacteristic.writeValue(test).then(() => {
            //           // this.form.reset();
            //           // this.spinnerService.hide()
            //           // this.notificationService.smallBox(
            //           //   {
            //           //     title:
            //           //       " <span class='txt-color-green'> Success</span>",
            //           //     content:
            //           //       "Lot Created Successfully!"
            //           //   }
            //           // );
            //           // console.log('test done');
            //         })
            //       })
            //     })
            //   })
            // })
          })
          .catch((err) => {
            console.error(err.message);
          });
      } else {
        var writeChunks = async (chunks) => {
          for (const chunk of chunks) {
            await chunk().then(() => {});
          }
          return Promise.resolve();
        };

        var writeChunks2 = async () => {
          await writeChunks([this.sendQRCode]).then(() => {});
          return Promise.resolve();
        };

        writeChunks2().then(() => {});
        // this.sendQRCode().then(() => {
        //   console.log('done');
        //   this.sendFormData().then(() => {
        //     console.log('done form');
        //     this.sendFormData2().then(() => {
        //       this.sendFormData3().then(() => {
        //         let test = new Uint8Array([0x0a, 0x1d, 0x56, 0x42, 0x01, 0x0a]);
        //         this.printCharacteristic.writeValue(test).then(() => {
        //           this.form.reset();
        //           // this.spinnerService.hide()
        //           // this.notificationService.smallBox(
        //           //   {
        //           //     title:
        //           //       " <span class='txt-color-green'> Success</span>",
        //           //     content:
        //           //       "Lot Created Successfully!"
        //           //   }
        //           // );
        //           // console.log('test done');
        //         })
        //       })
        //     })
        //   })
        // })
      }
    };
    // this.sendFormData().then(() => {
    //   console.log('done!');
    // });
    // setTimeout(() => {
    //   this.sendQRCode();
    // },3200)
  }
  // this.sendFormData().then(() => {
  //   console.log('done!');
  // });
  // setTimeout(() => {
  //   this.sendQRCode();
  // },3200)

  defaultPrinter() {
    // this.qrData = this.data.lot;
    // this.cd.detectChanges();

    setTimeout(() => {
      let printContents, popupWin;
      printContents = document.getElementById("print-section").innerHTML;
      popupWin = window.open(
        "",
        "_blank",
        "top=0,left=0,height=100%,width=auto"
      );
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          div {
          height: 200px;
          width: 200px;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
      popupWin.document.close();
    });
  }
}
