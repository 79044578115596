import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@env/environment";

/*
// ********** TODO **********
//
// - make list collapsable
// - toggle between horizontal and vertical
//
*/

interface Item {
  type: string;
  data: any;
}

@Component({
  selector: "bext-scroll-section",
  templateUrl: "./scroll-section.component.html",
  styleUrls: ["./scroll-section.component.scss"],
})
export class ScrollSectionComponent implements OnInit {
  private MAPBOX_ACCESS_TOKEN = environment.MAPBOX_ACCESS_TOKEN;

  _data: Item[];

  @Input("sectionTitle") title: any;
  @Input("showToolTip") showToolTip: boolean = false;
  @Input("showPermissionIcons") showPermissionIcons = false;
  @Input("dataType") type: any;
  @Input("defaultMapImage") defaultMapImage = true;
  @Input("data") items: any[];
  @Input("navigateOnClick") onClickNavigateToItem = false;
  @Output("itemSelected")
  itemSelected: EventEmitter<any> = new EventEmitter<any>();

  currentItem: any;
  currentItemIndex: number;

  constructor(private router: Router) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const items = changes.items.currentValue
      ? changes.items.currentValue
      : undefined;
    const format =
      changes.type && changes.type.currentValue
        ? changes.type.currentValue
        : this.type;
    // console.log('changes: ', changes);
    if (items && format) {
      this._data = this.formatDataForScroller(items, format);
      // console.log('%c formatted scroll data: ', 'background: #41ff6b; color: #ff4700;', this._data);
    }
  }

  onItemClicked(item, idx) {
    switch (this.onClickNavigateToItem) {
      case true:
        let url = "";
        if (item.type === "node") {
          url = `/v2/node/${item.data.id}`;
          this.router.navigate([url]);
        }
        break;

      default:
        this.currentItem = item;
        this.currentItemIndex = idx;
        this.itemSelected.emit(item.data);
        break;
    }
  }

  formatDataForScroller(data: any, format: string) {
    switch (format) {
      case "node":
        return this._createNodeData(data, format);

      case "nodeDetail":
        return this._createNodeDetailData(data, format);

      case "assetGroup":
        return this._createAssetGroupData(data, format);

      default:
        return this._createNodeData(data, format);
    }
  }

  createStaticMapImage(lng, lat) {
    // console.log(
    //   "%c scroll lng, lat: ",
    //   "background: #41ff6b; color: #ff4700;",
    //   lng + "-" + lat
    // );
    return `https://api.mapbox.com/styles/v1/mapbox/navigation-guidance-day-v4/static/geojson({"type":"Point","coordinates":[${lng},${lat}]})/${lng},${lat},2/239x135?access_token=${this.MAPBOX_ACCESS_TOKEN}`;
  }

  private _createNodeData(da, dt) {
    return da.reduce((p, c) => {
      let o = {
        type: dt,
        data: c,
      };
      p.push(o);
      return p;
    }, []);
  }

  private _createNodeDetailData(dm, dt) {
    return Object.keys(dm).reduce((p, c) => {
      let o = {
        type: dt,
        data: dm[c],
      };
      p.push(o);
      return p;
    }, []);
  }

  private _createAssetGroupData(dm, dt) {
    return Object.keys(dm).reduce((p, c) => {
      let o = {
        type: dt,
        data: dm[c],
      };
      p.push(o);
      return p;
    }, []);
  }
}
