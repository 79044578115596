import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ModalModule } from "ngx-bootstrap";
import { MaterialModule } from "@app/shared/angular-material.module";
import { SortableTableComponent } from "./sortable-table/sortable-table.component";
import { ExtendedSortableTableComponent } from "./extended-sortable-table/extended-sortable-table.component";
import { ColumnSorterComponent } from "./sortable-table/actions/column-sorter/column-sorter.component";

//import { SaveReportComponent } from '@app/features-v2/reports/save-report/save-report.component';
import { FormsModule } from "@angular/forms";

const MAT_TABLE_COMPONENTS = [
  SortableTableComponent,
  ExtendedSortableTableComponent,
];

const MAT_TABLE_ACTIONS = [ColumnSorterComponent];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ModalModule,
    FormsModule,
  ],
  declarations: [
    ...MAT_TABLE_COMPONENTS,
    ...MAT_TABLE_ACTIONS,
    //SaveReportComponent
  ],
  exports: [...MAT_TABLE_COMPONENTS, ...MAT_TABLE_ACTIONS],
  entryComponents: [
    SortableTableComponent,
    ColumnSorterComponent,
    //SaveReportComponent
  ],
})
export class MatTablesModule {}
