import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'sa-organization-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class OrganizationFormComponent implements OnInit {
  @Input() locations;
  @Output() activateModal = new EventEmitter<any>();
  @Output() locationAdded: EventEmitter<any> = new EventEmitter<any>();
  @Input() existingOrganization: any;

  constructor(public controlContainer: ControlContainer) { }
  

  ngOnInit() {
  }

  showModal() {
    this.activateModal.emit(true);
  }

  onChange(event) {
    if (event.target.value == 'locationAdd') {
      this.showModal();
    }
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

}
