import { CreateSupplyChainComponent } from "@app/shared-v2/util-components/create-supply-chain/create-supply-chain.component";
import { LocationServiceV2 } from "@app/core-v2/services/locations.service";
import { AddProductComponent } from "@app/shared-v2/product/add-product/add-product.component";
import { LocationListV2Component } from "@app/shared-v2/location/location-list/location-list.component";
import { SimpleAddOrganizationComponent } from "@app/shared-v2/organizations/simple/simple.component";
import { CreateRequisitionComponent } from "@app/shared-v2/dialogs/create-requisition/create-requisition.component";
import { CustomFormDialogComponent } from "@app/shared-v2/dialogs/custom-form-dialog/custom-form-dialog.component";
import { ReportListDialogComponent } from "@app/shared-v2/dialogs/report-list-dialog/report-list-dialog.component";
import { AddUserComponent } from "./../../user/add/add.component";
import { AddPersonComponent } from "./../../../shared-v2/person/add-person/add-person.component";
import { ScanTransferQrLotComponent } from "@app/shared-v2/lots/scan-transfer-qr-lot/scan-transfer-qr-lot.component";
import { Validator } from "./../../../shared-v2/models/field-config.interface";
import { environment } from "@env/environment";

import {
  Component,
  HostListener,
  Directive,
  ElementRef,
  Renderer2,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";

import { isNullOrUndefined } from "util";
import { getRandomNumberInRange } from "@app/shared-v2/utils/number-utils";

import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, timer, forkJoin, interval, fromEvent, of } from "rxjs";
import {
  map,
  takeWhile,
  retry,
  tap,
  shareReplay,
  retryWhen,
  delayWhen,
  skip,
  take,
  filter,
  switchMap,
  catchError,
} from "rxjs/operators";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatDialog,
  MatDrawer,
  MatDialogConfig,
} from "@angular/material";

import { genericRetryStrategy } from "@app/core-v2/api-generic-retry-strategy";
import { BextAuthenticationService, UiDataService } from "@app/core/services";
import { slideInAnimation } from "@app/route-animations";
import { HelpService } from "@app/core/services/backend/help.service";
import {
  FeedbackService,
  NotificationService,
  OrganizationService,
  QuickLinksService,
} from "@app/core-v2/services";

import { Notification } from "@app/shared-v2/notifications/models/notification.model";

import { UploadUserImageDialogComponent } from "@app/shared-v2/dialogs/upload-user-image-dialog/upload-user-image-dialog.component";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { LocationDialogComponent } from "@app/shared-v2/dialogs/location-dialog/location-dialog.component";

const SIDE_TAB_FROM_TOP = 200;
const FIXED_NAV_ITEMS = [
  {
    Title: "Wizard (Form Builder)",
    Icon: {
      IconType: "matIcon",
      ClassName: "build",
    },
    Route: "/v2/wizard",
    Action: null,
    NavItems: [],
    theme: {
      textColor: "rgba(0,0,0,0.87)",
      iconColor: "rgba(0,0,0,0.87)",
    },
  },
];

type NotificationDrawerContentType =
  | "notifications"
  | "events"
  | "customNavItems"
  | "quickLinks";

@Component({
  selector: "app-main-layout-v2",
  templateUrl: "./main-layout-v2.component.html",
  styleUrls: ["./main-layout-v2.component.scss"],
  animations: [slideInAnimation],
})
export class MainLayoutV2Component {
  //appVersion = "2.7.0";

  private alive = true;

  @ViewChild("uploadUserImageDialog") uploadUserImageDialog;

  isSendingFeedback = false;
  appUser: any = undefined;
  navigationItems: any = [];
  msalUserObj: any;
  ownerOrganization: any;
  helpTutorials;
  customNavItems: any[] = [];
  toolbarQuickLinks: any[] = [];
  navigationQuickLinks: any[] = [];
  message: string = "Success! Feedback submitted";
  actionButtonLabel: string = "close";
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2500;
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  addExtraClass: boolean = false;

  eventForm: FormGroup;
  eventTypeList = [
    { label: "email report", value: "email report" },
    { label: "push notifications", value: "push notifications" },
  ];
  frequencyList = [
    { label: "does not repeat", value: "does not repeat" },
    { label: "daily", value: "daily" },
    { label: "weekly", value: "weekly" },
    { label: "monthly", value: "monthly" },
    { label: "annually", value: "annually" },
    { label: "custom", value: "custom" },
  ];

  notifications: Notification[];
  sortNotificationsByField: string = "date";
  NOTIFICATION_TEST_DURATION = 25000;
  NOTIFICATION_TEST_INTERVAL = 5000;
  private _notificationDrawerContent: NotificationDrawerContentType;
  set notificationDrawerContent(content: NotificationDrawerContentType) {
    this._notificationDrawerContent = content;
  }
  get notificationDrawerContent() {
    return this._notificationDrawerContent;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  notificationCount = 0;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: BextAuthenticationService,
    private uiDataService: UiDataService,
    private helpService: HelpService,
    public snackBar: MatSnackBar,
    private feedbackService: FeedbackService,
    private dialog: MatDialog,
    private router: Router,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private locationService: LocationServiceV2,
    private organizationService: OrganizationService,
    private quickLinksService: QuickLinksService
  ) {
    this._getAppUser();
    this.helpService
      .getTutorials()
      .pipe(takeWhile(() => this.alive))
      .subscribe((res) => {
        this.helpTutorials = res && res.length ? res : [];
      });

    this.authService.AppUserState$.pipe(
      takeWhile(() => this.alive),
      skip(1)
    ).subscribe((res) => {
      console.log("app user set to: ", res);
      if (!this.appUser || this.appUser === {}) {
        this.appUser = res;
        this.authService.setAppUser(res);
      } else {
        this.appUser = res;
      }

      // if (!isNullOrUndefined(this.appUser.appUserUiData)) {
      //   this.navigationItems = this.appUser.getUiData('NavigationItems')
      //   console.log('%c Navigation Items: ', 'background: #41ff6b; color: #ff4700;', this.navigationItems);
      // }
    });

    this.notificationService.notifications$
      .pipe(takeWhile(() => this.alive))
      .subscribe((notifications) => {
        let notificationType = (a, b) => {
          return (
            b[this.sortNotificationsByField] - a[this.sortNotificationsByField]
          );
        };
        this.notifications = notifications.sort(notificationType);
        this.notificationCount++;
      });
  }

  ngOnInit(): void {
    this.initNotifications();
    this.eventForm = this.createNewEventForm();
    this.getCustomizationNavItems();
    this.getQuickLinks();
  }

  createNewEventForm() {
    return this.fb.group({
      recipients: this.fb.array([]),
      eventType: ["", Validators.required],
      startDate: ["", Validators.required],
      frequency: ["", Validators.required],
      frequencyUnit: ["", Validators.required],
    });
  }

  submitNewEvent() {}

  initNotifications() {
    // for (let i = 0; i < 10; i++) {
    //   let n = this.notificationService.createRandomNotification();
    //   console.log('%c random notification: ', 'background: #41ff6b; color: #ff4700;', n);
    //   this.notificationService.addNotification(n);
    // }

    this.notificationService
      .getNotificationList()
      .pipe(take(1))
      .subscribe((res) => (error) => {});

    this.notificationService.startRandomGeneration();

    setTimeout(() => {
      this.notificationService.stopRandomGeneration();
    }, 25000);
  }

  toggleRightSideMenu(
    drawerRef: MatDrawer,
    contentType: NotificationDrawerContentType
  ) {
    this.notificationDrawerContent = contentType;
    drawerRef.toggle();
  }

  closeNotificationDrawer(drawerRef: MatDrawer) {
    this.notificationCount = 0;
  }

  navigateToLink(route, drawer) {
    let protocolIndex = route.lastIndexOf("http");
    if (document.getElementsByClassName("mat-drawer-shown").length) {
      drawer.toggle();
    }
    if (protocolIndex === 0) {
      let externalLink = route.substring(protocolIndex);
      window.open(externalLink, "_blank");
    } else {
      this.router.navigate([route]);
    }
  }

  getQuickLinks() {
    forkJoin([
      this.quickLinksService
        .getQuickLinks("organization", "", "", "home_component_quicklinks")
        .pipe(
          take(1),
          catchError((err) => of({ isError: true, error: err }))
        ),
      this.quickLinksService
        .getQuickLinks("organization", "", "", "main_navigation")
        .pipe(
          take(1),
          catchError((err) => of({ isError: true, error: err }))
        ),
    ])
      .pipe(take(1))
      .subscribe(
        ([main_header, main_navigation]: [any, any]) => {
          let qls = {
            main_header: !isNullOrUndefined(main_header) ? main_header : null,
            main_navigation: !isNullOrUndefined(main_navigation)
              ? main_navigation
              : null,
          };
          this.toolbarQuickLinks = qls.main_header;
          this.navigationQuickLinks = qls.main_navigation;
        },
        (error) =>
          console.log(
            "%c GET quicklinks failed: ",
            "background: #ff0000; color: #ffffff;",
            error
          )
      );
  }

  getCustomizationNavItems() {
    this.uiDataService
      .getUiData("settingsnavigation")
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.customNavItems = [...res.CustomizationItems, ...FIXED_NAV_ITEMS];
        },
        (error) =>
          console.log(
            "%c GET customization nav failed: ",
            "background: #ff0000; color: #ffffff;",
            error
          )
      );
  }

  getNavItems() {
    this.uiDataService
      .getUiData("navigation")
      .pipe(
        takeWhile(() => this.alive),
        retryWhen(genericRetryStrategy())
      )
      .subscribe(
        (res) => {
          let appUserUiDataObject = {
            Version: "tempUiData",
            Type: null,
            Sections: null,
            NavigationItems: res,
            CustomizationItems: null,
            AdministrativeItems: null,
            PreferencesItems: null,
            NotificationItems: null,
            RetailDisplayItems: null,
          };
          this.authService.updateAppUserProperty(
            "appUserUiData",
            appUserUiDataObject
          );
        },
        (error) => {
          console.log(
            "%c GET navigation uiData error: ",
            "background: #ff0000; color: #ffffff;",
            error
          );
        }
      );
  }

  openFeedbackSnackBar(message?, action?, duration?, panelClass?, cb?) {
    let config = new MatSnackBarConfig();
    config.duration = this.setAutoHide
      ? duration
        ? duration
        : this.autoHide
      : 0;
    config.panelClass = panelClass
      ? panelClass
      : this.addExtraClass
      ? ["test"]
      : undefined;
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    let snackBarRef = this.snackBar.open(
      message ? message : this.message,
      action ? action : this.action ? this.actionButtonLabel : undefined,
      config
    );
  }

  startTutorial(tut) {
    this.helpService.startTutorial(tut);
  }

  logOut() {
    this.authService.logout();
  }

  sendFeedback(feedback) {
    let commaIdx = feedback.screenshot.indexOf(",") + 1;
    feedback.screenshot = feedback.screenshot.substring(commaIdx);
    this.isSendingFeedback = true;
    this.feedbackService.sendFeedback(feedback).subscribe(
      (res) => {
        this.openFeedbackSnackBar();
        this.isSendingFeedback = false;
        return;
      },
      (error) => {
        this.openFeedbackSnackBar("Failed to send feedback.", "retry");
        this.isSendingFeedback = false;
        return;
      }
    );
  }

  // Kelly added 021920
  previewUserImage() {}

  uploadUserImage(image) {
    console.log("IMAGE CHOSEN: ");
    // PUT to AppUser?
  }

  openUserImageDialog(appUser) {
    console.log("User to Pass: ", appUser);
    let uploadUserImageDialogRef = this.dialog.open(
      UploadUserImageDialogComponent,
      {
        data: appUser,
        autoFocus: false,
        disableClose: false,
      }
    );

    uploadUserImageDialogRef.afterClosed().subscribe((res) => {
      console.log("results from image: ", res);
      if (res) {
        this.uploadUserImage(res);
      }
    });
  }
  //

  private _getAppUser(): void {
    this.msalUserObj = this.authService.getUser();

    let userOrganization$ = this.authService
      .getUserOrganization(this.msalUserObj)
      .pipe(
        takeWhile(() => this.alive),
        retryWhen(genericRetryStrategy())
      );

    let appUser$ = this.authService
      .appUserData(this.msalUserObj.idToken.oid)
      .pipe(
        takeWhile(() => this.alive),
        retryWhen(genericRetryStrategy())
      );

    forkJoin([userOrganization$, appUser$]).subscribe(
      ([userOrg, appUserData]: [any, any]) => {
        // CREATE TEMP NAME HOLDERS AND IMAGE HOLDER
        let FirstName = appUserData.Name.substring(
          0,
          appUserData.Name.indexOf(" ")
        );
        let LastName = appUserData.Name.substring(
          appUserData.Name.indexOf(" ") + 1
        );
        let NameArray = [FirstName, LastName].filter((n) => n);
        appUserData["FirstName"] = NameArray[0];
        appUserData["LastName"] = NameArray[1];
        appUserData["NameArray"] = NameArray;
        appUserData[
          "TempImageURL"
        ] = `https://ui-avatars.com/api/?name=${NameArray.join("+")}`;
        // END TEMP

        let appUserObj = {
          username: this.msalUserObj.idToken.emails[0],
          email: this.msalUserObj.idToken.emails[0],
          name: this.msalUserObj.name,
          appUserId: this.msalUserObj.idToken.oid,
          appUserData: appUserData,
          appUserUiData: Array.isArray(appUserData.UiData)
            ? appUserData.UiData[0]
            : null,
          organizationName: userOrg.OrganizationName,
          organization: userOrg,
          loggedIn: true,
          sessionExpired: false,
          getUiData: function (fieldName?) {
            let uiData =
              fieldName && Array.isArray(this.appUserUiData[fieldName])
                ? this.appUserUiData[fieldName]
                : this.appUserUiData;
            return uiData;
          },
        };
        this.authService.setAppUser(appUserObj);
        if (appUserObj.appUserUiData === null) {
          this.getNavItems();
        }
        return;
      },
      (error) => {
        console.log(
          "%c error GET org and set app user: ",
          "background: #ff0000; color: #ffffff;",
          error
        );
      }
    );
  }

  onQuickLink(action, modalTitle) {
    switch (action.ActionType) {
      case "modal":
        switch (action.ActionKey) {
          case "add-person":
            return this.openAddPersonModal();
          case "add-organization":
            return this.openAddOrganizationDialog();
          case "add-product":
            return this.dialog.open(AddProductComponent, {
              data: { test: true },
            });
          case "add-requisition":
            return this.openAddRequisitionModal();
          case "custom-form":
            if (Object.keys(action).some((v) => isNullOrUndefined(v)))
              return this.openCustomFormModal();
            else {
              let queryParams = action.Parameters.reduce(
                (p, c, i) =>
                  (p += (i === 0 ? `?` : `&`) + `${c.Name}=${c.Value}`),
                ""
              );
              let method = action.Method;
              let endpoint = `/${action.Controller}/${action.EndPoint}${queryParams}`;
              return this.openCustomFormModal(method, endpoint);
            }
          case "location-list":
            return this.openLocationModal();
          case "location-add":
            return this.openAddLocationModal();
          case "add-user":
            return this.openAddUserModal();
          case "add-supplychain":
            return this.openCreateSupplyChainModal();
          case "scan-qr":
            return this.openScanQRCodeModal();
          case "report-list":
            let queryParams = action.Parameters.reduce(
              (p, c, i) =>
                (p += (i === 0 ? `?` : `&`) + `${c.Name}=${c.Value}`),
              ""
            );
            let method = action.Method;
            let endpoint = `/${action.Controller}/${action.EndPoint}${queryParams}`;
            let title = modalTitle;
            return this.openReportListModal(method, endpoint, title);
          default:
            console.log(
              "%c NO SWITCH CASE FOUND FOR - ActionKey: ",
              "background: #fae552; color: #323232;",
              action.ActionKey
            );
        }
        break;
    }
  }

  openLocationModal() {
    this.locationService.getLocations().subscribe((res) => {
      if (res && res.length) {
        this.dialog.open(LocationListV2Component, { data: { locations: res } });
      }
    });
  }

  openAddOrganizationDialog() {
    let ref = this.dialog.open(SimpleAddOrganizationComponent);

    ref.componentInstance.onCreateOrganization
      .pipe(
        takeWhile(() => this.alive),
        switchMap((res) => this.organizationService.addOrganization(res))
      )
      .subscribe(
        (res: any) => {
          ref.close();
          this.snackBar.open(
            "Success! " + res.OrganizationName + "- Organization Created",
            null,
            { duration: 5000 }
          );
          return;
        },
        (error) => {
          console.log("error: ", error);

          return;
        },
        () => {}
      );
  }

  openAddRequisitionModal() {
    this.dialog.open(CreateRequisitionComponent);
  }

  openCustomFormModal(method?: string, endpoint?: string, title?: any) {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.data =
      !method || !endpoint ? undefined : { method: method, endpoint: endpoint };
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = "50%";
    this.dialog.open(CustomFormDialogComponent, dialogConfig);
  }

  openReportListModal(
    method?: string,
    endpoint?: string,
    title?: string,
    selectedReport?: any
  ) {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.data =
      !method || !endpoint
        ? undefined
        : {
            method: method,
            endpoint: endpoint,
            title: title,
            selectedReport: selectedReport,
          };
    // dialogConfig.autoFocus = false;
    // dialogConfig.minWidth = '50%';
    this.dialog.open(ReportListDialogComponent, dialogConfig);
  }

  openAddUserModal() {
    this.dialog.open(AddUserComponent);
  }

  openAddLocationModal() {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.height = "90vh";
    dialogConfig.width = "90vw";
    dialogConfig.panelClass = "custom-location-dialog";
    dialogConfig.autoFocus = false;
    let dialogRef = this.dialog.open(LocationDialogComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe()
      .subscribe((res) => {
        if (res) {
          this.snackBar.open(`${res.data.Name.toUpperCase()} saved`, "close", {
            duration: 2500,
          });
        }
      });
  }

  openScanQRCodeModal() {
    this.dialog.open(ScanTransferQrLotComponent, {
      autoFocus: false,
    });
  }

  openAddPersonModal() {
    forkJoin(
      this.locationService.getLocations(),
      this.organizationService.getOrganizations()
    ).subscribe(([locations, organizations]) => {
      this.dialog.open(AddPersonComponent, {
        data: { locations: locations, organizations: organizations },
      });
    });
  }

  openCreateSupplyChainModal() {
    this.dialog.open(CreateSupplyChainComponent, {
      autoFocus: false,
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}

// ---------------------------------------------------------------------
// --------------------- Side Tab Scroll Directive ---------------------
// ---------------------------------------------------------------------

@Directive({
  selector: "[sideTabSroll]",
})
export class SideTabScrollDirective {
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    let offSetTop = e.target["scrollingElement"].offsetTop;
    let overlayPresent = offSetTop < 0;
    let scrollPosition = !overlayPresent
      ? e.target["scrollingElement"].scrollTop
      : offSetTop * -1;
    this.renderer.setStyle(
      this.el.nativeElement,
      "top",
      scrollPosition + SIDE_TAB_FROM_TOP + "px"
    );
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}
}
