import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

export interface ListItem {
  id: string;
  dateCreated: string;
  title?: string;
  subsection1?: string;
  subsection2?: string;
  icon?: string;
  nodes?: any[];
  checked?: boolean;
}

@Component({
  selector: 'bext-supply-chain-list',
  templateUrl: './supply-chain-list.component.html',
  styleUrls: ['./supply-chain-list.component.scss']
})

export class SupplyChainListComponent {

  @Input('listItems') listItems: ListItem[];
  @Input('url') ITEM_URL: string;
  @Input() initialSelected: any = '';
  @Input() customEventHandler = false;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router, private cd: ChangeDetectorRef) { }

  ngAfterContentInit() {
    if (this.initialSelected && this.initialSelected.length) {
      let matchingSupplyChain = this.listItems.find(item => item.title == this.initialSelected);
      matchingSupplyChain['checked'] = true;
      setTimeout(() => { 
          this.emitChange();
      }, 800);
    }
  }

  linkTo(item: ListItem, reportSummary?: string) {
    console.log('ITEM SELECTED: ', item);
    if (!this.ITEM_URL && !reportSummary) return;
    else if ((!this.ITEM_URL || this.ITEM_URL) && reportSummary) {
      this.router.navigate([`v2/report/supplychain/${item.id}`])
    }
    else if (this.ITEM_URL && !reportSummary) {
      this.router.navigate([`${this.ITEM_URL}/${item.id}/${item.nodes[0].NodeData.NodeId}`])
    }
  }
                                                                                                                                                                                                                      
  emitChange() {
    this.itemSelected.emit(this.listItems.filter(item => item['checked']))
  }

  LOGIT() {
    console.log('link to report: ', );
  }

}
