import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { BextAuthenticationService } from './authentication.service';

@Injectable()
export class BextAuthenticationHttpInterceptor implements HttpInterceptor {

  constructor(private bextAuthenticationService: BextAuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.bextAuthenticationService.getAuthenticationToken())
      .switchMap(token => {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(req);
      });
  }
}