import { GeoLocationService } from '@app/core/services/geo-location.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartadminFormsModule } from '../forms/smartadmin-forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmartadminWidgetsModule } from '../widgets/smartadmin-widgets.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DndModule } from 'ngx-drag-drop';
import { LocationService } from '@app/core/services/backend/locations.service';
import { GoogleMapService } from '@app/core/services/google-map.service';
import { MapStyleService } from '@app/features/maps/shared/map-style.service';
import { BootstrapModule } from '../bootstrap.module';
import { LocationFormComponent } from './form/form.component';
import { LocationMapComponent } from './location-map/location-map.component';

@NgModule({
  imports: [
    CommonModule,
    SmartadminFormsModule,
    FormsModule,
    ReactiveFormsModule,
    SmartadminWidgetsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    DndModule,
    BootstrapModule
  ],
  providers: [
    LocationService,
    MapStyleService,
    GoogleMapService,
    GeoLocationService
  ],
  declarations: [LocationFormComponent, LocationMapComponent],
  exports: [LocationFormComponent, LocationMapComponent]
})
export class SharedLocationModule { }
