import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { of } from 'rxjs';
import { takeWhile, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'bext-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent implements OnInit {

  @Output('imageUrlChange') imageUrlChange: EventEmitter<any> = new EventEmitter<any>();

  private alive = true;

  image: FormGroup;

  constructor(private fb: FormBuilder) {
    this.image = this.fb.group({
      url: ''
    });
  }

  ngOnInit() {
    this.image.valueChanges
      .pipe(
        takeWhile(() => this.alive),
        map((image) => image.url),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(
        res => {
          this.imageUrlChange.emit(res);
          return;
         },
        error => {
          console.log('image url vaule changes - error: ', error);
          return;
        }
      );
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
