import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule, MatButtonModule, MatIconModule } from '@angular/material';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { DeleteEntityDialogComponent } from './delete-entity-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    NgxSpinnerModule
  ],
  declarations: [DeleteEntityDialogComponent],
  entryComponents: [DeleteEntityDialogComponent],
  providers: [NgxSpinnerService]
})
export class DeleteEntityDialogModule { }
