import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../../../models/field-config.interface';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bext-ngx-datetime-local',
  templateUrl: './datetime-local.component.html',
  styleUrls: ['./datetime-local.component.scss']
})
export class NgxDateTimeLocalComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor() { }
  ngOnInit() { }
}
