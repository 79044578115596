import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import { BextAuthenticationService } from '../services';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: BextAuthenticationService) {}

  canActivate(): Observable<boolean> {
    // return Observable.of(true);
    return Observable.of(this.authService.getUser() != null);
  }
}
