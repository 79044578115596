import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { NgxFormFieldDirective } from './containers/ngx-form-field/ngx-form-field.directive';
import { NgxFormComponent } from './containers/ngx-form/ngx-form.component';
import { MaterialModule } from '@app/shared/angular-material.module';
import { NgxButtonComponent } from './containers/button/button.component';
import { NgxCheckboxComponent } from './containers/checkbox/checkbox.component';
import { NgxDateComponent } from './containers/date/date.component';
import { NgxDateTimeLocalComponent } from './containers/datetime-local/datetime-local.component';
import { NgxInputComponent } from './containers/input/input.component';
import { NgxRadiobuttonComponent } from './containers/radiobutton/radiobutton.component';
import { NgxSelectComponent } from './containers/select/select.component';
import { NgxFileUploadComponent } from './containers/file-upload/file-upload.component';
import { FilePreviewOverlayModule } from '../file-preview-overlay/file-preview-overlay.module';
import { FilePreviewOverlayService } from '../file-preview-overlay/file-preview-overlay.service';
import { UploadService } from '@app/core/services/backend/uploads.service';

export const ngxFormComponents = [
  NgxButtonComponent,
  NgxCheckboxComponent,
  NgxDateComponent,
  NgxDateTimeLocalComponent,
  NgxInputComponent,
  NgxRadiobuttonComponent,
  NgxSelectComponent,
  NgxFileUploadComponent,
]

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FilePreviewOverlayModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  declarations: [
    NgxFormFieldDirective,
    NgxFormComponent,
    ...ngxFormComponents
  ],
  providers: [
    FilePreviewOverlayService,
    UploadService
  ],
  exports: [
    NgxFormFieldDirective,
    NgxFormComponent
  ],
  entryComponents: [
    ...ngxFormComponents
  ]
})

export class NgxFormModule { }