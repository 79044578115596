import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "@app/shared/shared.module";
import {MaterialModule} from "@app/shared/angular-material.module";
import {QRCodeModule} from "angularx-qrcode";
import {PrintLotQrComponent} from "@app/shared-v2/lots/print-lot-qr/print-lot-qr.component";

@NgModule({
  declarations: [PrintLotQrComponent],
  imports: [
    CommonModule,
      SharedModule,
      MaterialModule,
      QRCodeModule
  ], entryComponents: [PrintLotQrComponent],
  exports: [PrintLotQrComponent]
})
export class PrintLotQrModule { }
