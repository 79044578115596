import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { LocationService } from '@app/core/services/backend/locations.service';
import { Subscription, forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatPaginator, MatTableDataSource, MatSort, MatDialogRef, MatDialogConfig, MatSnackBar } from '@angular/material';
import { AddLocationV2Component } from '@app/shared-v2/location/add-location/add-location.component';
import { AddLocationComponent } from '@app/features/setup/location/add/add.component';
import { LocationDialogComponent } from '@app/shared-v2/dialogs/location-dialog/location-dialog.component';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'bext-list-location',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class LocationListV2Component implements OnInit, OnDestroy {

  private alive = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input('isMobile') isMobile = false;

  locations;
  queryString: string = '';
  pageSize = 25;
  isLoading;

  displayedColumns = [
    'Name',
    'Country',
    'City',
    'State',
    'Latitude',
    'Longitude',
    'Elevation',
    'ElevationUnit',
    'Address',
    'Actions'
  ];

  // filteredLocations;
  dataSource: MatTableDataSource<any[]>;

  customCsvParsingString =
    'Name,Country,City,State,Latitude,Longitude,Elevation,ElevationUnit,Address,Description,\nMountain Cotton Organics Farm 1,United States,Almont,Colorado,38.755527,-106.478526,10322,feet,93 Main Street Tincup CO 81210';
  constructor(
    private locationService: LocationService,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    // this.spinnerService.show();
    this.locationService.getLocations()
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        data => {
          console.log('locations: ', data);
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.locations = data;
          this.setSearchQuery('');
          // this.spinnerService.hide();
        },
        err => {
          // this.spinnerService.hide();
        }
      );
  }

  uploadingCsv(e) {
    console.log('%c upload locations csv event: ', 'background: #41ff6b; color: #ff4700;', e);
    // this.spinnerService.show();
    if (!e.inProgress) {
      this.loadData();
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  setSearchQuery(text) {
    // if (text && text.length) {
    //   this.filteredLocations = this.locations.filter(location => {
    //     return Object.keys(location).some(key => {
    //       if (typeof location[key] == "string") {
    //         return location[key].toLowerCase().includes(this.queryString.toLowerCase())
    //       }
    //     })
    //   })
    // } else if (text.length == 0) {
    //   this.filteredLocations = this.locations;
    // }
    this.queryString = text;
  }

  onDelete(location, index) {
    this.locationService.deleteLocation(location).subscribe(
      res => {
        console.log('%c deleted LOcation: ', 'background: #ff0000; color: #ffffff;', res);
        this.loadData();
      },
      error => {
        console.log(error);
      }
    );
  }

  addLocationDialog() {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.height = '90vh';
    dialogConfig.width = '90vw';
    dialogConfig.panelClass = 'custom-location-dialog';
    dialogConfig.autoFocus = false;
    let dialogRef = this.dialog.open(LocationDialogComponent, dialogConfig);

    dialogRef.afterClosed()
      .pipe(
        
      )
      .subscribe(
        res => {
          console.log('%c LOCATION DIALOG CLOSED: ', 'background: #fae552; color: #323232;', res)
          if (res) {
            this.locations.push(res.data);
            this.dataSource.data = [...this.locations];
            this.snackBar.open(`${res.data.Name.toUpperCase()} saved`, 'close', {duration: 2500})
          }
        }
      )
  }

  editLocation(location) {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.height = '90vh';
    dialogConfig.width = '90vw';
    dialogConfig.panelClass = 'custom-location-dialog';
    dialogConfig.autoFocus = false;
    dialogConfig.data = { location: JSON.parse(JSON.stringify(location)) };
    let dialogRef = this.dialog.open(LocationDialogComponent, dialogConfig);

    dialogRef.afterClosed()
      .pipe(
        
      )
      .subscribe(
        res => {
          console.log('%c LOCATION DIALOG CLOSED: ', 'background: #fae552; color: #323232;', res)
          if (res) {
            let oldLocationIndex = this.locations.findIndex((l) => l.id === res.data.id);
            this.locations.splice(oldLocationIndex, 1, res.data);
            this.dataSource.data = [...this.locations];
            this.snackBar.open(`${res.data.Name.toUpperCase()} updated`, 'close', {duration: 2500})
          }
        }
      )
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}