import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs'
import { ValuationService } from '.';
import { MAT_DIALOG_DATA } from '@angular/material';
import { takeWhile } from 'rxjs/operators';

export interface IValuationState {
  list?: any[];
  valuationName?: string;
  valuationData?: any;
}

@Injectable({
  providedIn: 'root'
})
export class ValuationHelperService {

  initialValuationState = {
    list: [],
    valuationName: undefined,
    valuationData: undefined
  };

  private _valuationSubject: BehaviorSubject<IValuationState> = new BehaviorSubject<IValuationState>(this.initialValuationState);

  get valuation() {
    return this._valuationSubject;
  }

  get valuation$() {
    return this._valuationSubject.asObservable();
  }

  get valuationData() {
    return this._valuationSubject.getValue().valuationData;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private valuationService: ValuationService) {
  }

  valuationGet(data) {
    return this.valuationService.getValuation(data.id);
  }

  updateValuationState(list, valuationName, valuationData) {
    let newValuationState = {
      list: list,
      valuationName: valuationName,
      valuationData: valuationData
    };
    this.valuation.next(newValuationState)
  }

  updateValuationData(index, field, value) {
    let newValuationState = this.valuation.getValue();
    newValuationState.list = newValuationState.list.map((e, i) => {
      if (index === i) {
        return {
          ...e,
          [field]: value
        }
      }
      return e;
    });
    newValuationState.valuationData[0].ProductTokenValues = newValuationState.list;
    this.valuation.next(newValuationState);
  }

  getControl(index, fieldName) {
  }


}