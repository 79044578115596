import { tap } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { LocationService } from '@app/core/services/backend/locations.service';
import { CSVFileService } from '@app/core/services/csv-file.service';

class Person {
  constructor(
    public Name: string,
    public PersonType: string,
    public LocationName: string = "",
    public FarmerId: string = "",
    public NameOfICS: string = "",
    public Notes: string = "",
    public LocationId: string = "",
    public ImageId: any = "",
    public Images: any[] = []
  ) {

  }
}

@Component({
  selector: 'bext-upload-csv-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.css']
})
export class UploadCsvButtonComponent implements OnInit {

  @ViewChild('fileImportInput') fileImportInput;
  @Input() buttonTitle: string;
  @Input() csvType: string;
  @Input() locations: any[];
  
  @Output() uploadComplete: EventEmitter<any> = new EventEmitter<any>();
  @Output() loading: EventEmitter<any> = new EventEmitter<any>();

  uploads$;

  constructor(private papa: Papa, private csvFileService: CSVFileService, private locationService: LocationService) {}

  ngOnInit() {
  }

  onCsvSelected(e) {

    let data = e.target.files[0];
    let options = {
      header: true,
      complete: (results, file) => {
        if (this.csvType && results.data) {
          let data = results.data;
          if (this.csvType === 'person') {
            let personPayload = [];
            data = data.filter(person => !!person.Name);
            data.forEach(person => {
              var firstLetter = person.PersonType.split('')[0].toLowerCase();
              var type = (firstLetter === 'f') ? 'Farmer' : 'Collector';
              var location = this.locations.find(loc => loc.Name.toLowerCase() === person.LocationName.toLowerCase());
              if (!location) {
                console.log('%c UPLOAD CSV FAILED: LOCATION NAME WAS INCORRECTLY SPELLED, THEREFORE IT WAS NOT ADDED TO THE PAYLOAD: ', 'background: #ff0000; color: #ffffff;', '');
              }
              else {
                console.log('person object before creating Person object: ', person, location);
                let newPerson = new Person(person.Name, type, location.Name, person.FarmerId, person.NameOfICS, person.Notes, location.id)
                newPerson.Images.push(person.ImageUrl);
                personPayload.push(newPerson);
              }
            });
            this.runCsvUploaderForPerson(personPayload);
          }
          else {
            this.runCsvUploader(data);
          }
        }
      }
    };
    this.papa.parse(data, options);
    this.fileImportInput.nativeElement.value = null;
  }

  runCsvUploader(arr) {
    var stats = { inProgress: true, percentComplete: 0, results: [], item: undefined };
    this.loading.emit(stats);
    arr.forEach((obj, i) => {
      stats.inProgress = true;
      stats.percentComplete = (i + 1)  / arr.length;
      stats.results.push(obj);
      if (this.csvType === 'location') {
        obj.Longitude = (obj.Longitude === '' || obj.Longitude === undefined) ? 0 : parseFloat(obj.Longitude);
        obj.Latitude = (obj.Latitude === '' || obj.Latitude === undefined) ? 0 : parseFloat(obj.Latitude);
        obj.Elevation = (obj.Elevation === '' || obj.Elevation === undefined) ? 0 : parseFloat(obj.Elevation);
        obj.ElevationUnit = (obj.ElevationUnit === '' || obj.ElevationUnit === undefined) ? 'feet' : obj.ElevationUnit;
      }
      this.csvFileService.uploadCsv(this.csvType, obj).subscribe();
      stats.item = obj;
      this.loading.emit(stats)
    });
    stats.inProgress = false;
    this.loading.emit(stats)
  }

  runCsvUploaderForPerson(arr) {
    console.log('person payload: ', arr);
    var stats = { inProgress: true, percentComplete: 0, results: [], item: undefined };
    this.loading.emit(stats);
    arr.forEach((obj, i) => {
      stats.inProgress = true;
      stats.percentComplete = (i + 1)  / arr.length;
      stats.results.push(obj);
      this.csvFileService.uploadCsv(this.csvType, obj).subscribe();
      stats.item = obj;
      this.loading.emit(stats);
    });
    stats.inProgress = false;
    this.loading.emit(stats);
  }

}
