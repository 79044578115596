import { Component, OnInit, Input, HostListener, EventEmitter } from '@angular/core';
import { GlobeService } from '../../../core-v2/services/globe.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GlobeConfig, IGlobeConfig } from '@app/shared-v2/models/globe/globe-config.model';

declare var Earth: any;

@Component({
  selector: 'bext-globe',
  templateUrl: './base-globe.component.html',
  styleUrls: ['./base-globe.component.scss']
})
export class BaseGlobeComponent {

  private _loadAsync: boolean = true;
  @Input('loadAsync')
  set loadAsync(bool: boolean) {
    this._loadAsync = bool;
  }
  get loadAsync() {
    return this._loadAsync;
  }

  private _globeConfig: object = {
    location: { lat: 0, lng: 0 },
    zoom: 0.925,
    zoomMin: 0.925,
    zoomMax: 2.5,
    zoomable: true,
    zoomSpeed: 0.75,
    quality: (window.innerWidth <= 1024) ? 4 : 5,
    light: 'simple',
    transparent: false,
    mapLandColor: 'rgba(122, 121, 121, 1)',
    mapSeaColor: 'rgba(240, 240, 240, 1)',
    mapBorderColor: 'rgba(255, 255, 255, 1)',
    mapBorderWidth: 0.25,
    mapHitTest: true
    // mapStyles: '#FR, #ES, #DE, #IT, #GB, #BE, #NL, #LU, #DK, #SE, #FI, #IE, #PT, #GR, #EE, #LV, #LT, #PL, #CZ, #AT, #BG, #MT, #SK, #SI, #HR, #HU, #RO, #CY { fill: #e1f3fd; } #GL, #GF { fill: #a9c3e4; }'
  };
  @Input('globeConfig')
  set globeConfig(config: IGlobeConfig) {
    this._globeConfig = new GlobeConfig(config ? config : undefined);
  }
  get globeConfig() {
    return this._globeConfig;
  }

  public globeReady: EventEmitter<any> = new EventEmitter();
  public scriptLoaded = new BehaviorSubject<boolean>(false);

  constructor(
    private globeService: GlobeService
  ) {}

  ngOnInit() {
    this.globeService.loadEarthJs()
      .then(val => {
        // console.log('%c LOAD EARTH: ', 'background: #41ff6b; color: #ff4700;', val);
        this.scriptLoaded.next(true);
        this.scriptLoaded.complete();
      })
  }

  ngAfterViewInit() {
    this.scriptLoaded.asObservable()
      .pipe(
        filter((loaded) => !loaded)
      )
      .subscribe(
        res => {
          // console.log('%c globe config: ', 'background: #fae552; color: #323232;', this.globeConfig);
          let earth = new Earth('baseGlobe', this.globeConfig)
          this.globeReady.emit(earth);
          // console.log('EARTH OBJECT: ', earth);
        }
      )
  }

  

}
