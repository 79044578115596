import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OcrComponent } from './ocr.component';
import { OcrService } from '@app/core/services/backend/ocr.service';
import { CanvasModule } from '@app/shared/canvas/canvas.module';
import { UploadService } from '@app/core/services/backend/uploads.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    CanvasModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [OcrComponent],
  exports: [OcrComponent],
  providers: [OcrService, UploadService]
})
export class OcrModule { }
