import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplyChainListComponent } from './supply-chain-list.component';
import { MatListModule, MatIconModule, MatDividerModule, MatCheckboxModule, MatTooltipModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { PipesV2Module } from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    PipesV2Module,
    FormsModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDividerModule,
    RouterModule
  ],
  declarations: [SupplyChainListComponent],
  exports: [SupplyChainListComponent]
})
export class SupplyChainListModule { }
