import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'transferred-token-table',
  templateUrl: './transferred-token-table.component.html',
  styleUrls: ['./transferred-token-table.component.css']
})
export class LotTransferredTokenTableComponent implements OnInit {
  @Input() tokens: Array<any>;
  constructor() { }

  ngOnInit() {
  }

}
