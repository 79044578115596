import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material";
import { take, map } from "rxjs/operators";
import { FileNameDialogComponent } from "./file-name-dialog/file-name-dialog.component";

@Component({
  selector: "bext-download-file-button",
  templateUrl: "./download-file-button.component.html",
  styleUrls: ["./download-file-button.component.scss"],
})
export class DownloadFileButtonComponent implements OnInit {
  private _iconName: string = "";
  private _fileUrl: string;

  @Input("icon-name")
  set iconName(name: string) {
    this._iconName = name;
  }
  get iconName() {
    return this._iconName;
  }

  @Input("fileUrl")
  set fileUrl(url: string) {
    this._fileUrl = url;
  }
  get fileUrl() {
    return this._fileUrl;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // console.group('component inputs');
    // console.log('%c icon name: ', 'background: #fae552; color: #323232;', this.iconName);
    // console.log('%c file object: ', 'background: #fae552; color: #323232;', this.fileUrl);
    // console.groupEnd();
  }

  downloadFile() {
    let timeStamp = () => {
      let d = new Date();
      let year: any = d.getFullYear();
      let month: any =
        d.getMonth() + 1 < 10 ? "0" + d.getMonth() + 1 : "" + d.getMonth() + 1;
      let date: any = d.getDate() < 10 ? "0" + d.getDate() : "" + d.getDate();
      return `${year}${month}${date}`;
    };

    let exportFile = (fileName) => {
      return fetch(this.fileUrl)
        .then((res) => res.blob())
        .then((blob) => {
          console.log(
            "%c blob: ",
            "background: #41ff6b; color: #ff4700;",
            blob
          );
          if (window.navigator.msSaveOrOpenBlob) {
            return window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            return setTimeout(() => {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }, 150);
          }
        });
    };

    let dialogRef = this.dialog.open(FileNameDialogComponent, {
      disableClose: true,
      data: {
        fileName:
          this.fileUrl.split("/").pop().split(".")[0] || `file_${timeStamp}`,
        fileType: "." + this.fileUrl.split(".").pop(),
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          exportFile(res);
        }
      });

    // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }
}
