import { VideosDialogComponent } from './videos-dialog.component';
import { MatExpansionModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatCardModule, MatDialogModule, MatSnackBarModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatListModule, MatProgressBarModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxFormModule } from '@app/shared-v2/ngx-form/ngx-form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilComponentsModule } from '@app/shared-v2/util-components/util-components.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatExpansionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatListModule,
    MatProgressBarModule,
    NgxFormModule,
    FormsModule,
    ReactiveFormsModule,
    UtilComponentsModule
  ],
  declarations: [
    VideosDialogComponent
  ],
  entryComponents: [
    VideosDialogComponent
  ],
  exports: [
    VideosDialogComponent
  ],
})
export class VideosDialogModule { }
