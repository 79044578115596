import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable, timer } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { last, scan, takeWhile } from 'rxjs/operators';



@Component({
  selector: 'bext-delete-entity-dialog',
  templateUrl: './delete-entity-dialog.component.html',
  styleUrls: ['./delete-entity-dialog.component.scss']
})
export class DeleteEntityDialogComponent implements OnInit {

  deletedFailedMessage: string = null;
  timer$: Observable<number> = null;
  message: string = null;

  @ViewChild('deleteBtn', { read: ElementRef }) deleteBtn: ElementRef;
  @ViewChild('cancelBtn', { read: ElementRef }) cancelBtn: ElementRef;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
        entityValue: string,
        entityName: string,
        entityId: string,
        deleteEntityObservable: Observable<any>,
        afterDeleteEntityObservable?: Observable<any>
    },
    public dialogRef: MatDialogRef<DeleteEntityDialogComponent>,
    private spinnerService: NgxSpinnerService
  ) {
    console.log('Delete Entity Dialog - data: ', this.data);
  }
    
  ngOnInit() {
    console.log(this.deleteBtn.nativeElement)
    console.log(this.cancelBtn.nativeElement)
    this.spinnerService.hide();
  }

  deleteEntity() {
    this.spinnerService.show();
    this.data.deleteEntityObservable
    .subscribe(
        res => {
          console.log('DELETE entity: ', res);
          this.deletedFailedMessage = null;
          if (!!this.data.afterDeleteEntityObservable) {
            this.data.afterDeleteEntityObservable.subscribe(data => {
                this.spinnerService.hide();
            });
          } else { //performing reload page if no afterDeleteEntityObservable is given, in order to update the latest data
            
            //hide the spin
            this.spinnerService.hide();

            //disable the buttons to ensure reload
            this.deleteBtn.nativeElement.disabled = true;
            this.cancelBtn.nativeElement.disabled = true;
            
            //init timer to show count down message
            this.timer$ = timer(0, 1000)
                            .pipe(
                                scan(acc => --acc, 5),
                                takeWhile(x => x >= 0)
                            )

            //reload when timer is done
            const sub = this.timer$.pipe(last()).subscribe(() => window.location.reload())
          }
          
          return;
        },
        error => {
          console.log('DELETE entity failed: ', error);
          this.deletedFailedMessage = error;
          this.spinnerService.hide();
          return;
        }
      );
  }



  closeDialog() {
    this.dialogRef.close();
  }  

}
