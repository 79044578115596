import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppUserRoutes } from '@app/core/api-routes.enum';

@Injectable()
export class AppUserService {

  BASE_URL = environment.baseApiUrl;

  private preferencesSubjcet = new Subject<any>();

  constructor(private http: HttpClient) { }

  getUsers(): Observable<any> {
    return this.http.get<any>(this.BASE_URL + AppUserRoutes.BASE_URL);
  }

  getUser(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + AppUserRoutes.BASE_URL + '/' + id);
  }
  
  addUser(payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + AppUserRoutes.BASE_URL, payload);
  }
  
  editUser(id, payload): Observable<any> {
    return this.http.put<any>(this.BASE_URL + AppUserRoutes.BASE_URL + '/' + id, payload);
  }

  deleteUser(id): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + AppUserRoutes.BASE_URL + '/' + id);
  }

  getUserByEmail(email) {
    return this.http.get<any>(this.BASE_URL + AppUserRoutes.BASE_URL + '/Email/' + email);
  }

  addOrgAppUser(payload) {
    return this.http.post<any>(this.BASE_URL + AppUserRoutes.BASE_URL + '/addorgappuser', payload)
  }

  getOperators() {
    return this.http.get<any>(this.BASE_URL + AppUserRoutes.BASE_URL + '/GetOperators')
  }

  setPreferences() {
    
  }
}