import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmapComponent } from './gmap.component';
import { MapStyleService } from '@app/features/maps/shared/map-style.service';
import { GoogleMapService } from '@app/core/services/google-map.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [GmapComponent],
  exports: [GmapComponent],
  providers: [GoogleMapService, MapStyleService]
})
export class GmapModule { }
