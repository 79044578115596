import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { BextAuthenticationService } from '@app/core-v2/services/authentication.service';

@Injectable()
export class BextAuthenticationHttpInterceptor implements HttpInterceptor {

  constructor(private bextAuthenticationService: BextAuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.bextAuthenticationService.getAuthenticationToken())
      .pipe(
        switchMap(token => {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
          return next.handle(req);
        })
      )
  }
}