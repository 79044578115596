import { Component, OnInit, Inject, Input, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadService } from '@app/core/services/backend/uploads.service';
import { NodeService } from '@app/core/services/backend/node.service';

@Component({
  selector: 'sa-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class NodeImagesComponentV2 implements OnInit {
@Input() public node:any;
@ViewChild('fileInputNode') fileInputNode: ElementRef;
public currentNode:any;
public loadingMessage = '';
public file: File = null;
public currentImage;
public showImageModal = false;
public showSpinner = false;
constructor(
    public dialogRef: MatDialogRef<NodeImagesComponentV2>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private uploadService: UploadService,
    private nodeService: NodeService){}

  ngOnInit() {
    this.moveDataToNode();
  }

  ngOnChanges() {
    this.moveDataToNode();
  }

  moveDataToNode() {
    this.node = this.data && this.data.node ? this.data.node : this.node ? this.node : undefined;
    this.currentNode = this.node;
  }


  onFileSelected(event, level) {
  this.showSpinner = true;
    let file = event.target.files[0];
    let id;

    if (!file) {
      return;
    }

    // this.spinner.show();
    this.loadingMessage = 'Uploading image';

    this.file = <File>event.target.files[0]
    const fd = new FormData();
    fd.append('files', this.file, this.file.name);

    console.log('file: ', file);
      id = this.node.id;
      this.uploadService.uploadNodeImage(id, fd).subscribe(res => {
        if (res === null || !res) {
          console.log('%c image POST successful but response body was: ', 'background: #fae552; color: #323232;', res);
          // this.spinner.hide();
          this.showSpinner = false;
          this.loadingMessage = '';
          this.file = null;
        }
        if (this.node.NodeData.Images === null) {
          let imageArr = [];
          imageArr.push(res);
          this.node.NodeData.Images = imageArr;
          this.showSpinner = false;
        }
        else {
          this.loadingMessage = 'Complete';
          setTimeout(() => {
            this.loadingMessage = 'Fetching Node';
            this.refreshNode()

          }, 750);
          setTimeout(() => {
            // this.spinner.hide();
            this.loadingMessage = '';
          }, 1500);

        this.file = null;
        this.fileInputNode.nativeElement.value = null;
      }
    },
        error => {
          console.log('%c Error - POST image failed: ', 'background: #ff0000; color: #ffffff;', error);
          this.spinner.hide();
          this.loadingMessage = '';
          this.file = null;
          this.fileInputNode.nativeElement.value = null;
          return;
          
        })
  }

  onClickImage(image) {
    this.currentImage = image;
    this.showImageModal = true;
    // this.imageModal.show();
  }

  onCloseImage(modal) {
    this.currentImage = '';
    // modal.hide();
    this.showImageModal = false;
  }

  deleteImage(image) {
    console.log('image to delete', image);
    this.uploadService.deleteImage(image.id).subscribe(
      res => {
          console.log('node images: ', this.node.NodeData.Images);
          // this.data = this.node.NodeData.Images.filter(item => item !== image.id);
          this.node.NodeData.Images = this.node.NodeData.Images.filter(item => item.id !== image.id);
          console.log('node images after delete: ', this.node.NodeData.Images);
        return;
      },
      error => console.log('%c failed to delete Node image: ', 'background: #ff0000; color: #ffffff;', error)
    )
  }

  refreshNode() {
    this.showSpinner = true;
      this.nodeService.getNodeById(this.node.id).subscribe(res => {
        if (res) {
          this.data.node = res;
          this.moveDataToNode();
          this.showSpinner = false;
        }
      })
    }

  // refreshNode() {
  //   this.ngOnInit();
  // }

  closeDialog(){
    this.dialogRef.close();
  }  

}
