import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportListDialogComponent } from './report-list-dialog.component';
import { MatProgressSpinnerModule, MatButtonModule, MatIconModule, MatDialogModule, MatMenuModule, MatTableModule } from '@angular/material';
import { LoadersModule } from '@app/shared-v2/loaders/loaders.module';
import { MatTablesModule } from '@app/shared-v2/mat-tables/mat-tables.module';
import { RouterModule } from '@angular/router';

//import { SaveReportComponent } from '../../../features-v2/reports/save-report/save-report.component';

@NgModule({
  imports: [
    CommonModule,
    LoadersModule,
    MatTablesModule,
    MatMenuModule,
    MatTableModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule
  ],
  declarations: [ReportListDialogComponent],
  exports: [ReportListDialogComponent],
  entryComponents: [ReportListDialogComponent]
})
export class ReportListDialogModule { }
