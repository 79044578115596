import { flattenObject } from '@app/shared/utils/object-utils';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { UploadService } from "@app/core/services/backend/uploads.service";
import { fromEvent } from "rxjs";
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentService } from '@app/core/services/backend/document.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'bext-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {

  @Input('dataObject') dataObject: any;
  @Input() documents;
  @Input() enableChangeListener = false;
  @Input() disabled = false;
  @Input() hideCancelButton = false;
  @Input() disableOCR = false;
  @Input() preventFormResetOnSubmit = false;
  @Input() type: any;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  parentUiMetaData;
  uploadingFile = false;
  sectionName;
  sectionVisibile = false;
  objectProps;
  file;
  imgUrls;
  date;
  level;
  fileInput;
  isUsingOCR;
  section;
  lotCurrentWeightUiMetaDataExists = false;

  constructor(private uploadService: UploadService, private spinnerService: NgxSpinnerService, private documentService: DocumentService) { }

  ngOnInit() {
    console.log('selected node lot documents: ', this.documents);
    this.bindFormData(this.dataObject);
    this.onImageUrlChange();
  }

  ngAfterContentInit() {
    if (this.documents && this.documents.length) {
        this.documents.forEach(document => {
          this.getTemplate(document);
        })
    }
  }

  getTemplate(doc) {
   
      this.documentService.getDocument(doc.BextDocumentId).subscribe(res => {
        doc.Data = res;
        if (!doc['template']) {
          this.documentService.getDocumentTemplate(doc['Data']['DocumentType']).subscribe(
              res => {
                // this.documentTemplate = res;
                doc['template'] = res;
                if (res && res['UiData'] && res['UiData'].length) {
                  doc['template'].UiData[0].Sections[0].Fields.forEach(field => {
                    field.value = doc.Data.DocumentDatas.find((data) => {
                      return data.Name == field.name.split('.')[0]
                    }) ? doc.Data.DocumentDatas.find((data) => {
                      return data.Name == field.name.split('.')[0]
                    })[field.name.split('.')[1]] : '';
                  });
                  return;
                }
              },
              error => {
                console.log('error getting documenttemplate: ', error);
                return;
              },
              () => {
                doc.loading = false;
                // this.openModal(this.documentViewerModal);
                // this.spinnerService.hide();
              }
          );
        }
      })
  
   
  }


  

  ngOnChanges(changes: SimpleChanges): void {
    if (this.enableChangeListener && changes.dataObject && !changes.dataObject.firstChange) {
      // console.log('dynamic form changes: ', changes);
      this.form = undefined;
      this.bindFormData(changes.dataObject.currentValue);
    }
  }



  onImageUrlChange() {
    this.form.valueChanges.subscribe(
      val => {
        this.imgUrls = (val[this.level]) ? val[this.level] : undefined
      }
    )
  }
  
  openImage(url) {
    window.open(url, '_blank');
  }

  spliceUrl(name, i) {
    this.form.controls[name].setValue('');
  }

  getTimeStamp(e, form, name) {
    // e.stopPropagation();
    this.date = new Date();
    // console.log('%c get time stamp: ', 'background: #41ff6b; color: #ff4700;', this.date);
    form.controls[name].setValue(this.date.getFullYear() + '-' + ((this.date.getMonth() < 10 ? '0' : '') + (this.date.getMonth() + 1)) + '-' + ((this.date.getDate() < 10 ? '0' : '') + this.date.getDate()) + 'T' + (this.date.getHours() < 10 ? '0' : '') + this.date.getHours() + ':' + ((this.date.getMinutes() < 10 ? '0' : '') + this.date.getMinutes()));
  }

  onFileSelected(event, level) {
    this.spinnerService.show()
    let fd = new FormData();
    [<File>event.target.files[0]].forEach(document => {
      fd.append('files', document, document.name);
    })

    this.uploadService.upload(fd)
    .pipe(
      take(1)
    )
    .subscribe(
      res => {
        // console.log(res, level);
        this.form.controls[level].setValue(res.ImageURL);
        console.log('upload service res: ', res);
        console.log('this.form.controls and level: ', level, this.form.controls[level]);
        return;
      },
      error => {
        console.log('error uploading image: ', error)
        return;
      },
      () => {
        this.spinnerService.hide();
      }
    );
  }

  onUploadFile(sectionName, level, UiDataFields, input, section) {
    // this.uploadFileModal.config.ignoreBackdropClick = true;
    // this.uploadFileModal.show();
    this.sectionName = sectionName;
    this.level = level;
    this.parentUiMetaData = UiDataFields;
    this.fileInput = input;
    this.section = section;
  }

  formatDateToInputFormat(inputType, timestamp) {
    let isoString = new Date(timestamp).toISOString();

    let dateFormatter = (trunc) => (ts) => ts.substring(0, trunc);

    let dateTime = dateFormatter(10);
    let dateTimeLocal = dateFormatter(16);

    switch (inputType) {

      case 'datetime-local':
        return dateTimeLocal(isoString);
        // return datetime_local_value;
      case 'datetime':
        return dateTime(isoString);
        // return datetime_value;

      case 'date':
        return dateTime(isoString);
        // return date_value;

      default:
        console.log('%c FAILED TO CONVERT DATE TO CORRECT FORMAT: (original date <-> converted date)', 'background: #ff0000; color: #ffffff;', timestamp, ' <->', isoString);
        return;
    }

  }

  bindFormData(dataObj? ) {
    const formGroup = {};
    // let checkUiMetaDataForLotCurrentWeightField = (currUiMetaData) => {
    //   this.lotCurrentWeightUiMetaDataExists = (currUiMetaData.match(/lotcurrentweight|lotcurrentweightdb/i) === null) ? this.lotCurrentWeightUiMetaDataExists : true;
    // }
    this.dataObject.Sections.forEach(section => {
      section.Fields.forEach(field => {
        // checkUiMetaDataForLotCurrentWeightField(field.name);
        field.type = field.type.toLowerCase();
        this.lotCurrentWeightUiMetaDataExists = (field.name.match(/lotcurrentweight|lotcurrentweightdb/i) === null) ? this.lotCurrentWeightUiMetaDataExists : true;
        if (field.visible || (!field.visible && (field.validation.create.required || field.validation.analysis.required || field.validation.transfer.required))) {
          let fieldValue = field.value || '';
          if (field.type === 'date' || field.type === 'datetime' || field.type === 'datetime-local') {
            fieldValue = fieldValue ? this.formatDateToInputFormat(field.type, fieldValue) : fieldValue;
          }
          else if (field.type === 'select' || field.type === 'radio') {
            let optionExists = (o) => o.value.toUpperCase() === fieldValue.toUpperCase();
            let optionFound = field.options.some(optionExists);
            if (!optionFound && fieldValue !== '') {
              console.log('%c option doesnt exist in options array: ', 'background: #fae552; color: #323232;', { value: fieldValue, label: fieldValue }, field.options);
              field.options = [{ value: fieldValue, label: fieldValue }, ...field.options]
              field.options = field.options.map((o) => {
                o.value = o.value
                return o;
              })
            }
          }
          // console.log('%c form field: ', 'background: #fae552; color: #323232;', field);
          formGroup[field.name] = new FormControl({ value: fieldValue, disabled: this.disabled }, this.mapValidators(field.validation[this.type === 'edit' ? 'create' : this.type]));
        }
      });
    })
    console.log('%c dynamic form data: ', 'background: #fae552; color: #323232;', this.dataObject);
    this.form = new FormGroup(formGroup);
  }

  private mapValidators(validators) {
    const formValidators = [];
    if (validators) {
      for (const validation of Object.keys(validators)) {
        if (validation === 'required' && validators['required']) {
          formValidators.push(Validators.required);
        } else if (validation === 'min') {
          formValidators.push(Validators.min(validators[validation]));
        }
      }
    }
    return formValidators;
  }

  onSubmit(form) {
    let submitEventObject = { action: this.type, formValues: form.value, form: form};
    form.submitted = true;
    if (form.valid) {
      event.preventDefault();
      event.stopPropagation();
      submitEventObject = this.appendLotCurrentWeightUiMetaDataToPayload(this.lotCurrentWeightUiMetaDataExists, submitEventObject);
      this.submit.emit(submitEventObject);
      if (!this.preventFormResetOnSubmit) {
        this.form.reset();
      }
    }
  }

  onCancel(form) {
    let submitEventObject = { action: 'cancel', formValues: form.value, form: form };
    event.preventDefault();
    event.stopPropagation();
    this.form.reset();
    this.cancel.emit(submitEventObject)
  }

  hasVisibleFields(section) {
    let hasVisibleFields = false;
    if (section.Fields && section.Fields.length) {
      section.Fields.some((field) => { hasVisibleFields = ((field.visible || (!field.visible && (field.validation.create.required || field.validation.analysis.required || field.validation.transfer.required))) ? field.visible : hasVisibleFields); return hasVisibleFields });
    }
    return hasVisibleFields;
  }

  appendLotCurrentWeightUiMetaDataToPayload(currentWeightUiMDExists, payload) {
    
    if (!currentWeightUiMDExists) {
      payload.formValues['LotMetaData.LotCurrentWeight.Measure'] = '';
      payload.formValues['LotMetaData.LotCurrentWeight.Unit'] = '';
      Object.keys(payload.formValues).forEach((formKey) => {
        if (formKey.match(/lotstartweight.measure/i) !== null) {
          payload.formValues['LotMetaData.LotCurrentWeight.Measure'] = payload.formValues[formKey];
        }
        else if (formKey.match(/lotstartweight.unit/i) !== null) {
          payload.formValues['LotMetaData.LotCurrentWeight.Unit'] = payload.formValues[formKey]
        }
      });
      // console.log('%c payload after appending: ', 'background: #fae552; color: #323232;', payload);
      return payload;
    } else {
      // console.log('%c payload doesn\'t require appending - original payload: ', 'background: #fae552; color: #323232;', payload);
      return payload;
    }
  }

}
