import { OnInit, Inject, Component, ElementRef, ViewChild, NgZone, Input, EventEmitter, Output } from '@angular/core';
import { take, pluck, filter } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PersonService } from '@app/core-v2/services/person.service';
import { IPersonTypeDto } from '../models/person/personType.interface';
import { IPersonDetailDto } from '../models/person/person-detail.interface';
import { IPersonGroupDto } from '../models/person/personGroup.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'bext-personv2 ',
  templateUrl: './personv2.component.html',
  styleUrls: ['./personv2.component.scss']
})
export class PersonV2Component implements OnInit{

  @ViewChild('search')
  public searchElementRef: ElementRef;

  private alive = true;

  private _existingPerson: any;
  @Input()
  set existingPerson(val) {
    this._existingPerson = val;
  }
  get existingPerson() {
    return this._existingPerson;
  }

  personTypes : IPersonTypeDto[];
  personGroups : IPersonGroupDto[]
  selectedPersonGroup : IPersonGroupDto;
  selectedPersonType : IPersonTypeDto;

  form: FormGroup;

  personProps = [
    { Name: 'PersonName', Type: 'input', SubType: 'text', Required: false, Placeholder: 'Name', DefaultValue: null, Validations: []},
    { Name: 'PersonGroup', Type: 'select', SubType: undefined, Required: false, Placeholder: 'Person Group', DefaultValue: null, Validations: []},
    { Name: 'PersonType', Type: 'select', SubType: undefined, Required: false, Placeholder: 'Person Type', DefaultValue: null, Validations: []},
    { Name: 'PersonExternalIdCode', Type: 'input', SubType: 'text', Required: false, Placeholder: 'Local Id Number', DefaultValue: null, Validations: []},
    { Name: 'PersonTagIdCode', Type: 'input', SubType: 'text', Required: false, Placeholder: 'Tag Id Number', DefaultValue: null, Validations: []},
    // { Name: 'Id', Type: 'input', SubType: 'input', Required: false, Placeholder: 'ID Number', DefaultValue: null, Validations: []},
  ];

  mapReady = false;

  @Output('processActionEvent') onPersonChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<PersonV2Component>,
    @Inject(MAT_DIALOG_DATA) public personDetailDto: IPersonDetailDto,
    private personService: PersonService
  ) {

  }

  ngOnInit() {
    this.initPersonGroups();
    this.initPersonTypes();
    this.initializeForm(this.personDetailDto);
  }

  initializeForm(personRecord?: object | undefined) {

    const formDataObj = {};
    this.personProps.forEach((p) => {
      formDataObj[p.Name] = new FormControl(
        personRecord ? personRecord[p.Name] : p.DefaultValue,
        this._bindValidations(!personRecord && p.Validations.length ? p.Validations : []),
      );
    })
    this.form = new FormGroup(formDataObj)
  }

  initPersonGroups() {
    this.personService
      .getPersonGroupDtos()
      .pipe(take(1))
      .subscribe((res: any) => {
        this.personGroups = res;
      });
  }

  initPersonTypes() {
    this.personService
      .getPersonTypeDtos()
      .pipe(take(1))
      .subscribe((res: any) => {
        this.personTypes = res;
      });
  }

  getFormControl(ctrlName) {
    return this.form.get(ctrlName) as FormControl;
  }

  setFormControlValue(ctrlName, val, os: boolean = false) {
    this.getFormControl(ctrlName).setValue(val, { onlySelf: os});
  }

  onPersonTypeSelect(c) {
    this.selectedPersonType = this.personTypes.find((n) => n.Name === c.value)
    console.log('%c SELECTED PERSON TYPE: ', 'background: #fae552; color: #323232;', this.selectedPersonType);
  }

  onPersonGroupSelect(c) {
    this.selectedPersonGroup = this.personGroups.find((n) => n.Name === c.value)
    console.log('%c SELECTED PERSON GROUP: ', 'background: #fae552; color: #323232;', this.selectedPersonGroup);
  }

  personCRUDOperation(method) {
    this.form['submitted'] = true;
    let payload = this.form.value;
    console.log("payload",payload);
    switch (method) {
      case 'POST':
        //this.personDetailDto.Name = payload.
        let addedPersonDetailDto : IPersonDetailDto;
        console.log("this.selectedPersonGroup",this.selectedPersonGroup);
        if (this.selectedPersonGroup){this.personDetailDto.PersonGroupId = this.selectedPersonGroup.PersonGroupId;}
        console.log("this.personDetailDto",this.personDetailDto);
        if (this.selectedPersonType){this.personDetailDto.PersonTypeId = this.selectedPersonType.PersonTypeId;}
        console.log("this.personDetailDto",this.personDetailDto);
        payload = Object.assign(this.personDetailDto, payload)
        // console.log('%c PERSON payload: ', 'background: #41ff6b; color: #ff4700;', payload);
        this.onPersonChangeEvent.emit({ eventType: 'CRUD', method: method, status: 'processing', statusCode: null, data: payload, loading: true})
        this.personService.addPerson(payload)
          .pipe(
            take(1)
          )
          .subscribe(
            res => {
              // console.log('%c POST person: ', 'background: #41ff6b; color: #ff4700;', res);
              this.onPersonChangeEvent.emit({ eventType: 'CRUD', method: method, status: 'success', statusCode: 200, data: res, loading: false})
              this.personDetailDto = res;
              this.dialogRef.close(this.personDetailDto);
            },
            error => console.log('%c POST person error: ', 'background: #ff0000; color: #ffffff;', error),
            () => console.log('person event complete')
          )

        break;

      case 'PUT':
        if (this.selectedPersonGroup){this.personDetailDto.PersonGroupId = this.selectedPersonGroup.PersonGroupId;}
        if (this.selectedPersonType){this.personDetailDto.PersonTypeId = this.selectedPersonType.PersonTypeId;}
        payload = Object.assign(this.personDetailDto, payload)
        this.onPersonChangeEvent.emit({ eventType: 'CRUD', method: method, status: 'processing', statusCode: null, data: payload, loading: true})
        this.personService.editPerson(payload)
          .pipe(
            take(1)
          )
          .subscribe(
            res => {
              // console.log('%c PUT person: ', 'background: #41ff6b; color: #ff4700;', res);
              this.onPersonChangeEvent.emit({ eventType: 'CRUD', method: method, status: 'success', statusCode: 200, data: payload, loading: false})
              this.dialogRef.close(this.personDetailDto);
            },
            error => console.log('%c PUT person error: ', 'background: #ff0000; color: #ffffff;', error),
            () => console.log('person event complete')
          )
        break;

      case 'CANCEL':
            // console.log('%c CANCEL FORM event: ', 'background: #fae552; color: #323232;');
            let person = Object.assign(this.existingPerson || {}, this.form.value)
            this.onPersonChangeEvent.emit({eventType: 'CANCEL', status: null, statusCode: null, data: person, loading: null})
        break;
    
      default:
        console.log('%c METHOD FOR PERSON CRUD OPERATION NOT FOUND: ', 'background: #fae552; color: #323232;', method);
        break;
    }
  }
  
  private _bindValidations(validations: any) {
    if (validations.length > 0) {
      let validationsList = validations.reduce((prev, v) => {
        let validationName = v.name.toLowerCase();
        if (validationName === 'required') {
          prev.push(Validators.required)
        }
        if (validationName === 'pattern') {
          prev.push(Validators.pattern(v.validator))
        }
        return prev;
      }, []);
      return Validators.compose(validationsList);
    }
    return null;
  }
  closeDialog() {
    if (Object.keys(this.dialogRef).length) {
      this.dialogRef.close();
    }
  }
  ngOnDestroy(): void {
    this.alive = false;
  }

}
