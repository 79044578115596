export enum AccountRoutes {
  BASE_URL = "/accounts",
}

export enum AppUserRoutes {
  BASE_URL = "/appusers",
}

export enum BlockchainRoutes {
  BASE_URL = "/blockchains",
  LOT = "/lot",
}

export enum DocumentRoutes {
  BASE_URL = "/BextDocument",
  TEMPLATES_URL = "/BextDocumentTemplate",
}

export enum FeedbackRoutes {
  BASE_URL = "/feedback",
  BUG = "/bug",
}

export enum LocationRoutes {
  BASE_URL = "/locations",
}

export enum LotRoutes {
  ABSORBED_URL = "/absorbed/absorbed",
  BASE_URL = "/lotsV1",
  LOTS_SUMMARY_URL = "/lotsummary",
  TRANSFER_URL = "/transfer",
  TRANSFER_V2_URL = "/transferv2",
  TRANSFER_SPLIT_URL = "/transfersplit",
  TRANSFER_LOT_LIST_URL = "/gettransferlotlist",
  CREATE_TRANSFER_URL = "/CreateAndTransfer",
}

export enum MarkerRoutes {
  BASE_URL = "/marker",
  DELETEMARKER_URL = "/deleteLotMarkers",
  EDITMARKER_URL = "/editLotMarkers",
}

export enum MarketPlaceRoutes {
  BASE_URL = "/marketplace",
  PRODUCT_LIST = "/productlist",
  PRODUCT_CONFIG = "/productconfig",
  PRODUCT_REPORT = "/productreport",
}

export enum NodeRoutes {
  BASE_URL = "/nodesV1",
  NODE_LIST_DTOS = "/nodelistdtos",

  BASE_URLV2 = "/node",
  CREATE_NODE_V1 = "/CreateNodeV1",
  NODE_SUMMARIES = "/summaries",
  NODE_UIDATA = "/uidata",
  NODE_TEMPLATE_URL = "/NodeTemplate",
  NODE_LOTS_URL = "/nodelot",
  RETAIL_REPORT_DISPLAY_URL = "/retaildisplay",
}

export enum AssetRoutes {
  BASE_URL = "/asset",
  NODE_SUMMARY = "/summary",
  NODE_ASSETS = "/node",
  DATA_URL = "/data",
  LOT_URL = "/lot",
  OVERVIEW_URL = "/overview",
  BOTANICALNAMES = "/botanicalnames",
  PARTNERNAMES = "/partnerNames",
  ASSETPARTNERS = "/AssetPartner",
  ASSETDONORS = "/AssetDonor",
  GET_ASSET = "/asset/getasset",
  UPDATE_ASSET = "/asset/updateasset",
}

export enum NotificationRoutes {
  BASE_URL = "/notifications",
}

export enum OpenCageRoutes {
  BASE_URL = "https://api.opencagedata.com/geocode/v1",
}

export enum OrganizationRoutes {
  BASE_URL = "/organizations",
}

export enum PartnerOrganizationRoutes {
  BASE_URL = "/PartnershipOrganizations",
  VISIBLE_PARTNERS = "/GetVisablePartnerList",
  REQUEST = "/Request",
  GRANT_NODE_PERMISSIONS = "/grantnodepermissions",
  SENT_REQUESTS = "/sentrequests",
  RECEIVED_REQUESTS = "/receivedrequests",
  PENDING_STATUS = "/pending",
  EXPIRED_STATUS = "/expired",
  ACCEPTED_STATUS = "/accepted",
  DENIED_STATUS = "/denied",
  PENDING_RECEIVED_REQUESTS = "/receivedrequests/pending",
  CHANGE_RECEIVED_REQUEST_STATUS = "/changereceivedrequeststatus",
  CHANGE_SENT_REQUEST_STATUS = "/changesentrequeststatus",
  PARTNERSHIP_VISIBLE = "/visible",
}

export enum PersonsRoutes {
  BASE_URL = "/Persons",
  FARMER_URL = "/typeandfarmerid/Farmer/",
  COLLECTOR_URL = "/typeandfarmerid/Collector/",
  PERSONTYPEDTOS = "/persontypedtos",
  PERSONGROUPDTOS = "/persongroupdtos",
  PERSON_LIST_SUMMARY_DTOS = "/personlistsummarydtos",
  PERSON_DETAIL_DTO = "/persondetaildto"
}

export enum ProductRoutes {
  BASE_URL = "/products",
  PRODUCT_LIST_SUMMARY_DTOS = "/productlistsummarydtos",
}

export enum QuickLinksRoutes {
  BASE_URL = "/quicklinks",
  CREATE_LOT_URL = "/createlot",
  MENU_URL = "/menu",
}

export enum ReportRoutes {
  BASE_URL = "/reports",
  BASE_URL_V2 = "/reportsv2",
  LOT_REPORT_V2 = "/lot",
  LOTPREVIOUSNEXT_URL = "/lotnextprevious01V1",
  LOTTIMELINE_URL = "/timeline01",
  LOTTIMELINE_MARKER_URL = "/timelinemarker01",
  NESPRESSO_POD_SOURCE_URL = "/nespressopodsource",
  SIMPLELOT_URL = "/simpleLot",
  RETAIL_SINGLELOT_URL = "/retailsinglelot",
  RETAIL_IMGS_FARMER_URL = "/retailfarmerimages",
  RETAIL_IMGS_SUPPLYCHAIN_URL = "/retailsupplychainimages",
  RETAIL_IMGS_NODE_URL = "/retailnodeimages",
  RECURSIVE_LOT_LIST_URL = "/lothistory/ReportName=LotHistory",
}

export enum ReportV2Routes {
  BASE_URL = "/reportsv2",
  LOT_REPORT = "/lot",
  RECURSIVE_LOT_HISTORY_LIST_URL = "/lothistory/ReportName=LotHistory",
}

export enum SupplyChainRoutes {
  BASE_URL = "/supplychains",
  VALUATION_URL = "/valuation",
}

export enum SustainabilityRoutes {
  BASE_URL = "/sustainability",
  BASE_GROUP_INSTANCE_URL = "/sustainabilitygroupinstances",
  ADD_GROUP_INSTANCE_URL = "/addsustainabilitygroupinstancetonode",
  ADD_GROUP_URL = "/addgroup",
  ADD_LEVEL_URL = "/addLevel",
  ADD_CRITERIA_URL = "/addcriteria",
  GROUPS_URL = "/groups",
  SUSTAINABILITYGROUP_URL = "/sustainabilitygroup",
  SUSTAINABILITYGROUP_REPORT_URL = "/groupreport01",
  UPLOAD_GROUP_URL = "/groups/upload",
  GROUP_INSTANCE_NODE_URL = "/node",
}

export enum TransferLotsRoutes {
  BASE_URL = "/TransferLots",
  TRANSFER_V2_URL = "/transferV2",
}

export enum UiDataRoutes {
  BASE_URL = "/uidata",
  NAVIGATION_ITEMS_URL = "/navigation",
}

export enum UserRoutes {}

export enum UploadRoutes {
  BASE_URL = "/uploads",
  UPLOAD_URL = "/upload",
}

export enum ValuationRoutes {
  BASE_URL = "/valuation",
  NODE_VALUATION_URL = "/nodevaluationdata",
}

export enum VerifyDataRoutes {
  BASE_URL = "/verifydata",
  WORKFLOW = "/workflow",
  PUSHOBJECT = "/pushobject",
  VERIFY = "/verify",
  USER = "/user",
}

export enum WizardRoutes {
  BASE_URL = "/wizard",
}
