import { SmartadminWidgetsModule } from '@app/shared/widgets/smartadmin-widgets.module';
import { BootstrapModule } from '@app/shared/bootstrap.module';
import { WizardInstanceModule } from '@app/shared/master-wizard/wizard-instance/wizard-instance.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { WizardService } from '@app/core/services/backend/wizard.service';
import { GandalfComponent } from './gandalf.component';
import { GandalfInputsComponent } from './gandalf-inputs/gandalf-inputs.component';
import { SmartadminUiModule } from '@app/shared/ui/smartadmin-ui.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WizardInstanceModule,
    SmartadminUiModule,
    SmartadminWidgetsModule,
    BootstrapModule,
    DragDropModule
  ],
  providers: [WizardService],
  declarations: [GandalfComponent, GandalfInputsComponent],
  exports: [GandalfComponent, GandalfInputsComponent]
})
export class GandalfModule { }
