/*****************************************/
/********* UNIT CONVERSION OBJECT ********/
/*****************************************/
//*** check if @param: emailaddressstring contains @param: word

export const Conversion = {
  metersToFeet: function(m) {
    return m * 3.28084;
  },
  feetToMeters: function(ft) {
    return ft * 0.3048;
  }
}