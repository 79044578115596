import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmartadminWizardsModule } from '@app/shared/forms/wizards/smartadmin-wizards.module';
import { WizardService } from '@app/core/services/backend/wizard.service';
import { UploadService } from '@app/core/services/backend/uploads.service';
import { WizardInstanceComponent } from './wizard-instance.component';
import { SmartadminWidgetsModule } from '@app/shared/widgets/smartadmin-widgets.module';
import { LoadingSpinnerModule } from '@app/shared/loading-spinner/loading-spinner.module';
import { MaterialModule } from '@app/shared/angular-material.module';


@NgModule({
  imports: [
    CommonModule,
    SmartadminWidgetsModule,
    FormsModule,
    SmartadminWizardsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    MaterialModule
  ],
  providers: [
    WizardService,
    UploadService
  ],
  declarations: [WizardInstanceComponent],
  exports: [WizardInstanceComponent]
})
export class WizardInstanceModule { }