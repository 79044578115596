import { TransferOneExistingManyExistingModule } from './transfer-one-existing-many-existing/transfer-one-existing-many-existing.module';
import { NgModule } from '@angular/core';
import { TransferOneOneModule } from './transfer-one-one/transfer-one-one.module';
import { TransferOneManyModule } from './transfer-one-many/transfer-one-many.module';
import { TransferManyOneModule } from './transfer-many-one/transfer-many-one.module';

const transferModules = [
  TransferOneOneModule,
  TransferOneExistingManyExistingModule,
  TransferOneManyModule,
  TransferManyOneModule,
]

@NgModule({
  imports: [...transferModules],
  exports: [...transferModules]
})
export class LotTransfersModule { }
