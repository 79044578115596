import { AnalyticData } from "@app/shared-v2/models/analytic-data.model";
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarRef, SimpleSnackBar } from "@angular/material";
import { Component, OnInit, Inject, ViewChild, EventEmitter } from "@angular/core";
import { takeWhile, take, map, mergeMap, tap, switchMap } from "rxjs/operators";
import { DocumentService } from "@app/core-v2/services/document.service";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { timer, BehaviorSubject, of, forkJoin } from "rxjs";
import { isNullOrUndefined } from "util";
import { UploadService, ImageService } from "@app/core-v2/services";
import * as _ from "lodash";

@Component({
  selector: "bext-documents-dialog",
  templateUrl: "./documents-dialog.component.html",
  styleUrls: ["./documents-dialog.component.scss"],
})
export class DocumentsDialogComponent implements OnInit {
  @ViewChild("file") file;
  @ViewChild("simplefile") simplefile;

  public files: Set<File> = new Set();

  private alive = true;

  private _isLoading = false;
  get loading() {
    return this._isLoading;
  }
  set loading(bool) {
    this._isLoading = bool;
  }

  private _showAddDocumentForm = false;
  get showAddDocumentForm() {
    return this._showAddDocumentForm;
  }
  set showAddDocumentForm(bool) {
    this._showAddDocumentForm = bool;
  }

  private _showEditDocumentForm = false;
  get showEditDocumentForm() {
    return this._showEditDocumentForm;
  }
  set showEditDocumentForm(bool) {
    this._showEditDocumentForm = bool;
  }

  private _selectedDocTemplate;
  get selectedDocTemplate() {
    return this._selectedDocTemplate;
  }
  set selectedDocTemplate(doc) {
    this._selectedDocTemplate = doc;
  }

  private _selectedDocument;
  get selectedDocument() {
    return this._selectedDocument;
  }
  set selectedDocument(doc) {
    this._selectedDocument = doc;
  }

  public isUpdating = false;
  public isSavingDocument = false;
  public formValid = false;
  public runFormValidation$ = new BehaviorSubject<boolean>(false);
  public documentListChange = new EventEmitter<any>();
  public newDocumentForm;
  public documents: any[];
  public documentsLoading = false;
  public docTypeForm: FormGroup;
  public docTemplates: any = null;
  public progress;
  public canBeClosed = true;
  public showCancelButton = true;
  public uploading = false;
  public uploadSuccessful = false;
  public uploadedImages: any[] = [];
  public uploadsImageHistory: any[] = [];
  public headerContent: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DocumentsDialogComponent>,
    private documentService: DocumentService,
    private uploadService: UploadService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private imageService: ImageService
  ) {
    // console.log('dialog data: ', this.data);
    this.docTypeForm = this.fb.group({
      type: new FormControl(null, [Validators.required]),
    });
  }

  get docTypeFormCtrl() {
    return this.docTypeForm.get("type") as FormControl;
  }

  ngOnInit() {
    this.loading = true;
    this.headerContent = this.data.dialogHeaderContent;
    this.documentService
      .getDocuments(this.data.entityType, this.data.id)
      .pipe(
        takeWhile(() => this.alive),
        map((docs: any[]) => {
          if (isNullOrUndefined(docs)) return [];
          return docs.map((doc) => {
            doc["template"] = null;
            return doc;
          });
        })
      )
      .subscribe(
        (res) => {
          console.log(res);
          this.documents = res.map((doc) => {
            if (Array.isArray(doc.Images) && doc.Images.length) {
              doc.Images.forEach((image) => {
                image["ImageThumbnailURL"] = this.imageService.getThumbnailUrl(image.ImageURL);
                image["ImageExtension"] = this.imageService.getImageExtension(image.ImageURL);
              });
            }
            return doc;
          });
          console.log(this.documents);
          this.documentListChange.emit(this.documents);
          this.loading = false;
        },
        (error) => {
          console.log("%c GET documentsV2 - document dialog for - " + this.data.entityType + ": ", "background: #fae552; color: #323232;", error);
          this.loading = false;
        }
      );

    // if (this.data.isSimpleFileUpload) {
    //   this.hiddenfileupload.nativeElement.click()
    // }
  }

  docTypeSelectionChange(e) {
    this.selectedDocTemplate = undefined;
    this.progress = undefined;
    this.canBeClosed = true;
    this.showCancelButton = true;
    this.uploading = false;
    this.uploadSuccessful = false;
    this.files = new Set();
    console.log(e.value);
    this.selectedDocTemplate = e.value;
  }

  startNewDocumentProcess() {
    this.showAddDocumentForm = true;
    if (this.docTemplates) return;
    this.documentsLoading = true;

    this.documentService
      .getDocumentTemplates(this.data.entityType, this.data.id)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (res) => {
          this.documentsLoading = false;
          this.docTemplates = res;
        },
        (error) => {
          console.log("GET document templates - error: ", error);
        }
      );
  }

  getErrorMessage() {
    if (this.docTypeFormCtrl.hasError("required")) {
      return "please make a selection";
    }
  }

  editDocument(doc) {
    this.showEditDocumentForm = false;
    this.selectedDocument = undefined;
    doc.UiData[0].Sections = doc.UiData[0].Sections.map((section) => {
      section.Fields = section.Fields.map((field) => {
        let documentDataIndex = doc.DocumentDatas.findIndex((d) => d.Name === field.name || d.Key === field.name);
        let analyticDataObject = doc.DocumentDatas[documentDataIndex];
        let analyticDataObjectValue = analyticDataObject ? analyticDataObject[field.name.split(".")[field.name.split(".").length - 1]] : undefined;
        field.value = analyticDataObjectValue ? analyticDataObjectValue : field.value;
        return field;
      });
      return section;
    });
    this.selectedDocument = doc;
    setTimeout(() => {
      this.showEditDocumentForm = true;
    }, 300);
    // this.runFormValidation$.next(true);
  }

  onAddDocumentFormChanges(values) {
    this.newDocumentForm = values;
  }

  onFormValidityChanges(e) {
    this.formValid = e;
  }

  onEditFormChanges(values) {
    let documentDatas = this.selectedDocument.DocumentDatas;
    Object.keys(values).forEach((k) => {
      let keyMatch = (datum) => datum.Name === k || datum.Key === k;
      let adIndex = documentDatas.findIndex(keyMatch);
      let keyValueName = k.split(".")[k.split(".").length - 1];
      let path = `DocumentDatas[${adIndex}].${keyValueName}`;
      _.set(this.selectedDocument, path, values[k]);
    });
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  addSimpleFile() {
    this.simplefile.nativeElement.click();
  }

  onFilesAdded() {
    //let files : { [key: string]: File };
    if (this.data.isSimpleFileUpload) {
      this.files.clear();
      //console.log(this.files)
      const singleFile = this.simplefile.nativeElement.files[0] as File;
      this.files.add(singleFile);
    } else {
      const files = this.file.nativeElement.files;
      for (let key in files) {
        if (!isNaN(parseInt(key))) {
          this.files.add(files[key]);
        }
      }
    }

    //console.log(this.files)

    if (this.data.isSimpleFileUpload) {
      this.simplefile.nativeElement.value = "";
    } else {
      this.file.nativeElement.value = "";
    }
    this.uploadFiles();
  }

  uploadFiles(selectedDocument?) {
    // IF STATEMENT allows for disabling Add More Files
    // if (this.uploadSuccessful) {
    //   return;
    // }

    // set the component state to "uploading"
    this.uploading = true;

    // start the upload and save the progress map

    this.progress = this.uploadService.uploadV2(this.files);
    for (const key in this.progress) {
      this.progress[key].progress.subscribe(
        (val) => {
          if (typeof val === "object") {
            if (val.status === 200 && val.body) {
              val.body["ImageThumbnailURL"] = this.imageService.getThumbnailUrl(val.body.ImageURL);
              val.body["ImageExtension"] = this.imageService.getImageExtension(val.body.ImageURL);
              console.log("response: ", val.body);
              this.uploadedImages.push(val.body);
              this.uploadsImageHistory = [...this.uploadedImages];
              if (selectedDocument) {
                this.selectedDocument.Images.push(val.body);
              }
              console.log("updated images array: ", this.uploadedImages);
              console.log("this.selectedDocument: ", this.selectedDocument);

              if (this.data.isSimpleFileUpload) {
                this.newDocumentForm = {
                  "DocumentName.Measure": "SimpleUploadedFile",
                };
              }
            }
          } else {
            console.log("progress: ", val);
          }
        },
        (error) => {
          this.canBeClosed = true;

          // ... the upload was successful...
          this.uploadSuccessful = true;

          // ... and the component is no longer uploading
          this.uploading = false;

          // ... and reset progress
          this.progress = undefined;

          // ... and reset file Set
          this.files.clear();
          let snackBarRef = this.openSnackBar("failed to upload file", "CLOSE");
        }
      );
    }

    // convert the progress map into an array
    let allProgressObservables = [];
    for (let key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
    }

    // Adjust the state variables

    // The dialog should not be closed while uploading
    this.canBeClosed = false;

    // Hide the cancel-button
    this.showCancelButton = false;

    // When all progress-observables are completed...
    forkJoin(allProgressObservables).subscribe((end) => {
      // ... the dialog can be closed again...
      this.canBeClosed = true;

      // ... the upload was successful...
      this.uploadSuccessful = true;

      // ... and the component is no longer uploading
      this.uploading = false;

      // ... and reset progress
      this.progress = undefined;

      console.log("Here");
      // ... and reset file Set
      this.files.clear();

      console.log(this.files);

      if (this.data.isSimpleFileUpload) {
        this.formValid = true;
      }
    });
  }

  onRemoveFile(file) {
    this.files.forEach((f) => {
      if (f.name === file.name) this.files.delete(file);
    });
  }

  doneUploadSimpleFile() {
    this.saveNewDocument();
    this.closeDialogRef();
  }

  saveNewDocument() {
    if (!this.formValid) {
      this.runFormValidation$.next(true);
      return;
    }

    let bextDocument = new BextDocumentPayload();

    //console.log(this.newDocumentForm)
    Object.keys(this.newDocumentForm).forEach((key) => {
      let value = this.newDocumentForm[key];
      bextDocument.addAnalyticData(key, value);
    });

    //console.log("this.data.images[0].ImageName", this.data.images[0].ImageName);
    //field.value = this.newDocumentForm[field.name];

    if (this.data.isSimpleFileUpload) {
      //bextDocument.addAnalyticData("DocumentName.Measure", "SimpleUploadedFile");
      bextDocument.DocumentType = "UploadEntity";
      bextDocument.DocumentTemplateId = "627390a6-b95d-4403-96a2-36b23a8b6cbe";
    } else {
      bextDocument.DocumentType = this.selectedDocTemplate.BextDocumentTemplateType;
      bextDocument.DocumentTemplateId = this.selectedDocTemplate.id;

      bextDocument.UiData = [...this.selectedDocTemplate.UiData];
      bextDocument.UiData[0].Sections.forEach((section) => {
        section.Fields.forEach((field) => {
          // console.log(field)
          console.log(this.newDocumentForm[field.name]);
          field.value = this.newDocumentForm[field.name];
        });
      });
    }

    bextDocument.Images = [...this.uploadedImages];

    //console.log([...this.selectedDocTemplate.UiData])

    console.log("%c bextdocument payload: ", "background: #41ff6b; color: #ff4700;", bextDocument);
    this.documentService
      .addDocument(this.data.entityType, this.data.id, bextDocument)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          console.log("%c POST document success: ", "background: #41ff6b; color: #ff4700;", res);
          let updatedDocument = res;
          if (Array.isArray(res.Images) && res.Images.length) {
            updatedDocument.Images = res.Images.map((image) => {
              image["ImageThumbnailURL"] = this.imageService.getThumbnailUrl(image.ImageURL);
              image["ImageExtension"] = this.imageService.getImageExtension(image.ImageURL);
              return image;
            });
          }
          this.documents = [...this.documents, updatedDocument];
          this.documentListChange.emit(this.documents);
          this.cancelAddDocument();
        },
        (error) => console.log("%c POST document failed: ", "background: #ff0000; color: #ffffff;", error)
      );
  }

  saveDocument() {
    this.isSavingDocument = true;
    this.documentService.editDocument(this.selectedDocument.id, this.selectedDocument).subscribe(
      (res: any) => {
        let originalDocumentIndex = this.documents.findIndex((doc) => doc.id === this.selectedDocument.id);
        let updatedDocument = res;
        if (Array.isArray(res.Images) && res.Images.length) {
          updatedDocument.Images = res.Images.map((image) => {
            image["ImageThumbnailURL"] = this.imageService.getThumbnailUrl(image.ImageURL);
            image["ImageExtension"] = this.imageService.getImageExtension(image.ImageURL);
            return image;
          });
        }
        this.documents.splice(originalDocumentIndex, 1, updatedDocument);
        this.documentListChange.emit(this.documents);
        this.isSavingDocument = false;
        this.cancelEditDocument();
      },
      (error) => {
        this.isSavingDocument = false;
      }
    );
  }

  deleteDocument(doc) {
    let snackBarRef = this.openSnackBar("Are you sure? Action cannot be undone.", "DELETE");
    snackBarRef
      .afterDismissed()
      .pipe(
        take(1),
        switchMap((dismissal) => {
          if (dismissal.dismissedByAction) {
            return this.documentService.deleteDocument(this.data.entityType, this.data.id, doc.id);
          } else {
            return of(dismissal);
          }
        })
      )
      .subscribe(
        (res: any) => {
          this.documents = this.documents.filter((d) => d.id !== doc.id);
          this.documentListChange.emit(this.documents);
        },
        (error) => console.log("%c snackbar dismissal failed: ", "background: #ff0000; color: #ffffff;", error),
        () => {
          this.isUpdating = false;
        }
      );
  }

  deleteImage(img) {
    img["deleting"] = true;
    this.uploadService.deleteImage(img.id).subscribe(
      (res) => {
        let imageIndex = this.uploadedImages.findIndex((i) => i.id === img.id);
        this.uploadedImages.splice(imageIndex, 1);
        this.uploadedImages = [...this.uploadedImages];
        // if (this.uploadedImages.length === 0) {
        //   this.uploadSuccessful = false;
        // }
      },
      (error) => {
        console.log("%c delete image failed: ", "background: #ff0000; color: #ffffff;", error);
        // Remove document EVEN IF error occurs - strictly for testing
        this.uploadedImages = this.uploadedImages.filter((i) => i.id !== img.id);
        // if (this.uploadedImages.length === 0) {
        //   this.uploadSuccessful = false;
        // }
        //
        img["deleting"] = false;
      }
    );
  }

  openSnackBar(message: string, action: string, config = {}): MatSnackBarRef<SimpleSnackBar> {
    let snackBarRef = this._snackBar.open(message, action, {
      duration: config["duration"] ? config["duration"] : 5000,
      data: config["data"] ? config["data"] : undefined,
    });

    return snackBarRef;
  }

  closeDialogRef() {
    this.dialogRef.close();
  }

  submitDialogRef(obj) {
    this.dialogRef.close(obj);
  }

  cancelAddDocument() {
    this.selectedDocTemplate = undefined;
    this.showAddDocumentForm = false;
    this.formValid = false;
    this.progress = undefined;
    this.canBeClosed = true;
    this.showCancelButton = true;
    this.uploading = false;
    this.uploadSuccessful = false;
    this.files = new Set();
    this.uploadsImageHistory = [...this.uploadedImages];
    this.uploadedImages = [];
    this.docTypeForm.reset();
  }

  cancelEditDocument() {
    this.selectedDocument = undefined;
    this.showEditDocumentForm = false;
    this.formValid = false;
    this.progress = undefined;
    this.canBeClosed = true;
    this.showCancelButton = true;
    this.uploading = false;
    this.uploadSuccessful = false;
    this.files = new Set();
    this.uploadsImageHistory = [...this.uploadedImages];
    this.uploadedImages = [];
  }

  ngOnDestroy(): void {
    this.alive = false;
    // console.log('DOCUMENTS DIALOG DESTROYED');
    // this.documentDialogService.destroy();
  }
}

class BextDocumentPayload {
  public DocumentName: string;
  public DocumentType: string;
  public DocumentTemplateId: string;
  public Images: any[] = [];
  public DocumentDatas: any[] = [];
  public UiData: any;

  constructor() {}

  addAnalyticData(name, value, label?) {
    let ad = new AnalyticData(name, value, "", label);
    this.DocumentDatas.push(ad);
  }
}
