import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ValuationRoutes } from '@app/core-v2/api-routes-v2.enum';
import { Observable } from 'rxjs';

@Injectable()
export class ValuationService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  // GET api/valuation/nodevaluationdata/{nodeId}
  getValuation(node_id: string): Observable<any> {
    return this.http.get(this.BASE_URL + ValuationRoutes.BASE_URL + ValuationRoutes.NODE_VALUATION_URL + '/' + node_id);
  }

  // PUT api/valuation/nodevaluationdata/{nodeId} with PUT PAYLOAD
  editValuation(node_id, payload) {
    return this.http.put<any>(this.BASE_URL + ValuationRoutes.BASE_URL + ValuationRoutes.NODE_VALUATION_URL + '/' + node_id, payload);
  }  

  // DELETE api/valuation/nodevaluationdata/{nodeId}/{valuationId}
  deleteValuation(node_id: string, valuation_id: string) {
    return this.http.delete(this.BASE_URL + ValuationRoutes.BASE_URL + ValuationRoutes.NODE_VALUATION_URL + '/' + node_id + '/' + valuation_id);
  }

  // PUT api/valuation/nodevaluationdata/{nodeId} with PUT PAYLOAD


  // POST api/valuation/nodevaluationdata/{nodeId} with POST PAYLOAD
  createValuation(node_id, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + ValuationRoutes.BASE_URL + ValuationRoutes.NODE_VALUATION_URL + '/' + node_id, payload);
  }

}