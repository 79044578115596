import { Component, OnInit, Input, Output, ViewChild, OnDestroy, EventEmitter } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ReportsService } from '@app/core-v2/services';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap';

import { SaveReportComponent } from '@app/features-v2/reports/save-report/save-report.component';
// export interface ITableCell {
//   value: string;
//   path: string;
//   clickmetadata: string;
//   isImage: boolean
// }

export interface ITableRow {
  [key: string]: string,
}

export interface DisplayMetaData {
  DisplayLocation: string, //SamePage, Below, Model, NewPage, reload, navigateto, new
  DisplayFormat: string  // Model, Below
}


export interface SummaryPeriod {
  StartDateTim: string, //DATETIME
  EndDateTime: string, //DATETIME
  StartNum: number, //DOUBLE
  EndNum: number //DOUBLE
}

export interface ClickMetaData {
  IsReport: boolean,
  ReportId: string,
  IsQuery: string,
  ReportQuery: string,
  DisplayMetaData: DisplayMetaData,
  ColumnMetaData: ColumnMetaData[],
  OpenPage: boolean,
  Path: string,
  NewPage: boolean


}

export interface ColumnMetaData {
  Field: string, //RowNumber
  DataBaseField: string, //RowNumber,
  Label: string, //Row,
  Display: string //False,
  Path: string,
  ExportAs: string //String,
  AddSummaryPeriods: boolean,  //false,
  SummaryField: boolean, //false,
  SummaryPeriods: string //Monthly,
  SummaryPeriod: SummaryPeriod, //null
  SummaryType: string,
  Format: string,
  ClickMetaData: ClickMetaData //null,
  //isImage?: boolean
}

export interface RowData {
  Field: string,
  DataBaseField: string,
  Id: string,
  Partition: string,
  Value: string,
  Path: string,
  ClickMetaData: ClickMetaData,
  IsImage?: boolean
}

export interface ReportData {
  Name: string, //Organization Products,
  Sequence: string, //null,
  ReportType: string, //Simple,
  PopulationMethod: string, //null,
  ColumnMetaData: ColumnMetaData[],
  RowData: RowData[]
}

const SAVED_QUERY_PARAMS_LOCALSTOAGE_KEY = 'bcrs_query_params';


@Component({
  selector: 'bext-extended-sortable-table',
  templateUrl: './extended-sortable-table.component.html',
  styleUrls: ['./extended-sortable-table.component.scss']
})
export class ExtendedSortableTableComponent implements OnInit, OnDestroy {

  
  saveReportDialogRef;
  humanNames: {};
  isLoading: boolean;

  private _tableName: string;
  @Input() set tableName(tableName: string) {
    this._tableName = tableName;
  }
  get tableName(): string {
    return this._tableName;
  }

  private _selectedReport: any;
  @Input() set selectedReport(selectedReport: any) {
    this._selectedReport = selectedReport;
  }
  get selectedReport() {
    return this._selectedReport;
  }

  private _savedReportName: string;
  @Input() set savedReportName(saveReportName: string) {
    this._savedReportName = saveReportName
  }

  get savedReportName(): string {
    return this._savedReportName
  }

  private _endpoint: string;
  @Input() set endpoint(m) {
    this._endpoint = m;
  }
  get endpoint() {
    return this._endpoint;
  }

  tableDataSource: MatTableDataSource<ITableRow>;
  private _reportData: ReportData;
  @Input() set reportData(reportData: ReportData) {
    if (reportData === undefined) {
      throw new Error('ReportData cannot be undefined');
    }
    this._reportData = reportData;
    this.onGetReportData();
  }
  get reportData(): ReportData {
    return { ...this._reportData }
  }

  private _allColumnsNames: string[] = []
  set allColumnsNames(names: string[]) {
    this._allColumnsNames = names
  }

  get allColumnsNames(): string[] {
    return this._allColumnsNames.slice()
  }

  private _displayedColumns: string[] = [];
  @Input() set displayedColumns(displayedColumns: string[]) {
    if (displayedColumns === undefined) {
      throw new Error('DisplayedColumns cannot be undefined');
    }
    this._displayedColumns = displayedColumns;
    this.allColumnsNames = [...displayedColumns, 'EditDelete']

  }
  get displayedColumns(): string[] {
    return this._displayedColumns.slice();
  }



  private _initialHiddenColumns: boolean[] = [];
  @Input() set initialHiddenColumns(initialHiddenColumns: boolean[]) {
    if (initialHiddenColumns === undefined) {
      throw new Error('InitialHiddenColumns cannot be undefined');
    }
    this._initialHiddenColumns = initialHiddenColumns;
  }
  get initialHiddenColumns(): boolean[] {
    return this._initialHiddenColumns.slice();
  }

  private _displayedColumnsNames: string[] = [];
  @Input() set displayedColumnsNames(displayedColumnsNames: string[]) {
    if (displayedColumnsNames === undefined) {
      throw new Error('DisplayedColumnsNames cannot be undefined');
    }
    this._displayedColumnsNames = displayedColumnsNames;
  }
  get displayedColumnsNames(): string[] {
    return this._displayedColumnsNames.slice();
  }


  private _selectedRowIndex: number;
  get selectedRowIndex(): number {
    return this._selectedRowIndex;
  }
  private _selectedModal: ModalDirective;
  //@Output() @ViewChild('addItem') addItem: ModalDirective;
  @ViewChild('editItemModal') public editItemModal: ModalDirective;
  @Output() onOpenEditItemModal = new EventEmitter<{ e: Event, rowIndex: number, rows: ITableRow[], currentModal: ModalDirective }>();

  @ViewChild('deleteItemModal') public deleteItemModal: ModalDirective;
  @Output() onOpenDeleteItemModal = new EventEmitter<{ e: Event, rowIndex: number, rows: ITableRow[], currentModal: ModalDirective }>();

  @ViewChild('addItemModal') public addItemModal: ModalDirective;
  @Output() onOpenAddItemModal = new EventEmitter<{ e: Event, rows: ITableRow[], currentModal: ModalDirective }>();


  @Output() onConfirmSave = new EventEmitter<{ e: Event, rowIndex: number, rows: ITableRow[], currentModal: ModalDirective }>();
  @Output() onConfirmDelete = new EventEmitter<{ e: Event, rowIndex: number, rows: ITableRow[], currentModal: ModalDirective }>();
  @Output() onConfirmAdd = new EventEmitter<{ e: Event, rows: ITableRow[], currentModal: ModalDirective }>();


  pageSize = 25;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  constructor(
    private reportsService: ReportsService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit() {

  }

  applyFilter($event: Event) {
    let filteredValue = $event.target['value']
    //console.log(filteredValue)
    filteredValue = filteredValue.trim().toLowerCase();
    this.tableDataSource.filter = filteredValue;
  }

  openAddModal($event: Event) {
    this.addItemModal.show();

    this._selectedModal = this.addItemModal;
    this.onOpenAddItemModal.emit({
      e: $event,
      rows: this.tableDataSource.filteredData,
      currentModal: this.addItemModal
    });
  }

  openEditModal($event: Event, rowIndex: number) {
    this.editItemModal.show();

    this._selectedRowIndex = rowIndex;
    this._selectedModal = this.editItemModal;
    this.onOpenEditItemModal.emit({
      e: $event,
      rowIndex,
      rows: this.tableDataSource.filteredData,
      currentModal: this.editItemModal
    });
  }

  openDeleteModal($event: Event, rowIndex: number) {
    this.deleteItemModal.show();

    this._selectedRowIndex = rowIndex;
    this._selectedModal = this.deleteItemModal;
    this.onOpenDeleteItemModal.emit({
      e: $event,
      rowIndex,
      rows: this.tableDataSource.filteredData,
      currentModal: this.deleteItemModal
    });
  }

  confirmAdd($event) {
    this.onConfirmAdd.emit({
      e: $event,
      rows: this.tableDataSource.filteredData,
      currentModal: this._selectedModal
    });
    this._selectedModal.hide();
  }

  confirmSave($event) {
    this.onConfirmSave.emit({
      e: $event,
      rowIndex: this._selectedRowIndex,
      rows: this.tableDataSource.filteredData,
      currentModal: this._selectedModal
    });
    this._selectedModal.hide();
  }

  confirmDelete($event) {
    this.onConfirmDelete.emit({
      e: $event,
      rowIndex: this._selectedRowIndex,
      rows: this.tableDataSource.filteredData,
      currentModal: this._selectedModal
    })

    this._selectedModal.hide();
  }

  getColumnName(col) {
    //console.log(col)
    if (col.indexOf('.') === -1) return col;
    else {
      let formattedName = col.split('.').join(' ');
      return formattedName;
    }
  }

  getSavedQueryParams(additionalReportParamsKey: string = '') {
    return localStorage.getItem(`${SAVED_QUERY_PARAMS_LOCALSTOAGE_KEY}${additionalReportParamsKey}`)
  }

  saveQueryParams(queryParams: string, additionalReportParamsKey: string = '') {
    if (!localStorage) return;
    localStorage.setItem(`${SAVED_QUERY_PARAMS_LOCALSTOAGE_KEY}${additionalReportParamsKey}`, queryParams)
  }

  onGetReportData() {
    this.saveQueryParams(this.endpoint);
    this.isLoading = true;

    this.humanNames = {};
    this.displayedColumns = this.reportData.ColumnMetaData.map(c => c.Field);
    this.displayedColumnsNames = this.reportData.ColumnMetaData.map(c => c.Label);
    this.initialHiddenColumns = this.reportData.ColumnMetaData
      .map(c => {
        if (c.Display.toLowerCase() === 'false') {
          return true;
        }
        else {
          return false;
        }
      });
    this.savedReportName = `brcs___ReportList--${this.reportData.Name}__queryparams=${this.getSavedQueryParams()}` // brs = bext report column storage
    //console.log('Saved Report Name: ', this.savedReportName)
    //console.log(this.reportData.RowData)
    let rows = this.reportData.RowData
      .reduce((prev, curr, i, arr) => {
        let temp = prev.find((row) => row.Id === arr[i].Id);
        temp = temp ? temp : {};

        if (!Object.keys(this.humanNames).length) {
          this.displayedColumns.forEach(column => {
            this.humanNames[column] = column.replace(/([a-z])([A-Z])/g, '$1 $2');
          })
        }

        this.displayedColumns.forEach((col) => {
          if (curr.Field === col && !temp[col]) {
            temp['Id'] = temp['Id'] ? temp['Id'] : curr.Id;
            temp[col] = curr.Value;
            if (curr.Path !== null && curr.Path) {
              temp[col + 'Path'] = curr.Path;
            }
            if (curr.ClickMetaData !== null && curr.ClickMetaData) {
              temp[col + 'ClickMetaData'] = curr.ClickMetaData;
            }
          }
        });
        if (curr.IsImage) {

          const colName = curr.Field;
          temp[colName + 'URL'] = curr.Value;
        }

        let prevIndex = prev.findIndex((row) => row['Id'] === curr.Id);
        if (prevIndex !== -1) {
          prev.splice(prevIndex, 1, temp);
        } else {
          prev.push(temp);
        }
        //console.log(prev)
        return prev;
      }, [])

    //sconsole.log(this.humanNames)

    this.isLoading = false;
    this.tableDataSource = new MatTableDataSource(rows);
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
    console.log('data source: ', this.tableDataSource.filteredData);
  }

  downloadReport(format, selectedReport) {
    let file$: Observable<any>;
    let queryParams = this.getSavedQueryParams();

    switch (format) {
      case 'excel':
        file$ = this.reportsService.getExcelReport(selectedReport.ReportId, queryParams);
        break;

      case 'pdf':
        file$ = this.reportsService.getPdfReport(selectedReport.ReportId, queryParams);
        break;

      default:
        file$ = of(['format not specified or does not match options'])
        break;
    }
    file$
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          console.log('%c downloaded report: ', 'background: #41ff6b; color: #ff4700;', res);
        },
        error => {
          console.log('%c GET report download failed: ', 'background: #ff0000; color: #ffffff;', error);
        }
      )
  }

  emailReport(format, selectedReport) {
    let file$: Observable<any>;
    let queryParams = this.getSavedQueryParams();

    switch (format) {

      case 'pdf':
        file$ = this.reportsService.emailPdfReport(selectedReport.ReportId, queryParams);
        break;

      case 'excel':
        file$ = this.reportsService.emailExcelReport(selectedReport.ReportId, queryParams);
        break;

      default:
        file$ = of(['format not specified or does not match options'])
        break;
    }
    file$
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          console.log('%c email report: ', 'background: #41ff6b; color: #ff4700;', res);
        },
        error => {
          console.log('%c GET report email failed: ', 'background: #ff0000; color: #ffffff;', error);
        }
      )
  }

  saveReport(selectedReport) {
    this.saveReportDialogRef = this.dialog.open(SaveReportComponent, {
      data: { test: true }
    });

    this.saveReportDialogRef.afterClosed().subscribe((data) => {
      if (data.reportName && data.orgOrUser) {
        selectedReport.Name = data.reportName;
        selectedReport.OrgOrUser = data.orgOrUser;
        this.reportsService.saveCustomReport(selectedReport).pipe(take(1)).subscribe(res => {
          console.log(res);
        })
      }
      // if (data)
    })
  }
  clearSavedQueryParams(additionalReportParamsKey: string = '') {
    localStorage.removeItem(`${SAVED_QUERY_PARAMS_LOCALSTOAGE_KEY}${additionalReportParamsKey}`);
  }

  // clearColumnInfo() {
  //   let saved_reports: any = JSON.parse(localStorage.getItem(SAVED_REPORTS_LOCALSTOAGE_KEY));
  //   if (saved_reports === null) return;
  //   else {
  //     saved_reports.forEach((reportName) => {
  //       localStorage.removeItem(reportName)
  //     });
  //     localStorage.removeItem(SAVED_REPORTS_LOCALSTOAGE_KEY)
  //     return;
  //   }
  // }


//



  ngOnDestroy() {
    //this.clearColumnInfo();
    this.clearSavedQueryParams();

  }

}
