import { BaseLot } from './../../shared-v2/models/lot.model';
import { AnalyticData, IAnalyticData } from '@app/shared-v2/models/analytic-data.model';
import { Injectable } from '@angular/core';
import { Subject, of, BehaviorSubject, Observable } from 'rxjs';
import { NodeService } from '@app/core-v2/services/node.service';
import { tap, catchError, map } from 'rxjs/operators';
import { BextAuthenticationService } from '@app/core/services';
import { TransferLotHelperService } from './transfer-lot-helper.service';
import { ILot } from '@app/shared-v2/models/lot.interface';
import { INode, NodeData, FieldsEntity } from '@app/shared-v2/models/node/node.interface';
import * as _ from 'lodash';
import { DocumentService } from '@app/core-v2/services/document.service';
import { isNull, isNullOrUndefined } from 'util';

@Injectable()
export class NodesStoreService {

  private _requestCount = 0;

  get requestCount() {
    return this._requestCount;
  }

  set requestCount(num) {
    var num = 1;
    this._requestCount += num;
  }

  private _selectedNodeSubject = new Subject<any>();
  private _currentNodeDetailSubject = new BehaviorSubject<any>(null);
  private _nodesDataMapSubject = new Subject<any>();
  private _nodeLotsSubject = new Subject<any>();
  private _nodeLotsSubjectV2 = new Subject<any>();
  private _nodeLotsBehaviourSubjectV2 = new BehaviorSubject<any>(null);
  private _urlParamChangeSubject = new Subject<any>();
  private _nodesListSubject = new Subject<any>();
  private _loadingSubject = new BehaviorSubject<boolean>(false);
  private _loadNodeUiDataSubject = new BehaviorSubject<boolean>(false);
  private _loadingFormSubject = new BehaviorSubject<any>({loading: false, message: ''});

  constructor(private nodeService: NodeService, private authService: BextAuthenticationService, private transferLotHelperService: TransferLotHelperService, private documentService: DocumentService) { }

  get selectedNode$() {
    return this._selectedNodeSubject.asObservable();
  }
  get nodesDataMap$() {
    return this._nodesDataMapSubject.asObservable();
  }
  get nodeLots$() {
    return this._nodeLotsSubject.asObservable();
  }
  get nodeLotsV2$() {
    return this._nodeLotsSubjectV2.asObservable();
  }
  get nodeLotsV2BehaviorSubject() {
    return this._nodeLotsBehaviourSubjectV2.asObservable();
  }
  get nodesList$() {
    return this._nodesListSubject.asObservable();
  }
  get loading$() {
    return this._loadingSubject.asObservable();
  }
  get loadNodeUiDataChange() {
    return this._loadNodeUiDataSubject.asObservable();
  }
  get loadNodeUiDataValue() {
    return this._loadNodeUiDataSubject.getValue();
  }
  get loadingFormChange() {
    return this._loadingFormSubject.asObservable();
  }
  get loadingFormValue() {
    return this._loadingFormSubject.getValue();
  }
  get currentNodeDetails() {
  // private get currentNodeDetails() {
    return this._currentNodeDetailSubject.getValue();
  }

  get loadash() {
    return _;
  }


  getNodes(): Observable<any> {
    this._loadingSubject.next(true);
    return this.nodeService.getNodes().pipe(
      catchError(() => of([])),
      tap(nodes => {
        // this.getNodeMap(nodes);
        this._nodesListSubject.next(nodes);
      })
    );
  }

  getSupplyChainNodeSummaries(supply_chain_id): Observable<any> {
    this._loadingSubject.next(true);
    return this.nodeService.getSupplyChainNodeSummaries(supply_chain_id).pipe(
      catchError(() => of([])),
      tap(nodes => {
        this.getNodeMap(nodes, 'Node');
        this._nodesListSubject.next(nodes);
      })
    );
  }

  getNodeSummaries(): Observable<any> {
    this._loadingSubject.next(true);
    return this.nodeService.getNodeSummaries().pipe(
      catchError(() => of([])),
      tap(nodes => {
        this.getNodeMap(nodes, 'NodeDetailType');
        this._nodesListSubject.next(nodes);
      })
    );
  }

  getNode(nodeId): Observable<any> {
    return this.nodeService.getNodeById(nodeId)
    .pipe(
      catchError(() => of({})),
      tap(node => {
        if (node === null) return of({});
        //console.log('%c getNode TAP node: ', 'background: #ff00ff; color: #ffffff;', node);
        node.UiData[0].Type = 'create';
        this.transferLotHelperService.addTransferFromNode(node);
        this._selectedNodeSubject.next(node);
        this._currentNodeDetailSubject.next(node);
      })
    );
  }

  setSupplyChainNode(node) {
    //console.log('set supply chain node: ', node);
    node.UiData[0].Type = 'create';
    this.transferLotHelperService.addTransferFromNode(node);
    this._selectedNodeSubject.next(node);
    this._currentNodeDetailSubject.next(node);
  }

  getNodeLots(node_id): Observable<any> {
    return this.nodeService.getNodeLots(node_id).pipe(
      catchError((error) => of({})),
      tap(nodeLots => {
        // console.log('%c node lots V2: ', 'background: #41ff6b; color: #ff4700;', nodeLots);
        this._nodeLotsSubject.next(nodeLots)
      })
    )
  }

  getNodeLotsV2(node_id): Observable<any> {
    return this.nodeService.getNodeLotsV2(node_id).pipe(
      catchError((error) => of({httpStatus: error.status, httpStatusText: error.statusText, url: error.url})),
      tap(nodeLots => {
        // console.log('%c node lots V2: ', 'background: #41ff6b; color: #ff4700;', nodeLots);
        this._nodeLotsSubjectV2.next(nodeLots)
        this._nodeLotsBehaviourSubjectV2.next(nodeLots);
      })
    )
  }

  resetNodeUiData() {
    let node: INode = JSON.parse(JSON.stringify(this.currentNodeDetails));
    this._selectedNodeSubject.next(node);
  }

  resetAllNodeStoreSubjects() {
    this._selectedNodeSubject.complete();
    this._selectedNodeSubject = new Subject<any>();
    this._currentNodeDetailSubject.complete();
    this._currentNodeDetailSubject = new BehaviorSubject<any>(null);
    this._nodesDataMapSubject.complete();
    this._nodesDataMapSubject = new Subject<any>();
    this._nodeLotsSubject.complete();
    this._nodeLotsSubject = new Subject<any>();
    this._nodeLotsSubjectV2.complete();
    this._nodeLotsSubjectV2 = new Subject<any>();
    this._urlParamChangeSubject.complete();
    this._urlParamChangeSubject = new Subject<any>();
    this._nodesListSubject.complete();
    this._nodesListSubject = new Subject<any>();
    this._loadingSubject.complete();
    this._loadingSubject = new BehaviorSubject<boolean>(false);
    this._loadNodeUiDataSubject.complete();
    this._loadNodeUiDataSubject = new BehaviorSubject<boolean>(false);
    this._loadingFormSubject.complete();
    this._loadingFormSubject = new BehaviorSubject<any>({ loading: false, message: '' });
  }

  updateNodeUiDataWithLotValues(lot: ILot) {
    let node: INode = JSON.parse(JSON.stringify(this.currentNodeDetails));

    let adDataExtractor = (LotData: IAnalyticData[], NodeUiDataField: FieldsEntity) => {
      let NodeFieldName = !isNullOrUndefined(NodeUiDataField.name) ? NodeUiDataField.name.toUpperCase() : '';
      let LotFieldNamAndNodeFieldNameMatch = (l) => {
        let LotFieldName = !isNullOrUndefined(l.Name) ? l.Name.toUpperCase() : (!isNullOrUndefined(l.Key) ? l.Key.toUpperCase() : '');
        if (
          LotFieldName == NodeFieldName                    /* LotData Name === Node UiData Field Name */
          || LotFieldName == NodeFieldName.split('.')[0]   /* LotData Name === First portion of Node UiData Field Name  */
          // || NodeFieldName.includes(LotFieldName)          /* Node UiData Field Name contains any portion LotData Field Name */
        ) {
            return true;
        }
        else return false;
      };
      let fieldMatch = LotData.find(LotFieldNamAndNodeFieldNameMatch);
      // console.log('%c lot data field: ', 'background: #41ff6b; color: #ff4700;', fieldMatch);
      
      let lotDataFromFieldNameMatch: IAnalyticData | any = fieldMatch ? fieldMatch : NodeUiDataField.name

      let name = lotDataFromFieldNameMatch.Name || lotDataFromFieldNameMatch.Key || lotDataFromFieldNameMatch;
      
      let nameArray = name.split('.');
      let adKey = nameArray[nameArray.length - 1];

      return ({
        // lotName: ((): string => name)(),
        // lotNameArray: ((): string[] => nameArray)(),
        // length: ((): number => nameArray.length)(),
        // firstValueOfFieldName: ((): string => nameArray[0])(),
        // lastValueOfFieldName: ((): string => adKey)(),
        // lotValue: ((): string => lotDataFromFieldNameMatch[adKey] || lotDataFromFieldNameMatch.Measure)()
        lotValue: lotDataFromFieldNameMatch[adKey] || lotDataFromFieldNameMatch.Measure || ''
      })
    }

    // lot.LotDatas = [];
    node['LotDocuments'] = lot.BextDocumentSummaries;
   
    // console.log(node['LotDocuments']);
    node.UiData[0].Type = 'edit';
    node.UiData[0].Sections.forEach((section) => {
      section.Fields.forEach((field, i) => {
        if (field.name.indexOf('LotMetaData') > -1) {
          field.value = _.get(lot, field.name, field.value);
        }
        else {
          if (field.visible) {
            // console.log('%c Update node ui data field: ', 'background: #fae552; color: #323232;', field.name);
            // console.log('%c lot: ', 'background: #fae552; color: #323232;', lot);
            let lotDataExtract = adDataExtractor(lot.LotDatas, field);
            // console.log('%c lot data value to append to node ui date: ', 'background: #fae552; color: #323232;', lotDataExtract);
            // console.log('\n');
            // console.group('LOT DATA EXTRACT');
            field.value = lotDataExtract.lotValue;
            // console.log('%c node field: ', 'background: #fae552; color: #323232;', field);
            // console.groupEnd();
          }
        }
      })
    })
    this._selectedNodeSubject.next(node);
  }

  createAnalyticData(lot, name, value, label?) {
    let nextIndex = lot.LotDatas.length;
    console.log('%c create analytic data - name: ', 'background: #fae552; color: #323232;', label);
    let key = name;
    return new AnalyticData(name, value, key, label);
  }

  createNewNodeLot(formValues) {
    let formNameFields = Object.keys(formValues);
    let newLot = new BaseLot();
    // console.log('Form values: ', formValues);
    formNameFields.forEach((path) => {
      // console.group('CREATE NEW LOT FIELD')
      let lotPropFound = _.get(newLot, path);
      // console.log('path on lot: ', path);
      // console.log('lot prop value: ', lotPropFound);

      if (lotPropFound !== undefined) {
        _.set(newLot, path, formValues[path])
        // console.log('set value to : ', formValues[path]);
        // console.log('lot - after set: ', newLot);
      } else {
        // console.log('add AD with value of : ', formValues[path]);
        let analyticDataObject = this.createAnalyticData(newLot, path, formValues[path]);
        newLot.LotDatas.push(analyticDataObject);
        
        // console.log('lot - after Analytic Data added: ', newLot);
      }
      // console.groupEnd();
    })
    newLot.LotMetaData.NodeId = this.currentNodeDetails.id;
    return newLot;
    // let node: INode = JSON.parse(JSON.stringify(this.currentNodeDetails));

  }

  createNewNodeLotErrorHandler(formValues) {
    let node: INode = JSON.parse(JSON.stringify(this.currentNodeDetails));
    node.UiData[0].Type = 'create';
    node.UiData[0].Sections.forEach((section) => {
      section.Fields.forEach((field) => {
        console.group('create new lot error handler');
        console.log('field: ', field);
        console.log(`formValues.hasOwnProperty(${field.name}): `, formValues.hasOwnProperty(field.name));
        console.groupEnd();
        field.value = formValues.hasOwnProperty(field.name) ? formValues[field.name] : field.value;
        // if (field.visible && typeof field.value === 'string' && field.value.length == 0) {
        //   let analyticDataObject = this.createAnalyticData(lot, field.name, field.value);
        //   field.name = analyticDataObject.Name;
        //   lot.LotDatas.push(analyticDataObject)
        //   // console.log('new ANALYTIC DATA OBJECT: ', analyticDataObject);
        // }
      })
    })
    console.log('%c node: ', 'background: #fae552; color: #323232;', node);
    this._selectedNodeSubject.next(node);
  }

  urlParamChangeEvent(nodeId) {
    this._urlParamChangeSubject.next(nodeId);
    this._urlParamChangeSubject.complete();
  }
  
  getNodeMap(nodeList, filterBy: string) {
    let map: any[];
    switch (filterBy) {
      case 'NodeDetailType':
      case 'NodeType':
          // console.log('FILTER BY NODE_TYPE / NODE_DETAIL_TYPE');
          map = this.__createNodeDetailMap(nodeList, filterBy);
        break;

      case 'Node':
          // console.log('FILTER BY NODE');
          map = nodeList
        break;
    
      default:
          console.log('GET NODE MAP filterBy DEFAULT CASE: ', filterBy);
          map = [];
        break;
    }


    this._nodesDataMapSubject.next(map);
    this._loadingSubject.next(false);
    this._loadingSubject.complete();
  }


  // flattenUiData(node) {
  //   let uiData = {
  //     fields: node.UiData[0].Sections.reduce((prev, section) => prev.concat(section.Fields),[]),
  //     onlyVisibleFields: node.UiData[0].Sections.reduce((prev, section) => prev.concat(section.Fields),[]).filter((field) => field.visible)
  //   };
  //   return uiData;
  // }

  private __createNodeDetailMap(nodes: NodeData[], type) {

    let nodeList = (prev, curr, i, arr) => {
      prev[curr[type]] = (!prev[curr[type]] || !prev[curr[type]].length) ? [] : prev[curr[type]];
      prev[curr[type]].push(curr);
      return prev;
    }

    let nodeMap = nodes.reduce(nodeList, {})
    let nodeMapKeys = Object.keys(nodeMap);

    let nodeDetails = (prev, curr, i, arr) => {
      let o = {
        type: type,
        data: nodeMap[curr]
      };
      prev.push(o);
      return prev;
    }

    // console.log('%c NODE MAP: ', 'background: #fae552; color: #323232;', nodeMap);
    // console.log('%c NODE DETAILS: ', 'background: #fae552; color: #323232;', nodeMapKeys
    //   .reduce(nodeDetails, []));

    return nodeMapKeys
      .reduce(nodeDetails, [])
  }

  toggleLoadingSpinner(message?) {
    let loadingForm = this.loadingFormValue;
    loadingForm.loading = !loadingForm.loading;
    loadingForm.message = !!message && message.length ? message : '';
    // console.log('loading form status: ', loadingForm.loading);
    this._loadingFormSubject.next(loadingForm);
  }

  loadingSpinnerShow(message?) {
    let loadingForm = this.loadingFormValue;
    loadingForm.loading = true;
    loadingForm.message = !!message && message.length ? message : '';
    // console.log('loading form status: ', loadingForm);
    this._loadingFormSubject.next(loadingForm);
  }

  loadingSpinnerHide(message?) {
    let loadingForm = this.loadingFormValue;
    loadingForm.loading = false;
    loadingForm.message = !!message && message.length ? message : '';
    console.log('loading form status: ', loadingForm);
    this._loadingFormSubject.next(loadingForm);
  }

  loadNodeUiDataSpinnerShow() {
    this._loadNodeUiDataSubject.next(true);
  }

  loadNodeUiDataSpinnerHide() {
    this._loadNodeUiDataSubject.next(false);
  }

}