import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MarkerRoutes } from '@app/core/api-routes.enum';

@Injectable()
export class MarkerService {

  BASE_URL = environment.baseApiUrl;
  MARKERS_URL = '/marker';

  constructor(private http: HttpClient) { }

  getLotMarkers(id, startIndex?) {
    switch (typeof startIndex) {
      case 'number':
        let endIndex = startIndex + 5;
        console.log('startIndex + typeof: ', startIndex, typeof startIndex);
        return this.http.get(this.BASE_URL + this.MARKERS_URL + `/GetLotMarkers/${id}?startIndex=${startIndex}&endIndex=${endIndex}`);
    
      default:
        return this.http.get(this.BASE_URL + this.MARKERS_URL + '/GetLotMarkers/' + id);
    }
  }

  getLotMarkersDashboard(id) {
    return this.http.get(this.BASE_URL + this.MARKERS_URL + '/Dashboard/' + id)
  }

  // new endpoint for Markers Dan built 121919
  getLotMarkersVerification(id) {
    return this.http.get(this.BASE_URL + this.MARKERS_URL + '/lotmarkersandverifications/' + id)
  }

  verifyLotMarker(id, payload) {
    return this.http.post<any>(this.BASE_URL + this.MARKERS_URL + '/VerifyMarkers/' + id, payload)
  }

  addLotMarker(lot, payload) {
    return this.http.post<any>(this.BASE_URL + '/Marker/' + lot.LotMetaData.LotId, payload);
  }

  getVerifiedMarkerData(id) {
    return this.http.get(this.BASE_URL + this.MARKERS_URL + '/GetVerifiedMarkerDatas/' + id);
  }

  deleteLotMarker(MarkerDataId, AddMarkerId) {
    return this.http.delete(this.BASE_URL + MarkerRoutes.BASE_URL + MarkerRoutes.DELETEMARKER_URL + '/' + MarkerDataId + '/' + AddMarkerId)
  }

  editLotMarker(MarkerDataId, AddMarkerId, payload) {
    return this.http.put<any>(this.BASE_URL + MarkerRoutes.BASE_URL + MarkerRoutes.EDITMARKER_URL + '/' + MarkerDataId + '/' + AddMarkerId, payload);
  }



}