import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpenCageRoutes } from '@app/core/api-routes.enum';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class GeoLocationService {

  BASE_URL = OpenCageRoutes.BASE_URL

  constructor(private http: HttpClient) {}

  getGeoData(lat,lng): Observable<any> {
    return this.http.get<any>(`https://elevation-api.io/api/elevation?points=(${lat},${lng})&resolution=90&key=${environment.ELEVATION_API_IO_KEY}`)
  };

  locationCountryNameMapping(country) {
    const googlePlacesCountryNames = {
      "Myanmar (Burma)": "Burma",
      "United States of America": "United States",
      "USA": "United States"
    };
    let results = googlePlacesCountryNames[country] ? googlePlacesCountryNames[country] : country;
    return results;
  };

}
