import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import { Document } from '@app/shared-v2/models/archwizard/form-documents.interface';
import { GALLERY_IMAGE } from '@app/shared-v2/photo-gallery/models/photo-gallery.conf';

@Injectable()
export class ImageService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getThumbnailUrl(imageUrl: string) {
    if (isNullOrUndefined(imageUrl)) return imageUrl;
    let imageUrlArr = imageUrl.split('.');
    let imageExtension = imageUrlArr.pop();
    let thumbnailImageExtension = (imageExtension === 'jpg') ? `-thumbnail.${imageExtension}` : `.${imageExtension}`;
    let imageThumbnailUrl = imageUrlArr.join('.') + thumbnailImageExtension
    return imageThumbnailUrl;
  }

  getImageExtension(imageUrl: string) {
    if (isNullOrUndefined(imageUrl)) return imageUrl;
    let imageUrlArr = imageUrl.split('.');
    let imageExtension = imageUrlArr.pop();
    if (imageExtension === 'pdf' || imageExtension === 'xlsx' || imageExtension === 'xls' || imageExtension === 'xlsm') {
    }
    return imageExtension;
  }

  formatImagesToGalleryImages(documents: Document[]): GALLERY_IMAGE[] {
    if (documents === null || !documents) return [];
    return documents.reduce((p, c) => {
      let currentImages = c.Images.map((img) => {
        return (
          {
            _cached: false,
            url: img.ImageURL,
            thumbnailUrl: img['ImageThumbnailURL'] = this.getThumbnailUrl(img.ImageURL),
            altText: img.ImageName,
            title: img.ImageName,
            extUrl: img['ImageExtension'] = this.getImageExtension(img.ImageURL),
            extUrlTarget: ''
          }
        );
      });
      return [...p, ...currentImages];
    }, [])
  }

}