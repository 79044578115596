import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonDialogComponent } from './person-dialog.component';
import { PersonV2Module } from '@app/shared-v2/personv2/personv2.module';
import { MatDialogModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    PersonV2Module,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  declarations: [PersonDialogComponent],
  exports: [PersonDialogComponent],
  entryComponents: [PersonDialogComponent]
})
export class PersonDialogModule { }
