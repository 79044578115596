import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'quality-token-table',
  templateUrl: './quality-token-table.component.html',
  styleUrls: ['./quality-token-table.component.css']
})
export class LotQualityTokenTableComponent implements OnInit {
  @Input() tokens: Array<any>;
  constructor() { }

  ngOnInit() {
    console.log('analysis tokens', this.tokens);
  }

}
