import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { FormStep } from '@app/shared-v2/models/archwizard/form-step.interface';
import { ApiFakerService } from './api-faker.service';

@Injectable()
export class ArchwizardBuilderService {

  constructor(
    private http: HttpClient,
    private apiFakerService: ApiFakerService
  ) {}

  initialWizardBuilder: FormStep[] = [];

  private _wizardBuilderSubject = new BehaviorSubject<FormStep[]>(this.initialWizardBuilder);

  get wizardBuilderValues() {
    return this._wizardBuilderSubject.getValue();
  }

  get wizardBuilderValueChanges() {
    return this._wizardBuilderSubject.asObservable();
  }

  getWizardBuilderDraggableElementsData(url) {
    return this.apiFakerService.fetch(url)
  }

  addStep(stepIndex?: number) {
    let step: FormStep = {
      StepIndex: stepIndex || this.wizardBuilderValues.length,
      StepTouched: false,
      StepError: false,
      StepComplete: false,
      StepPending: false,
      StepSubmitted: false,
      Header: `Steptitle ${this.wizardBuilderValues.length + 1}`,
      Icon: "far fa-circle",
      Content: `Content: Step ${this.wizardBuilderValues.length + 1}`,
      Name: `name for step ${this.wizardBuilderValues.length + 1}`,
      Description: `description of step ${this.wizardBuilderValues.length + 1}`,
      Theme: {
        BgColor: `ffffff`,
        TextColor: `555555`,
        BannerImage: ''
      },
      Attributes: []
    };
    
    let wizardBuilderModel = this.wizardBuilderValues;
    wizardBuilderModel.push(step);
    this._wizardBuilderSubject.next(wizardBuilderModel);
  }
  
  removeStep(stepNumber) {
    let wizardBuilderModel = this.wizardBuilderValues;
    wizardBuilderModel.splice(stepNumber, 1);
    wizardBuilderModel.forEach((step,i) => {
      step.StepIndex = i;
    })
    this._wizardBuilderSubject.next(wizardBuilderModel);
  }

}