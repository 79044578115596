import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LotTokensV2Component} from './lot-tokens.component';
import { LotTokenTableV2Component } from './lot-token-table.component';
import { MatButtonModule, MatDialogModule, MatTableModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MaterialModule } from '@app/shared/angular-material.module';
// import { ListWithAvatarsComponent } from './list-with-avatars.component';
// import { MatListModule, MatIconModule, MatDividerModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MaterialModule,
    MatTableModule
  ],
  declarations: [LotTokensV2Component, LotTokenTableV2Component],
  exports: [LotTokensV2Component, LotTokenTableV2Component],
  entryComponents: [LotTokensV2Component]
})
export class LotTokensModule { } 