import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[click-stop-propagation]"
})
export class ClickStopPropagation {
  @HostListener("click", ["$event"])
  public onClick(event: any): void {
    event.stopPropagation();
    event.preventDefault();
  }
}

@Directive({
  selector: "[change-stop-propagation]"
})
export class ChangeStopPropagation {
  @HostListener("change", ["$event"])
  public onChange(event: any): void {
    event.stopPropagation();
  }
}