import { Component, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { Location } from '@app/shared/models/location.model';
import { LocationService } from '@app/core/services/backend/locations.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrganizationService } from '@app/core/services/backend/organization.service';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'sa-add-organization',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddOrganizationComponent implements OnInit, AfterContentInit {
  public form: any;
  public locationForm: any;
  public addLocationForm: any;
  public newOrganization: any = {};
  public forbiddenOrganizations: any;
  public locations;
  constructor(public organizationService: OrganizationService, public router: Router, public fb: FormBuilder, public locationService: LocationService) {
    this.organizationService.getOrganizations().subscribe(res => {
      this.forbiddenOrganizations = res.map(organization => {
        if (organization.OrganizationName !== null) {
          return organization.OrganizationName.toLowerCase();
        }
      })
    })
  }
  @ViewChild('lgModal') public lgModal: ModalDirective;

  ngOnInit() {
    this.locationService.getLocations().subscribe(res => {
      this.locations = res;
    });
    // this.lgModal.show();
  }

  ngAfterContentInit() {
    this.form = this.fb.group({
      addOrganizationForm: this.fb.group({
        OrganizationName: this.fb.control('', [Validators.required, this.forbiddenNames.bind(this)]),
        OrganizationType: this.fb.control('Company', []),
        TrustLineAccountName: this.fb.control('TrustLineAccount', []),
        FundingAccountName: this.fb.control('FundingAccount', []),
        TestAccount: this.fb.control(true, []),
        BlockChainName: this.fb.control('Stellar', []),
        FundingAmount: this.fb.control(2000, [])
      })
    });
    this.locationForm = this.fb.group({
      addLocationForm: this.fb.group({
        Name: this.fb.control('', [Validators.required]),
        Country: this.fb.control('', [Validators.required]),
        City: this.fb.control('', [Validators.required]),
        State: this.fb.control('', [Validators.required]),
        Latitude: this.fb.control('', []),
        Longitude: this.fb.control('', []),
        Elevation: this.fb.control('', []),
        ElevationUnit: this.fb.control('', []),
        Address: this.fb.control('', []),
        Description: this.fb.control('', [])
      })
    })
  }

  addLocation() {
    if (this.locationForm.valid){
      this.locationService.addLocation(this.locationForm.value.addLocationForm)
        .subscribe(res => {
            this.ngOnInit();
            this.lgModal.hide();
        }, error => {
          console.log(error);
        });
    }
  }

  addNewOrganization() {
    this.form.submitted = true;
    if (this.form.valid) {
      this.organizationService.addOrganization(this.form.value.addOrganizationForm).subscribe(res => {
        console.log('%c succesful POST organization: ', 'background: #41ff6b; color: #ff4700;', res);
          this.router.navigateByUrl('/v2/settings/organizationgitlist');
      }, error => {
        console.log(error);
      });
    }
  }

  showModal(e) {
    this.lgModal.show();
  }

  forbiddenNames(control): { [s: string]: boolean } {
    if (!!control.value && this.forbiddenOrganizations.indexOf(control.value.toLowerCase()) !== -1) {
      return { 'nameAlreadyExists': true };
    }
    return null;
  }
}
