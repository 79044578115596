import { Validator } from './../models/field.interface';
import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { fromEvent, pipe } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'bext-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent {
  @ViewChild('searchInput') searchInputEl: ElementRef;
  @Input() placeholder: string;
  @Input() fontColor: string;
  @Input() label: string;
  @Input() displaySearchIcon: boolean = false;
  @Input() enableClearSearchButton: boolean = false;
  @Output() inputChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder) { }

  form: FormGroup;
  searchInputFocused = false;

  ngOnInit() {
    this.form = this.fb.group({
      'searchParams': new FormControl('')
    })
  }

  get searchParamsCtrl() {
    return this.form.get('searchParams');
  }

  ngAfterViewInit(): void {
    // fromEvent<any>(this.searchInputEl.nativeElement, 'keyup')
    this.form.valueChanges
      .pipe(
        map(event => event.searchParams),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(text => {
        this.inputChange.emit(text);
      })
  }

  onSearchSubmit(e) {
    const searchParams = this.form.value.searchParams.trim().split('');
    e.stopPropagation();
    e.preventDefault();
    console.log('%c search params ', 'background: #41ff6b; color: #ff4700;', searchParams.length);
    // let text = this.searchInputEl.nativeElement.value;
    if (searchParams.length) {
      this.search.emit(this.form.value);
    }
  }

  clearSearchBox(e) {
    e.stopPropagation();
    e.preventDefault();
    this.searchParamsCtrl.setValue('');
    this.focusSearchInput();
  }

  get checkInputFocus() {
    return this.searchInputFocused;
  }

  focusSearchInput() {
    this.searchInputEl.nativeElement.focus()
  }

}
