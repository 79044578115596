import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'circle-check-loader',
  templateUrl: './circle-check.component.html',
  styleUrls: ['./circle-check.component.scss']
})
export class CircleCheckComponent implements OnInit {

  private _loadComplete = false;
  @Input()
  set loadComplete(val) {
    this._loadComplete = val ? true : false;
  }
  get loadComplete() {
    return this._loadComplete;
  }

  constructor() { }

  ngOnInit() {
  }

}
