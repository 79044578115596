import { Injectable, ApplicationRef } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { config } from '@app/core/smartadmin.config';

import { JsonApiService } from "@app/core/services/json-api.service";
import { Subject, Observable } from 'rxjs';
import { delay, map, catchError } from 'rxjs/operators';
import { languages } from '@app/shared/i18n/languages.model';
import 'rxjs/add/observable/of';



@Injectable()
export class LanguageSelectorService {

  public state;
  public data: {};
  public currentLanguage: any;


  constructor(private jsonApiService: JsonApiService, private ref: ApplicationRef, private http: HttpClient) {
    this.state = new Subject();

    this.initLanguage(config.defaultLocale || 'us');
    this.fetch(this.currentLanguage.key)
  }

  private getBaseUrl() {
    return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/'
  }

  private fetch(locale: any): Observable<any> {
    return Observable.of(this.http.get(this.getBaseUrl() + config.API_URL + `/langs/${locale}.json`)
      .pipe(
        delay(100),
        map((data: any) => {
          (data.data || data)
        })
      ).subscribe((data: any) => {
        this.data = data;
        this.state.next(data);
        this.ref.tick()
      })
    )
  }

  private initLanguage(locale: string) {
    let language = languages.find((it) => {
      return it.key == locale
    });
    if (language) {
      this.currentLanguage = language
    } else {
      throw new Error(`Incorrect locale used for I18nService: ${locale}`);

    }
  }

  setLanguage(language) {
    this.currentLanguage = language;
    console.log('set language: ', language);
    this.fetch(language.key).subscribe(res => {
        console.log('fetch lang promise: ', res)
      })
  }


  subscribe(sub: any, err: any) {
    return this.state.subscribe(sub, err)
  }

  public getTranslation(phrase: string): string {
    return this.data && this.data[phrase] ? this.data[phrase] : phrase
  }

}