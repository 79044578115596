import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotSummaryV2Component } from './lot-summary.component';
import { MaterialModule } from '@app/shared/angular-material.module';
import { RouterModule } from '@angular/router';
// import { ListWithAvatarsComponent } from './list-with-avatars.component';
// import { MatListModule, MatIconModule, MatDividerModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ],
  declarations: [LotSummaryV2Component],
  entryComponents: [LotSummaryV2Component],
  providers: [],
  exports: [LotSummaryV2Component]
})
export class LotSummaryModule { } 
