import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationV2Component } from './locationv2.component';
import { MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatSelectModule, MatOptionModule, MatExpansionModule, MatProgressSpinnerModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    AgmCoreModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule
  ],
  declarations: [LocationV2Component],
  exports: [LocationV2Component],
  providers: [GoogleMapsAPIWrapper]
})
export class LocationV2Module { }
