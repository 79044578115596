import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bx-scroll-section',
  templateUrl: './scroll-section.component.html',
  styleUrls: ['./scroll-section.component.css']
})
export class ScrollSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
