import { LocationRoutes } from '@app/core-v2/api-routes-v2.enum';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LocationServiceV2 {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getLocations(): Observable<any> {
    return this.http.get<any>(this.BASE_URL + LocationRoutes.BASE_URL);
  }

  getLocation(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + LocationRoutes.BASE_URL + '/' + id)
  }

  getLocationV2(entity_id: string, node_org_id: string, location_type: string = 'LotCollectionLocation'): Observable<any> {
    return this.http.get<any>(this.BASE_URL + '/location' + `?EntityId=${entity_id}` + `&OwnerOrganizationId=${node_org_id}` + `&LocationType=${location_type}`)
  }

  getLotLocationV2(lot_id: string, location_type: string = 'LotCollectionLocation'): Observable<any> {
    return this.http.get<any>(this.BASE_URL + '/location/lot' + `?LotId=${lot_id}` + `&LocationType=${location_type}`)
  }

  addLocation(location): Observable<any> {
    return this.http.post<any>(this.BASE_URL + LocationRoutes.BASE_URL, location);
  }

  deleteLocation(location: any): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + LocationRoutes.BASE_URL + '/' + location.id)
  }

  editLocation(location): Observable<any> {
    return this.http.put<any>(this.BASE_URL + LocationRoutes.BASE_URL + '/' + location.id, location)
  }
}