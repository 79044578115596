import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { LotService } from '@app/core/services/backend/lot.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'lot-token-table-v2',
  templateUrl: './lot-token-table.component.html',
  styleUrls: ['./lot-token-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class LotTokenTableV2Component {
  private _tokens = [];
  @Input()
  set tokens(tks) {
    this._tokens = tks;
  }
  get tokens() {
    return this._tokens;
  }
  loadingIndicator: boolean = false;
  reorderable: boolean = false;
  sortedTokens = [];
  columnsToDisplay = ['Name', 'Weight', 'Date Created', 'Number', 'Details'];
  expandedElement;

  // private alive = true;

  columns = [
    { prop: 'Name', name: 'Name' },
    { prop: 'Weight', name: 'Weight' },
    { prop: 'DateCreated', name: 'DateCreated' },
    { prop: 'Number', name: 'Number' }];

  constructor(
    private lotService: LotService
    // private dialogRef: MatDialogRef<LotTokenTableComponent>
  ) { }

  expandRow(element) {
    this.expandedElement = this.expandedElement === element ? null : element
    // console.log('row element: ', element);
    // console.log('expanded element: ', this.expandedElement);
  }

  ngOnInit() {
    this.tokens.forEach(lotToken => {
      lotToken.Tokens.forEach(token => {
        token['Date Created'] = new Date(lotToken.DateCreated).toLocaleDateString();
        token['Lot Token Value'] = '<mat-icon>lock</mat-icon>'
        this.sortedTokens.push(token)
      });
    })
  }

  // closeDialog() {
  //   this.dialogRef.close();
  // }

  // ngOnDestroy(): void {
  //   this.alive = false;
  // }

}
