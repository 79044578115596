import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { CommentsComponent, DatacontainerDirective } from './comments.component';
import { CommentBoxComponent } from './comment-box/comment-box.component';
import { ChildBoxComponent } from './child-box/child-box.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [CommentsComponent, CommentBoxComponent, ChildBoxComponent, DatacontainerDirective],
  exports: [CommentsComponent, CommentBoxComponent, ChildBoxComponent, DatacontainerDirective],
  entryComponents: [ChildBoxComponent]
})
export class CommentsModule { }
