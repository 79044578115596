import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";
import { delay, map, catchError } from 'rxjs/operators';


@Injectable()
export class ApiFakerService {

  constructor(private http: HttpClient) {}

  public fetch(url): Observable<any>{
    return this.http.get(this.getBaseUrl() + url)
    .pipe(
      delay(100),
      map((data: any)=>(data.data || data)),
      catchError(err => this.handleError(err))
    )
  }

  public getBaseUrl(){
    return location.protocol + '//' + location.hostname + (location.port ? ':'+location.port : '') + '/'
  }

  private handleError(error:any) {
    let err$ = Observable.create(obeserver => {
      let errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.log('%c error handler: ', 'background: #ff0000; color: #ffffff;', error);
        obeserver.next(errMsg);
        obeserver.complete();
    });
    return err$;
  }

}