import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSupplyChainDialogComponent } from './edit-supply-chain-dialog.component';
import { MatDialogModule, MatButtonModule, MatIconModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [EditSupplyChainDialogComponent],
  entryComponents: [EditSupplyChainDialogComponent]
})
export class EditSupplyChainDialogModule { }
