import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { of } from 'rxjs';


@Injectable()
export class PageFormatterService {

  private _form: FormGroup;
  private _tempFileUploadFields: any;

  constructor(private fb: FormBuilder) { }

  get formValue() {
    return this.form.value;
  }

  get form() {
    return this._form;
  }

  set form(form) {
    this._form = form;
  }

  get formValueChanges() {
    const valueChanges = this.form ? this.form.valueChanges : of([]);
    return valueChanges;
  }

  get tempFileUploadFields() {
    return this._tempFileUploadFields;
  }

  set tempFileUploadFields(uploadObject) {
    let tempFileFieldNamesObject = this.tempFileUploadFields ? this.tempFileUploadFields : {};
    tempFileFieldNamesObject[uploadObject.fieldName] = uploadObject.url;
    this._tempFileUploadFields = tempFileFieldNamesObject;
  }

  get tempFileUploadFieldKeys() {
    return Object.keys(this.tempFileUploadFields);
  }

  createForm(config) {
    const group = this.fb.group({});
    config.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      // console.log('create control: ', control);
      group.addControl(field.name, control);
      // console.log('group: ', group);
    });
    this.form = group;
    return this.form;
  }

  createControlFromFormattedUiData(config) {
    console.log('create form using config: ', config);
    const group = this.fb.group({});
    config.forEach(section => {
      section.fields.forEach(field => {
        if (field.type === "button") return;
        if (field.type === "fileupload") this.tempFileUploadFields = { fieldName: field.name, url: null };
        const control = this.fb.control(
          field.value,
          this.bindValidations(field.validations || [])
        );
        // console.log('create control: ', control);
        group.addControl(field.name, control);
        // console.log('group: ', group);
      });
    });
    this.form = group;
    return this.form;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  patchFileUploadFormFields(formValues) {
    if (!this.tempFileUploadFieldKeys || this.tempFileUploadFieldKeys === null) return;
    return Object.keys(formValues).reduce((prev, curr) => {
      let isFileUploadFieldMatch = (tempFieldName) => tempFieldName === curr;
      let match = this.tempFileUploadFieldKeys.find(isFileUploadFieldMatch);
      prev[curr] = match ? this.tempFileUploadFields[match] : formValues[curr];
      // console.log('prev[' + curr + ']: ', prev[curr]);
      return prev;
    }, {});
  }

}