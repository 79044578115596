import { AppUserService } from '@app/core/services/backend/app-user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { OrganizationService } from '@app/core/services/backend/organization.service';
import { SupplychainService } from '@app/core/services/backend/supplychain.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'bext-edit-user',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditUserComponent implements OnInit, OnDestroy {

  private existingUserSub: any;
  public form: any;
  public user;
  public organizations: any[] = [];
  public supplychains = [];
  private basePermissions = [{
    "Action": "AppUsersGet",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "AppUsersAdd",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "AppUsersUpdate",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "AppUsersDelete",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "LocationsGet",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "LocationsAdd",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "LocationsUpdate",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "LocationsDelete",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "OrganizationsGet",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "OrganizationsAdd",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "OrganizationsUpdate",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "OrganizationsDelete",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "SupplyChainsGet",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "SupplyChainsAdd",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "SupplyChainsDelete",
    "Description": null,
    "Permission": 2,
    "read": false
},
{
    "Action": "Base",
    "Description": null,
    "Permission": 2,
    "read": false
}]
  constructor(
    private router: Router,
    private organizationService: OrganizationService,
    private fb: FormBuilder,
    private appUserService: AppUserService,
    private route: ActivatedRoute,
    private supplyChainService: SupplychainService,
    private spinnerService: NgxSpinnerService,

   
    ) { }

  ngOnInit() {
    this.organizationService.getOrganizations().subscribe(res => {
      console.log(res)
      this.organizations = res;
    });
    this.existingUserSub = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.appUserService.getUser(params.get('id')))
    );

    this.existingUserSub.subscribe(res => {
      this.user = res;
      this.form = this.fb.group({
        editForm: this.fb.group({
          id: this.fb.control(this.user.id, [Validators.required]),
          Name: this.fb.control(this.user.Name, [Validators.required]),
          EmailAddress: this.fb.control(this.user.EmailAddress, [Validators.required, Validators.email]),
          // Password: this.fb.control(this.user.Password, []),
          AppUserRole: this.fb.control(this.user.AppUserRole, [Validators.required]),
          OrganizationIds: this.fb.control('')
        })
      });
      this.basePermissions.forEach((permission) => {
          let found = this.user.AppUserPermissions.find((thing) => {return (thing.Action == permission.Action) && (thing.Permission == 2) })
          console.log('found', found);
          if (found) {
              permission.read = true;
          } else {
              permission.read = false;
          }
      })
      let form = this.form.get('editForm').get('OrganizationIds').setValue(this.user.OrganizationIds[0]);
      this.supplyChainService.getSupplychains().subscribe(
        res => {
          this.supplychains = res.filter((supplychain) => {
            console.log(supplychain.DefaultSupplyChain && (this.user.OwnerOrganizationId == supplychain.OrganizationId));
            return supplychain.DefaultSupplyChain && (this.user.OwnerOrganizationId == supplychain.OrganizationId);
          });
          this.supplychains.forEach((supplychain) => {
            let foundSupplyChainPermission = this.user.AppUserSupplyChainPermissions.find((permission) => {
              return permission.SupplyChainId == supplychain.id
            });
            if (foundSupplyChainPermission) {
              foundSupplyChainPermission.Nodes.forEach((node) => {
                let foundNodeId = supplychain.PreviousNodesData.find(node1 => node.NodeId == node1.NodeId)
                if (foundNodeId) {
                  foundNodeId.ReadOnly = node.NodePermission.ReadOnly || false;
                  foundNodeId.ReadWrite = node.NodePermission.ReadWrite || false;
                  foundNodeId.TransferInto = node.NodePermission.TransferInto || false;

                } else {
                  foundNodeId.ReadOnly = false;
                  foundNodeId.ReadWrite = false;
                  foundNodeId.TransferInto = false;
                }
              });
            }
          })
          this.spinnerService.hide();
          return;
        },
        error => {
          console.log('%c Error - GET supplychains failed: ', 'background: #ff0000; color: #ffffff;', error);
          this.spinnerService.hide();
          return;
        }
      );
    })
    
  }

  ngAfterContentInit() {
   
  }

  pushNodeToPermissions(node, supplychain) {
    let foundSupplyChainPermission = this.user.AppUserSupplyChainPermissions.find((permission) => {
      return permission.SupplyChainId == supplychain.id
    });
    let temp = this.user.AppUserSupplyChainPermissions.find((permission) => {
      return permission.SupplyChainId == supplychain.id
    })
    foundSupplyChainPermission = (this.user.AppUserSupplyChainPermissions && this.user.AppUserSupplyChainPermissions.length && temp) ? temp :  {
      "SupplyChainId": supplychain.id,
      "Nodes": [],
      Permission: 2
     };
     if (temp) {
      let foundNode = foundSupplyChainPermission.Nodes.find((itNode)=> {return itNode.NodeId == node.NodeId});
      if (!foundNode) {
        foundSupplyChainPermission.Nodes.push({NodeId: node.NodeId, NodePermission: {ReadWrite: node.ReadWrite || false, ReadOnly: node.ReadOnly || false, TransferInto: node.TransferInto || false}})
      } else {
        foundNode.NodePermission = {ReadWrite: node.ReadWrite || false, ReadOnly: node.ReadOnly || false, TransferInto: node.TransferInto || false};
      }
     } else {
       this.user.AppUserSupplyChainPermissions.push(foundSupplyChainPermission);
     }
  }

  editUser() {
    const user = this.user;
    let form = this.form.value.editForm;
    // form.AppUserPermissions = this.basePermissions;
    form.AppUserPermissions = this.basePermissions.filter((permission, index) => {
      return permission.read;
  })
    let orgIds = [form.OrganizationIds];
    form.OrganizationIds = orgIds;
    this.user.AppUserSupplyChainPermissions.forEach((supplyChain) => {
      supplyChain.Nodes.filter((node) => {
        return node.NodePermission.ReadOnly || node.NodePermission.ReadWrite || node.NodePermission.TransferInto;
      });
    })
    form.AppUserSupplyChainPermissions = this.user.AppUserSupplyChainPermissions;
    form.AppUserSupplyChainPermissions.reverse().forEach((supplyChain, index) => {
        if(!supplyChain.Nodes.length) {
            form.AppUserSupplyChainPermissions.reverse().splice(index,1);
        }
    })
    // delete form.EmailAddress;
    // delete form.Name;
    if (this.form.valid) { 
      this.appUserService.editUser(user.id, form).subscribe(res => {
        this.router.navigateByUrl('/admin/users/list');
      }, error => {
        console.log('%c error - add user POST failed: ', 'background: #ff0000; color: #ffffff', error);
      });
    }
  }

  ngOnDestroy() {
    this.existingUserSub.unsubscribe();
  }
}