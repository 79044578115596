import { BootstrapModule } from '@app/shared/bootstrap.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotAbsorbedTokenTableComponent } from './absorbed-token-table/absorbed-token-table.component';
import { LotAnalysisTokenTableComponent } from './analysis-token-table/analysis-token-table.component';
import { LotQualityTokenTableComponent } from './quality-token-table/quality-token-table.component';
import { LotTransferredTokenTableComponent } from './transferred-token-table/transferred-token-table.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [LotAbsorbedTokenTableComponent, LotAnalysisTokenTableComponent, LotQualityTokenTableComponent, LotTransferredTokenTableComponent],
  exports: [LotAbsorbedTokenTableComponent, LotAnalysisTokenTableComponent, LotQualityTokenTableComponent, LotTransferredTokenTableComponent]
})
export class TokenTablesModule { }
