import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MccColorPickerItem, MccColorPickerService } from 'material-community-components';
import { fromEvent } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'bext-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {

  private alive = true;
  private _selectedTheme;
  
  @ViewChild('colorPicker') colorPicker: ElementRef;
  
  @Input('selectedTheme')
  set selectedTheme(theme) {
    this._selectedTheme = theme;
  }
  get selectedTheme() {
    return this._selectedTheme;
  }

  @Output('colorPickerChange') colorPickerChange: EventEmitter<any> = new EventEmitter<any>();
  
  newlySelectedTheme: any;
  
  changeColor: string;
  
  form: FormGroup;
  
  originalTheme: any;
  // last line will not be show in used colors because they are invalid values
  usedStart: string[] = [
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
    '#000zzz',
    'zzzzzz',
  ];

  colors: string[] = [
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#FFFF99',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
  ];

  items: MccColorPickerItem[] = [
    { text: 'Black', value: '#000000' },
    { text: 'White', value: '#FFFFFF' },
    { text: 'Gray', value: '#CCCCCC' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private mccColorPickerService: MccColorPickerService
  ) { }

  ngOnInit() {
    this.originalTheme = {...this.selectedTheme};
  }

  reset(): void {
    this.mccColorPickerService.resetUseColors();
  }

  colorPickerAction(action, value): void {
    this.selectedTheme.value = value;
    this.colorPickerChange.emit({
      actionType: action,
      newThemeKey: this.selectedTheme.key,
      newThemeValue: value,
      originalTheme: this.originalTheme
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
  