import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { takeWhile, take, map, mergeMap, tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'bext-edit-copy-node-dialog',
  templateUrl: './edit-copy-node-dialog.component.html',
  styleUrls: ['./edit-copy-node-dialog.component.scss']
})
export class EditCopyNodeDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditCopyNodeDialogComponent>
  ) {
    console.log('EDIT COPY NODE DIALOG - data: ', this.data);
  }

  ngOnInit() {
  }

}
