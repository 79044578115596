import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TransferLotHelperObject, NodesStoreService, TransferLotHelperService } from '@app/core-v2/services';
import { NodeService } from '@app/core/services/backend/node.service';
import { takeWhile, tap } from 'rxjs/operators';
import { MatSelectionListChange } from '@angular/material';
import { TransferLotEntityV2 } from '@app/shared-v2/models/transfers/transfer-lot-payload.model';
import { LotService } from '@app/core/services/backend/lot.service';

@Component({
  selector: 'bext-transfer-one-existing-many-existing',
  templateUrl: './transfer-one-existing-many-existing.component.html',
  styleUrls: ['./transfer-one-existing-many-existing.component.scss']
})
export class TransferOneExistingManyExistingComponent implements OnInit {

  private alive = true;
  private _nodeList: any;

  transferObject: TransferLotHelperObject;
  currentNodeDetails: any;
  transferToNode: any;
  nodeLots: any;
  selectedLot: any;

  payload = new TransferLotEntityV2(
    {
      "TransferType": "LinkOneExistingToManyExisting",
      "ForwardTransfer": true,
      "FullTransfer": true,
      "CloseTransferredLots": true
    },
    []
  )

  @Input('nodeList')
  set nodeList(object) {
    this._nodeList = object;
  }
  get nodeList() {
    return this._nodeList;
  }

  @Output() submitTransfer = new EventEmitter<any>();

  constructor(private nodeStore: NodesStoreService, private transferLotHelperService: TransferLotHelperService, private nodeService: NodeService, private lotService: LotService) {
  }

  ngOnInit() {
    this.transferObject = this.transferLotHelperService.transferLotSubject;
    this.selectedLot = this.transferLotHelperService.selectedLots[0]
    this.currentNodeDetails = this.nodeStore.currentNodeDetails;

    this.transferLotHelperService.transferLotChange
      .pipe(
        takeWhile(() => this.alive),
        tap((res) => {
          // console.log('transfer lot object change: ', res);
          return;
        })
      ).subscribe();

    this.payload.addAbsorbedFromExistingLot(this.selectedLot);

  }

  resetTransferSubject() {
    this.transferLotHelperService.reInstantiateTransferLotSubject();
  }

  transferToNodeSelected(event) {
    let node = event.value;
    this.transferToNode = node;
    this.nodeService.getNodeById(node.NodeId)
      .pipe(
        takeWhile(() => this.alive),
        tap((node) => {
          this.transferLotHelperService.addTransferToNode(node);
        })
      ).subscribe();

    this.nodeService.getNodeLotsV2(node.NodeId)
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        res => {
          console.log('node lots: ', res);
          this.nodeLots = res.Lots;
          return;
        },
        error => {
          console.log('GET node lots failed: ', error);
          return;
        }
      );
  }

  transferToLotSelected(event: MatSelectionListChange) {
    let lot = event.option.value
    this.payload.addTransferredToExistingLot(lot);
  }

  submit() {
    let payload = this.payload.payloadValue;
    this.submitTransfer.emit(payload);
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
