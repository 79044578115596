import { environment } from '@env/environment';
import { UploadRoutes } from '@app/core-v2/api-routes-v2.enum';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';

@Injectable()
export class UploadService {
  
  BASE_URL = environment.baseApiUrl;
  UPLOAD_URL = "/uploads";
  UPLOADS_LOTS_URL = "/uploads/Lots";
  DELETE_URL = "/delete";

  constructor(private http: HttpClient) { }

  getUpload(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + UploadRoutes.BASE_URL + UploadRoutes.UPLOAD_URL + '/' + id);
  }

  uploadLotImage(id, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + this.UPLOAD_URL + '/' + id + '/lot', payload);
  }

  uploadNodeImage(id, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + this.UPLOADS_LOTS_URL + '/' + id + '/node', payload);
  }

  upload(payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + this.UPLOAD_URL + '/upload', payload);
  }

  deleteNodeImage(node_id, img_id): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + this.UPLOAD_URL + this.DELETE_URL + `/${node_id}/${img_id}`);
  }

  deleteImage(img_id): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + this.UPLOAD_URL + this.DELETE_URL + `/${img_id}`);
  }

  deleteVideo(video_id): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + this.UPLOAD_URL + this.DELETE_URL + `/${video_id}`);
  }

  uploadV2(files: Set<File>): { [key: string]: { progress: Observable<any> } } {
    // this will be the our resulting map
    const url = this.BASE_URL + this.UPLOAD_URL + '/upload';
    const status: { [key: string]: { progress: Observable<any> } } = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('files', file, file.name);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', url, formData, {
        reportProgress: true
      });

      // create a new progress-subject for every file
      const progress = new Subject<any>();

      // send the http-request and subscribe for progress-updates

      const startTime = new Date().getTime();
      this.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          // calculate the progress percentage

          const percentDone = Math.round((100 * event.loaded) / event.total);
          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse  ) {
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.next(event);
          progress.complete();
        }
      });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable()
      };
    });

    // return the map of progress.observables
    return status;
  }
}