import { IEntityMedia } from "./../../models/asset/asset.interface";
import { KeyValuePipe, formatDate } from "@angular/common";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { bextAnimations } from "../../animations/bext-animations";
import { take } from "rxjs/operators";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { AssetService } from "../../../core-v2/services/asset.service";
import { IAsset } from "@app/shared-v2/models/asset/asset.interface";
@Component({
  selector: "app-edit-asset",
  templateUrl: "./edit-asset.component.html",
  styleUrls: ["./edit-asset.component.scss"],
  animations: bextAnimations,
})
export class EditAssetComponent implements OnInit {
  editAssetForm: FormGroup;
  selectedName: any;
  botanicalNamesDict: { [key: string]: string };
  partnerNamesDict: { [key: string]: string };
  donorNamesDict: { [key: string]: string };
  //@Input() asset: IAsset;
  @Input() hideButtons = false;
  public images?: string[];
  public allBotanicalNames?: any[] = [{ id: "", name: "" }];
  public allPartnerNames?: any[] = [{ id: "", name: "" }];
  public allDonorNames?: any[] = [{ id: "", name: "" }];
  public selectedBotanicalNames?: any[] = [{ id: "", name: "" }];
  public selectedPartnerNames?: any[] = [{ id: "", name: "" }];
  public selectedDonorNames?: any[] = [{ id: "", name: "" }];
  newName: any;

  public photoGallery: any[] = [{ url: "", state: "0", id: "" }];
  firstImage: any;

  constructor(
    public dialogRef: MatDialogRef<EditAssetComponent>,
    @Inject(MAT_DIALOG_DATA) public asset: IAsset,
    private assetService: AssetService,
    public fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initBotanicalNames();
    this.initPartnerNames();
    this.initDonorNames();
    this.initGallery(this.asset);
    this.reactiveForm();
  }

  reactiveForm() {
    let newDate= new Date(this.asset.PlantedDate);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    this.editAssetForm = this.fb.group({
      botanicalName: [""],
      partnerName: [""],
      donorName: [""],
      plantedDate: [newDate],
    });

  }

  onKey(value) {
    this.selectedBotanicalNames = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.allBotanicalNames.filter((option) =>
      option.Name.toLowerCase().includes(filter)
    );
  }

  onNameSelection() {
  }

  //Partner Section

  onKeyPartner(value) {
    this.selectedPartnerNames = this.search(value);
  }

  searchPartner(value: string) {
    let filter = value.toLowerCase();
    return this.allPartnerNames.filter((option) =>
      option.Name.toLowerCase().includes(filter)
    );
  }

  onNameSelectionPartner() {
  }

  //Donor Section
  onKeyDonor(value) {
    this.selectedDonorNames = this.search(value);
  }

  searchDonor(value: string) {
    let filter = value.toLowerCase();
    return this.allDonorNames.filter((option) =>
      option.Name.toLowerCase().includes(filter)
    );
  }

  onNameSelectionDonor() {
  }

  date(e) {
    let newDate= new Date(e.target.value);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    this.editAssetForm.get("plantedDate").setValue(newDate, {
      onlyself: true,
    });
  }

  onSubmit() {

    console.log(
      "this.editAssetForm.value",
      "background: #ff0000; color: #ffffff",
      this.editAssetForm.value
    );

    if (this.editAssetForm.value.botanicalName.Name != null && this.editAssetForm.value.botanicalName.Id != null){
      let newBotanicalName  = this.allBotanicalNames.find(i => i.Id === this.editAssetForm.value.botanicalName.Id).NameShort;
      this.asset.BotanicalName = newBotanicalName;
      this.asset.PlantClassificationId = this.editAssetForm.value.botanicalName.Id;
    }


    console.log(
      "this.editAssetForm.value.partnerName.PartnerId",
      "background: #ff0000; color: #ffffff",
      this.editAssetForm.value.partnerName.PartnerId
    );

    if (this.editAssetForm.value.partnerName.Name != null && this.editAssetForm.value.partnerName.PartnerId != null){
      console.log(
        "allPartnerNames",
        "background: #ff0000; color: #ffffff",
        this.allPartnerNames
      );
      let newPartnerNameAbbreviation  = this.allPartnerNames.find(i => i.PartnerId === this.editAssetForm.value.partnerName.PartnerId).Abbreviation;
      let newPartnerName  = this.allPartnerNames.find(i => i.PartnerId === this.editAssetForm.value.partnerName.PartnerId).Name;
      console.log(
        "newPartnerNameAbbreviation",
        "background: #ff0000; color: #ffffff",
        newPartnerNameAbbreviation
      );
      this.asset.PartnerNameAbbreviation = newPartnerNameAbbreviation;
      this.asset.PartnerName = newPartnerName;
      this.asset.PartnerId = this.editAssetForm.value.partnerName.PartnerId;
    }

    if (this.editAssetForm.value.donorName.Name != null && this.editAssetForm.value.donorName.PartnerId != null){
      let newDonorNameAbbreviation  = this.allDonorNames.find(i => i.PartnerId === this.editAssetForm.value.donorName.PartnerId).Abbreviation;
      let newDonorName  = this.allDonorNames.find(i => i.PartnerId === this.editAssetForm.value.donorName.PartnerId).Name;
      this.asset.DonorNameAbbreviation = newDonorNameAbbreviation;
      this.asset.DonorName = newDonorName;
      this.asset.DonorId = this.editAssetForm.value.donorName.PartnerId;
    }

    if (this.editAssetForm.value.plantedDate != ""){
      this.asset.PlantedDate = this.editAssetForm.value.plantedDate;
    }
    
    console.log(
      "this.asset",
      "background: #ff0000; color: #ffffff",
      this.asset
    );

    // if (this.editNameForm.value.id != this.data?.BotanicalNameId) {
    this.assetService.updateAsset(this.asset).subscribe(
      (res) => {
        this.dialogRef.close(this.asset);
      },
      (error) => {
        console.log(
          "%c error - Update POST failed: ",
          "background: #ff0000; color: #ffffff",
          error
        );
      }
    );
    // }
  }

  updateAsset() {}

  initGallery(asset: IAsset) {
    if (!asset) {
      return;
    }
    var imageObjects = asset.Media.filter(function (entityMedia) {
      return (entityMedia.MediaType = "Image");
    });

    this.photoGallery = imageObjects.map((entity) => ({
      url: entity.URL,
      state: "0",
      id: entity.EntityId,
    }));

    if (this.photoGallery[0]) {
      this.photoGallery[0].state = "1";
    }
  }

  initBotanicalNames() {
    this.assetService
      .getAssetBotanicalNames()
      .pipe(take(1))
      //.map(res => )
      .subscribe((res: any) => {
        this.allBotanicalNames = res;
        this.selectedBotanicalNames = this.allBotanicalNames;
      });
  }

  initPartnerNames() {
    this.assetService
      .getAssetPartnerNames()
      .pipe(take(1))
      //.map(res => )
      .subscribe((res: any) => {
        this.allPartnerNames = res;
        this.selectedPartnerNames = this.allPartnerNames;
      });
  }

  initDonorNames() {
    this.assetService
      .getAssetDonorNames()
      .pipe(take(1))
      //.map(res => )
      .subscribe((res: any) => {
        this.allDonorNames = res;
        this.selectedDonorNames = this.allDonorNames;
      });
  }

  changeState(images) {
    if (images.state === "1") {
      return;
    }
    this.photoGallery = this.photoGallery.map((p) => {
      if (images.url === p.url) {
        setTimeout(() => {
          p.state = "1";
          return p;
        }, 290);
      }
      p.state = "0";
      return p;
    });
  }

  ngOnChanges() {
  }

  closeDialog() {
    if (Object.keys(this.dialogRef).length) {
      this.dialogRef.close();
    }
  }
}
