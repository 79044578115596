import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bext-avatar-card',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarCardComponent implements OnInit {

  constructor() { }

  @Input() persons;
  @Input() enableRotation = false;
  @Input() degreesOfRotation = 0;

  ngOnInit() {

  }


}
