/**
 * `deepSearch()` Function:
 * 
 * @param path
 * dot notation that specifies the location
 * of an objects property
 * 
 * 
 */

export function deepSearch(path) {
  path = (typeof path === 'string') ? path.split('.') : path;
  return function (obj) {
    return path.reduce(function (xs, x) {
      return xs && xs[x] ? xs[x] : "";
    }, obj);
  };
};

/**
 * `deepSet()` function
 *
 * @param path
 * comming soon ...
 *
 *
 */

export function deepSet(obj, path, value) {
  obj = typeof obj === 'object' ? obj : {};
  var keys = Array.isArray(path) ? path : path.split('.');
  var curStep = obj;
  for (var i = 0; i < keys.length - 1; i++) {
    var key = keys[i];
    if (!curStep[key] && !Object.prototype.hasOwnProperty.call(curStep, key)) {
      var nextKey = keys[i + 1];
      var useArray = /^\+?(0|[1-9]\d*)$/.test(nextKey);
      curStep[key] = useArray ? [] : {};
    }
    curStep = curStep[key] || {};
  }
  var finalStep = keys[keys.length - 1];
  curStep[finalStep] = value;
};



/*************************/
/***** FLATTEN OBJECT *****/
/*************************/
/* - take deeply nested object and flatten it to 1 level
*/

export function flattenObject(o: object): any {
   var flatten = function(obj) {
      var toReturn = {};
      
      for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        
        if ((typeof obj[i]) == 'object') {
          var flatObject = flatten(obj[i]);
          for (var x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;
            
            toReturn[i + '.' + x] = flatObject[x];
          }
        } else {
          toReturn[i] = obj[i];
        }
      }
      return toReturn;
    };
    return flatten(o);
  }



/*************************/
/***** FILTER OBJECT *****/
/*************************/
// - filter an object by properties (allowed properties is an array of object properties: string)

export function filterObject(obj: object, allowed: string[]): any {
  let result = Object.keys(obj)
    .filter(key => {
      return allowed.includes(key)
    })
    .reduce((o, key) => {
      o[key] = obj[key];
      return o;
    }, {});
  return result;
}



/********************************/
/***** UNIQUE OBJECTS ARRAY *****/
/********************************/
// - filter an array by unique objects

export function uniqueObjectsArray(array: any[], propertyToFilterBy?: string): any {
  const a = array.map(o => o);
  const ptfb = propertyToFilterBy;
  const r = [];
  const m = new Map();
  for (const obj of a) {
    if (!m.has(obj[ptfb])) {
      m.set(obj[ptfb], true);
      const newObj = {};
      for (let key in obj) {
        newObj[key] = obj[key];
      }
      r.push(newObj);
    }
  }
  return r;
}



/*********************************************/
/********* GET & SET OBJECT PROPERT *********/
/*********************************************/
// - get value for analytic data object by 'Name'
//   property

export function getAnalyticDataValueByName(obj) {
  let nameArray = obj.split('.');
  let valuePropertyName = nameArray[nameArray.length - 1];
  return obj[valuePropertyName];
}



/**********************************************/
/***** GET AND/OR SET OBJECT VALUE BY KEY *****/
/**********************************************/
// - get value of object by key 'path'
//   path is written in dot notation
//   e.x. - 'obj.someKey.someNestedKey'
/**
 * 
 * @param obj {"name": "John", "address": { "street": "123 Fake St", "city": "Boulder", "state": "Colorado" }}
 * @param path "address.state" // if key:value exists on the object then expected return "Colorado"
 * @param value (optional - for example if the path were "address.zipCode" and the value were "80304" ) the key:value pair doesn't exists on the object, therefore the fn would create a new key:value pair and set to the value to 80304
 * 
 * expected return {"name": "John", "address": { "street": "123 Fake St", "city": "Boulder", "state": "Colorado", "zipCode": "" }}
 */

export function getSetObjectProp(obj, path, value?) {
  var arr = path ? path.split(".") : [];

  while (arr.length && obj) {
    var comp = arr.shift();
    var match = new RegExp("(.+)\\[([0-9]*)\\]").exec(comp);

    // handle arrays
    if ((match !== null) && (match.length == 3)) {
      var arrayData = {
        arrName: match[1],
        arrIndex: match[2]
      };
      if (obj[arrayData.arrName] !== undefined) {
        if (typeof value !== 'undefined' && arr.length === 0) {
          obj[arrayData.arrName][arrayData.arrIndex] = value;
        }
        obj = obj[arrayData.arrName][arrayData.arrIndex];
      } else {
        obj = undefined;
      }

      continue;
    }

    // handle regular things
    if (typeof value !== 'undefined') {
      if (obj[comp] === undefined) {
        obj[comp] = {};
      }

      if (arr.length === 0) {
        obj[comp] = value;
      }
    }

    obj = obj[comp];
  }

  return obj;
}
