import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { MatButtonModule, MatIconModule, MatSidenavModule, MatToolbarModule, MatProgressSpinnerModule, MatExpansionModule, MatFormFieldModule, MatInputModule, MatTabsModule, MatCheckboxModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { FormsModule } from '@angular/forms';
import { SearchbarModule } from '@app/shared/searchbar/searchbar.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GoogleMapComponent } from '@app/shared-v2/google-map/google-map.component';



@NgModule({
  imports: [
    CommonModule,
    AgmCoreModule,
    RouterModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    AgmJsMarkerClustererModule,
    MatExpansionModule,
    AgmSnazzyInfoWindowModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    SearchbarModule,
    Ng2SearchPipeModule,
    MatTabsModule,
    MatCheckboxModule
  ],
  declarations: [
    GoogleMapComponent
  ]
  ,
  exports: [
    GoogleMapComponent
  ],
  providers: [
    GoogleMapsAPIWrapper
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class GoogleMapModule { }
