import { AuthGuard } from "@app/core-v2/guards/auth.guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { NovariLayoutComponent } from "./shared/layout/app-layouts/novari-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { NespressoLayoutComponent } from "./shared/layout/app-layouts/nespresso-layout.component";
import { MainLayoutV2Component } from "./shared/layout/app-layouts/main-layout-v2.component";
import { NespressoLayoutV2Component } from "./shared/layout/app-layouts/nespresso-layout-v2.component";
import { EmptyLayoutComponent } from "./shared/layout/app-layouts/empty-layout.component";
import { FullScreenComponent } from "./shared/layout";

const routes: Routes = [
  {
    path: "novari",
    component: NovariLayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "nespresso",
        pathMatch: "full",
      },
      {
        path: "nespresso",
        loadChildren: "./features-v2/novari/novari.module#NovariModule",
      },
      {
        path: "**",
        redirectTo: "nespresso",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "nespresso",
    component: NespressoLayoutV2Component,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren:
          "./features-v2/nespresso/nespresso.module#NespressoV2Module",
      },
      {
        path: "**",
        redirectTo: "",
      },
    ],
  },
  {
    path: "v2",
    component: MainLayoutV2Component,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: "./features-v2/home/home.module#HomeV2Module",
      },
      {
        path: "assetdashboard",
        loadChildren:
          "./features-v2/asset-dashboard/asset-dashboard.module#AssetDashboardModule",
      },
      {
        path: "node-asset",
        loadChildren:
          "./features-v2/node-asset/node-asset.module#NodeAssetModule",
      },
      {
        path: "nodeasset",
        loadChildren:
          "./features-v2/node-asset/node-asset.module#NodeAssetModule",
      },
      {
        path: "node",
        loadChildren: "./features-v2/node/node.module#NodeV2Module",
      },
      {
        path: "nespresso",
        loadChildren:
          "./features-v2/nespresso/nespresso.module#NespressoV2Module",
      },
      {
        path: "settings",
        loadChildren: "./features-v2/settings/settings.module#SettingsModule",
      },
      {
        path: "verifydata/workflow",
        loadChildren: "./features-v2/verify/verify.module#VerifyModule",
      },
      {
        path: "reports",
        loadChildren: "./features-v2/reports/reports.module#ReportsModule",
      },
      {
        path: "asset-valuation",
        loadChildren:
          "./features-v2/asset-valuation-v2/asset-valuation.module#AssetValuationV2Module",
      },
      {
        path: "sustainability",
        loadChildren:
          "./features-v2/sustainability/sustainability.module#SustainabilityModule",
      },
      {
        path: "lot",
        loadChildren: "./features-v2/lots/lots.module#LotsV2Module",
      },
      {
        path: "lot-details",
        loadChildren:
          "./features-v2/lot-details/lot-details.module#LotDetailsModule",
      },
      {
        path: "asset-details",
        loadChildren:
          "./features-v2/asset-details/asset-details.module#AssetDetailsModule",
      },
      {
        path: "report",
        loadChildren:
          "./features-v2/report-summaries/report-summaries.module#ReportSummariesModule",
      },
      {
        path: "maps",
        loadChildren: "./features-v2/maps/maps.module#MapsModule",
      },
      {
        path: "wizard",
        loadChildren: "./features-v2/wizard/wizard.module#WizardModule",
      },
      {
        path: "api",
        loadChildren: "./features-v2/api/api.module#ApiModule",
      },
      {
        path: "misc",
        loadChildren: "./features-v2/misc/misc.module#MiscModule",
      },
      {
        path: "marketplace",
        loadChildren:
          "./features-v2/marketplace/marketplace.module#MarketPlaceModule",
      },
      {
        path: "**",
        redirectTo: "misc/404",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "v2-report",
    component: EmptyLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "retail",
        loadChildren:
          "./features-v2/retail-report/retail-report.module#RetailReportModule",
      },
      {
        path: "**",
        redirectTo: "./features-v2/home/home.module#HomeV2Module",
      },
    ],
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: "./features-v2/auth/auth.module#AuthModule",
  },
  { path: "**", redirectTo: "v2" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
