import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockchainListComponent } from './blockchain-list.component';
import { LotService } from '@app/core/services/backend/lot.service';
import { SharedModule } from '@app/shared/shared.module';
import { MaterialModule } from '@app/shared/angular-material.module';

@NgModule({
  declarations: [BlockchainListComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ],
  providers:  [LotService],
  entryComponents: [BlockchainListComponent]
})
export class BlockchainListModule { }
