import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RetailGlobeComponent } from './retail-globe/retail-globe.component';
import { GlobeService } from '../../core-v2/services/globe.service';
import { StoryGlobeComponent } from './story-globe/story-globe.component';
import { BaseGlobeComponent } from './base-globe/base-globe.component';
import { BaseGlobeDirective } from './base-globe/base-globe.directive';
import { MatListModule, MatIconModule, MatDividerModule, MatButtonModule } from '@angular/material';
import { CustomDirectivesModule } from '../custom-directives/custom-directives.module';
import { RouterModule } from '@angular/router';
import { PhotoGalleryModule } from '@app/shared-v2/photo-gallery/photo-gallery.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PhotoGalleryModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    CustomDirectivesModule
  ],
  declarations: [
    RetailGlobeComponent,
    StoryGlobeComponent,
    BaseGlobeComponent,
    BaseGlobeDirective
  ],
  exports: [
    RetailGlobeComponent,
    StoryGlobeComponent,
    BaseGlobeComponent,
    BaseGlobeDirective
  ]
})
export class GlobesModule { }
