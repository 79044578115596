import { RecyclingActivityService } from './recycling-activity.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './users.service';
import { ElectricityService } from './electricity.service';
import { BoutiquesListService } from './boutiques-list.service';
import { UpsService } from './ups.service';
import { PeriodsService } from './periods.service';
import { CapsulesService } from '../utils/capsules.service';
import { MapDataService } from './map-data.service';
import { CapsuleReportService } from './capsule-report.service';
import { EchartEventsService } from './echart-events.service';

const SERVICES = [
  UserService,
  ElectricityService,
  BoutiquesListService,
  UpsService,
  RecyclingActivityService,
  PeriodsService,
  CapsulesService,
  CapsuleReportService,
  MapDataService,
  EchartEventsService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class MockDataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: MockDataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
