import { Injectable } from '@angular/core';
import { of, BehaviorSubject, Observable } from 'rxjs';
import * as TransferUtils from '@app/core-v2/utils/transfer-utils';
import { TransferLot } from '@app/shared-v2/models/transfer-lot.model';

export interface TransferLotHelperObject {
  transferFromNode?: any | null;
  transferToNode?: any | null;
  transferTypeConfig?: TransferTypeConfig;
  selectedLots?: (any)[] | null;
  selectedExistingLots?: (any)[] | null;
  formConfig: FormConfig;
}
export interface FormConfig {
  sections?: (any)[] | null;
  payload?: any | null;
}
export interface TransferTypeConfig {
  from?: TransferTypeConfigProperties;
  to?: TransferTypeConfigProperties;
}
export interface TransferTypeConfigProperties {
  amount: string | null;
  existing: boolean | null;
}


@Injectable()
export class TransferLotHelperService {

  initialTransferHelperObject = { transferFromNode: null, transferToNode: null, transferTypeConfig: { from: { amount: null, existing: false }, to: { amount: null, existing: false } }, selectedLots: [], selectedExistingLots: [], formConfig: { sections: [], payload: null } };

  constructor() {}
  
  private _transferLotSubject = new BehaviorSubject<TransferLotHelperObject>(this.initialTransferHelperObject);
  private _transferExistingLotSubject = new BehaviorSubject<any>([]);

  get transferLotSubject() {
    return this._transferLotSubject.getValue();
  }

  get transferLotChange() {
    return this._transferLotSubject.asObservable();
  }

  get selectedLots() {
    return this._transferLotSubject.getValue().selectedLots;
  }

  addTransferFromNode(node) {
    let transferObject = this.transferLotSubject;
    transferObject.transferFromNode = node;
    this._transferLotSubject.next(transferObject);
  }

  addTransferToNode(node) {
    let transferObject = this.transferLotSubject;
    transferObject.transferToNode = node;
    this._transferLotSubject.next(transferObject);
  }

  clearSelectedLots() {
    let transferObject = this.transferLotSubject;
    transferObject.selectedLots = [];
    this._transferLotSubject.next(transferObject);
  }

  addLotsToSelectionsAndUpdateTransferType(lot: any) {
    let transferObject = this.transferLotSubject;
    transferObject.selectedLots.unshift(lot);
    switch (Array.isArray(transferObject.selectedLots)) {
      case (transferObject.selectedLots.length === 1):
        transferObject.transferTypeConfig.from.amount = 'one'
        break;

      case (transferObject.selectedLots.length > 1):
        transferObject.transferTypeConfig.from.amount = 'many'
        break;

      case (transferObject.selectedLots.length === 0):
        transferObject.transferTypeConfig.from.amount = null;
        transferObject.transferTypeConfig.from.existing = false;
        break;

      default:
        transferObject
        break;
    }
    this._transferLotSubject.next(transferObject);
  }

  addLotsToSelections(lot) {
    let transferObject = this.transferLotSubject;
    let index = transferObject.selectedLots.findIndex((existingLot) => existingLot === lot);
    console.log('add lot to selection - index: ', index);
    index === -1 ? transferObject.selectedLots.push(lot) : transferObject.selectedLots.splice(index, 1);
    this._transferLotSubject.next(transferObject);
  }



  removeLotsFromSelections(removeLot: any) {
    let transferObject = this.transferLotSubject;
    let index = transferObject.selectedLots.findIndex((existingLot) => existingLot === removeLot);
    transferObject.selectedLots.splice(index, 1);
    switch (Array.isArray(transferObject.selectedLots)) {
      case (transferObject.selectedLots.length === 1):
        transferObject.transferTypeConfig.from.amount = 'one'
        break;

      case (transferObject.selectedLots.length > 1):
        transferObject.transferTypeConfig.from.amount = 'many'
        break;

      case (transferObject.selectedLots.length === 0):
        transferObject.transferTypeConfig.from.amount = null;
        transferObject.transferTypeConfig.from.existing = false;
        break;

      default:
        transferObject
        break;
    }
    this._transferLotSubject.next(transferObject);
  }

  addLotToExistingSelections(lot) {
    let transferObject = this.transferLotSubject;
    transferObject.selectedExistingLots.push(lot);
    this._transferLotSubject.next(transferObject);
  }

  addFormConfig(prop, value) {
    let transferObject = this.transferLotSubject;
    transferObject.formConfig[prop] = value
    this._transferLotSubject.next(transferObject);
  }

  addTransferFromAmount(amount: string, existing?: boolean) {
    let transferObject = this.transferLotSubject;
    transferObject.transferTypeConfig.from.amount = amount;
    transferObject.transferTypeConfig.from.existing = existing !== undefined ? existing : transferObject.transferTypeConfig.from.existing;
    this._transferLotSubject.next(transferObject);
  }

  addTransferToAmount(amount: string, existing?: boolean) {
    let transferObject = this.transferLotSubject;
    transferObject.transferTypeConfig.to.amount = amount;
    transferObject.transferTypeConfig.to.existing = existing !== undefined ? existing : transferObject.transferTypeConfig.to.existing;
    this._transferLotSubject.next(transferObject);
  }

  updateTransferTypeConfig(config: TransferTypeConfig) {
    let transferObject = this.transferLotSubject;
    transferObject.transferTypeConfig = config;
    this._transferLotSubject.next(transferObject);
  }

  updateTransferTypeConfigAndSelectedLots(config: TransferTypeConfig, lots) {
    let transferObject = this.transferLotSubject;
    transferObject.transferTypeConfig = config;
    transferObject.selectedLots = lots.map((lot) => lot)
    this._transferLotSubject.next(transferObject);
  }

  resetTransferTypeConfig() {
    let transferObject = this.transferLotSubject;
    let config: TransferTypeConfig = { from: { amount: null, existing: false }, to: { amount: null, existing: false } }
    transferObject.transferTypeConfig = config;
    this._transferLotSubject.next(transferObject);
  }

  resetTransferTypeConfigAndSelectedLots() {
    let transferObject = this.transferLotSubject;
    this.resetTransferTypeConfig();
    transferObject.selectedLots = [];
    this._transferLotSubject.next(transferObject);
  }

  addFormUiData(uiData, requiresFormatting = true, addSubmitButton = true) {
    let formattedUiData = requiresFormatting ? TransferUtils.UiDataUtils.convertUiDataToNewFormart(uiData) : uiData;

    // create a SAVE button because backend does not contain UiData for button
    let addtionalUiData = [
      // // ----- FAKE SECTION TO TEST MULTIPLE UPLOADS -----
      // { sectionName: 'Fake Certification', fields: [{ "type": "fileupload", "label": "FAKE CERT", "inputType": "file", "name": "FAKECERT.Measure", "visible": true}] },
      // // ----- END FAKE SECTION -----
      { sectionName: 'SUBMIT', fields: [{ "label": "Transfer Lot", "type": "button", "visible": true }] },
    ];
    addSubmitButton ? addtionalUiData.map(UiData => formattedUiData.push(UiData)) : formattedUiData;

  }

  resetFormConfig() {
    let transferObject = this.transferLotSubject;
    transferObject.formConfig.sections.length = 0;
    transferObject.formConfig.payload = undefined;
    this._transferLotSubject.next(transferObject);
  }

  reInstantiateTransferLotSubject() {
    let initialState = { transferFromNode: null, transferToNode: null, transferTypeConfig: { from: { amount: null, existing: false }, to: { amount: null, existing: false } }, selectedLots: [], selectedExistingLots: [], formConfig: { sections: [], payload: null } };
    this._transferLotSubject.next(initialState);
  }

  setAbosrbedFromLot(absorbedFromNode) {
    let transferObject = this.transferLotSubject;
    transferObject.formConfig.payload.TransferLotV1Dtos[0].AbsorbedFromLot.LotMetaData.LotId = transferObject.selectedLots[0].id;
    transferObject.formConfig.payload.TransferLotV1Dtos[0].AbsorbedFromLot.LotMetaData.OwnerOrganizationId = absorbedFromNode.OwnerOrganizationId;
    transferObject.formConfig.payload.TransferLotV1Dtos[0].AbsorbedFromLot.LotMetaData.NodeId = absorbedFromNode.id;
    transferObject.formConfig.payload.TransferLotV1Dtos[0].AbsorbedFromLot.LotMetaData['SupplyChainId'] = undefined;
    this._transferLotSubject.next(transferObject);
  }

  createTransferredToLot(selectedNodeId) {
    let transferObject = this.transferLotSubject;
    transferObject.formConfig.payload.TransferLotV1Dtos[0].TransferredToLot = new TransferLot();
    transferObject.formConfig.payload.TransferLotV1Dtos[0].TransferredToLot.LotMetaData.NodeId = selectedNodeId;
    this._transferLotSubject.next(transferObject);
  }

}
