import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { UploadService } from '@app/core/services/backend/uploads.service';
import { fromEvent, pipe, from, of, Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LocationService } from '@app/core/services/backend/locations.service';

@Component({
  selector: 'bext-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.css']
})
export class ImageGalleryComponent implements OnInit {

  @ViewChild('imageSearchInput') imageSearchInput: ElementRef;
  @Input() placeholder: string;
  @Input('images') images: any[];
  @Input('disableZoom') disableZoom = true;
  @Input('disableDelete') disableDelete = false;
  @Input('enableSearchBar') enableSearchBar = true;
  @Output('onDeleteImage') onDeleteImage: EventEmitter<any> = new EventEmitter<any>();
  @Output('searchForImage') searchForImage: EventEmitter<any> = new EventEmitter<any>();
  @Output('openPdf') openPdf: EventEmitter<any> = new EventEmitter<any>();

  imageList;
  showFileNames = false;
  displaySearchIcon = false;
  queryString: string = '';
  imageForm: FormGroup;
  label = 'search images'

  constructor(private uploadService: UploadService, private fb: FormBuilder, private locationService: LocationService) { }

  ngOnInit() {
    this.appendImageType();
  }

  ngOnChanges(changes): void {
    if (changes.images.currentValue) {
      this.appendImageType();
    }
  }

  appendImageType() {
      this.images.forEach(img => {
        let type = img.ImageURL.split('.')[img.ImageURL.split('.').length - 1];
        img['_fileType'] = type;
      });
    this.imageForm = this.fb.group({
      'imageParams': new FormControl('')
    });
  }

  ngAfterViewInit(): void {
    if (this.enableSearchBar) {
      this.addListenerToSearchBox();
    }
  }

  addListenerToSearchBox() {
    fromEvent<any>(this.imageSearchInput.nativeElement, 'keyup')
      .pipe(
        map(event => event),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(e => {
        e.stopPropagation();
        e.preventDefault();
        this.setSearchQuery(e.target.value);
      })
  }

  setSearchQuery(text) {
    this.queryString = text;
  }

  onImageClick(event, img) {
    event.stopPropagation();
    event.preventDefault();
    window.open(img.ImageURL, '_blank');
  }

  onPdfClick(event, img) {
    event.stopPropagation();
    event.preventDefault();
    this.openPdf.emit({image: img });
    // console.log('pdf file: ', img);

    // window.open(`#/pdf/viewer/${img.ImageId}`, '_blank');
  }

  deleteImage(event, image) {
    event.stopPropagation();
    event.preventDefault();
    this.onDeleteImage.emit(image);
  }

  onSearchSubmit(e) {
    const searchParams = this.imageForm.value.imageParams.trim().split('');
    e.stopPropagation();
    e.preventDefault();
    console.log('%c search params ', 'background: #41ff6b; color: #ff4700;', searchParams.length);
    let text = this.imageSearchInput.nativeElement.value;
    if (searchParams.length) {
      this.searchForImage.emit(this.imageForm.value);
    }
  }

}
