import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LotService } from '@app/core/services/backend/lot.service';
import { NodeService } from '@app/core/services/backend/node.service';
import { TransferLotHelperService, NodesStoreService } from '@app/core-v2/services';
import { takeWhile, tap } from 'rxjs/operators';
import { MatSelectionListChange } from '@angular/material';
import { TransferLotEntityV2 } from '@app/shared-v2/models/transfers/transfer-lot-payload.model';

@Component({
  selector: 'bext-transfer-many-one',
  templateUrl: './transfer-many-one.component.html',
  styleUrls: ['./transfer-many-one.component.scss']
})
export class TransferManyOneComponent implements OnInit {

  private _nodeList;
  private alive = true;

  loadingTransferredToNode = false;

  form: FormGroup;

  nodeLots: any[] = [];

  transferToNode;

  selectedLot;
  selectedLots: any[] = [];

  @Input('nodeList')
  set nodeList(object) {
    this._nodeList = object;
  }
  get nodeList() {
    return this._nodeList;
  }

  @Output('submitTransfer') submitTransfer = new EventEmitter<any>();

  payload = new TransferLotEntityV2(
    {
      "TransferType": "ManyExistingToOneNewLot",
      "ForwardTransfer": true,
      "FullTransfer": true,
      "CloseTransferredLots": true
    },
    []
  )

  constructor(private nodeStore: NodesStoreService, private transferLotHelperService: TransferLotHelperService, private nodeService: NodeService, private lotService: LotService, private fb: FormBuilder) {
    this.form = this.fb.group({
      'LotName': new FormControl('', [Validators.required]),
      'LotStartWeight': new FormControl({value: '', disabled: true}, [Validators.required]),
      'LotStartWeightUnit': new FormControl('', [Validators.required])
    });
  }

  get LotName() {
    return this.form.get('LotName') as FormGroup;
  }

  get LotStartWeight() {
    return this.form.get('LotStartWeight') as FormGroup;
  }

  get LotStartWeightUnit() {
    return this.form.get('LotStartWeightUnit') as FormGroup;
  }

  ngOnInit() {

    this.nodeStore.nodeLotsV2BehaviorSubject
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        res => {
          this.nodeLots = res.Lots.filter((lot) => lot.LotStatus.toLowerCase() === 'active');
          return;
         },
        error => {
          console.log('error getting NodeLots from store: ', error);
          
          return;
        }
      );

    this.transferLotHelperService.transferLotChange
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        res => {
          if (res.selectedLots.length === 1 && !this.selectedLot) {
            this.selectedLot = res.selectedLots[0]
            this.payload.addAbsorbedFromManyExistingLot(this.selectedLot)
          }
          this.selectedLots = res.selectedLots;
          this.updateLotStartWeight(this.selectedLots);
          return;
          }
      );
      
  }

  resetTransferSubject() {
    this.transferLotHelperService.reInstantiateTransferLotSubject();
  }

  transferFromLotSelected(event: MatSelectionListChange) {
    console.log('%c transfer from lot selected: ', 'background: #fae552; color: #323232;', event);
    this.transferLotHelperService.addLotsToSelections(event.option.value);
    this.payload.addAbsorbedFromManyExistingLot(event.option.value)
  }

  updateLotStartWeight(selectedLots) {
    let addLotWeight = (prev, curr) => prev + curr.LotCurrentWeightDb;
    let sumSelectedLotsWeight = selectedLots.reduce(addLotWeight, 0);
    this.LotStartWeight.setValue(sumSelectedLotsWeight);
  }

  transferToNodeSelected(event) {
    this.loadingTransferredToNode = true;
    this.transferToNode = event.value;
    this.nodeService.getNodeById(this.transferToNode.NodeId)
      .pipe(
        takeWhile(() => this.alive),
        tap((node) => {
          this.transferLotHelperService.addTransferToNode(node);
        })
      ).subscribe(
        res => res,
        error => error,
        () => this.loadingTransferredToNode = false
      );
  }

  transferManyToOneSubmitted() {
    let transferredTolotData = {LotName: this.LotName.value, LotStartWeight: this.LotStartWeight.value, LotStartWeightUnit: this.LotStartWeightUnit.value};
    // console.log('%c form value: ', 'background: #fae552; color: #323232;', transferredTolotData, '\n', this.form);
    let payload = this.payload.updatOneToManyTransferredToLotDto(this.payload.payloadValue, transferredTolotData, this.transferToNode);
    this.submitTransfer.emit(payload);
  }

  

  ngOnDestroy() {
    this.payload.destroyPayload();
    this.alive = false;
  }


}
