import { AnalyticData } from '@app/shared-v2/models/analytic-data.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { TransferLot } from '@app/shared-v2/models/transfer-lot.model';


@Injectable()
export class EditLotStoreService {

  constructor() { }

  private _editLotSubject = new BehaviorSubject<any>(null);

  get currentEditLot() {
    return this._editLotSubject.getValue();
  }

  get currentEditLotChange() {
    return this._editLotSubject.asObservable();
  }

  setCurrentEditLot(lot) {
    let newLot = new TransferLot();
    // console.log('%c EXISTING LOT: ', 'background: #41ff6b; color: #ff4700;', lot);
    let lotClone = _.defaultsDeep(lot, newLot);
    // console.log('%c LOT CLONE: ', 'background: #41ff6b; color: #ff4700;', lotClone);
    this._editLotSubject.next(lotClone)
  }

  updateCurrentEditLot(editLotFormValues) {
    let formValues = Object.keys(editLotFormValues);
    let lot = this._editLotSubject.getValue();
    // console.log('%c EDIT LOT FORM OBJECT: ', 'background: #41ff6b; color: #ff4700;', editLotFormValues);
    formValues.forEach((path) => {
      // console.group('PATH & VALUE');
      // console.log('%c path - : ', 'background: #fae552; color: #323232;', path);
      // console.log('%c value - : ', 'background: #fae552; color: #323232;', editLotFormValues[path]);
      // console.groupEnd();
      if (path.indexOf('LotMetaData') > -1) {
        _.set(lot, path, editLotFormValues[path])
      } else {
        let foundLotData = lot.LotDatas.find(data => data.Name == path);
        if (foundLotData) {
          _.set(foundLotData, path.split('.')[1], editLotFormValues[path]);
        }
        else {
          let ad = new AnalyticData(path, editLotFormValues[path]);
          lot.LotDatas.push(ad);
        }
      }
    });
    return lot;
  }

  resetCurrentEditLot() {
    this._editLotSubject.next(null);
  }

}