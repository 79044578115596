import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter, Output, SimpleChanges, Inject } from '@angular/core';

import { MarkerService } from '@app/core/services/backend/marker.service';
import { tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'add-verify-markers',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.css']
})
export class MarkerComponent {
    @Input() markerCount = 0;
    @Input() currentLot: any;
    @Input() createNode = false;
    @Input() addMarkers = [];
    @Input() verifyMarkers = [];
    @Input() previousAddedMarkers = [];
    @Output() markersChanged = new EventEmitter<any>();
    @Output() verifyMarkerSave = new EventEmitter<any>();
    @Output() verifyMarker = new EventEmitter<any>();
    @Output() deleteAddedMarker = new EventEmitter<any>();
    @Output() saveAddedMarkerChanges = new EventEmitter<any>();
    @Input() show = false;
    @Input() disabled = false;
    probabilityScore;
    sampleNumber;
    values;
    // verifyMarker;
    showSyntheticFields;
    changedMarkers: any[] = [];
    copyOfAddMarkers = [];
    private copyCount = 0;
    markerCountArray: any[] = [];
    paginatedMarkersArray: any[] = [];
    currentMarkerPage = 0;

    markerList = [
      this.buildPayload('Haelixa', 'SyntheticDNA', 'DNA Sequence', 'Unique sequence of Natural or Artificial DNA in an invisible particle', ''),
      this.buildPayload('Bext', 'Biome', 'Biome', 'Bext Biome Marker', ''),
      this.buildPayload('Oritain', 'Isotope', 'Isotope', 'Verify product is consistent with claimed origin fingerprint', ''),
      this.buildPayload('Tailorlux', 'FluorescentFiber', 'Fluorescent Fiber', 'Unique Fluorescent embedded in cellulose fiber ', ''),
      this.buildPayload('Bext360', 'Fluorescent', 'Fluorescent', 'Fluorescent Marker', ''),
      this.buildPayload('InCode', 'NFC', 'NFC', 'Near Field Communication', 'NFC'),
      this.buildPayload('InCode', 'Invisible', 'Invisible', 'Raman spectroscopy to provide structural fingerprint to identify molecules', ''),
      this.buildPayload('InCode', 'Fluorescent', 'Li-Code Fluorescent', 'Fluorescent Marker', '')
    ];

    constructor(private markerService: MarkerService, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<MarkerComponent>) {

    }

    showVerifyFields(value) {
      this.verifyMarker.emit(value);
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.markerCount && !changes.markerCount.firstChange && changes.markerCount.currentValue) {
        console.log('changes: ', changes.markerCount.currentValue);
        for (let i = 0; i < Math.floor(changes.markerCount.currentValue / 5); i++) {
          this.markerCountArray.push(i);
          
        }
        // this.markerCountArray = Array(Math.floor(changes.markerCount.currentValue / 5)).fill().map((x, i) => i);
      }
    }

    ngOnInit() {
      console.log('add markers: ', this.addMarkers);
      console.log('marker count: ', this.markerCount);
      this.addMarkers = this.addMarkers || this.data.addMarkers;
      this.verifyMarkers = this.verifyMarkers || this.data.verifyMarkers;
      this.verifyMarkers.forEach(marker => {
        if (marker.MarkerAddType == 'MicroBiome') {
          marker.MicroBiomeAddData = marker.MicrobiomeAddData;
          let tem = marker.MarkerAddType || marker.MarkerType;
          this.markerList.filter(itMarker => itMarker.MarkerType == tem)[0].added = true;
        }
      })
      this.addMarkers.forEach(marker => {
        if (marker.MarkerAddType == 'MicroBiome') {
          marker.MicroBiomeAddData = marker.MicrobiomeAddData;
          let tem = marker.MarkerAddType || marker.MarkerType;
          this.markerList.filter(itMarker => itMarker.MarkerType == tem)[0].added = true;
        }
      })
      this.markOptions();

    }

    // ngOnChanges() {
    //   console.log(this.addMarkers);
    //   this.createCopyOfMarkers();
    //   this.markersChanged.emit(this.addMarkers);
    //   if (!this.show) {
    //     this.markerList.forEach(marker => marker.added = false)
    //   }
    // }

    // createCopyOfMarkers() {
    //   if (this.addMarkers.length && this.copyCount === 0) {
    //     this.copyOfAddMarkers = JSON.parse(JSON.stringify(this.addMarkers));
    //     this.copyCount++;
    //   }
    //   else {
    //     if (this.copyCount > 0) {
    //       this.changedMarkers.length = 0;
    //     }
    //   }
    // }

    getMarkerPage(action, idx?) {
      switch (action) {
        case 'prev':
          // console.log('this.markerCountArray: ', this.markerCountArray);
          this.currentMarkerPage = this.currentMarkerPage > 0 ? this.currentMarkerPage - 1 : 0;
          break;

        case 'current':
          // console.log('current lot ', this.currentLot);
          this.currentMarkerPage = idx;
          let startIndex;
          if (idx === 0) {
            startIndex = 0  
          } else {
            startIndex = idx * 5;
          }
          
          this.markerService.getLotMarkers(this.currentLot.id, startIndex)
            .pipe(
              tap(res => {
                this.paginatedMarkersArray = res[0].AddedMarkerIds.slice(startIndex, startIndex + 5);
              })
            )
          
          .subscribe(
            res => {
              console.log('get paginated markers starting at 0', res);
              console.log('this.paginatedMarkersArray: ', this.paginatedMarkersArray);
              return;
             },
            error => {
              console.log('error: ', error);
              
              return;
            },
            () => {
              // console.log('--- subscription complete ---');
              
            }
          );
          
          break;

        case 'next':
          console.log('this.markerCountArray: ', this.markerCountArray);
          this.currentMarkerPage = this.currentMarkerPage < this.markerCountArray.length - 1 ? this.currentMarkerPage + 1 : this.markerCountArray.length - 1  ;
          break;
      
        default:
          break;
      }
    }

    buildPayload(company, type, name, description, code) {
      let tempPayload = {
        'MarkerCompany': company,
        'MarkerType': type,
        'MarkerName': name,
        'MarkerDescription': description,
        'MarkerCodeName':code,
        'added': false
      }
      return tempPayload;
    }

    markOptions() {
      this.addMarkers.forEach(marker => {
        let tem = marker.MarkerAddType || marker.MarkerType;
        let tempMarker = this.markerList.filter(marker => {
          return marker.MarkerType == tem;
        })[0];
        if (tempMarker) {
          tempMarker.added = true;
        }

      })
      this.verifyMarkers.forEach(marker => {
        let tem = marker.MarkerAddType || marker.MarkerType;
        this.markerList.filter(marker => marker.MarkerType == tem)[0].added = true;
      })
    }

    addMarker(marker) {
      marker.MarkerCodes = [];
      marker.MarkerCodes.push({ "AttributeTypeId": 0,
      "Type": "",
      "Default": true,
      "Name": "",
      "Method": "",
      "Group": "",
      "Unit": "",
      "Measure": marker.MarkerCodeName,
      "StartMeasureTime": "",
      "EndMeasureTime": "",
      "MeasureTime": ""})
      this.addMarkers.push(JSON.parse(JSON.stringify(marker)));
      marker.added = true;
      this.markersChanged.emit(this.addMarkers);
    }

    deleteMarker(marker, i) {
      let tem = marker.MarkerAddType || marker.MarkerType;
      let filteredArray = this.addMarkers.filter(marker => (marker.MarkerType == tem || marker.MarkerAddType == tem));
      if (filteredArray.length == 1) {
        this.markerList.filter(marker => marker.MarkerType == tem).length ? this.markerList.filter(marker => marker.MarkerType == tem)[0].added = false : '';
      }
      this.deleteAddedMarker.emit({'marker': marker, 'index': i})
      this.markersChanged.emit(this.addMarkers);
    }

    editMarker(marker) {
      this.saveAddedMarkerChanges.emit(marker);
      // let markerIndex = this.changedMarkers.indexOf(marker.AddMarkerId);
      // if (markerIndex === -1) {
        // this.changedMarkers.push(marker.AddMarkerId)
      // }
    }

    // onSaveMarkerChanges(marker) {
    //   this.removeMarkerFromList(marker);
    //   this.saveAddedMarkerChanges.emit(marker);
    // }

    // onCancelMarkerChanges(marker) {
    //   this.removeMarkerFromList(marker);
    //   this.resetMarkCodeToOriginal(marker);
    // }

    // removeMarkerFromList(marker) {
    //   let markerIndex = this.changedMarkers.indexOf(marker.AddMarkerId);
    //   this.changedMarkers.splice(markerIndex, 1);
    // }

    // resetMarkCodeToOriginal(marker) {
    //   let id = marker.AddMarkerId;
    //   let newMarkerIdx = this.addMarkers.indexOf(this.addMarkers.find(obj => obj.AddMarkerId === id));
    //   let originalMarker = this.copyOfAddMarkers.find(obj => obj.AddMarkerId === id);
    //   this.addMarkers.splice(newMarkerIdx, 1, JSON.parse(JSON.stringify(originalMarker)));
    // }

    // emitVerification() {
    //   let payload = {
    //     "OwnerOrganizationId": "914b3dcb-6b72-4b69-992e-f5aba2a45ebb",
    //     "AddMarkerId": this.verifyMarker.AddMarkerId,
    //     "MarkerCompany": this.verifyMarker.MarkerCompany,
    //     "MarkerType": this.verifyMarker.MarkerAddType,
    //     "MarkerName": "DNA Sequence",
    //     "MarkerDescription": "Unique sequence of Natural or Artificial DNA in an invisible particle",
    //     "AddVerify": "Verify",
    //     "MarkerCodeName": this.verifyMarker.MarkerCodeName
    //   };
    //   switch (this.verifyMarker.MarkerAddType) {
    //     case 'SyntheticDNA': payload['SyntheticDNAVerifyData'] = {ProbabilityMarkerCodes: [{ProbabilityMarkerCode: this.probabilityScore}], MarkerCodeName :this.verifyMarker[this.verifyMarker.MarkerAddType + 'AddData'].MarkerCodeName.Measure}; break;

    //   }
    //   this.verifyMarkerSave.emit(payload);
    //   this.probabilityScore = '';
    //   this.sampleNumber = '';
    // }
}