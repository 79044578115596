import { map } from 'rxjs/operators';
import { flattenObject } from '@app/shared/utils/object-utils';
import { FormBuilder, Form, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Component, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '@app/core/services';
import { OcrService } from '@app/core/services/backend/ocr.service';
import { fromEvent, Observable } from "rxjs";
import { UploadService } from '@app/core/services/backend/uploads.service';

@Component({
  selector: 'bext-ocr-component',
  templateUrl: './ocr.component.html',
  styleUrls: ['./ocr.component.css']
})
export class OcrComponent {

  private eventsSubscription: any
  
  section;
  
  colorOptions = [
    { label: "SILVER", value: "RGBA(192, 192, 192, 1)" },
    { label: "GRAY", value: "RGBA(128, 128, 128, 1)" },
    { label: "BLACK", value: "RGBA(0, 0, 0, 1)" },
    { label: "RED", value: "RGBA(255, 0, 0, 1)" },
    { label: "ORANGE", value: "RGBA(249, 116, 0, 1)" },
    { label: "MAROON", value: "RGBA(128, 0, 0, 1)" },
    { label: "YELLOW", value: "RGBA(255, 255, 0, 1)" },
    { label: "OLIVE", value: "RGBA(128, 128, 0, 1)" },
    { label: "LIME", value: "RGBA(0, 255, 0, 1)" },
    { label: "GREEN", value: "RGBA(0, 128, 0, 1)" },
    { label: "AQUA", value: "RGBA(0, 255, 255, 1)" },
    { label: "TEAL", value: "RGBA(0, 128, 128, 1)" },
    { label: "BLUE", value: "RGBA(0, 0, 255, 1)" },
    { label: "NAVY", value: "RGBA(0, 0, 128, 1)" },
    { label: "FUCHSIA", value: "RGBA(255, 0, 255, 1)" },
    { label: "PURPLE", value: "RGBA(128, 0, 128, 1)" }
  ];

  documentTypes = [
    { value: "GCC.Measure", label: 'GCC', templateType: 'GCC' },
    { value: "FabricTest.Measure", label: 'Fabric Test', templateType: 'FabricTest' },
    { value: "GarmentTest.Measure", label: 'Garment Test', templateType: 'GarmentTest' },
    { value: "OekoTex.Measure", label: 'OekoTex', templateType: 'OekoTex' },
    { value: "CommericalInvoice.Measure", label: 'Commercial Invoice', templateType: 'CommercialInvoice' },
    { value: "BCI.Measure", label: 'BCI', templateType: 'BCI' },
    { value: "Supima.Measure", label: 'Supima', templateType: 'Supima' },
    { value: "Gots.Measure", label: 'GOTS', templateType: 'Gots' },
    { value: "BillOfLading.Measure", label: 'Bill Of Lading', templateType: 'BillOfLading' }
  ];

  imageObj;
  ocrResults;
  currentWidth = undefined;
  parentDocumentData;
  selectedObj;
  selectedColor;
  ocrUiData;
  selectedDocumentType;
  selectedInput;
  selectedInputControlName;
  selectedInputWordsControlName;
  dataMap;

  @ViewChild('ocroverlay') ocroverlay: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('colorSelect') colorSelect: any;
  @ViewChild('canvas') canvas: ElementRef;
  @Input() events: Observable<void>;
  @Input() btnType: string;
  @Output() submitOcrResults: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  constructor(private ocrService: OcrService, private fb: FormBuilder, private notifcationService: NotificationService, private uploadService: UploadService) {
  
  }

  private cx: CanvasRenderingContext2D;
  
  ngAfterViewInit() {
    this.eventsSubscription = this.events.subscribe(
      res => {
        console.log('%c OCR event Subscription and parent form: ', 'background: #41ff6b; color: #ff4700;', res);
        this.setFormData(res);
      }
    );

    fromEvent<any>(this.canvas.nativeElement, 'click')
      .pipe(event => event)
      .subscribe(e => {
        var eX = e.offsetX;
        var eY = e.offsetY;
        if (!this.selectedColor) {
          this.showNotification('please select a color', 'use drop down to see a list of color options');
          return;
        }
        if (!this.selectedInput) {
          this.showNotification('please select a input to assign values', 'click \'Label\' or \'Value\' input field', "#C46A69", 3000);
          return;
        }
        var selection = this.dataMap.filter(obj => {
          var betweenX = (obj.x <= eX && (obj.x + obj.w) >= eX);
          var betweenY = (obj.y <= eY && (obj.y + obj.h) >= eY);
          return betweenX && betweenY;
        });
        let selectedObj = (selection.length) ? selection[0] : undefined;
        if (selectedObj && !Array.isArray(selectedObj)) {
          this.selectedObj = selectedObj;
          if (selectedObj.colorCode !== this.selectedColor.value) {
            selectedObj.colorCode = this.selectedColor.value;
            selectedObj.color = this.selectedColor.label;
            this.drawLine(selectedObj);
          }
          else {
            this.redrawCanvas(selectedObj);
          }
        }
      });
  }

  setFormData(documentData) {
    if (documentData.section) {
      this.section = documentData.section;
      var templateType = this.section.SectionName;
    }
    if (documentData === 'toggle-overlay' || typeof documentData === 'string') {
      this.toggleOverlay(100, 0.5);
      let x = 0;
      let y = 0;
      this.cx.drawImage(this.imageObj, x, y);
      this.dataMap.map(obj => {
        if (obj.colorCode !== null) {
          this.cx.strokeStyle = obj.colorCode;
          this.cx.lineWidth = 2;
          this.cx.strokeRect(obj.x, obj.y, obj.w, obj.h);
        }
      });
      return;
    }
    this.parentDocumentData = documentData;
    

    // let templateType = this.documentTypes.find(doc => doc.value == documentData.level).templateType;


    if (templateType) {
      this.form = this.fb.group({
        'imageUrl': '',
        'docType': templateType,
        'ocrResults': '',
        'mappedData': this.fb.array([])
      });

      // this.ocrService.getTemplateType(templateType).subscribe(
      //   tmpl => {
      //     // console.log('template for type - ' + tmpl.templateType + ': ', tmpl);
      //     this.ocrUiData = tmpl.UiData[0];
      //   },
      //   error => console.log('%c error GET template type failed: ', 'background: #ff0000; color: #ffffff;', error),
      //   () => {

      //   }
      // );
      setTimeout(() => {
        this.fileInput.nativeElement.click();
      }, 300);
    }
    else {
      console.log('%c DOCUMENT TYPE COULD NOT BE FOUND: ', 'background: #ff0000; color: #ffffff;', '');
      return;
    }

  }

  addMappedData(uimdn, label) {
    this.mappedData.push(this.createMappedData(uimdn, label))
  }

  createMappedData(uimdn, label) {
    return this.fb.group({
      'UiMetaDataName': uimdn,
      'label': label,
      'selectedName': '',
      'selectedValue': this.parentDocumentData.parentForm.controls[uimdn] ? this.parentDocumentData.parentForm.controls[uimdn].value : '',
      'color': '',
      'colorCode': '',
      'labelWords': '',
      'valueWords': '',
    });
  }

  get imageUrlControl() {
    return this.form.get('imageUrl') as FormControl;
  }

  get ocrResultsControl() {
    return this.form.get('ocrResults') as FormControl;
  }

  get mappedData(): FormArray {
    return this.form.get('mappedData') as FormArray;
  }

  toggleOverlay(val, d = 0.0) {
    let styles = this.ocroverlay.nativeElement.style;
    let duration = d +'s'
    let background = ['255', '255', '255'];
    let backgroundOpacity = 1;
    let backgroundColor = "rgba(" + background[0] + ", " + background[1] + ", " + background[2] + ", " + backgroundOpacity + ")";
    styles.backgroundColor = backgroundColor;
    if (this.currentWidth > 25 || !this.currentWidth) {
      this.currentWidth = (!this.currentWidth) ? 100 : this.currentWidth;
      styles['transition'] = duration;
      styles['-moz-transition'] = duration;
      styles['-o-transition'] = duration;
      styles['-webkit-transition'] = duration;
      styles.width = val + "%";
      this.currentWidth = val;
    }
    else {
      styles.width = 0 + "";
      this.currentWidth = 0;
    }
  }

  onDocumentSelected(e) {
    let file = e.target.files[0];
    
    if (!file) {
      return;
    }

    let fd = new FormData();
    [<File>file].forEach(document => {
      fd.append('files', document, document.name);
    });
    
    this.uploadService.upload(fd).subscribe(
      res => {
        // console.log('image uploaded successfully: ', res);
        this.imageUrlControl.setValue(res.ImageURL);
        // this.ocrUiData.Sections.forEach(section => {
          this.section.Fields.forEach(field => {
            if (field.type !== 'fileUpload') {
              let label = field.label || field.humanName;
              this.addMappedData(field.name, label);
            }
          });
        // });
        
        console.log('%c mapped data: ', 'background: #41ff6b; color: #ff4700;', this.mappedData);
        this.toggleOverlay(100, 0.5);
        this.createCanvasFromImage();
      },
      err => {
        this.showNotification("error uploading file", "please try again", "#C46A69");
        console.log('%c failed to upload image: ', 'background: #ff0000; color: #ffffff;', err)
        return;
      }
    );
    
  }

  createCanvasFromImage() {
    this.ocrService.getImageText(this.imageUrlControl.value).subscribe(
      res => {
        this.ocrResults = res;
        this.ocrResultsControl.setValue(this.ocrResults);
      },
      error => {
        console.log('error getting image text: ', error);
        this.showNotification("error OCRing file", "please try uploading file again", "#C46A69", 3000);
        this.toggleOverlay(0);
        return;
      },
      () => {
        this.ocrResults = Object.entries(flattenObject(this.ocrResults.Regions));
        this.dataMap = this.createTextBoxData(this.ocrResults);
        // console.log('%c ocr data map: ', 'background: #41ff6b; color: #ff4700;', this.dataMap);
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        this.cx = canvasEl.getContext('2d');
        var imageObj = new Image();
        imageObj.src = this.imageUrlControl.value;
        imageObj.onload = e => {
          var imgHt = e['path'][0]['height'];
          var imgWt = e['path'][0]['width'];
          var cw = document.getElementById('canvasWrapper');
          // console.log('image height: ', imgHt);
          // console.log('image width: ', imgWt);
          cw.style.height = imgHt + 'px';
          cw.style.width = imgWt + 'px';
          // console.log('canvas wrapper: ', cw);
          canvasEl.width = imgHt * 1.5;
          canvasEl.height = imgWt * 1.5;
          let x = 0;
          let y = 0;
          this.cx.drawImage(imageObj, x, y);
        };
        this.imageObj = imageObj;
      }
      );
  }

  onFocusInput(input, controlName, wordsControlName) {
    this.selectedInput = input;
    this.selectedInputControlName = controlName;
    this.selectedInputWordsControlName = wordsControlName;
  }

  drawLine(obj) {
    var inputValue = this.selectedInput.get(this.selectedInputWordsControlName);
    if (inputValue === null) {
      this.showNotification('please select a input to assign values', 'click \'Text\' or \'Value\' input field', "#C46A69", 3000);
      return;
    }
    var wordsArray = (!Array.isArray(inputValue.value)) ? [] : inputValue.value;
    wordsArray.push(obj);
    this.selectedInput.get(this.selectedInputWordsControlName).setValue(wordsArray);
    let wordString = '';
    wordsArray.forEach(obj => {
      wordString += (!wordString) ? obj.data : (' ' + obj.data);
    });
    this.selectedInput.get(this.selectedInputControlName).setValue(wordString);
    this.cx.strokeStyle = obj.colorCode;
    this.cx.lineWidth = 2;
    this.cx.strokeRect(obj.x, obj.y, obj.w, obj.h);
  }

  redrawCanvas(objectToRemove) {
    var inputValue = this.selectedInput.get(this.selectedInputWordsControlName);
    console.log('%c redraw canvas -> inputValue: ', 'background: #fae552; color: #323232;', inputValue);
    console.log('%c object to remove: ', 'background: #fae552; color: #323232;', objectToRemove);
    if (inputValue === null) {
      this.showNotification('please select a input to assign values', 'click \'Text\' or \'Value\' input field', "#C46A69", 3000);
      return;
    }
    let newInputValue = inputValue.value.filter(obj => objectToRemove != obj);
    this.selectedInput.get(this.selectedInputWordsControlName).setValue(newInputValue);
    let wordString = '';
    newInputValue.forEach(obj => {
      wordString += (!wordString) ? obj.data : (' ' + obj.data);
    });
    this.selectedInput.get(this.selectedInputControlName).setValue(wordString);
    objectToRemove.colorCode = null;
    objectToRemove.color = null;
    console.log('newInputValue: ', newInputValue);
    let x = 0;
    let y = 0;
    this.cx.drawImage(this.imageObj, x, y);
    this.dataMap.map(obj => {
      if (obj.colorCode !== null) {
        this.cx.strokeStyle = obj.colorCode;
        this.cx.lineWidth = 2;
        this.cx.strokeRect(obj.x, obj.y, obj.w, obj.h);
      }
    });
  }

  setColor(e) {
    let key = e.target.value;
    let color = this.colorOptions.find(option => key === option.label);
    this.selectedColor = color;
    console.log('selected color: ', this.selectedColor);
  }

  selectItemToEdit(item) {
    this.selectedInput = item;
    let inputColor = this.selectedInput.value.color; 
    if (inputColor) {
      let color = this.colorOptions.find(color => inputColor === color.label);
      this.selectedColor = color;
    }
    return;
  }

  saveItem(item) {
    this.selectedInput = undefined;
    this.selectedColor = undefined;
  }

  cancelEdit(item) {
    this.selectedInput = undefined;
    this.selectedInputControlName = undefined;
    this.selectedInputWordsControlName = undefined;
  }

  createTextBoxData(arr) {
    let output = [];
    let textIndex = 0;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      let path = element[0].split('.');
      let isText = (path[path.length - 1] === 'Text') ? true : false;
      if (isText) {
        let coords = arr[i - 1][1].split(',').map(n => parseFloat(n));
        let o = {}
        o = {
          coords: arr[i - 1][1],
          x: coords[0],
          y: coords[1],
          w: coords[2],
          h: coords[3],
          index: textIndex,
          data: element[1],
          colorCode: null,
          color: null
        };
        output.push(o);
        textIndex++;
      }
    }
    return output;
  }
  
  onClose(fileInput) {
    let level = this.parentDocumentData.level;
    let parentForm = this.parentDocumentData.parentForm;
    let ocrForm = this.form.value;
    console.log('ocr form: ', ocrForm);
    if (parentForm.controls[level]) {
      parentForm.controls[level].setValue(ocrForm.imageUrl);
    }

    for (let i = 0; i < ocrForm.mappedData.length; i++) {
      const field = ocrForm.mappedData[i];
      let parentControl = parentForm.controls[field.UiMetaDataName];
      if (parentControl) {
        parentControl.setValue(field.selectedValue);
      }
    }
    fileInput.value = null;
    this.selectedInput = undefined;
    this.selectedColor = undefined;
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    this.cx.clearRect(0, 0, canvasEl.width, canvasEl.height)
    this.toggleOverlay(0, 0.5);
  }

  showNotification(title, content, color?, duration?, icon?) {
    return this.notifcationService.smallBox({
      title: title,
      content: "<i>" + content + "</i>",
      color: color || "#ffc107",
      iconSmall: "fa " + (icon || "fa-warning") + " bounce animated",
      timeout: duration || 2500
    });
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

}
