import { GALLERY_CONF } from "@app/shared-v2/photo-gallery/models/photo-gallery.conf";
import { Document } from "./../models/archwizard/form-documents.interface";
import { environment } from "@env/environment";
import { Option } from "@app/shared-v2/models/archwizard/form-attribute.class";
import { Attribute } from "../models/archwizard/form-attribute.class";
import { DomSanitizer } from "@angular/platform-browser";
import { Component, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, ViewChild, ElementRef, Directive, Renderer2, ViewEncapsulation } from "@angular/core";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { FormStep } from "../models/archwizard/form-step.interface";
import { WizardComponent, MovingDirection } from "angular-archwizard";
import { ApiFakerService } from "@app/core-v2/services";
import { take, map, startWith } from "rxjs/operators";
import { Observable, fromEvent, noop } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent, MatCheckboxChange, MatDialog, MatSelectChange } from "@angular/material";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { isNullOrUndefined } from "util";
import { DocumentsDialogComponent } from "../dialogs/documents-dialog/documents-dialog.component";
import { HttpClient } from "@angular/common/http";
import { PhotoGalleryComponent } from "../photo-gallery/photo-gallery.component";
import { ConnectionServiceModule } from "ng-connection-service";
import { Console } from "console";

interface IStepChangeObject {
  currentStep: FormStep | null;
  previousStep: FormStep | null;
}

@Component({
  selector: "bext-archwizard",
  templateUrl: "./archwizard.component.html",
  styleUrls: ["./archwizard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BextArchwizardComponent implements AfterViewInit {
  BASE_URL = environment.baseApiUrl;

  @ViewChild(WizardComponent)
  public wizard: WizardComponent;

  @ViewChild("fileUpload")
  fileUpload: ElementRef;

  @ViewChild("chipInput")
  chipInput: ElementRef<HTMLInputElement>;

  @ViewChild("auto")
  matAutocomplete: MatAutocomplete;

  @ViewChild(PhotoGalleryComponent) photoGallery: PhotoGalleryComponent;

  conf: GALLERY_CONF = {
    imageOffset: "0px",
    showDeleteControl: false,
    showImageTitle: false,
    reactToMouseWheel: false,
  };

  separatorKeysCodes: number[] = [ENTER, COMMA];

  success = false;
  showData = false;
  files: any[] = [];
  hideWizardNaivationButtons = false;
  currentStepIndex = 0;
  currentStepButtons: any[] = [];
  changeStepObject: IStepChangeObject = {
    previousStep: null,
    currentStep: null,
  };

  private _masterForm: any;
  @Input()
  set masterForm(value) {
    this.wizardModel = value.Form.FormSteps;
    console.log("WZ: ", this.wizardModel);

    this._masterForm = value;
    console.log("_masterForm: ", this._masterForm);
    this.currentStepIndex = this._masterForm.StepToDisplayIndex;
    console.log("this.currentStepIndex: ", this.currentStepIndex);
  }
  get masterForm() {
    return this._masterForm;
  }

  private _wizardModel: FormStep[];
  @Input()
  set wizardModel(value: FormStep[]) {
    this._wizardModel = value;
  }
  get wizardModel(): FormStep[] {
    return this._wizardModel;
  }

  private _navBarLocation = "left";
  @Input()
  set navBarLocation(location: any) {
    this._navBarLocation = location;
  }
  get navBarLocation() {
    return this._navBarLocation;
  }

  private _navBarLayout = "large-empty-symbols";
  @Input()
  set navBarLayout(layout: any) {
    this._navBarLayout = layout;
  }
  get navBarLayout() {
    return this._navBarLayout;
  }

  private _initializeWithCompletedSteps: boolean = false;
  @Input()
  set initializeWithCompletedSteps(bool) {
    this._initializeWithCompletedSteps = bool;
  }
  get initializeWithCompletedSteps() {
    return this._initializeWithCompletedSteps;
  }

  private _showModelToggle: boolean = false;
  @Input()
  set showModelToggle(value: any) {
    this._showModelToggle = value;
  }
  get showModelToggle() {
    return this._showModelToggle;
  }

  private _selectedStepIndex = 1;
  @Input()
  set selectedStepIndex(value: any) {
    this._selectedStepIndex = value;
  }
  get selectedStepIndex() {
    return this._selectedStepIndex;
  }

  private _hideCompleteWizardButton: boolean = false;
  @Input()
  set hideCompleteWizardButton(bool: boolean) {
    this._hideCompleteWizardButton = bool;
  }
  get hideCompleteWizardButton() {
    return this._hideCompleteWizardButton;
  }

  private _formStatus = { submitted: false, valid: false };
  set formStatus(statusObj: { submitted: boolean; valid: boolean }) {
    this._formStatus = statusObj;
  }
  get formStatus() {
    return this._formStatus;
  }

  private _photoGalleryImages = [];
  set photoGalleryImages(imgs) {
    this._photoGalleryImages = imgs;
  }
  get photoGalleryImages() {
    return this._photoGalleryImages;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((result) => result.matches));

  public validateStepUponExit: (MovingDirection) => boolean = (direction) => {
    console.log("In Validate");
    let currentStep: FormStep = this.wizardModel[this.selectedStepIndex];
    let currentStepClone: FormStep = JSON.parse(JSON.stringify(this.wizardModel[this.selectedStepIndex]));
    let currentStepCloneFormattedValues: FormStep[] = this._forceValuesToListOfOptions([currentStepClone]);
    console.log("%c on exit STEP CLONE with formatted values : ", "background: #41ff6b; color: #ff4700;", currentStepCloneFormattedValues);
    let valid = this.stepValidation([...currentStepCloneFormattedValues[0].Attributes]);
    console.log("%c BEFORE EXIT VALIDATE STEP: ", "background: #41ff6b; color: #ff4700;");
    console.log("%c valid step: ", "background: #fae552; color: #323232;", valid);
    switch (direction) {
      case MovingDirection.Forwards:
        if (valid) {
          currentStep.StepComplete = true;
          currentStep.StepTouched = true;
        }
        return valid;

      case MovingDirection.Backwards:
        if (!currentStep.StepComplete) {
          currentStep.StepPending = true;
        }
        return true;

      case MovingDirection.Stay:
        return true;
    }
  };

  @Output("submitWizard") submitWizard = new EventEmitter<any>();
  @Output("onWizardCrudAction") onWizardCrudAction = new EventEmitter<any>();
  @Output("onQueryNextSteps") onQueryNextSteps = new EventEmitter<any>();
  @Output("onStepChange") onStepChange = new EventEmitter<any>();

  constructor(
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private apiFakerService: ApiFakerService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.setDefaultValues();
    console.log("ngOnInit");
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
    console.log("ngAfterViewInit");
  }

  enterStep(event, step: FormStep) {
    console.log("%c Enter Step: ", "background: #41ff6b; color: #ff4700;", step);

    this.hideWizardNaivationButtons = this.checkIfStepContainsButton(step.Attributes);
    this.selectedStepIndex = step.StepIndex;
    step.StepPending = true;
    this.changeStepObject.currentStep = step;
    this.onStepChange.emit(this.changeStepObject);
  }

  exitStep(event, step: FormStep) {
    console.log("In Exit Step step:", step);
    this.changeStepObject.previousStep = step;
    step.StepPending = false;
  }

  getAttributes(stepIndex?: any) {
    if (!stepIndex) return this.wizardModel.reduce((prev, curr) => [...prev, ...curr.Attributes], []);
    else {
      return this.wizardModel.reduce((prev, curr) => [...prev, ...curr.Attributes], [])[stepIndex];
    }
  }

  setDefaultValues(): void {
    this.wizardModel.forEach((s, i) => {
      s.Attributes.forEach((a) => {
        if (a.Type === "select" && a.MultiSelect === false && a.hasOwnProperty("Value") && a.Value !== null) {
          a.Value = {
            Value: a.Value[0].Value,
            Label: a.Value[0].Label,
            IsSelected: false,
          };
        } else if (a.Type === "radio" && a.hasOwnProperty("Options") && a.Options !== null && a.Options.length) {
          let radioChecked = a.Options.find((o) => o.Value === a.Value[0].Value);
          a.Value = radioChecked ? radioChecked : { Value: "", Label: "", IsSelected: false };
        } else if (!a.hasOwnProperty("Value") || a.Value === null || !a.Value.length) {
          if (a.Type === "checkbox") a.Value = [];
          else a.Value = [{ Label: "", Value: "" }];
        }

        // TEST APPROVAL STEPS
        if (a.Type === "text") {
          a.ApprovalProcess = APPROVAL_PROCESS;
          console.log("%c attribute: ", "background: #fae552; color: #323232;", a);
        }
      });
    });
  }

  checkIfStepContainsButton(Attributes: Attribute[]) {
    let hasButton = (f: Attribute) => f.Type === "button";
    this.currentStepButtons = Attributes.filter(hasButton);
    return Attributes.some(hasButton);
  }

  compareOptionObjectsByValue(o1: Option, o2: Option) {
    return o1 && o2 && o1.Value === o2.Value;
  }

  toggleIndeterminateCheckBox(item) {}

  openImageGallery(docs: Document[]) {
    this.photoGalleryImages = docs
      .filter((d) => d.Images.length && d.Images !== null)
      .reduce((p, c) => [...p, ...c.Images], [])
      .map((img) => ({
        url: img.ImageURL,
        altText: img.ImageName,
        title: img.ImageName,
        thumbnailUrl: img.ImageURL,
      }));
    console.log("%c Images for gallery: ", "background: #41ff6b; color: #ff4700;", this.photoGalleryImages);
    this.openGallery();
  }

  openGallery(index: number = 0) {
    this.photoGallery.open(index);
  }

  // close gallery
  closeGallery() {
    this.photoGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.photoGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.photoGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.photoGallery.prev();
  }

  openDocumentDialog(entityType?: string, entity_id?: string, field?: Attribute) {
    let documentDialogRef = this.dialog.open(DocumentsDialogComponent, {
      data: {
        entityType: entityType,
        id: entity_id,
        dialogHeaderContent: {
          entityType: entityType,
          entityName: "Upload documents to form",
          dialogType: "Documents",
        },
        isSimpleFileUpload: field ? (field.Type ? field.Type === "filesimpleupload" : false) : false,
      },
      autoFocus: false,
      minWidth: "400px",
      disableClose: true,
    });

    documentDialogRef.componentInstance.documentListChange.subscribe(
      (res) => {
        console.log("%c document list change event: ", "background: #41ff6b; color: #ff4700;", res);
        field.Documents = res;
        if (field.Type == "filesimpleupload") {
          field.Value[0].Label = "uploadedDocument";
          if (field.Documents.length == 1) {
            field.Value[0].Value = field.Documents[0].Images[0].ImageName;
          }
        }
        this.photoGalleryImages = field.Documents.filter((d) => d.Images.length && d.Images !== null)
          .reduce((p, c) => [...p, ...c.Images], [])
          .map((img) => ({
            url: img.ImageURL,
            altText: img.ImageName,
            title: img.ImageName,
            thumbnailUrl: img.ImageURL,
          }));
        console.log("%c field documents after update event: ", "background: #41ff6b; color: #ff4700;", field);
      },
      (error) => console.log("%c document list change event error: ", "background: #ff0000; color: #ffffff;", error),
      () => console.log("%c document list change subscription complete: ", "background: #41ff6b; color: #ff4700;")
    );

    documentDialogRef.afterClosed().subscribe((res) => console.log("%c document dialog closed: ", "background: #41ff6b; color: #ff4700;", res));
  }

  onFileSelected(event, ngModel) {
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      console.log("file: ", file);
      if (this.validate(file)) {
        file.objectURL = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(files[i]));
        if (!ngModel.acceptsMultipleFiles) {
          this.files = [];
        }
        this.files.push({
          name: files[i].name,
          url: file.objectURL.changingThisBreaksApplicationSecurity,
          size: file.size,
          type: file.type,
        });
      }
    }
    ngModel["values"] = this.files;
  }

  removeFile(event, file, ngModel) {
    let ix;
    if (ngModel["values"] && -1 !== (ix = ngModel["values"].indexOf(file))) {
      ngModel["values"].splice(ix, 1);
      this.clearInputElement();
    }
  }

  validate(file: File) {
    for (const f of this.files) {
      if (f.name === file.name && f.lastModified === file.lastModified && f.size === f.size && f.type === f.type) {
        return false;
      }
    }
    return true;
  }

  clearInputElement() {
    this.fileUpload.nativeElement.value = "";
  }

  queryAndAppendNextStep(item, step?: FormStep) {
    console.log("%c queryAndAppendNextStep: ", "background: #41ff6b; color: #ff4700;", step);
    let configuredEndpoint = `/${item.Action.Controller}/${item.Action.EndPoint}`;
    let queryParams = "";
    if (step.StepId && item.Action.hasOwnProperty("Parameters")) {
      item.Action.Parameters.unshift({ Name: "StepId", Value: step.StepId });
      console.log("%c parameters with step Id: ", "background: #41ff6b; color: #ff4700;", item.Action.Parameters);
    }
    if (item.Action && typeof item.Action === "object" && item.Action.hasOwnProperty("Parameters")) {
      queryParams = item.Action.Parameters.reduce((p, c, i) => (p += (i === 0 ? `?` : `&`) + `${c.Name}=${c.Value}`), "");
      configuredEndpoint += queryParams;
    }
    console.log("%c method and endpoint: ", "background: #fae552; color: #323232;", item.Action.Method, configuredEndpoint);
    let formValid = this.submit();
    console.log("%c form valid: ", "background: #ff00ff; color: #ffffff;", formValid);
    console.log("%c Form Object: ", "background: #ff00ff; color: #ffffff;", this.masterForm);
    if (formValid) {
      this._processQuery(item.Action.Method, configuredEndpoint, this.masterForm);
    } else {
    }
  }

  queryAndSetNewForm(item, step?: FormStep) {
    let configuredEndpoint = `/${item.Action.Controller}/${item.Action.EndPoint}`;
    let queryParams = "";
    if (step.StepId && item.Action.hasOwnProperty("Parameters")) {
      item.Action.Parameters.unshift({ Name: "StepId", Value: step.StepId });
      // console.log('%c parameters with step Id: ', 'background: #41ff6b; color: #ff4700;', item.Action.Parameters);
    }
    if (item.Action && typeof item.Action === "object" && item.Action.hasOwnProperty("Parameters")) {
      queryParams = item.Action.Parameters.reduce((p, c, i) => (p += (i === 0 ? `?` : `&`) + `${c.Name}=${c.Value}`), "");
      configuredEndpoint += queryParams;
    }
    // console.log('%c method and endpoint: ', 'background: #fae552; color: #323232;', item.Action.Method, configuredEndpoint);
    let formValid = this.submit();
    // console.log('%c form valid: ', 'background: #ff00ff; color: #ffffff;', formValid);
    // console.log('%c Form Object: ', 'background: #ff00ff; color: #ffffff;', this.masterForm);
    if (formValid) {
      step.StepComplete = true;
      step.StepTouched = true;
      step.StepPending = false;
      this._processQuery(item.Action.Method, configuredEndpoint, this.masterForm);
    } else {
    }
  }

  drop(event: CdkDragDrop<string[]>, item) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

      item.Value = event.container.data;
    }
  }

  addChip(event: MatChipInputEvent, item: Attribute): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event

    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || "").trim()) {
        let chip = item.Options.find((c) => c.Value.indexOf(value) >= 0);
        console.log("%c ADD CHIP - chip found: ", "background: #fae552; color: #323232;", chip);
        if (chip) {
          item.Value = Array.isArray(item.Value) ? [...item.Value] : [];
          item.Value.push(chip);
        }
      }

      // Reset the input value
      if (input) {
        input.value = "";
      }
    }
  }

  removeChip(chip: Option, chips): void {
    const index = chips.findIndex((c) => c.Value === chip.Value);
    if (index >= 0) {
      chips.splice(index, 1);
      console.log("%c item value after remove: ", "background: #fae552; color: #323232;", chips);
    }
  }

  selectedChip(event: MatAutocompleteSelectedEvent, item): void {
    console.log("%c SELECTED CHIP event: ", "background: #fae552; color: #323232;", event);
    item.Value = Array.isArray(item.Value) ? [...item.Value] : [];
    item.Value.push(event.option.value);
    console.log("%c item value after push: ", "background: #fae552; color: #323232;", item.Value);
    this.chipInput.nativeElement.value = "";
  }

  stepValidation(steps: Attribute[]): boolean {
    console.log("In stepValidation");
    let valid = true;
    if (!steps.length) return true;
    steps.reverse().forEach((field) => {
      console.log("LABEL: " + field.Label + "\n REQUIRED: " + field.Required + "\n VALUE: " + JSON.stringify(field.Value));
      if (field.Required && !field.Value[0].Value && field.Type != "checkbox") {
        console.log("Error", "Please enter " + field.Label, "error");
        valid = false;
        return false;
      }
      if (field.Required && field.Regex) {
        let regex = new RegExp(field.Regex);
        if (regex.test(field.Value[0].Value) == false) {
          // Swal('Error', field.ErrorText, 'error');
          console.log("Error", field.ErrorText, "error");
          valid = false;
          return false;
        }
      }
      if (field.Required && field.Type == "checkbox") {
        if (field.Options.filter((r) => r["IsSelected"]).length == 0) {
          // Swal('Error', 'Please enterrr ' + field.Label, 'error');
          console.log("Error", "Please enterr " + field.Label, "error");
          valid = false;
          return false;
        }
      }
      if (field.Required && field.Type == "select") {
        if (field.Value.filter((r) => r.Value).length == 0) {
          // Swal('Error', 'Please enterrr ' + field.Label, 'error');
          console.log("Error", "Please enterr " + field.Label, "error");
          valid = false;
          return false;
        }
      }
    });
    this.formStatus = { submitted: true, valid: valid };
    if (!valid) {
      return false;
    } else {
      return true;
    }
  }

  submit() {
    let valid = true;
    this._forceValuesToListOfOptions(this.wizardModel);
    let combinedWizardSteps = this.getAttributes();
    let validationArray = JSON.parse(JSON.stringify(combinedWizardSteps));
    validationArray.reverse().forEach((field) => {
      console.log("LABEL: " + field.Label + "\n REQUIRED: " + field.Required + "\n VALUE: " + JSON.stringify(field.Value));
      if (field.Required && !field.Value && field.Type != "checkbox") {
        // Swal('Error', 'Please enter ' + field.Label, 'error');
        console.log("Error", "Please enter " + field.Label, "error");
        valid = false;
        return false;
      }
      if (field.Required && field.Regex) {
        let regex = new RegExp(field.Regex);
        if (regex.test(field.Value) == false) {
          // Swal('Error', field.ErrorText, 'error');
          console.log("Error", field.ErrorText, "error");
          valid = false;
          return false;
        }
      }
      if (field.Required && field.Type == "checkbox") {
        if (field.Options.filter((r) => r.selected).length == 0) {
          // Swal('Error', 'Please enterrr ' + field.Label, 'error');
          console.log("Error", "Please enterrr " + field.Label, "error");
          valid = false;
          return false;
        }
      }
    });
    this.formStatus = { submitted: true, valid: valid };
    if (!valid) {
      return false;
    } else if (valid) {
      return true;
    }
  }

  downloadFileAndClose(item: any) {
    console.log("%c item: ", "background: #41ff6b; color: #ff4700;", item);
    let file_path = item.Action.Parameters.find((p) => p.Name === "FilePath").Value;
    let file_name = item.Action.Parameters.find((p) => p.Name === "FileName").Value;
    fetch(file_path)
      .then((res) => res.blob())
      .then((blob) => {
        console.log("%c blob: ", "background: #41ff6b; color: #ff4700;", blob);
        if (window.navigator.msSaveOrOpenBlob) {
          return window.navigator.msSaveOrOpenBlob(blob, file_name);
        } else {
          const a = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = file_name;
          a.click();
          return setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 150);
        }
      });

    this.onWizardCrudAction.emit();
  }

  emitForm(item: any) {
    let steps: Attribute[] = this.wizardModel.reduce((prev, curr) => [...prev, ...curr.Attributes], []);
    this.wizardModel = this._forceValuesToListOfOptions(this.wizardModel);
    let validForm = this.stepValidation(steps);
    console.log("%c item to emit: ", "background: #41ff6b; color: #ff4700;", item);
    console.log("%c emit form - valid: ", "background: #41ff6b; color: #ff4700;", validForm);
    if (!item || item === "CancelAndClose") {
      return this.onWizardCrudAction.emit();
    } else if (validForm) {
      let configuredEndpoint = "";
      let queryParams = "";
      if (item.Action && typeof item.Action === "object" && item.Action.hasOwnProperty("Parameters")) {
        queryParams = item.Action.Parameters.reduce((p, c, i) => (p += (i === 0 ? `?` : `&`) + `${c.Name}=${c.Value}`), "");
        configuredEndpoint = `${item.Action.EndPoint}${queryParams}`;
      } else {
        configuredEndpoint = `${item.Action.EndPoint}`;
      }
      this.onWizardCrudAction.emit({
        method: item.Action.Method,
        endpoint: `/${item.Action.Controller}/${configuredEndpoint}`, // add ${queryParams} eventurally
        value: this.wizardModel,
        uiAction: item.Action.Response.UiAction,
      });
    }
  }

  receiveComment(e, item) {
    console.log("%c COMMENTS FOR FIELD: ", "background: #fae552; color: #323232;", e, item);
    item.ApprovalProcess.ApprovalComments = e;
  }

  recieveCount(e, item) {
    console.log("%c receieve count: ", "background: #fae552; color: #323232;", e);
  }

  fieldApprovalCheckboxChange(e: MatCheckboxChange, item) {
    console.log("%c approval checkbox change: ", "background: #fae552; color: #323232;", e, item);
    item.ApprovalProcess.ApprovalCheckbox = e.checked;
  }

  fieldApprovalStatusChange(e: MatSelectChange, item) {
    console.log("%c approval status change: ", "background: #fae552; color: #323232;", e, item);
  }

  _forceValuesToListOfOptions(steps: FormStep[]): FormStep[] {
    let toValueArray = (field: Attribute) => {
      let temp: Option | any = { Label: field.Name, Value: "" };
      if (field.Type === "button") {
        return field;
      }

      if (isNullOrUndefined(field.Value) && field.Type !== "checkbox") {
        field.Value = [temp];
        return field;
      } else if (field.Type === "checkbox") {
        field.Value = field.Options.map((f) => ({
          Label: field.Name,
          Value: f.Value,
          IsSelected: f.IsSelected,
        }));
        return field;
      } else if (Array.isArray(field.Value) && field.Value.length) {
        field.Value = field.Value.map((f) => ({
          Label: field.Name,
          Value: f.Value,
        }));
        return field;
      } else if (typeof field.Value === "string" || typeof field.Value === "number") {
        temp.Value = field.Value;
        field.Value = [temp];
        return field;
      } else if (typeof field.Value === "object") {
        temp.Value = field.Value["Value"];
        field.Value = [temp];
        return field;
      } else {
        console.log("%c CASE SLIPPED THROUGH IF LOGIC: ", "background: #ff0000; color: #ffffff;", field);
      }
    };

    let updatedSteps = steps.map((s) => {
      s.Attributes = s.Attributes.map(toValueArray);
      return s;
    });

    console.log("%c udpatedSteps: ", "background: #fae552; color: #323232;", updatedSteps);
    return updatedSteps;
  }

  _processQuery(method, endpoint, payload?: any) {
    // console.log("PROCESS QUERY: ", method, endpoint);
    // console.log(
    //   "%c current step object: ",
    //   "background: #fae552; color: #323232;",
    //   this.changeStepObject
    // );
    // console.log(
    //   "%c payload number of steps: ",
    //   "background: #fae552; color: #323232;",
    //   payload.Form.FormSteps.length
    // );
    let request$: Observable<any>;
    switch (method) {
      case "POST":
        this.http
          .post(this.BASE_URL + endpoint, payload)
          .pipe(take(1))
          .subscribe(
            (res: any) => {
              // console.log(
              //   "%c " + method + " RETURN NEW FORM with StepToDisplayIndex",
              //   "background: #ff00ff; color: #ffffff;",
              //   res
              // );
              this.currentStepIndex = payload.Form.FormSteps.length;
              res.StepToDisplayIndex = payload.Form.FormSteps.length;
              console.log("%c currentStepIndex", "background: #ff00ff; color: #ffffff;", this.currentStepIndex);
              console.log("%c " + method + " RETURN NEW FORM with StepToDisplayIndex", "background: #ff00ff; color: #ffffff;", res);
              this.onQueryNextSteps.emit(res);
            },
            (error) => {
              console.log("%c " + method + " query error ", "background: #ff00ff; color: #ffffff;", error);
            }
          );
        break;

      case "PUT":
        this.http
          .put(this.BASE_URL + endpoint, payload)
          .pipe(take(1))
          .subscribe(
            (res: any) => {
              console.log("%c " + method + " query success ", "background: #ff00ff; color: #ffffff;", res);
            },
            (error) => {
              console.log("%c " + method + " query error ", "background: #ff00ff; color: #ffffff;", error);
            }
          );
        break;

      case "GET":
        this.http
          .get(this.BASE_URL + endpoint)
          .pipe(take(1))
          .subscribe(
            (res: any) => {
              console.log("%c " + method + " query success ", "background: #ff00ff; color: #ffffff;", res);
            },
            (error) => {
              console.log("%c " + method + " query error ", "background: #ff00ff; color: #ffffff;", error);
            }
          );
        break;

      default:
        break;
    }

    request$;
  }
}

const APPROVAL_PROCESS = {
  ApprovalStatus: null,
  ApprovalVisible: false,
  ApprovalComments: [
    {
      CommentId: 0,
      CurrentDate: "2020-05-27T08:50:24.074Z",
      CommentTxt: "qdwqdqw",
      ReplyComment: [
        {
          CurrentDate: "2020-05-27T08:52:16.370Z",
          CommentTxt: "wqdqwdqwd",
        },
        {
          CurrentDate: "2020-05-27T08:52:20.239Z",
          CommentTxt: "wqdqwd",
        },
      ],
    },
    {
      CommentId: 1,
      CurrentDate: "2020-05-27T08:52:12.856Z",
      CommentTxt: "qqwdwqdqdwq",
      ReplyComment: [],
    },
  ],
  ApprovalCheckbox: true,
  ApprovalStatusReasonList: [
    {
      Label: "Approved",
      Value: "Approved",
    },
    {
      Label: "Pending",
      Value: "Pending",
    },
    {
      Label: "Rejected",
      Value: "Rejected",
    },
  ],
  ApprovalStatusReason: {
    Label: "Pending",
    Value: "Pending",
  },
};
