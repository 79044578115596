import { environment } from "@env/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { PersonsRoutes } from "../../api-routes.enum";
@Injectable()
export class PersonsService {
  BASE_URL = environment.baseApiUrl;
  PERSONS_URL = "/persons";

  constructor(private http: HttpClient) {}

  getPersons() {
    return this.http.get(this.BASE_URL + PersonsRoutes.BASE_URL);
  }

  // getPersonsV2() {
  //   return this.http.get(
  //     this.BASE_URL + PersonsRoutes.BASE_URL + PersonsRoutes.PERSONLISTREPORT
  //   );
  // }

  addPerson(payload) {
    return this.http.post(this.BASE_URL + PersonsRoutes.BASE_URL, payload);
  }

  deletePerson(id) {
    return this.http.delete(this.BASE_URL + PersonsRoutes.BASE_URL + "/" + id);
  }

  editPerson(id, payload) {
    return this.http.put(
      this.BASE_URL + PersonsRoutes.BASE_URL + "/" + id,
      payload
    );
  }

  getPersonByFarmerId(id) {
    return this.http.get(
      this.BASE_URL + PersonsRoutes.BASE_URL + PersonsRoutes.FARMER_URL + id
    );
  }

  getPersonByCollectorId(id) {
    return this.http.get(
      this.BASE_URL + PersonsRoutes.BASE_URL + PersonsRoutes.COLLECTOR_URL + id
    );
  }

  getPersonsByTypeAndCoordinates(type, lat, lon, range) {
    return this.http.get(
      this.BASE_URL +
        PersonsRoutes.BASE_URL +
        "/Location/" +
        type +
        "/" +
        lat +
        "/" +
        lon +
        "/" +
        range
    );
  }
}
