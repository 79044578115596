import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class EchartEventsService {

  BASE_URL = environment.baseApiUrl;

  private echartSubject = new BehaviorSubject<any>(null)


  constructor(private http: HttpClient) {

  }

  get echartInstance() {
    return this.echartSubject
  }
  
  set echartInstance(echart) {
    this.echartSubject.next(echart);
  }

  get echartInstanceChange() {
    return this.echartInstance.asObservable();
  }

  EchartActions = (type) => {
    let ACTIONS = {
      chartInit: (evt) => ({event: evt, type: type}),
      chartClick: (evt) => ({event: evt, type: type}),
      chartDblClick: (evt) => ({event: evt, type: type}),
      chartMouseDown: (evt) => ({event: evt, type: type}),
      chartMouseUp: (evt) => ({event: evt, type: type}),
      chartMouseOver: (evt) => ({event: evt, type: type}),
      chartMouseOut: (evt) => ({event: evt, type: type}),
      chartGlobalOut: (evt) => ({event: evt, type: type}),
      chartContextMenu: (evt) => ({event: evt, type: type}),
      chartDataZoom: (evt) => ({event: evt, type: type})
    }
    return (event): any => ACTIONS[type](event)
  }

  processEvent(type: string, $event: any) {
    switch (type) {
      case 'chartInit':
        let action = this.EchartActions(type)($event);
        this.dispatch(action, this._CHART_INIT);
        break;
    
      default:
        break;
    }
    // this.echartInstance = init;
  }

  dispatch(action, cb) {
    let cbParams = [action.event, action.type, this.echartInstance]
    return cb(...cbParams);
  }

  _CHART_INIT(event, eventType, echartInstance: BehaviorSubject<any>) {
    let eci = echartInstance
    console.group('dispatch action - CHARTINIT:');
    console.log('%c event ', 'background: #41ff6b; color: #ff4700;', event);
    console.log('%c echart instance: ', 'background: #41ff6b; color: #ff4700;', eci);
    console.log('%c echart values: ', 'background: #41ff6b; color: #ff4700;', eci.getValue());
    console.groupEnd();
  }

}