export class Feedback {
  public description: string;
  public screenshot: string;
  public priority: string;
  public assignedTo: Assignee[];
  public emailTo: string[];
  public status: string;
  public pageUrl: string;
}

export interface Assignee {
  firstName: string;
  lastName: string;
  name: string;
  emailAddress: string;
}