import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from "@app/core/services/backend/uploads.service";
import { LotService } from "@app/core/services/backend/lot.service";
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'sa-lot-images',
  templateUrl: './lot-images.component.html',
  styleUrls: ['./lot-images.component.css']
})
export class LotImagesComponent implements OnInit {
  private alive = true;
  @Input() public lot: any;
  @ViewChild('fileInputNode') fileInputNode: ElementRef;
  public images;
  public currentLot: any;
  public loadingMessage = '';
  public file: File = null;
  public currentImage;
  public showImageModal = false;
  public loading = false;
  constructor(
    public dialogRef: MatDialogRef<LotImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private uploadService: UploadService,
    private lotService: LotService) { }

  ngOnInit() {
    this.loading = true;
    if (this.data && this.data.lot) {
      this.lotService.getLot(this.data.lot.id)
        .pipe(
          takeWhile(() => this.alive)
        )
        .subscribe(res => {
          console.groupCollapsed('GET Lot with images');
          console.log('%c lot: ', 'background: #41ff6b; color: #ff4700;', res);
          console.groupEnd();
          this.images = res && res.Images ? res.Images : [];
          this.loading = false;
        }
      );
      console.log(this.data.lot)
    } else if (this.lot && this.lot.Images && this.lot.Images.length) {
      this.images = this.lot.Images || [];
      this.loading = false;
    }
  }

  openImageModal(e) {

  }

  onPdfClosed() {

  }

  openPdf(event, componentType) {

  }

  addLotImage() {

  }

  onFileSelected(event, level) {
    let file = event.target.files[0];
    let id;

    if (!file) {
      return;
    }

    this.spinner.show();
    this.loadingMessage = 'Uploading image';

    this.file = <File>event.target.files[0];
    const fd = new FormData();
    fd.append('files', this.file, this.file.name);

    console.log('file: ', file);
    id = this.data.lot.id;
    this.loading = true;
    this.uploadService.uploadLotImage(id, fd).subscribe(res => {
      if (res === null || !res) {
        console.log('%c image POST successful but response body was: ', 'background: #fae552; color: #323232;', res);
        this.spinner.hide();
        this.loading = false;
        this.loadingMessage = '';
        this.file = null;
      }
      if (Array.isArray(this.data.lot.Images) || this.data.lot.Images === null || this.data.lot.Images === undefined) {
        let imageArr = [];
        imageArr.push(res);
        this.data.lot.Images = imageArr;
        this.loading = false;
      }
      else {
        this.loadingMessage = 'Complete';
        setTimeout(() => {
          this.loadingMessage = 'Fetching Lot';
          this.loading = false;
          this.refreshLot()

        }, 750)
        setTimeout(() => {
          this.spinner.hide();
          this.loadingMessage = '';
        }, 1500)

        this.file = null;
        this.fileInputNode.nativeElement.value = null;
      }
    },
      error => {
        console.log('%c Error - POST image failed: ', 'background: #ff0000; color: #ffffff;', error);
        this.spinner.hide();
        this.loadingMessage = '';
        this.file = null;
        this.fileInputNode.nativeElement.value = null;
        return;

      })
  }

  deleteImage(image) {
    console.log('image to delete', image);
    this.uploadService.deleteImage(image.id).subscribe(
      res => {
        console.log('image res: ', res);
        console.log('data res: ', this.data.lot.Images);
        this.data.lot.Images = this.data.lot.Images.filter(item => item.id !== image.id);
        console.log('data res 2: ', this.data.lot.Images);
        return;
      },
      error => console.log('%c failed to delete LOT image: ', 'background: #ff0000; color: #ffffff;', error)
    )
  }

    refreshLot() {
    this.ngOnInit();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
