import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteSupplyChainDialogComponent } from './delete-supply-chain-dialog.component';
import { MatDialogModule, MatButtonModule, MatIconModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [DeleteSupplyChainDialogComponent],
  entryComponents: [DeleteSupplyChainDialogComponent]
})
export class DeleteSupplyChainDialogModule { }
