import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddProductComponent } from './add-product/add-product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { ProductsService } from '@app/core/services/backend/product.service';
import { MaterialModule } from "@app/shared/angular-material.module";

@NgModule({
  declarations: [AddProductComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  providers: [ProductsService],
  exports: [FormsModule, ReactiveFormsModule],
  entryComponents: [AddProductComponent]
})
export class ProductModule { }
