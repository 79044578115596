import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { SustainabilityService } from '@app/core/services/backend/sustainability.service';
import { NotificationService } from '@app/core/services';
import { forkJoin } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'bext-sustainability-dialog',
  templateUrl: './sustainability.component.html',
  styleUrls: ['./sustainability.component.scss']
})
export class SustainabilityDialogComponent implements OnInit {

  private alive = true;

  @Input() hideAddSusGroupButton = false;
  @Input() hideEdit = false;
  @Input() hideSpinner = false;
  queryString: string = '';
  loadingMessage = 'Loading'
  nodeSusGroupInstances;
  susGroupList;
  susGroupOptionsList;
  showNewSusGroupInstanceWizard = false;
  wizardInstanceData;
  postingNewGroupInstance = true;
  newSustainabilityId;

  constructor(
    public dialogRef: MatDialogRef<SustainabilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sustainabilityService: SustainabilityService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private router: Router
    ) { }

  ngOnInit() {
    console.log('%c sustainability dialog - node: ', 'background: #fae552; color: #323232;', this.data);
    this.sustainabilityService.getGroupByProduct(this.data.node.NodeData.DefaultProductType)
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        res => {
          this.susGroupList = res;
          this.susGroupOptionsList = res.map(o => {
            let opt = {
              "label": o.Name,
              "value": o.SustainabilityGroupId
            };
            return opt;
          })
          console.log('GET group by product: ', res);
          console.log('GET group and map to list: ', this.susGroupOptionsList);
          return;
        },
        error => {
          console.log('error: ', error);

          return;
        },
        () => {
          // console.log('--- subscription complete ---');

        }
    );

    if (this.data.node.Sustainability.SustainabilityDataId === null) {
      console.log('%c current node - SustainabilityDataId === null: ', 'background: #fae552; color: #323232;', this.data.node);
      setTimeout(() => this.postingNewGroupInstance = false, 2000)
    }
    else {
      this.loadSustainabilityGroupData(this.data.node.Sustainability.SustainabilityDataId);
    }
  }

  loadSustainabilityGroupData(sustainabilityDataId) {
    this.loadingMessage = 'Loading';
    let getGroupInstances$ = this.sustainabilityService.getGroupInstances(sustainabilityDataId);
    forkJoin(getGroupInstances$)
      .pipe(
        map(fj => ({ susGroupInstances: fj[0] }))
      )
      .subscribe(
        res => {
          // console.log('sustainability group list and instances FJ: ', res);
          this.nodeSusGroupInstances = res.susGroupInstances.map(group => {
            var d = new Date(group.CreationDate);
            var year = d.getFullYear();
            var month = d.getMonth();
            var day = d.getDate();
            var c = new Date(year + 1, month, day)
            group.ExpirationDate = c;
            return group;
          });
          return;
        },
        error => {
          console.log('error GET sustainability group instances failed: ', error);
          return;
        },
        () => {
          this.postingNewGroupInstance = false;
        }
      );
  }

  createSustainabilityGroupInstance() {

  }

  setSearchQuery(text) {
    this.queryString = text;
  }

  openSustainabilityInstanceReport(group) {
    // console.log('open sustainability group: ', group);
    let groupId = group.SustainabilityGroupId;
    this.dialogRef.close();
    this.router.navigate(['v2/sustainability/group', groupId]);
  }

  openNewSustainabilityWizard() {
    this.wizardInstanceData = this.createSusGroupInstanceWizardInstanceJSON(this.susGroupOptionsList);
    this.showNewSusGroupInstanceWizard = true;
  }

  sendAction(e) {
    let groupId = e['SustainabilityGroupInstanceGroup.Measure'];
    let instanceName = e['SustainabilityGroupInstanceName.Measure'];
    console.log('groupId ', groupId);
    console.log('instanceName ', instanceName);

    if (!groupId || !instanceName) {
      let groupIdMissing = !groupId ? true : false;
      let instanceNameMissing = !instanceName ? true : false;
      this.notificationService.smallBox({
        title: "Missing form fields",
        content: '' + (groupIdMissing ? '&#8226 Step 1 - Please select a group <br/>' : '') + (instanceNameMissing ? '&#8226 Step 2 - Sustainability Group requires a name' : ''),
        color: "#ffc107",
        timeout: 5000,
        iconSmall: "fas fa-bell swing animated"
      });
      return;
    }
    else {
      this.loadingMessage = 'Creating Sustainability Group'
      this.postingNewGroupInstance = true;
      this.showNewSusGroupInstanceWizard = false;
      let payload = {
        "SustainabilityGroupId": groupId,
        "NodeId": this.data.node.id,
        "Name": instanceName
      };
      console.log('payload: ', payload);
      this.sustainabilityService.createGroupInstance(payload).subscribe(
        res => {
          console.log('successful POST group instance: ', res);
          this.newSustainabilityId = res[res.length - 1].SustainabilityDataId;
          return;
        },
        error => {
          console.log('error POST group instance failed: ', error);
          return;
        },
        () => {
          this.loadSustainabilityGroupData(this.newSustainabilityId);
        }
      );
    }
  }

  getSustainabilityGroupInstance(group) {
    this.sustainabilityService.getGroupInstance(group.SustainabilityGroupInstanceId).subscribe(
      res => {
        console.log('response: ', res);

        return;
      },
      error => {
        console.log('error: ', error);

        return;
      },
      () => {
        // console.log('--- subscription complete ---');

      }
    );

  }

  createSusGroupInstanceWizardInstanceJSON(options) {
    var d = {
      "id": "8bd90784-9478-4839-b4af-bb698d4ba084",
      "WizardId": "d1692898-e7c9-4afd-9441-9d3a2853b7ad",
      "WizardInstanceStatus": null,
      "WizardFrontEnd": {
        "WizardUserAction": {
          "WizardActionType": "TEST"
        },
        "WizardMetaData": {
          "WizardName": "Create Sustainability Group Instance",
          "WizardCurrentSequenceStep": 0,
          "WizardIcon": "TEST",
          "WizardType": "TEST"
        },
        "WizardStepDatas": [
          {
            "WizardStepMetaData": {
              "Sequence": 0,
              "StepName": "Sustainability Group",
              "StepIcon": "TEST",
              "StepColor": "TEST",
              "StepDisplayStatus": "TEST",
              "StepConditions": []
            },
            "WizardStepFields": [
              {
                "Fields": [
                  {
                    "name": "SustainabilityGroupInstanceGroup.Measure",
                    "humanName": "Select a Sustainability Group",
                    "label": "Select a Sustainability Group",
                    "type": "select",
                    "value": "",
                    "validation": {
                      "create": {
                        "required": false
                      },
                      "analysis": {
                        "required": true
                      },
                      "transfer": {
                        "required": true
                      }
                    },
                    "visible": true,
                    "options": options
                  }
                ]
              }
            ],
            "WizardStepActions": [
              {
                "Action": "PREV",
                "WizardActionLocation": "LEFT",
                "ActionClass": "btn btn-sm btn-primary btn-prev",
                "IconClass": "fa fa-arrow-left"
              },
              {
                "Action": "NEXT",
                "WizardActionLocation": "RIGHT",
                "ActionClass": "btn btn-sm btn-success btn-next",
                "IconClass": "fa fa-arrow-right"
              }
            ]
          },
          {
            "WizardStepMetaData": {
              "Sequence": 1,
              "StepName": "Name",
              "StepIcon": "TEST",
              "StepColor": "TEST",
              "StepDisplayStatus": "TEST",
              "StepConditions": []
            },
            "WizardStepFields": [
              {
                "Fields": [
                  {
                    "name": "SustainabilityGroupInstanceName.Measure",
                    "humanName": "New Group Name",
                    "label": "New Group Name",
                    "type": "text",
                    "value": "",
                    "validation": {
                      "create": {
                        "required": false
                      },
                      "analysis": {
                        "required": true
                      },
                      "transfer": {
                        "required": true
                      }
                    },
                    "visible": true,
                    "options": []
                  }
                ]
              }
            ],
            "WizardStepActions": [
              {
                "Action": "PREV",
                "WizardActionLocation": "LEFT",
                "ActionClass": "btn btn-sm btn-primary btn-prev",
                "IconClass": "fa fa-arrow-left"
              },
              {
                "Action": "FINISH",
                "WizardActionLocation": "RIGHT",
                "ActionClass": "btn btn-sm btn-success btn-next",
                "IconClass": "fa fa-arrow-right"
              }
            ]
          }
        ]
      }
    };
    return d;
  }

  close() {
    this.alive = false;
    this.dialogRef.close();
  }

}
