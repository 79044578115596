import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output, ViewEncapsulation, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { ColumnSorterService, ColumnInfo } from './column-sorter.service';

@Component({
  selector: 'bext-mat-table-column-sorter, button[bext-mat-table-column-sorter]',
  templateUrl: './column-sorter.component.html',
  styleUrls: ['./column-sorter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnSorterService]
})

export class ColumnSorterComponent implements OnInit {

  @Output()
  columnsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  columns: string[];

  @Input()
  columnNames: string[];
  
  @Input()
  saveName?: string;
  
  @Input()
  hiddenColumns?: boolean[];

  columnInfo: ColumnInfo[];

  constructor(private elementRef: ElementRef, private columnSorterService: ColumnSorterService) { }

  ngOnInit() {
    this.columnInfo = this.columns.map((currElement, index) => {
      console.log('column hidden: ', this.hiddenColumns[index]);
      return {
        id: currElement,
        name: this.columnNames[index],
        hidden: this.hiddenColumns.length && this.hiddenColumns[index] ? this.hiddenColumns[index] : false
      };
    });
    //this.columnInfo = this.columnSorterService.loadSavedColumnInfo(this.columnInfo, this.saveName);
    this.columnInfo = this.columnInfo;
    this.emitColumns(false);
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.classList += 'bext-sortable-mat-button-no-input';
  }

  columnMenuDropped(event: CdkDragDrop<any>): void {
    moveItemInArray(this.columnInfo, event.item.data.columnIndex, event.currentIndex);
    this.emitColumns(true);
  }

  toggleSelectedColumn(columnId: string) {
    const colFound = this.columnInfo.find(col => col.id === columnId);
    colFound.hidden = !colFound.hidden;
    this.emitColumns(true);
  }

  private emitColumns(saveColumns: boolean) {
    // Only emit the columns on the next animation frame available
    window.requestAnimationFrame(() => {
      this.columnsChange.emit(this.columnInfo.filter(colInfo => !colInfo.hidden).map(colInfo => colInfo.id));
      if (saveColumns) {
        this.columnSorterService.saveColumnInfo(this.columnInfo, this.saveName);
      }
    });
  }
}
