import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter, Output } from '@angular/core';

import {ProductTypes} from '@app/shared/nodes/create/productTypes';
@Component({
  selector: 'edit-copy-node-form',
  templateUrl: './edit-copy-form.component.html',
  styleUrls: ['./edit-copy-form.component.css']
})
export class EditCopyNodeFormComponent {
    node;
    @Input() locations;
    @Input() organizations;
    @Input('node') get getNode() {
        return this.node;
      };
  
      @Output() nodeChange = new EventEmitter();
  
      set getNode(val) {
        this.node = val;
      }
    productTypes = new ProductTypes().TypesByProduct;
    nodeTypes;
    nodeDetailTypes
    constructor() {

    }

    ngOnInit() {
        this.setNodeTypes(true);
        // this.copyNode = JSON.parse(JSON.stringify(this.node));
    }
    ngOnChanges() {
    }

    setNodeTypes(callNodeDetailTypes?) {
        let foundType = this.productTypes.filter(product => product.productType == this.node.NodeData.DefaultProductType);
        if (!foundType.length) {
          foundType = this.productTypes.filter(product => product.productType == 'Cotton');
        }
        this.nodeTypes = foundType[0].types;
        if (callNodeDetailTypes) {
          this.setNodeDetailTypes();
        }
    }
  
    setNodeDetailTypes() {
      this.nodeDetailTypes = this.nodeTypes.filter(nodeType => nodeType.node == this.node.NodeData.NodeType)[0].detail;
    }
}