import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router, RouterModule } from "@angular/router";
import {
  TransferLotHelperObject,
  TransferLotHelperService
} from "@app/core-v2/services";
@Component({
  selector: "sa-scan-transfer-qr-lot",
  templateUrl: "./scan-transfer-qr-lot.component.html",
  styleUrls: ["./scan-transfer-qr-lot.component.css"]
})
export class ScanTransferQrLotComponent {
  constructor(
    private dialogRef: MatDialogRef<ScanTransferQrLotComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private transferLotHelperService: TransferLotHelperService,
    private cd: ChangeDetectorRef
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  logger(e, t) {
    console.log("e", e, "t", t);
    console.log("phone home");
    if (e && e.length && e.indexOf(" ") > -1) {
      let lotID = e.split(" ")[0];
      let nodeID = e.split(" ")[1].split(",")[0];
      let lotName = e.split(",")[1];
      console.log(lotID, nodeID);
      this.router.navigateByUrl("/v2/node/" + nodeID).then(() => {
        setTimeout(() => {
          let filter = <HTMLInputElement>(
            document.getElementById("NodeLotsFilter")
          );
          filter.value = lotName;
          let keyup = new Event("keyup");
          filter.dispatchEvent(keyup);
          setTimeout(() => {
            let cbx1 = document.getElementsByClassName(
              "mat-checkbox-input cdk-visually-hidden"
            )[0];
            cbx1.dispatchEvent(new Event("click"));
          }, 1000);
          // this.cd.detectChanges();
        }, 4000);
        this.dialogRef.close();
      });
    }
  }
}
