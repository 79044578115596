import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bext-ui-metadata-custom-field-dialog',
  templateUrl: './ui-metadata-custom-field-dialog.component.html',
  styleUrls: ['./ui-metadata-custom-field-dialog.component.scss']
})
export class UiMetadataCustomFieldDialogComponent implements OnInit {

  uiData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UiMetadataCustomFieldDialogComponent>
  ) {
    this.uiData = new UiData();
  }

  ngOnInit() {
  }

  closeDialog(data:any = undefined) {
    this.dialogRef.close(data);
  }

}

interface IUiDataConfig {
  label: any;
  name: any;
  inputType: any;
  options: any[] | null;
  collections: any;
  type: any;
  value: any;
  validations: any;
}

class UiData {
  
  private _uiDataConfig: IUiDataConfig = {
    label: null,
    name: null,
    inputType: null,
    options: [],
    collections: null,
    type: null,
    value: null,
    validations: null
  };

  get uiDataConfig() {
    return this._uiDataConfig
  }

  private _uiData = new BehaviorSubject<IUiDataConfig>(this.uiDataConfig)

  get dataSubject() {
    return this._uiData;
  }

  get values() {
    return this._uiData.getValue();
  }

  get values$() {
    return this._uiData.asObservable();
  }

  get label() {
    return this.values.label
  }
  set label(val) {
    let uiData = this.values;
    uiData.label = val;
    this.dataSubject.next(uiData)
  }

  get name() {
    return this.values.name
  }
  set name(val) {
    let uiData = this.values;
    uiData.name = val;
    this.dataSubject.next(uiData)
  }

  get inputType() {
    return this.values.inputType
  }
  set inputType(val) {
    let uiData = this.values;
    uiData.inputType = val;
    this.dataSubject.next(uiData)
  }

  get options() {
    return this.values.options
  }
  set options(val) {
    let uiData = this.values;
    uiData.options = val;
    this.dataSubject.next(uiData)
  }

  get collections() {
    return this.values.collections
  }
  set collections(val) {
    let uiData = this.values;
    uiData.collections = val;
    this.dataSubject.next(uiData)
  }

  get type() {
    return this.values.type
  }
  set type(val) {
    let uiData = this.values;
    uiData.type = val;
    this.dataSubject.next(uiData)
  }

  get value() {
    return this.values.value
  }
  set value(val) {
    let uiData = this.values;
    uiData.value = val;
    this.dataSubject.next(uiData)
  }

  get validations() {
    return this.values.validations
  }
  set validations(val) {
    let uiData = this.values;
    uiData.validations = val;
    this.dataSubject.next(uiData)
  }


  constructor() {
    let uiData = this.values;
    uiData.label = null;
    uiData.name = null;
    uiData.inputType = null;
    uiData.options = [];
    uiData.collections = null;
    uiData.type = null;
    uiData.value = '';
    uiData.validations = null;
    this.dataSubject.next(uiData);
  }

  public addOption(label: string, value?: string) {
    let uiData = this.values;
    let opt = {
      label: label,
      value: value ? value : label.replace(/\s/g, '')
    };
    uiData.options.push(opt);
    this.dataSubject.next(uiData);
    return this.values;
  }

  public removeOption(index) {
    let uiData = this.values;
    uiData.options.splice(index, 1);
    uiData.options = [...uiData.options];
    this.dataSubject.next(uiData);
    return this.values;
  }

}
