import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

import { SupplychainService } from '../../../core/services/backend/supplychain.service';
import { NodeService } from '../../../core/services/backend/node.service';
import { LotService } from '../../../core/services/backend/lot.service';
import { LotDrilldownV2Component } from './lot-drilldown.component';
import { TreeModule } from 'angular-tree-component';
import { MaterialModule } from '../../../shared/angular-material.module';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCardModule, MatTableModule } from '@angular/material';
import { LotSummaryModule } from '../lot-summary/lot-summary.module';
import { LotTokensModule } from '../lot-tokens/lot-tokens.module';
import { LotDtoTreeComponent } from './lot-dto-tree/lot-dto-tree.component';
import {LotImagesModule} from "@app/shared-v2/lots/lot-images/lot-images.module";
import { PhotoGalleryModule } from '@app/shared-v2/photo-gallery/photo-gallery.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    MatCardModule,
    NgxSpinnerModule,
    MatDialogModule,
    TreeModule.forRoot(),
    MatTableModule,
    LotSummaryModule,
    LotTokensModule,
    LotImagesModule,
    LotTokensModule,
    PhotoGalleryModule,
  ],
  declarations: [LotDrilldownV2Component, LotDtoTreeComponent],
  providers: [NgxSpinnerService, SupplychainService, NodeService, LotService],
  exports: [LotDrilldownV2Component, LotDtoTreeComponent],
  entryComponents: [LotDrilldownV2Component]
})
export class LotDrilldownV2Module { }
