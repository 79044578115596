import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { MaterialModule } from "@app/shared/angular-material.module";
import { ScanTransferQrLotComponent } from "@app/shared-v2/lots/scan-transfer-qr-lot/scan-transfer-qr-lot.component";

@NgModule({
  declarations: [ScanTransferQrLotComponent],
  imports: [
    CommonModule,
    FormsModule,
    ZXingScannerModule,
    MaterialModule
  ],
  entryComponents: [ScanTransferQrLotComponent],
  exports: [ScanTransferQrLotComponent]
})
export class ScanTransferQrLotModule { }
