import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bext-kpi-card',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.css']
})
export class KpiCardComponent implements OnInit {

  @Input() metrics: any[] = [];

  sustainabilityScore;
  tracabilityScore;

  constructor() { }

  ngOnInit() {
    let sumScores = this.metrics.reduce((prev, curr) => {
      return (prev.score || prev) + curr.score;
    }, 0);

    this.sustainabilityScore = Math.round(sumScores / this.metrics.length) || 38;
    // this.tracabilityScore = Math.round((this.sustainabilityScore + 100) / 2) || 25
    this.tracabilityScore = 25
  }



}
