import { MatSelectChange } from '@angular/material';
import { environment } from '@env/environment';
import { CountryList } from '@app/shared-v2/models/country-list.model';
import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, ViewChild, NgZone, Input, EventEmitter, Output } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take, pluck, filter } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { Conversions } from '@app/shared-v2/utils/conversion-utils';
import { LocationServiceV2 } from '@app/core-v2/services';

interface ICountry {
  name?: string;
  states?: IState[];
}

interface IState {
  name?: string;
}

@Component({
  selector: 'bext-google-location',
  templateUrl: './locationv2.component.html',
  styleUrls: ['./locationv2.component.scss']
})
export class LocationV2Component {

  @ViewChild('search')
  public searchElementRef: ElementRef;

  private alive = true;

  private _existingLocation: any;
  @Input()
  set existingLocation(val) {
    this._existingLocation = val;
  }
  get existingLocation() {
    return this._existingLocation;
  }

  private _states: IState[] = [];
  set states(val) {
    this._states = val;
  }
  get states() {
    return this._states;
  }
  
  private _countries: ICountry[] = CountryList;
  set countries(val) {
    this._countries = val;
  }
  get countries() {
    return this._countries;
  }
  
  form: FormGroup;

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;

  locationProps = [
    { Name: 'Name', Type: 'input', SubType: 'text', Required: false, Placeholder: 'Name', DefaultValue: null, Validations: []},
    { Name: 'Country', Type: 'select', SubType: undefined, Required: false, Placeholder: 'Country', DefaultValue: null, Validations: []},
    { Name: 'State', Type: 'select', SubType: undefined, Required: false, Placeholder: 'State', DefaultValue: null, Validations: []},
    { Name: 'City', Type: 'input', SubType: 'text', Required: false, Placeholder: 'City', DefaultValue: null, Validations: []},
    { Name: 'Latitude', Type: 'input', SubType: 'number', Required: false, Placeholder: 'Latitude', DefaultValue: null, Validations: []},
    { Name: 'Longitude', Type: 'input', SubType: 'number', Required: false, Placeholder: 'Longitude', DefaultValue: null, Validations: []},
    { Name: 'Elevation', Type: 'input', SubType: 'number', Required: false, Placeholder: 'Elevation', DefaultValue: null, Validations: []},
    {   Name: 'ElevationUnit', Type: 'input', SubType: 'text', Required: false, Placeholder: 'Elevation Unit', DefaultValue: 'Meters', Validations: [
        {
          name: "required",
          validator: "required",
          message: "Must enter a unit"
        },
        {
          name: "pattern",
          validator:"^(meters|feet|Meters|Feet)$",
          message: "Accepts only 'Meters' or 'Feet'"
        }
      ]
    },
    { Name: 'Address', Type: 'input', SubType: 'text', Required: false, Placeholder: 'Address', DefaultValue: null, Validations: []},
    { Name: 'Description', Type: 'textarea', SubType: undefined, Required: false, Placeholder: 'Description', DefaultValue: null, Validations: []},
    { "Name": "LocationId", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "LocationId", "DefaultValue": null, "Validations": [] },
    { "Name": "type", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "type", "DefaultValue": null, "Validations": [] },
    { "Name": "PostalCode", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "PostalCode", "DefaultValue": null, "Validations": [] },
    { "Name": "LocationCode", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "LocationCode", "DefaultValue": null, "Validations": [] },
    { "Name": "ExternalReferenceId", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "ExternalReferenceId", "DefaultValue": null, "Validations": [] },
    { "Name": "ExternalReferenceSource", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "ExternalReferenceSource", "DefaultValue": null, "Validations": [] },
    { "Name": "LocationGroup", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "LocationGroup", "DefaultValue": null, "Validations": [] },
    { "Name": "LocationType", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "LocationType", "DefaultValue": null, "Validations": [] },
    { "Name": "ParcelId", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "ParcelId", "DefaultValue": null, "Validations": [] },
    { "Name": "District", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "District", "DefaultValue": null, "Validations": [] },
    { "Name": "LocationGroups", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "LocationGroups", "DefaultValue": null, "Validations": [] }
    // { "Name": "UiData", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "UiData", "DefaultValue": null, "Validations": [] },
    // { "Name": "EntityDocument", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "EntityDocument", "DefaultValue": null, "Validations": [] },
    // { "Name": "EntityPerson", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "EntityPerson", "DefaultValue": null, "Validations": [] },
    // { "Name": "EntityLocation", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "EntityLocation", "DefaultValue": null, "Validations": [] },
    // { "Name": "EntityValue", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "EntityValue", "DefaultValue": null, "Validations": [] },
    // { "Name": "Sustainability", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "Sustainability", "DefaultValue": null, "Validations": [] },
    // { "Name": "TraceabilityHashData", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "TraceabilityHashData", "DefaultValue": null, "Validations": [] },
    // { "Name": "TraceabilityBlockchain", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "TraceabilityBlockchain", "DefaultValue": null, "Validations": [] },
    // { "Name": "EntityFormListId", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "EntityFormListId", "DefaultValue": null, "Validations": [] },
    // { "Name": "DateCreated", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "DateCreated", "DefaultValue": null, "Validations": [] },
    // { "Name": "DateModified", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "DateModified", "DefaultValue": null, "Validations": [] },
    // { "Name": "OwnerOrganizationId", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "OwnerOrganizationId", "DefaultValue": null, "Validations": [] },
    // { "Name": "Deleted", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "Deleted", "DefaultValue": null, "Validations": [] },
    // { "Name": "Notes", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "Notes", "DefaultValue": null, "Validations": [] },
    // { "Name": "GenericAttributes", "Type": "input", "SubType": "text", "Required": false, "Placeholder": "GenericAttributes", "DefaultValue": null, "Validations": [] }
  ];

  mapReady = false;

  @Output('processActionEvent') onLocationChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient,
    private locationService: LocationServiceV2
  ) {

  }

  ngOnInit() {
    this.initializeForm(this.existingLocation);
    this.loadPlacesAutocomplete();
  }

  initializeForm(locationRecord?: object | undefined) {
    const formDataObj = {};
    this.locationProps.forEach((p) => {
      formDataObj[p.Name] = new FormControl(
        locationRecord ? locationRecord[p.Name] : p.DefaultValue,
        this._bindValidations(!locationRecord && p.Validations.length ? p.Validations : []),
      );
      
    })
    // console.log('%c FORM OBJECT: ', 'background: #fae552; color: #323232;', formDataObj);
    this.form = new FormGroup(formDataObj)
    // console.log('%c FORM INSTANCE: ', 'background: #fae552; color: #323232;', this.form);
    if (locationRecord && locationRecord['State'] !== null && locationRecord['Country'] !== null) this._setStateBasedOnCountry(locationRecord['Country'], locationRecord['State'])
  }

  getFormControl(ctrlName) {
    return this.form.get(ctrlName) as FormControl;
  }

  setFormControlValue(ctrlName, val, os: boolean = false) {
    this.getFormControl(ctrlName).setValue(val, { onlySelf: os});
  }

  get elevationUnitControl() {
    return this.form.get('ElevationUnit') as FormControl;
  }

  get elevationContol() {
    return this.form.get('Elevation') as FormControl;
  }

  onMapReady(e) {
    // console.log('GOOGLE MAP READY: ', e);
    e.maxZoom = 15;
    e.minZoom = 2;
    setTimeout(() => {
      this.mapReady = true;
    }, 1000);
  }

  loadPlacesAutocomplete(): void {
    this.mapsAPILoader.load().then(() => {

      if (this.existingLocation) this._setCurrentLocation(this.existingLocation.Latitude, this.existingLocation.Longitude);
      else this._setCurrentLocation();

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude, zoom, and address
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;

          console.log('%c PLACE: ', 'background: #41ff6b; color: #ff4700;', place);

          let byTypeCountry = (t) => t.types[0].toLowerCase() === 'country';
          let byTypeAdminLevel1 = (t) => t.types[0].toLowerCase() === 'administrative_area_level_1';
          let byLocalityOrPostalTown = (t) => t.types[0].toLowerCase() === 'locality' || t.types[0].toLowerCase() === 'postal_town';
          
          let country = place.address_components.find(byTypeCountry);
          let state = place.address_components.find(byTypeAdminLevel1);
          let city = place.address_components.find(byLocalityOrPostalTown);

          this.setFormControlValue('Country', country.long_name)
          this._setStateBasedOnCountry(country.long_name, state.long_name)
          this.setFormControlValue('City', city.long_name)
        
          this.setFormControlValue('Latitude', this.latitude)
          this.setFormControlValue('Longitude', this.longitude)
          this.setFormControlValue('Address', place.formatted_address)

          this._getElevation(this.latitude, this.longitude)
            .subscribe(
              res => {
                this._setElevationBasedOnCurrentUnitType(res)
              },
              error => console.log('%c GET geodata failed: ', 'background: #ff0000; color: #ffffff;', error)
            )
        });
      });
    });
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event['coords'].lat;
    this.longitude = $event['coords'].lng;

    this.setFormControlValue('Latitude', this.latitude)
    this.setFormControlValue('Longitude', this.longitude)
    this._getElevation(this.latitude, this.longitude)
      .subscribe(
        res => {
          console.log('%c GET geodata: ', 'background: #41ff6b; color: #ff4700;', res)
          this._setElevationBasedOnCurrentUnitType(res)
        },
        error => console.log('%c GET geodata failed: ', 'background: #ff0000; color: #ffffff;', error)
      )
  }

  toggleUnit() {
    if (isNullOrUndefined(this.elevationContol.value) || (!this.elevationContol.value || isNaN(this.elevationContol.value * 1)) || !isNullOrUndefined(this.elevationUnitControl.errors)) return;
    else {
      let currentElevationValue = this.elevationContol.value * 1;
      let newElevationUnit = this.elevationUnitControl.value === 'Meters' ? 'Feet' : 'Meters';
      let newElevationValue = newElevationUnit === 'Meters' ? Conversions.Length.feetToMeters(currentElevationValue) : Conversions.Length.metersToFeet(currentElevationValue);
      // this._setFormElevationUnit(newElevationUnit);
      this.setFormControlValue('ElevationUnit', newElevationUnit)
      // this._setFormElevation(newElevationValue);
      this.setFormControlValue('Elevation', Math.round(newElevationValue))
    }
  }

  onCountrySelect(c) {
    let selectedCountry = this.countries.find((n) => n.name === c.value)
    console.log('%c SELECTED COUNTRY: ', 'background: #fae552; color: #323232;', selectedCountry);
    this.states = selectedCountry.states
    this.setFormControlValue('State', this.states[0].name)
    // this.getFormControl('State').setValue(this.states[0].name)
  }

  locationCRUDOperation(method) {
    this.form['submitted'] = true;
    let payload = this.form.value;
    switch (method) {
      case 'POST':
        // console.log('%c LOCATION payload: ', 'background: #41ff6b; color: #ff4700;', payload);
        this.onLocationChangeEvent.emit({ eventType: 'CRUD', method: method, status: 'processing', statusCode: null, data: payload, loading: true})
        this.locationService.addLocation(payload)
          .pipe(
            take(1)
          )
          .subscribe(
            res => {
              // console.log('%c POST location: ', 'background: #41ff6b; color: #ff4700;', res);
              this.onLocationChangeEvent.emit({ eventType: 'CRUD', method: method, status: 'success', statusCode: 200, data: res, loading: false})
            },
            error => console.log('%c POST location error: ', 'background: #ff0000; color: #ffffff;', error),
            () => console.log('location event complete')
          )

        break;

      case 'PUT':
        payload = Object.assign(this.existingLocation, payload)
        // console.log('%c LOCATION payload: ', 'background: #41ff6b; color: #ff4700;', payload);
        this.onLocationChangeEvent.emit({ eventType: 'CRUD', method: method, status: 'processing', statusCode: null, data: payload, loading: true})
        this.locationService.editLocation(payload)
          .pipe(
            take(1)
          )
          .subscribe(
            res => {
              // console.log('%c PUT location: ', 'background: #41ff6b; color: #ff4700;', res);
              this.onLocationChangeEvent.emit({ eventType: 'CRUD', method: method, status: 'success', statusCode: 200, data: payload, loading: false})
            },
            error => console.log('%c PUT location error: ', 'background: #ff0000; color: #ffffff;', error),
            () => console.log('location event complete')
          )
        break;

      case 'CANCEL':
            // console.log('%c CANCEL FORM event: ', 'background: #fae552; color: #323232;');
            let location = Object.assign(this.existingLocation || {}, this.form.value)
            this.onLocationChangeEvent.emit({eventType: 'CANCEL', status: null, statusCode: null, data: location, loading: null})
        break;
    
      default:
        console.log('%c METHOD FOR LOCATION CRUD OPERATION NOT FOUND: ', 'background: #fae552; color: #323232;', method);
        break;
    }
  }

  private _getElevation(lat, lng): Observable<any> {
    return this.http.get<any>(`https://elevation-api.io/api/elevation?points=(${lat},${lng})&resolution=90&key=${environment.ELEVATION_API_IO_KEY}`).pipe(take(1), pluck('elevations', '0', 'elevation'))
  };

  private _setElevationBasedOnCurrentUnitType(elevation: number) {
    let currentElevationUnit = this.elevationUnitControl.value;
    let elevationUnitValue = '';
    let elevationValue: number;
    if (!isNullOrUndefined(this.elevationUnitControl.errors)) {
      elevationValue = Math.round(elevation)
      elevationUnitValue = 'Meters';
      this.setFormControlValue('ElevationUnit', elevationUnitValue)
      this.setFormControlValue('Elevation', elevationValue)
    }
    else {
      if (currentElevationUnit.toLowerCase() === 'feet') {
        elevationValue = Math.round(Conversions.Length.metersToFeet(elevation))
        elevationUnitValue = 'Feet';
        this.setFormControlValue('Elevation', elevationValue)
      }
      else {
        elevationValue = Math.round(elevation)
        elevationUnitValue = 'Meters';
        this.setFormControlValue('Elevation', elevationValue)
      }
    }
  }

  private _setCurrentLocation(lat?, lng?) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = lat || position.coords.latitude;
        this.longitude = lng || position.coords.longitude;
        this.zoom = 15;
        // this._setFormLatAndLng(this.latitude, this.longitude);

        if (!lat && !lng) {
          this.setFormControlValue('Latitude', this.latitude)
          this.setFormControlValue('Longitude', this.longitude)
        }
      });
    }
  }

  private _setStateBasedOnCountry(countryName, stateName) {
    let selectedCountry = this.countries.find((i) => i.name === countryName)
    this.states = selectedCountry.states
    let selectedState = this.states.find((j) => j.name === stateName)
    if (selectedState) this.setFormControlValue('State', selectedState.name)
  }

  // private _setFormLatAndLng(lat, lng) {
  //   this.getFormControl('Latitude').setValue(lat);
  //   this.getFormControl('Longitude').setValue(lng);
  // }

  private _bindValidations(validations: any) {
    if (validations.length > 0) {
      let validationsList = validations.reduce((prev, v) => {
        let validationName = v.name.toLowerCase();
        if (validationName === 'required') {
          prev.push(Validators.required)
        }
        if (validationName === 'pattern') {
          prev.push(Validators.pattern(v.validator))
        }
        return prev;
      }, []);
      return Validators.compose(validationsList);
    }
    return null;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
