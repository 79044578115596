import { BehaviorSubject } from "rxjs";
import { ITransferObject, LotTransferMetaDataDto, TransferLotV1DtosEntity, TransferredToLot } from "./transfer-lot-payload.interface";

export class TransferLotEntityV2 {

  private _payloadBehaviorSubject: BehaviorSubject<ITransferObject> = new BehaviorSubject<ITransferObject>(null);
  
  LotTransferMetaDataDto;
  TransferLotV1Dtos;

  constructor(
    LotTransferMetaDataDto: LotTransferMetaDataDto,
    TransferLotV1Dtos: TransferLotV1DtosEntity[]
  ) {
    this._payloadBehaviorSubject.next({ LotTransferMetaDataDto: LotTransferMetaDataDto, TransferLotV1Dtos: TransferLotV1Dtos});
  }

  get payloadChanges() {
    return this._payloadBehaviorSubject.asObservable();
  }

  get payloadValue() {
    return this._payloadBehaviorSubject.getValue();
  }

  updateTransferType(type) {
    let payload = this._payloadBehaviorSubject.getValue();
    payload.LotTransferMetaDataDto.TransferType = type;
    this._payloadBehaviorSubject.next(payload);
  }

  addAbsorbedFromExistingLot(lot) {
    let payload = this._payloadBehaviorSubject.getValue();
    let transferLotDto = {
      "AbsorbedFromLot": {
        "LotMetaData": {
          "LotId": lot.id
        }
      },
      "TransferredToLot": {}
    };
    payload.TransferLotV1Dtos.push(transferLotDto);
    this._payloadBehaviorSubject.next(payload);
  }

  addAbsorbedFromManyExistingLot(lot) {
    let payload = this._payloadBehaviorSubject.getValue();
    let existingTransferLotDtoIndex = payload.TransferLotV1Dtos.findIndex((existingLotDto) => existingLotDto.AbsorbedFromLot.LotMetaData.LotId === lot.id);
    // console.log('existing absorbedFromLot - index: ', existingTransferLotDtoIndex);
    let transferLotDto = {
      "AbsorbedFromLot": {
        "LotMetaData": {
          "LotId": lot.id
        }
      },
      "TransferredToLot": {}
    };
    existingTransferLotDtoIndex === -1 ? payload.TransferLotV1Dtos.push(transferLotDto) : payload.TransferLotV1Dtos.splice(existingTransferLotDtoIndex, 1);
    this._payloadBehaviorSubject.next(payload);
  }

  addTransferredToExistingLot(lot) {
    let payload = this._payloadBehaviorSubject.getValue();
    let transferLotDto = {
      "AbsorbedFromLot": {},
      "TransferredToLot": {
        "LotMetaData": {
          "LotId": lot.id
        }
      }
    };
    let lotMatch = (transferLotDto) => {
      if (transferLotDto && transferLotDto.TransferredToLot && transferLotDto.TransferredToLot.LotMetaData && transferLotDto.TransferredToLot.LotMetaData.LotId) {
        return lot.id === transferLotDto.TransferredToLot.LotMetaData.LotId;
      } else {
        return null;
      }
    };
    let payloadLotFoundIndex = payload.TransferLotV1Dtos.findIndex(lotMatch);
    // console.log('%c lot match - index: ', 'background: #fae552; color: #323232;', payloadLotFoundIndex);
    if (payloadLotFoundIndex === -1 || payloadLotFoundIndex === null) {
      payload.TransferLotV1Dtos.push(transferLotDto);
    } else {
      payload.TransferLotV1Dtos.splice(payloadLotFoundIndex, 1);
    }
    // console.log('payload: ', payload);
    this._payloadBehaviorSubject.next(payload);
  }

  addTransferredToManyLot(lot, node) {
    let payload = this._payloadBehaviorSubject.getValue();
    let transferLotDto = {
      "AbsorbedFromLot": {},
      "TransferredToLot": {
        "LotMetaData": {
          "LotName": lot.LotName,
          "LotCurrentWeight": {
            "Measure": lot.Measure,
            "Unit": lot.Unit
          },
          "NodeId": node.NodeId,
          "LotType": node.NodeType,
          "LotDetailType": node.NodeDetailType
        }
      }
    };
    let lotMatch = (transferLotDto) => {
      if (transferLotDto && transferLotDto.TransferredToLot && transferLotDto.TransferredToLot.LotMetaData && transferLotDto.TransferredToLot.LotMetaData.LotId) {
        return lot.id === transferLotDto.TransferredToLot.LotMetaData.LotId;
      } else {
        return null;
      }
    };
    let payloadLotFoundIndex = payload.TransferLotV1Dtos.findIndex(lotMatch);
    // console.log('%c lot match - index: ', 'background: #fae552; color: #323232;', payloadLotFoundIndex);
    if (payloadLotFoundIndex === -1 || payloadLotFoundIndex === null) {
      payload.TransferLotV1Dtos.push(transferLotDto);
    } else {
      payload.TransferLotV1Dtos.splice(payloadLotFoundIndex, 1);
    }
    // console.log('payload: ', payload);
    this._payloadBehaviorSubject.next(payload);
  }

  addAbsorbedFromToManyLot(lot) {
    // USING addAbsorbedFromExistingLot FUNCTION AS GENERIC ADD ABSORBED LOT
    this.addAbsorbedFromExistingLot(lot);
  }

  createTransferLotDtos(absorbedFromLots, absorbedFromNode, selectedNode) {
    let payload = this._payloadBehaviorSubject.getValue();
    payload.LotTransferMetaDataDto.TransferType = absorbedFromLots.length > 1 ? 'ManyLotsToOneNewLot' : "OneLotToOneNewLot";
    payload.TransferLotV1Dtos = absorbedFromLots.map((absorbedFromLot) => {
      let transferLotDto = {
        "AbsorbedFromLot": {
          "LotMetaData": {
            "LotId": absorbedFromLot.id,
            "SupplyChainId": "",
            "NodeId": absorbedFromNode.id,
            "OwnerOrganizationId": absorbedFromNode.OwnerOrganizationId
          }
        },
        "TransferredToLot": {
          "LotMetaData": {
            "LotName": "",
            "LotType": "",
            "LotDetailType": "",
            "NodeId": selectedNode.NodeId,
            "SupplyChainId": "",
            "OrganizationId": selectedNode.OrganizationId,
            "LotBeginDateTime": "",
            "LotEndDateTime": "",
            "LotStartWeight": {
              "AttributeTypeId": 0,
              "Type": "",
              "Label": "",
              "Key": "",
              "Default": false,
              "Name": "",
              "Method": "",
              "Group": "",
              "Unit": "",
              "Measure": "",
              "StartMeasureTime": "",
              "EndMeasureTime": "",
              "MeasureTime": "",
              "StartMeasureTimeDt": "",
              "EndMeasureTimeDt": "",
              "MeasureTimeDt": ""
            },
            "LotEndWeight": {
              "AttributeTypeId": 0,
              "Type": "",
              "Label": "",
              "Key": "",
              "Default": false,
              "Name": "",
              "Method": "",
              "Group": "",
              "Unit": "",
              "Measure": "",
              "StartMeasureTime": "",
              "EndMeasureTime": "",
              "MeasureTime": "",
              "StartMeasureTimeDt": "",
              "EndMeasureTimeDt": "",
              "MeasureTimeDt": ""
            },
            "QualityScore": {
              "AttributeTypeId": 0,
              "Type": "",
              "Label": "",
              "Key": "",
              "Default": false,
              "Name": "",
              "Method": "",
              "Group": "",
              "Unit": "",
              "Measure": "",
              "StartMeasureTime": "",
              "EndMeasureTime": "",
              "MeasureTime": "",
              "StartMeasureTimeDt": "",
              "EndMeasureTimeDt": "",
              "MeasureTimeDt": ""
            },
            "Tier": ""
          },
        "LotDatas": [],
          "Notes": ""
        }
      };

      return transferLotDto;
    })
    this._payloadBehaviorSubject.next(payload);
  }

  updatOneToManyTransferredToLotDto(payload, transferToLotData, selectedNode) {
    payload.TransferLotV1Dtos.forEach((lotDto) => {
      console.log('%c TRANSFERRED TO LOT DATA: ', 'background: #fae552; color: #323232;', transferToLotData);
      lotDto.TransferredToLot = this.createTransferToLotMetaData(transferToLotData, selectedNode)
    });
    return payload;
  }

  createTransferToLotMetaData(lotData, selectedNode) {
    let newMetaData = {
      "LotMetaData": {
          "LotName": lotData.LotName,
          "LotType": "",
          "LotDetailType": "",
          "NodeId": selectedNode.NodeId,
          "SupplyChainId": "",
          "OrganizationId": selectedNode.OrganizationId,
          "LotBeginDateTime": "",
          "LotEndDateTime": "",
          "LotStartWeight": {
            "AttributeTypeId": 0,
            "Type": "",
            "Label": "",
            "Key": "",
            "Default": false,
            "Name": "",
            "Method": "",
            "Group": "",
            "Unit": lotData.LotStartWeightUnit,
            "Measure": lotData.LotStartWeight,
            "StartMeasureTime": "",
            "EndMeasureTime": "",
            "MeasureTime": "",
            "StartMeasureTimeDt": "",
            "EndMeasureTimeDt": "",
            "MeasureTimeDt": ""
          },
          "LotEndWeight": {
            "AttributeTypeId": 0,
            "Type": "",
            "Label": "",
            "Key": "",
            "Default": false,
            "Name": "",
            "Method": "",
            "Group": "",
            "Unit": "",
            "Measure": "",
            "StartMeasureTime": "",
            "EndMeasureTime": "",
            "MeasureTime": "",
            "StartMeasureTimeDt": "",
            "EndMeasureTimeDt": "",
            "MeasureTimeDt": ""
          },
          "QualityScore": {
            "AttributeTypeId": 0,
            "Type": "",
            "Label": "",
            "Key": "",
            "Default": false,
            "Name": "",
            "Method": "",
            "Group": "",
            "Unit": "",
            "Measure": "",
            "StartMeasureTime": "",
            "EndMeasureTime": "",
            "MeasureTime": "",
            "StartMeasureTimeDt": "",
            "EndMeasureTimeDt": "",
            "MeasureTimeDt": ""
          },
          "Tier": ""
        },
      "LotDatas": [],
      "Notes": ""
      };
    return newMetaData;
  }

  destroyPayload() {
    this._payloadBehaviorSubject.next(null)
  }

}