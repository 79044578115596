import { Component, OnInit } from '@angular/core';
import { NodeService } from '@app/core/services/backend/node.service';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@app/core/services';
import { MatTreeNestedDataSource } from '@angular/material';
import { NestedTreeControl } from '@angular/cdk/tree';
import * as introJs from 'intro.js';
import { Router } from '@angular/router';
import {HelpService} from "@app/core/services/backend/help.service";
import { forkJoin } from 'rxjs';
import {ProductsService} from "@app/core/services/backend/product.service";

export class NodeDetailType {
  NodeTemplateId: string;
  NodeTemplateName: string;
  DefaultTemplate: boolean;
  DefaultDetailedTemplate: boolean;
  OrganizationTemplate: boolean;
  UserTemplate: boolean;
  name?: string;
}
export class NodeType {
  NodeType: string;
  NodeDetailTypes: NodeDetailType[];
  children?: NodeDetailType[];
  name?: string;
}
export class Product {
  Product: string;
  ProductLabel: any;
  TokenName: any;
  NodeTypes: NodeType[];
  children?: NodeType[];
  name?: string;
}

@Component({
  selector: 'bext-create-node-template-v2',
  templateUrl: './create-node-template-v2.component.html',
  styleUrls: ['./create-node-template-v2.component.scss']
})
export class CreateNodeTemplateV2Component {

  filters = [];
  selectedProduct: Product;
  selectedType: NodeType;
  dataSource = new MatTreeNestedDataSource<Product>();
  nodes;
  selectedTemplate;
  originalTemplateName = '';
  nodeTypes;
  newDetailType;
  templateSaved;
  selectedNodeTypes = [];
  introConfig;
  intro;
  filteredTemplates = [];
  saveToOrg = false;
  saveToOrgAndPartners = false;
  saveToUser = false;
  baseType: string;
  templateCache = [];
  products = [
    {
      "id": "4feac5f2-48dc-4ebe-bac4-5baabb353bc1",
      "Label": "Palm Oil",
      "ProductName": "Palm Oil",
      "TokenName": "PalmOil",
      "ProductOwnerType": "BextMaster",
      "ProductDatas": [],
      "Images": [],
      "type": "Product",
      "LocationId": null,
      "DateCreated": "2019-08-03T01:03:10.109416Z",
      "DateModified": "2019-08-03T01:03:10.109416Z",
      "UiData": null,
      "BextDocumentSummaries": [],
      "OwnerOrganizationId": "914b3dcb-6b72-4b69-992e-f5aba2a45ebb"
    },
    {
      "id": "a50ee360-769f-4e39-a3ac-8f904806c9b4",
      "Label": "Coffee",
      "ProductName": "Coffee",
      "TokenName": "Coffee",
      "ProductOwnerType": "BextMaster",
      "ProductDatas": [],
      "Images": [],
      "type": "Product",
      "LocationId": null,
      "DateCreated": "2019-08-03T01:03:19.7095513Z",
      "DateModified": "2019-08-03T01:03:19.7095513Z",
      "UiData": null,
      "BextDocumentSummaries": [],
      "OwnerOrganizationId": "914b3dcb-6b72-4b69-992e-f5aba2a45ebb"
    },
    {
      "id": "f997b824-c9fa-4d5b-96ce-a4e115d2f6dc",
      "Label": "Cocoa",
      "ProductName": "Cocoa",
      "TokenName": "Cocoa",
      "ProductOwnerType": "BextMaster",
      "ProductDatas": [],
      "Images": [],
      "type": "Product",
      "LocationId": null,
      "DateCreated": "2019-08-03T01:03:28.6735021Z",
      "DateModified": "2019-08-03T01:03:28.6735021Z",
      "UiData": null,
      "BextDocumentSummaries": [],
      "OwnerOrganizationId": "914b3dcb-6b72-4b69-992e-f5aba2a45ebb"
    },
    {
      "id": "b755598a-f92e-4104-9461-0a0963e25797",
      "Label": "Cotton",
      "ProductName": "Cotton",
      "TokenName": "Cotton",
      "ProductOwnerType": "BextMaster",
      "ProductDatas": [],
      "Images": [],
      "type": "Product",
      "LocationId": null,
      "DateCreated": "2019-08-03T01:03:43.0484736Z",
      "DateModified": "2019-08-03T01:03:43.0484741Z",
      "UiData": null,
      "BextDocumentSummaries": [],
      "OwnerOrganizationId": "914b3dcb-6b72-4b69-992e-f5aba2a45ebb",
    }
  ];

  constructor(
      private nodeService: NodeService,
      private spinner: NgxSpinnerService,
      private router: Router,
      private helpService: HelpService,
      private productService: ProductsService
  ) {
    this.configureIntro();
  }


  filterChanged(e?) {
    if (e) {
      if (this.filters.indexOf(e) > -1) {
        this.filters.splice(this.filters.indexOf(e), 1)
      } else {
        this.filters.push(e);
      }
    }
    if ((!this.filters.length || !e) && this.selectedType) { this.filteredTemplates = this.selectedType.NodeDetailTypes;}
    else {
      this.filterDetailTypes();
    }
  }

  onWizardComplete() {
    if (this.baseType) {
      this.selectedTemplate.NodeType = this.baseType;
    }
    this.selectedTemplate.BaseTemplate = false;
    this.selectedTemplate.Products.length = 0;
    this.selectedTemplate.Products.push(this.products.find(product => this.selectedProduct.Product == product.ProductName));
    this.selectedTemplate.NodeDetailType = this.newDetailType;
    this.selectedTemplate.OrganizationTemplate = this.saveToOrg;
    this.selectedTemplate.UserTemplate = this.saveToUser;
    this.selectedTemplate.OrganizationPartnersTemplate = this.saveToOrgAndPartners;
    delete this.selectedTemplate.id;
    delete this.selectedTemplate.OwnerOrganizationId;
    delete this.selectedTemplate.DateCreated;
    delete this.selectedTemplate.DateModified;
    this.nodeService.addNodeTemplate(this.selectedTemplate).subscribe(res => {
      this.templateSaved = true;
      this.router.navigate(['/v2/settings/nodetemplatelist'])
    })
  }

  ngOnInit() {
    forkJoin(this.nodeService.getTemplatesV2(), this.nodeService.getNodeTemplate('Base') ).subscribe(([products, baseTemplate]) => {
      products.forEach(product => {
        product.name = product.Product;
        product.children = product.NodeTypes;
        product.children.forEach(nodeType => {
          if (nodeType.NodeType) {
            nodeType.name = nodeType.NodeType;
            // if ((nodeType.NodeType == 'Base')) {
            //   let baseMod = JSON.parse(JSON.stringify(baseTemplate));
            //   baseMod.NodeType = nodeType.NodeType;
            //   console.log(baseMod);
            //   baseMod.NodeDetailType = nodeType.NodeType + 'Base';
            //   baseMod.NodeTemplateName = nodeType.NodeType + 'Base';
            //   baseMod.BaseTemplate = false;
            //   nodeType.NodeDetailTypes.push(baseMod);
            // }
            product.children = product.children.filter(nodeType => nodeType.NodeType != 'Base');
            nodeType.children = nodeType.NodeDetailTypes;
            let typeBaseCopy = JSON.parse(JSON.stringify(baseTemplate));
            typeBaseCopy.NodeType = nodeType.NodeType;
            typeBaseCopy.NodeDetailType = nodeType.NodeType + 'Base';
            typeBaseCopy.NodeTemplateName = nodeType.NodeType + 'Base';
            typeBaseCopy.NodeTemplateType = nodeType.NodeType + 'Base';
            typeBaseCopy.BaseTemplate = false;
            delete typeBaseCopy.id;
            delete typeBaseCopy.DateCreated;
            delete typeBaseCopy.DateModified;
            delete typeBaseCopy.OwnerOrganizationId;
            nodeType.children.push(typeBaseCopy, nodeType);
            nodeType.children.forEach(nodeDetailType => {
              nodeDetailType.name = nodeDetailType.NodeTemplateName;
            })
          }
        })
      });
      this.dataSource.data = products;
      this.selectedTemplate = baseTemplate;
      if (!localStorage.getItem(this.introConfig.storageKey)) {
        setTimeout(() => {
          this.intro.start();
          localStorage.setItem(this.introConfig.storageKey, 'true');
        });
      }
    })
  }
  getTemplate(type?) {
    this.originalTemplateName = type;
    let getB = false;
    if (type.indexOf('Base') > -1) {
      getB = true;
    }
    let cachedTemplate = this.templateCache.find(template => template.NodeDetailType == type);
    if (cachedTemplate) { this.selectedTemplate = cachedTemplate }
    else {
      this.spinner.show();

      this.nodeService.getNodeTemplate(getB ? 'Base' : type).subscribe(res => {
        this.templateCache.push(res);
        if (getB) {
          res.NodeDetailType = type;
          res.NodeTemplateType = type;
          res.NodeType = type.split('Base')[0];
          res.BaseTemplate = false;
        }
        this.selectedTemplate = res; 
        this.spinner.hide();
      }, err => {
        this.nodeService.getNodeTemplate('Base').subscribe(res => {
          this.selectedTemplate = res;
          this.spinner.hide();
        })
      })
    }
  }


  nodeTypeSelected(type) {
    let index = this.selectedNodeTypes.indexOf(type);
    if (index > -1) {
      this.selectedNodeTypes.splice(index, 1)
    } else {
      this.selectedNodeTypes.push(type);
    }
    this.filterDetailTypes();
  }

  filterDetailTypes() {
    this.filteredTemplates = [];
    const checkPermission = (template) => {
      let hasPermission = false;
      this.filters.forEach(filter => {
        switch (filter) {
          case 'org': hasPermission = hasPermission || template.OrganizationTemplate; break;
          case 'mine': hasPermission = hasPermission || template.UserTemplate; break;
          case 'default': hasPermission = hasPermission || template.DefaultTemplate; break;
          default : return;
        }
      });
      return hasPermission;
    };
    if (!this.filters.length && !this.selectedNodeTypes.length) {
      this.selectedProduct.NodeTypes.forEach(type => {
        this.filteredTemplates.push(...type.NodeDetailTypes)
      });
    } else if (!this.filters.length && this.selectedNodeTypes.length) {
      this.selectedNodeTypes.forEach(type => {
        this.filteredTemplates.push(...type.NodeDetailTypes);
      })
    } else {
      this.selectedNodeTypes.forEach(type => {
        let arr = type.NodeDetailTypes.filter(type => {
          return checkPermission(type);
        });
        if (arr.length) {
          this.filteredTemplates.push(...arr);
        }
      });
    }
  }

  configureIntro() {
    this.helpService.getTutorials()
        .subscribe((tutorials) => {
              this.introConfig = tutorials.find(tutorial =>  tutorial.id == 'create-node-template');
            });
    this.intro = introJs();
    this.intro.setOptions({
      steps: [
        {
          element: '#step1',
          intro: 'Select which Product to which this template applies.',
          position: 'right'
        },
        {
          element: '#step2',
          intro: " A Product will pass through many Nodes during its journey. Select the Node to which this template applies.",
          position: 'left'
        },
        {
          element: '#step3',
          intro: "To view or filter templates according to Type, including those you have created previously, select one or more here.",
          position: 'left'
        },
        {
          element: '#step4',
          intro: "Select one Template Type here. The fields within any selected Template Type may be customized below and saved with a unique name.",
          position: 'right'
        },
        {
          element: '#step5',
          intro: 'Create a unique Template Name for the template you created or modified.',
          position: 'left'
        },
        {
          element: '#step6',
          intro: 'Select the permission level to establish who may access the named template. Consider who will use this template – Your Organization (company), Partner Organizations (other companies, vendors, etc.) or only you want to have access to this template.',
          position: 'left'
        },
        {
          element: '#step7',
          intro: 'Design or modify the Template Form here. You may add, remove, and edit fields and reconfigure the form layout.',
          position: 'top'
        }
      ]
    });
  }
}
