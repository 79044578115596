import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "@app/shared/shared.module";
import {MaterialModule} from "@app/shared/angular-material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LotDocumentsComponent} from "@app/shared-v2/lots/lot-documents/lot-documents.component";
import {DocumentService} from "@app/core/services/backend/document.service";
import {LotService} from "@app/core/services/backend/lot.service";
import {WizardService} from "@app/core/services/backend/wizard.service";

@NgModule({
  declarations: [LotDocumentsComponent],
  imports: [
    CommonModule,
      SharedModule,
      MaterialModule,
      FormsModule,
      ReactiveFormsModule
  ],
  entryComponents: [LotDocumentsComponent],
  exports: [LotDocumentsComponent],
  providers: [DocumentService, LotService, WizardService]
})
export class LotDocumentsModule { }
