import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[faIconMatStyle]'
})
export class FontAwesomeIconMatStyleDirective {

  constructor(
    private host: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.renderer.setStyle(this.host.nativeElement, 'height', '18px');
    this.renderer.setStyle(this.host.nativeElement, 'width', '18px');
    this.renderer.setStyle(this.host.nativeElement, 'font-size', '18px');
  }

}