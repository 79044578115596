import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { first, catchError } from 'rxjs/operators';

export interface LatLng {
  latitude?: number;
  longitude?: number;
}

export interface MapData {
  code: string;
  name: string;
  value: number;
  color: string;
}


@Injectable()


export class MapDataService {

  private latLngSubject = new BehaviorSubject({})

  private allUsZipCodes;

  constructor(private http: HttpClient) {
    this.http.get('assets/map/zip_codes.json')
      .pipe(
        catchError(() => of([])),
        first()
      )
      .subscribe(
        res => this.allUsZipCodes = res
      )
  }

  createMapDataV2(capsuleData, colorOptions?: any[]) {
    // console.log('color options: ', colorOptions);
    let markerCoordinates = capsuleData.reduce((prev, curr) => {
      prev[curr['closest_capsule_zip_match_to_geoJson']] = this.createNewLatLng(curr['postalCodeData'].latitude || curr['postalCodeData'].lat, curr['postalCodeData'].longitude || curr['postalCodeData'].lng);
      return prev;
    }, {});

    let markerData = capsuleData.map((obj) => {
      let mapObj = {
            code: obj['postalCodeData'].zip,  
            name: obj.Name,
            capsule: obj,
            value: parseFloat(obj.Weight),
            unit: obj.WeightUnit,
            color: !colorOptions ? '#e4e4e4' : colorOptions[Math.round(Math.random() * colorOptions.length)]
          };
      return mapObj;
    });

    return {markerCoordinates: markerCoordinates, markerData: markerData};
  }

  private createNewLatLng(lat, lng) {
    let newLatLng: LatLng = { latitude: lat, longitude: lng };
    return newLatLng;
  }

  findUsPostalCodes(postalCodes) {
    // console.log('all zip codes: ', this.allUsZipCodes);
    return postalCodes
      .reduce((prev, curr) => {
        const zipCode = data => data.zip === curr;
        let postalCode = this.allUsZipCodes.find(zipCode)
        if (postalCode) {
          prev.push(postalCode);
          return prev;
        }
        return prev;
      }, []);
  }

  mapPostalCodeDataToCapsules(postalCodes) {
    return postalCodes
      .reduce((prevCapsule, currCapsule) => {
        const closestZipCodeMatch = (prev, curr) => (Math.abs(curr.zip - currCapsule.Zipcode) < Math.abs(prev.zip - currCapsule.Zipcode) ? curr : prev);
        let postalCodeData = this.allUsZipCodes.reduce(closestZipCodeMatch);
          currCapsule['postalCodeData'] = postalCodeData;
          currCapsule['closest_capsule_zip_match_to_geoJson'] = postalCodeData.zip;
          prevCapsule.push(currCapsule);
        return prevCapsule;
      }, []);
  }

  getUsPostalCodeData() {
    const data = this.allUsZipCodes;
    return data;
  }

  findClosestZipCodeMatch(capsule, postalCodesArray) {
    return postalCodesArray.reduce((prev, curr) => {
      return (Math.abs(curr.zip - capsule.Zipcode) < Math.abs(prev.zip - capsule.Zipcode) ? curr : prev);
    })
  }

  



}