import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

import { SupplychainService } from '../../../core/services/backend/supplychain.service';
import { LotService } from '../../../core/services/backend/lot.service';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCardModule, MatTableModule } from '@angular/material';
import {LotImagesComponent} from "@app/shared-v2/lots/lot-images/lot-images.component";
import {MaterialModule} from "@app/shared/angular-material.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        MatCardModule,
        NgxSpinnerModule,
        MatDialogModule
    ],
    declarations: [LotImagesComponent],
    providers: [NgxSpinnerService, SupplychainService, LotService],
    exports: [LotImagesComponent],
    entryComponents: [LotImagesComponent]
})
export class LotImagesModule { }
