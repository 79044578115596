import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject, Subject } from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { ReportRoutes } from '@app/core/api-routes.enum';
import { mergeMap, expand } from 'rxjs/operators';

export interface SingleLotSubject {
  requestStatus: Loading;
  data?: (RetailSingleLot)[] | null;
}
export interface Loading {
  loading: boolean;
  requestCount: number;
}

export interface RetailSingleLot {
  StartingLotId: string;
  LotVideo: string;
  SupplyChainName: string;
  SupplyChainId: string;
  SupplyChainIcon: string;
  NodesWithSingleLot: NodesWithSingleLot;
  Farmers?: any[] | null;
}
export interface NodesWithSingleLot {
  NodeName: string;
  NodeId: string;
  LotName: string;
  LotId: string;
  DateCreated: string;
  Location: Location;
  LotWeight: LotWeight;
  BlockchainHash?: null;
  NodeDetailType: string;
  NodeType: string;
  PreviousLotCount: number;
  NextLotCount: number;
  NodeIcon: string;
  AbsorbedFromLotIds?: (string)[] | null;
  TransferredToLotIds?: (string)[] | null;
}
export interface Location {
  id: string;
  LocationId?: null;
  Name: string;
  Country: string;
  City: string;
  State: string;
  Latitude: number;
  Longitude: number;
  Elevation: number;
  ElevationUnit: string;
  Address: string;
  Description: string;
  OrganizationName?: null;
  OrganizationId?: null;
  type: string;
  DateCreated: string;
  DateModified: string;
  UiData?: null;
  OwnerOrganizationId: string;
  Deleted: boolean;
}
export interface LotWeight {
  AttributeTypeId: number;
  Type: string;
  Default: boolean;
  Name: string;
  Method: string;
  Group: string;
  Unit: string;
  Measure: string;
  StartMeasureTime: string;
  EndMeasureTime: string;
  MeasureTime: string;
  StartMeasureTimeDt: string;
  EndMeasureTimeDt: string;
  MeasureTimeDt: string;
}

export interface LotHistoryDto {
  LotName: string;
  LotId: string;
  NodeName: string;
  NodeId: string;
  Level: number;
  lotHistoryDtos: Array<LotHistoryDto>;
}

@Injectable()
export class ReportsService {

  BASE_URL = environment.baseApiUrl;

  public singleLots = new Subject<SingleLotSubject>();
  singleLots$ = this.singleLots.asObservable();
  public singleLotsTree: any = { requestStatus: {loading: false, requestCount: 0 }, data: [] };

  public simpleLots = new Subject<any>();
  simpleLots$ = this.simpleLots.asObservable();
  public simpleLotsTree: any = { requestStatus: { loading: false, requestCount: 0 }, data: [] };

  constructor(private http: HttpClient) { }

  getReportData(id, type): Observable<any> {
    return this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL + `/${id}/${type}`);
  }

  getRetailFarmers(sc_id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.RETAIL_IMGS_FARMER_URL + `/${sc_id}`);
  }

  getNextPreviousReportData(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.LOTPREVIOUSNEXT_URL + '/' + id);
  }

  getTimelineReportData(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.LOTTIMELINE_URL + '/' + id);
  }

  getTimelineMarkerReportData(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.LOTTIMELINE_MARKER_URL + '/' + id);
  }

  getNespressoPodSource(sc_id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.NESPRESSO_POD_SOURCE_URL + '/' + sc_id);
  }

  getSimpleLot(lot_id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.SIMPLELOT_URL + '/' + lot_id);
  }

  getSimpleLots(lot_ids: string[]): Observable<any> {
    return from(lot_ids)
      .pipe(
        mergeMap(lot_id => this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.SIMPLELOT_URL + '/' + lot_id))
      );
  }

  getOrganizationReports() {
    return this.http.get(this.BASE_URL + '/reports/organization/2bfe88d9-8d7d-42ae-bcda-90ebefa1b774')
  }

  getReportRequest(url) {
    return this.http.get(this.BASE_URL + url)
  }

  getRetailImages(entityType: string, id): Observable<any> {
    let imagesUrl = '';
    switch (entityType) {
      case 'farmer':
          imagesUrl = ReportRoutes.RETAIL_IMGS_FARMER_URL + '/' + id;
        break;
      case 'node':
          imagesUrl = ReportRoutes.RETAIL_IMGS_NODE_URL + '/' + id;
        break;
      case 'supplychain':
          imagesUrl = ReportRoutes.RETAIL_IMGS_SUPPLYCHAIN_URL + '/' + id;
        break;
      default:
        break;
    }
    return this.http.get<any>(this.BASE_URL + imagesUrl);
  }

  getRetailSingleAbsorbedLots(lot_id): any {
    let promise = new Promise((resolve, reject) => {
      this.http.get<RetailSingleLot>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.RETAIL_SINGLELOT_URL + '/' + lot_id)
        .toPromise()
        .then(res => {
          if (this.singleLotsTree.requestStatus.requestCount === 0) {
            this.singleLotsTree.requestStatus.loading = true;
            this.singleLotsTree.requestStatus.requestCount++;
          }
          this.singleLotsTree.requestStatus.requestCount++;
          this.singleLotsTree.data.push(res);
          this.singleLots.next(this.singleLotsTree);

          if (res.NodesWithSingleLot.AbsorbedFromLotIds.length) {
            res.NodesWithSingleLot.AbsorbedFromLotIds.forEach(function(id) {
              this.getRetailSingleAbsorbedLots(id)
            }.bind(this));
            return res;
          }
          else {
            this.singleLotsTree.requestStatus.loading = false;
            this.singleLots.next(this.singleLotsTree);
          }
          // if (res.NodesWithSingleLot.TransferredToLotIds.length) {
          //   res.NodesWithSingleLot.TransferredToLotIds.forEach(function (id) {
          //     this.getRetailSingleTransferredLots(id)
          //   }.bind(this));
          //   this.singleLots.next(this.singleLotsTree);
          //   return res;
          // }
        });
      return promise;
    });

  }

  getRetailSingleTransferredLots(lot_id){
    let promise = new Promise((resolve, reject) => {
      this.http.get<RetailSingleLot>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.RETAIL_SINGLELOT_URL + '/' + lot_id)
        .toPromise()
        .then(res => {
          // console.log('GET retail single: ', );
          this.singleLotsTree.push(res);
          if (res.NodesWithSingleLot.TransferredToLotIds.length) {
            res.NodesWithSingleLot.TransferredToLotIds.forEach(function(id) {
              this.getRetailSingleTransferredLots(id)
            }.bind(this));
            this.singleLots.next(this.singleLotsTree);
            return res;
          }
        });
      return promise;
    });

  }

  getSimpleLotsRecursive(lot_ids) {
    // console.log('recursive get simple lot params: ', lot_ids);
    let promise = new Promise((resolve, reject) => {
      this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.SIMPLELOT_URL + '/' + lot_ids)
        .toPromise()
        .then(res => {
          if (this.simpleLotsTree.requestStatus.requestCount === 0) {
            this.simpleLotsTree.requestStatus.loading = true;
          }
          this.simpleLotsTree.requestStatus.requestCount++;
          this.simpleLotsTree.data.push(res);
          this.simpleLots.next(this.simpleLotsTree);

          if (res.AbsorbedFromTransferLotIds.length) {
            res.AbsorbedFromTransferLotIds.forEach(function (id) {
              this.getSimpleLotsRecursive(id)
            }.bind(this));
            this.simpleLots.next(this.simpleLotsTree)
            return res;
          }
          else {
            this.simpleLotsTree.requestStatus.loading = false;
            this.simpleLots.next(this.simpleLotsTree);
            this.simpleLots.complete();
          }
        });
      return promise;
    });
  }

  getLotHistoryReport(topLevelLotID, maxLots, maxLevels) {
    return this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL_V2 + ReportRoutes.RECURSIVE_LOT_LIST_URL + '&Lot.LotId=' + topLevelLotID + '&Lot.MaxLots=' + maxLots + '&Lot.MaxLevels=' + maxLevels);
  }
}