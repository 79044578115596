import { Component, OnInit } from '@angular/core';
import {languages} from '../languages.model'
import { LanguageSelectorService } from '@app/core/services/language-selector.service';

@Component({
  selector: 'sa-language-selector',
  templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {

  public languages: Array<any>;
  public currentLanguage: any;

  constructor(private languageSelectorService: LanguageSelectorService) {
  }

  ngOnInit() {
    this.languages = languages;
    this.currentLanguage = this.languageSelectorService.currentLanguage;
  }

  setLanguage(language){
    this.currentLanguage = language;
    this.languageSelectorService.setLanguage(language)
  }

}
