import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';
import { OrganizationService } from '@app/core/services/backend/organization.service';
import {Observable} from "rxjs";
import {map, startWith, debounceTime, takeWhile} from "rxjs/operators";

@Component({
  selector: 'bext-user-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class AdminUserFormComponent implements OnInit {

  private alive = true;

  @Input() existingUser: any;
  @Input() organizations = [];
  @Output('formValueChanges') formValueChanges = new EventEmitter<any>();

  public userRoles = ['Admin', 'ReadWrite', 'ReadOnly', 'Custom']
  constructor(public controlContainer: ControlContainer) { }
  filteredOptions: Observable<any[]>;

  ngOnInit() {
    this.filteredOptions = this.controlContainer.control['controls']['OrganizationIds'].valueChanges
        .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value['OrganizationName']),
            map(name => name ? this._filter(name) : this.organizations.slice())
        );

    // this.controlContainer.valueChanges
    //   .pipe(
    //     takeWhile(() => this.alive),
    //     debounceTime(100)
    //   )
    //   .subscribe(
    //     res => this.formValueChanges.emit()
    //   )
  }

  get passwordControl() {
    return this.controlContainer.control['controls']['Password'] as FormControl;
  }

  // private _filter(value: any): string[] {
  //   const filterValue = value.toLowerCase();
  //   // if (this.organizations) this.organizations = this.organizations.filter(org => org);
  //   //
  //   return this.organizations ? this.organizations.filter(option => {console.log(option.OrganizationName ? option.OrganizationName.includes(filterValue) : null); return option && option.OrganizationName ? option.OrganizationName.toLowerCase().includes(filterValue) : false}) : [];
  // }
  private _filter(name) {
    const filterValue = name.toLowerCase();

    return this.organizations.filter(option => option.OrganizationName ? option.OrganizationName.toLowerCase().indexOf(filterValue) === 0 : false);
  }

  displayFn(user?): string | undefined {
    return user ? user.OrganizationName : undefined;
  }
  onChange(event) {
    console.log(event);
    console.log(this.existingUser)
  }

  compareFn(c1: any, c2: any): boolean {  
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
