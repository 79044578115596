import { Component, OnInit, Input } from '@angular/core';
import { WindowRefService, GlobeService } from '@app/core-v2/services';
import { BehaviorSubject } from 'rxjs';
import { IGlobeConfig, GlobeConfig } from '@app/shared-v2/models/globe/globe-config.model';
import { filter } from 'rxjs/operators';
import { IRetailGlobeMarker } from '@app/shared-v2/models/globe/retail-globe.marker.interface';

declare var Earth: any;

@Component({
  selector: 'bext-retail-globe',
  templateUrl: './retail-globe.component.html',
  styleUrls: ['./retail-globe.component.scss']
})

export class RetailGlobeComponent implements OnInit {

  private alive = true;
  
  private _globeConfig: object;
  @Input('globeConfig')
  set globeConfig(config: IGlobeConfig) {
    this._globeConfig = new GlobeConfig(config);
  }
  get globeConfig() {
    return this._globeConfig;
  }
  
  private _globeHeight: string = '100vh';
  @Input('height')
  set globeHeight(height) {
    this._globeHeight = height;
  }
  get globeHeight() {
    return this._globeHeight;
  }

  private _markers: IRetailGlobeMarker[] = [];
  @Input('markers')
  set markers(markers: IRetailGlobeMarker[]) {
    // console.log('%c MARKERS: ', 'background: #41ff6b; color: #ff4700;', markers);
    this._markers = markers;
  }
  get markers() {
    return this._markers;
  }

  public scriptLoaded = new BehaviorSubject<boolean>(false);
  public star_mesh = 'o Star\nv 0.169 0.000 0.087\nv 0.442 0.000 -0.111\nv 0.104 0.000 -0.111\nv -0.000 0.000 -0.433\nv -0.104 0.000 -0.111\nv -0.443 0.000 -0.111\nv -0.169 0.000 0.087\nv -0.274 0.000 0.409\nv -0.000 0.000 0.210\nv 0.273 0.000 0.409\nv 0.115 0.104 0.070\nv 0.303 0.104 -0.065\nv 0.071 0.104 -0.065\nv -0.000 0.104 -0.286\nv -0.071 0.104 -0.065\nv -0.303 0.104 -0.065\nv -0.116 0.104 0.070\nv -0.187 0.104 0.290\nv -0.000 0.104 0.154\nv 0.187 0.104 0.290\nvn 0.00 -1.00 0.00\nvn 0.00 1.00 -0.00\nvn 0.00 0.99 -0.02\nvn 0.53 0.39 0.74\nvn 0.87 0.39 -0.28\nvn 0.00 0.39 -0.91\nvn -0.87 0.39 -0.28\nvn -0.87 0.39 -0.28\nvn -0.53 0.39 0.74\nvn 0.87 0.39 -0.28\nvn -0.53 0.39 0.74\ns off\nf 8//1 1//1 9//1\nf 15//2 13//2 14//2\nf 17//2 15//2 16//2\nf 17//2 13//2 15//2\nf 17//2 12//2 13//2\nf 17//2 11//2 12//2\nf 18//2 11//2 17//2\nf 18//3 19//3 11//3\nf 19//2 20//2 11//2\nf 9//4 18//4 8//4\nf 1//5 20//5 10//5\nf 3//6 12//6 2//6\nf 7//7 18//7 17//7\nf 5//8 14//8 4//8\nf 5//6 16//6 15//6\nf 7//9 16//9 6//9\nf 1//4 12//4 11//4\nf 3//10 14//10 13//10\nf 9//11 20//11 19//11\nf 9//4 19//4 18//4\nf 1//5 11//5 20//5\nf 3//6 13//6 12//6\nf 7//7 8//7 18//7\nf 5//8 15//8 14//8\nf 5//6 6//6 16//6\nf 7//9 17//9 16//9\nf 1//4 2//4 12//4\nf 3//10 4//10 14//10\nf 9//11 10//11 20//11\n';
  public goto_transition: boolean;
  public current_cluster: any;
  public earthMarkers: any[] = [];
  public earthClusters: any[] = [];
  public tooltip: any;
  public currentMarkerSelection: any;
  public showLocationDetails = false;

  constructor(
    private windowRefService: WindowRefService,
    private globeService: GlobeService
  ) {
    
  }

  @Input('selectedNodeId')

  ngOnInit() {
    this.scriptLoaded
      .pipe(
        filter((loaded) => !loaded)
      )
      .subscribe(
        res => {
          let globe_element = document.getElementById('retail--globe');
          let earth = new Earth('retail--globe', this.globeConfig)
          this.globeService.setEarthInstance(earth, globe_element);
          this.registerOnEarthReady(earth);
        }
      )

    this.globeService.loadEarthJs()
      .then(val => {
        this.scriptLoaded.next(true);
        this.scriptLoaded.complete();
      })
    
  }

  registerOnEarthReady(earthInstance) {

    earthInstance.addEventListener( 'click', (event) => {
      if (this.current_cluster && !this.goto_transition) {
        this.zoomOut();
      }
    });

    earthInstance.addEventListener('ready', (event) => {

      let collapseCluster = () => {

        if (!this.current_cluster) return;

        this.current_cluster.animate('scale', this.current_cluster.originalScale, { duration: 400, easing: 'out-quad' });

        for (var i = 0; i < this.current_cluster.clusteredMarkers.length; i++) {
          this.current_cluster.clusteredMarkers[i].animate('location', this.current_cluster.clusteredMarkers[i].clusterLocation, {
            duration: 400,
            easing: 'out-quad',
            complete: function () {
              this.visible = true;
            }
          });
        }
        this.current_cluster = false;
        document.body.classList.remove('cluster-open');
        earthInstance.draggable = true;
      }

      let enterMarker = (event) => {
        this.tooltip.location = marker.markerLocation;
        this.tooltip.content = marker.title;
        this.tooltip.element.style.marginTop = '-' + String(marker.scale * 0.5 + 0.75) + 'em';
        this.tooltip.visible = true;
      }

      let leaveMarker = (event) => {
        this.tooltip.visible = false;
      }

      let openLink = (event) => {
        window.open(event.target.link);
      }

      let expandCluster = (event) => {
        let componentRef = this;
        let targetCluster = event.target;
        let earthInstance = event.target.earth;
        if (this.current_cluster) {
          collapseCluster();
        }
        // shrink cluster marker
        targetCluster.animate('scale', 0.001, { duration: 200 });

        // go to and zoom earth
        earthInstance.draggable = false;
        componentRef.goto_transition = true;
        earthInstance.goTo(targetCluster.location, { zoom: 1.8, duration: 400, easing: 'out-quad', complete: function () { componentRef.goto_transition = false; } });

        // show clusteredMarkers
        for (var i = 0; i < targetCluster.clusteredMarkers.length; i++) {
          let marker = targetCluster.clusteredMarkers[i];
          // console.log('%c marker: ', 'background: #fae552; color: #323232;', marker);
          marker.visible = true;
          marker.animate('location', marker.markerLocation, { duration: 400, easing: 'out-quad' });
        }

        this.current_cluster = targetCluster;
        document.body.classList.add('cluster-open');
      }

      this.tooltip = earthInstance.addOverlay({
        className: 'marker-tooltip',
        visible: false,
        elementScale: 0.2,
        zoomScale: 0,
        depthScale: 0.4
      });
  
      Earth.addMesh(this.star_mesh);
  
      for (var i = 0; i < this.markers.length; i++) {
  
        let currentMarker = this.markers[i];
  
        if (!this.markers[i].clusteredMarkers) { // single marker
          var marker = earthInstance.addMarker({
  
            mesh: "Star",
            color: '#ffcc00',
            location: currentMarker.location,
            scale: 0.35,
            hotspotRadius: 0.75,
            hotspotHeight: 0.1,
  
            // custom properties
            level: currentMarker.level,
            entityData: currentMarker.entityData,
            title: currentMarker.title,
            link: currentMarker.link
  
          });
  
          marker.addEventListener('click', openLink);
          marker.addEventListener('mouseover', enterMarker);
          marker.addEventListener('mouseout', leaveMarker);
          this.earthMarkers.push(marker);
  
        } else { // cluster of markers
          var cluster = earthInstance.addMarker({
  
            mesh: "Star",
            color: '#ffcc00',
            location: currentMarker.location,
            scale: 0.5, //+ currentMarker.clusteredMarkers.length * 0.03,
            hotspotRadius: 0.5,
            hotspotHeight: 0.1,
  
            // custom properties
            level: currentMarker.level,
            entityData: currentMarker.entityData,
            originalScale: 0.4, //+ currentMarker.clusteredMarkers.length * 0.03,
            title: currentMarker.title,
            clusteredMarkers: []
            
          });
  
          cluster.addEventListener('click', expandCluster);
          this.earthClusters.push(cluster);
  
          // add clusteredMarkers
  
          for (var j = 0; j < currentMarker.clusteredMarkers.length; j++) {
            var marker = earthInstance.addMarker({
  
              mesh: "Star",
              color: '#ffcc00',
              location: currentMarker.location,
              scale: 0.2,
              hotspotRadius: 0.5,
              hotspotHeight: 0.1,
              visible: false,
  
              // custom properties
              level: currentMarker.clusteredMarkers[j].level,
              entityData: currentMarker.clusteredMarkers[j].entityData,
              title: currentMarker.clusteredMarkers[j].title,
              link: currentMarker.clusteredMarkers[j].link,
              clusterLocation: currentMarker.location,
              markerLocation: currentMarker.clusteredMarkers[j].location
  
            });
  
            marker.addEventListener('click', openLink);
            marker.addEventListener('mouseover', enterMarker);
            marker.addEventListener('mouseout', leaveMarker);
  
            cluster.clusteredMarkers.push(marker);
            this.earthMarkers.push(marker);
          }
  
        }
  
      }
    })
    this.globeService.updateEarthInstance(earthInstance);
  }

  shrinkPins() {

  }

  resorePins() {

  }

  zoomOut() {
    let earthInstance = this.globeService.globeState.earthInstance;
    earthInstance.animate('zoom', 1.15, { relativeDuration: 100 });
    if (!this.current_cluster) return;

    this.current_cluster.animate('scale', this.current_cluster.originalScale, { duration: 400, easing: 'out-quad' });

    for (var i = 0; i < this.current_cluster.clusteredMarkers.length; i++) {
      this.current_cluster.clusteredMarkers[i].animate('location', this.current_cluster.clusteredMarkers[i].clusterLocation, {
        duration: 400,
        easing: 'out-quad',
        complete: function () {
          this.visible = true;
        }
      });
    }
    this.current_cluster = false;
    document.body.classList.remove('cluster-open');
    earthInstance.draggable = true;
  }



  getRandomInRange = (from, to, fixed = 7) => {
    return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
