import { Component, OnInit, Input } from "@angular/core";

interface IDataTab {
  TabLabel?: string;
  TabKey?: string;
  Fields?: Field[];
}

interface Field {
  Label?: string;
  Key?: string;
  Value?: Value;
  Visible?: boolean;
}

type Value = number | string;

@Component({
  selector: "data-tabs",
  templateUrl: "./data-tabs.component.html",
  styleUrls: ["./data-tabs.component.scss"],
})
export class DataTabsComponent implements OnInit {
  private _dataTabs: IDataTab[] = [];
  @Input()
  set dataTabs(val) {
    this._dataTabs = val;
  }
  get dataTabs() {
    return this._dataTabs;
  }

  private _header: string = "";
  @Input()
  set header(val) {
    this._header = val;
  }
  get header() {
    return this._header;
  }

  constructor() {}

  ngOnInit() {
    console.log(
      "%c NEW data tabs: ",
      "background: #41ff6b; color: #ff4700;",
      this.dataTabs
    );
  }
}
