import { environment } from '@env/environment';
import { SustainabilityRoutes } from '@app/core-v2/api-routes-v2.enum';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, from, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SustainabilityGroupReport } from '@app/shared-v2/models/sustainability/sustainability-group-report.interface';
import { map, delay } from 'rxjs/operators';

export interface SustainabilityReportNavItem {
  Name: string;
  Disabled?: boolean;
  IconName: string;
  Level?: string;
  LevelName: null | string;
  ParentLevelName: null | string;
  Data?: SustainabilityReportNavItemData | null;
  Children: SustainabilityReportNavItem[] | null;
  SustainabilityHierarchyType: null | string;
}

export interface SustainabilityReportNavItemData {
  SpecificationWizardId?: null | string;
  SpecificationWizardInstanceId?: null | string;
  SustainabilitySDGDtos: null | any[];
}

@Injectable()
export class SustainabilityStoreService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getSustainabilityGroupReportList(): Observable<SustainabilityReportNavItem[]> {
    return of(SustainabilityReportNavList).pipe(delay(500));
  }
}

export const SustainabilityReportNavList = [
  {
    Name: 'IWCA',
    IconName: 'recent_actors',
    Level: '1',
    SustainabilityHierarchyType: "Principle",
    ParentLevelName: null,
    LevelName: '1',
    Data: {
      "SpecificationWizardId": null,
      "SpecificationWizardInstanceId": null,
      "SustainabilitySDGDtos": []
    },
    Children: [
      {
        Name: 'Women Membership Conduct',
        IconName: 'group',
        Level: '1/1.1',
        SustainabilityHierarchyType: "Group",
        ParentLevelName: '1',
        LevelName: '1.1',
        Data: {
          "SpecificationWizardId": null,
          "SpecificationWizardInstanceId": null,
          "SustainabilitySDGDtos": [],
        },
        Children: [
          {
            Name: 'Industry role',
            IconName: 'person',
            Level: '1/1.1/1.1.1',
            SustainabilityHierarchyType: "Indicator",
            ParentLevelName: '1.1',
            LevelName: '1.1.1',
            Data: {
              "SpecificationWizardId": null,
              "SpecificationWizardInstanceId": null,
              "SustainabilitySDGDtos": [],
            },
            Children: [
              {
                Name: 'Role must be in processing or ',
                IconName: 'star_rate',
                Level: '1/1.1/1.1.1/1.1.1.1',
                SustainabilityHierarchyType: "Criteria",
                ParentLevelName: '1.1.1',
                LevelName: '1.1.1.1',
                Data: {
                  "SpecificationWizardId": "b457456f-9905-425a-91d7-357e00453c47",
                  "SpecificationWizardInstanceId": null,
                  "SustainabilitySDGDtos": []
                },
                Children: null
              },
              {
                Name: 'marketing or ',
                IconName: 'star_rate',
                Level: '1/1.1/1.1.1/1.1.1.2',
                SustainabilityHierarchyType: "Criteria",
                ParentLevelName: '1.1.1',
                LevelName: '1.1.1.2',
                Data: {
                  "SpecificationWizardId": "b457456f-9905-425a-91d7-357e00453c47",
                  "SpecificationWizardInstanceId": null,
                  "SustainabilitySDGDtos": []
                },
                Children: null
              },
              {
                Name: 'service rendering ',
                IconName: 'star_rate',
                Level: '1/1.1/1.1.1/1.1.1.3',
                SustainabilityHierarchyType: "Criteria",
                ParentLevelName: '1.1.1',
                LevelName: '1.1.1.3',
                Data: {
                  "SpecificationWizardId": "b457456f-9905-425a-91d7-357e00453c47",
                  "SpecificationWizardInstanceId": null,
                  "SustainabilitySDGDtos": []
                },
                Children: null
              }
            ]
          },
        ]
      }
    ]
  }
];

export const SustainabilityGroupReportMockData = {
  "MainGroupLine": {
    "Level": "1",
    "Parent": null,
    "LevelName": "1",
    "Name": "IWCA",
    "SustainabilityHierarchyType": "Group",
    "ChildrenGroupLines": null,
    "SustainabilitySDGDtos": [],
    "SustainabilitySpecificationDto": null
  },
  "SustainabilitySDGDtos": [],
  "ChildrenGroupLines": [
    {
      "Level": "2",
      "Parent": "1",
      "LevelName": "1.1",
      "Name": "Women Membership Conduct",
      "SustainabilityHierarchyType": "Indicator Group",
      "ChildrenGroupLines": [
        {
          "Level": "3",
          "Parent": "1.1",
          "LevelName": "1.1.1",
          "Name": "Industry role",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "b457456f-9905-425a-91d7-357e00453c47",
              "SpecificationWizardInstanceId": null,
              "Specification": "Role must be in processing or ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "b457456f-9905-425a-91d7-357e00453c47",
              "SpecificationWizardInstanceId": null,
              "Specification": "marketing or",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "b457456f-9905-425a-91d7-357e00453c47",
              "SpecificationWizardInstanceId": null,
              "Specification": "service rendering",
              "SustainabilitySDGDtos": []
            }
          ]
        },
        {
          "Level": "3",
          "Parent": "1.1",
          "LevelName": "1.1.2",
          "Name": "Produced by women",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "e6060074-e00c-4444-af6d-5d44ff5d47be",
              "SpecificationWizardInstanceId": null,
              "Specification": "coffee operations must demonstrates good agricultural practices and  ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e6060074-e00c-4444-af6d-5d44ff5d47be",
              "SpecificationWizardInstanceId": null,
              "Specification": "coffee operations  must demonstrate socio-environmental responsibility",
              "SustainabilitySDGDtos": []
            }
          ]
        },
        {
          "Level": "3",
          "Parent": "1.1",
          "LevelName": "1.1.3",
          "Name": "Traceability ",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "e7cebe26-f615-4c26-834d-0fe734567734",
              "SpecificationWizardInstanceId": null,
              "Specification": "traced coffee must include adequate identification ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e7cebe26-f615-4c26-834d-0fe734567734",
              "SpecificationWizardInstanceId": null,
              "Specification": "traced coffee must be seperated",
              "SustainabilitySDGDtos": []
            }
          ]
        },
        {
          "Level": "3",
          "Parent": "1.1",
          "LevelName": "1.1.4 ",
          "Name": "Subchapters",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "c44afc8c-ea21-4236-b290-48ee79548eaf",
              "SpecificationWizardInstanceId": null,
              "Specification": "producer must attend the annual meetings of IWCA Brazil",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "80376152-b91b-4f2d-996c-ad8e920382ef",
              "SpecificationWizardInstanceId": null,
              "Specification": "Regional sub-chapters must formally meet at least twice a year, when information on the volume of coffee marketed using the IWCA Brazil seal is presented by the participants in the respective subchapter",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "7732b047-d5cb-460b-b837-b17deb4a73a6",
              "SpecificationWizardInstanceId": null,
              "Specification": "Subchapter must present to participants information on the volume of coffee marketed using the IWCA Brazil seal",
              "SustainabilitySDGDtos": []
            }
          ]
        },
        {
          "Level": "3",
          "Parent": "1.1",
          "LevelName": "1.1.5",
          "Name": "Reports",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "9807d618-2ae7-2585-3370-df5ecdcf678e",
              "SpecificationWizardInstanceId": null,
              "Specification": "Two annual reports must be sent",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "9807d618-2ae7-2585-3370-df5ecdcf678e",
              "SpecificationWizardInstanceId": null,
              "Specification": "one in April with the summary of the previous harvest and",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "9807d618-2ae7-2585-3370-df5ecdcf678e",
              "SpecificationWizardInstanceId": null,
              "Specification": "another until November with the data of the current harvest",
              "SustainabilitySDGDtos": []
            }
          ]
        }
      ],
      "SustainabilitySDGDtos": [],
      "SustainabilitySpecificationDto": null
    },
    {
      "Level": "2",
      "Parent": "1",
      "LevelName": "1.2",
      "Name": "Management Conduct",
      "SustainabilityHierarchyType": "Indicator Group",
      "ChildrenGroupLines": [
        {
          "Level": "3",
          "Parent": "1.2",
          "LevelName": "1.2.1",
          "Name": "Active Participation",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "8f5d1419-65c9-4882-aef9-cf887c0975fb",
              "SpecificationWizardInstanceId": null,
              "Specification": "Decision making must be related to financial management or",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "8f5d1419-65c9-4882-aef9-cf887c0975fb",
              "SpecificationWizardInstanceId": null,
              "Specification": "management of the coffee plantations or",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "8f5d1419-65c9-4882-aef9-cf887c0975fb",
              "SpecificationWizardInstanceId": null,
              "Specification": "postharvest manipulation or ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "8f5d1419-65c9-4882-aef9-cf887c0975fb",
              "SpecificationWizardInstanceId": null,
              "Specification": "socio environmental aspects",
              "SustainabilitySDGDtos": []
            }
          ]
        },
        {
          "Level": "3",
          "Parent": "1.2",
          "LevelName": "1.2.2",
          "Name": "Documents",
          "SustainabilityHierarchyType": "Indicator ",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "b207e30a-3773-4221-b5a7-0e19416d021a",
              "SpecificationWizardInstanceId": null,
              "Specification": "Producer must present supporting documents",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "03832533-f0f9-4529-b25a-d29137de6124",
              "SpecificationWizardInstanceId": null,
              "Specification": "Individuals must produce the coffee sales invoice issued in the name of the woman responsible for the production unit",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "03832533-f0f9-4529-b25a-d29137de6124",
              "SpecificationWizardInstanceId": null,
              "Specification": "Invoice must be noted: \"coffee produced by women",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "10689ae2-be12-4112-a35a-1dbaad751529",
              "SpecificationWizardInstanceId": null,
              "Specification": "For legal entities, a woman must prove her participation in the company with at least 50% of the shares in a social contract",
              "SustainabilitySDGDtos": []
            }
          ]
        }
      ],
      "SustainabilitySDGDtos": [],
      "SustainabilitySpecificationDto": null
    },
    {
      "Level": "2",
      "Parent": "1",
      "LevelName": "1.3",
      "Name": "Good Agricultural Practice Conduct",
      "SustainabilityHierarchyType": "Indicator Group",
      "ChildrenGroupLines": [
        {
          "Level": "3",
          "Parent": "1.3",
          "LevelName": "1.3.1",
          "Name": "Certifications",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "566ed42d-cb3c-4c0f-b2f3-e822f15c84b8",
              "SpecificationWizardInstanceId": null,
              "Specification": "Certifications must be used to reinforce good agricultural practices",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "49618b82-2e31-440a-93bf-95fa95d458fe",
              "SpecificationWizardInstanceId": null,
              "Specification": "If the property does not have any verification or certification, it will be verified compliance with the Coffee Sustainability Curriculum (CSC)",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "0182147f-46ae-4239-9f3f-0aab3ecc0b2d",
              "SpecificationWizardInstanceId": null,
              "Specification": "Producer must first comply with the PROHIBITED item",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "0182147f-46ae-4239-9f3f-0aab3ecc0b2d",
              "SpecificationWizardInstanceId": null,
              "Specification": "Producer must secondarily comply with PRIORITY ones",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "0182147f-46ae-4239-9f3f-0aab3ecc0b2d",
              "SpecificationWizardInstanceId": null,
              "Specification": " and finally to the RECOMMENDED ones in a process of continuous improvement.",
              "SustainabilitySDGDtos": []
            }
          ]
        }
      ],
      "SustainabilitySDGDtos": [],
      "SustainabilitySpecificationDto": null
    },
    {
      "Level": "2",
      "Parent": "1",
      "LevelName": "1.4",
      "Name": "Social and Environmental Responsibility Conduct",
      "SustainabilityHierarchyType": "Indicator Group",
      "ChildrenGroupLines": [
        {
          "Level": "3",
          "Parent": "1.4",
          "LevelName": "1.4.1",
          "Name": "Compliance",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "e286f9a3-8cf3-4082-a92a-f8295126e175",
              "SpecificationWizardInstanceId": null,
              "Specification": "Producer must seek compliance ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e6060074-e00c-4444-af6d-5d44ff5d47be",
              "SpecificationWizardInstanceId": null,
              "Specification": "producer must seek compliance with pertinent environmental legislation in the production unit",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e6060074-e00c-4444-af6d-5d44ff5d47be",
              "SpecificationWizardInstanceId": null,
              "Specification": "producer must seek compliance with labor legislation in the production unit",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e6060074-e00c-4444-af6d-5d44ff5d47be",
              "SpecificationWizardInstanceId": null,
              "Specification": "compliance must consider specifics for family agricultural operations",
              "SustainabilitySDGDtos": []
            }
          ]
        },
        {
          "Level": "3",
          "Parent": "1.4",
          "LevelName": "1.4.2",
          "Name": "Community",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "7d000a24-cdc3-4abe-af1a-064e3458f74b",
              "SpecificationWizardInstanceId": null,
              "Specification": "Must demonstrate good community practices",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "89475e31-5a9d-484d-95f1-e3957f0e8271",
              "SpecificationWizardInstanceId": null,
              "Specification": "Must demonstrate the implementation of actions that benefit the rural producers as well as the communities where the production units are inserted",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "c8b3ac0b-5826-4ae4-abe7-8d87f78a689c",
              "SpecificationWizardInstanceId": null,
              "Specification": "Must demonstrate the implementation of actions that benefit workers ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "89475e31-5a9d-484d-95f1-e3957f0e8271",
              "SpecificationWizardInstanceId": null,
              "Specification": "Must demonstrate the implementation of actions that benefit communities where production units are inserted. ",
              "SustainabilitySDGDtos": []
            }
          ]
        }
      ],
      "SustainabilitySDGDtos": [],
      "SustainabilitySpecificationDto": null
    },
    {
      "Level": "2",
      "Parent": "1",
      "LevelName": "1.5",
      "Name": "Traceability ",
      "SustainabilityHierarchyType": "Indicator Group",
      "ChildrenGroupLines": [
        {
          "Level": "3",
          "Parent": "1.5",
          "LevelName": "1.5.2",
          "Name": null,
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "e7cebe26-f615-4c26-834d-0fe734567734",
              "SpecificationWizardInstanceId": null,
              "Specification": "Product records must identify and seperate",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e7cebe26-f615-4c26-834d-0fe734567734",
              "SpecificationWizardInstanceId": null,
              "Specification": "identification of traced product across production stage and ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e7cebe26-f615-4c26-834d-0fe734567734",
              "SpecificationWizardInstanceId": null,
              "Specification": "identification of traced product across processing stage and ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e7cebe26-f615-4c26-834d-0fe734567734",
              "SpecificationWizardInstanceId": null,
              "Specification": "identification of traced product across marketing stage",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e7cebe26-f615-4c26-834d-0fe734567734",
              "SpecificationWizardInstanceId": null,
              "Specification": "seperation of traced product across production stage and ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e7cebe26-f615-4c26-834d-0fe734567734",
              "SpecificationWizardInstanceId": null,
              "Specification": "seperation of traced product across processing stage and ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "e7cebe26-f615-4c26-834d-0fe734567734",
              "SpecificationWizardInstanceId": null,
              "Specification": "seperation of traced product across marketing stage",
              "SustainabilitySDGDtos": []
            }
          ]
        }
      ],
      "SustainabilitySDGDtos": [],
      "SustainabilitySpecificationDto": null
    },
    {
      "Level": "2",
      "Parent": "1",
      "LevelName": "1.6",
      "Name": "Internal Verification System",
      "SustainabilityHierarchyType": "Indicator Group",
      "ChildrenGroupLines": [
        {
          "Level": "3",
          "Parent": "1.6",
          "LevelName": "1.6.1",
          "Name": "Guarantee System",
          "SustainabilityHierarchyType": "Indicator ",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "fac37e66-65d3-4aee-83a3-a2c16fcc918c",
              "SpecificationWizardInstanceId": null,
              "Specification": "Coffee must be verified through a Participatory Guarantee System (GSP) for marketing use of the IWCA Brazil logo",
              "SustainabilitySDGDtos": []
            }
          ]
        },
        {
          "Level": "3",
          "Parent": "1.6",
          "LevelName": "1.6.2",
          "Name": "Sub chapter controls",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": null,
              "SpecificationWizardInstanceId": null,
              "Specification": "At least once a year, production units applying for the use of the IWCA Brazil seal receive verification visits by IWCA Brazil participants or",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": null,
              "SpecificationWizardInstanceId": null,
              "Specification": "At least once a year, production units applying for the use of the IWCA Brazil seal receive verification visits by IWCA Brazil partner institutions",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "50242aed-e433-4ef2-8d71-4d46c9e66c53",
              "SpecificationWizardInstanceId": null,
              "Specification": "These visits must verify compliance with the requirements of this Code of Conduct",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "23ae0356-aa83-4602-965e-89d31baea634",
              "SpecificationWizardInstanceId": null,
              "Specification": "A report visit must be completed",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": null,
              "SpecificationWizardInstanceId": null,
              "Specification": "Site visits must provide full access to facilities",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": null,
              "SpecificationWizardInstanceId": null,
              "Specification": "Site visits must provide full access to records",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": null,
              "SpecificationWizardInstanceId": null,
              "Specification": "Site visits must provide full access to documents",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "9807d618-2ae7-2585-3370-df5ecdcf678e",
              "SpecificationWizardInstanceId": null,
              "Specification": "Site visits must provide full access to coffee sales invoices",
              "SustainabilitySDGDtos": []
            }
          ]
        },
        {
          "Level": "3",
          "Parent": "1.6",
          "LevelName": "1.6.3",
          "Name": "Subchapter Evaluation Committee",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "6dd9c5eb-5e02-44fc-8f9f-527086ebf4b0",
              "SpecificationWizardInstanceId": null,
              "Specification": "committee must have a minimum of three members",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "6dd9c5eb-5e02-44fc-8f9f-527086ebf4b0",
              "SpecificationWizardInstanceId": null,
              "Specification": "committee must include producers",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": "6dd9c5eb-5e02-44fc-8f9f-527086ebf4b0",
              "SpecificationWizardInstanceId": null,
              "Specification": "committee must include representatives of regional organizations related to agricultural activities (universities, federal institutes, technical assistance and extension agencies, research institutes, etc.). ",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": null,
              "SpecificationWizardInstanceId": null,
              "Specification": "Committee of the subchapter must be formally validated by the board of IWCA Brazil",
              "SustainabilitySDGDtos": []
            }
          ]
        },
        {
          "Level": "3",
          "Parent": "1.6",
          "LevelName": "1.6.4",
          "Name": "Certification Approval",
          "SustainabilityHierarchyType": "Indicator",
          "ChildrenGroupLines": null,
          "SustainabilitySDGDtos": [],
          "SustainabilitySpecificationDto": [
            {
              "SpecificationWizardId": "282fbfca-eaae-4791-92e6-7ab34387f495",
              "SpecificationWizardInstanceId": null,
              "Specification": "Each subchapter must inform the board of IWCA Brazil about the use of the seal",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": null,
              "SpecificationWizardInstanceId": null,
              "Specification": "Each subchapter must request from the Board the issuance of the certificates for verified producers",
              "SustainabilitySDGDtos": []
            },
            {
              "SpecificationWizardId": null,
              "SpecificationWizardInstanceId": null,
              "Specification": "IWCA Brasil Executive Secretariat issues the certificates in a period of at least 30 days",
              "SustainabilitySDGDtos": []
            }
          ]
        }
      ],
      "SustainabilitySDGDtos": [],
      "SustainabilitySpecificationDto": null
    }
  ],
  "SustainabilityHierarchyType": "Group",
  "SustainabilityGroupId": "424d2cbd-275a-4d99-a6c2-6dda6131698d",
  "OwnerOrganizationid": "914b3dcb-6b72-4b69-992e-f5aba2a45ebb",
  "Name": "IWCA",
  "SustainabilityGroupType": "Group",
  "Level": "1",
  "Products": [
    {
      "id": null,
      "Label": null,
      "ProductName": "All",
      "TokenName": null,
      "ProductOwnerType": "BextMaster",
      "ProductDatas": [],
      "Images": [],
      "type": null,
      "LocationId": null,
      "DateCreated": "2019-08-08T17:48:57.6302297Z",
      "DateModified": "2019-08-08T17:48:57.6302309Z",
      "UiData": null,
      "BextDocumentSummaries": [],
      "OwnerOrganizationId": null,
      "Deleted": false
    }
  ]
};

export const ImpactArea = {
  "Name": "Planet",
  
}