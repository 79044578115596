import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateRequisitionComponent } from './create-requisition.component';
import { MatIconModule, MatFormFieldModule, MatDialogModule, MatButtonModule, MatInputModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule 
  ],
  declarations: [CreateRequisitionComponent],
  exports: [CreateRequisitionComponent],
  entryComponents: [CreateRequisitionComponent]
})
export class CreateRequisitionModule { }
