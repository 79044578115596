import { environment } from '@env/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DndModule } from "ngx-drag-drop";
import { CoreV2Module } from './core-v2/core-v2.module';
// import { NbThemeModule } from '@nebular/theme';
import { Core2Module } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { SharedV2Module } from './shared-v2/shared-v2.module';
import { Ng2OdometerModule } from 'ng2-odometer';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    SharedV2Module,
    CoreModule,
    CoreV2Module,
    FormsModule,
    DndModule,
    Core2Module.forRoot(),
    ThemeModule.forRoot(),
    Ng2OdometerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY,
      libraries: ['places', 'visualization']
    })
    ],
  bootstrap: [AppComponent]
})
export class AppModule {}
