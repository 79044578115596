import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { GoogleMapService } from '@app/core/services/google-map.service';
import { MapStyleService } from '@app/features/maps/shared/map-style.service';
import { replaceSubstring } from '../utils/regex-utils';

declare var google: any;

@Component({
  selector: 'bext-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.css']
})
export class GmapComponent implements OnInit {

  private _markers;

  @Input() reverseMarkerPath = false;
  @Input()
    set markers(markers: any[]) {
      this._markers = markers;
    }

    get markers() {
      return this._markers;
    }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log('changes: ', changes);
  }

  constructor(private googleMapService: GoogleMapService, private styleService: MapStyleService) { }

  public styles = [
    { key: 'colorful', name: 'Colorful', url: '/maps/colorful.json' },
    { key: 'greyscale', name: 'Greyscale', url: '/maps/greyscale.json' },
    { key: 'metro', name: 'Metro', url: '/maps/metro.json' },
    { key: 'mono-color', name: 'Mono-color', url: '/maps/mono-color.json' },
    { key: 'monochrome', name: 'Monochrome', url: '/maps/monochrome.json' },
    { key: 'nightvision', name: 'Nightvision', url: '/maps/nightvision.json' },
    { key: 'nightvision-highlight', name: 'Nightvision Highlight', url: '/maps/nightvision-highlight.json'},
    { key: 'old-paper', name: 'Old Paper', url: '/maps/old-paper.json' }
  ];

  public iconBase = 'http://maps.google.com/mapfiles/kml/paddle/'

  public iconSet = [
    this.iconBase + 'blu-circle.png',
    this.iconBase + 'orange-circle.png',
    this.iconBase + 'grn-circle.png',
    this.iconBase + 'red-circle.png',
    this.iconBase + 'ylw-circle.png',
    this.iconBase + 'purple-circle.png',
    this.iconBase + 'ltblu-circle.png'
  ];

  public icons = {};

  public activeStyle: any;

  public map: any;

  ngOnInit() {
    var markerPathCoordinates = [];
    this.activeStyle = this.styles[2];
    // console.log('map style: ', this.activeStyle);
    this.googleMapService.loadAPI.then((google: any) => {
      var initMap = {
        center: new google.maps.LatLng(this.markers[this.markers.length - 1].lat, this.markers[this.markers.length - 1].lng),
        zoom: 7,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      };

      this.map = new google.maps.Map(document.getElementById('map'), initMap);
      // this.fetchStyle(this.activeStyle)
      if (this.reverseMarkerPath) {
        this.markers.reverse();
      }

      
      this.icons = this.createIconsObject(this.markers);
      
      // this.markers.reduce((prev, curr) => {
      //   let nodeDetailType = replaceSubstring(curr.nodeDetailType, curr.nodeType);
      //   if (!prev[nodeDetailType]) {
      //     if (iconCounter > this.iconSet.length - 1) {
      //       iconCounter = 0;
      //       prev[nodeDetailType] = { name: nodeDetailType, icon: this.iconSet[iconCounter]};
      //     }
      //     else {
      //       prev[nodeDetailType] = { name: nodeDetailType, icon: this.iconSet[iconCounter]};
      //       iconCounter++;
      //     }
      //   }
      //   return prev;
      // },{})

      var legend = document.getElementById('legend');
      for (var key in this.icons) {
        var type = this.icons[key];
        var name = type.name;
        var icon = type.icon;
        var div = document.createElement('div');
        div.style.display = "flex";
        div.style.justifyContent = "center";
        div.style.alignItems = "center";
        div.style.flexDirection = "column";
        div.style.paddingLeft = "10px";
        div.style.paddingRight = "10px";
        div.innerHTML = '<img src="' + icon + '" height="20" width="20">' + name;
        legend.appendChild(div);
      }

      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(legend);

      var bounds = new google.maps.LatLngBounds();
      var infoWindow = new google.maps.InfoWindow();
      
      for (let i = 0; i < this.markers.length; i++) {
        const marker = this.markers[i];
        console.log('marker to add: ', marker);
        this.addMarker(this.map, marker, bounds, infoWindow);
        markerPathCoordinates.push({lat: marker.lat, lng: marker.lng})
      };

      if (markerPathCoordinates.length > 1) {
        var lineSymbol = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 3,
          strokeColor: '#FF0000'
        };
  
        var markerPath = new google.maps.Polyline({
          path: markerPathCoordinates,
          icons: [{
            icon: lineSymbol,
            offset: '100%'
          }],
          // geodesic: true,
          strokeColor: '#FFB4B4',
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: this.map
        });
  
        this.animateArrow(markerPath);
        this.map.fitBounds(bounds);
      }

    },
    error => {
      console.log('error getting gmap: ', error);
    });
  }

  createIconsObject(markers) {
    var iconCounter = 0;
    return markers.reduce((prev, curr) => {
      let nodeDetailType = replaceSubstring(curr.nodeDetailType, curr.nodeType);
      if (!prev[nodeDetailType]) {
        if (iconCounter > this.iconSet.length - 1) {
          iconCounter = 0;
          prev[nodeDetailType] = { name: nodeDetailType, icon: this.iconSet[iconCounter] };
          iconCounter++;
        }
        else {
          prev[nodeDetailType] = { name: nodeDetailType, icon: this.iconSet[iconCounter] };
          iconCounter++;
        }
      }
      return prev;
    }, {})
  }

  addMarker(map, mkr, bounds, infoWindow) {
    const city = (mkr.location.City && mkr.location.City !== null) ? mkr.location.City + ', ' : '';
    const state = (mkr.location.State && mkr.location.State !== null) ? mkr.location.State + ', ' : '';
    const country = (mkr.location.Country && mkr.location.Country !== null) ? mkr.location.Country : '';
    const locationName = city + state + country;

    var latLng = new google.maps.LatLng((mkr.lat + (Math.random() / 25)), (mkr.lng + (Math.random() / 25)));

    var newMarker = new google.maps.Marker({
      position: latLng,
      map: map,
      title: mkr.name,
      icon: this.icons[replaceSubstring(mkr.nodeDetailType, mkr.nodeType)].icon
    });

    bounds.extend(newMarker.position);

    (function (newMarker) {
      google.maps.event.addListener(newMarker, "click", function (e) {
        //Create and open InfoWindow.
        infoWindow.setContent(
          '<div class="row">' +
            '<div class="col-xs-12">' +
              '<div><h2>' + mkr.nodeName + '</h2></div>' +
            '</div>' +
          '</div>' +
          '<div class="row" style="max-height: 200px; overflow: auto;">' +
            '<div class="col-xs-6">' +
              '<img style="max-width: 100%; height: auto; padding: 10px 0;" src="' + mkr.content.ImageUrl + '" alt="" />' +
            '</div>' +
            '<div class="col-xs-6">' +
              '<p style="font-weight: bold; color: red;">' + (mkr.content.AdditionalNote ? mkr.content.AdditionalNote : '') + '</p>' +
              '<p>Location: ' + locationName + '</p>' +
              '<p>Certifications: ' + mkr.content.Certifications + '</p>' +
              '<p>Sustainability Score: ' + mkr.content.SustainabilityScore + '</p>' +
              '<p>Number Of Workers: ' + mkr.content.NumberOfWorkers + '</p>' +
              '<p>Male/Female: ' + mkr.content.PersonY + '/' + mkr.content.PersonX + '</p>' +
              '<p>Languages: ' + mkr.content.Languages + '</p>' +
              '<p>Date Opended: ' + mkr.content.DateOpened + '</p>' +
              // '<p>Summary: ' + mkr.content.Summary + '</p>' +
            '</div>' +
          '</div>'
        );
        infoWindow.open(map, newMarker);
      });
    })(newMarker);
    
    return newMarker;

  }

  animateArrow(line) {
    var count = 0;
    window.setInterval(function () {
      count = (count + 1) % 200;

      var icons = line.get('icons');
      icons[0].offset = (count / 2) + '%';
      line.set('icons', icons);
    }, 20);
  }

  setStyle(style) {
    this.activeStyle = style;
    this.fetchStyle(style)
  }

  fetchStyle(style) {
    this.styleService.fetchStyle(style).subscribe((styleDef) => {
      this.map.mapTypes.set(style.key,
        new google.maps.StyledMapType(styleDef,
          { name: style.name })
      );
      this.map.setMapTypeId(style.key);
    })
  }

}
