import { Observable } from 'rxjs';

export interface RecyclingActive {
  date: string;
  pagesVisitCount: number;
  deltaUp: boolean;
  newVisits: number;
}

export abstract class RecyclingActivityData {
  abstract getRecyclingActivityData(period: string): Observable<RecyclingActive[]>;
}
