/*****************************************/
/********* EMAIL CONTAINS DOMAIN **********/
/*****************************************/
//*** check if @param: emailaddressstring contains @param: word

export function checkIfEmailDomainIsDev(string) {
  var str = string;
  var pattern = `@(test|testcorp|cottoncorp|testorg|coffeecorp|cocoacorp|katalon|honeycorp|procnottoc).*`;
  var re = new RegExp(pattern, "i");
  var match = str.match(re) !== null ? true : false;
  return match;
}

export function checkIfEmailDomainIsNespresso(string) {
  var str = string;
  var pattern = `@(nespresso|nespresso01|nespresso02).*`;
  var re = new RegExp(pattern, "i");
  var match = str.match(re) !== null ? false : false;
  return match;
}

export function checkIfEmailDomainIsPvh(string) {
  var str = string;
  var domain = str.replace(/.*@/, "");
  var match = domain.indexOf("pvh") >= 0 ? true : false;
  return match;
}

export function checkIfEmailDomainMatchesAppUser(domain_name, email_address) {
  var str = email_address;
  var domain = str.replace(/.*@/, "");
  var match = domain.indexOf(domain_name) >= 0 ? true : false;
  // console.group('TEST EMAIL ADDRESS HAS DOMAIN');
  // console.log('email address: ', str);
  // console.log('check if email domain is: ', domain_name);
  // console.log('match: ', match);
  // console.groupEnd();
  return match;
}

export function replaceSubstring(
  string,
  substring,
  replaceWith?,
  regexPatterns?
) {
  var re = new RegExp(substring, regexPatterns ? regexPatterns : "gi");
  let match = string.replace(re, replaceWith ? replaceWith : "");
  return match;
}
