import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { takeWhile, take, map, mergeMap, tap, switchMap, skip } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bext-person-dialog',
  templateUrl: './person-dialog.component.html',
  styleUrls: ['./person-dialog.component.scss']
})
export class PersonDialogComponent implements OnInit {

  private _person: any;
  @Input()
  set person(val) {
    this._person = val;
  }
  get person() {
    return this._person;
  }

  private _eventLoading: BehaviorSubject<any> = new BehaviorSubject<any>({data: undefined, loading: false, complete: false});
  get eventLoading() {
    return this._eventLoading;
  }
  get eventLoading$() {
    return this._eventLoading.asObservable();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PersonDialogComponent>,
  ) { }

  ngOnInit() {
    if (this.data && this.data.person) {
      this.person = this.data.person;
    }
  
    this.eventLoading$
      .pipe(
        skip(1)
      )
      .subscribe(
        res => {
          console.log('%c event loading: ', 'background: #fae552; color: #323232;', res);
          if (res.loading === false && res.complete) this.dialogRef.close(res)
        }
      )

  }

  personActionEvent(e) {
    switch (e.eventType) {
      case 'CANCEL':
        this.dialogRef.close()
        break;

      case 'CRUD':
        let eventLoadingState = this.eventLoading.getValue();
        eventLoadingState.loading = e.loading;
        eventLoadingState.data = e.data;
        eventLoadingState.complete = e.loading === false;
        this.eventLoading.next(eventLoadingState)
        break;
    
      default:
        break;
    }

  }

}
