import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateNodeComponent } from '@app/shared/nodes/create/create-node.component';
import { SmartadminFormsModule } from '../forms/smartadmin-forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmartadminWidgetsModule } from '../widgets/smartadmin-widgets.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DndModule } from 'ngx-drag-drop';
import { LocationService } from '@app/core/services/backend/locations.service';
import { OrganizationService } from '@app/core/services/backend/organization.service';
import { NodeService } from '@app/core/services/backend/node.service';
import { BootstrapModule } from '../bootstrap.module';
import { LocationModule } from '@app/features/setup/location/location.module';
import { OrganizationsModule } from '@app/features/setup/organizations/organizations.module';
import { SharedLocationModule } from '../location/shared-location.module';
import { SharedOrganizationModule } from '../organization/shared-organization.module';
import { TreeviewModule } from 'ngx-treeview';
import { UiMetadataComponent } from '@app/shared/nodes/metadata/ui-metadata.component';
import {MarkerComponent} from '@app/shared/nodes/markers/marker.component';
import { EditCopyNodeFormComponent } from '@app/shared/nodes/edit-copy-form/edit-copy-form.component';
import { UtilsModule } from '../utils/utils.module';
import { SusGroupModalComponent } from './sus-group-modal/sus-group-modal.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { WizardInstanceModule } from '../master-wizard/wizard-instance/wizard-instance.module';
import { SearchbarModule } from '../searchbar/searchbar.module';
import { PipesModule } from '../pipes/pipes.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatButtonModule, MatSelect, MatSelectModule, MatCheckboxModule, MatInputModule } from '@angular/material';
import {HelpService} from "@app/core/services/backend/help.service";
import { UiMetadataCustomFieldDialogModule } from '@app/shared-v2/dialogs/ui-metadata-custom-field-dialog/ui-metadata-custom-field-dialog.module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SmartadminFormsModule,
    FormsModule,
    SmartadminWidgetsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    DndModule,
    BootstrapModule,
    SharedLocationModule,
    SharedOrganizationModule, 
    SmartadminFormsModule,
    LoadingSpinnerModule,
    WizardInstanceModule,
    SearchbarModule,
    PipesModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    UiMetadataCustomFieldDialogModule
  ],
  providers: [
    {provide: MatDialogRef, useValue: {}}, {provide: MAT_DIALOG_DATA, useValue: {}}, HelpService
    // LocationService,OrganizationService, NodeService
  ],
  entryComponents: [MarkerComponent],
  declarations: [CreateNodeComponent, UiMetadataComponent, MarkerComponent, EditCopyNodeFormComponent, SusGroupModalComponent],
  exports: [CreateNodeComponent, UiMetadataComponent, MarkerComponent, EditCopyNodeFormComponent, SusGroupModalComponent]
})
export class SharedNodesModule { }
