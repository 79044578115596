import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollSectionComponent } from './scroll-section.component';
import { MatListModule, MatIconModule, MatButtonModule, MatTooltipModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { PipesV2Module } from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    RouterModule,
    PipesV2Module
  ],
  declarations: [ScrollSectionComponent],
  exports: [ScrollSectionComponent]
})
export class ScrollSectionModule { }
