import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldFilterPipe } from './field-filter.pipe';
import { MomentPipe } from './moment.pipe';
import { SafePipe } from '@app/shared/pipes/safe.pipe';
import { ClickStopPropagation, ChangeStopPropagation } from '@app/shared/pipes/event-stop-propagation.pipe';
import { KeysPipe } from '@app/shared/pipes/keys.pipe';
import { TextSearchPipe } from '@app/shared/pipes/text.pipe';
import { OrderBy } from '@app/shared/pipes/orderby.pipe';
import { FilterByStatusPipe } from '@app/shared/pipes/filter-by-status.pipe';
import { SearchFilterPipe } from '@app/shared/pipes/search-filter.pipe';

@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
      FieldFilterPipe,
      MomentPipe,
      SafePipe,
      ClickStopPropagation,
      ChangeStopPropagation,
      KeysPipe,
      TextSearchPipe,
      OrderBy,
      FilterByStatusPipe,
      SearchFilterPipe,
   ],
   exports: [
      FieldFilterPipe,
      MomentPipe,
      SafePipe,
      ClickStopPropagation,
      ChangeStopPropagation,
      KeysPipe,
      TextSearchPipe,
      OrderBy,
      FilterByStatusPipe,
      SearchFilterPipe,
   ]
})
export class PipesModule { }
