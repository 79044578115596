import { Component, AfterViewInit } from '@angular/core';
import { ElementRef } from "@angular/core";

import jarvisWidgetsDefaults from '../widgets-grid.defaults';

import 'smartadmin-plugins/smartwidgets/jarvis.widget.ng2.js'

declare var $: any;

@Component({

  selector: 'bext-widgets-grid',
  templateUrl: './widgets-grid.component.html',
  styleUrls: ['./widgets-grid.component.html']
})
export class WidgetsGridComponent implements AfterViewInit {

  constructor(public el: ElementRef) { }

  ngAfterViewInit() {
    $('#widgets-grid', this.el.nativeElement).jarvisWidgets(jarvisWidgetsDefaults);
  }

}
