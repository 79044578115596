import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'absorbed-token-table',
  templateUrl: './absorbed-token-table.component.html',
  styleUrls: ['./absorbed-token-table.component.css']
})
export class LotAbsorbedTokenTableComponent implements OnInit {
  @Input() tokens: Array<any>
  constructor() { }

  ngOnInit() {
  }

}
