import {Component, OnInit} from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { LayoutService } from '@app/core/services/layout.service';
import { BextAuthenticationService } from '@app/core/services';
import { AppUserService } from '@app/core/services/backend/app-user.service';

@Component({
  selector: 'sa-login-info',
  templateUrl: './login-info.component.html',
})
export class LoginInfoComponent implements OnInit {

  public currentUser = {
    username: 'carey',
    permissions: [
      'can_read',
      'can_write'
    ],
    appUser: null,
    msalUserObj: null
  }

  constructor(
    public us: UserService,
    private layoutService: LayoutService,
    private authService: BextAuthenticationService,
    private appUserService: AppUserService
    ) {
  }

  ngOnInit() {
    this.currentUser.msalUserObj = this.authService.getUser();
  }

  toggleShortcut() {
    this.layoutService.onShortcutToggle()
  }

}
