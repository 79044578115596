import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { BextAuthenticationService } from './core/services';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: [``]
})
export class AppComponent {

  isAuthenticated = false;

  constructor(
    private router: Router,
    private authService: BextAuthenticationService
  ) {
    
  }

  ngOnInit() {
    if(!this.isOnline) {
      this.router.navigate(['/auth/login']);
    }

  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  get user(): string {
    const user = this.authService.getUser();
    if (!user) { return null; }
    return user.name;
  }

  get isOnline(): boolean {
    return this.authService.isOnline();
  }
  


}
