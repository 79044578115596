import { LotService } from '@app/core/services/backend/lot.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, Input, Inject, ViewChild } from '@angular/core';
import { ReportsService } from '@app/core/services/backend/reports.service';
import {tap, last, flatMap, switchMap, takeWhile } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { combineLatest } from 'rxjs';
import { NodeService } from '@app/core/services/backend/node.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {BextAuthenticationService, UserService} from "@app/core/services";
import {AppUserService} from "@app/core/services/backend/app-user.service";
import {OrganizationService} from "@app/core/services/backend/organization.service";
import { PhotoGalleryComponent } from '@app/shared-v2/photo-gallery/photo-gallery.component';
import { GALLERY_IMAGE, GALLERY_CONF } from '@app/shared-v2/photo-gallery/models/photo-gallery.conf';
import { isNullOrUndefined } from 'util';

export interface TreeModel {
  requestStatus: RequestStatus;
  data?: (DataEntity)[] | null;
}
export interface RequestStatus {
  loading: boolean;
  requestCount: number;
}
export interface DataEntity {
  LotMetaData: LotMetaData;
  AbsorbedFromTransferLotIds?: (string | null)[] | null;
  TransferredToTransferLotIds?: (string | null)[] | null;
}
export interface LotMetaData {
  LotId: string;
  LotName: string;
  LotType: string;
  LotDetailType: string;
  Location: Location;
  LocationId?: string | null;
  LotStatus: LotStatus;
  NodeId: string;
  SupplyChainId: string;
  OrganizationId: string;
  LotBeginDateTime?: string | null;
  LotEndDateTime?: string | null;
  LotStartWeight: LotStartWeightOrLotCurrentWeightOrLotEndWeight;
  LotCurrentWeight: LotStartWeightOrLotCurrentWeightOrLotEndWeight;
  LotCurrentValue: LotCurrentValue;
  LotEndWeight: LotStartWeightOrLotCurrentWeightOrLotEndWeight;
  QualityScore: LotStartWeightOrLotCurrentWeightOrLotEndWeightOrQualityScore;
  Sustainabilities?: (null)[] | null;
  PersonIds?: null;
  Tier?: string | null;
  Product?: null;
  LotBeginDateTimeDt: string;
  LotEndDateTimeDt: string;
}
export interface Location {
  id: string;
  LocationId?: null;
  Name: string;
  Country: string;
  City: string;
  State: string;
  Latitude: number;
  Longitude: number;
  Elevation: number;
  ElevationUnit?: null;
  Address: string;
  Description: string;
  OrganizationName?: null;
  OrganizationId?: null;
  type: string;
  DateCreated: string;
  DateModified: string;
  UiData?: null;
  BextDocumentSummaries?: (null)[] | null;
  OwnerOrganizationId: string;
  Deleted: boolean;
}
export interface LotStatus {
  Open: boolean;
  Full: boolean;
}
export interface LotStartWeightOrLotCurrentWeightOrLotEndWeight {
  AttributeTypeId: number;
  Type?: string | null;
  Default: boolean;
  Name?: string | null;
  Method?: string | null;
  Group?: string | null;
  Unit: string;
  Measure: string;
  StartMeasureTime?: string | null;
  EndMeasureTime?: string | null;
  MeasureTime?: string | null;
  StartMeasureTimeDt: string;
  EndMeasureTimeDt: string;
  MeasureTimeDt: string;
}
export interface LotCurrentValue {
  AttributeTypeId: number;
  Type?: null;
  Default: boolean;
  Name?: null;
  Method?: null;
  Group?: null;
  Unit?: null;
  Measure?: string | null;
  StartMeasureTime?: null;
  EndMeasureTime?: null;
  MeasureTime?: null;
  StartMeasureTimeDt: string;
  EndMeasureTimeDt: string;
  MeasureTimeDt: string;
}
export interface LotStartWeightOrLotCurrentWeightOrLotEndWeightOrQualityScore {
  AttributeTypeId: number;
  Type: string;
  Default: boolean;
  Name: string;
  Method: string;
  Group: string;
  Unit: string;
  Measure: string;
  StartMeasureTime: string;
  EndMeasureTime: string;
  MeasureTime: string;
  StartMeasureTimeDt: string;
  EndMeasureTimeDt: string;
  MeasureTimeDt: string;
}

const actionMapping: IActionMapping = {
  mouse: {
    contextMenu: (tree, node, $event) => {
      $event.preventDefault();
      alert(`context menu for ${node.data.name}`);
    },
    dblClick: (tree, node, $event) => {
      if (node.hasChildren) {
        TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
      }
    },
    click: (tree, node, $event) => {
      $event.shiftKey
        ? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event)
        : TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
    }
  },
  keys: {
    [KEYS.ENTER]: (tree, node, $event) => alert(`This is ${node.data.name}`)
  }
};




@Component({
  selector: 'lot-drilldown',
  templateUrl: './lot-drilldown.component.html',
  styleUrls: ['./lot-drilldown.component.css']
})
export class LotDrilldownV2Component {

  private alive = true;

  @Input() lotID: string = '';
  loadingMessage;
  activeStep = 0;
  activeTab: any = 'ss0';
  initialLoad = true;
  initialLotHistory;
  loadLot = false;
  options: ITreeOptions = {
    actionMapping
  };
  nodes: any[] = [];
  selectedLot;
  selectedLotTokens;
  selectedLotSummaryV2;
  selectedNode;
  selectedImages = [];
  currentOrganization;
  initialLotSelected;
  wait = false;
  selectedIndex = 0;
  selectedTab = {
    prev: null,
    curr: this.selectedIndex
  };

  @ViewChild(PhotoGalleryComponent) photoGallery: PhotoGalleryComponent;

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
    reactToMouseWheel: false
  };

  constructor(private nodeService: NodeService, private lotService: LotService, private reportService: ReportsService, private route: ActivatedRoute, private spinnerService: NgxSpinnerService, public dialogRef: MatDialogRef<LotDrilldownV2Component>, @Inject(MAT_DIALOG_DATA) public data: any, private authService: BextAuthenticationService, private appUserService: AppUserService, private organizationService: OrganizationService ) {

  }


  ngOnInit() {
    // this.spinnerService.show();
    // let lot_id = this.data && this.data.lotID ? this.data.lotID : '';

    this.authService.AppUserState$
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        res => {
          console.log('app user state: ', res);
          if (res.organization !== null) {
            this.currentOrganization;
          }
          return;
         },
        error => {
          console.log('app user state - error: ', error);
          
          return;
        }
      );
      

    let lot_id = this.route.snapshot.paramMap.get('lot_id');
    //  let msalUserObj = this.authService.getUser();
    //   if (msalUserObj) {
    //     var ownerOrganization$ = this.appUserService.getUser(msalUserObj.idToken['oid']).switchMap(user => {
    //       return this.organizationService.getOrganization(user.OwnerOrganizationId)
    //     });
    //     ownerOrganization$.subscribe(org => { this.currentOrganization = org; this.spinnerService.hide();});
    //   }
    this.reportService.getLotHistoryReport(lot_id, 5, 20)
    //     .subscribe(res => {
    //   console.log(res);
    // });
    // if (lot_id && lot_id.length) {
    // this.reportService.getSimpleLotsRecursive(lot_id);
    // this.reportService.simpleLots$.pipe(
    //   last(),
    //   tap(lots => console.log('TAP lots: ', lots))
    // )
        .subscribe(
            res => {
              this.initialLotHistory = res;
              // this.lotSelected(this.initialLotHistory.lotHistoryDtos[0]);
              // this.lotSelected(this.initialLotHistory);
              // console.log('%c get simple lots: ', 'background: #41ff6b; color: #ff4700;', res);
              // let flatNodeList = [res].reduce((prev, curr, i) => {
              //   let treeNode = {
              //     id: curr.LotId,
              //     node_id: curr.NodeId,
              //     name: curr.LotName,
              //     isExpanded: true,
              //     parent: curr.lotHistoryDtos.length ? curr.lotHistoryDtos[0] : 0
              //   };
              //   prev.push(treeNode);
              //   return prev;
              // }, []);
              // this.nodes = this._getNestedChildren(flatNodeList, 0);
              // console.log(this.nodes);
              // this.lotSelected(this.nodes[0]);
              this.spinnerService.hide();
              this.initialLoad = false;
            }, err => {
              console.error(err);
            })
    // }
  }

  ngOnChanges() {
    // if (this.data && this.data.lotID) {
    //   this.ngOnInit();
    // }
  }

  _getNestedChildren(arr, parent) {
    var out = []
    for (var i in arr) {
      if (arr[i].parent == parent) {
        var children = this._getNestedChildren(arr, arr[i].id)
        if (children.length) {
          arr[i].children = children
        }
        else {
          arr[i].isExpanded = false;
        }
        out.push(arr[i])
      }
    }
    return out;
  }

  lotSelected(event) {
    console.log('lot selected event: ', event);
    setTimeout(() => {
      this.selectedImages = isNullOrUndefined(event.Images) ? [] : event.Images.map((img) => ({ url: img.ImageURL, altText: img.ImageName, title: img.ImageName, thumbnailUrl: img.ImageURL}));
    });
    if (event && event['_fetched']) {
      this.selectedLot = event['_lot'];
      this.selectedNode = event['_node'];
      this.selectedLotTokens = event['_lotTokens'];
      this.selectedLotSummaryV2 = event['_lotSummary'];
      return;
    }
    else if (event) {
      setTimeout(() => {
        this.initialLotSelected = event;
      })

      // this.loadLot = true;
    // else {
      this.getSummary();
        this.loadLot = true;
        const getLot$ = this.lotService.getLot(event.LotId);
        const node$ = this.nodeService.getNodeById(event.NodeId);
        const tokens$ =  this.lotService.getLotTokensV2(event.LotId)
        combineLatest(
            getLot$,
            node$,
            tokens$
        ).subscribe(
            ([lot, node, tokens]: any) => {
              if (this.initialLotSelected.LotId == lot.id) {
                this.selectedLot = lot;
                event['_lot'] = lot;
                this.selectedNode = node;
                event['_node'] = node;
                this.selectedLotTokens = tokens;
                event['_lotTokens'] = tokens;

                let currentNodeTemplate = node && node.UiData && node.UiData.length ? node.UiData[0] : {Sections: []};
                currentNodeTemplate.Sections.forEach(section => {
                  section.Fields.forEach(field => {
                    if (field.name.indexOf('LotMetaData') > -1) {
                      if (field.name.split('.').length == 2) {
                        field.value = this.selectedLot.LotMetaData[field.name.split('.')[1]]
                      } else if (field.name.split('.').length == 3) {
                        field.value = this.selectedLot.LotMetaData[field.name.split('.')[1]][field.name.split('.')[2]];
                      } else if (field.name.split('.').length == 4) {
                        field.value = this.selectedLot.LotMetaData[field.name.split('.')[1]][field.name.split('.')[2]][field.name.split('.')[3]];
                      }
                      if (field.value != '') {
                        field.show = true;
                      }
                    } else {
                      let found = this.selectedLot.LotDatas.find(lotData => {
                        return lotData.Name == field.name.split('.')[0];
                      });
                      if (found) {
                        field.value = found[field.name.split('.')[1]];
                        if (field.value != '') {
                          field.show = true;
                        }
                      }
                    }
                  })
                });
                event['_node']['_currentNodeTemplate'] = currentNodeTemplate;
                event['_fetched'] = true;
                this.loadLot = false;
              }
              // console.log('%c node: ', 'background: #41ff6b; color: #ff4700;', node);
            },
            error => {
              console.log('%c GET lot failed: ', 'background: #ff0000; color: #ffffff;', error);
              this.loadLot = false;
            }
        )
    }
  }

  nextStep() {
    if (this.activeStep < this.selectedNode._currentNodeTemplate.Sections.length - 1) {
      this.activeStep++;
    }
  }

  prevStep() {
    if (this.activeStep > 0) {
      this.activeStep--;
    }
  }

  setActiveStep(i) {
    this.activeStep = i;
  }

  getTokens() {
    if (!this.selectedLot['_lotTokens']) {
      this.lotService.getLotTokensV2(this.selectedLot.id).subscribe(res => {
        this.selectedLotTokens = res;
        this.selectedLot['_lotTokens'] = res;

      })
    } else {
      this.selectedLotTokens = this.selectedLot['_lotTokens'];
    }
  }

  getSummary() {
    if (!this.selectedLot || !this.selectedLot['_lotSummary']) {
      console.log('%c SELECTED LOT: ', 'background: #fae552; color: #323232;', this.selectedLot);
      this.lotService.getLotSummary('v2/' + this.selectedLot.id).subscribe(res => {
        this.selectedLotSummaryV2 = res;
        this.selectedLot['_lotSummary'] = res;
      })
    } else {
      this.selectedLotSummaryV2 = this.selectedLot['_lotSummary'];
    }
  }

  getSustainability() {

  }

  tabChange(event) {
    this.selectedIndex = event.index;
    this.selectedTab.prev = this.selectedTab.curr;
    this.selectedTab.curr = this.selectedIndex;
    console.log('selected tab: ', this.selectedTab);
    if (this.selectedTab.curr === 5) {
      this.openGallery(); 
    }
  }

  manualTabChange(selectedTab) {
    this.selectedIndex = selectedTab.prev;
  }

  openGallery(index: number = 0) {
    this.photoGallery.open(index);
  }

  // close gallery
  closeGallery() {
    this.photoGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.photoGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.photoGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.photoGallery.prev();
  }

  /**************************************************/

  // EVENTS
  // callback on gallery opened
  galleryOpened(index) {
    console.info('Gallery opened at index ', index);
  }

  // callback on gallery closed
  galleryClosed() {
    console.info('Gallery closed.');
    this.selectedIndex = this.selectedTab.prev;
    
  }

  // callback on gallery image clicked
  galleryImageClicked(index) {
    console.info('Gallery image clicked with index ', index);
  }

  // callback on gallery image changed
  galleryImageChanged(index) {
    console.info('Gallery image changed to index ', index);
  }

  // callback on user clicked delete button
  deleteImage(index) {
    console.info('Delete image at index ', index);
  }

  ngOnDestroy() {
    this.alive = true;
  }
}
