import { Component, OnInit, DoCheck, ViewChild, Inject } from "@angular/core";

import { SupplyChainService } from "@app/core-v2/services";
import { OrganizationService } from "@app/core-v2/services";
import { NodeService } from "@app/core-v2/services";
import { NotificationService } from "@app/core-v2/services";

import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap";
import { DndDropEvent } from "ngx-drag-drop";
import { fromEvent } from "rxjs";
import { map, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "bext-create-supplychain",
  templateUrl: "./create-supply-chain.component.html",
  styleUrls: ["./create-supply-chain.component.scss"],
  animations: [
    trigger("changePane", [
      state(
        "out",
        style({
          height: 0,
        })
      ),
      state(
        "in",
        style({
          height: "*",
        })
      ),
      transition("out => in", animate("250ms ease-out")),
      transition("in => out", animate("250ms 300ms ease-in")),
    ]),
  ],
})
export class CreateSupplyChainComponent implements OnInit, DoCheck {
  @ViewChild("scName") scName: any;

  nodeCounter = 0;
  organizations: any[] = [];
  nodes: any[] = [];
  nodeDetails: any[] = [];

  nodesDropDownOptions: string;
  selectedOrg: any;
  // source = [ 'Pawn', 'Rook', 'Knight', 'Bishop', 'Queen', 'King' ];
  target = [];
  nodeList;
  filterString = "";
  completeNodeList = [];
  nestableListItems: any[] = [];

  createNodeModalVisible = false;

  forbiddenSupplyChainNames: any[] = [];
  showDuplicateSupplyChainNameError = false;

  public model = {
    SupplyChainName: "",
    // OperatorOrganizationName: "",
    TestAccount: false,
    FundFromAccountName: "",
    FundingAmount: 2000,
    OrganizationList: [],
    NodeTypeList: [],
    NodeDetailTypeList: [],
    SupplyChainNodes: [],
    NodeNames: "",
  };

  @ViewChild("createNodeModal") public createNodeModal: ModalDirective;

  constructor(
    private organizationService: OrganizationService,
    private nodeService: NodeService,
    private supplyChainService: SupplyChainService,
    private notificationService: NotificationService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<CreateSupplyChainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.organizationService.getOrganizations().subscribe(
      (res) => {
        this.organizations = res;
      },
      (err) => {
        console.log(
          "%c Error - failed to GET organizations: ",
          "background: #fae552; color: #323232;",
          err
        );
      }
    );

    this.supplyChainService.getSupplychains().subscribe((res) => {
      this.forbiddenSupplyChainNames = res
        .filter((sc) => sc.DefaultSupplyChain)
        .map((n) => n.SupplyChainName.toLowerCase());
    });

    this.getNodes();
  }

  ngAfterViewInit(): void {
    fromEvent<any>(this.scName.nativeElement, "keyup")
      .pipe(
        map((event) => event.target.value.toLowerCase()),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((scn) => {
        if (this.forbiddenSupplyChainNames.indexOf(scn) !== -1) {
          this.steps[0].valid = false;
          this.showDuplicateSupplyChainNameError = true;
        } else {
          this.steps[0].valid = true;
          this.showDuplicateSupplyChainNameError = false;
        }
      });
  }

  public steps = [
    {
      key: "step1",
      title: "Supply Chain Name",
      valid: false,
      checked: false,
      submitted: false,
    },
    {
      key: "step2",
      //   title: 'Select Operator Organization',
      //   valid: false,
      //   checked: false,
      //   submitted: false,
      // },
      // {
      //   key: 'step3',
      title: "Select Nodes",
      valid: true,
      checked: false,
      submitted: false,
    },
    {
      key: "step3",
      title: "Review & Confirm",
      valid: true,
      checked: false,
      submitted: false,
    },
  ];

  public activeStep: any = this.steps[0];

  getNodes() {
    this.nodeService.getNodes().subscribe(
      (res) => {
        const nodeNameFt = (n) => n.NodeData.NodeName;
        this.nodeDetails = res.map((node) => node.NodeData);
        console.log("node details: ", this.nodeDetails);
        this.nodes = res.map(nodeNameFt);
        for (let i = 0; i < this.nodes.length; i++) {
          const nodeName = this.nodes[i];
          this.nodesDropDownOptions += "[" + nodeName + "]";
        }
      },
      (err) => {
        console.log(
          "%c Error - failed to GET nodes: ",
          "background: #fae552; color: #323232;",
          err
        );
      }
    );
  }

  onOrganizationSelected(data) {
    console.log(
      "%c on select org: ",
      "background: #41ff6b; color: #ff4700;",
      data
    );
  }

  addNodesToNodeList(event) {
    this.model.SupplyChainNodes = [];
    this.target.forEach((node, index) => {
      let nodeMatch = this.nodeDetails.find((n) => n.NodeName === node);
      this._addNodeToModel(nodeMatch, index);
    });
  }

  _addNodeToModel(node, nodeSequence) {
    console.log("node: ", node);
    var nModel = {
      NodeId: node.NodeId,
      NodeName: node.NodeName,
      NodeType: node.NodeType,
      NodeDetailType: node.NodeDetailType,
      NodeSequence: nodeSequence,
    };

    var nestableItem = {
      id: nodeSequence,
      content:
        '<div style="display: flex; justify-content: space-around; align-items: center;"><span>' +
        nodeSequence +
        "</span><span>" +
        node +
        "</span></div>",
    };

    this.model.SupplyChainNodes.push(nModel);
    this.nestableListItems.push(nestableItem);
  }

  removeNodeFromModel(index, supplychainNodes) {
    this.model.SupplyChainNodes.splice(index, 1);
    this.target.splice(index, 1);
    this.nodeCounter--;
    for (let i = 0; i < this.nodeCounter; i++) {
      var node = this.model.SupplyChainNodes[i];
      node.NodeSequence = i + 1;
    }
  }

  onNodeListChange(event) {}

  setActiveStep(step) {
    this.activeStep = step;
  }

  prevStep() {
    let idx = this.steps.indexOf(this.activeStep);
    if (idx > 0) {
      this.activeStep = this.steps[idx - 1];
    }
  }

  nextStep() {
    this.activeStep.submitted = true;
    if (!this.activeStep.valid) {
      return;
    }
    this.activeStep.checked = true;
    if (this.steps.every((it) => it.valid && it.checked)) {
      this.onWizardComplete(this.model);
    } else {
      let idx = this.steps.indexOf(this.activeStep);
      this.activeStep = null;
      while (!this.activeStep) {
        idx = idx == this.steps.length - 1 ? 0 : idx + 1;
        if (!this.steps[idx].valid || !this.steps[idx].checked) {
          this.activeStep = this.steps[idx];
        }
      }
    }
  }

  onWizardComplete(data) {
    for (let i = 0; i < data.SupplyChainNodes.length; i++) {
      const node = data.SupplyChainNodes[i];
    }

    console.log(
      "%c supplychain payload: ",
      "background: #41ff6b; color: #ff4700;",
      data
    );
    this.spinnerService.show();
    this.supplyChainService.addSupplychain(data).subscribe(
      (res) => {
        if (res === null || !res) {
          console.log(
            "%c WARNNING! - Supply Chain created but Response Body was Null or Undefined: ",
            "background: #fae552; color: #323232;",
            res
          );
          return;
        }

        console.log(
          "%c Success - server did POST supplychain: ",
          "background: #41ff6b; color: #ff4700;",
          res
        );

        return;
      },
      (err) => {
        console.log(
          "%c Error - failed to POST supplychain ",
          "background: #ff0000; color: #ffffff;",
          err
        );
        return;
      },
      () => {
        // this.dialogRef.close();
        this.spinnerService.hide();
        if (this.dialogRef) {
          this.dialogRef.close();
        }
        this.router.navigate(["/v2"]);
      }
    );
  }

  private lastModel;

  // custom change detection
  ngDoCheck() {
    if (!this.lastModel) {
      // backup model to compare further with
      this.lastModel = Object.assign({}, this.model);
    } else {
      if (
        Object.keys(this.model).some(
          (it) => this.model[it] != this.lastModel[it]
        )
      ) {
        // change detected
        this.steps.find((it) => it.key == "step1").valid = !!this.model
          .SupplyChainName;
        this.lastModel = Object.assign({}, this.model);
      }
    }
  }

  onDrop(event: DndDropEvent) {
    this.model.SupplyChainNodes.splice(event.index, 0, event.data);
  }

  onDragStart(event: DragEvent, section: any, field: any, index) {}

  onDragEnd(event: DragEvent, section: any, field, index) {}

  onDraggableMoved(event: DragEvent, item, index) {
    this.model.SupplyChainNodes.splice(index, 1);
  }

  hideCreateNodeModal() {
    this.createNodeModal.hide();
    this.createNodeModalVisible = false;
  }

  showCreateNodeModal() {
    this.createNodeModal.show();
    this.createNodeModalVisible = true;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
