import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilePreviewOverlayComponent } from './file-preview-overlay.component';
import { FilePreviewOverlayService } from './file-preview-overlay.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FilePreviewOverlayComponent],
  exports: [FilePreviewOverlayComponent],
  providers: [FilePreviewOverlayService],
  entryComponents: [FilePreviewOverlayComponent]
  
})
export class FilePreviewOverlayModule { }
