import { NodeLotsStoreService } from './node-lots-store.service';
import { GeoLocationService } from './geo-location.service';
import { PreviousRouteService } from '@app/core/services/previous-route.service';
import { StorageService } from "./storage.service";
import {
  AuthTokenFactory,
  AuthTokenService
} from "./auth-token.service";
import { BextAuthenticationService } from "./authentication.service";
import { TokenInterceptor } from "./token.interceptor";
import { JsonApiService } from "./json-api.service";
import { UserService } from "./user.service";
import { ChatService } from "./chat.service";
import { NotificationService } from "./notification.service";
import { BodyService } from "./body.service";
import { LayoutService } from "./layout.service";
import { SoundService } from "./sound.service";
import { AppUserService } from '@app/core/services/backend/app-user.service';

import * as fromVoice from './voice'

import { OcrService } from '@app/core/services/backend/ocr.service';
import { ReportsService } from '@app/core/services/backend/reports.service';
import { ApiTestService } from '@app/core/services/backend/api-test.service';
import { MarkerService } from '@app/core/services/backend/marker.service';
import { GoogleMapService } from '@app/core/services/google-map.service';
import { LocalStorageService } from './local-storage.service';
import { UiDataService } from './backend/ui-data.service';
import { ProductsService } from './backend/product.service';
import {PartnerOrganizationService} from "@app/core/services/backend/partner-organization.service";
import {HelpService} from "@app/core/services/backend/help.service";

export const services = [
  PreviousRouteService,
  StorageService,

  AppUserService,
  AuthTokenService,
  BextAuthenticationService,
  TokenInterceptor,
  JsonApiService,
  UserService,
  ChatService,
  GoogleMapService,
  MarkerService,
  NotificationService,
  BodyService,
  LayoutService,
  ApiTestService,
  OcrService,
  SoundService,
  ReportsService,
  fromVoice.VoiceControlService,
  fromVoice.VoiceRecognitionService,
  GeoLocationService,
  LocalStorageService,
  NodeLotsStoreService,
  UiDataService,
  ProductsService,
    PartnerOrganizationService,
    HelpService
];

export * from "./route-history.service";
export * from "./storage.service";
export * from "./auth-token.service";
export * from "./authentication.service";
export * from "./token.interceptor";
export * from "./json-api.service";
export * from "./user.service";
export * from "./chat.service";
export * from "./notification.service";
export * from "./body.service";
export * from "./layout.service";
export * from "./sound.service";
export * from "./voice";
export * from "./backend/ui-data.service"
