import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentService } from '@app/core/services/backend/document.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UploadService } from '@app/core/services/backend/uploads.service';
import { NodeService } from '@app/core/services/backend/node.service';
import { WizardService } from '@app/core/services/backend/wizard.service';

@Component({
  selector: 'sa-node-documents-v2',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class NodeDocumentsComponentV2 implements OnInit {

  @Input() documents: any;

  @ViewChild('documentViewerModal') documentViewerModal;
  @ViewChild('deleteDocumentModal') deleteDocumentModal;
  @ViewChild('documentDetailsModal') documentDetailsModal;
  loadingMessage;
  queryString: string = '';
  currentDocument;
  currentDocumentIdx;
  documentTemplate;
  updatingList = true;
  showAddDocuments = true;
  documentWizardInstance;
  documentWizardMap = {
    "Cotton": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Wool": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Coffee": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "EOil": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Soap": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Cocoa": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Honey": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "School": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "WatSan": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Tree": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "NRPB": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Timber": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Indigo": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Coltan": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Cobalt": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Quinoa": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Seafood": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Salt": '6c23b94a-9df3-4055-9b2f-577826a8e59e',
    "Phama": '6c23b94a-9df3-4055-9b2f-577826a8e59e'
  }

  lotDocumentWizardInstance;
  sendingDoc = false;

  constructor(private spinnerService: NgxSpinnerService, 
    private documentService: DocumentService,   
    public dialogRef: MatDialogRef<NodeDocumentsComponentV2>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private uploadService: UploadService,
    private nodeService: NodeService,
    private wizardService: WizardService) { }

  ngOnInit() {
    this.getData();
    this.documents = this.data.documents;
    if (this.documentWizardMap[this.data.currentNodeDetails.NodeData.DefaultProductType]) {
      this.wizardService.getWizardById(this.documentWizardMap[this.data.currentNodeDetails.NodeData.DefaultProductType]).subscribe(res => {
        this.documentWizardInstance = this.resetWizardInstanceFieldValues(res);
      })}
  }

  openModal(modalRef, options?) {
    let opts = {
      ignoreBackdropClick: options ? options.ignoreBackdropClick : false,
      enableEscBtn: options ? options.enableEscBtn : true
    };
    modalRef.config.ignoreBackdropClick = opts.ignoreBackdropClick;
    modalRef.config.keyboard = opts.enableEscBtn;
    modalRef.show()
  }

  closeModal(modalRef) {
    modalRef.hide()
  }

  onSelectDocument(doc) {
    console.log('document: ', doc);
  }

addNodeDocument(event) {

  let payload = { DocumentName: '', DocumentType: '', ImageUrls: [], DocumentDatas: [] };

  payload.DocumentName = event['DocumentName.Measure'];
  payload.DocumentType = event['DocumentType.Measure'];
  payload.ImageUrls.push(event['UploadDocument.Measure']);

  Object.keys(event).forEach((key) => {
    if ((event[key] && event[key].length) || (event[key] === 0)) {
      let keys = key.split('.');
      let temp = {
        Name: keys[0],
        Unit: "",
        Measure: "",
        StartMeasureTime: "",
        EndMeasureTime: "",
        MeasureTime: ""
      };
      temp[keys[1]] = event[key];
      payload.DocumentDatas.push(temp);
    }
  });
  this.sendingDoc = true;
  this.documentService.addNodeDocument(this.data.currentNodeDetails.id, payload).subscribe((res) => {
    this.nodeService.getNodeById(this.data.currentNodeDetails.id).subscribe(
      res => {
        this.data.currentNodeDetails = res;
        this.data.documents = res.BextDocumentSummaries;
        this.documents = this.data.documents;
        this.sendingDoc = false;
        console.info('currentNodeDetails', this.data.currentNodeDetails);
        if (this.documentWizardMap[this.data.currentNodeDetails.NodeData.DefaultProductType]) {
          this.wizardService.getWizardById(this.documentWizardMap[this.data.currentNodeDetails.NodeData.DefaultProductType]).subscribe(res => {
            this.documentWizardInstance = res;
            console.log('this.lotDocumentWizardInstance: ', this.lotDocumentWizardInstance);
            this.resetWizardInstanceFieldValues(this.documentWizardInstance);
            this.dialogRef.close();
          });
        }          
      },
      (error) => {
        console.log('%c ERROR getting node details: ', 'background: #ff0000; color: #ffffff;', error);
      },
      () => {
        this.showAddDocuments = false;
        this.spinner.hide();
      });
    });
  }

  setCurrentDoc(doc) {
    this.documentService.getDocument(doc.BextDocumentId).subscribe(res => {

      this.currentDocument = res;
      this.documentService.getDocumentTemplate(this.currentDocument.DocumentType).subscribe(
          res => {
            this.documentTemplate = res;
            if (res && res['UiData'] && res['UiData'].length) {
              this.documentTemplate.UiData[0].Sections[0].Fields.forEach(field => {
                field.value = this.currentDocument.DocumentDatas.find((data) => {
                  return data.Name == field.name.split('.')[0]
                }) ? this.currentDocument.DocumentDatas.find((data) => {
                  return data.Name == field.name.split('.')[0]
                })[field.name.split('.')[1]] : '';
              });
               //doc['template'] = this.documentTemplate;
              return;
            }
          },
          error => {
            console.log('error getting documenttemplate: ', error);
            return;
          },
          () => {
            doc.loading = false;
            // this.openModal(this.documentViewerModal);
            // this.spinnerService.hide();
          }
      );
      this.documentViewerModal.show();
    })
  }
  

  deleteDocument(doc) {
    this.spinnerService.show();
    this.documentService.deleteDocument(doc.id).subscribe(
      res => {
        // console.log('response: ', res);
        
        return;
       },
      error => {
        // console.log('error: ', error);
        
        return;
      },
      () => {
        // console.log('--- subscription complete ---');
        this.documents.splice(this.currentDocumentIdx, 1);
        this.spinnerService.hide();
      }
    );
    
  }

  getData() {
    // this.spinnerService.show();
    // this.documentService.getDocuments()
    //   // .pipe(
    //   //   map((docs: any[]) => docs.filter(doc => doc.DocumentName !== null && !!doc.DocumentType))
    //   // )
    //   .subscribe(
    //     res => {
    //       this.documents = res;
    //       return;
    //     },
    //     error => {
    //       console.log('error: ', error);
          
    //       return;
    //     },
    //     () => {
    //       // console.log('--- subscription complete ---');
    //       this.spinnerService.hide();
    //       this.updatingList = false;
    //     }
    //   );
  }

  getTemplate(doc) {
    this.spinnerService.show()
    this.documentService.getDocumentTemplate(doc.DocumentType).subscribe(
      res => {
        this.documentTemplate = res;
        if (res && res['UiData'] && res['UiData'].length) {
          this.documentTemplate.UiData[0].Sections[0].Fields.forEach(field => {
            field.value = this.currentDocument.DocumentDatas.find((data) => {
              return data.Name == field.name.split('.')[0]
            }) ? this.currentDocument.DocumentDatas.find((data) => {
              return data.Name == field.name.split('.')[0]
            })[field.name.split('.')[1]] : '';
          });
          console.log('document template: ', this.documentTemplate);
          console.log('document template: ',this.currentDocument);
          return;
        }
      },
      error => {
        console.log('error getting documenttemplate: ', error);
        return;
      },
      () => {
        this.openModal(this.documentViewerModal);
        this.spinnerService.hide();
      }
      );
  }

  editDocument(e) {
    this.loadingMessage = 'Updating Document...';
    this.spinnerService.show();
    Object.keys(e.value).forEach(key => {
      let name = key.split('.');
      if (name.length > 1) {
        let foundData = this.currentDocument.DocumentDatas.find(data => {
          return data.Name == name[0];
        });
        if (foundData) {
          foundData[name[1]] = e.value[key]
        }
      }
    })
    console.log(this.currentDocument);
    this.documentService.editDocument(this.currentDocument).subscribe(res => {
      this.documentViewerModal.hide();
      // this.currentDocument = undefined;
      // this.documentTemplate = undefined;
      this.spinnerService.hide();
      this.loadingMessage = undefined;
    },
    err => {
      this.documentViewerModal.hide();
      // this.currentDocument = undefined;
      // this.documentTemplate = undefined;
      this.spinnerService.hide();
      this.loadingMessage = undefined;
      console.error('error PUT document')
    })
  }


  resetWizardInstanceFieldValues(wizardInstance) {
    wizardInstance.WizardFrontEnd.WizardStepDatas.forEach(step => {
      step.WizardStepFields.forEach(stepFields => {
        stepFields.Fields.forEach(field => {
          field.value = "";
        });
      });
    });
    wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep = 0;
    return wizardInstance;
  }

  setSearchQuery(text) {
    this.queryString = text;
  }

  uploadingCsv(e) {
    console.log("upload csv for supplychain: ", e);
  }

  addDocument() {
  }

  closeDialog(){
    this.dialogRef.close();
  }


}
