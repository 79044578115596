
import { Injectable } from '@angular/core';

import * as Msal from 'msal';
import { User } from 'msal/lib-commonjs/User';

import { BehaviorSubject, of, Observable, merge } from 'rxjs';
import { delay, mapTo } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { OrganizationRoutes, AppUserRoutes } from '../api-routes.enum';
import { environment } from '../../../environments/environment';

const intializedAppUser = {
  username: null,
  email: null,
  name: null,
  ownerOrganizationId: null,
  organizationName: null,
  organization: null,
  loggedIn: false,
  sessionExpired: false,
};

@Injectable()
export class BextAuthenticationService {
  BASE_URL = environment.baseApiUrl;
  private appUserSubject = new BehaviorSubject<any>(intializedAppUser);
  AppUserState = this.appUserSubject.asObservable();
  private authority = 'https://bext360B2CDev.b2clogin.com/tfp/' 
+ environment.ad_config.tenant
+ '/'
+ environment.ad_config.signUpSignInPolicy;

  private clientApplication: Msal.UserAgentApplication;

  constructor(private http: HttpClient) {
    // new Msal.Storage("localStorage");
    this.clientApplication =
      new Msal.UserAgentApplication(
        environment.ad_config.clientID,
        this.authority,
        this.authCallback,
        {
          validateAuthority: false,
          cacheLocation: 'localStorage',
        });
  }

  public login(): void {
    this.clientApplication.loginRedirect(environment.ad_config.b2cScopes);
    // console.group('--- AUTHENTICATION --');
    // console.log('authority: ', this.authority);
    // console.log('clientApplication Object: ', this.clientApplication);
    // console.log('authentication token ', this.getAuthenticationToken());
    // console.groupEnd();
  }

  public logout(): void {
    this.clientApplication.logout();
  }

  public isOnline$(): Observable<any> {
    const client = of(this.clientApplication.getUser() != null);
    const status = merge(
      client.pipe(
        mapTo(this.clientApplication.getUser() != null),
        delay(1500),
      ),
    );
    return status;
  }

  public getUser(): User {
    return this.clientApplication.getUser();
  }

  public getUserOrganization(msalUserObj: any) {
    return this.http.get<any>(this.BASE_URL + AppUserRoutes.BASE_URL + '/' + msalUserObj.idToken.oid)
      .switchMap(user => {
        return this.http.get<any>(this.BASE_URL + OrganizationRoutes.BASE_URL + '/' + user.OwnerOrganizationId);
      });
  }

  public setAppUser(user) {
    this.appUserSubject.next(user);
  }

  public getAppUser() {
    const appUserValue = this.appUserSubject.getValue();
    return appUserValue;
  }

  public updateAppUserProperty(prop, value) {
    const appUser = this.appUserSubject.getValue();
    if (Object.keys(appUser).find(key => prop === key)) {
      appUser[prop] = value;
      this.appUserSubject.next(appUser);
    }
  }

  public  getAuthenticationToken(): Promise<string> {
    return this.clientApplication.acquireTokenSilent(environment.ad_config.b2cScopes)
      .then(token => {

        // this.authSubject.next(this.clientApplication);

        return token;
      }).catch(error => {
        localStorage.setItem('sessionExpired', 'true');
        const user = this.appUserSubject.getValue();
        this.setAppUser({
          username: user.username,
          email: user.email,
          name: user.name,
          ownerOrganizationId: user.ownerOrganizationId,
          organizationName: user.organizationName,
          loggedIn: false,
          sessionExpired: true,
        });

        // setTimeout(() => {
        this.logout();
        // }, 600000);
        return Promise.resolve('');
      });
  }

  private authCallback(errorDesc: any, token: any, error: any, tokenType: any) {

    // this.authSubject.next(this.clientApplication);

    if (token) {
      // console.log('%c authentication call back: ', 'background: #ff00ff; color: #ffffff;', token, tokenType);
    } else {
      // this.authSubject.next(this.clientApplication);
      localStorage.setItem('sessionExpired', 'true');
      this.logout();
      // console.log(error + ':' + errorDesc);
    }
  }

}
