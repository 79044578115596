import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MarketPlaceRoutes } from '@app/core-v2/api-routes-v2.enum';

@Injectable()
export class MarketPlaceService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getProductList(): Observable<any> {
    return this.http.get(this.BASE_URL + MarketPlaceRoutes.BASE_URL + MarketPlaceRoutes.PRODUCT_LIST);
  }

  getProductConfig(product_id: string, owner_org_id: string): Observable<any> {
    return this.http.get(this.BASE_URL + MarketPlaceRoutes.BASE_URL + MarketPlaceRoutes.PRODUCT_CONFIG + '/' + product_id + '/' + owner_org_id);
  }

  getProductReport(config_id: string, queryParamRequestString: string): Observable<any> {
    return this.http.get(this.BASE_URL + MarketPlaceRoutes.BASE_URL + MarketPlaceRoutes.PRODUCT_REPORT + '/' + config_id + queryParamRequestString);
  }

  getLastTrades(config_id: string, queryParamRequestString: string): Observable<any> {
    return this.http.get(this.BASE_URL + MarketPlaceRoutes.BASE_URL + MarketPlaceRoutes.PRODUCT_REPORT + '/lasttrades/' + config_id + queryParamRequestString)
  }
}
