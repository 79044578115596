import { FormGroup } from '@angular/forms';
import { FieldConfig } from '@app/shared-v2/models/field-config.interface';
import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnInit, ViewContainerRef } from "@angular/core";


const componentMapper = {
  // input: NgxInputComponent,
  // button: NgxButtonComponent,
  // select: NgxSelectComponent,
  // date: NgxDateComponent,
  // radiobutton: NgxRadiobuttonComponent,
  // checkbox: NgxCheckboxComponent,
  // fileupload: NgxFileUploadComponent
};

@Directive({
  selector: '[pageFormatterFormField]'
})

export class PageFormatterFormFieldDirective {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  componentRef: any;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }
  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.type]
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
  }
}
