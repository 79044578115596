import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletHeatmapComponent } from './leaflet-heatmap.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [LeafletHeatmapComponent],
  exports: [LeafletHeatmapComponent]
})
export class LeafletHeatmapModule { }
