import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KpiCardComponent } from '@app/shared/cards/kpi/kpi.component';
import { TabComponent } from '@app/shared/cards/tab/tab.component';
import { AvatarCardComponent } from '@app/shared/cards/avatar/avatar.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [KpiCardComponent, TabComponent, AvatarCardComponent],
  exports: [KpiCardComponent, TabComponent, AvatarCardComponent]
})
export class CardsModule { }
