import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { SmartadminLayoutModule } from "./layout";

import {I18nModule} from "./i18n/i18n.module";
import { UserModule } from "./user/user.module";
import { BootstrapModule } from "@app/shared/bootstrap.module";
import {VoiceControlModule} from "./voice-control/voice-control.module";

import {SmartadminWidgetsModule} from "./widgets/smartadmin-widgets.module";

import {UtilsModule} from "./utils/utils.module";
import {PipesModule} from "./pipes/pipes.module";
import {ChatModule} from "./chat/chat.module";
import {StatsModule} from "./stats/stats.module";
import {InlineGraphsModule} from "./graphs/inline/inline-graphs.module";
import {SmartadminFormsLiteModule} from "./forms/smartadmin-forms-lite.module";
import {SmartProgressbarModule} from "./ui/smart-progressbar/smart-progressbar.module";
import { CalendarComponentsModule } from "@app/shared/calendar/calendar-components.module";

import { CardsModule } from "@app/shared/cards/cards.module";
import { PapaParseModule } from 'ngx-papaparse';
import { SharedNodesModule } from "./nodes/nodes.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { CsvUploaderModule } from "./csv-uploader/csv-uploader.module";
import { SharedOrganizationModule } from "./organization/shared-organization.module";
import { SharedLocationModule } from "./location/shared-location.module";
import { CustomDualListModule } from "./custom-dual-list/custom-dual-list.module";
import { SearchbarModule } from "@app/shared/searchbar/searchbar.module";

import { OverlayModule } from "@app/shared/overlay/overlay.module";
import { OcrModule } from "@app/shared/ocr/ocr.module";
import { ImageGalleryModule } from "@app/shared/image-gallery/image-gallery.module";
import { GmapModule } from '@app/shared/gmap/gmap.module';
import { SdgImageGalleryModule } from "@app/shared/sdg-image-gallery/sdg-image-gallery.module";
import { LoadingSpinnerModule } from "@app/shared/loading-spinner/loading-spinner.module";
import { WizardInstanceModule } from '@app/shared/master-wizard/wizard-instance/wizard-instance.module';
import { SusGroupBuilderModule } from "@app/shared/sus-group-builder/sus-group-builder.module";
import { LeafletHeatmapModule } from "@app/shared/leaflet/heatmap/leaflet-heatmap.module";

import { GandalfModule } from "@app/shared/master-wizard/gandalf/gandalf.module";
import { SidenavModule } from "./sidenav/sidenav.module";
import { TokenTablesModule } from "./token-tables/token-tables.module";
import { ScrollSectionModule } from './scroll-section/scroll-section.module';
import { SharedPersonsModule } from "./persons/persons.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SmartadminLayoutModule,
    BootstrapModule,
    CardsModule,
    ImageGalleryModule,
    SdgImageGalleryModule,
    GmapModule,
    LeafletHeatmapModule,
    PapaParseModule,
    SharedNodesModule,
    NgxSpinnerModule,
    CsvUploaderModule,
    SharedOrganizationModule,
    SharedLocationModule,
    CustomDualListModule,
    SearchbarModule,

    OcrModule,
    OverlayModule,
    WizardInstanceModule,
    LoadingSpinnerModule,
    SusGroupBuilderModule,
    PipesModule,
    GandalfModule,
    SidenavModule,
    TokenTablesModule,
    ScrollSectionModule,
    SharedPersonsModule
  ],
  providers: [],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PapaParseModule,
    UserModule,
    SmartadminLayoutModule,
    BootstrapModule,
    ImageGalleryModule,
    SdgImageGalleryModule,
    I18nModule,
    UtilsModule,
    PipesModule,
    SmartadminFormsLiteModule,
    SmartProgressbarModule,
    InlineGraphsModule,
    SmartadminWidgetsModule,
    ChatModule,
    StatsModule,
    VoiceControlModule,
    CalendarComponentsModule,
    CardsModule,
    GmapModule,
    LeafletHeatmapModule,
    SharedNodesModule,
    CsvUploaderModule,
    SharedOrganizationModule,
    SharedLocationModule,
    CustomDualListModule,
    SearchbarModule,

    OcrModule,
    OverlayModule,
    WizardInstanceModule,
    LoadingSpinnerModule,
    SusGroupBuilderModule,

    GandalfModule,
    SidenavModule,
    TokenTablesModule,
    ScrollSectionModule,
    SharedPersonsModule
  ]
})
export class SharedModule {}
