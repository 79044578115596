import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValuationDialogComponent } from './valuation-dialog.component';
import { MatDialogModule, MatButtonModule, MatIconModule, MatCardModule, MatFormFieldModule, MatTableModule, MatPaginatorModule, MatInputModule, MatSelectModule, MatProgressSpinnerModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditableComponent } from '@app/shared-v2/custom-directives/editable.component';
import { CustomDirectivesModule } from '@app/shared-v2/custom-directives/custom-directives.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CustomDirectivesModule,
    MatProgressSpinnerModule
  ],
  
  declarations: [ValuationDialogComponent,EditableComponent],
  entryComponents: [ValuationDialogComponent]
})
export class ValuationDialogModule { }
