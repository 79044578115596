import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransferOneOneComponent } from './transfer-one-one.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxFormModule } from '@app/shared-v2/ngx-form/ngx-form.module';
import { MaterialModule } from '@app/shared/angular-material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgxFormModule,
  ],
  declarations: [TransferOneOneComponent],
  exports: [TransferOneOneComponent],
})
export class TransferOneOneModule { }
