import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LotRoutes, TransferLotsRoutes } from '@app/core/api-routes.enum';

@Injectable()
export class LotService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getLot(id, type?): any {
    return this.http.get<any>(!type ? this.BASE_URL + LotRoutes.BASE_URL + '/' + id : this.BASE_URL + LotRoutes.BASE_URL + '/' + id);
  }

  getLotsByNodeId(node_id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + '/nodes/' + node_id + '/lots');
  }

  getLotTokens(type, id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + LotRoutes.BASE_URL + '/tokendashboard/' + id);
  }

  getTransferLotList(transferLotListId): Observable<any> {
    return this.http.get<any>(this.BASE_URL + LotRoutes.BASE_URL + LotRoutes.TRANSFER_LOT_LIST_URL + '/' + transferLotListId);
  }

  // getLotSummary(id, type): Observable<any> {
  //   return this.http.get<any>(this.BASE_URL + '/lotsV1/blockchainlist/' + id );
  // }

  getLotSummary(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + LotRoutes.LOTS_SUMMARY_URL + '/' + id);
  }

  transferLots(type, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + LotRoutes.BASE_URL + LotRoutes.TRANSFER_URL, payload);
  }

  transferLotsV2(payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + TransferLotsRoutes.BASE_URL + TransferLotsRoutes.TRANSFER_V2_URL, payload);
  }

  transferSplitLots(type, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + LotRoutes.BASE_URL + LotRoutes.TRANSFER_SPLIT_URL, payload);
  }

  editLot(id, payload): Observable<any> {
    return this.http.put<any>(this.BASE_URL + LotRoutes.BASE_URL + '/' + id, payload);
  }

  createLot(lotType, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + LotRoutes.BASE_URL + '/' + 'CreateLotFromDefaultQuality', payload);
  }

  createLotV3(payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + LotRoutes.BASE_URL + '/' + 'CreateLotFromDefaultQualityV3', payload);
  }

  deleteLot(id): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + LotRoutes.BASE_URL + '/' + id );
  }

  createAbsorbedFromLots(payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + LotRoutes.BASE_URL + LotRoutes.ABSORBED_URL, payload);
  }

  addLotMarker(lot, payload) {
    return this.http.post<any>(this.BASE_URL + '/lots/AddMarker/' + lot.id + '/' + lot.LotMetaData.LotType, payload);
  }

  verifyLotMarker(lot, payload) { 
    return this.http.post<any>(this.BASE_URL + LotRoutes.BASE_URL + '/VerifyMarker/' + lot.id + '/' + lot.LotType, payload)
  }

  deleteLotMarker(lot, marker) {
    return this.http.delete(this.BASE_URL + LotRoutes.BASE_URL + '/DeleteAddedMarker/' + lot.id + '/' + lot.LotType + '/' + marker.MarkerMetaData.MarkerDataId + '/' + marker.AddMarkerId)
  }

  getLotMarkers(lot) {
    return this.http.get<any>(this.BASE_URL + LotRoutes.BASE_URL + '/GetMarkers/' + lot.id + '/' + lot.LotType);
  }

  verifySpecifMarkerHardCodedForTest(payload) {
    return this.http.post<any>('https://bext360apidevelopment.azurewebsites.net/api/lots/VerifyMarker/cd1989f0-b3db-4bdf-9ecb-e2ffc0d26db8/Storage', payload)
  }

  blockchain(id) {
    return this.http.get<any>(this.BASE_URL + '/blockchains/' + id);
  }

  createLotAndTransfer(payload) {
    return this.http.post(this.BASE_URL + LotRoutes.BASE_URL + LotRoutes.CREATE_TRANSFER_URL, payload);
  }

  uploadLots(fd, node_id) {
    return this.http.post(this.BASE_URL + LotRoutes.BASE_URL + '/excelupload/' + node_id, fd);
  }

  getBlockchainList(lotID) {
    return this.http.get(this.BASE_URL + LotRoutes.BASE_URL + '/blockchainList/' + lotID)
  }

  getLotTokensV2(id) {
    return this.http.get(this.BASE_URL + '/lottoken/v2/' + id);
  }

  getLotSummaryV2(id) {
    return this.http.get(this.BASE_URL + LotRoutes.LOTS_SUMMARY_URL + '/v2/' + id);
  }

}