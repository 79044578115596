import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DocumentRoutes } from '@app/core-v2/api-routes-v2.enum';

@Injectable()
export class DocumentService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }
  
  getDocuments(entity_type, entity_id) {
    return this.http.get(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + entity_type + '/' + entity_id);
  }

  getImageDocuments(entity_type, entity_id) {
    return this.http.get(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + entity_type + '/' + entity_id + '/images');
  }

  getVideoDocuments(entity_type, entity_id) {
    return this.http.get(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + entity_type + '/' + entity_id + '/videos');
  }

  getDocumentTemplates(entity_type, entity_id) {
    return this.http.get(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + entity_type + '/' + entity_id + '/list');
  }

  addDocument(entity_type, entity_id, doc) {
    return this.http.post(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + entity_type + '/' + entity_id, doc);
  }

  editDocument(doc_id, doc) {
    return this.http.put(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + doc_id, doc)
  }

  deleteDocument(entity_type, entity_id, doc_id) {
    return this.http.delete(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + entity_type + '/' + entity_id + '/' + doc_id)
  }

}