import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationDialogComponent } from './location-dialog.component';
import { LocationV2Module } from '@app/shared-v2/locationv2/locationv2.module';
import { MatDialogModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    LocationV2Module,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  declarations: [LocationDialogComponent],
  exports: [LocationDialogComponent],
  entryComponents: [LocationDialogComponent]
})
export class LocationDialogModule { }
