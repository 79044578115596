import { Component, Input } from '@angular/core';

@Component({
  selector: 'sa-stats',
  templateUrl: './stats.component.html'
})
export class StatsComponent {

  public nodes: any;
  public numberOfActiveNodes: any;
  public lotsPerActiveNode: string;
  public lotValues: number;

  private _sparkInfoData;

  get sparkInfoData(): any {
    return this._sparkInfoData;
  }

  @Input()
  set sparkInfoData(val: any) {
    this._sparkInfoData = val;
    if (val) {
      this.nodes = val.dashboard.NodeDashboardDtos;
      this.numberOfActiveNodes = val.dashboard.NumberOfActiveNodes;
      this.lotsPerActiveNode = this.nodes.reduce((prev, curr) => {
        prev += (!prev) ? curr.ActiveLotsCount : ', ' + curr.ActiveLotsCount;
        return prev;
      },'');
    }
  }

  constructor() {}
  
  ngAfterViewChecked(): void {
    
  }

}
