import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sidenav-with-content',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  currentNavItem = 0;

  @Input('menuList') menuList: string[] = [];
  @Input('dataObject') dataObject: any;
  /**
   * data object is a json representation of the DOM structure
   * root level object keys represent the navigation list items
   *  {
   *    'Naming Convetion': {},
   *    'Fonts': {},
   *    etc...
   *  }
   *  navigation items would therefore be ['Naming Convetion', 'Fonts', 'etc...']
   * 
   */



  navItems;

  constructor() { }

  ngOnInit() {
    if (this.menuList.length || Array.isArray(this.dataObject)) {
      this.navItems = Array.isArray(this.dataObject) ? this.dataObject : this.menuList;
      let json = this.navItems.reduce((prev, curr) => {
        prev[curr] = {};
        return prev;
      }, {});
      console.log('object rep of nav items array: ', json);
    }
    else if (this.dataObject === Object(this.dataObject)) {
      this.navItems = Object.keys(this.dataObject);

    }
  }

  setNavItem(idx) {
    this.currentNavItem = idx;
  }

}
