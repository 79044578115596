import { NotificationRoutes } from './../api-routes-v2.enum';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscription, interval } from "rxjs";

import { EnumEx } from '@app/shared-v2/notifications/models/enum-extensions';
import { Notification, NotificationType } from '@app/shared-v2/notifications/models/notification.model';

@Injectable()
export class NotificationService {

  BASE_URL = environment.baseApiUrl;

  randomGeneration: boolean;
  notifications$: Observable<Notification[]>;

  private notificationsSubject: ReplaySubject<Notification[]>;
  private notifications = new Array<Notification>();
  private randomSub: Subscription;

  constructor(
    private http: HttpClient
  ) {
    this.notificationsSubject = new ReplaySubject<Notification[]>(1);
    this.notifications$ = this.notificationsSubject.asObservable();
  }

  getNotificationList() {
    return this.http.get(this.BASE_URL + NotificationRoutes.BASE_URL)
  }

  startRandomGeneration(period: number = 5000) {
    this.randomSub = interval(period).subscribe(() => {
      let notification = this.createRandomNotification();
      this.addNotification(notification);
    })
  }

  stopRandomGeneration() {
    if (this.randomSub !== undefined) {
      this.randomSub.unsubscribe();
    }
  }

  addNotification(notification: Notification) {
    this.notifications = [...this.notifications, notification];
    this.notificationsSubject.next(this.notifications);
  }

  removeNotification(id: string) {
    this.notifications = this.notifications.filter((x) => x.id !== id);
    this.notificationsSubject.next(this.notifications);
  }

  clearNotifications() {
    this.notifications = new Array<Notification>();
    this.notificationsSubject.next(this.notifications);
  }

  createRandomNotification(): Notification {
    let notification = new Notification();
    let types = EnumEx.getValues(NotificationType);
    notification.type = types[Math.floor(Math.random() * types.length)];
    return notification;
  }
}