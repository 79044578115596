import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { PersonsService } from '@app/core/services/backend/persons.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocationService } from '@app/core/services/backend/locations.service';
import { ModalDirective } from 'ngx-bootstrap';
import { uniqueObjectsArray } from '@app/shared/utils/object-utils';


@Component({
  selector: 'sa-add-person',
  templateUrl: './add-person.component.html',
  styleUrls: ['./add-person.component.css']
})
export class AddPersonComponent implements OnInit {
  form;
  addPersonForm: any;
  locationForm: FormGroup;
  locations;
  locationLookup = {};
  customCsvParsingString = '';


  @ViewChild('addLocationModal') public addLocationModal: ModalDirective;

    
    constructor( public dialogRef: MatDialogRef<AddPersonComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private personsService: PersonsService, private spinnerService: NgxSpinnerService, private locationService: LocationService ) { }

  ngOnInit() {

    this.spinnerService.show();
    const persons$ = this.personsService.getPersons();

    this.form = this.fb.group({addPersonForm: this.fb.group({
      Name: this.fb.control('', [Validators.required]),
      PersonType: this.fb.control('', []),
      PersonDetailType: this.fb.control('', []),
      LocationName: this.fb.control('', []),
      FarmerId: this.fb.control('', []),
      CollectorId: this.fb.control('', []),
      NameOfICS: this.fb.control('', []),
      Notes: this.fb.control('', []),
      LocationId: this.fb.control('', []),
      ImageId: this.fb.control('', []),
      Images: this.fb.control([], [])
    })
   })
   this.locationForm = this.fb.group({
    addLocationForm: this.fb.group({
      Name: this.fb.control('', [Validators.required]),
      Country: this.fb.control('', [Validators.required]),
      City: this.fb.control('', [Validators.required]),
      State: this.fb.control('', [Validators.required]),
      Latitude: this.fb.control('', []),
      Longitude: this.fb.control('', []),
      Elevation: this.fb.control('', []),
      ElevationUnit: this.fb.control('', []),
      Address: this.fb.control('', []),
      Description: this.fb.control('', [])
    })
  });
  }

  showAddLocationModal() {
    this.addLocationModal.show();
  }

  addLocation() {
    if (this.locationForm.valid) {
      this.spinnerService.show();
      this.locationService.addLocation(this.locationForm.value.addLocationForm).subscribe(
        _res => {
          this.loadLocations({ hideLocationModal: true, hideSpinner: true });
        },
        _err => this.spinnerService.hide()
      );
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.personsService.addPerson(this.form.value.addPersonForm).subscribe(res => {
        console.log(res);
        // this.getPersons();
        // this.buildForm();
        // this.form.hide();
      });
      this.dialogRef.close();
    }
  }

    loadLocations(config?) {
    this.locationService.getLocations().subscribe(
      res => {
        this.locations = uniqueObjectsArray(res, 'Name');
        for (let i = 0; i < this.locations.length; i++) {
          const location = this.locations[i];
          this.customCsvParsingString +=
            i === 0
              ? `Name,PersonType,LocationName,NameOfICS,Notes,FarmerId,ImageUrl,,locations to choose from,\n John Doe,Farmer or Collector,${location.Name},,Some notes about the farmer or collector,abc123,https://banner2.kisspng.com/20180418/vkq/kisspng-computer-icons-farmer-avatar-job-5ad7515d44dfc0.7513319915240605092821.jpg,,${location.Name}`
              : `,\n,,,,,,,,${location.Name}`;
          this.locationLookup[location.id] = location.Name;
        }
        if (config && config.hideLocationModal) {
          this.addLocationModal.hide();
        }
        if (config && config.hideSpinner) {
          this.spinnerService.hide();
        }
      },
      err => console.error(err)
    );
  }

  closeSelf() {
    this.dialogRef.close();
  }

}
