import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sm-spinner',
  templateUrl: './sm-spin-loader.component.html',
  styleUrls: ['./sm-spin-loader.component.scss']
})
export class SmallSpinnerLoaderComponent implements OnInit {

  @Input('color') spinnerColor: string = '#9ec382';

  constructor() { }

  ngOnInit() {
    
  }

}
