import { environment } from '@env/environment';
import { ReportConfig } from '@app/shared-v2/models/reports/reports-config.interface';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportsService } from '@app/core-v2/services';
import { take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SaveReportComponent } from '@app/features-v2/reports/save-report/save-report.component';

const SAVED_REPORTS_LOCALSTOAGE_KEY = 'bcrs_column_definitions';
const SAVED_QUERY_PARAMS_LOCALSTOAGE_KEY = 'bcrs_query_params';
const ADDITIONAL_REPORT_SAVED_QUERY_PARAMS_LOCALSTOAGE_KEY = 'bcrs_additional_report_query_params';

interface IReportListDialogData {
  method?: string;
  endpoint?: string;
  title?: string;
  selectedReport?: any
}

@Component({
  selector: 'bext-report-list-dialog',
  templateUrl: './report-list-dialog.component.html',
  styleUrls: ['./report-list-dialog.component.scss']
})
export class ReportListDialogComponent implements OnInit {

  private alive = true;
  private BASE_URL = environment.baseApiUrl;

  private _endpoint: string;
  set endpoint(m) {
    this._endpoint = m;
  }
  get endpoint() {
    return this._endpoint;
  }

  private _title: string;
  set title(t) {
    this._title = t;
  }
  get title() {
    return this._title;
  }

  private _selectedReport: any;
  set selectedReport(t) {
    this._selectedReport = t;
  }
  get selectedReport() {
    return this._selectedReport;
  }

  humanNames = {};
  loading = false;
  queryParamRequest = ' ';
  allColumns: any[];
  displayedColumns: any[] = [];
  initialHiddenColumns: any[] = [];
  displayedColumnsNames: any[] = [];
  savedReportName;
  dataSource;
  tableName;
  saveReportDialogRef;

  constructor(
    private dialogRef: MatDialogRef<ReportListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private http: HttpClient,
    private reportsService: ReportsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {

    this.title = this.data.title;
    this.endpoint = this.data.endpoint;
    this.selectedReport = this.data.selectedReport;

    this.onGetReport(this.endpoint);
  }

  onGetReport(endpoint) {
    this.saveQueryParams(endpoint);
    this.loading = true;
    this.reportsService.getReportList(endpoint)
      .pipe(
        take(1)
      )
      .subscribe(
        (res: any) => {
          this.dataSource = undefined;
          this.humanNames = {};
          this.displayedColumns = res.ColumnMetaData.map(c => c.Field);
          this.displayedColumnsNames = res.ColumnMetaData.map(c => c.Label);
          this.initialHiddenColumns = res.ColumnMetaData
            .map(c => {
              if (c.Display.toLowerCase() === 'false') {
                return true;
              }
              else {
                return false;
              }
            });
          this.savedReportName = `brcs___ReportList--${res.Name}__queryparams=${this.getSavedQueryParams()}` // brs = bext report column storage
          console.log('Saved Report Name: ', this.savedReportName)
          let rows = res.RowData
            .reduce((prev, curr, i, arr) => {
              let temp = prev.find((row) => row.Id === arr[i].Id);
              temp = temp ? temp : {};
              if (!Object.keys(this.humanNames).length) {
                this.displayedColumns.forEach(column => {
                  this.humanNames[column] = column.replace(/([a-z])([A-Z])/g, '$1 $2');
                })
              }
              this.displayedColumns.forEach((col) => {
                if (curr.Field === col && !temp[col]) {
                  temp['Id'] = temp['Id'] ? temp['Id'] : curr.Id;
                  temp[col] = curr.Value;
                  if (curr.Path !== null && curr.Path) {
                    temp[col + 'Path'] = curr.Path;
                  }
                  if (curr.ClickMetaData !== null && curr.ClickMetaData) {
                    temp[col + 'ClickMetaData'] = curr.ClickMetaData;
                  }
                }
              });
              let prevIndex = prev.findIndex((row) => row['Id'] === curr.Id);
              if (prevIndex !== -1) {
                prev.splice(prevIndex, 1, temp);
              } else {
                prev.push(temp);
              }
              return prev;
            }, [])

          this.loading = false;
          this.dataSource = rows;
          console.log('data source: ', this.dataSource);
        },
        error => {
          this.loading = false;
          console.log('GET lot report - error: ', error);
        }
      )
  }

  saveReport(selectedReport) {
    this.saveReportDialogRef = this.dialog.open(SaveReportComponent, {
      data: { test: true }
    });

    this.saveReportDialogRef.afterClosed().subscribe((data) => {
      if (data.reportName && data.orgOrUser) {
        selectedReport.Name = data.reportName;
        selectedReport.OrgOrUser = data.orgOrUser;
        this.reportsService.saveCustomReport(selectedReport).pipe(take(1)).subscribe(res => {
          console.log(res);
        })
      }
      // if (data)
    })
  }

  downloadReport(format, selectedReport) {
    let file$: Observable<any>;
    let queryParams = this.getSavedQueryParams();

    switch (format) {
      case 'excel':
        file$ = this.reportsService.getExcelReport(selectedReport.ReportId, queryParams);
        break;

      case 'pdf':
        file$ = this.reportsService.getPdfReport(selectedReport.ReportId, queryParams);
        break;

      default:
        file$ = of(['format not specified or does not match options'])
        break;
    }
    file$
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          console.log('%c downloaded report: ', 'background: #41ff6b; color: #ff4700;', res);
        },
        error => {
          console.log('%c GET report download failed: ', 'background: #ff0000; color: #ffffff;', error);
        }
      )
  }

  emailReport(format, selectedReport) {
    let file$: Observable<any>;
    let queryParams = this.getSavedQueryParams();

    switch (format) {

      case 'pdf':
        file$ = this.reportsService.emailPdfReport(selectedReport.ReportId, queryParams);
        break;

      case 'excel':
        file$ = this.reportsService.emailExcelReport(selectedReport.ReportId, queryParams);
        break;

      default:
        file$ = of(['format not specified or does not match options'])
        break;
    }
    file$
      .pipe(
        take(1)
      )
      .subscribe(
        res => {
          console.log('%c email report: ', 'background: #41ff6b; color: #ff4700;', res);
        },
        error => {
          console.log('%c GET report email failed: ', 'background: #ff0000; color: #ffffff;', error);
        }
      )
  }

  getColumnName(col) {
    if (col.indexOf('.') === -1) return col;
    else {
      let formattedName = col.split('.').join(' ');
      return formattedName;
    }
  }

  saveQueryParams(queryParams: string, additionalReportParamsKey: string = '') {
    if (!localStorage) return;
    localStorage.setItem(`${SAVED_QUERY_PARAMS_LOCALSTOAGE_KEY}${additionalReportParamsKey}`, queryParams)
  }

  getSavedQueryParams(additionalReportParamsKey: string = '') {
    return localStorage.getItem(`${SAVED_QUERY_PARAMS_LOCALSTOAGE_KEY}${additionalReportParamsKey}`)
  }

  clearSavedQueryParams(additionalReportParamsKey: string = '') {
    localStorage.removeItem(`${SAVED_QUERY_PARAMS_LOCALSTOAGE_KEY}${additionalReportParamsKey}`);
  }

  clearColumnInfo() {
    let saved_reports: any = JSON.parse(localStorage.getItem(SAVED_REPORTS_LOCALSTOAGE_KEY));
    if (saved_reports === null) return;
    else {
      saved_reports.forEach((reportName) => {
        localStorage.removeItem(reportName)
      });
      localStorage.removeItem(SAVED_REPORTS_LOCALSTOAGE_KEY)
      return;
    }
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  ngOnDestroy(): void {
    this.clearColumnInfo();
    this.clearSavedQueryParams();
    this.clearSavedQueryParams(ADDITIONAL_REPORT_SAVED_QUERY_PARAMS_LOCALSTOAGE_KEY);
    this.alive = false;
  }

}
