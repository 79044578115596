import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { BextAuthenticationService } from '@app/core-v2/services/authentication.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: BextAuthenticationService) { }

  canActivate(): Observable<boolean> {
    // return Observable.of(true);
    return Observable.of(this.authService.getUser() != null);
  }
}