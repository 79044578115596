export interface TokenFactor {
  NodeType: string;
  NodeName: string;
  NodeSequence: number;
  IndustryStandard: number;
  StandardWeightLossPercentage: number;
  WeightLossPercentage: number;
  TokenState: string;
  TokenType: string;
  StandardCompletionPercentage: number;
  CompletionPercentage: number;
  TokenValue: number;
  TokenFactor: number;
  Weight: number;
}

export interface TokenValue {
  TokenName: string;
  TokenValue: string;
}

export interface ValuationModel {
  Type: string;
  ValuationName: string;
  TokenFactors: Array<TokenFactor>;
  TokenValues: Array<TokenValue>;
}

export class SampleValuations {
  public ValuationList: Array<ValuationModel>;

  constructor(valuations) {
    this.ValuationList = valuations;
  }
}

export const FakeValuation = {
  ValuationName: "UC50 SC VALUATION",
  Type: "Management Accounting",
  TokenFactors: [
    {
      NodeType: "Collection",
      NodeName: "Test Simple Edit 1",
      NodeSequence: 0,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 0.25,
      WeightLossPercentage: 0.2,
      TokenState: "Analysis",
      TokenType: "Cotton",
      StandardCompletionPercentage: 0.25,
      CompletionPercentage: 0.25,
      TokenValue: 25,
      TokenFactor: 0.05,
      Weight: 0,
    },
    {
      NodeType: "Collection",
      NodeName: "Test Dan 1",
      NodeSequence: 0,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 0.25,
      WeightLossPercentage: 0.2,
      TokenState: "Quality",
      TokenType: "Cotton",
      StandardCompletionPercentage: 0.25,
      CompletionPercentage: 0.25,
      TokenValue: 25,
      TokenFactor: 0.05,
      Weight: 0,
    },
    {
      NodeType: "Collection",
      NodeName: "Test Dan 1",
      NodeSequence: 0,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 0.25,
      WeightLossPercentage: 0.2,
      TokenState: "Transferred",
      TokenType: "Cotton",
      StandardCompletionPercentage: 0.25,
      CompletionPercentage: 0.25,
      TokenValue: 25,
      TokenFactor: 0.05,
      Weight: 0,
    },
    {
      NodeType: "Processing",
      NodeName: "Processing 1 V2A",
      NodeSequence: 1,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 0.5,
      WeightLossPercentage: 0.5,
      TokenState: "Analysis",
      TokenType: "Cotton",
      StandardCompletionPercentage: 0.5,
      CompletionPercentage: 0.5,
      TokenValue: 50,
      TokenFactor: 0,
      Weight: 0,
    },
    {
      NodeType: "Processing",
      NodeName: "Processing 1 V2A",
      NodeSequence: 1,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 0.5,
      WeightLossPercentage: 0.5,
      TokenState: "Quality",
      TokenType: "Cotton",
      StandardCompletionPercentage: 0.5,
      CompletionPercentage: 0.5,
      TokenValue: 50,
      TokenFactor: 0,
      Weight: 0,
    },
    {
      NodeType: "Processing",
      NodeName: "Processing 1 V2A",
      NodeSequence: 1,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 0.5,
      WeightLossPercentage: 0.5,
      TokenState: "Transferred",
      TokenType: "Cotton",
      StandardCompletionPercentage: 0.5,
      CompletionPercentage: 0.5,
      TokenValue: 50,
      TokenFactor: 0,
      Weight: 0,
    },
    {
      NodeType: "Transportation",
      NodeName: "Transportation V2A",
      NodeSequence: 3,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 0.75,
      WeightLossPercentage: 0.75,
      TokenState: "Analysis",
      TokenType: "Cotton",
      StandardCompletionPercentage: 0.75,
      CompletionPercentage: 0.75,
      TokenValue: 75,
      TokenFactor: 0,
      Weight: 0,
    },
    {
      NodeType: "Transportation",
      NodeName: "Transportation V2A",
      NodeSequence: 3,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 0.75,
      WeightLossPercentage: 0.75,
      TokenState: "Quality",
      TokenType: "Cotton",
      StandardCompletionPercentage: 0.75,
      CompletionPercentage: 0.75,
      TokenValue: 75,
      TokenFactor: 0,
      Weight: 0,
    },
    {
      NodeType: "Transportation",
      NodeName: "Transportation V2A",
      NodeSequence: 3,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 0.75,
      WeightLossPercentage: 0.75,
      TokenState: "Transferred",
      TokenType: "Cotton",
      StandardCompletionPercentage: 0.75,
      CompletionPercentage: 0.75,
      TokenValue: 75,
      TokenFactor: 0,
      Weight: 0,
    },
    {
      NodeType: "Sales",
      NodeName: "Retail V2A",
      NodeSequence: 4,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 1,
      WeightLossPercentage: 1,
      TokenState: "Analysis",
      TokenType: "Cotton",
      StandardCompletionPercentage: 1,
      CompletionPercentage: 1,
      TokenValue: 100,
      TokenFactor: 0,
      Weight: 0,
    },
    {
      NodeType: "Sales",
      NodeName: "Retail V2A",
      NodeSequence: 4,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 1,
      WeightLossPercentage: 1,
      TokenState: "Quality",
      TokenType: "Cotton",
      StandardCompletionPercentage: 1,
      CompletionPercentage: 1,
      TokenValue: 100,
      TokenFactor: 0,
      Weight: 0,
    },
    {
      NodeType: "Sales",
      NodeName: "Retail V2A",
      NodeSequence: 4,
      IndustryStandard: 1,
      StandardWeightLossPercentage: 1,
      WeightLossPercentage: 1,
      TokenState: "Transferred",
      TokenType: "Cotton",
      StandardCompletionPercentage: 1,
      CompletionPercentage: 1,
      TokenValue: 100,
      TokenFactor: 0,
      Weight: 0,
    },
  ],
  TokenValues: [
    {
      tokenName: "Cotton5",
      tokenValue: "3",
    },
    {
      tokenName: "Cotton10",
      tokenValue: "6",
    },
    {
      tokenName: "Cotton15",
      tokenValue: "9",
    },
    {
      tokenName: "Cotton20",
      tokenValue: 20,
    },
    {
      tokenName: "Cotton25",
      tokenValue: 25,
    },
    {
      tokenName: "Cotton30",
      tokenValue: 30,
    },
    {
      tokenName: "Cotton35",
      tokenValue: 35,
    },
    {
      tokenName: "Cotton40",
      tokenValue: 40,
    },
    {
      tokenName: "Cotton45",
      tokenValue: 45,
    },
    {
      tokenName: "Cotton50",
      tokenValue: 50,
    },
    {
      tokenName: "Cotton55",
      tokenValue: 55,
    },
    {
      tokenName: "Cotton60",
      tokenValue: 60,
    },
    {
      tokenName: "Cotton65",
      tokenValue: 65,
    },
    {
      tokenName: "Cotton70",
      tokenValue: 70,
    },
    {
      tokenName: "Cotton75",
      tokenValue: 75,
    },
    {
      tokenName: "Cotton80",
      tokenValue: 80,
    },
    {
      tokenName: "Cotton85",
      tokenValue: 85,
    },
    {
      tokenName: "Cotton90",
      tokenValue: 90,
    },
    {
      tokenName: "Cotton95",
      tokenValue: 95,
    },
    {
      tokenName: "Cotton100",
      tokenValue: 100,
    },
  ],
};
