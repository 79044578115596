import { HttpClient } from '@angular/common/http';
import { FormStep } from '@app/shared-v2/models/archwizard/form-step.interface';
import { Component, OnInit, Inject, Input, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { QuickLinksService } from "@app/core-v2/services";
import { takeWhile, take, pluck } from "rxjs/operators";
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { UiAction } from '@app/shared-v2/models/archwizard/form-attribute.class';

interface ICustomFormDialogData {
  method?: string;
  endpoint?: string;
}

@Component({
  selector: 'bext-custom-form-dialog',
  templateUrl: './custom-form-dialog.component.html',
  styleUrls: ['./custom-form-dialog.component.scss']
})
export class CustomFormDialogComponent implements OnInit {

  @ViewChild('dialogContent')
  dialogContent: ElementRef;

  private alive = true;
  private BASE_URL = environment.baseApiUrl;

  private _method: string;
  @Input()
  set method(m) {
    this._method = m;
  }
  get method() {
    return this._method;
  }

  private _endpoint: string;
  @Input()
  set endpoint(m) {
    this._endpoint = m;
  }
  get endpoint() {
    return this._endpoint;
  }

  loadComplete = false;
  formObj;
  wizardModel: FormStep[] = [];
  processingCustomForm = false;
  postResponse: UiAction;
  initializeWithCompletedSteps = false;

  constructor(
    public dialogRef: MatDialogRef<CustomFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICustomFormDialogData,
    private quickLinksService: QuickLinksService,
    private http: HttpClient
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {

    if (this.data) {
      this.method = this.data.method;
      this.endpoint = this.data.endpoint;
      this.quickLinksService.customFormMethodHandler(this.method, this.endpoint)
        .pipe(
          takeWhile(() => this.alive)
        )
        .subscribe(
          res => {
            // console.log('GET quicklinks custom form handler - response: ', res);
            if (res === null || !res) {
              this.loadComplete = true;
              return;
            }
            this.formObj = res;
            // console.log('%c FORM OBJ: ', 'background: #fae552; color: #323232;', this.formObj);
            this.wizardModel = res.Form.FormSteps;
            this.loadComplete = true;
          },
          error => {
            this.loadComplete = true;
            console.log('GET quicklinks custom form handler - error: ', error);
          },
          () => {
            // console.log('--- subscription complete ---');

          }
        );
    }
    else {
      this.quickLinksService.getWizard()
        .pipe(
          takeWhile(() => this.alive)
        )
        .subscribe(
          res => {
            console.log('GET default quicklinks custom form - response: ', res);
            this.formObj = res;
            this.wizardModel = this.formObj['lotWizardStepsDtos'] || this.formObj['FormSteps'];
            this.loadComplete = true;
           },
          error => {
            this.loadComplete = true;
            console.log('GET quicklinks wizard - error: ', error);
          },
          () => {
            // console.log('--- subscription complete ---');
            
          }
        );
    }
  }

  stepChange(e: {currentStep: FormStep, previousStep: FormStep}) {
    console.log('%c Step change event: ', 'background: #41ff6b; color: #ff4700;', e);
    this.dialogContent.nativeElement.scrollTo(0,0)
  }

  processButtonAction(e) {
    console.log('PROCESS WIZARD ACTION: ', e);
    if (!e) {
      return this.dialogRef.close();
    }
    else {
      this.processingCustomForm = true;
      let request$: Observable<any>;
      this.formObj.Form.FormSteps = e.value;
      console.log('%c FORM OBJ: ', 'background: #fae552; color: #323232;', this.formObj);
      switch (e.method) {
        case 'POST':
            request$ = this.http.post(this.BASE_URL + e.endpoint, this.formObj)
          break;
  
        case 'PUT':
          request$ = this.http.put(this.BASE_URL + e.endpoint, this.formObj)
          break;
      
        default:
          break;
      }
  
      request$
        .pipe(
          take(1),
          pluck('UiAction')
        )
        .subscribe(
          (res: UiAction) => {
            this.postResponse = res
            setTimeout(() => {
              this.dialogRef.close()
            }, res.Duration || 3000);
            console.log('%c ' + e.method + ' action success ', 'background: #ff00ff; color: #ffffff;', res);
          },
          error => {
            this.postResponse = undefined;
            console.log('%c ' + e.method + ' action error ', 'background: #ff00ff; color: #ffffff;', error);
          }
        )
    }
  }

  appendNewFormResults(e) {

    this.loadComplete = false;
    this.formObj;
    this.wizardModel = [];

    setTimeout(() => {
      this.initializeWithCompletedSteps = true;
      console.log('%c intialize with completed steps: ', 'background: #41ff6b; color: #ff4700;', this.initializeWithCompletedSteps);
      this.formObj = e;
      this.wizardModel = this.formObj.Form.FormSteps;
      this.loadComplete = true;
    }, 1500);

  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
