import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VerifyDataRoutes } from '@app/core-v2/api-routes-v2.enum';
import { Observable } from 'rxjs';
import { IDataVerificationDto, IDataVerificationResultSummaryDto } from '@app/shared-v2/models/verify-data/verify-data.interface';

@Injectable()
export class VerifyDataService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  // GET api/verifydata/workflow/user
  getAppUserVerifyData(): Observable<IDataVerificationDto[]> {
    return this.http.get<IDataVerificationDto[]>(this.BASE_URL + VerifyDataRoutes.BASE_URL + VerifyDataRoutes.PUSHOBJECT + VerifyDataRoutes.USER);
  }

  performDataVerification(dataVerificationId : string): Observable<IDataVerificationResultSummaryDto> {
    return this.http.get<IDataVerificationResultSummaryDto>(this.BASE_URL + VerifyDataRoutes.BASE_URL + VerifyDataRoutes.PUSHOBJECT + VerifyDataRoutes.VERIFY + '/' + dataVerificationId);
  }
}