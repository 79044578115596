import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonV2Component } from './personv2.component';
import { MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatSelectModule, MatOptionModule, MatExpansionModule, MatProgressSpinnerModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    AgmCoreModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule
  ],
  declarations: [PersonV2Component],
  entryComponents: [PersonV2Component],
  exports: [PersonV2Component],
  providers: []
})
export class PersonV2Module { }
