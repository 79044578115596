import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import * as Msal from 'msal';
import { User } from 'msal/lib-commonjs/User';

import { environment } from '@env/environment';

import { BehaviorSubject, from } from "rxjs";
import { distinctUntilChanged, tap, switchMap, flatMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { OrganizationRoutes, AppUserRoutes } from '../api-routes.enum';

let intializedAppUser = {
  username: null,
  email: null,
  name: null,
  appUserId: null,
  appUserData: null,
  appUserUiData: null,
  organizationName: null,
  organization: null,
  loggedIn: null,
  sessionExpired: null,
  getUiData: null
}

@Injectable()
export class BextAuthenticationService {

  BASE_URL = environment.baseApiUrl;
  
  private appUserSubject = new BehaviorSubject<any>(intializedAppUser);

  AppUserState$ = this.appUserSubject.asObservable();
  AppUserState = this.appUserSubject.getValue();

  private authority = 'https://bext360B2CDev.b2clogin.com/tfp/' + environment.ad_config.tenant + '/' + environment.ad_config.signUpSignInPolicy;

  private clientApplication: Msal.UserAgentApplication;

  constructor(private router: Router, private http: HttpClient) {
    // new Msal.Storage("localStorage");
    this.clientApplication =
      new Msal.UserAgentApplication(
        environment.ad_config.clientID,
        this.authority,
        this.authCallback,
        {
          validateAuthority: false,
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true
        });
  }

  public login(): void {
    this.clientApplication.loginRedirect(environment.ad_config.b2cScopes);
    // console.group('--- AUTHENTICATION --');
    // console.log('authority: ', this.authority);
    // console.log('clientApplication Object: ', this.clientApplication);
    // console.log('authentication token ', this.getAuthenticationToken());
    // console.groupEnd();
    this.getAuthenticationToken().then((res) => {
      console.log('%c GET auth token: ', 'background: #41ff6b; color: #ff4700;', res);
    })
  }

  public logout(): void {
    this.clientApplication.logout();
  }

  public isOnline(): boolean {
    return this.clientApplication.getUser() != null;
  }

  public getUser(): User {
    return this.clientApplication.getUser();
  }

  public appUserData(app_user_id) {
    return this.http.get(this.BASE_URL + '/appusers/' + app_user_id)
  }

  public getUserOrganization(msalUserObj: any) {
    return this.http.get<any>(this.BASE_URL + AppUserRoutes.BASE_URL + '/' + msalUserObj.idToken.oid)
      .switchMap(user => {
        return this.http.get<any>(this.BASE_URL + OrganizationRoutes.BASE_URL + '/' + user.OwnerOrganizationId)
      });
  }

  public setAppUser(user, userUiData?) {
    this.appUserSubject.next(user);
  }

  public getAppUser() {
    const appUserValue = this.appUserSubject.getValue();
    return appUserValue;
  }

  public updateAppUserProperty(prop, value) {
    let appUser = this.appUserSubject.getValue();
    if (Object.keys(appUser).find(key => prop === key)) {
      appUser[prop] = value;
      this.appUserSubject.next(appUser);
    }
    else {
      console.log('%c Update app user property failed because property does not exist: ', 'background: #ff0000; color: #ffffff;', prop);
    }
  }

  public getAuthenticationToken(): Promise<string> {
    return this.clientApplication.acquireTokenSilent(environment.ad_config.b2cScopes)
      .then(token => {

        // this.authSubject.next(this.clientApplication);

        return token;
      }).catch(error => {
        localStorage.setItem('sessionExpired', 'true');
        let user = this.appUserSubject.getValue();
        this.setAppUser({
          username: user.username,
          email: user.email,
          name: user.name,
          ownerOrganizationId: user.ownerOrganizationId,
          organizationName: user.organizationName,
          organization: user.organization,
          loggedIn: false,
          sessionExpired: true
        });
        // console.log('%c SESSION TIMED OUT (UPDATE BEHAVIOR SUBJECT TO SET SESSION EXPIRED PROPERTY) - CURRENT USER IS: ', 'background: #41ff6b; color: #ff4700;', user);
        // setTimeout(() => {
          this.logout();
        // }, 600000);
        return Promise.resolve('');
      });
  }

  private authCallback(errorDesc: any, token: any, error: any, tokenType: any) {

    // this.authSubject.next(this.clientApplication);
    
    if (token) {
      // console.log('%c authentication call back: ', 'background: #ff00ff; color: #ffffff;', token, tokenType);
    } else {
      // this.authSubject.next(this.clientApplication);
      localStorage.setItem('sessionExpired', 'true');
      this.logout();
      console.log(error + ':' + errorDesc);
    }
  }
  
}
