import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OcrService {

    BASE_URL = environment.baseApiUrl;
    OCR_IMAGETEXT_URL = '/OcrTextRecognition/GetImageText';
    OCR_TEMPLATE_TYPE_URL = '/OcrTextRecognition/GetTemplate';
   

  constructor(private http: HttpClient) { }

  getImageText(imgUrl): Observable<any> {
    let body = new FormData();
    body.append('imageUrl', imgUrl);
    return this.http.post<any>(this.BASE_URL + this.OCR_IMAGETEXT_URL, body);
  }

  getTemplateType(type): Observable<any> {
    return this.http.get<any>(this.BASE_URL + this.OCR_TEMPLATE_TYPE_URL + '/' + type);
  }

}