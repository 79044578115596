import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { Component, OnInit, ViewChild, HostListener, Inject, Directive } from '@angular/core';
import { ReportsService, ApiFakerService } from '@app/core-v2/services';
import { ReportsListItem } from '@app/shared-v2/models/reports/reports-list.interface';
import { takeWhile, map, take } from 'rxjs/operators';
import { MatSelectChange, MatButtonToggleChange, MatDatepickerInputEvent, MatStepper, MatDialog, MatDialogRef } from '@angular/material';
import { MatMenuTrigger } from '@angular/material';
import { ReportConfig, QueryParam } from '@app/shared-v2/models/reports/reports-config.interface';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'save-report',
  templateUrl: './save-report.component.html',
  styleUrls: ['./save-report.component.scss'],

})
export class SaveReportComponent implements OnInit {
    reportName;
    orgOrUser;

    constructor(private dialogRef: MatDialogRef<SaveReportComponent>) {}

    ngOnInit() {

    }

    closeDialog() {
        this.dialogRef.close({reportName: this.reportName, orgOrUser: this.orgOrUser})
    }

}