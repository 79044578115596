import { MatDialogModule, MatButtonModule, MatIconModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiMetadataCustomFieldDialogComponent } from './ui-metadata-custom-field-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [UiMetadataCustomFieldDialogComponent],
  entryComponents: [UiMetadataCustomFieldDialogComponent]
})
export class UiMetadataCustomFieldDialogModule { }
