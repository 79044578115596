import { BextArchwizardModule } from '@app/shared-v2/archwizard/archwizard.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SustainabilityDialogComponent } from './sustainability.component';
import { MatButtonModule, MatIconModule, MatProgressSpinnerModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { SearchbarModule } from '@app/shared/searchbar/searchbar.module';
import { LoadingSpinnerModule } from '@app/shared/loading-spinner/loading-spinner.module';
import { WizardInstanceModule } from '@app/shared/master-wizard/wizard-instance/wizard-instance.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { SustainabilityWizardComponent } from './sustainability-wizard/sustainability-wizard.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    SearchbarModule,
    LoadingSpinnerModule,
    WizardInstanceModule,
    PipesModule,
    BextArchwizardModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  declarations: [SustainabilityDialogComponent, SustainabilityWizardComponent],
  entryComponents: [SustainabilityDialogComponent, SustainabilityWizardComponent],
  exports: [SustainabilityDialogComponent, SustainabilityWizardComponent],
})
export class SustainabilityDialogModule { }
