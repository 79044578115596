import { environment } from '@env/environment';
import { SustainabilityRoutes } from '@app/core/api-routes.enum';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SustainabilityService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getGroups(): Observable<any> {
    return this.http.get(this.BASE_URL + SustainabilityRoutes.BASE_URL + SustainabilityRoutes.GROUPS_URL);
  }

  getGroupInstance(sus_group_instance_id): Observable<any> {
    return this.http.get(this.BASE_URL + SustainabilityRoutes.BASE_GROUP_INSTANCE_URL + '/' + sus_group_instance_id);
  }

  createGroupInstance(payload): Observable<any> {
    return this.http.post(this.BASE_URL + SustainabilityRoutes.BASE_GROUP_INSTANCE_URL + SustainabilityRoutes.ADD_GROUP_INSTANCE_URL, payload);
  }

  getGroupInstances(sus_data_id): Observable<any> {
    return this.http.get(this.BASE_URL + SustainabilityRoutes.BASE_GROUP_INSTANCE_URL + SustainabilityRoutes.GROUP_INSTANCE_NODE_URL + '/' + sus_data_id);
  }
  
  getGroupByProduct(product): Observable<any> {
    return this.http.get(this.BASE_URL + SustainabilityRoutes.BASE_URL + SustainabilityRoutes.SUSTAINABILITYGROUP_URL + '/' + product);
  }

  getGroupReport(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + SustainabilityRoutes.BASE_URL + SustainabilityRoutes.SUSTAINABILITYGROUP_REPORT_URL + '/' + id);
  }

  uploadGroup(payload): Observable<any> {
    return this.http.post(this.BASE_URL + SustainabilityRoutes.BASE_URL + SustainabilityRoutes.UPLOAD_GROUP_URL, payload);
  }

  addGroup(payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + SustainabilityRoutes.BASE_URL + SustainabilityRoutes.ADD_GROUP_URL, payload);
  }

  addLevel(payload): Observable<any> {
    return this.http.put<any>(this.BASE_URL + SustainabilityRoutes.BASE_URL + SustainabilityRoutes.ADD_LEVEL_URL, payload);
  }

  addCriteria(payload): Observable<any> {
    return this.http.put(this.BASE_URL + SustainabilityRoutes.BASE_URL + SustainabilityRoutes.ADD_CRITERIA_URL, payload)
  }
}