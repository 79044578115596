import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollSectionComponent } from './scroll-section.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ScrollSectionComponent],
  exports: [ScrollSectionComponent]
})
export class ScrollSectionModule { }
