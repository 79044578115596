import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { Camera, SecurityCamerasData } from '../data/security-cameras';

@Injectable()
export class SecurityCamerasService extends SecurityCamerasData {

  private cameras: Camera[] = [
    {
      title: 'Camera #1',
      source: 'https://concept-consult.ch/wp-content/uploads/2012/03/CCD1_ZH_05-1944x1400.jpg',
    },
    {
      title: 'Camera #2',
      source: 'https://concept-consult.ch/wp-content/uploads/2012/03/CCD1_ZH_01-1944x1400.jpg',
    },
    {
      title: 'Camera #3',
      source: 'https://concept-consult.ch/wp-content/uploads/2012/03/CCD1_ZH_01-1944x1400.jpg',
    },
    {
      title: 'Camera #4',
      source: 'https://concept-consult.ch/wp-content/uploads/2012/03/CCD1_ZH_03-1944x1400.jpg',
    },
  ];

  getCamerasData(): Observable<Camera[]> {
    return observableOf(this.cameras);
  }
}
