import { environment } from "@env/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AssetRoutes } from "@app/core-v2/api-routes-v2.enum";
import { IAsset } from "@app/shared-v2/models/asset/asset.interface";

@Injectable()
export class AssetService {
  GLOBAL_SUBSCRIPTION_KEY = "246566bb759c41a095cf3a4716d8376e";

  BASE_URL = environment.baseApiUrl;

  constructor(private _http: HttpClient) {}

  getAssetOverviewData(params) {
    return this._http.get(
      this.BASE_URL +
        AssetRoutes.BASE_URL +
        AssetRoutes.DATA_URL +
        AssetRoutes.OVERVIEW_URL +
        params
    );
  }

  getAssetSectionData(params) {
    return this._http.get(
      this.BASE_URL + AssetRoutes.BASE_URL + AssetRoutes.DATA_URL + "/" + params
    );
  }

  getOrgNodeAssetGroups(): Observable<any> {
    return this._http.get(
      this.BASE_URL + AssetRoutes.BASE_URL + AssetRoutes.NODE_SUMMARY
    );
  }

  getAssetByIdV2(id, orgId): Observable<any> {
    return this._http.get<IAsset>(
      this.BASE_URL + AssetRoutes.GET_ASSET + "/" + orgId + "/" + id
    );
  }

  updateAsset(payload): Observable<any> {
    return this._http.post<any>(
      this.BASE_URL + AssetRoutes.UPDATE_ASSET,
      payload
    );
  }

  getAssetBotanicalNames(): Observable<any> {
    return this._http.get(
      this.BASE_URL + AssetRoutes.BASE_URL + AssetRoutes.BOTANICALNAMES
    );
  }

  getAssetPartnerNames(): Observable<any> {
    return this._http.get(
      this.BASE_URL + AssetRoutes.BASE_URL + AssetRoutes.PARTNERNAMES + AssetRoutes.ASSETPARTNERS
    );
  }

  getAssetDonorNames(): Observable<any> {
    return this._http.get(
      this.BASE_URL + AssetRoutes.BASE_URL + AssetRoutes.PARTNERNAMES + AssetRoutes.ASSETDONORS
    );
  }

  // updateBotanicalName(assetId, newNameId): Observable<any> {
  //   return this._http.post<any>(
  //     this.BASE_URL +
  //       AssetRoutes.EDIT_BOTANICALNAME +
  //       "/" +
  //       assetId +
  //       "/" +
  //       newNameId,
  //     null
  //   );
  // }

  getAssetsByNode(nodeId: string, orgId: string): Observable<IAsset[]> {
    return this._http.get<IAsset[]>(
      "https://bext360api.azure-api.net/retaildev/v1/GetAssets/Node/" + nodeId,
      {
        headers: new HttpHeaders({
          OwnerOrganizationId: orgId,
          "Ocp-Apim-Subscription-Key": this.GLOBAL_SUBSCRIPTION_KEY,
        }),
      }
    );
  }

  getNodeAssetGroup(
    nodeId: string,
    assetgroupId: string,
    orgId: string
  ): Observable<IAsset[]> {
    return this._http.get<IAsset[]>(
      this.BASE_URL +
        AssetRoutes.BASE_URL +
        AssetRoutes.NODE_ASSETS +
        "/" +
        nodeId +
        "/" +
        assetgroupId
      //   assetgroupId,
      // {
      //   headers: new HttpHeaders({
      //     OwnerOrganizationId: orgId,
      //     OrganizationId: this.GLOBAL_SUBSCRIPTION_KEY,
      //   }),
      // }
    );
  }

  getAssetById(assetId: string, orgId: string): Observable<IAsset> {
    return this._http.get<IAsset>(
      "https://bext360api.azure-api.net/retaildev/v1/GetAsset/" + assetId,
      {
        headers: new HttpHeaders({
          OwnerOrganizationId: orgId,
          "Ocp-Apim-Subscription-Key": this.GLOBAL_SUBSCRIPTION_KEY,
        }),
      }
    );
  }
}
