import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartadminFormsModule } from '@app/shared/forms/smartadmin-forms.module';
import { OrganizationService } from '@app/core/services/backend/organization.service';
import { LocationService } from '@app/core/services/backend/locations.service';
import { SustainabilityService } from '@app/core/services/backend/sustainability.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NodeService } from '@app/core/services/backend/node.service';
import { SmartadminWidgetsModule } from '@app/shared/widgets/smartadmin-widgets.module';
import { DndModule } from 'ngx-drag-drop';
import { SusGroupBuilderComponent } from './sus-group-builder.component';
import { SusGroupBuilderTreeComponent } from './sus-group-tree/sus-group-tree.component';

@NgModule({
  imports: [
    CommonModule,
    SmartadminFormsModule,
    FormsModule,
    SmartadminWidgetsModule,
    ReactiveFormsModule,
    DndModule
  ],
  declarations: [SusGroupBuilderComponent, SusGroupBuilderTreeComponent],
  providers: [NodeService, OrganizationService, LocationService, SustainabilityService],
  exports: [SusGroupBuilderComponent, SusGroupBuilderTreeComponent]
})
export class SusGroupBuilderModule { }
