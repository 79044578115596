import { LocationRoutes } from "@app/core/api-routes.enum";
import { environment } from "@env/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class LocationService {
  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) {}

  getNodeLocations(): Observable<any> {
    return this.http.get<any>(
      this.BASE_URL + LocationRoutes.BASE_URL_V2 + LocationRoutes.Node_Locations
    );
  }
  getLocations(): Observable<any> {
    return this.http.get<any>(this.BASE_URL + LocationRoutes.BASE_URL);
  }

  getLocation(id): Observable<any> {
    return this.http.get<any>(
      this.BASE_URL + LocationRoutes.BASE_URL + "/" + id
    );
  }

  addLocation(location): Observable<any> {
    return this.http.post<any>(
      this.BASE_URL + LocationRoutes.BASE_URL,
      location
    );
  }

  deleteLocation(location: any): Observable<any> {
    return this.http.delete<any>(
      this.BASE_URL + LocationRoutes.BASE_URL + "/" + location.id
    );
  }

  editLocation(location): Observable<any> {
    return this.http.put<any>(
      this.BASE_URL + LocationRoutes.BASE_URL + "/" + location.id,
      location
    );
  }
}
