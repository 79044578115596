import {Component, OnInit, Input} from '@angular/core';

@Component({

  selector: 'sa-big-breadcrumbs',
  template: `
   <div><h1 class="page-title txt-color-blueDark">
   <img *ngIf="image" style="max-height: 50px;" [src]="image" />
   <i *ngIf="icon" class="fa-fw fa fa-{{icon}}"></i>
   <i *ngIf="fasIcon" class="fa-fw fas {{fasIcon}}"></i>
   <i *ngIf="farIcon" class="fa-fw far {{farIcon}}"></i>
   <i *ngIf="fabIcon" style="font-size: 64px;" class="fa-fw fab {{fabIcon}}"></i>
   {{items[0]}}
   <span *ngFor="let item of items.slice(1)">> {{item}}</span>
</h1></div>
  `,
})
export class BigBreadcrumbsComponent implements OnInit {

  @Input() public icon: string;
  @Input() public image: string;
  @Input() public fasIcon: string;
  @Input() public farIcon: string;
  @Input() public fabIcon: string;
  @Input() public items: Array<string>;


  constructor() {}

  ngOnInit() {
  }

}
