import { Directive, ElementRef, Renderer2, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { of } from 'rxjs';

@Directive({
  selector: '[videoEventListener]'
})
export class VideoMetaDataDirective {

  private _listenForVideoEndEvent = false;
  @Input('listenForVideoEndEvent')
  set listenForVideoEndEvent(bool) {
    this._listenForVideoEndEvent = bool;
  }
  get listenForVideoEndEvent() {
    return this._listenForVideoEndEvent;
  }

  private _maximumAllowedAutoPlays = 1000;
  @Input('maximumAllowedAutoPlays')
  set maximumAllowedAutoPlays(n) {
    this._maximumAllowedAutoPlays = n * 1; // times one is a shortcircut for converting strings to numbers. allows user to enter string or number as input
  }
  get maximumAllowedAutoPlays() {
    return this._maximumAllowedAutoPlays;
  }

  private _forceRemoveEventListener = false;
  @Input('forceRemoveEventListener')
  set forceRemoveEventListener(bool) {
    this._forceRemoveEventListener = bool;
  }
  get forceRemoveEventListener() {
    return this._forceRemoveEventListener;
  }

  private _muteVideo = false;
  @Input('muteVideo')
  set muteVideo(bool) {
    this._muteVideo = bool;
  }
  get muteVideo() {
    return this._muteVideo;
  }

  public completedVideos = 0;

  @Output('afterVideoCompletion') afterVideoCompletion = new EventEmitter<any>();

  constructor(
    private host: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if (this.forceRemoveEventListener) {
    
    }
  }

  @HostListener('canplay', ['$event']) onVideoCanPlay(e) {
    if (this.muteVideo) {
      e.target.muted = true;
    }
  }

  @HostListener('ended', ['$event']) onVideoEnded(e) {
    if (this.listenForVideoEndEvent) {
      this.completedVideos = ++this.completedVideos;
      let playbackData = {
        numberOfVideosViewed: this.completedVideos,
        maxViewsAllowed: this.maximumAllowedAutoPlays,
        viewsRemaining: this.maximumAllowedAutoPlays - this.completedVideos
      };
      this.afterVideoCompletion.emit(playbackData)
    }
  }

}