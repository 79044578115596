import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { FormGroup } from '@angular/forms';
import { NodeService } from '@app/core/services/backend/node.service';
import { TransferLotHelperService, NodesStoreService } from '@app/core-v2/services';
import { LotService } from '@app/core/services/backend/lot.service';
import { Router, ActivatedRoute } from '@angular/router';
import { takeWhile, tap } from 'rxjs/operators';
import { TransferLotEntityV2 } from '@app/shared-v2/models/transfers/transfer-lot-payload.model';
import { AnalyticData } from '@app/shared-v2/models/analytic-data.model';

import * as TransferUtils from '@app/core-v2/utils/transfer-utils';
import * as _ from 'lodash';

@Component({
  selector: 'bext-transfer-one-one',
  templateUrl: './transfer-one-one.component.html',
  styleUrls: ['./transfer-one-one.component.scss']
})
export class TransferOneOneComponent implements OnInit {
  
  private alive = true;

  @ViewChild('stepper') stepper: CdkStepper;
  @Input('nodeList') nodeList: any;
  @Input('currentNodeDetails') currentNodeDetails: any;
  @Output('submitTransfer') submitTransfer = new EventEmitter<any>();

  loadingMessage;
  selectedStepIndex = 0;
  stepperSelectionChangeObject;
  selectNodeForm: FormGroup;
  transferForm: FormGroup;
  testForm: FormGroup;
  form: FormGroup;
  transferLotChanges;
  selectedNode;
  loadingNodeUiData = false;
  showForm = false;
  formConfig;
  transferLotPayload: any;
  // newUiDataForForms = [
  //   {
  //     type: "input",
  //     label: "Username",
  //     inputType: "text",
  //     name: "name",
  //     validations: [
  //       {
  //         name: "required",
  //         validator: Validators.required,
  //         message: "Name Required"
  //       },
  //       {
  //         name: "pattern",
  //         validator: Validators.pattern("^[a-zA-Z0-9 _-]+$"),
  //         message: "Accepts only alphanumerics, -, and _"
  //       }
  //     ]
  //   },
  //   {
  //     type: "input",
  //     label: "Email Address",
  //     inputType: "email",
  //     name: "email",
  //     validations: [
  //       {
  //         name: "required",
  //         validator: Validators.required,
  //         message: "Email Required"
  //       },
  //       {
  //         name: "pattern",
  //         validator: Validators.pattern(
  //           "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"
  //         ),
  //         message: "Invalid email"
  //       }
  //     ],
  //     value: "carey@bext360.com"
  //   },
  //   {
  //     type: "input",
  //     label: "Password",
  //     inputType: "password",
  //     name: "password",
  //     validations: [
  //       {
  //         name: "required",
  //         validator: Validators.required,
  //         message: "Password Required"
  //       }
  //     ]
  //   },
  //   {
  //     type: "radiobutton",
  //     label: "Gender",
  //     name: "gender",
  //     options: [
  //       { label: "Male", value: "Male"},
  //       { label: "Female", value: "Female"}
  //     ],
  //     value: "Male"
  //   },
  //   {
  //     type: "date",
  //     label: "DOB",
  //     name: "dob",
  //     validations: [
  //       {
  //         name: "required",
  //         validator: Validators.required,
  //         message: "Date of Birth Required"
  //       }
  //     ]
  //   },
  //   {
  //     type: "select",
  //     label: "Country",
  //     name: "country",
  //     value: "UK",
  //     options: [
  //       { label: "India", value: "India"},
  //       { label: "UAE", value: "UAE"},
  //       { label: "UK", value: "UK"},
  //       { label: "US", value: "US"}
  //     ]
  //   },
  //   {
  //     type: "checkbox",
  //     label: "Accept Terms",
  //     name: "term",
  //     value: true
  //   }
  // ];


  constructor(private nodeService: NodeService, private transferLotHelperService: TransferLotHelperService, private lotService: LotService, private router: Router, private nodeStore: NodesStoreService, private route: ActivatedRoute) { }


  ngOnInit() {

    this.transferLotHelperService.addTransferFromNode(this.currentNodeDetails);

    this.transferLotHelperService.transferLotChange
      .pipe(
        takeWhile(() => this.alive),
        tap(changes => {
          this.transferLotChanges = changes;
          if (this.transferLotPayload) {
            this.transferLotPayload.createTransferLotDtos(this.transferLotChanges.selectedLots, this.transferLotChanges.transferFromNode, this.selectedNode)
            console.log('%c Update transfer payload lot dtos with  changes: ', 'background: #41ff6b; color: #ff4700;', this.transferLotPayload);
          }
        })
      )
      .subscribe()

    this.nodeStore.loadNodeUiDataChange
      .pipe(
        takeWhile(() => this.alive),
        tap((event) => this.loadingNodeUiData = event)
      )
      .subscribe()

  }

  nodeSelected(event) {
    this.loadingMessage = 'creating a transfer form...';
    this.nodeStore.loadNodeUiDataSpinnerShow();
    let node = event.value;
    this.selectedNode = node;
    this.nodeService.getNodeUiData(this.selectedNode.NodeId)
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        nodeUiData => {
          console.log('GET node uidata successful: ', nodeUiData);
          let previousStepIsForm = this.stepperSelectionChangeObject && this.stepperSelectionChangeObject.previouslySelectedIndex === 1 ? true : false;
          if (this.transferLotHelperService.transferLotSubject.formConfig.sections.length) {
            this.showForm = false;
            if (previousStepIsForm) {
              this.stepperSelectionChangeObject.previouslySelectedStep.reset();
            }
            this.transferLotHelperService.resetFormConfig();
          }
          let formattedUiData = TransferUtils.UiDataUtils.convertUiDataToNewFormart(nodeUiData);

          // create a SAVE button because backend does not contain UiData for button
          let addtionalUiData = [
            // // ----- FAKE SECTION TO TEST MULTIPLE UPLOADS -----
            // { sectionName: 'Fake Certification', fields: [{ "type": "fileupload", "label": "FAKE CERT", "inputType": "file", "name": "FAKECERT.Measure", "visible": true}] },
            // // ----- END FAKE SECTION -----
            { sectionName: 'SUBMIT', fields: [{ "label": "Create Lot", "type": "button", "visible": true }] },
          ];

          addtionalUiData.map(UiData => formattedUiData.push(UiData));
          // --------

          // console.log('Formatted UIdata: ', formattedUiData);
          this.addSelectedLotStartWeightToFormattedNodeUiData(formattedUiData, this.transferLotHelperService.selectedLots[0]);

          this.transferLotPayload = new TransferLotEntityV2(
            {
              "TransferType": this.transferLotHelperService.transferLotSubject.selectedLots.length > 1 ? 'ManyLotsToOneNewLot' : "OneLotToOneNewLot",
              "ForwardTransfer": true,
              "FullTransfer": true,
              "CloseTransferredLots": true
            },
            []
          );

          this.transferLotPayload.createTransferLotDtos(this.transferLotHelperService.transferLotSubject.selectedLots, this.transferLotHelperService.transferLotSubject.transferFromNode, this.selectedNode)

          console.log('transferLotPayload: ', this.transferLotPayload.payloadValue);

          this.transferLotHelperService.addFormConfig('sections', formattedUiData);
          this.transferLotHelperService.addFormConfig('payload', TransferUtils.OneToOneTransferPayload);
          this.transferLotHelperService.addTransferToNode(node);
          this.formConfig = this.transferLotHelperService.transferLotSubject.formConfig;
          console.log('%c subject values: ', 'background: #41ff6b; color: #ff4700;', this.transferLotHelperService.transferLotSubject);
          this.nodeStore.loadNodeUiDataSpinnerHide();
          this.loadingMessage = undefined;
          return;
        },
        error => {
          console.log('GET node uidata failed: ', error);
          this.nodeStore.loadNodeUiDataSpinnerHide();
          this.loadingMessage = undefined;
          return;
        }
      );

  }

  matStepperSelectionChange(event: StepperSelectionEvent) {
    // console.log('%c MatStepperChange: ', 'background: #fae552; color: #323232;', event);
    if (event.selectedIndex === 1) this.showForm = true;
    this.stepperSelectionChangeObject = event;
    this.selectedStepIndex = event.selectedIndex;
  }

  previousStep() {
    this.selectedStepIndex--;
  }

  onSubmit(payload: any) {
    let absorbedFromNode = this.currentNodeDetails ? this.currentNodeDetails : this.nodeStore.currentNodeDetails ? this.nodeStore.currentNodeDetails : null;
    if (absorbedFromNode === null) return;
    // console.log('%c form submitted: ', 'background: #41ff6b; color: #ff4700;', payload);
    console.log('%c on submit transferlotchanges object: ', 'background: #41ff6b; color: #ff4700;', this.transferLotChanges);
    this.transferLotHelperService.setAbosrbedFromLot(absorbedFromNode)
    this.transferLotHelperService.createTransferredToLot(this.selectedNode.NodeId);

    // console.log('transfer lot subject: ', this.transferLotChanges);

    Object.keys(payload).forEach((path) => {
      let transferredToLot = this.transferLotChanges.formConfig.payload.TransferLotV1Dtos[0].TransferredToLot;
      let fieldExists = _.get(transferredToLot, path);
      if (fieldExists !== undefined) {
        _.set(transferredToLot, path, payload[path])
      } else {
        let analyticData = new AnalyticData(path, payload[path]);
        // console.log('%c new analytic data field: ', 'background: #41ff6b; color: #ff4700;', analyticData);
        transferredToLot.LotDatas.push(analyticData);
      }
    })

    this.transferLotChanges.formConfig.payload.TransferLotV1Dtos[0].TransferredToLot.LotMetaData.NodeId = this.transferLotChanges.transferToNode.NodeId;

    this.submitTransfer.emit(this.transferLotChanges.formConfig.payload);

    // console.log('%c TRANSFER LOT PAYLOAD: ', 'background: #fae552; color: #323232;', this.transferLotChanges.formConfig.payload);
    // this.lotService.transferLotsV2(this.transferLotChanges.formConfig.payload).subscribe(
    //   res => {
    //     console.log('POST transferLots success: ', res);
    //     this.nodeStore.loadingSpinnerHide();
    //     this.transferLotHelperService.reInstantiateTransferLotSubject();
    //     this.router.navigate([this.redirectUrl]);
    //   },
    //   error => {
    //     console.log('POST transferLots failed: ', error);
    //     this.nodeStore.loadingSpinnerHide();
    //     this.nodeStore.resetNodeUiData();
    //     return;
    //   }
    // );
  }

  addSelectedLotStartWeightToFormattedNodeUiData(formattedNodeUiData, selectedLot) {
    formattedNodeUiData.forEach((section) => {
      section.fields.forEach((field, i) => {
        if (field.hasOwnProperty('name')) {
          let lotStartWeightFieldMatch = field.name.match(/(lotstartweight.measure|lotcurrentweight.measure)/i);
          console.log('field match: ', lotStartWeightFieldMatch);
          if (lotStartWeightFieldMatch !== null) {
            if (field.hasOwnProperty('value')) {
              field.value = selectedLot.LotCurrentWeight ? selectedLot.LotCurrentWeight : selectedLot.LotStartWeight;
            }
            else {
              field['value'] = selectedLot.LotCurrentWeight ? selectedLot.LotCurrentWeight : selectedLot.LotStartWeight;
            }
          }
        }
      })
    })
  }

  ngOnDestroy() {
    if (this.transferLotHelperService.transferLotSubject.selectedLots.length === 0) this.transferLotHelperService.resetTransferTypeConfigAndSelectedLots();
    this.stepper.reset();
    this.alive = false;
  }

}
