import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bext-sdg-image-gallery',
  templateUrl: './sdg-image-gallery.component.html',
  styleUrls: ['./sdg-image-gallery.component.css']
})
export class SdgImageGalleryComponent implements OnInit {

  @Input('images') images: any[];
  @Input('disableImageHover') disableImageHover: any;
  @Output() imageClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onImageClick(event, image, index, imageRef) {
    event.stopPropagation();
    event.preventDefault();
    imageRef.style.opacity = (imageRef.style.opacity == 1) ? '0.1' : '1';
    this.imageClicked.emit(image);
  }

}
