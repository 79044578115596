export const CountryList: any = [
  {
    "name": "Afghanistan",
    "states": [
      {
        "name": "Badakhshan"
      },
      {
        "name": "Badghis"
      },
      {
        "name": "Baghlan"
      },
      {
        "name": "Balkh"
      },
      {
        "name": "Bamian"
      },
      {
        "name": "Daykondi"
      },
      {
        "name": "Farah"
      },
      {
        "name": "Faryab"
      },
      {
        "name": "Ghazni"
      },
      {
        "name": "Ghowr"
      },
      {
        "name": "Helmand"
      },
      {
        "name": "Herat"
      },
      {
        "name": "Jowzjan"
      },
      {
        "name": "Kabul"
      },
      {
        "name": "Kandahar"
      },
      {
        "name": "Kapisa"
      },
      {
        "name": "Khost"
      },
      {
        "name": "Konar"
      },
      {
        "name": "Kondoz"
      },
      {
        "name": "Laghman"
      },
      {
        "name": "Lowgar"
      },
      {
        "name": "Nangarhar"
      },
      {
        "name": "Nimruz"
      },
      {
        "name": "Nurestan"
      },
      {
        "name": "Oruzgan"
      },
      {
        "name": "Paktia"
      },
      {
        "name": "Paktika"
      },
      {
        "name": "Panjshir"
      },
      {
        "name": "Parvan"
      },
      {
        "name": "Samangan"
      },
      {
        "name": "Sar-e Pol"
      },
      {
        "name": "Takhar"
      },
      {
        "name": "Vardak"
      },
      {
        "name": "Zabol"
      }
    ]
  },
  {
    "name": "Albania",
    "states": [
      {
        "name": "Berat"
      },
      {
        "name": "Dibres"
      },
      {
        "name": "Durres"
      },
      {
        "name": "Elbasan"
      },
      {
        "name": "Fier"
      },
      {
        "name": "Gjirokastre"
      },
      {
        "name": "Korce"
      },
      {
        "name": "Kukes"
      },
      {
        "name": "Lezhe"
      },
      {
        "name": "Shkoder"
      },
      {
        "name": "Tirane"
      },
      {
        "name": "Vlore"
      }
    ]
  },
  {
    "name": "Algeria",
    "states": [
      {
        "name": "Adrar"
      },
      {
        "name": "Ain Defla"
      },
      {
        "name": "Ain Temouchent"
      },
      {
        "name": "Alger"
      },
      {
        "name": "Annaba"
      },
      {
        "name": "Batna"
      },
      {
        "name": "Bechar"
      },
      {
        "name": "Bejaia"
      },
      {
        "name": "Biskra"
      },
      {
        "name": "Blida"
      },
      {
        "name": "Bordj Bou Arreridj"
      },
      {
        "name": "Bouira"
      },
      {
        "name": "Boumerdes"
      },
      {
        "name": "Chlef"
      },
      {
        "name": "Constantine"
      },
      {
        "name": "Djelfa"
      },
      {
        "name": "El Bayadh"
      },
      {
        "name": "El Oued"
      },
      {
        "name": "El Tarf"
      },
      {
        "name": "Ghardaia"
      },
      {
        "name": "Guelma"
      },
      {
        "name": "Illizi"
      },
      {
        "name": "Jijel"
      },
      {
        "name": "Khenchela"
      },
      {
        "name": "Laghouat"
      },
      {
        "name": "Muaskar"
      },
      {
        "name": "Medea"
      },
      {
        "name": "Mila"
      },
      {
        "name": "Mostaganem"
      },
      {
        "name": "M'Sila"
      },
      {
        "name": "Naama"
      },
      {
        "name": "Oran"
      },
      {
        "name": "Ouargla"
      },
      {
        "name": "Oum el Bouaghi"
      },
      {
        "name": "Relizane"
      },
      {
        "name": "Saida"
      },
      {
        "name": "Setif"
      },
      {
        "name": "Sidi Bel Abbes"
      },
      {
        "name": "Skikda"
      },
      {
        "name": "Souk Ahras"
      },
      {
        "name": "Tamanghasset"
      },
      {
        "name": "Tebessa"
      },
      {
        "name": "Tiaret"
      },
      {
        "name": "Tindouf"
      },
      {
        "name": "Tipaza"
      },
      {
        "name": "Tissemsilt"
      },
      {
        "name": "Tizi Ouzou"
      },
      {
        "name": "Tlemcen"
      }
    ]
  },
  {
    "name": "Andorra",
    "states": [
      {
        "name": "Andorra la Vella"
      },
      {
        "name": "Canillo"
      },
      {
        "name": "Encamp"
      },
      {
        "name": "Escaldes-Engordany"
      },
      {
        "name": "La Massana"
      },
      {
        "name": "Ordino"
      },
      {
        "name": "Sant Julia de Loria"
      }
    ]
  },
  {
    "name": "Angola",
    "states": [
      {
        "name": "Bengo"
      },
      {
        "name": "Benguela"
      },
      {
        "name": "Bie"
      },
      {
        "name": "Cabinda"
      },
      {
        "name": "Cuando Cubango"
      },
      {
        "name": "Cuanza Norte"
      },
      {
        "name": "Cuanza Sul"
      },
      {
        "name": "Cunene"
      },
      {
        "name": "Huambo"
      },
      {
        "name": "Huila"
      },
      {
        "name": "Luanda"
      },
      {
        "name": "Lunda Norte"
      },
      {
        "name": "Lunda Sul"
      },
      {
        "name": "Malanje"
      },
      {
        "name": "Moxico"
      },
      {
        "name": "Namibe"
      },
      {
        "name": "Uige"
      },
      {
        "name": "Zaire"
      }
    ]
  },
  {
    "name": "Antarctica",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Antigua and Barbuda",
    "states": [
      {
        "name": "Barbuda"
      },
      {
        "name": "Redonda"
      },
      {
        "name": "Saint George"
      },
      {
        "name": "Saint John"
      },
      {
        "name": "Saint Mary"
      },
      {
        "name": "Saint Paul"
      },
      {
        "name": "Saint Peter"
      },
      {
        "name": "Saint Philip"
      }
    ]
  },
  {
    "name": "Argentina",
    "states": [
      {
        "name": "Buenos Aires"
      },
      {
        "name": "Buenos Aires Capital"
      },
      {
        "name": "Catamarca"
      },
      {
        "name": "Chaco"
      },
      {
        "name": "Chubut"
      },
      {
        "name": "Cordoba"
      },
      {
        "name": "Corrientes"
      },
      {
        "name": "Entre Rios"
      },
      {
        "name": "Formosa"
      },
      {
        "name": "Jujuy"
      },
      {
        "name": "La Pampa"
      },
      {
        "name": "La Rioja"
      },
      {
        "name": "Mendoza"
      },
      {
        "name": "Misiones"
      },
      {
        "name": "Neuquen"
      },
      {
        "name": "Rio Negro"
      },
      {
        "name": "Salta"
      },
      {
        "name": "San Juan"
      },
      {
        "name": "San Luis"
      },
      {
        "name": "Santa Cruz"
      },
      {
        "name": "Santa Fe"
      },
      {
        "name": "Santiago del Estero"
      },
      {
        "name": "Tierra del Fuego"
      },
      {
        "name": "Tucuman"
      }
    ]
  },
  {
    "name": "Armenia",
    "states": [
      {
        "name": "Aragatsotn"
      },
      {
        "name": "Ararat"
      },
      {
        "name": "Armavir"
      },
      {
        "name": "Geghark'unik'"
      },
      {
        "name": "Kotayk'"
      },
      {
        "name": "Lorri"
      },
      {
        "name": "Shirak"
      },
      {
        "name": "Syunik'"
      },
      {
        "name": "Tavush"
      },
      {
        "name": "Vayots' Dzor"
      },
      {
        "name": "Yerevan"
      }
    ]
  },
  {
    "name": "Australia",
    "states": [
      {
        "name": "New South Wales"
      },
      {
        "name": "Northern Territory"
      },
      {
        "name": "Queensland"
      },
      {
        "name": "South Australia"
      },
      {
        "name": "Victoria"
      },
      {
        "name": "Western Australia"
      }
    ]
  },
  {
    "name": "Austria",
    "states": [
      {
        "name": "Burgenland"
      },
      {
        "name": "Kaernten"
      },
      {
        "name": "Niederoesterreich"
      },
      {
        "name": "Oberoesterreich"
      },
      {
        "name": "Salzburg"
      },
      {
        "name": "Steiermark"
      },
      {
        "name": "Tirol"
      },
      {
        "name": "Vorarlberg"
      },
      {
        "name": "Wien"
      }
    ]
  },
  {
    "name": "Azerbaijan",
    "states": [
      {
        "name": "Abseron Rayonu"
      },
      {
        "name": "Agcabadi Rayonu"
      },
      {
        "name": "Agdam Rayonu"
      },
      {
        "name": "Agdas Rayonu"
      },
      {
        "name": "Agstafa Rayonu"
      },
      {
        "name": "Agsu Rayonu"
      },
      {
        "name": "Astara Rayonu"
      },
      {
        "name": "Balakan Rayonu"
      },
      {
        "name": "Barda Rayonu"
      },
      {
        "name": "Beylaqan Rayonu"
      },
      {
        "name": "Bilasuvar Rayonu"
      },
      {
        "name": "Cabrayil Rayonu"
      },
      {
        "name": "Calilabad Rayonu"
      },
      {
        "name": "Daskasan Rayonu"
      },
      {
        "name": "Davaci Rayonu"
      },
      {
        "name": "Fuzuli Rayonu"
      },
      {
        "name": "Gadabay Rayonu"
      },
      {
        "name": "Goranboy Rayonu"
      },
      {
        "name": "Goycay Rayonu"
      },
      {
        "name": "Haciqabul Rayonu"
      },
      {
        "name": "Imisli Rayonu"
      },
      {
        "name": "Ismayilli Rayonu"
      },
      {
        "name": "Kalbacar Rayonu"
      },
      {
        "name": "Kurdamir Rayonu"
      },
      {
        "name": "Lacin Rayonu"
      },
      {
        "name": "Lankaran Rayonu"
      },
      {
        "name": "Lerik Rayonu"
      },
      {
        "name": "Masalli Rayonu"
      },
      {
        "name": "Neftcala Rayonu"
      },
      {
        "name": "Oguz Rayonu"
      },
      {
        "name": "Qabala Rayonu"
      },
      {
        "name": "Qax Rayonu"
      },
      {
        "name": "Qazax Rayonu"
      },
      {
        "name": "Qobustan Rayonu"
      },
      {
        "name": "Quba Rayonu"
      },
      {
        "name": "Qubadli Rayonu"
      },
      {
        "name": "Qusar Rayonu"
      },
      {
        "name": "Saatli Rayonu"
      },
      {
        "name": "Sabirabad Rayonu"
      },
      {
        "name": "Saki Rayonu"
      },
      {
        "name": "Salyan Rayonu"
      },
      {
        "name": "Samaxi Rayonu"
      },
      {
        "name": "Samkir Rayonu"
      },
      {
        "name": "Samux Rayonu"
      },
      {
        "name": "Siyazan Rayonu"
      },
      {
        "name": "Susa Rayonu"
      },
      {
        "name": "Tartar Rayonu"
      },
      {
        "name": "Tovuz Rayonu"
      },
      {
        "name": "Ucar Rayonu"
      },
      {
        "name": "Xacmaz Rayonu"
      },
      {
        "name": "Xanlar Rayonu"
      },
      {
        "name": "Xizi Rayonu"
      },
      {
        "name": "Xocali Rayonu"
      },
      {
        "name": "Xocavand Rayonu"
      },
      {
        "name": "Yardimli Rayonu"
      },
      {
        "name": "Yevlax Rayonu"
      },
      {
        "name": "Zangilan Rayonu"
      },
      {
        "name": "Zaqatala Rayonu"
      },
      {
        "name": "Zardab Rayonu"
      },
      {
        "name": "Ali Bayramli Sahari"
      },
      {
        "name": "Baki Sahari"
      },
      {
        "name": "Ganca Sahari"
      },
      {
        "name": "Lankaran Sahari"
      },
      {
        "name": "Mingacevir Sahari"
      },
      {
        "name": "Naftalan Sahari"
      },
      {
        "name": "Saki Sahari"
      },
      {
        "name": "Sumqayit Sahari"
      },
      {
        "name": "Susa Sahari"
      },
      {
        "name": "Xankandi Sahari"
      },
      {
        "name": "Yevlax Sahari"
      },
      {
        "name": "Naxcivan Muxtar"
      }
    ]
  },
  {
    "name": "Bahamas",
    "states": [
      {
        "name": "Acklins and Crooked Islands"
      },
      {
        "name": "Bimini"
      },
      {
        "name": "Cat Island"
      },
      {
        "name": "Exuma"
      },
      {
        "name": "Freeport"
      },
      {
        "name": "Fresh Creek"
      },
      {
        "name": "Governor's Harbour"
      },
      {
        "name": "Green Turtle Cay"
      },
      {
        "name": "Harbour Island"
      },
      {
        "name": "High Rock"
      },
      {
        "name": "Inagua"
      },
      {
        "name": "Kemps Bay"
      },
      {
        "name": "Long Island"
      },
      {
        "name": "Marsh Harbour"
      },
      {
        "name": "Mayaguana"
      },
      {
        "name": "New Providence"
      },
      {
        "name": "Nichollstown and Berry Islands"
      },
      {
        "name": "Ragged Island"
      },
      {
        "name": "Rock Sound"
      },
      {
        "name": "Sandy Point"
      },
      {
        "name": "San Salvador and Rum Cay"
      }
    ]
  },
  {
    "name": "Bahrain",
    "states": [
      {
        "name": "Al Hadd"
      },
      {
        "name": "Al Manamah"
      },
      {
        "name": "Al Mintaqah al Gharbiyah"
      },
      {
        "name": "Al Mintaqah al Wusta"
      },
      {
        "name": "Al Mintaqah ash Shamaliyah"
      },
      {
        "name": "Al Muharraq"
      },
      {
        "name": "Ar Rifa' wa al Mintaqah al Janubiyah"
      },
      {
        "name": "Jidd Hafs"
      },
      {
        "name": "Madinat Hamad"
      },
      {
        "name": "Madinat 'Isa"
      },
      {
        "name": "Juzur Hawar"
      },
      {
        "name": "Sitrah"
      }
    ]
  },
  {
    "name": "Bangladesh",
    "states": [
      {
        "name": "Barisal"
      },
      {
        "name": "Chittagong"
      },
      {
        "name": "Dhaka"
      },
      {
        "name": "Khulna"
      },
      {
        "name": "Rajshahi"
      },
      {
        "name": "Sylhet"
      }
    ]
  },
  {
    "name": "Barbados",
    "states": [
      {
        "name": "Christ Church"
      },
      {
        "name": "Saint Andrew"
      },
      {
        "name": "Saint George"
      },
      {
        "name": "Saint James"
      },
      {
        "name": "Saint John"
      },
      {
        "name": "Saint Joseph"
      },
      {
        "name": "Saint Lucy"
      },
      {
        "name": "Saint Michael"
      },
      {
        "name": "Saint Peter"
      },
      {
        "name": "Saint Philip"
      },
      {
        "name": "Saint Thomas"
      }
    ]
  },
  {
    "name": "Belarus",
    "states": [
      {
        "name": "Brest"
      },
      {
        "name": "Homyel"
      },
      {
        "name": "Horad Minsk"
      },
      {
        "name": "Hrodna"
      },
      {
        "name": "Mahilyow"
      },
      {
        "name": "Minsk"
      },
      {
        "name": "Vitsyebsk"
      }
    ]
  },
  {
    "name": "Belgium",
    "states": [
      {
        "name": "Antwerpen"
      },
      {
        "name": "Brabant Wallon"
      },
      {
        "name": "Brussels"
      },
      {
        "name": "Flanders"
      },
      {
        "name": "Hainaut"
      },
      {
        "name": "Liege"
      },
      {
        "name": "Limburg"
      },
      {
        "name": "Luxembourg"
      },
      {
        "name": "Namur"
      },
      {
        "name": "Oost-Vlaanderen"
      },
      {
        "name": "Vlaams-Brabant"
      },
      {
        "name": "Wallonia"
      },
      {
        "name": "West-Vlaanderen"
      }
    ]
  },
  {
    "name": "Belize",
    "states": [
      {
        "name": "Belize"
      },
      {
        "name": "Cayo"
      },
      {
        "name": "Corozal"
      },
      {
        "name": "Orange Walk"
      },
      {
        "name": "Stann Creek"
      },
      {
        "name": "Toledo"
      }
    ]
  },
  {
    "name": "Benin",
    "states": [
      {
        "name": "Alibori"
      },
      {
        "name": "Atakora"
      },
      {
        "name": "Atlantique"
      },
      {
        "name": "Borgou"
      },
      {
        "name": "Collines"
      },
      {
        "name": "Donga"
      },
      {
        "name": "Kouffo"
      },
      {
        "name": "Littoral"
      },
      {
        "name": "Mono"
      },
      {
        "name": "Oueme"
      },
      {
        "name": "Plateau"
      },
      {
        "name": "Zou"
      }
    ]
  },
  {
    "name": "Bermuda",
    "states": [
      {
        "name": "Devonshire"
      },
      {
        "name": "Hamilton"
      },
      {
        "name": "Hamilton"
      },
      {
        "name": "Paget"
      },
      {
        "name": "Pembroke"
      },
      {
        "name": "Saint George"
      },
      {
        "name": "Saint George's"
      },
      {
        "name": "Sandys"
      },
      {
        "name": "Smith's"
      },
      {
        "name": "Southampton"
      },
      {
        "name": "Warwick"
      }
    ]
  },
  {
    "name": "Bhutan",
    "states": [
      {
        "name": "Bumthang"
      },
      {
        "name": "Chukha"
      },
      {
        "name": "Dagana"
      },
      {
        "name": "Gasa"
      },
      {
        "name": "Haa"
      },
      {
        "name": "Lhuntse"
      },
      {
        "name": "Mongar"
      },
      {
        "name": "Paro"
      },
      {
        "name": "Pemagatshel"
      },
      {
        "name": "Punakha"
      },
      {
        "name": "Samdrup Jongkhar"
      },
      {
        "name": "Samtse"
      },
      {
        "name": "Sarpang"
      },
      {
        "name": "Thimphu"
      },
      {
        "name": "Trashigang"
      },
      {
        "name": "Trashiyangste"
      },
      {
        "name": "Trongsa"
      },
      {
        "name": "Tsirang"
      },
      {
        "name": "Wangdue Phodrang"
      },
      {
        "name": "Zhemgang"
      }
    ]
  },
  {
    "name": "Bolivia",
    "states": [
      {
        "name": "Chuquisaca"
      },
      {
        "name": "Cochabamba"
      },
      {
        "name": "Beni"
      },
      {
        "name": "La Paz"
      },
      {
        "name": "Oruro"
      },
      {
        "name": "Pando"
      },
      {
        "name": "Potosi"
      },
      {
        "name": "Santa Cruz"
      },
      {
        "name": "Tarija"
      }
    ]
  },
  {
    "name": "Bosnia and Herzegovina",
    "states": [
      {
        "name": "Una-Sana [Federation]"
      },
      {
        "name": "Posavina [Federation]"
      },
      {
        "name": "Tuzla [Federation]"
      },
      {
        "name": "Zenica-Doboj [Federation]"
      },
      {
        "name": "Bosnian Podrinje [Federation]"
      },
      {
        "name": "Central Bosnia [Federation]"
      },
      {
        "name": "Herzegovina-Neretva [Federation]"
      },
      {
        "name": "West Herzegovina [Federation]"
      },
      {
        "name": "Sarajevo [Federation]"
      },
      {
        "name": " West Bosnia [Federation]"
      },
      {
        "name": "Banja Luka [RS]"
      },
      {
        "name": "Bijeljina [RS]"
      },
      {
        "name": "Doboj [RS]"
      },
      {
        "name": "Fo?a [RS]"
      },
      {
        "name": "Sarajevo-Romanija [RS]"
      },
      {
        "name": "Trebinje [RS]"
      },
      {
        "name": "Vlasenica [RS]"
      }
    ]
  },
  {
    "name": "Botswana",
    "states": [
      {
        "name": "Central"
      },
      {
        "name": "Ghanzi"
      },
      {
        "name": "Kgalagadi"
      },
      {
        "name": "Kgatleng"
      },
      {
        "name": "Kweneng"
      },
      {
        "name": "North East"
      },
      {
        "name": "North West"
      },
      {
        "name": "South East"
      },
      {
        "name": "Southern"
      }
    ]
  },
  {
    "name": "Brazil",
    "states": [
      {
        "name": "Acre"
      },
      {
        "name": "Alagoas"
      },
      {
        "name": "Amapa"
      },
      {
        "name": "Amazonas"
      },
      {
        "name": "Bahia"
      },
      {
        "name": "Ceara"
      },
      {
        "name": "Distrito Federal"
      },
      {
        "name": "Espirito Santo"
      },
      {
        "name": "Goias"
      },
      {
        "name": "Maranhao"
      },
      {
        "name": "Mato Grosso"
      },
      {
        "name": "Mato Grosso do Sul"
      },
      {
        "name": "Minas Gerais"
      },
      {
        "name": "Para"
      },
      {
        "name": "Paraiba"
      },
      {
        "name": "Parana"
      },
      {
        "name": "Pernambuco"
      },
      {
        "name": "Piaui"
      },
      {
        "name": "Rio de Janeiro"
      },
      {
        "name": "Rio Grande do Norte"
      },
      {
        "name": "Rio Grande do Sul"
      },
      {
        "name": "Rondonia"
      },
      {
        "name": "Roraima"
      },
      {
        "name": "Santa Catarina"
      },
      {
        "name": "Sao Paulo"
      },
      {
        "name": "Sergipe"
      },
      {
        "name": "Tocantins"
      }
    ]
  },
  {
    "name": "Brunei",
    "states": [
      {
        "name": "Belait"
      },
      {
        "name": "Brunei and Muara"
      },
      {
        "name": "Temburong"
      },
      {
        "name": "Tutong"
      }
    ]
  },
  {
    "name": "Bulgaria",
    "states": [
      {
        "name": "Blagoevgrad"
      },
      {
        "name": "Burgas"
      },
      {
        "name": "Dobrich"
      },
      {
        "name": "Gabrovo"
      },
      {
        "name": "Khaskovo"
      },
      {
        "name": "Kurdzhali"
      },
      {
        "name": "Kyustendil"
      },
      {
        "name": "Lovech"
      },
      {
        "name": "Montana"
      },
      {
        "name": "Pazardzhik"
      },
      {
        "name": "Pernik"
      },
      {
        "name": "Pleven"
      },
      {
        "name": "Plovdiv"
      },
      {
        "name": "Razgrad"
      },
      {
        "name": "Ruse"
      },
      {
        "name": "Shumen"
      },
      {
        "name": "Silistra"
      },
      {
        "name": "Sliven"
      },
      {
        "name": "Smolyan"
      },
      {
        "name": "Sofiya"
      },
      {
        "name": "Sofiya-Grad"
      },
      {
        "name": "Stara Zagora"
      },
      {
        "name": "Turgovishte"
      },
      {
        "name": "Varna"
      },
      {
        "name": "Veliko Turnovo"
      },
      {
        "name": "Vidin"
      },
      {
        "name": "Vratsa"
      },
      {
        "name": "Yambol"
      }
    ]
  },
  {
    "name": "Burkina Faso",
    "states": [
      {
        "name": "Bale"
      },
      {
        "name": "Bam"
      },
      {
        "name": "Banwa"
      },
      {
        "name": "Bazega"
      },
      {
        "name": "Bougouriba"
      },
      {
        "name": "Boulgou"
      },
      {
        "name": "Boulkiemde"
      },
      {
        "name": "Comoe"
      },
      {
        "name": "Ganzourgou"
      },
      {
        "name": "Gnagna"
      },
      {
        "name": "Gourma"
      },
      {
        "name": "Houet"
      },
      {
        "name": "Ioba"
      },
      {
        "name": "Kadiogo"
      },
      {
        "name": "Kenedougou"
      },
      {
        "name": "Komondjari"
      },
      {
        "name": "Kompienga"
      },
      {
        "name": "Kossi"
      },
      {
        "name": "Koulpelogo"
      },
      {
        "name": "Kouritenga"
      },
      {
        "name": "Kourweogo"
      },
      {
        "name": "Leraba"
      },
      {
        "name": "Loroum"
      },
      {
        "name": "Mouhoun"
      },
      {
        "name": "Namentenga"
      },
      {
        "name": "Nahouri"
      },
      {
        "name": "Nayala"
      },
      {
        "name": "Noumbiel"
      },
      {
        "name": "Oubritenga"
      },
      {
        "name": "Oudalan"
      },
      {
        "name": "Passore"
      },
      {
        "name": "Poni"
      },
      {
        "name": "Sanguie"
      },
      {
        "name": "Sanmatenga"
      },
      {
        "name": "Seno"
      },
      {
        "name": "Sissili"
      },
      {
        "name": "Soum"
      },
      {
        "name": "Sourou"
      },
      {
        "name": "Tapoa"
      },
      {
        "name": "Tuy"
      },
      {
        "name": "Yagha"
      },
      {
        "name": "Yatenga"
      },
      {
        "name": "Ziro"
      },
      {
        "name": "Zondoma"
      },
      {
        "name": "Zoundweogo"
      }
    ]
  },
  {
    "name": "Burma",
    "states": [
      {
        "name": "Ayeyarwady"
      },
      {
        "name": "Bago"
      },
      {
        "name": "Magway"
      },
      {
        "name": "Mandalay"
      },
      {
        "name": "Sagaing"
      },
      {
        "name": "Tanintharyi"
      },
      {
        "name": "Yangon"
      },
      {
        "name": "Chin State"
      },
      {
        "name": "Kachin State"
      },
      {
        "name": "Kayin State"
      },
      {
        "name": "Kayah State"
      },
      {
        "name": "Mon State"
      },
      {
        "name": "Rakhine State"
      },
      {
        "name": "Shan State"
      }
    ]
  },
  {
    "name": "Burundi",
    "states": [
      {
        "name": "Bubanza"
      },
      {
        "name": "Bujumbura Mairie"
      },
      {
        "name": "Bujumbura Rural"
      },
      {
        "name": "Bururi"
      },
      {
        "name": "Cankuzo"
      },
      {
        "name": "Cibitoke"
      },
      {
        "name": "Gitega"
      },
      {
        "name": "Karuzi"
      },
      {
        "name": "Kayanza"
      },
      {
        "name": "Kirundo"
      },
      {
        "name": "Makamba"
      },
      {
        "name": "Muramvya"
      },
      {
        "name": "Muyinga"
      },
      {
        "name": "Mwaro"
      },
      {
        "name": "Ngozi"
      },
      {
        "name": "Rutana"
      },
      {
        "name": "Ruyigi"
      }
    ]
  },
  {
    "name": "Cambodia",
    "states": [
      {
        "name": "Banteay Mean Chey"
      },
      {
        "name": "Batdambang"
      },
      {
        "name": "Kampong Cham"
      },
      {
        "name": "Kampong Chhnang"
      },
      {
        "name": "Kampong Spoe"
      },
      {
        "name": "Kampong Thum"
      },
      {
        "name": "Kampot"
      },
      {
        "name": "Kandal"
      },
      {
        "name": "Koh Kong"
      },
      {
        "name": "Kracheh"
      },
      {
        "name": "Mondol Kiri"
      },
      {
        "name": "Otdar Mean Chey"
      },
      {
        "name": "Pouthisat"
      },
      {
        "name": "Preah Vihear"
      },
      {
        "name": "Prey Veng"
      },
      {
        "name": "Rotanakir"
      },
      {
        "name": "Siem Reab"
      },
      {
        "name": "Stoeng Treng"
      },
      {
        "name": "Svay Rieng"
      },
      {
        "name": "Takao"
      },
      {
        "name": "Keb"
      },
      {
        "name": "Pailin"
      },
      {
        "name": "Phnom Penh"
      },
      {
        "name": "Preah Seihanu"
      }
    ]
  },
  {
    "name": "Cameroon",
    "states": [
      {
        "name": "Adamaoua"
      },
      {
        "name": "Centre"
      },
      {
        "name": "Est"
      },
      {
        "name": "Extreme-Nord"
      },
      {
        "name": "Littoral"
      },
      {
        "name": "Nord"
      },
      {
        "name": "Nord-Ouest"
      },
      {
        "name": "Ouest"
      },
      {
        "name": "Sud"
      },
      {
        "name": "Sud-Ouest"
      }
    ]
  },
  {
    "name": "Canada",
    "states": [
      {
        "name": "Alberta"
      },
      {
        "name": "British Columbia"
      },
      {
        "name": "Manitoba"
      },
      {
        "name": "New Brunswick"
      },
      {
        "name": "Newfoundland and Labrador"
      },
      {
        "name": "Northwest Territories"
      },
      {
        "name": "Nova Scotia"
      },
      {
        "name": "Nunavut"
      },
      {
        "name": "Ontario"
      },
      {
        "name": "Prince Edward Island"
      },
      {
        "name": "Quebec"
      },
      {
        "name": "Saskatchewan"
      },
      {
        "name": "Yukon Territory"
      }
    ]
  },
  {
    "name": "Cape Verde",
    "states": [
      {
        "name": "Barlavento"
      },
      {
        "name": "Sotavento"
      }
    ]
  },
  {
    "name": "Central African Republic",
    "states": [
      {
        "name": "Bamingui-Bangoran"
      },
      {
        "name": "Bangui"
      },
      {
        "name": "Basse-Kotto"
      },
      {
        "name": "Haute-Kotto"
      },
      {
        "name": "Haut-Mbomou"
      },
      {
        "name": "Kemo"
      },
      {
        "name": "Lobaye"
      },
      {
        "name": "Mambere-Kadei"
      },
      {
        "name": "Mbomou"
      },
      {
        "name": "Nana-Grebizi"
      },
      {
        "name": "Nana-Mambere"
      },
      {
        "name": "Ombella-Mpoko"
      },
      {
        "name": "Ouaka"
      },
      {
        "name": "Ouham"
      },
      {
        "name": "Ouham-Pende"
      },
      {
        "name": "Sangha-Mbaere"
      },
      {
        "name": "Vakaga"
      }
    ]
  },
  {
    "name": "Chad",
    "states": [
      {
        "name": "Batha"
      },
      {
        "name": "Biltine"
      },
      {
        "name": "Borkou-Ennedi-Tibesti"
      },
      {
        "name": "Chari-Baguirmi"
      },
      {
        "name": "Guéra"
      },
      {
        "name": "Kanem"
      },
      {
        "name": "Lac"
      },
      {
        "name": "Logone Occidental"
      },
      {
        "name": "Logone Oriental"
      },
      {
        "name": "Mayo-Kebbi"
      },
      {
        "name": "Moyen-Chari"
      },
      {
        "name": "Ouaddaï"
      },
      {
        "name": "Salamat"
      },
      {
        "name": "Tandjile"
      }
    ]
  },
  {
    "name": "Chile",
    "states": [
      {
        "name": "Aysen"
      },
      {
        "name": "Antofagasta"
      },
      {
        "name": "Araucania"
      },
      {
        "name": "Atacama"
      },
      {
        "name": "Bio-Bio"
      },
      {
        "name": "Coquimbo"
      },
      {
        "name": "O'Higgins"
      },
      {
        "name": "Los Lagos"
      },
      {
        "name": "Magallanes y la Antartica Chilena"
      },
      {
        "name": "Maule"
      },
      {
        "name": "Santiago Region Metropolitana"
      },
      {
        "name": "Tarapaca"
      },
      {
        "name": "Valparaiso"
      }
    ]
  },
  {
    "name": "China",
    "states": [
      {
        "name": "Anhui"
      },
      {
        "name": "Fujian"
      },
      {
        "name": "Gansu"
      },
      {
        "name": "Guangdong"
      },
      {
        "name": "Guizhou"
      },
      {
        "name": "Hainan"
      },
      {
        "name": "Hebei"
      },
      {
        "name": "Heilongjiang"
      },
      {
        "name": "Henan"
      },
      {
        "name": "Hubei"
      },
      {
        "name": "Hunan"
      },
      {
        "name": "Jiangsu"
      },
      {
        "name": "Jiangxi"
      },
      {
        "name": "Jilin"
      },
      {
        "name": "Liaoning"
      },
      {
        "name": "Qinghai"
      },
      {
        "name": "Shaanxi"
      },
      {
        "name": "Shandong"
      },
      {
        "name": "Shanxi"
      },
      {
        "name": "Sichuan"
      },
      {
        "name": "Yunnan"
      },
      {
        "name": "Zhejiang"
      },
      {
        "name": "Guangxi"
      },
      {
        "name": "Nei Mongol"
      },
      {
        "name": "Ningxia"
      },
      {
        "name": "Xinjiang"
      },
      {
        "name": "Xizang (Tibet)"
      },
      {
        "name": "Beijing"
      },
      {
        "name": "Chongqing"
      },
      {
        "name": "Shanghai"
      },
      {
        "name": "Tianjin"
      }
    ]
  },
  {
    "name": "Colombia",
    "states": [
      {
        "name": "Amazonas"
      },
      {
        "name": "Antioquia"
      },
      {
        "name": "Arauca"
      },
      {
        "name": "Atlantico"
      },
      {
        "name": "Bogota District Capital"
      },
      {
        "name": "Bolivar"
      },
      {
        "name": "Boyaca"
      },
      {
        "name": "Caldas"
      },
      {
        "name": "Caqueta"
      },
      {
        "name": "Casanare"
      },
      {
        "name": "Cauca"
      },
      {
        "name": "Cesar"
      },
      {
        "name": "Choco"
      },
      {
        "name": "Cordoba"
      },
      {
        "name": "Cundinamarca"
      },
      {
        "name": "Guainia"
      },
      {
        "name": "Guaviare"
      },
      {
        "name": "Huila"
      },
      {
        "name": "La Guajira"
      },
      {
        "name": "Magdalena"
      },
      {
        "name": "Meta"
      },
      {
        "name": "Narino"
      },
      {
        "name": "Norte de Santander"
      },
      {
        "name": "Putumayo"
      },
      {
        "name": "Quindio"
      },
      {
        "name": "Risaralda"
      },
      {
        "name": "San Andres & Providencia"
      },
      {
        "name": "Santander"
      },
      {
        "name": "Sucre"
      },
      {
        "name": "Tolima"
      },
      {
        "name": "Valle del Cauca"
      },
      {
        "name": "Vaupes"
      },
      {
        "name": "Vichada"
      }
    ]
  },
  {
    "name": "Comoros",
    "states": [
      {
        "name": "Grande Comore (Njazidja)"
      },
      {
        "name": "Anjouan (Nzwani)"
      },
      {
        "name": "Moheli (Mwali)"
      }
    ]
  },
  {
    "name": "Congo, Democratic Republic",
    "states": [
      {
        "name": "Bandundu"
      },
      {
        "name": "Bas-Congo"
      },
      {
        "name": "Equateur"
      },
      {
        "name": "Kasai-Occidental"
      },
      {
        "name": "Kasai-Oriental"
      },
      {
        "name": "Katanga"
      },
      {
        "name": "Kinshasa"
      },
      {
        "name": "Maniema"
      },
      {
        "name": "Nord-Kivu"
      },
      {
        "name": "Orientale"
      },
      {
        "name": "Sud-Kivu"
      }
    ]
  },
  {
    "name": "Congo, Republic of the",
    "states": [
      {
        "name": "Bouenza"
      },
      {
        "name": "Brazzaville"
      },
      {
        "name": "Cuvette"
      },
      {
        "name": "Cuvette-Ouest"
      },
      {
        "name": "Kouilou"
      },
      {
        "name": "Lekoumou"
      },
      {
        "name": "Likouala"
      },
      {
        "name": "Niari"
      },
      {
        "name": "Plateaux"
      },
      {
        "name": "Pool"
      },
      {
        "name": "Sangha"
      }
    ]
  },
  {
    "name": "Costa Rica",
    "states": [
      {
        "name": "Alajuela"
      },
      {
        "name": "Cartago"
      },
      {
        "name": "Guanacaste"
      },
      {
        "name": "Heredia"
      },
      {
        "name": "Limon"
      },
      {
        "name": "Puntarenas"
      },
      {
        "name": "San Jose"
      }
    ]
  },
  {
    "name": "Cotê d'Ivoire",
    "states": [
      {
        "name": "Abidjan"
      },
      {
        "name": "Bas-Sassandra District"
      },
      {
        "name": "Comoé District"
      },
      {
        "name": "Denguélé District"
      },
      {
        "name": "Gôh-Djiboua District"
      },
      {
        "name": "Lacs District"
      },
      {
        "name": "Lagunes District"
      },
      {
        "name": "Montagnes District"
      },
      {
        "name": "Sassandra-Marahoué District"
      },
      {
        "name": "Savanes District"
      },
      {
        "name": "Vallée du Bandama District"
      },
      {
        "name": "Woroba District"
      },
      {
        "name": "Yamoussoukro"
      },
      {
        "name": "Zanzan District"
      }
    ]
  },
  {
    "name": "Croatia",
    "states": [
      {
        "name": "Bjelovarsko-Bilogorska"
      },
      {
        "name": "Brodsko-Posavska"
      },
      {
        "name": "Dubrovacko-Neretvanska"
      },
      {
        "name": "Istarska"
      },
      {
        "name": "Karlovacka"
      },
      {
        "name": "Koprivnicko-Krizevacka"
      },
      {
        "name": "Krapinsko-Zagorska"
      },
      {
        "name": "Licko-Senjska"
      },
      {
        "name": "Medimurska"
      },
      {
        "name": "Osjecko-Baranjska"
      },
      {
        "name": "Pozesko-Slavonska"
      },
      {
        "name": "Primorsko-Goranska"
      },
      {
        "name": "Sibensko-Kninska"
      },
      {
        "name": "Sisacko-Moslavacka"
      },
      {
        "name": "Splitsko-Dalmatinska"
      },
      {
        "name": "Varazdinska"
      },
      {
        "name": "Viroviticko-Podravska"
      },
      {
        "name": "Vukovarsko-Srijemska"
      },
      {
        "name": "Zadarska"
      },
      {
        "name": "Zagreb"
      },
      {
        "name": "Zagrebacka"
      }
    ]
  },
  {
    "name": "Cuba",
    "states": [
      {
        "name": "Camaguey"
      },
      {
        "name": "Ciego de Avila"
      },
      {
        "name": "Cienfuegos"
      },
      {
        "name": "Ciudad de La Habana"
      },
      {
        "name": "Granma"
      },
      {
        "name": "Guantanamo"
      },
      {
        "name": "Holguin"
      },
      {
        "name": "Isla de la Juventud"
      },
      {
        "name": "La Habana"
      },
      {
        "name": "Las Tunas"
      },
      {
        "name": "Matanzas"
      },
      {
        "name": "Pinar del Rio"
      },
      {
        "name": "Sancti Spiritus"
      },
      {
        "name": "Santiago de Cuba"
      },
      {
        "name": "Villa Clara"
      }
    ]
  },
  {
    "name": "Cyprus",
    "states": [
      {
        "name": "Famagusta"
      },
      {
        "name": "Kyrenia"
      },
      {
        "name": "Larnaca"
      },
      {
        "name": "Limassol"
      },
      {
        "name": "Nicosia"
      },
      {
        "name": "Paphos"
      }
    ]
  },
  {
    "name": "Czech Republic",
    "states": [
      {
        "name": "Jihocesky Kraj"
      },
      {
        "name": "Jihomoravsky Kraj"
      },
      {
        "name": "Karlovarsky Kraj"
      },
      {
        "name": "Kralovehradecky Kraj"
      },
      {
        "name": "Liberecky Kraj"
      },
      {
        "name": "Moravskoslezsky Kraj"
      },
      {
        "name": "Olomoucky Kraj"
      },
      {
        "name": "Pardubicky Kraj"
      },
      {
        "name": "Plzensky Kraj"
      },
      {
        "name": "Praha"
      },
      {
        "name": "Stredocesky Kraj"
      },
      {
        "name": "Ustecky Kraj"
      },
      {
        "name": "Vysocina"
      },
      {
        "name": "Zlinsky Kraj"
      }
    ]
  },
  {
    "name": "Denmark",
    "states": [
      {
        "name": "Arhus"
      },
      {
        "name": "Bornholm"
      },
      {
        "name": "Frederiksberg"
      },
      {
        "name": "Frederiksborg"
      },
      {
        "name": "Fyn"
      },
      {
        "name": "Kobenhavn"
      },
      {
        "name": "Kobenhavns"
      },
      {
        "name": "Nordjylland"
      },
      {
        "name": "Ribe"
      },
      {
        "name": "Ringkobing"
      },
      {
        "name": "Roskilde"
      },
      {
        "name": "Sonderjylland"
      },
      {
        "name": "Storstrom"
      },
      {
        "name": "Vejle"
      },
      {
        "name": "Vestsjalland"
      },
      {
        "name": "Viborg"
      }
    ]
  },
  {
    "name": "Djibouti",
    "states": [
      {
        "name": "Ali Sabih"
      },
      {
        "name": "Dikhil"
      },
      {
        "name": "Djibouti"
      },
      {
        "name": "Obock"
      },
      {
        "name": "Tadjoura"
      }
    ]
  },
  {
    "name": "Dominica",
    "states": [
      {
        "name": "Saint Andrew"
      },
      {
        "name": "Saint David"
      },
      {
        "name": "Saint George"
      },
      {
        "name": "Saint John"
      },
      {
        "name": "Saint Joseph"
      },
      {
        "name": "Saint Luke"
      },
      {
        "name": "Saint Mark"
      },
      {
        "name": "Saint Patrick"
      },
      {
        "name": "Saint Paul"
      },
      {
        "name": "Saint Peter"
      }
    ]
  },
  {
    "name": "Dominican Republic",
    "states": [
      {
        "name": "Azua"
      },
      {
        "name": "Baoruco"
      },
      {
        "name": "Barahona"
      },
      {
        "name": "Dajabon"
      },
      {
        "name": "Distrito Nacional"
      },
      {
        "name": "Duarte"
      },
      {
        "name": "Elias Pina"
      },
      {
        "name": "El Seibo"
      },
      {
        "name": "Espaillat"
      },
      {
        "name": "Hato Mayor"
      },
      {
        "name": "Independencia"
      },
      {
        "name": "La Altagracia"
      },
      {
        "name": "La Romana"
      },
      {
        "name": "La Vega"
      },
      {
        "name": "Maria Trinidad Sanchez"
      },
      {
        "name": "Monsenor Nouel"
      },
      {
        "name": "Monte Cristi"
      },
      {
        "name": "Monte Plata"
      },
      {
        "name": "Pedernales"
      },
      {
        "name": "Peravia"
      },
      {
        "name": "Puerto Plata"
      },
      {
        "name": "Salcedo"
      },
      {
        "name": "Samana"
      },
      {
        "name": "Sanchez Ramirez"
      },
      {
        "name": "San Cristobal"
      },
      {
        "name": "San Jose de Ocoa"
      },
      {
        "name": "San Juan"
      },
      {
        "name": "San Pedro de Macoris"
      },
      {
        "name": "Santiago"
      },
      {
        "name": "Santiago Rodriguez"
      },
      {
        "name": "Santo Domingo"
      },
      {
        "name": "Valverde"
      }
    ]
  },
  {
    "name": "East Timor",
    "states": [
      {
        "name": "Aileu"
      },
      {
        "name": "Ainaro"
      },
      {
        "name": "Baucau"
      },
      {
        "name": "Bobonaro"
      },
      {
        "name": "Cova-Lima"
      },
      {
        "name": "Dili"
      },
      {
        "name": "Ermera"
      },
      {
        "name": "Lautem"
      },
      {
        "name": "Liquica"
      },
      {
        "name": "Manatuto"
      },
      {
        "name": "Manufahi"
      },
      {
        "name": "Oecussi"
      },
      {
        "name": "Viqueque"
      }
    ]
  },
  {
    "name": "Ecuador",
    "states": [
      {
        "name": "Azuay"
      },
      {
        "name": "Bolivar"
      },
      {
        "name": "Canar"
      },
      {
        "name": "Carchi"
      },
      {
        "name": "Chimborazo"
      },
      {
        "name": "Cotopaxi"
      },
      {
        "name": "El Oro"
      },
      {
        "name": "Esmeraldas"
      },
      {
        "name": "Galapagos"
      },
      {
        "name": "Guayas"
      },
      {
        "name": "Imbabura"
      },
      {
        "name": "Loja"
      },
      {
        "name": "Los Rios"
      },
      {
        "name": "Manabi"
      },
      {
        "name": "Morona-Santiago"
      },
      {
        "name": "Napo"
      },
      {
        "name": "Orellana"
      },
      {
        "name": "Pastaza"
      },
      {
        "name": "Pichincha"
      },
      {
        "name": "Sucumbios"
      },
      {
        "name": "Tungurahua"
      },
      {
        "name": "Zamora-Chinchipe"
      }
    ]
  },
  {
    "name": "Egypt",
    "states": [
      {
        "name": "Ad Daqahliyah"
      },
      {
        "name": "Al Bahr al Ahmar"
      },
      {
        "name": "Al Buhayrah"
      },
      {
        "name": "Al Fayyum"
      },
      {
        "name": "Al Gharbiyah"
      },
      {
        "name": "Al Iskandariyah"
      },
      {
        "name": "Al Isma'iliyah"
      },
      {
        "name": "Al Jizah"
      },
      {
        "name": "Al Minufiyah"
      },
      {
        "name": "Al Minya"
      },
      {
        "name": "Al Qahirah"
      },
      {
        "name": "Al Qalyubiyah"
      },
      {
        "name": "Al Wadi al Jadid"
      },
      {
        "name": "Ash Sharqiyah"
      },
      {
        "name": "As Suways"
      },
      {
        "name": "Aswan"
      },
      {
        "name": "Asyut"
      },
      {
        "name": "Bani Suwayf"
      },
      {
        "name": "Bur Sa'id"
      },
      {
        "name": "Dumyat"
      },
      {
        "name": "Janub Sina'"
      },
      {
        "name": "Kafr ash Shaykh"
      },
      {
        "name": "Matruh"
      },
      {
        "name": "Qina"
      },
      {
        "name": "Shamal Sina'"
      },
      {
        "name": "Suhaj"
      }
    ]
  },
  {
    "name": "El Salvador",
    "states": [
      {
        "name": "Ahuachapan"
      },
      {
        "name": "Cabanas"
      },
      {
        "name": "Chalatenango"
      },
      {
        "name": "Cuscatlan"
      },
      {
        "name": "La Libertad"
      },
      {
        "name": "La Paz"
      },
      {
        "name": "La Union"
      },
      {
        "name": "Morazan"
      },
      {
        "name": "San Miguel"
      },
      {
        "name": "San Salvador"
      },
      {
        "name": "Santa Ana"
      },
      {
        "name": "San Vicente"
      },
      {
        "name": "Sonsonate"
      },
      {
        "name": "Usulutan"
      }
    ]
  },
  {
    "name": "Equatorial Guinea",
    "states": [
      {
        "name": "Annobon"
      },
      {
        "name": "Bioko Norte"
      },
      {
        "name": "Bioko Sur"
      },
      {
        "name": "Centro Sur"
      },
      {
        "name": "Kie-Ntem"
      },
      {
        "name": "Litoral"
      },
      {
        "name": "Wele-Nzas"
      }
    ]
  },
  {
    "name": "Eritrea",
    "states": [
      {
        "name": "Anseba"
      },
      {
        "name": "Debub"
      },
      {
        "name": "Debubawi K'eyih Bahri"
      },
      {
        "name": "Gash Barka"
      },
      {
        "name": "Ma'akel"
      },
      {
        "name": "Semenawi Keyih Bahri"
      }
    ]
  },
  {
    "name": "Estonia",
    "states": [
      {
        "name": "Harjumaa (Tallinn)"
      },
      {
        "name": "Hiiumaa (Kardla)"
      },
      {
        "name": "Ida-Virumaa (Johvi)"
      },
      {
        "name": "Jarvamaa (Paide)"
      },
      {
        "name": "Jogevamaa (Jogeva)"
      },
      {
        "name": "Laanemaa (Haapsalu)"
      },
      {
        "name": "Laane-Virumaa (Rakvere)"
      },
      {
        "name": "Parnumaa (Parnu)"
      },
      {
        "name": "Polvamaa (Polva)"
      },
      {
        "name": "Raplamaa (Rapla)"
      },
      {
        "name": "Saaremaa (Kuressaare)"
      },
      {
        "name": "Tartumaa (Tartu)"
      },
      {
        "name": "Valgamaa (Valga)"
      },
      {
        "name": "Viljandimaa (Viljandi)"
      },
      {
        "name": "Vorumaa (Voru)"
      }
    ]
  },
  {
    "name": "Ethiopia",
    "states": [
      {
        "name": "Addis Ababa"
      },
      {
        "name": "Afar"
      },
      {
        "name": "Amhara"
      },
      {
        "name": "Binshangul Gumuz"
      },
      {
        "name": "Dire Dawa"
      },
      {
        "name": "Gambela Hizboch"
      },
      {
        "name": "Harari"
      },
      {
        "name": "Oromia"
      },
      {
        "name": "Somali"
      },
      {
        "name": "Tigray"
      },
      {
        "name": "Southern Nations, Nationalities, and Peoples Region"
      }
    ]
  },
  {
    "name": "Fiji",
    "states": [
      {
        "name": "Central (Suva)"
      },
      {
        "name": "Eastern (Levuka)"
      },
      {
        "name": "Northern (Labasa)"
      },
      {
        "name": "Rotuma"
      },
      {
        "name": "Western (Lautoka)"
      }
    ]
  },
  {
    "name": "Finland",
    "states": [
      {
        "name": "Aland"
      },
      {
        "name": "Etela-Suomen Laani"
      },
      {
        "name": "Ita-Suomen Laani"
      },
      {
        "name": "Lansi-Suomen Laani"
      },
      {
        "name": "Lappi"
      },
      {
        "name": "Oulun Laani"
      }
    ]
  },
  {
    "name": "France",
    "states": [
      {
        "name": "Alsace"
      },
      {
        "name": "Aquitaine"
      },
      {
        "name": "Auvergne"
      },
      {
        "name": "Basse-Normandie"
      },
      {
        "name": "Bourgogne"
      },
      {
        "name": "Bretagne"
      },
      {
        "name": "Centre"
      },
      {
        "name": "Champagne-Ardenne"
      },
      {
        "name": "Corse"
      },
      {
        "name": "Franche-Comte"
      },
      {
        "name": "Haute-Normandie"
      },
      {
        "name": "Ile-de-France"
      },
      {
        "name": "Languedoc-Roussillon"
      },
      {
        "name": "Limousin"
      },
      {
        "name": "Lorraine"
      },
      {
        "name": "Midi-Pyrenees"
      },
      {
        "name": "Nord-Pas-de-Calais"
      },
      {
        "name": "Pays de la Loire"
      },
      {
        "name": "Picardie"
      },
      {
        "name": "Poitou-Charentes"
      },
      {
        "name": "Provence-Alpes-Cote d'Azur"
      },
      {
        "name": "Rhone-Alpes"
      }
    ]
  },
  {
    "name": "Gabon",
    "states": [
      {
        "name": "Estuaire"
      },
      {
        "name": "Haut-Ogooue"
      },
      {
        "name": "Moyen-Ogooue"
      },
      {
        "name": "Ngounie"
      },
      {
        "name": "Nyanga"
      },
      {
        "name": "Ogooue-Ivindo"
      },
      {
        "name": "Ogooue-Lolo"
      },
      {
        "name": "Ogooue-Maritime"
      },
      {
        "name": "Woleu-Ntem"
      }
    ]
  },
  {
    "name": "Gambia",
    "states": [
      {
        "name": "Banjul"
      },
      {
        "name": "Central River"
      },
      {
        "name": "Lower River"
      },
      {
        "name": "North Bank"
      },
      {
        "name": "Upper River"
      },
      {
        "name": "Western"
      }
    ]
  },
  {
    "name": "Georgia",
    "states": []
  },
  {
    "name": "Germany",
    "states": [
      {
        "name": "Baden-Wuerttemberg"
      },
      {
        "name": "Bayern"
      },
      {
        "name": "Berlin"
      },
      {
        "name": "Brandenburg"
      },
      {
        "name": "Bremen"
      },
      {
        "name": "Hamburg"
      },
      {
        "name": "Hessen"
      },
      {
        "name": "Mecklenburg-Vorpommern"
      },
      {
        "name": "Niedersachsen"
      },
      {
        "name": "Nordrhein-Westfalen"
      },
      {
        "name": "Rheinland-Pfalz"
      },
      {
        "name": "Saarland"
      },
      {
        "name": "Sachsen"
      },
      {
        "name": "Sachsen-Anhalt"
      },
      {
        "name": "Schleswig-Holstein"
      },
      {
        "name": "Thueringen"
      }
    ]
  },
  {
    "name": "Ghana",
    "states": [
      {
        "name": "Ashanti"
      },
      {
        "name": "Brong-Ahafo"
      },
      {
        "name": "Central"
      },
      {
        "name": "Eastern"
      },
      {
        "name": "Greater Accra"
      },
      {
        "name": "Northern"
      },
      {
        "name": "Upper East"
      },
      {
        "name": "Upper West"
      },
      {
        "name": "Volta"
      },
      {
        "name": "Western"
      }
    ]
  },
  {
    "name": "Greece",
    "states": [
      {
        "name": "Agion Oros"
      },
      {
        "name": "Achaia"
      },
      {
        "name": "Aitolia kai Akarmania"
      },
      {
        "name": "Argolis"
      },
      {
        "name": "Arkadia"
      },
      {
        "name": "Arta"
      },
      {
        "name": "Attiki"
      },
      {
        "name": "Chalkidiki"
      },
      {
        "name": "Chanion"
      },
      {
        "name": "Chios"
      },
      {
        "name": "Dodekanisos"
      },
      {
        "name": "Drama"
      },
      {
        "name": "Evros"
      },
      {
        "name": "Evrytania"
      },
      {
        "name": "Evvoia"
      },
      {
        "name": "Florina"
      },
      {
        "name": "Fokidos"
      },
      {
        "name": "Fthiotis"
      },
      {
        "name": "Grevena"
      },
      {
        "name": "Ileia"
      },
      {
        "name": "Imathia"
      },
      {
        "name": "Ioannina"
      },
      {
        "name": "Irakleion"
      },
      {
        "name": "Karditsa"
      },
      {
        "name": "Kastoria"
      },
      {
        "name": "Kavala"
      },
      {
        "name": "Kefallinia"
      },
      {
        "name": "Kerkyra"
      },
      {
        "name": "Kilkis"
      },
      {
        "name": "Korinthia"
      },
      {
        "name": "Kozani"
      },
      {
        "name": "Kyklades"
      },
      {
        "name": "Lakonia"
      },
      {
        "name": "Larisa"
      },
      {
        "name": "Lasithi"
      },
      {
        "name": "Lefkas"
      },
      {
        "name": "Lesvos"
      },
      {
        "name": "Magnisia"
      },
      {
        "name": "Messinia"
      },
      {
        "name": "Pella"
      },
      {
        "name": "Pieria"
      },
      {
        "name": "Preveza"
      },
      {
        "name": "Rethynnis"
      },
      {
        "name": "Rodopi"
      },
      {
        "name": "Samos"
      },
      {
        "name": "Serrai"
      },
      {
        "name": "Thesprotia"
      },
      {
        "name": "Thessaloniki"
      },
      {
        "name": "Trikala"
      },
      {
        "name": "Voiotia"
      },
      {
        "name": "Xanthi"
      },
      {
        "name": "Zakynthos"
      }
    ]
  },
  {
    "name": "Greenland",
    "states": [
      {
        "name": "Avannaa (Nordgronland)"
      },
      {
        "name": "Tunu (Ostgronland)"
      },
      {
        "name": "Kitaa (Vestgronland)"
      }
    ]
  },
  {
    "name": "Grenada",
    "states": [
      {
        "name": "Carriacou and Petit Martinique"
      },
      {
        "name": "Saint Andrew"
      },
      {
        "name": "Saint David"
      },
      {
        "name": "Saint George"
      },
      {
        "name": "Saint John"
      },
      {
        "name": "Saint Mark"
      },
      {
        "name": "Saint Patrick"
      }
    ]
  },
  {
    "name": "Guatemala",
    "states": [
      {
        "name": "Alta Verapaz"
      },
      {
        "name": "Baja Verapaz"
      },
      {
        "name": "Chimaltenango"
      },
      {
        "name": "Chiquimula"
      },
      {
        "name": "El Progreso"
      },
      {
        "name": "Escuintla"
      },
      {
        "name": "Guatemala"
      },
      {
        "name": "Huehuetenango"
      },
      {
        "name": "Izabal"
      },
      {
        "name": "Jalapa"
      },
      {
        "name": "Jutiapa"
      },
      {
        "name": "Peten"
      },
      {
        "name": "Quetzaltenango"
      },
      {
        "name": "Quiche"
      },
      {
        "name": "Retalhuleu"
      },
      {
        "name": "Sacatepequez"
      },
      {
        "name": "San Marcos"
      },
      {
        "name": "Santa Rosa"
      },
      {
        "name": "Solola"
      },
      {
        "name": "Suchitepequez"
      },
      {
        "name": "Totonicapan"
      },
      {
        "name": "Zacapa"
      }
    ]
  },
  {
    "name": "Guinea",
    "states": [
      {
        "name": "Beyla"
      },
      {
        "name": "Boffa"
      },
      {
        "name": "Boke"
      },
      {
        "name": "Conakry"
      },
      {
        "name": "Coyah"
      },
      {
        "name": "Dabola"
      },
      {
        "name": "Dalaba"
      },
      {
        "name": "Dinguiraye"
      },
      {
        "name": "Dubreka"
      },
      {
        "name": "Faranah"
      },
      {
        "name": "Forecariah"
      },
      {
        "name": "Fria"
      },
      {
        "name": "Gaoual"
      },
      {
        "name": "Gueckedou"
      },
      {
        "name": "Kankan"
      },
      {
        "name": "Kerouane"
      },
      {
        "name": "Kindia"
      },
      {
        "name": "Kissidougou"
      },
      {
        "name": "Koubia"
      },
      {
        "name": "Koundara"
      },
      {
        "name": "Kouroussa"
      },
      {
        "name": "Labe"
      },
      {
        "name": "Lelouma"
      },
      {
        "name": "Lola"
      },
      {
        "name": "Macenta"
      },
      {
        "name": "Mali"
      },
      {
        "name": "Mamou"
      },
      {
        "name": "Mandiana"
      },
      {
        "name": "Nzerekore"
      },
      {
        "name": "Pita"
      },
      {
        "name": "Siguiri"
      },
      {
        "name": "Telimele"
      },
      {
        "name": "Tougue"
      },
      {
        "name": "Yomou"
      }
    ]
  },
  {
    "name": "Guinea-Bissau",
    "states": [
      {
        "name": "Bafata"
      },
      {
        "name": "Biombo"
      },
      {
        "name": "Bissau"
      },
      {
        "name": "Bolama"
      },
      {
        "name": "Cacheu"
      },
      {
        "name": "Gabu"
      },
      {
        "name": "Oio"
      },
      {
        "name": "Quinara"
      },
      {
        "name": "Tombali"
      }
    ]
  },
  {
    "name": "Guyana",
    "states": [
      {
        "name": "Barima-Waini"
      },
      {
        "name": "Cuyuni-Mazaruni"
      },
      {
        "name": "Demerara-Mahaica"
      },
      {
        "name": "East Berbice-Corentyne"
      },
      {
        "name": "Essequibo Islands-West Demerara"
      },
      {
        "name": "Mahaica-Berbice"
      },
      {
        "name": "Pomeroon-Supenaam"
      },
      {
        "name": "Potaro-Siparuni"
      },
      {
        "name": "Upper Demerara-Berbice"
      },
      {
        "name": "Upper Takutu-Upper Essequibo"
      }
    ]
  },
  {
    "name": "Haiti",
    "states": [
      {
        "name": "Artibonite"
      },
      {
        "name": "Centre"
      },
      {
        "name": "Grand 'Anse"
      },
      {
        "name": "Nord"
      },
      {
        "name": "Nord-Est"
      },
      {
        "name": "Nord-Ouest"
      },
      {
        "name": "Ouest"
      },
      {
        "name": "Sud"
      },
      {
        "name": "Sud-Est"
      }
    ]
  },
  {
    "name": "Honduras",
    "states": [
      {
        "name": "Atlantida"
      },
      {
        "name": "Choluteca"
      },
      {
        "name": "Colon"
      },
      {
        "name": "Comayagua"
      },
      {
        "name": "Copan"
      },
      {
        "name": "Cortes"
      },
      {
        "name": "El Paraiso"
      },
      {
        "name": "Francisco Morazan"
      },
      {
        "name": "Gracias a Dios"
      },
      {
        "name": "Intibuca"
      },
      {
        "name": "Islas de la Bahia"
      },
      {
        "name": "La Paz"
      },
      {
        "name": "Lempira"
      },
      {
        "name": "Ocotepeque"
      },
      {
        "name": "Olancho"
      },
      {
        "name": "Santa Barbara"
      },
      {
        "name": "Valle"
      },
      {
        "name": "Yoro"
      }
    ]
  },
  {
    "name": "Hong Kong",
    "states": [
      {
        "name": "Central and Western"
      },
      {
        "name": "Eastern"
      },
      {
        "name": "Southern"
      },
      {
        "name": "Wan Chai"
      },
      {
        "name": "Sham Shui Po"
      },
      {
        "name": "Kowloon City"
      },
      {
        "name": "Kwun Tong"
      },
      {
        "name": "Wong Tai Sin"
      },
      {
        "name": "Yau Tsim Mong"
      },
      {
        "name": "Islands"
      },
      {
        "name": "Kwai Tsing"
      },
      {
        "name": "North"
      },
      {
        "name": "Sai Kung"
      },
      {
        "name": "Sha Tin"
      },
      {
        "name": "Tai Po"
      },
      {
        "name": "Tsuen Wan"
      },
      {
        "name": "Tuen Mun"
      },
      {
        "name": "Yuen Long"
      }
    ]
  },
  {
    "name": "Hungary",
    "states": [
      {
        "name": "Bacs-Kiskun"
      },
      {
        "name": "Baranya"
      },
      {
        "name": "Bekes"
      },
      {
        "name": "Borsod-Abauj-Zemplen"
      },
      {
        "name": "Csongrad"
      },
      {
        "name": "Fejer"
      },
      {
        "name": "Gyor-Moson-Sopron"
      },
      {
        "name": "Hajdu-Bihar"
      },
      {
        "name": "Heves"
      },
      {
        "name": "Jasz-Nagykun-Szolnok"
      },
      {
        "name": "Komarom-Esztergom"
      },
      {
        "name": "Nograd"
      },
      {
        "name": "Pest"
      },
      {
        "name": "Somogy"
      },
      {
        "name": "Szabolcs-Szatmar-Bereg"
      },
      {
        "name": "Tolna"
      },
      {
        "name": "Vas"
      },
      {
        "name": "Veszprem"
      },
      {
        "name": "Zala"
      },
      {
        "name": "Bekescsaba"
      },
      {
        "name": "Debrecen"
      },
      {
        "name": "Dunaujvaros"
      },
      {
        "name": "Eger"
      },
      {
        "name": "Gyor"
      },
      {
        "name": "Hodmezovasarhely"
      },
      {
        "name": "Kaposvar"
      },
      {
        "name": "Kecskemet"
      },
      {
        "name": "Miskolc"
      },
      {
        "name": "Nagykanizsa"
      },
      {
        "name": "Nyiregyhaza"
      },
      {
        "name": "Pecs"
      },
      {
        "name": "Sopron"
      },
      {
        "name": "Szeged"
      },
      {
        "name": "Szekesfehervar"
      },
      {
        "name": "Szolnok"
      },
      {
        "name": "Szombathely"
      },
      {
        "name": "Tatabanya"
      },
      {
        "name": "Veszprem"
      },
      {
        "name": "Zalaegerszeg"
      }
    ]
  },
  {
    "name": "Iceland",
    "states": [
      {
        "name": "Austurland"
      },
      {
        "name": "Hofudhborgarsvaedhi"
      },
      {
        "name": "Nordhurland Eystra"
      },
      {
        "name": "Nordhurland Vestra"
      },
      {
        "name": "Sudhurland"
      },
      {
        "name": "Sudhurnes"
      },
      {
        "name": "Vestfirdhir"
      },
      {
        "name": "Vesturland"
      }
    ]
  },
  {
    "name": "India",
    "states": [
      {
        "name": "Andaman and Nicobar Islands"
      },
      {
        "name": "Andhra Pradesh"
      },
      {
        "name": "Arunachal Pradesh"
      },
      {
        "name": "Assam"
      },
      {
        "name": "Bihar"
      },
      {
        "name": "Chandigarh"
      },
      {
        "name": "Chhattisgarh"
      },
      {
        "name": "Dadra and Nagar Haveli"
      },
      {
        "name": "Daman and Diu"
      },
      {
        "name": "Delhi"
      },
      {
        "name": "Goa"
      },
      {
        "name": "Gujarat"
      },
      {
        "name": "Haryana"
      },
      {
        "name": "Himachal Pradesh"
      },
      {
        "name": "Jammu and Kashmir"
      },
      {
        "name": "Jharkhand"
      },
      {
        "name": "Karnataka"
      },
      {
        "name": "Kerala"
      },
      {
        "name": "Lakshadweep"
      },
      {
        "name": "Madhya Pradesh"
      },
      {
        "name": "Maharashtra"
      },
      {
        "name": "Manipur"
      },
      {
        "name": "Meghalaya"
      },
      {
        "name": "Mizoram"
      },
      {
        "name": "Nagaland"
      },
      {
        "name": "Orissa"
      },
      {
        "name": "Pondicherry"
      },
      {
        "name": "Punjab"
      },
      {
        "name": "Rajasthan"
      },
      {
        "name": "Sikkim"
      },
      {
        "name": "Tamil Nadu"
      },
      {
        "name": "Tripura"
      },
      {
        "name": "Uttaranchal"
      },
      {
        "name": "Uttar Pradesh"
      },
      {
        "name": "West Bengal"
      }
    ]
  },
  {
    "name": "Indonesia",
    "states": [
      {
        "name": "Aceh"
      },
      {
        "name": "Bali"
      },
      {
        "name": "Banten"
      },
      {
        "name": "Bengkulu"
      },
      {
        "name": "Gorontalo"
      },
      {
        "name": "Irian Jaya Barat"
      },
      {
        "name": "Jakarta Raya"
      },
      {
        "name": "Jambi"
      },
      {
        "name": "Jawa Barat"
      },
      {
        "name": "Jawa Tengah"
      },
      {
        "name": "Jawa Timur"
      },
      {
        "name": "Kalimantan Barat"
      },
      {
        "name": "Kalimantan Selatan"
      },
      {
        "name": "Kalimantan Tengah"
      },
      {
        "name": "Kalimantan Timur"
      },
      {
        "name": "Kepulauan Bangka Belitung"
      },
      {
        "name": "Kepulauan Riau"
      },
      {
        "name": "Lampung"
      },
      {
        "name": "Maluku"
      },
      {
        "name": "Maluku Utara"
      },
      {
        "name": "Nusa Tenggara Barat"
      },
      {
        "name": "Nusa Tenggara Timur"
      },
      {
        "name": "Papua"
      },
      {
        "name": "Riau"
      },
      {
        "name": "Sulawesi Barat"
      },
      {
        "name": "Sulawesi Selatan"
      },
      {
        "name": "Sulawesi Tengah"
      },
      {
        "name": "Sulawesi Tenggara"
      },
      {
        "name": "Sulawesi Utara"
      },
      {
        "name": "Sumatera Barat"
      },
      {
        "name": "Sumatera Selatan"
      },
      {
        "name": "Sumatera Utara"
      },
      {
        "name": "Yogyakarta"
      }
    ]
  },
  {
    "name": "Iran",
    "states": [
      {
        "name": "Ardabil"
      },
      {
        "name": "Azarbayjan-e Gharbi"
      },
      {
        "name": "Azarbayjan-e Sharqi"
      },
      {
        "name": "Bushehr"
      },
      {
        "name": "Chahar Mahall va Bakhtiari"
      },
      {
        "name": "Esfahan"
      },
      {
        "name": "Fars"
      },
      {
        "name": "Gilan"
      },
      {
        "name": "Golestan"
      },
      {
        "name": "Hamadan"
      },
      {
        "name": "Hormozgan"
      },
      {
        "name": "Ilam"
      },
      {
        "name": "Kerman"
      },
      {
        "name": "Kermanshah"
      },
      {
        "name": "Khorasan-e Janubi"
      },
      {
        "name": "Khorasan-e Razavi"
      },
      {
        "name": "Khorasan-e Shemali"
      },
      {
        "name": "Khuzestan"
      },
      {
        "name": "Kohgiluyeh va Buyer Ahmad"
      },
      {
        "name": "Kordestan"
      },
      {
        "name": "Lorestan"
      },
      {
        "name": "Markazi"
      },
      {
        "name": "Mazandaran"
      },
      {
        "name": "Qazvin"
      },
      {
        "name": "Qom"
      },
      {
        "name": "Semnan"
      },
      {
        "name": "Sistan va Baluchestan"
      },
      {
        "name": "Tehran"
      },
      {
        "name": "Yazd"
      },
      {
        "name": "Zanjan"
      }
    ]
  },
  {
    "name": "Iraq",
    "states": [
      {
        "name": "Al Anbar"
      },
      {
        "name": "Al Basrah"
      },
      {
        "name": "Al Muthanna"
      },
      {
        "name": "Al Qadisiyah"
      },
      {
        "name": "An Najaf"
      },
      {
        "name": "Arbil"
      },
      {
        "name": "As Sulaymaniyah"
      },
      {
        "name": "At Ta'mim"
      },
      {
        "name": "Babil"
      },
      {
        "name": "Baghdad"
      },
      {
        "name": "Dahuk"
      },
      {
        "name": "Dhi Qar"
      },
      {
        "name": "Diyala"
      },
      {
        "name": "Karbala'"
      },
      {
        "name": "Maysan"
      },
      {
        "name": "Ninawa"
      },
      {
        "name": "Salah ad Din"
      },
      {
        "name": "Wasit"
      }
    ]
  },
  {
    "name": "Ireland",
    "states": [
      {
        "name": "Carlow"
      },
      {
        "name": "Cavan"
      },
      {
        "name": "Clare"
      },
      {
        "name": "Cork"
      },
      {
        "name": "Donegal"
      },
      {
        "name": "Dublin"
      },
      {
        "name": "Galway"
      },
      {
        "name": "Kerry"
      },
      {
        "name": "Kildare"
      },
      {
        "name": "Kilkenny"
      },
      {
        "name": "Laois"
      },
      {
        "name": "Leitrim"
      },
      {
        "name": "Limerick"
      },
      {
        "name": "Longford"
      },
      {
        "name": "Louth"
      },
      {
        "name": "Mayo"
      },
      {
        "name": "Meath"
      },
      {
        "name": "Monaghan"
      },
      {
        "name": "Offaly"
      },
      {
        "name": "Roscommon"
      },
      {
        "name": "Sligo"
      },
      {
        "name": "Tipperary"
      },
      {
        "name": "Waterford"
      },
      {
        "name": "Westmeath"
      },
      {
        "name": "Wexford"
      },
      {
        "name": "Wicklow"
      }
    ]
  },
  {
    "name": "Israel",
    "states": [
      {
        "name": "Central"
      },
      {
        "name": "Haifa"
      },
      {
        "name": "Jerusalem"
      },
      {
        "name": "Northern"
      },
      {
        "name": "Southern"
      },
      {
        "name": "Tel Aviv"
      }
    ]
  },
  {
    "name": "Italy",
    "states": [
      {
        "name": "Abruzzo"
      },
      {
        "name": "Basilicata"
      },
      {
        "name": "Calabria"
      },
      {
        "name": "Campania"
      },
      {
        "name": "Emilia-Romagna"
      },
      {
        "name": "Friuli-Venezia Giulia"
      },
      {
        "name": "Lazio"
      },
      {
        "name": "Liguria"
      },
      {
        "name": "Lombardia"
      },
      {
        "name": "Marche"
      },
      {
        "name": "Molise"
      },
      {
        "name": "Piemonte"
      },
      {
        "name": "Puglia"
      },
      {
        "name": "Sardegna"
      },
      {
        "name": "Sicilia"
      },
      {
        "name": "Toscana"
      },
      {
        "name": "Trentino-Alto Adige"
      },
      {
        "name": "Umbria"
      },
      {
        "name": "Valle d'Aosta"
      },
      {
        "name": "Veneto"
      }
    ]
  },
  {
    "name": "Jamaica",
    "states": [
      {
        "name": "Clarendon"
      },
      {
        "name": "Hanover"
      },
      {
        "name": "Kingston"
      },
      {
        "name": "Manchester"
      },
      {
        "name": "Portland"
      },
      {
        "name": "Saint Andrew"
      },
      {
        "name": "Saint Ann"
      },
      {
        "name": "Saint Catherine"
      },
      {
        "name": "Saint Elizabeth"
      },
      {
        "name": "Saint James"
      },
      {
        "name": "Saint Mary"
      },
      {
        "name": "Saint Thomas"
      },
      {
        "name": "Trelawny"
      },
      {
        "name": "Westmoreland"
      }
    ]
  },
  {
    "name": "Japan",
    "states": [
      {
        "name": "Aichi"
      },
      {
        "name": "Akita"
      },
      {
        "name": "Aomori"
      },
      {
        "name": "Chiba"
      },
      {
        "name": "Ehime"
      },
      {
        "name": "Fukui"
      },
      {
        "name": "Fukuoka"
      },
      {
        "name": "Fukushima"
      },
      {
        "name": "Gifu"
      },
      {
        "name": "Gumma"
      },
      {
        "name": "Hiroshima"
      },
      {
        "name": "Hokkaido"
      },
      {
        "name": "Hyogo"
      },
      {
        "name": "Ibaraki"
      },
      {
        "name": "Ishikawa"
      },
      {
        "name": "Iwate"
      },
      {
        "name": "Kagawa"
      },
      {
        "name": "Kagoshima"
      },
      {
        "name": "Kanagawa"
      },
      {
        "name": "Kochi"
      },
      {
        "name": "Kumamoto"
      },
      {
        "name": "Kyoto"
      },
      {
        "name": "Mie"
      },
      {
        "name": "Miyagi"
      },
      {
        "name": "Miyazaki"
      },
      {
        "name": "Nagano"
      },
      {
        "name": "Nagasaki"
      },
      {
        "name": "Nara"
      },
      {
        "name": "Niigata"
      },
      {
        "name": "Oita"
      },
      {
        "name": "Okayama"
      },
      {
        "name": "Okinawa"
      },
      {
        "name": "Osaka"
      },
      {
        "name": "Saga"
      },
      {
        "name": "Saitama"
      },
      {
        "name": "Shiga"
      },
      {
        "name": "Shimane"
      },
      {
        "name": "Shizuoka"
      },
      {
        "name": "Tochigi"
      },
      {
        "name": "Tokushima"
      },
      {
        "name": "Tokyo"
      },
      {
        "name": "Tottori"
      },
      {
        "name": "Toyama"
      },
      {
        "name": "Wakayama"
      },
      {
        "name": "Yamagata"
      },
      {
        "name": "Yamaguchi"
      },
      {
        "name": "Yamanashi"
      }
    ]
  },
  {
    "name": "Jordan",
    "states": [
      {
        "name": "Ajlun"
      },
      {
        "name": "Al 'Aqabah"
      },
      {
        "name": "Al Balqa'"
      },
      {
        "name": "Al Karak"
      },
      {
        "name": "Al Mafraq"
      },
      {
        "name": "'Amman"
      },
      {
        "name": "At Tafilah"
      },
      {
        "name": "Az Zarqa'"
      },
      {
        "name": "Irbid"
      },
      {
        "name": "Jarash"
      },
      {
        "name": "Ma'an"
      },
      {
        "name": "Madaba"
      }
    ]
  },
  {
    "name": "Kazakhstan",
    "states": [
      {
        "name": "Almaty Oblysy"
      },
      {
        "name": "Almaty Qalasy"
      },
      {
        "name": "Aqmola Oblysy"
      },
      {
        "name": "Aqtobe Oblysy"
      },
      {
        "name": "Astana Qalasy"
      },
      {
        "name": "Atyrau Oblysy"
      },
      {
        "name": "Batys Qazaqstan Oblysy"
      },
      {
        "name": "Bayqongyr Qalasy"
      },
      {
        "name": "Mangghystau Oblysy"
      },
      {
        "name": "Ongtustik Qazaqstan Oblysy"
      },
      {
        "name": "Pavlodar Oblysy"
      },
      {
        "name": "Qaraghandy Oblysy"
      },
      {
        "name": "Qostanay Oblysy"
      },
      {
        "name": "Qyzylorda Oblysy"
      },
      {
        "name": "Shyghys Qazaqstan Oblysy"
      },
      {
        "name": "Soltustik Qazaqstan Oblysy"
      },
      {
        "name": "Zhambyl Oblysy"
      }
    ]
  },
  {
    "name": "Kenya",
    "states": [
      {
        "name": "Central"
      },
      {
        "name": "Coast"
      },
      {
        "name": "Eastern"
      },
      {
        "name": "Nairobi Area"
      },
      {
        "name": "North Eastern"
      },
      {
        "name": "Nyanza"
      },
      {
        "name": "Rift Valley"
      },
      {
        "name": "Western"
      }
    ]
  },
  {
    "name": "Kiribati",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Korea North",
    "states": [
      {
        "name": "Chagang"
      },
      {
        "name": "North Hamgyong"
      },
      {
        "name": "South Hamgyong"
      },
      {
        "name": "North Hwanghae"
      },
      {
        "name": "South Hwanghae"
      },
      {
        "name": "Kangwon"
      },
      {
        "name": "North P'yongan"
      },
      {
        "name": "South P'yongan"
      },
      {
        "name": "Yanggang"
      },
      {
        "name": "Kaesong"
      },
      {
        "name": "Najin"
      },
      {
        "name": "Namp'o"
      },
      {
        "name": "Pyongyang"
      }
    ]
  },
  {
    "name": "Korea South",
    "states": [
      {
        "name": "Seoul"
      },
      {
        "name": "Busan City"
      },
      {
        "name": "Daegu City"
      },
      {
        "name": "Incheon City"
      },
      {
        "name": "Gwangju City"
      },
      {
        "name": "Daejeon City"
      },
      {
        "name": "Ulsan"
      },
      {
        "name": "Gyeonggi Province"
      },
      {
        "name": "Gangwon Province"
      },
      {
        "name": "North Chungcheong Province"
      },
      {
        "name": "South Chungcheong Province"
      },
      {
        "name": "North Jeolla Province"
      },
      {
        "name": "South Jeolla Province"
      },
      {
        "name": "North Gyeongsang Province"
      },
      {
        "name": "South Gyeongsang Province"
      },
      {
        "name": "Jeju"
      }
    ]
  },
  {
    "name": "Kuwait",
    "states": [
      {
        "name": "Al Ahmadi"
      },
      {
        "name": "Al Farwaniyah"
      },
      {
        "name": "Al Asimah"
      },
      {
        "name": "Al Jahra"
      },
      {
        "name": "Hawalli"
      },
      {
        "name": "Mubarak Al-Kabeer"
      }
    ]
  },
  {
    "name": "Kyrgyzstan",
    "states": [
      {
        "name": "Batken Oblasty"
      },
      {
        "name": "Bishkek Shaary"
      },
      {
        "name": "Chuy Oblasty"
      },
      {
        "name": "Jalal-Abad Oblasty"
      },
      {
        "name": "Naryn Oblasty"
      },
      {
        "name": "Osh Oblasty"
      },
      {
        "name": "Talas Oblasty"
      },
      {
        "name": "Ysyk-Kol Oblasty"
      }
    ]
  },
  {
    "name": "Laos",
    "states": [
      {
        "name": "Attapu"
      },
      {
        "name": "Bokeo"
      },
      {
        "name": "Bolikhamxai"
      },
      {
        "name": "Champasak"
      },
      {
        "name": "Houaphan"
      },
      {
        "name": "Khammouan"
      },
      {
        "name": "Louangnamtha"
      },
      {
        "name": "Louangphrabang"
      },
      {
        "name": "Oudomxai"
      },
      {
        "name": "Phongsali"
      },
      {
        "name": "Salavan"
      },
      {
        "name": "Savannakhet"
      },
      {
        "name": "Viangchan"
      },
      {
        "name": "Viangchan"
      },
      {
        "name": "Xaignabouli"
      },
      {
        "name": "Xaisomboun"
      },
      {
        "name": "Xekong"
      },
      {
        "name": "Xiangkhoang"
      }
    ]
  },
  {
    "name": "Latvia",
    "states": [
      {
        "name": "Aizkraukles Rajons"
      },
      {
        "name": "Aluksnes Rajons"
      },
      {
        "name": "Balvu Rajons"
      },
      {
        "name": "Bauskas Rajons"
      },
      {
        "name": "Cesu Rajons"
      },
      {
        "name": "Daugavpils"
      },
      {
        "name": "Daugavpils Rajons"
      },
      {
        "name": "Dobeles Rajons"
      },
      {
        "name": "Gulbenes Rajons"
      },
      {
        "name": "Jekabpils Rajons"
      },
      {
        "name": "Jelgava"
      },
      {
        "name": "Jelgavas Rajons"
      },
      {
        "name": "Jurmala"
      },
      {
        "name": "Kraslavas Rajons"
      },
      {
        "name": "Kuldigas Rajons"
      },
      {
        "name": "Liepaja"
      },
      {
        "name": "Liepajas Rajons"
      },
      {
        "name": "Limbazu Rajons"
      },
      {
        "name": "Ludzas Rajons"
      },
      {
        "name": "Madonas Rajons"
      },
      {
        "name": "Ogres Rajons"
      },
      {
        "name": "Preilu Rajons"
      },
      {
        "name": "Rezekne"
      },
      {
        "name": "Rezeknes Rajons"
      },
      {
        "name": "Riga"
      },
      {
        "name": "Rigas Rajons"
      },
      {
        "name": "Saldus Rajons"
      },
      {
        "name": "Talsu Rajons"
      },
      {
        "name": "Tukuma Rajons"
      },
      {
        "name": "Valkas Rajons"
      },
      {
        "name": "Valmieras Rajons"
      },
      {
        "name": "Ventspils"
      },
      {
        "name": "Ventspils Rajons"
      }
    ]
  },
  {
    "name": "Lebanon",
    "states": [
      {
        "name": "Beyrouth"
      },
      {
        "name": "Beqaa"
      },
      {
        "name": "Liban-Nord"
      },
      {
        "name": "Liban-Sud"
      },
      {
        "name": "Mont-Liban"
      },
      {
        "name": "Nabatiye"
      }
    ]
  },
  {
    "name": "Lesotho",
    "states": [
      {
        "name": "Berea"
      },
      {
        "name": "Butha-Buthe"
      },
      {
        "name": "Leribe"
      },
      {
        "name": "Mafeteng"
      },
      {
        "name": "Maseru"
      },
      {
        "name": "Mohale's Hoek"
      },
      {
        "name": "Mokhotlong"
      },
      {
        "name": "Qacha's Nek"
      },
      {
        "name": "Quthing"
      },
      {
        "name": "Thaba-Tseka"
      }
    ]
  },
  {
    "name": "Liberia",
    "states": [
      {
        "name": "Bomi"
      },
      {
        "name": "Bong"
      },
      {
        "name": "Gbarpolu"
      },
      {
        "name": "Grand Bassa"
      },
      {
        "name": "Grand Cape Mount"
      },
      {
        "name": "Grand Gedeh"
      },
      {
        "name": "Grand Kru"
      },
      {
        "name": "Lofa"
      },
      {
        "name": "Margibi"
      },
      {
        "name": "Maryland"
      },
      {
        "name": "Montserrado"
      },
      {
        "name": "Nimba"
      },
      {
        "name": "River Cess"
      },
      {
        "name": "River Gee"
      },
      {
        "name": "Sinoe"
      }
    ]
  },
  {
    "name": "Libya",
    "states": [
      {
        "name": "Ajdabiya"
      },
      {
        "name": "Al 'Aziziyah"
      },
      {
        "name": "Al Fatih"
      },
      {
        "name": "Al Jabal al Akhdar"
      },
      {
        "name": "Al Jufrah"
      },
      {
        "name": "Al Khums"
      },
      {
        "name": "Al Kufrah"
      },
      {
        "name": "An Nuqat al Khams"
      },
      {
        "name": "Ash Shati'"
      },
      {
        "name": "Awbari"
      },
      {
        "name": "Az Zawiyah"
      },
      {
        "name": "Banghazi"
      },
      {
        "name": "Darnah"
      },
      {
        "name": "Ghadamis"
      },
      {
        "name": "Gharyan"
      },
      {
        "name": "Misratah"
      },
      {
        "name": "Murzuq"
      },
      {
        "name": "Sabha"
      },
      {
        "name": "Sawfajjin"
      },
      {
        "name": "Surt"
      },
      {
        "name": "Tarabulus"
      },
      {
        "name": "Tarhunah"
      },
      {
        "name": "Tubruq"
      },
      {
        "name": "Yafran"
      },
      {
        "name": "Zlitan"
      }
    ]
  },
  {
    "name": "Liechtenstein",
    "states": [
      {
        "name": "Balzers"
      },
      {
        "name": "Eschen"
      },
      {
        "name": "Gamprin"
      },
      {
        "name": "Mauren"
      },
      {
        "name": "Planken"
      },
      {
        "name": "Ruggell"
      },
      {
        "name": "Schaan"
      },
      {
        "name": "Schellenberg"
      },
      {
        "name": "Triesen"
      },
      {
        "name": "Triesenberg"
      },
      {
        "name": "Vaduz"
      }
    ]
  },
  {
    "name": "Lithuania",
    "states": [
      {
        "name": "Alytaus"
      },
      {
        "name": "Kauno"
      },
      {
        "name": "Klaipedos"
      },
      {
        "name": "Marijampoles"
      },
      {
        "name": "Panevezio"
      },
      {
        "name": "Siauliu"
      },
      {
        "name": "Taurages"
      },
      {
        "name": "Telsiu"
      },
      {
        "name": "Utenos"
      },
      {
        "name": "Vilniaus"
      }
    ]
  },
  {
    "name": "Luxembourg",
    "states": [
      {
        "name": "Diekirch"
      },
      {
        "name": "Grevenmacher"
      },
      {
        "name": "Luxembourg"
      }
    ]
  },
  {
    "name": "Macedonia",
    "states": [
      {
        "name": "Aerodrom"
      },
      {
        "name": "Aracinovo"
      },
      {
        "name": "Berovo"
      },
      {
        "name": "Bitola"
      },
      {
        "name": "Bogdanci"
      },
      {
        "name": "Bogovinje"
      },
      {
        "name": "Bosilovo"
      },
      {
        "name": "Brvenica"
      },
      {
        "name": "Butel"
      },
      {
        "name": "Cair"
      },
      {
        "name": "Caska"
      },
      {
        "name": "Centar"
      },
      {
        "name": "Centar Zupa"
      },
      {
        "name": "Cesinovo"
      },
      {
        "name": "Cucer-Sandevo"
      },
      {
        "name": "Debar"
      },
      {
        "name": "Debartsa"
      },
      {
        "name": "Delcevo"
      },
      {
        "name": "Demir Hisar"
      },
      {
        "name": "Demir Kapija"
      },
      {
        "name": "Dojran"
      },
      {
        "name": "Dolneni"
      },
      {
        "name": "Drugovo"
      },
      {
        "name": "Gazi Baba"
      },
      {
        "name": "Gevgelija"
      },
      {
        "name": "Gjorce Petrov"
      },
      {
        "name": "Gostivar"
      },
      {
        "name": "Gradsko"
      },
      {
        "name": "Ilinden"
      },
      {
        "name": "Jegunovce"
      },
      {
        "name": "Karbinci"
      },
      {
        "name": "Karpos"
      },
      {
        "name": "Kavadarci"
      },
      {
        "name": "Kicevo"
      },
      {
        "name": "Kisela Voda"
      },
      {
        "name": "Kocani"
      },
      {
        "name": "Konce"
      },
      {
        "name": "Kratovo"
      },
      {
        "name": "Kriva Palanka"
      },
      {
        "name": "Krivogastani"
      },
      {
        "name": "Krusevo"
      },
      {
        "name": "Kumanovo"
      },
      {
        "name": "Lipkovo"
      },
      {
        "name": "Lozovo"
      },
      {
        "name": "Makedonska Kamenica"
      },
      {
        "name": "Makedonski Brod"
      },
      {
        "name": "Mavrovo i Rastusa"
      },
      {
        "name": "Mogila"
      },
      {
        "name": "Negotino"
      },
      {
        "name": "Novaci"
      },
      {
        "name": "Novo Selo"
      },
      {
        "name": "Ohrid"
      },
      {
        "name": "Oslomej"
      },
      {
        "name": "Pehcevo"
      },
      {
        "name": "Petrovec"
      },
      {
        "name": "Plasnica"
      },
      {
        "name": "Prilep"
      },
      {
        "name": "Probistip"
      },
      {
        "name": "Radovis"
      },
      {
        "name": "Rankovce"
      },
      {
        "name": "Resen"
      },
      {
        "name": "Rosoman"
      },
      {
        "name": "Saraj"
      },
      {
        "name": "Skopje"
      },
      {
        "name": "Sopiste"
      },
      {
        "name": "Staro Nagoricane"
      },
      {
        "name": "Stip"
      },
      {
        "name": "Struga"
      },
      {
        "name": "Strumica"
      },
      {
        "name": "Studenicani"
      },
      {
        "name": "Suto Orizari"
      },
      {
        "name": "Sveti Nikole"
      },
      {
        "name": "Tearce"
      },
      {
        "name": "Tetovo"
      },
      {
        "name": "Valandovo"
      },
      {
        "name": "Vasilevo"
      },
      {
        "name": "Veles"
      },
      {
        "name": "Vevcani"
      },
      {
        "name": "Vinica"
      },
      {
        "name": "Vranestica"
      },
      {
        "name": "Vrapciste"
      },
      {
        "name": "Zajas"
      },
      {
        "name": "Zelenikovo"
      },
      {
        "name": "Zelino"
      },
      {
        "name": "Zrnovci"
      }
    ]
  },
  {
    "name": "Madagascar",
    "states": [
      {
        "name": "Antananarivo"
      },
      {
        "name": "Antsiranana"
      },
      {
        "name": "Fianarantsoa"
      },
      {
        "name": "Mahajanga"
      },
      {
        "name": "Toamasina"
      },
      {
        "name": "Toliara"
      }
    ]
  },
  {
    "name": "Malawi",
    "states": [
      {
        "name": "Balaka"
      },
      {
        "name": "Blantyre"
      },
      {
        "name": "Chikwawa"
      },
      {
        "name": "Chiradzulu"
      },
      {
        "name": "Chitipa"
      },
      {
        "name": "Dedza"
      },
      {
        "name": "Dowa"
      },
      {
        "name": "Karonga"
      },
      {
        "name": "Kasungu"
      },
      {
        "name": "Likoma"
      },
      {
        "name": "Lilongwe"
      },
      {
        "name": "Machinga"
      },
      {
        "name": "Mangochi"
      },
      {
        "name": "Mchinji"
      },
      {
        "name": "Mulanje"
      },
      {
        "name": "Mwanza"
      },
      {
        "name": "Mzimba"
      },
      {
        "name": "Ntcheu"
      },
      {
        "name": "Nkhata Bay"
      },
      {
        "name": "Nkhotakota"
      },
      {
        "name": "Nsanje"
      },
      {
        "name": "Ntchisi"
      },
      {
        "name": "Phalombe"
      },
      {
        "name": "Rumphi"
      },
      {
        "name": "Salima"
      },
      {
        "name": "Thyolo"
      },
      {
        "name": "Zomba"
      }
    ]
  },
  {
    "name": "Malaysia",
    "states": [
      {
        "name": "Johor"
      },
      {
        "name": "Kedah"
      },
      {
        "name": "Kelantan"
      },
      {
        "name": "Kuala Lumpur"
      },
      {
        "name": "Labuan"
      },
      {
        "name": "Malacca"
      },
      {
        "name": "Negeri Sembilan"
      },
      {
        "name": "Pahang"
      },
      {
        "name": "Perak"
      },
      {
        "name": "Perlis"
      },
      {
        "name": "Penang"
      },
      {
        "name": "Sabah"
      },
      {
        "name": "Sarawak"
      },
      {
        "name": "Selangor"
      },
      {
        "name": "Terengganu"
      }
    ]
  },
  {
    "name": "Maldives",
    "states": [
      {
        "name": "Alifu"
      },
      {
        "name": "Baa"
      },
      {
        "name": "Dhaalu"
      },
      {
        "name": "Faafu"
      },
      {
        "name": "Gaafu Alifu"
      },
      {
        "name": "Gaafu Dhaalu"
      },
      {
        "name": "Gnaviyani"
      },
      {
        "name": "Haa Alifu"
      },
      {
        "name": "Haa Dhaalu"
      },
      {
        "name": "Kaafu"
      },
      {
        "name": "Laamu"
      },
      {
        "name": "Lhaviyani"
      },
      {
        "name": "Maale"
      },
      {
        "name": "Meemu"
      },
      {
        "name": "Noonu"
      },
      {
        "name": "Raa"
      },
      {
        "name": "Seenu"
      },
      {
        "name": "Shaviyani"
      },
      {
        "name": "Thaa"
      },
      {
        "name": "Vaavu"
      }
    ]
  },
  {
    "name": "Mali",
    "states": [
      {
        "name": "Bamako (Capital)"
      },
      {
        "name": "Gao"
      },
      {
        "name": "Kayes"
      },
      {
        "name": "Kidal"
      },
      {
        "name": "Koulikoro"
      },
      {
        "name": "Mopti"
      },
      {
        "name": "Segou"
      },
      {
        "name": "Sikasso"
      },
      {
        "name": "Tombouctou"
      }
    ]
  },
  {
    "name": "Malta",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Marshall Islands",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Mauritania",
    "states": [
      {
        "name": "Adrar"
      },
      {
        "name": "Assaba"
      },
      {
        "name": "Brakna"
      },
      {
        "name": "Dakhlet Nouadhibou"
      },
      {
        "name": "Gorgol"
      },
      {
        "name": "Guidimaka"
      },
      {
        "name": "Hodh Ech Chargui"
      },
      {
        "name": "Hodh El Gharbi"
      },
      {
        "name": "Inchiri"
      },
      {
        "name": "Nouakchott"
      },
      {
        "name": "Tagant"
      },
      {
        "name": "Tiris Zemmour"
      },
      {
        "name": "Trarza"
      }
    ]
  },
  {
    "name": "Mauritius",
    "states": [
      {
        "name": "Agalega Islands"
      },
      {
        "name": "Black River"
      },
      {
        "name": "Cargados Carajos Shoals"
      },
      {
        "name": "Flacq"
      },
      {
        "name": "Grand Port"
      },
      {
        "name": "Moka"
      },
      {
        "name": "Pamplemousses"
      },
      {
        "name": "Plaines Wilhems"
      },
      {
        "name": "Port Louis"
      },
      {
        "name": "Riviere du Rempart"
      },
      {
        "name": "Rodrigues"
      },
      {
        "name": "Savanne"
      }
    ]
  },
  {
    "name": "Mexico",
    "states": [
      {
        "name": "Aguascalientes"
      },
      {
        "name": "Baja California"
      },
      {
        "name": "Baja California Sur"
      },
      {
        "name": "Campeche"
      },
      {
        "name": "Chiapas"
      },
      {
        "name": "Chihuahua"
      },
      {
        "name": "Coahuila de Zaragoza"
      },
      {
        "name": "Colima"
      },
      {
        "name": "Distrito Federal"
      },
      {
        "name": "Durango"
      },
      {
        "name": "Guanajuato"
      },
      {
        "name": "Guerrero"
      },
      {
        "name": "Hidalgo"
      },
      {
        "name": "Jalisco"
      },
      {
        "name": "Mexico"
      },
      {
        "name": "Michoacan de Ocampo"
      },
      {
        "name": "Morelos"
      },
      {
        "name": "Nayarit"
      },
      {
        "name": "Nuevo Leon"
      },
      {
        "name": "Oaxaca"
      },
      {
        "name": "Puebla"
      },
      {
        "name": "Queretaro de Arteaga"
      },
      {
        "name": "Quintana Roo"
      },
      {
        "name": "San Luis Potosi"
      },
      {
        "name": "Sinaloa"
      },
      {
        "name": "Sonora"
      },
      {
        "name": "Tabasco"
      },
      {
        "name": "Tamaulipas"
      },
      {
        "name": "Tlaxcala"
      },
      {
        "name": "Veracruz-Llave"
      },
      {
        "name": "Yucatan"
      },
      {
        "name": "Zacatecas"
      }
    ]
  },
  {
    "name": "Micronesia",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Moldova",
    "states": [
      {
        "name": "Anenii Noi"
      },
      {
        "name": "Basarabeasca"
      },
      {
        "name": "Briceni"
      },
      {
        "name": "Cahul"
      },
      {
        "name": "Cantemir"
      },
      {
        "name": "Calarasi"
      },
      {
        "name": "Causeni"
      },
      {
        "name": "Cimislia"
      },
      {
        "name": "Criuleni"
      },
      {
        "name": "Donduseni"
      },
      {
        "name": "Drochia"
      },
      {
        "name": "Dubasari"
      },
      {
        "name": "Edinet"
      },
      {
        "name": "Falesti"
      },
      {
        "name": "Floresti"
      },
      {
        "name": "Glodeni"
      },
      {
        "name": "Hincesti"
      },
      {
        "name": "Ialoveni"
      },
      {
        "name": "Leova"
      },
      {
        "name": "Nisporeni"
      },
      {
        "name": "Ocnita"
      },
      {
        "name": "Orhei"
      },
      {
        "name": "Rezina"
      },
      {
        "name": "Riscani"
      },
      {
        "name": "Singerei"
      },
      {
        "name": "Soldanesti"
      },
      {
        "name": "Soroca"
      },
      {
        "name": "Stefan-Voda"
      },
      {
        "name": "Straseni"
      },
      {
        "name": "Taraclia"
      },
      {
        "name": "Telenesti"
      },
      {
        "name": "Ungheni"
      },
      {
        "name": "Balti"
      },
      {
        "name": "Bender"
      },
      {
        "name": "Chisinau"
      },
      {
        "name": "Gagauzia"
      },
      {
        "name": "Stinga Nistrului"
      }
    ]
  },
  {
    "name": "Mongolia",
    "states": [
      {
        "name": "Arhangay"
      },
      {
        "name": "Bayanhongor"
      },
      {
        "name": "Bayan-Olgiy"
      },
      {
        "name": "Bulgan"
      },
      {
        "name": "Darhan Uul"
      },
      {
        "name": "Dornod"
      },
      {
        "name": "Dornogovi"
      },
      {
        "name": "Dundgovi"
      },
      {
        "name": "Dzavhan"
      },
      {
        "name": "Govi-Altay"
      },
      {
        "name": "Govi-Sumber"
      },
      {
        "name": "Hentiy"
      },
      {
        "name": "Hovd"
      },
      {
        "name": "Hovsgol"
      },
      {
        "name": "Omnogovi"
      },
      {
        "name": "Orhon"
      },
      {
        "name": "Ovorhangay"
      },
      {
        "name": "Selenge"
      },
      {
        "name": "Suhbaatar"
      },
      {
        "name": "Tov"
      },
      {
        "name": "Ulaanbaatar"
      },
      {
        "name": "Uvs"
      }
    ]
  },
  {
    "name": "Morocco",
    "states": [
      {
        "name": "Agadir"
      },
      {
        "name": "Al Hoceima"
      },
      {
        "name": "Azilal"
      },
      {
        "name": "Beni Mellal"
      },
      {
        "name": "Ben Slimane"
      },
      {
        "name": "Boulemane"
      },
      {
        "name": "Casablanca"
      },
      {
        "name": "Chaouen"
      },
      {
        "name": "El Jadida"
      },
      {
        "name": "El Kelaa des Sraghna"
      },
      {
        "name": "Er Rachidia"
      },
      {
        "name": "Essaouira"
      },
      {
        "name": "Fes"
      },
      {
        "name": "Figuig"
      },
      {
        "name": "Guelmim"
      },
      {
        "name": "Ifrane"
      },
      {
        "name": "Kenitra"
      },
      {
        "name": "Khemisset"
      },
      {
        "name": "Khenifra"
      },
      {
        "name": "Khouribga"
      },
      {
        "name": "Laayoune"
      },
      {
        "name": "Larache"
      },
      {
        "name": "Marrakech"
      },
      {
        "name": "Meknes"
      },
      {
        "name": "Nador"
      },
      {
        "name": "Ouarzazate"
      },
      {
        "name": "Oujda"
      },
      {
        "name": "Rabat-Sale"
      },
      {
        "name": "Safi"
      },
      {
        "name": "Settat"
      },
      {
        "name": "Sidi Kacem"
      },
      {
        "name": "Tangier"
      },
      {
        "name": "Tan-Tan"
      },
      {
        "name": "Taounate"
      },
      {
        "name": "Taroudannt"
      },
      {
        "name": "Tata"
      },
      {
        "name": "Taza"
      },
      {
        "name": "Tetouan"
      },
      {
        "name": "Tiznit"
      }
    ]
  },
  {
    "name": "Monaco",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Mozambique",
    "states": [
      {
        "name": "Cabo Delgado"
      },
      {
        "name": "Gaza"
      },
      {
        "name": "Inhambane"
      },
      {
        "name": "Manica"
      },
      {
        "name": "Maputo"
      },
      {
        "name": "Cidade de Maputo"
      },
      {
        "name": "Nampula"
      },
      {
        "name": "Niassa"
      },
      {
        "name": "Sofala"
      },
      {
        "name": "Tete"
      },
      {
        "name": "Zambezia"
      }
    ]
  },
  {
    "name": "Namibia",
    "states": [
      {
        "name": "Caprivi"
      },
      {
        "name": "Erongo"
      },
      {
        "name": "Hardap"
      },
      {
        "name": "Karas"
      },
      {
        "name": "Khomas"
      },
      {
        "name": "Kunene"
      },
      {
        "name": "Ohangwena"
      },
      {
        "name": "Okavango"
      },
      {
        "name": "Omaheke"
      },
      {
        "name": "Omusati"
      },
      {
        "name": "Oshana"
      },
      {
        "name": "Oshikoto"
      },
      {
        "name": "Otjozondjupa"
      }
    ]
  },
  {
    "name": "Nauru",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Nepal",
    "states": [
      {
        "name": "Bagmati"
      },
      {
        "name": "Bheri"
      },
      {
        "name": "Dhawalagiri"
      },
      {
        "name": "Gandaki"
      },
      {
        "name": "Janakpur"
      },
      {
        "name": "Karnali"
      },
      {
        "name": "Kosi"
      },
      {
        "name": "Lumbini"
      },
      {
        "name": "Mahakali"
      },
      {
        "name": "Mechi"
      },
      {
        "name": "Narayani"
      },
      {
        "name": "Rapti"
      },
      {
        "name": "Sagarmatha"
      },
      {
        "name": "Seti"
      }
    ]
  },
  {
    "name": "Netherlands",
    "states": [
      {
        "name": "Drenthe"
      },
      {
        "name": "Flevoland"
      },
      {
        "name": "Friesland"
      },
      {
        "name": "Gelderland"
      },
      {
        "name": "Groningen"
      },
      {
        "name": "Limburg"
      },
      {
        "name": "Noord-Brabant"
      },
      {
        "name": "Noord-Holland"
      },
      {
        "name": "Overijssel"
      },
      {
        "name": "Utrecht"
      },
      {
        "name": "Zeeland"
      },
      {
        "name": "Zuid-Holland"
      }
    ]
  },
  {
    "name": "New Zealand",
    "states": [
      {
        "name": "Auckland"
      },
      {
        "name": "Bay of Plenty"
      },
      {
        "name": "Canterbury"
      },
      {
        "name": "Chatham Islands"
      },
      {
        "name": "Gisborne"
      },
      {
        "name": "Hawke's Bay"
      },
      {
        "name": "Manawatu-Wanganui"
      },
      {
        "name": "Marlborough"
      },
      {
        "name": "Nelson"
      },
      {
        "name": "Northland"
      },
      {
        "name": "Otago"
      },
      {
        "name": "Southland"
      },
      {
        "name": "Taranaki"
      },
      {
        "name": "Tasman"
      },
      {
        "name": "Waikato"
      },
      {
        "name": "Wellington"
      },
      {
        "name": "West Coast"
      }
    ]
  },
  {
    "name": "Nicaragua",
    "states": [
      {
        "name": "Atlantico Norte"
      },
      {
        "name": "Atlantico Sur"
      },
      {
        "name": "Boaco"
      },
      {
        "name": "Carazo"
      },
      {
        "name": "Chinandega"
      },
      {
        "name": "Chontales"
      },
      {
        "name": "Esteli"
      },
      {
        "name": "Granada"
      },
      {
        "name": "Jinotega"
      },
      {
        "name": "Leon"
      },
      {
        "name": "Madriz"
      },
      {
        "name": "Managua"
      },
      {
        "name": "Masaya"
      },
      {
        "name": "Matagalpa"
      },
      {
        "name": "Nueva Segovia"
      },
      {
        "name": "Rio San Juan"
      },
      {
        "name": "Rivas"
      }
    ]
  },
  {
    "name": "Niger",
    "states": [
      {
        "name": "Agadez"
      },
      {
        "name": "Diffa"
      },
      {
        "name": "Dosso"
      },
      {
        "name": "Maradi"
      },
      {
        "name": "Niamey"
      },
      {
        "name": "Tahoua"
      },
      {
        "name": "Tillaberi"
      },
      {
        "name": "Zinder"
      }
    ]
  },
  {
    "name": "Nigeria",
    "states": [
      {
        "name": "Abia"
      },
      {
        "name": "Abuja Federal Capital"
      },
      {
        "name": "Adamawa"
      },
      {
        "name": "Akwa Ibom"
      },
      {
        "name": "Anambra"
      },
      {
        "name": "Bauchi"
      },
      {
        "name": "Bayelsa"
      },
      {
        "name": "Benue"
      },
      {
        "name": "Borno"
      },
      {
        "name": "Cross River"
      },
      {
        "name": "Delta"
      },
      {
        "name": "Ebonyi"
      },
      {
        "name": "Edo"
      },
      {
        "name": "Ekiti"
      },
      {
        "name": "Enugu"
      },
      {
        "name": "Gombe"
      },
      {
        "name": "Imo"
      },
      {
        "name": "Jigawa"
      },
      {
        "name": "Kaduna"
      },
      {
        "name": "Kano"
      },
      {
        "name": "Katsina"
      },
      {
        "name": "Kebbi"
      },
      {
        "name": "Kogi"
      },
      {
        "name": "Kwara"
      },
      {
        "name": "Lagos"
      },
      {
        "name": "Nassarawa"
      },
      {
        "name": "Niger"
      },
      {
        "name": "Ogun"
      },
      {
        "name": "Ondo"
      },
      {
        "name": "Osun"
      },
      {
        "name": "Oyo"
      },
      {
        "name": "Plateau"
      },
      {
        "name": "Rivers"
      },
      {
        "name": "Sokoto"
      },
      {
        "name": "Taraba"
      },
      {
        "name": "Yobe"
      },
      {
        "name": "Zamfara"
      }
    ]
  },
  {
    "name": "Norway",
    "states": [
      {
        "name": "Akershus"
      },
      {
        "name": "Aust-Agder"
      },
      {
        "name": "Buskerud"
      },
      {
        "name": "Finnmark"
      },
      {
        "name": "Hedmark"
      },
      {
        "name": "Hordaland"
      },
      {
        "name": "More og Romsdal"
      },
      {
        "name": "Nordland"
      },
      {
        "name": "Nord-Trondelag"
      },
      {
        "name": "Oppland"
      },
      {
        "name": "Oslo"
      },
      {
        "name": "Ostfold"
      },
      {
        "name": "Rogaland"
      },
      {
        "name": "Sogn og Fjordane"
      },
      {
        "name": "Sor-Trondelag"
      },
      {
        "name": "Telemark"
      },
      {
        "name": "Troms"
      },
      {
        "name": "Vest-Agder"
      },
      {
        "name": "Vestfold"
      }
    ]
  },
  {
    "name": "Oman",
    "states": [
      {
        "name": "Ad Dakhiliyah"
      },
      {
        "name": "Al Batinah"
      },
      {
        "name": "Al Wusta"
      },
      {
        "name": "Ash Sharqiyah"
      },
      {
        "name": "Az Zahirah"
      },
      {
        "name": "Masqat"
      },
      {
        "name": "Musandam"
      },
      {
        "name": "Dhofar"
      }
    ]
  },
  {
    "name": "Pakistan",
    "states": [
      {
        "name": "Balochistan"
      },
      {
        "name": "North-West Frontier Province"
      },
      {
        "name": "Punjab"
      },
      {
        "name": "Sindh"
      },
      {
        "name": "Islamabad Capital Territory"
      },
      {
        "name": "Federally Administered Tribal Areas"
      }
    ]
  },
  {
    "name": "Panama",
    "states": [
      {
        "name": "Bocas del Toro"
      },
      {
        "name": "Chiriqui"
      },
      {
        "name": "Cocle"
      },
      {
        "name": "Colon"
      },
      {
        "name": "Darien"
      },
      {
        "name": "Herrera"
      },
      {
        "name": "Los Santos"
      },
      {
        "name": "Panama"
      },
      {
        "name": "San Blas"
      },
      {
        "name": "Veraguas"
      }
    ]
  },
  {
    "name": "Papua New Guinea",
    "states": [
      {
        "name": "Bougainville"
      },
      {
        "name": "Central"
      },
      {
        "name": "Chimbu"
      },
      {
        "name": "Eastern Highlands"
      },
      {
        "name": "East New Britain"
      },
      {
        "name": "East Sepik"
      },
      {
        "name": "Enga"
      },
      {
        "name": "Gulf"
      },
      {
        "name": "Madang"
      },
      {
        "name": "Manus"
      },
      {
        "name": "Milne Bay"
      },
      {
        "name": "Morobe"
      },
      {
        "name": "National Capital"
      },
      {
        "name": "New Ireland"
      },
      {
        "name": "Northern"
      },
      {
        "name": "Sandaun"
      },
      {
        "name": "Southern Highlands"
      },
      {
        "name": "Western"
      },
      {
        "name": "Western Highlands"
      },
      {
        "name": "West New Britain"
      }
    ]
  },
  {
    "name": "Paraguay",
    "states": [
      {
        "name": "Alto Paraguay"
      },
      {
        "name": "Alto Parana"
      },
      {
        "name": "Amambay"
      },
      {
        "name": "Asuncion"
      },
      {
        "name": "Boqueron"
      },
      {
        "name": "Caaguazu"
      },
      {
        "name": "Caazapa"
      },
      {
        "name": "Canindeyu"
      },
      {
        "name": "Central"
      },
      {
        "name": "Concepcion"
      },
      {
        "name": "Cordillera"
      },
      {
        "name": "Guaira"
      },
      {
        "name": "Itapua"
      },
      {
        "name": "Misiones"
      },
      {
        "name": "Neembucu"
      },
      {
        "name": "Paraguari"
      },
      {
        "name": "Presidente Hayes"
      },
      {
        "name": "San Pedro"
      }
    ]
  },
  {
    "name": "Peru",
    "states": [
      {
        "name": "Amazonas"
      },
      {
        "name": "Ancash"
      },
      {
        "name": "Apurimac"
      },
      {
        "name": "Arequipa"
      },
      {
        "name": "Ayacucho"
      },
      {
        "name": "Cajamarca"
      },
      {
        "name": "Callao"
      },
      {
        "name": "Cusco"
      },
      {
        "name": "Huancavelica"
      },
      {
        "name": "Huanuco"
      },
      {
        "name": "Ica"
      },
      {
        "name": "Junin"
      },
      {
        "name": "La Libertad"
      },
      {
        "name": "Lambayeque"
      },
      {
        "name": "Lima"
      },
      {
        "name": "Loreto"
      },
      {
        "name": "Madre de Dios"
      },
      {
        "name": "Moquegua"
      },
      {
        "name": "Pasco"
      },
      {
        "name": "Piura"
      },
      {
        "name": "Puno"
      },
      {
        "name": "San Martin"
      },
      {
        "name": "Tacna"
      },
      {
        "name": "Tumbes"
      },
      {
        "name": "Ucayali"
      }
    ]
  },
  {
    "name": "Philippines",
    "states": [
      {
        "name": "Abra"
      },
      {
        "name": "Agusan del Norte"
      },
      {
        "name": "Agusan del Sur"
      },
      {
        "name": "Aklan"
      },
      {
        "name": "Albay"
      },
      {
        "name": "Antique"
      },
      {
        "name": "Apayao"
      },
      {
        "name": "Aurora"
      },
      {
        "name": "Basilan"
      },
      {
        "name": "Bataan"
      },
      {
        "name": "Batanes"
      },
      {
        "name": "Batangas"
      },
      {
        "name": "Biliran"
      },
      {
        "name": "Benguet"
      },
      {
        "name": "Bohol"
      },
      {
        "name": "Bukidnon"
      },
      {
        "name": "Bulacan"
      },
      {
        "name": "Cagayan"
      },
      {
        "name": "Camarines Norte"
      },
      {
        "name": "Camarines Sur"
      },
      {
        "name": "Camiguin"
      },
      {
        "name": "Capiz"
      },
      {
        "name": "Catanduanes"
      },
      {
        "name": "Cavite"
      },
      {
        "name": "Cebu"
      },
      {
        "name": "Compostela"
      },
      {
        "name": "Davao del Norte"
      },
      {
        "name": "Davao del Sur"
      },
      {
        "name": "Davao Oriental"
      },
      {
        "name": "Eastern Samar"
      },
      {
        "name": "Guimaras"
      },
      {
        "name": "Ifugao"
      },
      {
        "name": "Ilocos Norte"
      },
      {
        "name": "Ilocos Sur"
      },
      {
        "name": "Iloilo"
      },
      {
        "name": "Isabela"
      },
      {
        "name": "Kalinga"
      },
      {
        "name": "Laguna"
      },
      {
        "name": "Lanao del Norte"
      },
      {
        "name": "Lanao del Sur"
      },
      {
        "name": "La Union"
      },
      {
        "name": "Leyte"
      },
      {
        "name": "Maguindanao"
      },
      {
        "name": "Marinduque"
      },
      {
        "name": "Masbate"
      },
      {
        "name": "Mindoro Occidental"
      },
      {
        "name": "Mindoro Oriental"
      },
      {
        "name": "Misamis Occidental"
      },
      {
        "name": "Misamis Oriental"
      },
      {
        "name": "Mountain Province"
      },
      {
        "name": "Negros Occidental"
      },
      {
        "name": "Negros Oriental"
      },
      {
        "name": "North Cotabato"
      },
      {
        "name": "Northern Samar"
      },
      {
        "name": "Nueva Ecija"
      },
      {
        "name": "Nueva Vizcaya"
      },
      {
        "name": "Palawan"
      },
      {
        "name": "Pampanga"
      },
      {
        "name": "Pangasinan"
      },
      {
        "name": "Quezon"
      },
      {
        "name": "Quirino"
      },
      {
        "name": "Rizal"
      },
      {
        "name": "Romblon"
      },
      {
        "name": "Samar"
      },
      {
        "name": "Sarangani"
      },
      {
        "name": "Siquijor"
      },
      {
        "name": "Sorsogon"
      },
      {
        "name": "South Cotabato"
      },
      {
        "name": "Southern Leyte"
      },
      {
        "name": "Sultan Kudarat"
      },
      {
        "name": "Sulu"
      },
      {
        "name": "Surigao del Norte"
      },
      {
        "name": "Surigao del Sur"
      },
      {
        "name": "Tarlac"
      },
      {
        "name": "Tawi-Tawi"
      },
      {
        "name": "Zambales"
      },
      {
        "name": "Zamboanga del Norte"
      },
      {
        "name": "Zamboanga del Sur"
      },
      {
        "name": "Zamboanga Sibugay"
      }
    ]
  },
  {
    "name": "Poland",
    "states": [
      {
        "name": "Greater Poland (Wielkopolskie)"
      },
      {
        "name": "Kuyavian-Pomeranian (Kujawsko-Pomorskie)"
      },
      {
        "name": "Lesser Poland (Malopolskie)"
      },
      {
        "name": "Lodz (Lodzkie)"
      },
      {
        "name": "Lower Silesian (Dolnoslaskie)"
      },
      {
        "name": "Lublin (Lubelskie)"
      },
      {
        "name": "Lubusz (Lubuskie)"
      },
      {
        "name": "Masovian (Mazowieckie)"
      },
      {
        "name": "Opole (Opolskie)"
      },
      {
        "name": "Podlasie (Podlaskie)"
      },
      {
        "name": "Pomeranian (Pomorskie)"
      },
      {
        "name": "Silesian (Slaskie)"
      },
      {
        "name": "Subcarpathian (Podkarpackie)"
      },
      {
        "name": "Swietokrzyskie (Swietokrzyskie)"
      },
      {
        "name": "Warmian-Masurian (Warminsko-Mazurskie)"
      },
      {
        "name": "West Pomeranian (Zachodniopomorskie)"
      }
    ]
  },
  {
    "name": "Portugal",
    "states": [
      {
        "name": "Aveiro"
      },
      {
        "name": "Acores"
      },
      {
        "name": "Beja"
      },
      {
        "name": "Braga"
      },
      {
        "name": "Braganca"
      },
      {
        "name": "Castelo Branco"
      },
      {
        "name": "Coimbra"
      },
      {
        "name": "Evora"
      },
      {
        "name": "Faro"
      },
      {
        "name": "Guarda"
      },
      {
        "name": "Leiria"
      },
      {
        "name": "Lisboa"
      },
      {
        "name": "Madeira"
      },
      {
        "name": "Portalegre"
      },
      {
        "name": "Porto"
      },
      {
        "name": "Santarem"
      },
      {
        "name": "Setubal"
      },
      {
        "name": "Viana do Castelo"
      },
      {
        "name": "Vila Real"
      },
      {
        "name": "Viseu"
      }
    ]
  },
  {
    "name": "Qatar",
    "states": [
      {
        "name": "Ad Dawhah"
      },
      {
        "name": "Al Ghuwayriyah"
      },
      {
        "name": "Al Jumayliyah"
      },
      {
        "name": "Al Khawr"
      },
      {
        "name": "Al Wakrah"
      },
      {
        "name": "Ar Rayyan"
      },
      {
        "name": "Jarayan al Batinah"
      },
      {
        "name": "Madinat ash Shamal"
      },
      {
        "name": "Umm Sa'id"
      },
      {
        "name": "Umm Salal"
      }
    ]
  },
  {
    "name": "Romania",
    "states": [
      {
        "name": "Alba"
      },
      {
        "name": "Arad"
      },
      {
        "name": "Arges"
      },
      {
        "name": "Bacau"
      },
      {
        "name": "Bihor"
      },
      {
        "name": "Bistrita-Nasaud"
      },
      {
        "name": "Botosani"
      },
      {
        "name": "Braila"
      },
      {
        "name": "Brasov"
      },
      {
        "name": "Bucuresti"
      },
      {
        "name": "Buzau"
      },
      {
        "name": "Calarasi"
      },
      {
        "name": "Caras-Severin"
      },
      {
        "name": "Cluj"
      },
      {
        "name": "Constanta"
      },
      {
        "name": "Covasna"
      },
      {
        "name": "Dimbovita"
      },
      {
        "name": "Dolj"
      },
      {
        "name": "Galati"
      },
      {
        "name": "Gorj"
      },
      {
        "name": "Giurgiu"
      },
      {
        "name": "Harghita"
      },
      {
        "name": "Hunedoara"
      },
      {
        "name": "Ialomita"
      },
      {
        "name": "Iasi"
      },
      {
        "name": "Ilfov"
      },
      {
        "name": "Maramures"
      },
      {
        "name": "Mehedinti"
      },
      {
        "name": "Mures"
      },
      {
        "name": "Neamt"
      },
      {
        "name": "Olt"
      },
      {
        "name": "Prahova"
      },
      {
        "name": "Salaj"
      },
      {
        "name": "Satu Mare"
      },
      {
        "name": "Sibiu"
      },
      {
        "name": "Suceava"
      },
      {
        "name": "Teleorman"
      },
      {
        "name": "Timis"
      },
      {
        "name": "Tulcea"
      },
      {
        "name": "Vaslui"
      },
      {
        "name": "Vilcea"
      },
      {
        "name": "Vrancea"
      }
    ]
  },
  {
    "name": "Russia",
    "states": [
      {
        "name": "Amur"
      },
      {
        "name": "Arkhangel'sk"
      },
      {
        "name": "Astrakhan'"
      },
      {
        "name": "Belgorod"
      },
      {
        "name": "Bryansk"
      },
      {
        "name": "Chelyabinsk"
      },
      {
        "name": "Chita"
      },
      {
        "name": "Irkutsk"
      },
      {
        "name": "Ivanovo"
      },
      {
        "name": "Kaliningrad"
      },
      {
        "name": "Kaluga"
      },
      {
        "name": "Kamchatka"
      },
      {
        "name": "Kemerovo"
      },
      {
        "name": "Kirov"
      },
      {
        "name": "Kostroma"
      },
      {
        "name": "Kurgan"
      },
      {
        "name": "Kursk"
      },
      {
        "name": "Leningrad"
      },
      {
        "name": "Lipetsk"
      },
      {
        "name": "Magadan"
      },
      {
        "name": "Moscow"
      },
      {
        "name": "Murmansk"
      },
      {
        "name": "Nizhniy Novgorod"
      },
      {
        "name": "Novgorod"
      },
      {
        "name": "Novosibirsk"
      },
      {
        "name": "Omsk"
      },
      {
        "name": "Orenburg"
      },
      {
        "name": "Orel"
      },
      {
        "name": "Penza"
      },
      {
        "name": "Perm'"
      },
      {
        "name": "Pskov"
      },
      {
        "name": "Rostov"
      },
      {
        "name": "Ryazan'"
      },
      {
        "name": "Sakhalin"
      },
      {
        "name": "Samara"
      },
      {
        "name": "Saratov"
      },
      {
        "name": "Smolensk"
      },
      {
        "name": "Sverdlovsk"
      },
      {
        "name": "Tambov"
      },
      {
        "name": "Tomsk"
      },
      {
        "name": "Tula"
      },
      {
        "name": "Tver'"
      },
      {
        "name": "Tyumen'"
      },
      {
        "name": "Ul'yanovsk"
      },
      {
        "name": "Vladimir"
      },
      {
        "name": "Volgograd"
      },
      {
        "name": "Vologda"
      },
      {
        "name": "Voronezh"
      },
      {
        "name": "Yaroslavl'"
      },
      {
        "name": "Adygeya"
      },
      {
        "name": "Altay"
      },
      {
        "name": "Bashkortostan"
      },
      {
        "name": "Buryatiya"
      },
      {
        "name": "Chechnya"
      },
      {
        "name": "Chuvashiya"
      },
      {
        "name": "Dagestan"
      },
      {
        "name": "Ingushetiya"
      },
      {
        "name": "Kabardino-Balkariya"
      },
      {
        "name": "Kalmykiya"
      },
      {
        "name": "Karachayevo-Cherkesiya"
      },
      {
        "name": "Kareliya"
      },
      {
        "name": "Khakasiya"
      },
      {
        "name": "Komi"
      },
      {
        "name": "Mariy-El"
      },
      {
        "name": "Mordoviya"
      },
      {
        "name": "Sakha"
      },
      {
        "name": "North Ossetia"
      },
      {
        "name": "Tatarstan"
      },
      {
        "name": "Tyva"
      },
      {
        "name": "Udmurtiya"
      },
      {
        "name": "Aga Buryat"
      },
      {
        "name": "Chukotka"
      },
      {
        "name": "Evenk"
      },
      {
        "name": "Khanty-Mansi"
      },
      {
        "name": "Komi-Permyak"
      },
      {
        "name": "Koryak"
      },
      {
        "name": "Nenets"
      },
      {
        "name": "Taymyr"
      },
      {
        "name": "Ust'-Orda Buryat"
      },
      {
        "name": "Yamalo-Nenets"
      },
      {
        "name": "Altay"
      },
      {
        "name": "Khabarovsk"
      },
      {
        "name": "Krasnodar"
      },
      {
        "name": "Krasnoyarsk"
      },
      {
        "name": "Primorskiy"
      },
      {
        "name": "Stavropol'"
      },
      {
        "name": "Moscow"
      },
      {
        "name": "St. Petersburg"
      },
      {
        "name": "Yevrey"
      }
    ]
  },
  {
    "name": "Rwanda",
    "states": [
      {
        "name": "Butare"
      },
      {
        "name": "Byumba"
      },
      {
        "name": "Cyangugu"
      },
      {
        "name": "Gikongoro"
      },
      {
        "name": "Gisenyi"
      },
      {
        "name": "Gitarama"
      },
      {
        "name": "Kibungo"
      },
      {
        "name": "Kibuye"
      },
      {
        "name": "Kigali Rurale"
      },
      {
        "name": "Kigali-ville"
      },
      {
        "name": "Umutara"
      },
      {
        "name": "Ruhengeri"
      }
    ]
  },
  {
    "name": "Samoa",
    "states": [
      {
        "name": "A'ana"
      },
      {
        "name": "Aiga-i-le-Tai"
      },
      {
        "name": "Atua"
      },
      {
        "name": "Fa'asaleleaga"
      },
      {
        "name": "Gaga'emauga"
      },
      {
        "name": "Gagaifomauga"
      },
      {
        "name": "Palauli"
      },
      {
        "name": "Satupa'itea"
      },
      {
        "name": "Tuamasaga"
      },
      {
        "name": "Va'a-o-Fonoti"
      },
      {
        "name": "Vaisigano"
      }
    ]
  },
  {
    "name": "San Marino",
    "states": [
      {
        "name": "Acquaviva"
      },
      {
        "name": "Borgo Maggiore"
      },
      {
        "name": "Chiesanuova"
      },
      {
        "name": "Domagnano"
      },
      {
        "name": "Faetano"
      },
      {
        "name": "Fiorentino"
      },
      {
        "name": "Montegiardino"
      },
      {
        "name": "San Marino Citta"
      },
      {
        "name": "Serravalle"
      }
    ]
  },
  {
    "name": "Sao Tome",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Saudi Arabia",
    "states": [
      {
        "name": "Al Bahah"
      },
      {
        "name": "Al Hudud ash Shamaliyah"
      },
      {
        "name": "Al Jawf"
      },
      {
        "name": "Al Madinah"
      },
      {
        "name": "Al Qasim"
      },
      {
        "name": "Ar Riyad"
      },
      {
        "name": "Ash Sharqiyah"
      },
      {
        "name": "'Asir"
      },
      {
        "name": "Ha'il"
      },
      {
        "name": "Jizan"
      },
      {
        "name": "Makkah"
      },
      {
        "name": "Najran"
      },
      {
        "name": "Tabuk"
      }
    ]
  },
  {
    "name": "Senegal",
    "states": [
      {
        "name": "Dakar"
      },
      {
        "name": "Diourbel"
      },
      {
        "name": "Fatick"
      },
      {
        "name": "Kaolack"
      },
      {
        "name": "Kolda"
      },
      {
        "name": "Louga"
      },
      {
        "name": "Matam"
      },
      {
        "name": "Saint-Louis"
      },
      {
        "name": "Tambacounda"
      },
      {
        "name": "Thies"
      },
      {
        "name": "Ziguinchor"
      }
    ]
  },
  {
    "name": "Serbia and Montenegro",
    "states": [
      {
        "name": "Kosovo"
      },
      {
        "name": "Montenegro"
      },
      {
        "name": "Serbia"
      },
      {
        "name": "Vojvodina"
      }
    ]
  },
  {
    "name": "Seychelles",
    "states": [
      {
        "name": "Anse aux Pins"
      },
      {
        "name": "Anse Boileau"
      },
      {
        "name": "Anse Etoile"
      },
      {
        "name": "Anse Louis"
      },
      {
        "name": "Anse Royale"
      },
      {
        "name": "Baie Lazare"
      },
      {
        "name": "Baie Sainte Anne"
      },
      {
        "name": "Beau Vallon"
      },
      {
        "name": "Bel Air"
      },
      {
        "name": "Bel Ombre"
      },
      {
        "name": "Cascade"
      },
      {
        "name": "Glacis"
      },
      {
        "name": "Grand' Anse"
      },
      {
        "name": "Grand' Anse"
      },
      {
        "name": "La Digue"
      },
      {
        "name": "La Riviere Anglaise"
      },
      {
        "name": "Mont Buxton"
      },
      {
        "name": "Mont Fleuri"
      },
      {
        "name": "Plaisance"
      },
      {
        "name": "Pointe La Rue"
      },
      {
        "name": "Port Glaud"
      },
      {
        "name": "Saint Louis"
      },
      {
        "name": "Takamaka"
      }
    ]
  },
  {
    "name": "Sierra Leone",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Singapore",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Slovakia",
    "states": [
      {
        "name": "Banskobystricky"
      },
      {
        "name": "Bratislavsky"
      },
      {
        "name": "Kosicky"
      },
      {
        "name": "Nitriansky"
      },
      {
        "name": "Presovsky"
      },
      {
        "name": "Trenciansky"
      },
      {
        "name": "Trnavsky"
      },
      {
        "name": "Zilinsky"
      }
    ]
  },
  {
    "name": "Slovenia",
    "states": [
      {
        "name": "Ajdovscina"
      },
      {
        "name": "Beltinci"
      },
      {
        "name": "Benedikt"
      },
      {
        "name": "Bistrica ob Sotli"
      },
      {
        "name": "Bled"
      },
      {
        "name": "Bloke"
      },
      {
        "name": "Bohinj"
      },
      {
        "name": "Borovnica"
      },
      {
        "name": "Bovec"
      },
      {
        "name": "Braslovce"
      },
      {
        "name": "Brda"
      },
      {
        "name": "Brezice"
      },
      {
        "name": "Brezovica"
      },
      {
        "name": "Cankova"
      },
      {
        "name": "Celje"
      },
      {
        "name": "Cerklje na Gorenjskem"
      },
      {
        "name": "Cerknica"
      },
      {
        "name": "Cerkno"
      },
      {
        "name": "Cerkvenjak"
      },
      {
        "name": "Crensovci"
      },
      {
        "name": "Crna na Koroskem"
      },
      {
        "name": "Crnomelj"
      },
      {
        "name": "Destrnik"
      },
      {
        "name": "Divaca"
      },
      {
        "name": "Dobje"
      },
      {
        "name": "Dobrepolje"
      },
      {
        "name": "Dobrna"
      },
      {
        "name": "Dobrova-Horjul-Polhov Gradec"
      },
      {
        "name": "Dobrovnik-Dobronak"
      },
      {
        "name": "Dolenjske Toplice"
      },
      {
        "name": "Dol pri Ljubljani"
      },
      {
        "name": "Domzale"
      },
      {
        "name": "Dornava"
      },
      {
        "name": "Dravograd"
      },
      {
        "name": "Duplek"
      },
      {
        "name": "Gorenja Vas-Poljane"
      },
      {
        "name": "Gorisnica"
      },
      {
        "name": "Gornja Radgona"
      },
      {
        "name": "Gornji Grad"
      },
      {
        "name": "Gornji Petrovci"
      },
      {
        "name": "Grad"
      },
      {
        "name": "Grosuplje"
      },
      {
        "name": "Hajdina"
      },
      {
        "name": "Hoce-Slivnica"
      },
      {
        "name": "Hodos-Hodos"
      },
      {
        "name": "Horjul"
      },
      {
        "name": "Hrastnik"
      },
      {
        "name": "Hrpelje-Kozina"
      },
      {
        "name": "Idrija"
      },
      {
        "name": "Ig"
      },
      {
        "name": "Ilirska Bistrica"
      },
      {
        "name": "Ivancna Gorica"
      },
      {
        "name": "Izola-Isola"
      },
      {
        "name": "Jesenice"
      },
      {
        "name": "Jezersko"
      },
      {
        "name": "Jursinci"
      },
      {
        "name": "Kamnik"
      },
      {
        "name": "Kanal"
      },
      {
        "name": "Kidricevo"
      },
      {
        "name": "Kobarid"
      },
      {
        "name": "Kobilje"
      },
      {
        "name": "Kocevje"
      },
      {
        "name": "Komen"
      },
      {
        "name": "Komenda"
      },
      {
        "name": "Koper-Capodistria"
      },
      {
        "name": "Kostel"
      },
      {
        "name": "Kozje"
      },
      {
        "name": "Kranj"
      },
      {
        "name": "Kranjska Gora"
      },
      {
        "name": "Krizevci"
      },
      {
        "name": "Krsko"
      },
      {
        "name": "Kungota"
      },
      {
        "name": "Kuzma"
      },
      {
        "name": "Lasko"
      },
      {
        "name": "Lenart"
      },
      {
        "name": "Lendava-Lendva"
      },
      {
        "name": "Litija"
      },
      {
        "name": "Ljubljana"
      },
      {
        "name": "Ljubno"
      },
      {
        "name": "Ljutomer"
      },
      {
        "name": "Logatec"
      },
      {
        "name": "Loska Dolina"
      },
      {
        "name": "Loski Potok"
      },
      {
        "name": "Lovrenc na Pohorju"
      },
      {
        "name": "Luce"
      },
      {
        "name": "Lukovica"
      },
      {
        "name": "Majsperk"
      },
      {
        "name": "Maribor"
      },
      {
        "name": "Markovci"
      },
      {
        "name": "Medvode"
      },
      {
        "name": "Menges"
      },
      {
        "name": "Metlika"
      },
      {
        "name": "Mezica"
      },
      {
        "name": "Miklavz na Dravskem Polju"
      },
      {
        "name": "Miren-Kostanjevica"
      },
      {
        "name": "Mirna Pec"
      },
      {
        "name": "Mislinja"
      },
      {
        "name": "Moravce"
      },
      {
        "name": "Moravske Toplice"
      },
      {
        "name": "Mozirje"
      },
      {
        "name": "Murska Sobota"
      },
      {
        "name": "Muta"
      },
      {
        "name": "Naklo"
      },
      {
        "name": "Nazarje"
      },
      {
        "name": "Nova Gorica"
      },
      {
        "name": "Novo Mesto"
      },
      {
        "name": "Odranci"
      },
      {
        "name": "Oplotnica"
      },
      {
        "name": "Ormoz"
      },
      {
        "name": "Osilnica"
      },
      {
        "name": "Pesnica"
      },
      {
        "name": "Piran-Pirano"
      },
      {
        "name": "Pivka"
      },
      {
        "name": "Podcetrtek"
      },
      {
        "name": "Podlehnik"
      },
      {
        "name": "Podvelka"
      },
      {
        "name": "Polzela"
      },
      {
        "name": "Postojna"
      },
      {
        "name": "Prebold"
      },
      {
        "name": "Preddvor"
      },
      {
        "name": "Prevalje"
      },
      {
        "name": "Ptuj"
      },
      {
        "name": "Puconci"
      },
      {
        "name": "Race-Fram"
      },
      {
        "name": "Radece"
      },
      {
        "name": "Radenci"
      },
      {
        "name": "Radlje ob Dravi"
      },
      {
        "name": "Radovljica"
      },
      {
        "name": "Ravne na Koroskem"
      },
      {
        "name": "Razkrizje"
      },
      {
        "name": "Ribnica"
      },
      {
        "name": "Ribnica na Pohorju"
      },
      {
        "name": "Rogasovci"
      },
      {
        "name": "Rogaska Slatina"
      },
      {
        "name": "Rogatec"
      },
      {
        "name": "Ruse"
      },
      {
        "name": "Salovci"
      },
      {
        "name": "Selnica ob Dravi"
      },
      {
        "name": "Semic"
      },
      {
        "name": "Sempeter-Vrtojba"
      },
      {
        "name": "Sencur"
      },
      {
        "name": "Sentilj"
      },
      {
        "name": "Sentjernej"
      },
      {
        "name": "Sentjur pri Celju"
      },
      {
        "name": "Sevnica"
      },
      {
        "name": "Sezana"
      },
      {
        "name": "Skocjan"
      },
      {
        "name": "Skofja Loka"
      },
      {
        "name": "Skofljica"
      },
      {
        "name": "Slovenj Gradec"
      },
      {
        "name": "Slovenska Bistrica"
      },
      {
        "name": "Slovenske Konjice"
      },
      {
        "name": "Smarje pri Jelsah"
      },
      {
        "name": "Smartno ob Paki"
      },
      {
        "name": "Smartno pri Litiji"
      },
      {
        "name": "Sodrazica"
      },
      {
        "name": "Solcava"
      },
      {
        "name": "Sostanj"
      },
      {
        "name": "Starse"
      },
      {
        "name": "Store"
      },
      {
        "name": "Sveta Ana"
      },
      {
        "name": "Sveti Andraz v Slovenskih Goricah"
      },
      {
        "name": "Sveti Jurij"
      },
      {
        "name": "Tabor"
      },
      {
        "name": "Tisina"
      },
      {
        "name": "Tolmin"
      },
      {
        "name": "Trbovlje"
      },
      {
        "name": "Trebnje"
      },
      {
        "name": "Trnovska Vas"
      },
      {
        "name": "Trzic"
      },
      {
        "name": "Trzin"
      },
      {
        "name": "Turnisce"
      },
      {
        "name": "Velenje"
      },
      {
        "name": "Velika Polana"
      },
      {
        "name": "Velike Lasce"
      },
      {
        "name": "Verzej"
      },
      {
        "name": "Videm"
      },
      {
        "name": "Vipava"
      },
      {
        "name": "Vitanje"
      },
      {
        "name": "Vodice"
      },
      {
        "name": "Vojnik"
      },
      {
        "name": "Vransko"
      },
      {
        "name": "Vrhnika"
      },
      {
        "name": "Vuzenica"
      },
      {
        "name": "Zagorje ob Savi"
      },
      {
        "name": "Zalec"
      },
      {
        "name": "Zavrc"
      },
      {
        "name": "Zelezniki"
      },
      {
        "name": "Zetale"
      },
      {
        "name": "Ziri"
      },
      {
        "name": "Zirovnica"
      },
      {
        "name": "Zuzemberk"
      },
      {
        "name": "Zrece"
      }
    ]
  },
  {
    "name": "Solomon Islands",
    "states": [
      {
        "name": "Central"
      },
      {
        "name": "Choiseul"
      },
      {
        "name": "Guadalcanal"
      },
      {
        "name": "Honiara"
      },
      {
        "name": "Isabel"
      },
      {
        "name": "Makira"
      },
      {
        "name": "Malaita"
      },
      {
        "name": "Rennell and Bellona"
      },
      {
        "name": "Temotu"
      },
      {
        "name": "Western"
      }
    ]
  },
  {
    "name": "Somalia",
    "states": [
      {
        "name": "Awdal"
      },
      {
        "name": "Bakool"
      },
      {
        "name": "Banaadir"
      },
      {
        "name": "Bari"
      },
      {
        "name": "Bay"
      },
      {
        "name": "Galguduud"
      },
      {
        "name": "Gedo"
      },
      {
        "name": "Hiiraan"
      },
      {
        "name": "Jubbada Dhexe"
      },
      {
        "name": "Jubbada Hoose"
      },
      {
        "name": "Mudug"
      },
      {
        "name": "Nugaal"
      },
      {
        "name": "Sanaag"
      },
      {
        "name": "Shabeellaha Dhexe"
      },
      {
        "name": "Shabeellaha Hoose"
      },
      {
        "name": "Sool"
      },
      {
        "name": "Togdheer"
      },
      {
        "name": "Woqooyi Galbeed"
      }
    ]
  },
  {
    "name": "South Africa",
    "states": [
      {
        "name": "Eastern Cape"
      },
      {
        "name": "Free State"
      },
      {
        "name": "Gauteng"
      },
      {
        "name": "KwaZulu-Natal"
      },
      {
        "name": "Limpopo"
      },
      {
        "name": "Mpumalanga"
      },
      {
        "name": "North-West"
      },
      {
        "name": "Northern Cape"
      },
      {
        "name": "Western Cape"
      }
    ]
  },
  {
    "name": "Spain",
    "states": [
      {
        "name": "Andalucia"
      },
      {
        "name": "Aragon"
      },
      {
        "name": "Asturias"
      },
      {
        "name": "Baleares"
      },
      {
        "name": "Ceuta"
      },
      {
        "name": "Canarias"
      },
      {
        "name": "Cantabria"
      },
      {
        "name": "Castilla-La Mancha"
      },
      {
        "name": "Castilla y Leon"
      },
      {
        "name": "Cataluna"
      },
      {
        "name": "Comunidad Valenciana"
      },
      {
        "name": "Extremadura"
      },
      {
        "name": "Galicia"
      },
      {
        "name": "La Rioja"
      },
      {
        "name": "Madrid"
      },
      {
        "name": "Melilla"
      },
      {
        "name": "Murcia"
      },
      {
        "name": "Navarra"
      },
      {
        "name": "Pais Vasco"
      }
    ]
  },
  {
    "name": "Sri Lanka",
    "states": [
      {
        "name": "Central"
      },
      {
        "name": "North Central"
      },
      {
        "name": "North Eastern"
      },
      {
        "name": "North Western"
      },
      {
        "name": "Sabaragamuwa"
      },
      {
        "name": "Southern"
      },
      {
        "name": "Uva"
      },
      {
        "name": "Western"
      }
    ]
  },
  {
    "name": "Sudan",
    "states": [
      {
        "name": "A'ali an Nil"
      },
      {
        "name": "Al Bahr al Ahmar"
      },
      {
        "name": "Al Buhayrat"
      },
      {
        "name": "Al Jazirah"
      },
      {
        "name": "Al Khartum"
      },
      {
        "name": "Al Qadarif"
      },
      {
        "name": "Al Wahdah"
      },
      {
        "name": "An Nil al Abyad"
      },
      {
        "name": "An Nil al Azraq"
      },
      {
        "name": "Ash Shamaliyah"
      },
      {
        "name": "Bahr al Jabal"
      },
      {
        "name": "Gharb al Istiwa'iyah"
      },
      {
        "name": "Gharb Bahr al Ghazal"
      },
      {
        "name": "Gharb Darfur"
      },
      {
        "name": "Gharb Kurdufan"
      },
      {
        "name": "Janub Darfur"
      },
      {
        "name": "Janub Kurdufan"
      },
      {
        "name": "Junqali"
      },
      {
        "name": "Kassala"
      },
      {
        "name": "Nahr an Nil"
      },
      {
        "name": "Shamal Bahr al Ghazal"
      },
      {
        "name": "Shamal Darfur"
      },
      {
        "name": "Shamal Kurdufan"
      },
      {
        "name": "Sharq al Istiwa'iyah"
      },
      {
        "name": "Sinnar"
      },
      {
        "name": "Warab"
      }
    ]
  },
  {
    "name": "Suriname",
    "states": [
      {
        "name": "Brokopondo"
      },
      {
        "name": "Commewijne"
      },
      {
        "name": "Coronie"
      },
      {
        "name": "Marowijne"
      },
      {
        "name": "Nickerie"
      },
      {
        "name": "Para"
      },
      {
        "name": "Paramaribo"
      },
      {
        "name": "Saramacca"
      },
      {
        "name": "Sipaliwini"
      },
      {
        "name": "Wanica"
      }
    ]
  },
  {
    "name": "Swaziland",
    "states": [
      {
        "name": "Hhohho"
      },
      {
        "name": "Lubombo"
      },
      {
        "name": "Manzini"
      },
      {
        "name": "Shiselweni"
      }
    ]
  },
  {
    "name": "Sweden",
    "states": [
      {
        "name": "Blekinge"
      },
      {
        "name": "Dalarnas"
      },
      {
        "name": "Gavleborgs"
      },
      {
        "name": "Gotlands"
      },
      {
        "name": "Hallands"
      },
      {
        "name": "Jamtlands"
      },
      {
        "name": "Jonkopings"
      },
      {
        "name": "Kalmar"
      },
      {
        "name": "Kronobergs"
      },
      {
        "name": "Norrbottens"
      },
      {
        "name": "Orebro"
      },
      {
        "name": "Ostergotlands"
      },
      {
        "name": "Skane"
      },
      {
        "name": "Sodermanlands"
      },
      {
        "name": "Stockholms"
      },
      {
        "name": "Uppsala"
      },
      {
        "name": "Varmlands"
      },
      {
        "name": "Vasterbottens"
      },
      {
        "name": "Vasternorrlands"
      },
      {
        "name": "Vastmanlands"
      },
      {
        "name": "Vastra Gotalands"
      }
    ]
  },
  {
    "name": "Switzerland",
    "states": [
      {
        "name": "Aargau"
      },
      {
        "name": "Appenzell Ausser-Rhoden"
      },
      {
        "name": "Appenzell Inner-Rhoden"
      },
      {
        "name": "Basel-Landschaft"
      },
      {
        "name": "Basel-Stadt"
      },
      {
        "name": "Bern"
      },
      {
        "name": "Fribourg"
      },
      {
        "name": "Geneve"
      },
      {
        "name": "Glarus"
      },
      {
        "name": "Graubunden"
      },
      {
        "name": "Jura"
      },
      {
        "name": "Luzern"
      },
      {
        "name": "Neuchatel"
      },
      {
        "name": "Nidwalden"
      },
      {
        "name": "Obwalden"
      },
      {
        "name": "Sankt Gallen"
      },
      {
        "name": "Schaffhausen"
      },
      {
        "name": "Schwyz"
      },
      {
        "name": "Solothurn"
      },
      {
        "name": "Thurgau"
      },
      {
        "name": "Ticino"
      },
      {
        "name": "Uri"
      },
      {
        "name": "Valais"
      },
      {
        "name": "Vaud"
      },
      {
        "name": "Zug"
      },
      {
        "name": "Zurich"
      }
    ]
  },
  {
    "name": "Syria",
    "states": [
      {
        "name": "Al Hasakah"
      },
      {
        "name": "Al Ladhiqiyah"
      },
      {
        "name": "Al Qunaytirah"
      },
      {
        "name": "Ar Raqqah"
      },
      {
        "name": "As Suwayda'"
      },
      {
        "name": "Dar'a"
      },
      {
        "name": "Dayr az Zawr"
      },
      {
        "name": "Dimashq"
      },
      {
        "name": "Halab"
      },
      {
        "name": "Hamah"
      },
      {
        "name": "Hims"
      },
      {
        "name": "Idlib"
      },
      {
        "name": "Rif Dimashq"
      },
      {
        "name": "Tartus"
      }
    ]
  },
  {
    "name": "Taiwan",
    "states": [
      {
        "name": "Chang-hua"
      },
      {
        "name": "Chia-i"
      },
      {
        "name": "Hsin-chu"
      },
      {
        "name": "Hua-lien"
      },
      {
        "name": "I-lan"
      },
      {
        "name": "Kao-hsiung"
      },
      {
        "name": "Kin-men"
      },
      {
        "name": "Lien-chiang"
      },
      {
        "name": "Miao-li"
      },
      {
        "name": "Nan-t'ou"
      },
      {
        "name": "P'eng-hu"
      },
      {
        "name": "P'ing-tung"
      },
      {
        "name": "T'ai-chung"
      },
      {
        "name": "T'ai-nan"
      },
      {
        "name": "T'ai-pei"
      },
      {
        "name": "T'ai-tung"
      },
      {
        "name": "T'ao-yuan"
      },
      {
        "name": "Yun-lin"
      },
      {
        "name": "Chia-i"
      },
      {
        "name": "Chi-lung"
      },
      {
        "name": "Hsin-chu"
      },
      {
        "name": "T'ai-chung"
      },
      {
        "name": "T'ai-nan"
      },
      {
        "name": "Kao-hsiung city"
      },
      {
        "name": "T'ai-pei city"
      }
    ]
  },
  {
    "name": "Tajikistan",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Tanzania",
    "states": [
      {
        "name": "Arusha"
      },
      {
        "name": "Dar es Salaam"
      },
      {
        "name": "Dodoma"
      },
      {
        "name": "Iringa"
      },
      {
        "name": "Kagera"
      },
      {
        "name": "Kigoma"
      },
      {
        "name": "Kilimanjaro"
      },
      {
        "name": "Lindi"
      },
      {
        "name": "Manyara"
      },
      {
        "name": "Mara"
      },
      {
        "name": "Mbeya"
      },
      {
        "name": "Morogoro"
      },
      {
        "name": "Mtwara"
      },
      {
        "name": "Mwanza"
      },
      {
        "name": "Pemba North"
      },
      {
        "name": "Pemba South"
      },
      {
        "name": "Pwani"
      },
      {
        "name": "Rukwa"
      },
      {
        "name": "Ruvuma"
      },
      {
        "name": "Shinyanga"
      },
      {
        "name": "Singida"
      },
      {
        "name": "Tabora"
      },
      {
        "name": "Tanga"
      },
      {
        "name": "Zanzibar Central/South"
      },
      {
        "name": "Zanzibar North"
      },
      {
        "name": "Zanzibar Urban/West"
      }
    ]
  },
  {
    "name": "Thailand",
    "states": [
      {
        "name": "Amnat Charoen"
      },
      {
        "name": "Ang Thong"
      },
      {
        "name": "Buriram"
      },
      {
        "name": "Chachoengsao"
      },
      {
        "name": "Chai Nat"
      },
      {
        "name": "Chaiyaphum"
      },
      {
        "name": "Chanthaburi"
      },
      {
        "name": "Chiang Mai"
      },
      {
        "name": "Chiang Rai"
      },
      {
        "name": "Chon Buri"
      },
      {
        "name": "Chumphon"
      },
      {
        "name": "Kalasin"
      },
      {
        "name": "Kamphaeng Phet"
      },
      {
        "name": "Kanchanaburi"
      },
      {
        "name": "Khon Kaen"
      },
      {
        "name": "Krabi"
      },
      {
        "name": "Krung Thep Mahanakhon"
      },
      {
        "name": "Lampang"
      },
      {
        "name": "Lamphun"
      },
      {
        "name": "Loei"
      },
      {
        "name": "Lop Buri"
      },
      {
        "name": "Mae Hong Son"
      },
      {
        "name": "Maha Sarakham"
      },
      {
        "name": "Mukdahan"
      },
      {
        "name": "Nakhon Nayok"
      },
      {
        "name": "Nakhon Pathom"
      },
      {
        "name": "Nakhon Phanom"
      },
      {
        "name": "Nakhon Ratchasima"
      },
      {
        "name": "Nakhon Sawan"
      },
      {
        "name": "Nakhon Si Thammarat"
      },
      {
        "name": "Nan"
      },
      {
        "name": "Narathiwat"
      },
      {
        "name": "Nong Bua Lamphu"
      },
      {
        "name": "Nong Khai"
      },
      {
        "name": "Nonthaburi"
      },
      {
        "name": "Pathum Thani"
      },
      {
        "name": "Pattani"
      },
      {
        "name": "Phangnga"
      },
      {
        "name": "Phatthalung"
      },
      {
        "name": "Phayao"
      },
      {
        "name": "Phetchabun"
      },
      {
        "name": "Phetchaburi"
      },
      {
        "name": "Phichit"
      },
      {
        "name": "Phitsanulok"
      },
      {
        "name": "Phra Nakhon Si Ayutthaya"
      },
      {
        "name": "Phrae"
      },
      {
        "name": "Phuket"
      },
      {
        "name": "Prachin Buri"
      },
      {
        "name": "Prachuap Khiri Khan"
      },
      {
        "name": "Ranong"
      },
      {
        "name": "Ratchaburi"
      },
      {
        "name": "Rayong"
      },
      {
        "name": "Roi Et"
      },
      {
        "name": "Sa Kaeo"
      },
      {
        "name": "Sakon Nakhon"
      },
      {
        "name": "Samut Prakan"
      },
      {
        "name": "Samut Sakhon"
      },
      {
        "name": "Samut Songkhram"
      },
      {
        "name": "Sara Buri"
      },
      {
        "name": "Satun"
      },
      {
        "name": "Sing Buri"
      },
      {
        "name": "Sisaket"
      },
      {
        "name": "Songkhla"
      },
      {
        "name": "Sukhothai"
      },
      {
        "name": "Suphan Buri"
      },
      {
        "name": "Surat Thani"
      },
      {
        "name": "Surin"
      },
      {
        "name": "Tak"
      },
      {
        "name": "Trang"
      },
      {
        "name": "Trat"
      },
      {
        "name": "Ubon Ratchathani"
      },
      {
        "name": "Udon Thani"
      },
      {
        "name": "Uthai Thani"
      },
      {
        "name": "Uttaradit"
      },
      {
        "name": "Yala"
      },
      {
        "name": "Yasothon"
      }
    ]
  },
  {
    "name": "Togo",
    "states": [
      {
        "name": "Kara"
      },
      {
        "name": "Plateaux"
      },
      {
        "name": "Savanes"
      },
      {
        "name": "Centrale"
      },
      {
        "name": "Maritime"
      }
    ]
  },
  {
    "name": "Tonga",
    "states": [
      {
        "name": "N/A"
      }
    ]
  },
  {
    "name": "Trinidad and Tobago",
    "states": [
      {
        "name": "Couva"
      },
      {
        "name": "Diego Martin"
      },
      {
        "name": "Mayaro"
      },
      {
        "name": "Penal"
      },
      {
        "name": "Princes Town"
      },
      {
        "name": "Sangre Grande"
      },
      {
        "name": "San Juan"
      },
      {
        "name": "Siparia"
      },
      {
        "name": "Tunapuna"
      },
      {
        "name": "Port-of-Spain"
      },
      {
        "name": "San Fernando"
      },
      {
        "name": "Arima"
      },
      {
        "name": "Point Fortin"
      },
      {
        "name": "Chaguanas"
      },
      {
        "name": "Tobago"
      }
    ]
  },
  {
    "name": "Tunisia",
    "states": [
      {
        "name": "Ariana (Aryanah)"
      },
      {
        "name": "Beja (Bajah)"
      },
      {
        "name": "Ben Arous (Bin 'Arus)"
      },
      {
        "name": "Bizerte (Banzart)"
      },
      {
        "name": "Gabes (Qabis)"
      },
      {
        "name": "Gafsa (Qafsah)"
      },
      {
        "name": "Jendouba (Jundubah)"
      },
      {
        "name": "Kairouan (Al Qayrawan)"
      },
      {
        "name": "Kasserine (Al Qasrayn)"
      },
      {
        "name": "Kebili (Qibili)"
      },
      {
        "name": "Kef (Al Kaf)"
      },
      {
        "name": "Mahdia (Al Mahdiyah)"
      },
      {
        "name": "Manouba (Manubah)"
      },
      {
        "name": "Medenine (Madanin)"
      },
      {
        "name": "Monastir (Al Munastir)"
      },
      {
        "name": "Nabeul (Nabul)"
      },
      {
        "name": "Sfax (Safaqis)"
      },
      {
        "name": "Sidi Bou Zid (Sidi Bu Zayd)"
      },
      {
        "name": "Siliana (Silyanah)"
      },
      {
        "name": "Sousse (Susah)"
      },
      {
        "name": "Tataouine (Tatawin)"
      },
      {
        "name": "Tozeur (Tawzar)"
      },
      {
        "name": "Tunis"
      },
      {
        "name": "Zaghouan (Zaghwan)"
      }
    ]
  },
  {
    "name": "Turkey",
    "states": [
      {
        "name": "Adana"
      },
      {
        "name": "Adiyaman"
      },
      {
        "name": "Afyonkarahisar"
      },
      {
        "name": "Agri"
      },
      {
        "name": "Aksaray"
      },
      {
        "name": "Amasya"
      },
      {
        "name": "Ankara"
      },
      {
        "name": "Antalya"
      },
      {
        "name": "Ardahan"
      },
      {
        "name": "Artvin"
      },
      {
        "name": "Aydin"
      },
      {
        "name": "Balikesir"
      },
      {
        "name": "Bartin"
      },
      {
        "name": "Batman"
      },
      {
        "name": "Bayburt"
      },
      {
        "name": "Bilecik"
      },
      {
        "name": "Bingol"
      },
      {
        "name": "Bitlis"
      },
      {
        "name": "Bolu"
      },
      {
        "name": "Burdur"
      },
      {
        "name": "Bursa"
      },
      {
        "name": "Canakkale"
      },
      {
        "name": "Cankiri"
      },
      {
        "name": "Corum"
      },
      {
        "name": "Denizli"
      },
      {
        "name": "Diyarbakir"
      },
      {
        "name": "Duzce"
      },
      {
        "name": "Edirne"
      },
      {
        "name": "Elazig"
      },
      {
        "name": "Erzincan"
      },
      {
        "name": "Erzurum"
      },
      {
        "name": "Eskisehir"
      },
      {
        "name": "Gaziantep"
      },
      {
        "name": "Giresun"
      },
      {
        "name": "Gumushane"
      },
      {
        "name": "Hakkari"
      },
      {
        "name": "Hatay"
      },
      {
        "name": "Igdir"
      },
      {
        "name": "Isparta"
      },
      {
        "name": "Istanbul"
      },
      {
        "name": "Izmir"
      },
      {
        "name": "Kahramanmaras"
      },
      {
        "name": "Karabuk"
      },
      {
        "name": "Karaman"
      },
      {
        "name": "Kars"
      },
      {
        "name": "Kastamonu"
      },
      {
        "name": "Kayseri"
      },
      {
        "name": "Kilis"
      },
      {
        "name": "Kirikkale"
      },
      {
        "name": "Kirklareli"
      },
      {
        "name": "Kirsehir"
      },
      {
        "name": "Kocaeli"
      },
      {
        "name": "Konya"
      },
      {
        "name": "Kutahya"
      },
      {
        "name": "Malatya"
      },
      {
        "name": "Manisa"
      },
      {
        "name": "Mardin"
      },
      {
        "name": "Mersin"
      },
      {
        "name": "Mugla"
      },
      {
        "name": "Mus"
      },
      {
        "name": "Nevsehir"
      },
      {
        "name": "Nigde"
      },
      {
        "name": "Ordu"
      },
      {
        "name": "Osmaniye"
      },
      {
        "name": "Rize"
      },
      {
        "name": "Sakarya"
      },
      {
        "name": "Samsun"
      },
      {
        "name": "Sanliurfa"
      },
      {
        "name": "Siirt"
      },
      {
        "name": "Sinop"
      },
      {
        "name": "Sirnak"
      },
      {
        "name": "Sivas"
      },
      {
        "name": "Tekirdag"
      },
      {
        "name": "Tokat"
      },
      {
        "name": "Trabzon"
      },
      {
        "name": "Tunceli"
      },
      {
        "name": "Usak"
      },
      {
        "name": "Van"
      },
      {
        "name": "Yalova"
      },
      {
        "name": "Yozgat"
      },
      {
        "name": "Zonguldak"
      }
    ]
  },
  {
    "name": "Turkmenistan",
    "states": [
      {
        "name": "Ahal Welayaty (Ashgabat)"
      },
      {
        "name": "Balkan Welayaty (Balkanabat)"
      },
      {
        "name": "Dashoguz Welayaty"
      },
      {
        "name": "Lebap Welayaty (Turkmenabat)"
      },
      {
        "name": "Mary Welayaty"
      }
    ]
  },
  {
    "name": "Uganda",
    "states": [
      {
        "name": "Adjumani"
      },
      {
        "name": "Apac"
      },
      {
        "name": "Arua"
      },
      {
        "name": "Bugiri"
      },
      {
        "name": "Bundibugyo"
      },
      {
        "name": "Bushenyi"
      },
      {
        "name": "Busia"
      },
      {
        "name": "Gulu"
      },
      {
        "name": "Hoima"
      },
      {
        "name": "Iganga"
      },
      {
        "name": "Jinja"
      },
      {
        "name": "Kabale"
      },
      {
        "name": "Kabarole"
      },
      {
        "name": "Kaberamaido"
      },
      {
        "name": "Kalangala"
      },
      {
        "name": "Kampala"
      },
      {
        "name": "Kamuli"
      },
      {
        "name": "Kamwenge"
      },
      {
        "name": "Kanungu"
      },
      {
        "name": "Kapchorwa"
      },
      {
        "name": "Kasese"
      },
      {
        "name": "Katakwi"
      },
      {
        "name": "Kayunga"
      },
      {
        "name": "Kibale"
      },
      {
        "name": "Kiboga"
      },
      {
        "name": "Kisoro"
      },
      {
        "name": "Kitgum"
      },
      {
        "name": "Kotido"
      },
      {
        "name": "Kumi"
      },
      {
        "name": "Kyenjojo"
      },
      {
        "name": "Lira"
      },
      {
        "name": "Luwero"
      },
      {
        "name": "Masaka"
      },
      {
        "name": "Masindi"
      },
      {
        "name": "Mayuge"
      },
      {
        "name": "Mbale"
      },
      {
        "name": "Mbarara"
      },
      {
        "name": "Moroto"
      },
      {
        "name": "Moyo"
      },
      {
        "name": "Mpigi"
      },
      {
        "name": "Mubende"
      },
      {
        "name": "Mukono"
      },
      {
        "name": "Nakapiripirit"
      },
      {
        "name": "Nakasongola"
      },
      {
        "name": "Nebbi"
      },
      {
        "name": "Ntungamo"
      },
      {
        "name": "Pader"
      },
      {
        "name": "Pallisa"
      },
      {
        "name": "Rakai"
      },
      {
        "name": "Rukungiri"
      },
      {
        "name": "Sembabule"
      },
      {
        "name": "Sironko"
      },
      {
        "name": "Soroti"
      },
      {
        "name": "Tororo"
      },
      {
        "name": "Wakiso"
      },
      {
        "name": "Yumbe"
      }
    ]
  },
  {
    "name": "Ukraine",
    "states": [
      {
        "name": "Cherkasy"
      },
      {
        "name": "Chernihiv"
      },
      {
        "name": "Chernivtsi"
      },
      {
        "name": "Crimea"
      },
      {
        "name": "Dnipropetrovs'k"
      },
      {
        "name": "Donets'k"
      },
      {
        "name": "Ivano-Frankivs'k"
      },
      {
        "name": "Kharkiv"
      },
      {
        "name": "Kherson"
      },
      {
        "name": "Khmel'nyts'kyy"
      },
      {
        "name": "Kirovohrad"
      },
      {
        "name": "Kiev"
      },
      {
        "name": "Kyyiv"
      },
      {
        "name": "Luhans'k"
      },
      {
        "name": "L'viv"
      },
      {
        "name": "Mykolayiv"
      },
      {
        "name": "Odesa"
      },
      {
        "name": "Poltava"
      },
      {
        "name": "Rivne"
      },
      {
        "name": "Sevastopol'"
      },
      {
        "name": "Sumy"
      },
      {
        "name": "Ternopil'"
      },
      {
        "name": "Vinnytsya"
      },
      {
        "name": "Volyn'"
      },
      {
        "name": "Zakarpattya"
      },
      {
        "name": "Zaporizhzhya"
      },
      {
        "name": "Zhytomyr"
      }
    ]
  },
  {
    "name": "United Arab Emirates",
    "states": [
      {
        "name": "Abu Dhabi"
      },
      {
        "name": "'Ajman"
      },
      {
        "name": "Al Fujayrah"
      },
      {
        "name": "Sharjah"
      },
      {
        "name": "Dubai"
      },
      {
        "name": "Ra's al Khaymah"
      },
      {
        "name": "Umm al Qaywayn"
      }
    ]
  },
  {
    "name": "United Kingdom",
    "states": [
      {
        "name": "England"
      },
      {
        "name": "Northern Ireland"
      },
      {
        "name": "Scotland"
      },
      {
        "name": "Wales"
      }
    ]
  },
  {
    "name": "United States",
    "states": [
      {
        "name": "Alabama"
      },
      {
        "name": "Alaska"
      },
      {
        "name": "Arizona"
      },
      {
        "name": "Arkansas"
      },
      {
        "name": "California"
      },
      {
        "name": "Colorado"
      },
      {
        "name": "Connecticut"
      },
      {
        "name": "Delaware"
      },
      {
        "name": "District of Columbia"
      },
      {
        "name": "Florida"
      },
      {
        "name": "Georgia"
      },
      {
        "name": "Hawaii"
      },
      {
        "name": "Idaho"
      },
      {
        "name": "Illinois"
      },
      {
        "name": "Indiana"
      },
      {
        "name": "Iowa"
      },
      {
        "name": "Kansas"
      },
      {
        "name": "Kentucky"
      },
      {
        "name": "Louisiana"
      },
      {
        "name": "Maine"
      },
      {
        "name": "Maryland"
      },
      {
        "name": "Massachusetts"
      },
      {
        "name": "Michigan"
      },
      {
        "name": "Minnesota"
      },
      {
        "name": "Mississippi"
      },
      {
        "name": "Missouri"
      },
      {
        "name": "Montana"
      },
      {
        "name": "Nebraska"
      },
      {
        "name": "Nevada"
      },
      {
        "name": "New Hampshire"
      },
      {
        "name": "New Jersey"
      },
      {
        "name": "New Mexico"
      },
      {
        "name": "New York"
      },
      {
        "name": "North Carolina"
      },
      {
        "name": "North Dakota"
      },
      {
        "name": "Ohio"
      },
      {
        "name": "Oklahoma"
      },
      {
        "name": "Oregon"
      },
      {
        "name": "Pennsylvania"
      },
      {
        "name": "Puerto Rico"
      },
      {
        "name": "Rhode Island"
      },
      {
        "name": "South Carolina"
      },
      {
        "name": "South Dakota"
      },
      {
        "name": "Tennessee"
      },
      {
        "name": "Texas"
      },
      {
        "name": "Utah"
      },
      {
        "name": "Vermont"
      },
      {
        "name": "Virginia"
      },
      {
        "name": "Washington"
      },
      {
        "name": "West Virginia"
      },
      {
        "name": "Wisconsin"
      },
      {
        "name": "Wyoming"
      }
    ]
  },
  {
    "name": "Uruguay",
    "states": [
      {
        "name": "Artigas"
      },
      {
        "name": "Canelones"
      },
      {
        "name": "Cerro Largo"
      },
      {
        "name": "Colonia"
      },
      {
        "name": "Durazno"
      },
      {
        "name": "Flores"
      },
      {
        "name": "Florida"
      },
      {
        "name": "Lavalleja"
      },
      {
        "name": "Maldonado"
      },
      {
        "name": "Montevideo"
      },
      {
        "name": "Paysandu"
      },
      {
        "name": "Rio Negro"
      },
      {
        "name": "Rivera"
      },
      {
        "name": "Rocha"
      },
      {
        "name": "Salto"
      },
      {
        "name": "San Jose"
      },
      {
        "name": "Soriano"
      },
      {
        "name": "Tacuarembo"
      },
      {
        "name": "Treinta y Tres"
      }
    ]
  },
  {
    "name": "Uzbekistan",
    "states": [
      {
        "name": "Andijon Viloyati"
      },
      {
        "name": "Buxoro Viloyati"
      },
      {
        "name": "Farg'ona Viloyati"
      },
      {
        "name": "Jizzax Viloyati"
      },
      {
        "name": "Namangan Viloyati"
      },
      {
        "name": "Navoiy Viloyati"
      },
      {
        "name": "Qashqadaryo Viloyati"
      },
      {
        "name": "Qaraqalpog'iston Respublikasi"
      },
      {
        "name": "Samarqand Viloyati"
      },
      {
        "name": "Sirdaryo Viloyati"
      },
      {
        "name": "Surxondaryo Viloyati"
      },
      {
        "name": "Toshkent Shahri"
      },
      {
        "name": "Toshkent Viloyati"
      },
      {
        "name": "Xorazm Viloyati"
      }
    ]
  },
  {
    "name": "Vanuatu",
    "states": [
      {
        "name": "Malampa"
      },
      {
        "name": "Penama"
      },
      {
        "name": "Sanma"
      },
      {
        "name": "Shefa"
      },
      {
        "name": "Tafea"
      },
      {
        "name": "Torba"
      }
    ]
  },
  {
    "name": "Venezuela",
    "states": [
      {
        "name": "Amazonas"
      },
      {
        "name": "Anzoategui"
      },
      {
        "name": "Apure"
      },
      {
        "name": "Aragua"
      },
      {
        "name": "Barinas"
      },
      {
        "name": "Bolivar"
      },
      {
        "name": "Carabobo"
      },
      {
        "name": "Cojedes"
      },
      {
        "name": "Delta Amacuro"
      },
      {
        "name": "Dependencias Federales"
      },
      {
        "name": "Distrito Federal"
      },
      {
        "name": "Falcon"
      },
      {
        "name": "Guarico"
      },
      {
        "name": "Lara"
      },
      {
        "name": "Merida"
      },
      {
        "name": "Miranda"
      },
      {
        "name": "Monagas"
      },
      {
        "name": "Nueva Esparta"
      },
      {
        "name": "Portuguesa"
      },
      {
        "name": "Sucre"
      },
      {
        "name": "Tachira"
      },
      {
        "name": "Trujillo"
      },
      {
        "name": "Vargas"
      },
      {
        "name": "Yaracuy"
      },
      {
        "name": "Zulia"
      }
    ]
  },
  {
    "name": "Vietnam",
    "states": [
      {
        "name": "An Giang"
      },
      {
        "name": "Bac Giang"
      },
      {
        "name": "Bac Kan"
      },
      {
        "name": "Bac Lieu"
      },
      {
        "name": "Bac Ninh"
      },
      {
        "name": "Ba Ria-Vung Tau"
      },
      {
        "name": "Ben Tre"
      },
      {
        "name": "Binh Dinh"
      },
      {
        "name": "Binh Duong"
      },
      {
        "name": "Binh Phuoc"
      },
      {
        "name": "Binh Thuan"
      },
      {
        "name": "Ca Mau"
      },
      {
        "name": "Cao Bang"
      },
      {
        "name": "Dac Lak"
      },
      {
        "name": "Dac Nong"
      },
      {
        "name": "Dien Bien"
      },
      {
        "name": "Dong Nai"
      },
      {
        "name": "Dong Thap"
      },
      {
        "name": "Gia Lai"
      },
      {
        "name": "Ha Giang"
      },
      {
        "name": "Hai Duong"
      },
      {
        "name": "Ha Nam"
      },
      {
        "name": "Ha Tay"
      },
      {
        "name": "Ha Tinh"
      },
      {
        "name": "Hau Giang"
      },
      {
        "name": "Hoa Binh"
      },
      {
        "name": "Hung Yen"
      },
      {
        "name": "Khanh Hoa"
      },
      {
        "name": "Kien Giang"
      },
      {
        "name": "Kon Tum"
      },
      {
        "name": "Lai Chau"
      },
      {
        "name": "Lam Dong"
      },
      {
        "name": "Lang Son"
      },
      {
        "name": "Lao Cai"
      },
      {
        "name": "Long An"
      },
      {
        "name": "Nam Dinh"
      },
      {
        "name": "Nghe An"
      },
      {
        "name": "Ninh Binh"
      },
      {
        "name": "Ninh Thuan"
      },
      {
        "name": "Phu Tho"
      },
      {
        "name": "Phu Yen"
      },
      {
        "name": "Quang Binh"
      },
      {
        "name": "Quang Nam"
      },
      {
        "name": "Quang Ngai"
      },
      {
        "name": "Quang Ninh"
      },
      {
        "name": "Quang Tri"
      },
      {
        "name": "Soc Trang"
      },
      {
        "name": "Son La"
      },
      {
        "name": "Tay Ninh"
      },
      {
        "name": "Thai Binh"
      },
      {
        "name": "Thai Nguyen"
      },
      {
        "name": "Thanh Hoa"
      },
      {
        "name": "Thua Thien-Hue"
      },
      {
        "name": "Tien Giang"
      },
      {
        "name": "Tra Vinh"
      },
      {
        "name": "Tuyen Quang"
      },
      {
        "name": "Vinh Long"
      },
      {
        "name": "Vinh Phuc"
      },
      {
        "name": "Yen Bai"
      },
      {
        "name": "Can Tho"
      },
      {
        "name": "Da Nang"
      },
      {
        "name": "Hai Phong"
      },
      {
        "name": "Hanoi"
      },
      {
        "name": "Ho Chi Minh"
      }
    ]
  },
  {
    "name": "Yemen",
    "states": [
      {
        "name": "Abyan"
      },
      {
        "name": "'Adan"
      },
      {
        "name": "Ad Dali'"
      },
      {
        "name": "Al Bayda'"
      },
      {
        "name": "Al Hudaydah"
      },
      {
        "name": "Al Jawf"
      },
      {
        "name": "Al Mahrah"
      },
      {
        "name": "Al Mahwit"
      },
      {
        "name": "'Amran"
      },
      {
        "name": "Dhamar"
      },
      {
        "name": "Hadramawt"
      },
      {
        "name": "Hajjah"
      },
      {
        "name": "Ibb"
      },
      {
        "name": "Lahij"
      },
      {
        "name": "Ma'rib"
      },
      {
        "name": "Sa'dah"
      },
      {
        "name": "San'a'"
      },
      {
        "name": "Shabwah"
      },
      {
        "name": "Ta'izz"
      }
    ]
  },
  {
    "name": "Zambia",
    "states": [
      {
        "name": "Central"
      },
      {
        "name": "Copperbelt"
      },
      {
        "name": "Eastern"
      },
      {
        "name": "Luapula"
      },
      {
        "name": "Lusaka"
      },
      {
        "name": "Northern"
      },
      {
        "name": "North-Western"
      },
      {
        "name": "Southern"
      },
      {
        "name": "Western"
      }
    ]
  },
  {
    "name": "Zimbabwe",
    "states": [
      {
        "name": "Bulawayo"
      },
      {
        "name": "Harare"
      },
      {
        "name": "Manicaland"
      },
      {
        "name": "Mashonaland Central"
      },
      {
        "name": "Mashonaland East"
      },
      {
        "name": "Mashonaland West"
      },
      {
        "name": "Masvingo"
      },
      {
        "name": "Matabeleland North"
      },
      {
        "name": "Matabeleland South"
      },
      {
        "name": "Midlands"
      }
    ]
  }
]