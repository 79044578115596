import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {DocumentService} from "@app/core/services/backend/document.service";
import {LotService} from "@app/core/services/backend/lot.service";
import {WizardService} from "@app/core/services/backend/wizard.service";

@Component({
  selector: 'sa-lot-documents',
  templateUrl: './lot-documents.component.html',
  styleUrls: ['./lot-documents.component.css']
})
export class LotDocumentsComponent implements OnInit {

  public lotDocumentWizardInstanceID = '6c23b94a-9df3-4055-9b2f-577826a8e59e';
  public lotDocumentWizardInstance;
  public currentLotDetails;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<LotDocumentsComponent>, private documentService: DocumentService, private lotService: LotService, private wizardService: WizardService) {
    console.log('%c lot data in dialog: ', 'background: #41ff6b; color: #ff4700;', data);
  }

  ngOnInit() {
    this.wizardService.getWizardById(this.lotDocumentWizardInstanceID).subscribe(res => {
       this.lotDocumentWizardInstance = res;
       this.resetWizardInstanceFieldValues(this.lotDocumentWizardInstance);
    })
  }

  documentAction(event) {
    let payload = { DocumentName: '', DocumentType: '', ImageUrls: [], DocumentDatas: [] };

    payload.DocumentName = event['DocumentName.Measure'];
    payload.DocumentType = event['DocumentType.Measure'];
    payload.ImageUrls.push(event['UploadDocument.Measure']);

    Object.keys(event).forEach((key) => {
      if ((event[key] && event[key].length) || (event[key] === 0)) {
        let keys = key.split('.');
        let temp = {
          Name: keys[0],
          Unit: "",
          Measure: "",
          StartMeasureTime: "",
          EndMeasureTime: "",
          MeasureTime: ""
        };
        temp[keys[1]] = event[key];
        payload.DocumentDatas.push(temp);
      }
    });
    // switch('lot') {
    //   case 'lot':
        this.documentService.addLotDocument(this.data.lot.id, payload).subscribe((res) => {
          this.lotService.getLot(this.data.lot.id).subscribe(
              res => {
                // console.log('response: ', res);
                this.currentLotDetails = res;
                this.resetWizardInstanceFieldValues(this.lotDocumentWizardInstance);
                // this.dialogRef.close();
                return;
              },
              error => {
                console.log('ERROR resetting current lot details after document added ', error);
                return;
              },
              () => {
                // console.log('--- subscription complete ---');
                this.dialogRef.close();
              }
          );
        });
      //   break;
      // case 'node':
        // this.documentService.addNodeDocument(this.currentNodeDetails.id, payload).subscribe((res) => {
        //   this.nodeService.getNodeById(this.currentNodeDetails.id).subscribe(
        //       res => {
        //         this.currentNodeDetails = res;
        //         console.info('currentNodeDetails', this.currentNodeDetails);
        //         if (this.documentWizardMap[this.currentNodeDetails.NodeData.DefaultProductType]) {
        //           this.wizardService.getWizardById(this.documentWizardMap[this.currentNodeDetails.NodeData.DefaultProductType]).subscribe(res => {
        //             this.lotDocumentWizardInstance = res;
        //             console.log('this.lotDocumentWizardInstance: ', this.lotDocumentWizardInstance);
        //             this.resetWizardInstanceFieldValues(this.lotDocumentWizardInstance);
        //           });
        //         }
        //       },
        //       (error) => {
        //         console.log('%c ERROR getting node details: ', 'background: #ff0000; color: #ffffff;', error);
        //       },
        //       () => {
        //         this.lotDocumentsModal.hide();
        //         this.spinner.hide();
        //       });
        // });
        // break;
    // }
  }

  resetWizardInstanceFieldValues(wizardInstance) {
    wizardInstance.WizardFrontEnd.WizardStepDatas.forEach(step => {
      step.WizardStepFields.forEach(stepFields => {
        stepFields.Fields.forEach(field => {
          field.value = "";
        });
      });
    });
    wizardInstance.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep = 0;
    return wizardInstance;
  }

  closeDialog(){
    this.dialogRef.close();
  }  

}
