import { Observable } from 'rxjs/Observable';
import { from, throwError } from 'rxjs';
import 'rxjs/add/operator/switchMap';

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BextAuthenticationService } from './authentication.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class BextAuthenticationHttpInterceptor implements HttpInterceptor {

  constructor(private bextAuthenticationService: BextAuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.bextAuthenticationService.getAuthenticationToken())
      .switchMap(token => {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next.handle(req)
          // .pipe(
          //   map((event: HttpEvent<any>) => {
          //     if (event instanceof HttpResponse) {
          //       console.log('event ------>>> ', event);
          //     } else {
          //       console.log('event ------>>> ', event);
          //     }
          //     return event;
          //   })
          // );
        });
      }
  }
