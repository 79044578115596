import { Component, OnInit } from '@angular/core';
import {AddOrganizationComponent} from "@app/features/setup/organizations/add/add.component";
import {OrganizationService} from "@app/core/services/backend/organization.service";
import {Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {LocationService} from "@app/core/services/backend/locations.service";

@Component({
  selector: 'sa-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.css']
})
export class CreateOrganizationV2Component extends AddOrganizationComponent {

  constructor(public organizationService: OrganizationService, public router: Router, public fb: FormBuilder, public locationService: LocationService) {
    super(organizationService, router, fb, locationService)
  }

  ngOnInit() {
    console.log(this);
  }
}
