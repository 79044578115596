import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NodeCardListComponent } from './node-card-list.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NodeCardListComponent],
  exports: [NodeCardListComponent]
})
export class NodeCardListModule { }
