import { CommentsModule } from "./comments/comments.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTablesModule } from "@app/shared-v2/mat-tables/mat-tables.module";
import { NodeCardListModule } from "./node-card-list/node-card-list.module";
import { DataTabsModule } from "./data-tabs/data-tabs.module";
import { SupplyChainListModule } from "./supply-chain-list/supply-chain-list.module";
import { ScrollSectionModule } from "./scroll-section/scroll-section.module";
import { DynamicFormModule } from "./dynamic-form/dynamic-form.module";
import { LotTransfersModule } from "./lot-transfers/lot-transfers.module";
import { NgxFormModule } from "./ngx-form/ngx-form.module";
import { ThemeModule } from "@app/@theme/theme.module";
import { FilePreviewOverlayModule } from "./file-preview-overlay/file-preview-overlay.module";
import { LotSummaryModule } from "./lots/lot-summary/lot-summary.module";
import { EditAssetModule } from "./assets/edit-asset/edit-asset.module";
import { LotTokensModule } from "./lots/lot-tokens/lot-tokens.module";
import { PersonModule } from "./person/person.module";
import { ProductModule } from "./product/product.module";
import { LotDrilldownV2Module } from "./lots/lot-drilldown/lot-drilldown.module";
import { NodesSharedV2Module } from "./nodes/nodes-shared-v2.module";
import { SustainabilityDialogModule } from "@app/shared-v2/dialogs/sustainability/sustainability.module";
import { OrganizationsSharedV2Module } from "@app/shared-v2/organizations/organizations-shared-v2.module";
import { PipesV2Module } from "./pipes/pipes.module";
import { LoadersModule } from "./loaders/loaders.module";
import { ScanTransferQrLotComponent } from "./lots/scan-transfer-qr-lot/scan-transfer-qr-lot.component";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { PrintLotQrComponent } from "./lots/print-lot-qr/print-lot-qr.component";
import { MaterialModule } from "@app/shared/angular-material.module";
import { QRCodeModule } from "angularx-qrcode";
import { RetailReportFormattersModule } from "@app/shared-v2/retail-report-formatters/retail-report-formatters.module";
import { PageFormatterFormModule } from "@app/shared-v2/page-formatter/page-formatter.module";
import { LotDocumentsComponent } from "@app/shared-v2/lots/lot-documents/lot-documents.component";
import { SharedModule } from "@app/shared/shared.module";
import { LotImagesComponent } from "./lots/lot-images/lot-images.component";
import { PhotoGalleryModule } from "@app/shared-v2/photo-gallery/photo-gallery.module";
import { LotImagesModule } from "@app/shared-v2/lots/lot-images/lot-images.module";
import { BextArchwizardModule } from "@app/shared-v2/archwizard/archwizard.module";
import { LotDocumentsModule } from "./lots/lot-documents/lot-documents.module";
import { LotMarkerReportModule } from "./lots/lot-marker-report/lot-marker-report.module";
import { PrintLotQrModule } from "./lots/print-lot-qr/print-lot-qr.module";
import { ScanTransferQrLotModule } from "./lots/scan-transfer-qr-lot/scan-transfer-qr-lot.module";
import { BlockchainListModule } from "./lots/blockchain-list/blockchain-list.module";
import { BackgroundImageModule } from "./background-image/background-image.module";
import { GoogleMapModule } from "./google-map/google-map.module";
import { NgxJsonViewerModule } from "./ngx-json-viewer/ngx-json-viewer.module";
import { GlobesModule } from "./globes/globes.module";
import { DocumentsDialogModule } from "./dialogs/documents-dialog/documents-dialog.module";
import { ImagesDialogModule } from "./dialogs/images-dialog/images-dialog.module";
import { EditCopyNodeDialogModule } from "./dialogs/edit-copy-node-dialog/edit-copy-node-dialog.module";
import { DeleteSupplyChainDialogModule } from "./dialogs/delete-supply-chain-dialog/delete-supply-chain-dialog.module";
import { EditSupplyChainDialogModule } from "./dialogs/edit-supply-chain-dialog/edit-supply-chain-dialog.module";
import { DeleteUserDialogModule } from "./dialogs/delete-user-dialog/delete-user-dialog.module";
import { EditUserDialogModule } from "./dialogs/edit-user-dialog/edit-user-dialog.module";
import { UiMetadataCustomFieldDialogModule } from "./dialogs/ui-metadata-custom-field-dialog/ui-metadata-custom-field-dialog.module";
import { CustomDirectivesModule } from "./custom-directives/custom-directives.module";
import { ValuationDialogModule } from "./dialogs/valuation-dialog/valuation-dialog.module";
import { UtilComponentsModule } from "./util-components/util-components.module";
import { UploadUserImageDialogModule } from "./dialogs/upload-user-image-dialog/upload-user-image-dialog.module";
import { VideosDialogModule } from "./dialogs/videos-dialog/videos-dialog.module";
import { CreateRequisitionModule } from "./dialogs/create-requisition/create-requisition.module";
import { CustomFormDialogModule } from "./dialogs/custom-form-dialog/custom-form-dialog.module";
import { WidgetsGridModule } from "./widgets-grid/widgets-grid.module";
import { ReportListDialogModule } from "./dialogs/report-list-dialog/report-list-dialog.module";
import { LocationV2Module } from "./locationv2/locationv2.module";
import { LocationDialogModule } from "./dialogs/location-dialog/location-dialog.module";
import { PersonV2Module } from "./personv2/personv2.module";
import { PersonDialogModule } from "./dialogs/person-dialog/person-dialog.module";
import { QuicklinksModule } from "./quicklinks/quicklinks.module";

import { DeleteEntityDialogModule } from "./dialogs/delete-entity-dialog/delete-entity-dialog.module";

export const DialogModules = [
  SustainabilityDialogModule,
  DocumentsDialogModule,
  ImagesDialogModule,
  EditCopyNodeDialogModule,
  DeleteSupplyChainDialogModule,
  EditSupplyChainDialogModule,
  DeleteUserDialogModule,
  EditUserDialogModule,
  UiMetadataCustomFieldDialogModule,
  ValuationDialogModule,
  UploadUserImageDialogModule,
  VideosDialogModule,
  CreateRequisitionModule,
  CustomFormDialogModule,
  ReportListDialogModule,
  LocationDialogModule,
  PersonDialogModule,
  DeleteEntityDialogModule,
];

export const DirectivesModules = [CustomDirectivesModule];

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    ZXingScannerModule,
    MaterialModule,
    QRCodeModule,
    SharedModule,
    NgxJsonViewerModule,
  ],
  exports: [
    ...DialogModules,
    ...DirectivesModules,
    BextArchwizardModule,
    NodeCardListModule,
    DataTabsModule,
    SupplyChainListModule,
    ScrollSectionModule,
    DynamicFormModule,
    LotTransfersModule,
    FilePreviewOverlayModule,
    NgxFormModule,
    LotSummaryModule,
    EditAssetModule,
    LotTokensModule,
    LotDrilldownV2Module,
    PersonModule,
    ProductModule,
    NodesSharedV2Module,
    OrganizationsSharedV2Module,
    PipesV2Module,
    LoadersModule,
    RetailReportFormattersModule,
    PageFormatterFormModule,
    PhotoGalleryModule,
    LotImagesModule,
    LotDocumentsModule,
    LotMarkerReportModule,
    PrintLotQrModule,
    ScanTransferQrLotModule,
    BlockchainListModule,
    BackgroundImageModule,
    GoogleMapModule,
    NgxJsonViewerModule,
    GlobesModule,
    MatTablesModule,
    ValuationDialogModule,
    UtilComponentsModule,
    WidgetsGridModule,
    LocationV2Module,
    PersonV2Module,
    CommentsModule,
    QuicklinksModule,
  ],
})
export class SharedV2Module {}
