import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataTabsComponent } from "./data-tabs.component";
import { MaterialModule } from "@app/shared/angular-material.module";

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [DataTabsComponent],
  exports: [DataTabsComponent],
})
export class DataTabsModule {}
