import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { PageFormatterFormComponent } from '@app/shared-v2/page-formatter/page-formatter-form/page-formatter-form.component';
import { MatInputModule, MatFormFieldModule, MatButtonModule, MatExpansionModule, MatCardModule, MatRadioModule, MatSelectModule, MatIconModule } from '@angular/material';
import { PageFormatterFormFieldDirective } from '@app/shared-v2/page-formatter/page-formatter-field/page-formatter-form-field.directive';
import { MccColorPickerModule, MccColorPickerService } from 'material-community-components';
// import { PageFormmatterColorPickerComponent } from '@app/shared-v2/page-formatter/color-picker/color-picker.component';

export const PAGE_FORMATTER_COMPONENTS = [
  // PageFormmatterColorPickerComponent,
]

export const MATERIAL_MODULES = [
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatExpansionModule,
  MatCardModule,
  MatRadioModule,
  MatSelectModule,
  MatIconModule
]

export const ADDITIONAL_MODULES = [
  MccColorPickerModule
]

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...MATERIAL_MODULES
  ],
  declarations: [
    PageFormatterFormFieldDirective,
    PageFormatterFormComponent,
    ...PAGE_FORMATTER_COMPONENTS
  ],
  exports:[
    ...PAGE_FORMATTER_COMPONENTS
  ],
  providers: [
    MccColorPickerService
  ],
  entryComponents: [
    ...PAGE_FORMATTER_COMPONENTS
  ]
})

export class PageFormatterFormModule { }
