import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { LotService } from '@app/core/services/backend/lot.service';
import { lotSummaryDtoV2 } from '@app/shared-v2/models/lot-summary.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lot-summary-v2',
  templateUrl: './lot-summary.component.html',
  styleUrls: ['./lot-summary.component.scss']
})
export class LotSummaryV2Component {

  @Input() lotSummary: any;
  @Input() hideButtons = false;

  constructor(
    public dialogRef: MatDialogRef<LotSummaryV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnChanges() {
    this.data = this.lotSummary ? this.lotSummary : this.data;
  }

  closeDialog() {
    if (Object.keys(this.dialogRef).length) {
      this.dialogRef.close();
    }
  }
}
