import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { UploadCsvButtonComponent } from './upload-button/upload-button.component';
import { DownloadCsvButtonComponent } from './download-button/download-button.component';
import { PapaParseModule } from 'ngx-papaparse';
import { CSVFileService } from '@app/core/services/csv-file.service';
import { LocationService } from '@app/core/services/backend/locations.service';
import {MatButtonModule} from "@angular/material";

@NgModule({
  imports: [
    CommonModule,
    PapaParseModule,
    MatButtonModule
  ],
  declarations: [UploadCsvButtonComponent, DownloadCsvButtonComponent],
  exports: [UploadCsvButtonComponent, DownloadCsvButtonComponent],
  providers: [CSVFileService, LocationService]
})

export class CsvUploaderModule { }
