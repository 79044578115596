import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransferOneExistingManyExistingComponent } from './transfer-one-existing-many-existing.component';
import { MaterialModule } from '@app/shared/angular-material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [TransferOneExistingManyExistingComponent],
  exports: [TransferOneExistingManyExistingComponent]
})
export class TransferOneExistingManyExistingModule { }
