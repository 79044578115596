import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DocumentRoutes } from '@app/core/api-routes.enum';

@Injectable()
export class DocumentService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getDocuments() {
    return this.http.get(this.BASE_URL + DocumentRoutes.BASE_URL);
  }

  addLotDocument(lot_id, payload) {
    return this.http.post(this.BASE_URL + DocumentRoutes.BASE_URL + '/lot/' + lot_id, payload);
  }

  addNodeDocument(node_id, payload) {
    return this.http.post(this.BASE_URL + DocumentRoutes.BASE_URL + '/node/' + node_id, payload);
  }

  addOrganizationDocument(org_id, payload) {
    return this.http.post(this.BASE_URL + DocumentRoutes.BASE_URL + '/organization/' + org_id, payload);
  }

  getDocument(doc_id) {
    return this.http.get(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + doc_id);
  }

  getDocumentTypes(entity_type, id) {
    return this.http.get(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + entity_type + '/' + id + '/list');
  }

  getDocumentsV2(doc_id, type) {
    return this.http.get(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + type + '/' + doc_id);
  }

  getDocumentTemplate(type) {
    return this.http.get(this.BASE_URL + DocumentRoutes.TEMPLATES_URL + '/' + type);
  }

  addTemplate(payload) {
    return this.http.post(this.BASE_URL + DocumentRoutes.TEMPLATES_URL, payload);
  }

  editDocument(doc) {
    return this.http.put(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + doc.id, doc)
  }

  deleteDocument(doc_id) {
    return this.http.delete(this.BASE_URL + DocumentRoutes.BASE_URL + '/' + doc_id)
  }
}