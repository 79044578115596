import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EditAssetComponent } from "./edit-asset.component";
import { MaterialModule } from "@app/shared/angular-material.module";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { ListWithAvatarsComponent } from './list-with-avatars.component';
// import { MatListModule, MatIconModule, MatDividerModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [EditAssetComponent],
  entryComponents: [EditAssetComponent],
  providers: [],
  exports: [EditAssetComponent],
})
export class EditAssetModule {}
