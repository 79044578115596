

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CustomDualListComponent } from './custom-dual-list.component';
import { AngularDualListBoxModule } from "angular-dual-listbox";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [CommonModule, AngularDualListBoxModule, FormsModule],
  declarations: [
    CustomDualListComponent
  ],
  exports: [
    CustomDualListComponent
  ]

})
export class CustomDualListModule{}
