import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'location-list-v2',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListV2Component implements OnInit {
  @Input() locations:any = []; //todo: change type impl

  constructor(private matDialogRef: MatDialogRef<LocationListV2Component>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
      this.locations = this.locations && this.locations.length ? this.locations : this.data && this.data.locations && this.data.locations.length ? this.data.locations : [];
      console.log(this.locations);
  }

}
