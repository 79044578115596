const DEFAULT_WIZARD_NODE_ID = '69959f5b-aec7-48f0-b1a9-7b24d958554d'

import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QuickLinksRoutes } from '@app/core-v2/api-routes-v2.enum';
import { Observable, of } from 'rxjs';

@Injectable()
export class QuickLinksService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  // GET api/valuation/nodevaluationdata/{nodeId}
  getWizard(node_id: string = DEFAULT_WIZARD_NODE_ID): Observable<any> {
    return this.http.get(this.BASE_URL + QuickLinksRoutes.BASE_URL + QuickLinksRoutes.CREATE_LOT_URL + '/' + node_id);
  }

  customFormMethodHandler(method: string, endpoint: string): Observable<any> {
    switch (method) {
      case 'GET':
        return this.http.get(this.BASE_URL + endpoint);
    
      default:
        break;
    }
    return of(null)
  }

  getQuickLinks(entityType, entityId, orgId, menuName): Observable<any> {
    return this.http.get(this.BASE_URL + QuickLinksRoutes.MENU_URL + `?entity=${entityType}&id=${entityId}&org=${orgId}&menuname=${menuName}`)
  }

}