import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundDigitToDecimalDirective } from './decimal-places.directive';
import { EditModeDirective } from './edit-mode.directive';
import { EditOnEnterDirective } from './edit-on-enter.directive';
import { FocusableDirective } from './focusable.directive';
import { ViewModeDirective } from './view-mode.directive';
import { FontAwesomeIconMatStyleDirective } from './font-icon-mat-style.directive';
import { VideoMetaDataDirective } from './video-metadata.directive';

@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
      RoundDigitToDecimalDirective,
      EditModeDirective,
      EditOnEnterDirective,
      FocusableDirective,
      ViewModeDirective,
      FontAwesomeIconMatStyleDirective,
      VideoMetaDataDirective
   ],
   exports: [
      RoundDigitToDecimalDirective,
      EditModeDirective,
      EditOnEnterDirective,
      FocusableDirective,
      ViewModeDirective,
      FontAwesomeIconMatStyleDirective,
      VideoMetaDataDirective
   ]
})
export class CustomDirectivesModule { }
