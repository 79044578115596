import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Observable, merge, from } from 'rxjs';
import { delay, mapTo, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ReportRoutes } from '../api-routes.enum';
import { environment } from '../../../environments/environment';



@Injectable()
export class CapsulesService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) {}

  getCapsules(): Observable<any> {
    return this.http.get<any>(this.BASE_URL + ReportRoutes.BASE_URL + ReportRoutes.NESPRESSO_CAPSULE_REPORT_URL)
  }

}
