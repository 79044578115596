import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UiDataRoutes } from '@app/core/api-routes.enum';

@Injectable()
export class UiDataService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getUiData(type: string): Observable<any> {
    return this.http.get<any>(this.BASE_URL + UiDataRoutes.BASE_URL + '/' + type);
  }

}