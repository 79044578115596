import { NgxFormService } from '@app/core-v2/services/ngx-form.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FieldConfig } from '../../../models/field-config.interface';
import { Component, OnInit } from '@angular/core';
import { tap, takeWhile } from 'rxjs/operators';
import { FilePreviewOverlayService } from '@app/shared-v2/file-preview-overlay/file-preview-overlay.service';
import { FilePreviewOverlayRef } from '@app/shared-v2/file-preview-overlay/file-preview-overlay-ref';
import { generateRandomNumber } from '@app/shared/utils/number-utils';
import { UploadService } from '@app/core/services/backend/uploads.service';
import { interval, noop } from 'rxjs';

@Component({
  selector: 'bext-ngx-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class NgxFileUploadComponent implements OnInit {

  files: any[] = [];
  editFileForm: FormGroup;
  field: FieldConfig;
  group: FormGroup;

  deletingImage = false;
  uploadingImage = false;
  progress$ = interval(500);
  progress = 0;
  deleteSpinnerStyleObject = {
    'height': '12px',
    'width': '12px',
    'margin': 'auto',
    'border': '4px rgba(0, 255, 255, 0.25) solid',
    'border-top': '4px red solid'
  }
  
  constructor(private uploadService: UploadService, private previewDialog: FilePreviewOverlayService, private ngxFormService: NgxFormService) {}
  ngOnInit() { }

  onFileSelected(event) {
    this.uploadingImage = true;
    this.uploadProgress();
    let fd = new FormData();
    [<File>event.target.files[0]].forEach(document => {
      fd.append('files', document, document.name);
    })
    
    // ----- RESET FILE SELECTION BEHAVIOR -----
    event.target.value = '';

    this.uploadService.upload(fd).subscribe(
      res => {
        this.files.length = 0;
        this.files.push(res);
        this.ngxFormService.tempFileUploadFields = {fieldName: this.field.name, url: res.ImageURL}; 
        this.uploadingImage = false
        return;
      },
      error => this.uploadingImage = false
    );
  }

  deleteFile(file, index) {
    this.deletingImage = true;
    this.deleteProgress();
    this.group.controls[this.field.name].setValue(null);

    this.uploadService.deleteImage(file.id)
      .pipe(
        tap((res) => {
          this.ngxFormService.tempFileUploadFields = {fieldName: this.field.name, url: null};
          this.files.splice(index, 1);
          this.progress = 100;
        })
      ).subscribe(
        res => this.deletingImage = false,
        error => this.deletingImage = false
      )
  }

  showPreview(file, _index = generateRandomNumber(0, 4)) {
    let formattedImageObject = {
      name: file.ImageName,
      url: file.ImageURL
    };
    let dialogRef: FilePreviewOverlayRef = this.previewDialog.open({
      image: formattedImageObject
    });
  }

  deleteProgress() {
    this.progress$
      .pipe(
        takeWhile(() => this.deletingImage),
        tap((val) => {
          // console.log('delete progress interval value: ', val);
          this.progress = (1 - (1/(val + 1))) * 100;
          // console.log('delete progress: ', this.progress);
        })
      )
      .subscribe(
        noop,
        error => this.progress = 0,
        () => this.progress = 0
      );
  }

  uploadProgress() {
    this.progress$
      .pipe(
        takeWhile(() => this.uploadingImage),
        tap((val) => {
          // console.log('upload progress interval value: ', val);
          this.progress = (1 - (1/(val + 1))) * 100;
          // console.log('upload progress: ', this.progress);
        })
      )
      .subscribe(
        noop,
        error => this.progress = 0,
        () => this.progress = 0
      );
  }

}
