import { UploadService } from '@app/core/services/backend/uploads.service';
import { NgModule } from "@angular/core";
import { ToggleActiveDirective } from "./toggle-active.directive";
import { ImageRotationDirective } from './image-rotation.directive';

@NgModule({
   declarations: [
      ToggleActiveDirective,
      ImageRotationDirective
   ],
   exports: [
      ToggleActiveDirective,
      ImageRotationDirective
   ]
})
export class UtilsModule {}
