import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DndModule } from "ngx-drag-drop";
import { BextArchwizardComponent } from "./archwizard.component";
import { BextArchwizardBuilderComponent } from "./archwizard-builder/archwizard-builder.component";
import { ArchwizardModule } from "angular-archwizard";
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule,
  MatRadioModule,
  MatIconModule,
  MatButtonToggleModule,
  MatTooltipModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatBadgeModule,
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxJsonViewerModule } from "../ngx-json-viewer/ngx-json-viewer.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PhotoGalleryModule } from "../photo-gallery/photo-gallery.module";
import { CommentsModule } from "../comments/comments.module";
import { PipesV2Module } from "../pipes/pipes.module";
import { UtilComponentsModule } from "@app/shared-v2/util-components/util-components.module";

@NgModule({
  imports: [
    CommonModule,
    PipesV2Module,
    FormsModule,
    ReactiveFormsModule,
    ArchwizardModule,
    DragDropModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    MatTooltipModule,
    MatChipsModule,
    MatBadgeModule,
    DndModule,
    PhotoGalleryModule,
    NgxJsonViewerModule,
    CommentsModule,
    UtilComponentsModule,
  ],
  declarations: [BextArchwizardComponent, BextArchwizardBuilderComponent],
  exports: [BextArchwizardComponent, BextArchwizardBuilderComponent],
})
export class BextArchwizardModule {}
