import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../../../models/field-config.interface';
import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnInit, ViewContainerRef } from "@angular/core";
import { NgxInputComponent } from "../input/input.component";
import { NgxButtonComponent } from "../button/button.component";
import { NgxSelectComponent } from "../select/select.component";
import { NgxDateComponent } from "../date/date.component";
import { NgxRadiobuttonComponent } from "../radiobutton/radiobutton.component";
import { NgxCheckboxComponent } from "../checkbox/checkbox.component";
import { NgxFileUploadComponent } from '../file-upload/file-upload.component';
import { NgxDateTimeLocalComponent } from '../datetime-local/datetime-local.component';

const componentMapper = {
  "button": NgxButtonComponent,
  "checkbox": NgxCheckboxComponent,
  "date": NgxDateComponent,
  "datetime-local": NgxDateTimeLocalComponent,
  "input": NgxInputComponent,
  "radiobutton": NgxRadiobuttonComponent,
  "select": NgxSelectComponent,
  "fileupload": NgxFileUploadComponent
};

@Directive({
  selector: '[ngxFormField]'
})

export class NgxFormFieldDirective {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  componentRef: any;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }
  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.type]
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
  }
}
