import { Component, Inject, OnInit } from '@angular/core';
import { MarkerService } from "@app/core/services/backend/marker.service";
import { LotService } from "@app/core/services/backend/lot.service";
import { NodeService } from "@app/core/services/backend/node.service";
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from "@angular/material";
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'sa-lot-marker-report',
  templateUrl: './lot-marker-report.component.html',
  styleUrls: ['./lot-marker-report.component.scss']
})
export class LotMarkerReportComponent implements OnInit {
  public currentMarkers: any = [];
  // public previousMarkers: any = [];
  // public currentMarkerGroups = [];
  // public previousMarkerGroups = [];
  public error;
  private alive = true;
  Company: any;
  public displayedColumns: string[] = [];
  public displayedVerifiedColumns: string[] = [];
  // index = [];
  // dataSource: MatTableDataSource<any[]>;

  constructor(
    private markerService: MarkerService,
    private lotService: LotService,
    private nodeService: NodeService,
    @Inject(MAT_DIALOG_DATA) public data,
    public matDialogRef: MatDialogRef<LotMarkerReportComponent>) { }

  ngOnInit() {
    this.displayedColumns = [
      'MarkerAddedType',
      'MarkerAddedCode',
      'MarkerAddedName',
      'MarkerAddedLotName',
      'MarkerAddedNodeName',
      'DateMarkerAdded'
    ]
    this.displayedVerifiedColumns = [
      'Passed',
      'MarkerAddedType',
      'MarkerAddedCode',
      'MarkerAddedName',
      'DateVerified',
      'MarkerVerifiedLotName',
      'MarkerVerifiedNodeName', 
      'DateMarkerAdded'
      
      // 'MarkerVerifiedLotId',
      // 'MarkerVerifiedNodeId',
      // 'MarkerCompanyName',
    ];
      this.markerService.getLotMarkersVerification(this.data.lotID)
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        (res: any[]) => {
          this.currentMarkers = res;

          this.currentMarkers.forEach(marker => {
            marker.VerifiedMarkersDtos.forEach(m => {
              m['MarkerCompanyName'] = marker.MarkerCompanyName;
              m['MarkerAddedName'] = marker.MarkerAddedName;
              m['MarkerAddedCode'] = marker.MarkerAddedCode;
              m['MarkerAddedType'] = marker.MarkerAddedType;
              m['DateMarkerAdded'] = marker.DateMarkerAdded;
            });

            marker['PreviouslyAddedMarkers'] = [
              {
                'MarkerAddedType': marker['MarkerAddedType'],
                'MarkerAddedName': marker['MarkerAddedName'],
                'MarkerAddedCode': marker['MarkerAddedCode'],
                'DateMarkerAdded': marker['DateMarkerAdded'],
                'MarkerAddedNodeName': marker['MarkerAddedNodeName'],
                'MarkerAddedLotName': marker['MarkerAddedLotName'],
              }
            ]
          });
          //   this.nodeService.getNodeAndOrgName(marker.MarkerAddedNodeId).subscribe(res => {
          //     marker.MarkerAddedNodeName = res.NodeName;
          //   });
          //   let group = this.currentMarkerGroups.find(group => group.CompanyName == marker.MarkerCompanyName);
          //   if (group) {
          //     group.Markers.push(marker)
          //   } else {
          //     this.currentMarkerGroups.push({CompanyName: marker.MarkerCompanyName, Markers: [marker]});
          //   }
          //   // console.log('currentMarkerGroups: ', this.currentMarkerGroups);
          // });
          // this.previousMarkers = res;
          // console.log('previous markers working: ', this.previousMarkers);

          // this.previousMarkers.forEach(marker => {
          //   let i: string | number;
          //   this.nodeService.getNodeAndOrgName(marker.VerifiedMarkerDtos[i].MarkerVerifiedNodeId).subscribe(res => {
          //     marker.VerifiedMarkerDtos[i].MarkerVerifiedNodeName = res.NodeName;
          //   });
          //   let group = this.currentMarkerGroups.find(group => group.CompanyName == marker.MarkerCompanyName);
          //   if (group) {
          //     group.Markers.push(marker);

          //   } else {
          //     this.previousMarkerGroups.push({CompanyName: marker.MarkerCompanyName, Markers: [marker]});
          //   }
          // });
          console.log('currentMarkers AND verifiedMarkersDtos: ', this.currentMarkers);

          // console.log('currentMarkerGroups: ', this.currentMarkerGroups);
      },
      error => {
        this.error = error;
      }
      )
    }


  //   this.markerService.getLotMarkersVerification(this.data.lotID)
  //     .pipe(
  //       takeWhile(() => this.alive)
  //     )
  //     .subscribe(
  //       res => {
  //         this.currentMarkers = res;
  //         this.dataSource = new MatTableDataSource(this.currentMarkers);
  //         console.log('new ENDPOINT MARKERS: ', res);
  //       }),
  //     error => {
  //       this.error = error;
  //     }
  // }




  closeDialog() {
    this.matDialogRef.close();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
