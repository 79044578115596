import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoLoaderComponent } from './logo-loader/logo-loader.component';
import { SmallSpinnerLoaderComponent } from './sm-spin-loader/sm-spin-loader.component';
import { CircleCheckComponent } from './circle-check/circle-check.component';

const CustomLoaders = [
  LogoLoaderComponent,
  SmallSpinnerLoaderComponent,
  CircleCheckComponent
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...CustomLoaders
  ],
  exports: [
    ...CustomLoaders
  ]
})
export class LoadersModule {}
