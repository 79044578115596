import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { takeWhile, tap } from 'rxjs/operators';
import { NgxFormService } from '@app/core-v2/services/ngx-form.service';
import { PageFormatterService } from '@app/core-v2/services';

@Component({
  exportAs: 'pageFormatterForm',
  selector: 'bext-page-formatter-form',
  templateUrl: './page-formatter-form.component.html',
  styleUrls: ['./page-formatter-form.component.scss']
})
export class PageFormatterFormComponent implements OnInit {

  private alive = true;

  isDevEnv: boolean = environment.release_package.local;

  @Input() config;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  constructor(private pageFormatterService: PageFormatterService) { }

  ngOnInit() {

    if (Array.isArray(this.config)) {
      this.form = this.pageFormatterService.createForm(this.config);
    } else if (typeof this.config === 'object') {
      this.form = this.pageFormatterService.createControlFromFormattedUiData(this.config);
    } else {
      // add logic if form config is not provided
    }


    this.pageFormatterService.formValueChanges.pipe(
      takeWhile(() => this.alive),
      tap((changes) => changes)
    ).subscribe();

  } 

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.form.valid) {
      let payload = this.form.value;
      console.log('page formatter form submit: ', payload);
      this.submit.emit(payload);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.controls[field];
      control.markAsTouched({ onlySelf: true });
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
