import { Component, OnInit } from '@angular/core';
import {LayoutService} from "@app/core/services/layout.service";

@Component({
  selector: 'sa-ribbon',
  styles: [`
      #ribbon {
        max-width: 1665px;
      } 
  `],
  templateUrl: './ribbon.component.html'
})
export class RibbonComponent implements OnInit {

  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
  }

  getLeft () {

    var width = document.getElementsByTagName('html')[0].offsetWidth
    var width1 = document.getElementsByTagName('body')[0].offsetWidth

    return ((width - width1) / 2) + 'px';

  }

  resetWidgets() {
    this.layoutService.factoryReset()
  }

}
