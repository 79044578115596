import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';



const url = 'https://maps.googleapis.com/maps/api/js?key=' + environment.GOOGLE_API_KEY + '&libraries=places,visualization&callback=__onGoogleLoaded'


@Injectable()
export class GoogleMapService {

  public loadAPI: Promise<any>;

  constructor(private http: HttpClient) {
    if (window['google']) {
      this.loadAPI = Promise.resolve(window['google'])
    } else {
      this.loadAPI = new Promise((resolve) => {
        window['__onGoogleLoaded'] = (ev) => {
          resolve(window['google']);
        };
        this.loadScript()
      })
    }
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  getCoordinatesByCity(city): Observable<any> {
    return this.http.get<any>(`https://nominatim.openstreetmap.org/search?q=${city}&format=json`);
  }
}