import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { WizardRoutes } from '@app/core/api-routes.enum';

@Injectable()
export class WizardService {

    BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }


  getWizards() :Observable<any> {
    return this.http.get(this.BASE_URL + WizardRoutes.BASE_URL);
  }

  addWizard(payload):Observable<any> {
      return this.http.post(this.BASE_URL + WizardRoutes.BASE_URL, payload);
  }

  getWizardById(id):Observable<any> {
      return this.http.get(this.BASE_URL + WizardRoutes.BASE_URL + '/' + id);
  }

  updateWizardInstance(instanceID, payload): Observable<any>{
      console.log('%c wizard instance payload: ', 'background: #ff00ff; color: #ffffff;', payload);
      return this.http.put(this.BASE_URL + WizardRoutes.BASE_URL + '/' + instanceID, payload);
  }

  createWizardInstance(id) {
    return this.http.get(this.BASE_URL + '/wizard/' + id + '/createwizardinstance');
  }

  getWizardInstance(id) {
    return this.http.get(this.BASE_URL + '/wizardinstance/' + id);
  }

  // sendAction(instanceID, action, payload): Observable<any> {
  //   return this.http.put
  // }
}