import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mini-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input('spinnerType') spinnerType;
  // Options for spinnerType:
  // - 'circle'
  // - '3 balls'
  // - 'volume'
  // - 'vortex'
  // - 'slices'
  // - 'sphere'
  // - 'bars'
  // - 'clock'
  // - 'wave'
  // - 'texture'
  // - 'loadbar'
  // - 'hydrogen'

  @Input('spinnerText') spinnerText;
  @Input('spinnerColor') spinnerColor = undefined;
  @Input('excludeSpinnerText') excludeSpinnerText = false;
  @Input('disableDefaultStyling') disableDefaultStyling = false;
  @Input('size') spinnerSize = undefined;

  constructor() { }

  ngOnInit() {
  }

  styleSpinner(): Object {
    console.log('typeof spinnerSize: ', typeof this.spinnerSize);
    if (typeof this.spinnerSize === 'string') {
      return {height: this.spinnerSize + 'px', width: this.spinnerSize + 'px', margin: 'auto'};
    }
    else if (typeof this.spinnerSize === 'object') {
      return this.spinnerSize;
    }
    return {};
  }

}
