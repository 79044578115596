import { Component, OnInit } from '@angular/core';
import {OrganizationFormComponent} from "@app/shared/organization/form/form.component";
import {ControlContainer} from "@angular/forms";

@Component({
  selector: 'sa-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class OrganizationFormV2Component extends OrganizationFormComponent {

  constructor(public controlContainer: ControlContainer) {
    super(controlContainer)
  }

}
