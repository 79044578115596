import { environment } from "@env/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { PersonsRoutes } from "@app/core-v2/api-routes-v2.enum";
import { IPersonSummaryDto } from "@app/shared-v2/models/person/person.interface";
import { IPersonGroupDto } from "@app/shared-v2/models/person/personGroup.interface";
import { IPersonTypeDto } from "@app/shared-v2/models/person/personType.interface";
import { IPersonDetailDto } from "@app/shared-v2/models/person/person-detail.interface";

@Injectable()
export class PersonService {
  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) {}

  //New Routes for Dtos
  getPersonSummaryDtos(): Observable<IPersonSummaryDto[]> {
    return this.http.get<IPersonSummaryDto[]>(
      this.BASE_URL + PersonsRoutes.BASE_URL + PersonsRoutes.PERSON_LIST_SUMMARY_DTOS
    );
  }

  getPersonTypeDtos(): Observable<IPersonTypeDto[]> {
    return this.http.get<IPersonTypeDto[]>(
      this.BASE_URL + PersonsRoutes.BASE_URL + PersonsRoutes.PERSONTYPEDTOS
    );
  }

  getPersonGroupDtos(): Observable<IPersonGroupDto[]> {
    return this.http.get<IPersonGroupDto[]>(
      this.BASE_URL + PersonsRoutes.BASE_URL + PersonsRoutes.PERSONGROUPDTOS
    );
  }

  getPersonDetailById(personId: string, personOrganizationId: string): Observable<IPersonDetailDto> {
    let params = new HttpParams();
    params = params.append('_personId', personId);
    params = params.append('_personOrganizationId', personOrganizationId);
    return this.http.get<IPersonDetailDto>(
      this.BASE_URL + PersonsRoutes.BASE_URL + PersonsRoutes.PERSON_DETAIL_DTO, {params:params}
    );
  }


  addPerson(person): Observable<any> {
    return this.http.post<any>(this.BASE_URL + PersonsRoutes.BASE_URL + "/add", person);
  }

  deletePerson(person: any): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + PersonsRoutes.BASE_URL + '/' + person.id)
  }

  editPerson(personDetailDto): Observable<any> {
    return this.http.put<any>(this.BASE_URL + PersonsRoutes.BASE_URL + '/update/' + personDetailDto.Id, personDetailDto)
  }

}
