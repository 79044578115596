import { Component, OnInit, Inject, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { ValuationService } from '@app/core-v2/services/valuation.service';
import { ValuationHelperService } from '@app/core-v2/services/valuation-helper.service';
import { FormControl, Validators, FormGroup, FormArray, AbstractControl, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { takeWhile, skip } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bext-valuation-dialog',
  templateUrl: './valuation-dialog.component.html',
  styleUrls: ['./valuation-dialog.component.scss']
})
export class ValuationDialogComponent implements OnInit {

  private alive = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  node: any;
  node_id: string;
  nodes;
  valuationName;
  core: any;
  toGroups: any;
  value: any;
  isLoading = false;
  dataSource = this.valuationHelperService.valuation.value.list;

  ValueUnits = [
    { label: 'USD', value: 'USD' },
    { label: 'EUR', value: 'EUR' },
    { label: 'GBP', value: 'GBP' },
    { label: 'INR', value: 'INR' },
    { label: 'XAF', value: 'XAF' }
  ]

  WeightUnits = [
    { label: 'KG', value: 'KG' },
    { label: 'LB', value: 'LB' }
  ]

  displayedColumns = [
    'TokenName',
    'TokenQuality',
    'ValuePerWeightUnit',
    'ValueUnit',
    'WeightUnit',
    // 'CurrencyUnit',
    'TokensPerWeightUnit',
  ];

  private _controls: FormArray;
  get controls() {
    return this._controls;
  }
  set controls(ctrls: any) {
    this._controls = ctrls
  }


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ValuationDialogComponent>,
    private valuationService: ValuationService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private valuationHelperService: ValuationHelperService

  ) {
    console.log('Valuation Dialog - data: ', this.data);

  }

  ngOnInit() {
    this.isLoading = true;

    this.valuationHelperService.valuationGet(this.data)
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        res => {
          let list = res[0].ProductTokenValues;
          let valuationName = res[0].Name;
          let originalValuationGet = res;

          this.valuationName = valuationName;
          this.valuationHelperService.updateValuationState(list, valuationName, originalValuationGet)
        }
      )

    this.valuationHelperService.valuation$
      .pipe(
        takeWhile(() => this.alive),
        skip(1)
      )
      .subscribe(
        res => {
          if (!this.toGroups) {
            this.createFormGroups(res.list)
          }
          this.dataSource = [...res.list];
          console.log('%c valuation data: ', 'background: #fae552; color: #323232;', this.valuationHelperService.valuationData);
          // console.log('FORM FIELDS: ', this.toGroups);
          // console.log('CONTROLS: ', this.controls);
        }
      )
  }

  createFormGroups(dataColumnList) {
    this.toGroups = dataColumnList.map(p => {
      return new FormGroup({
        // TokenName: new FormControl(p.TokenName, Validators.required),
        // TokenQuality: new FormControl(p.TokenQuality, Validators.required),
        ValuePerWeightUnit: new FormControl(p.ValuePerWeightUnit, Validators.required),
        ValueUnit: new FormControl(p.ValueUnit, Validators.required),
        WeightUnit: new FormControl(p.WeightUnit, Validators.required),
        // CurrencyUnit: new FormControl(p.CurrencyUnit, Validators.required),
        // TokensPerWeightUnit: new FormControl(p.TokensPerWeightUnit, Validators.required),
      }, { updateOn: "blur" });
    });
    this.isLoading = false;
    this.controls = new FormArray(this.toGroups) as FormArray;
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      this.valuationHelperService.updateValuationData(index, field, control.value);
    }
  }

  getControl(index, fieldName) {
    const ctrl = this.controls.at(index).get(fieldName) as FormControl;
    return ctrl;
  }

  editData() {
    console.log('DATA FOR VALUATION PUT: ', this.data);
    // console.log(this.valuationHelperService.list$.value);
    this.valuationService.editValuation(
        this.data.id,
        this.valuationHelperService.valuationData[0]
      ).subscribe(res => {
      console.log(res);
      this.dialogRef.close();
    });
  }

  // Is this needed?
  ngAfterViewChecked(){
    this.cdr.detectChanges();
 }

  closeDialog(results?) {
    this.dialogRef.close(results);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }



}


