import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-empty-layout',
  templateUrl: './empty-layout.component.html',
  styles: [
    `

    body,
    html {

    }

    body.menu-on-top.fixed-header {
      padding: 0;
    }

    .nb-theme-corporate nb-layout ::-webkit-scrollbar {
      width: 5px !important;
      height: 10px !important;
    }

    .nb-theme-corporate nb-layout ::-webkit-scrollbar-thumb {
      background: #0053b2 !important;
      border-radius: 20px !important;
    }

    .nb-theme-corporate nb-layout ::-webkit-scrollbar-track {
      background: #e3e9ee !important;
    }

    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class EmptyLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
