import { Component, OnInit } from '@angular/core';
import { SustainabilityService } from '@app/core/services/backend/sustainability.service';
import { NodeService } from '@app/core/services/backend/node.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'sus-group-builder',
  templateUrl: './sus-group-builder.component.html',
  styleUrls: ['./sus-group-builder.component.css']
})
export class SusGroupBuilderComponent implements OnInit {
  nodes;
  products;
  productType;
  id;
  groupName;
  subGroupName;
  groupLevel = 1;
  subGroupLevel = 2;
  sets = [];
  groupDescription;

  baseGroup = {
    name: '',
    description: '',
    subGroups: [],
    id: '',
    level: 1
  }

  addedGroupId = '';
  constructor(private susService: SustainabilityService, private nodeService: NodeService) { }


  ngOnInit() {
    const compareFn = (a, b) => {
      if (a.Product > b.Product) {
        return 1;
      }
      if (a.Product < b.Product) {
        return -1;
      }
      return 0;
    };
    this.nodeService.getNodeProductTypes()
      .pipe(
        map(product => product['Products'].sort(compareFn))
      ).subscribe(
        res => {
          this.products = res;
          console.log(this.products);
        },
        error => console.log('error GET products failed: ', error),
        () => {

        }
      );
  }

  addGroup() {
    this.baseGroup.subGroups.push({
      name: '',
      description: '',
      level: '',
      subGroups: []
    })
  }

  testPostGroup() {
    let testPayload = new SustainabilityGroup(this.baseGroup.name, this.baseGroup.description, undefined, [{
      "ProductName": "All"
    }])
    console.log(testPayload);
    this.susService.addGroup(testPayload).subscribe(res => {
      console.log(res);
      this.baseGroup.id = res.id;
    })
  }

  testPostLevel() {

  }

  addSpecification() {
    this.baseGroup.subGroups.push({
      name: '',
      level: '',
      description: '',
      specification: true
    });
  }

  saveBaseGroup() {
    let testPayload = new SustainabilityGroup(this.baseGroup.name, this.baseGroup.description, undefined, [{
      "ProductName": "All"
    }])
    this.susService.addGroup(testPayload).subscribe(res => {
      console.log(res);
      this.id = res.id;
      this.baseGroup.level = 1;
    })
  }
}

export class SustainabilityGroup {
  public name;
  public level;
  public description;
  public products;
  constructor(name: string, description: string, level: string, products?: Array<any>) {
    this.name = name;
    this.level = level;
    this.description = description;
    this.products = products || ['All'];
  }

}
