import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Validators, ControlContainer, FormControl } from '@angular/forms';
import { PersonsService } from '@app/core/services/backend/persons.service';
import { FormBuilder } from '@angular/forms';
import { LocationService } from '@app/core/services/backend/locations.service';
import { UploadService } from '@app/core/services/backend/uploads.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'persons-form',
  templateUrl: './persons-form.component.html',
  styleUrls: ['./persons-form.component.css']
})
export class PersonsFormComponent implements OnInit {
  persons;
  forbiddenLocations;
  form;
  @Input() organizations;
  @Input() locations;
  @Output() addLocationModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(private personsService: PersonsService, private fb: FormBuilder, public controlContainer: ControlContainer, private uploadService: UploadService, private locationService: LocationService, private spinnerService: NgxSpinnerService) { 

  this.locationService.getLocations().subscribe(res => {
      this.forbiddenLocations = res.map(location => location.Name.toLowerCase())
      this.form = this.fb.group({
        addForm: this.fb.group({
          Name: this.fb.control('', [Validators.required, this.forbiddenNames.bind(this)]),
          Country: this.fb.control('', [Validators.required]),
          City: this.fb.control('', [Validators.required]),
          State: this.fb.control('', [Validators.required]),
          Latitude: this.fb.control('', []),
          Longitude: this.fb.control('', []),
          Elevation: this.fb.control('', []),
          ElevationUnit: this.fb.control('feet', []),
          Address: this.fb.control('', []),
          Description: this.fb.control('', []),
        })
      })
    });
  }


  addNewLocation() {
    let formValues = this.form.value.addForm;
    this.form.submitted = true;
    if (this.form.valid){
      this.setFormControlValuesBasedOnCondition('', ['Latitude', 'Longitude', 'Elevation'], 0, formValues);
      this.spinnerService.show();
      this.locationService.addLocation(this.form.value.addForm).subscribe(res => {
        if (res === null || !res) {
          console.log('%c location POST created lot but returned null or udefined: ', 'background: #ff0000; color: #ffffff;', res);
          this.spinnerService.hide();
          return;
        }
          this.spinnerService.hide();
      }, error => {
        console.log(error);
        setTimeout(() => this.spinnerService.hide(), 2000)
        return;
      });
    }
  }

  setFormControlValuesBasedOnCondition(condition, propsToCheckArray, defaultValue, formValuesObject) {
    for (const key in formValuesObject) {
      if (formValuesObject.hasOwnProperty(key)) {
        const value = formValuesObject[key];
        const propMatch = propsToCheckArray.includes(key);
        formValuesObject[key] = (propMatch && value === condition) ? defaultValue : value;
      }
    }
    return formValuesObject;
  }

  ngOnInit() {
   
  }

  get personTypeCtrl() {
    let personCtrl = this.controlContainer.control['controls'].PersonType as FormControl;
      return personCtrl;
  }

  onChange(e) {
    this.controlContainer.control['controls'].LocationName.setValue(this.locations.filter(location => {return location.id == this.controlContainer.control['controls'].LocationId.value})[0].Name);
  }

  showAddLocationModal() {
    this.addLocationModal.emit('true')
    // this.addLocationModal.show();
  }

  onFileSelected(event) {
    let file = <File>event.target.files[0];
    const fd = new FormData();
    fd.append('files', file, file.name);
    this.uploadService.upload(fd).subscribe(res => {
      this.controlContainer.control['controls']['Images'].value.push(res);
    });
  }

  forbiddenNames(control): {[s: string]: boolean} {
    if (!!control.value && this.forbiddenLocations.indexOf(control.value.toLowerCase()) !== -1) {
      return {'nameAlreadyExists': true};
    }
    return null;
  }

  spliceImage(e, i) {
    e.stopPropagation();
    e.preventDefault();
    console.log(e, i)
    this.controlContainer.control['controls']['Images'].value.splice(i, 1)
    console.log(this.controlContainer.control['controls']['Images'].value);
    // this.controlContainer.control['controls']['Images'].value.split(',')
  }
}
