import { Component, OnInit, Input } from '@angular/core';
import { CSVFileService } from '@app/core/services/csv-file.service';

@Component({
  selector: 'bext-download-csv-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.css']
})
export class DownloadCsvButtonComponent implements OnInit {

  @Input() buttonTitle: string;
  @Input() csvType: string;
  @Input() customCsvParsingString: string;

  constructor(private csvFileService: CSVFileService) { }

  ngOnInit() {

  }

  downloadCsv() {
    switch (this.csvType) {
      case 'location':
        this.csvFileService.getCsvFile('location', 'location_template.csv', this.customCsvParsingString);
        break;
      case 'user':
        this.csvFileService.getCsvFile('user', 'user_template.csv', this.customCsvParsingString);
        break;
      case 'person':
        this.csvFileService.getCsvFile('person', 'persons_template.csv', this.customCsvParsingString);
        break;
      case 'organization':
        this.csvFileService.getCsvFile('organization', 'organization_template.csv', this.customCsvParsingString);
        break;
      case 'node':
        this.csvFileService.getCsvFile('node', 'node_template.csv', this.customCsvParsingString);
        break;
      case 'supplychain':
        this.csvFileService.getCsvFile('supplychain', 'supply_chain_template.csv', this.customCsvParsingString);
        break;
      case 'lot':
        this.csvFileService.getExcelFile('lot', 'lot_template');
        break;

      default:
        break;
    }
  }

}
