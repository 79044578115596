import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WindowRefService } from './window-ref.service';

export interface IGlobeState {
  earthInstance?: any;
  containerElement?: HTMLElement;
}

@Injectable()
export class GlobeService {

  private windowRef = this.windowRefService.nativeWindow;

  public globeStateInit = {earthInstance: null}

  public loadAPI: Promise<any>;

  constructor(
    private windowRefService: WindowRefService
  ) {}

  private _globeState: BehaviorSubject<IGlobeState> = new BehaviorSubject<IGlobeState>(this.globeStateInit)

  get globeStateSubject() {
    return this._globeState;
  }

  get globeState() {
    return this._globeState.getValue();
  }

  get globeState$() {
    return this._globeState.asObservable();
  }

  get earthInstance() {
    return this.globeStateSubject.getValue().earthInstance;
  }

  loadEarthJs(libraryName: string = 'miniature.earth.js') {
    if (this.windowRef['Earth']) {
      this.loadAPI = Promise.resolve(this.windowRef['Earth'])
      return this.loadAPI;
    }
    else {
      this.loadScript(libraryName)
      return Promise.resolve(this.windowRef['Earth']);
    }
  }

  private loadScript(lib) {
    let script = document.createElement('script');
    script.src = `assets/globe/${lib}`;
    script.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  setEarthInstance(instance, element) {
    let state = this.globeState;
    state.earthInstance = instance;
    state.containerElement = element;
    this.globeStateSubject.next(state);
  }

  updateEarthInstance(updatedInstance) {
    let state = this.globeState;
    state.earthInstance = updatedInstance;
    this.globeStateSubject.next(state);
  }
  

}