import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


@Component({
  selector: 'bext-delete-supply-chain-dialog',
  templateUrl: './delete-supply-chain-dialog.component.html',
  styleUrls: ['./delete-supply-chain-dialog.component.scss']
})
export class DeleteSupplyChainDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteSupplyChainDialogComponent>
  ) {
    console.log('Delete Supply Chain Dialog - data: ', this.data);
  }
    
  ngOnInit() {
  }

  closeDialog(results?) {
    this.dialogRef.close(results);
  }  

}
