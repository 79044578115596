import { NgModule } from '@angular/core';
import { MatExpansionModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatCardModule, MatDialogModule, MatSnackBarModule } from '@angular/material';
import { NgxFormModule } from '@app/shared-v2/ngx-form/ngx-form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EditCopyNodeDialogComponent } from './edit-copy-node-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSnackBarModule,
    NgxFormModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    EditCopyNodeDialogComponent
  ],
  exports: [
    EditCopyNodeDialogComponent 
  ],
  entryComponents: [
    EditCopyNodeDialogComponent
  ]
})
export class EditCopyNodeDialogModule { }
