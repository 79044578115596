import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class NodeLotsStoreService {

  private _supplyChainSubject = new BehaviorSubject<any>(undefined);
  private _loadingSubject = new BehaviorSubject<any>(true);

  get supplyChain() {
    return this._supplyChainSubject.getValue();
  }

  get supplyChainChange$() {
    return this._supplyChainSubject.asObservable();
  }

  get loadingSupplyChain$() {
    return this._loadingSubject.asObservable();
  }
  
  showSupplyChain(val: boolean) {
    this._loadingSubject.next(val)
  };

  updateSupplyChain(sc) {
    this._supplyChainSubject.next(sc);
  }

  clearSubjects() {
    this._loadingSubject.complete();
    this._supplyChainSubject.complete();
  }

}
