export class ProductTypes {
    TypesByProduct: Array<any>
    constructor() {
        this.TypesByProduct =  [
            {
              productType:'Coffee',
              types:  [
                {
                  node: 'Collection',
                  detail: [
                    "CollectionFarm",
                    "CollectionHuman"
                  ]
                },
                {
                  node: 'IoT',
                  detail: [
                    'IotDeviceMachineBextCherry'
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    "ProcessingDrying",
                    "ProcessingFermenting",
                    "ProcessingFloating",
                    "ProcessingPulping",
                    "ProcessingMilling",
                    "ProcessingRoasting",
                    "ProcessingWashing"
                  ]
                },
                {
                  node: 'Quality',
                  detail: [
                    'QualityCupping'
                  ]
                },
                { node: 'Sales', 
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Cotton' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionFarm',
                    'CollectionHuman'
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            
            {
              productType: 'Cement' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            
            {
              productType: 'Herbs' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Indigo' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Honey' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Minerals' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Oil' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Pharmaceutical' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Quinoa' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Salt' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Seafood' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Timber' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Wool' ,
              types: [
                {
                  node: 'Collection',
                  detail: [
                    'CollectionHuman',
                  ]
                },
                {
                  node: 'Processing',
                  detail: [
                    'ProcessingDrying',
                    'ProcessingDyeing',
                    'ProcessingManufacturing',
                    'ProcessingFinishing',
                    'ProcessingGinning',
                    'ProcessingKnitting',
                    'ProcessingMilling',
                    'ProcessingSpinning',
                    'ProcessingWashing'
                  ]
                },
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            },
            {
              productType: 'Essential Oils',
              types: [
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                 node: 'Transportation',
                 detail: [
                    'TransportationExport'
                  ]
                }
              ]
            }, 
            {
              productType: 'Seafood',
              types: [
                {
                  node: 'Sales',
                  detail: [
                    'SalesRetail'
                  ]
                },
                {
                  node: 'Storage',
                  detail: [
                    'StorageWarehouse'
                  ]
                },
                {
                  node: 'Transportation',
                  detail: [
                    'TransportationExport'
                  ]
                }
              ]
            }
          ];
    }
    
}