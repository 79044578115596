import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { INode, NodeData } from '@app/shared-v2/models/node/node.interface';
import * as _ from 'lodash';
import { NodeService } from '@app/core/services/backend/node.service';
import { first, retry, map, take } from 'rxjs/operators';
import { NodesStoreService } from './nodes-store.service';


@Injectable()
export class SupplyChainStoreService {

  constructor(private nodeService: NodeService, private nodeStore: NodesStoreService) { }

  private initalSupplyChainSubject = {supplyChain: null, nodeMap: null, selectedNode: null, selectedNodeLots: null, refreshingSupplyChainData: false};

  private _supplyChainSubject = new BehaviorSubject<any>(this.initalSupplyChainSubject);
  private _loadingSubject = new BehaviorSubject<any>({ loading: false, message: '' });

  get supplyChain() {
    return this._supplyChainSubject.getValue();
  }

  get supplyChainChange() {
    return this._supplyChainSubject.asObservable();
  }

  get loading() {
    return this._loadingSubject.getValue();
  }

  get loadingChangeEvent() {
    return this._loadingSubject.asObservable();
  }

  setSupplyChain(supplyChain) {
    supplyChain.PreviousNodesData.forEach((node, i) => node.NodeSequence = i);
    let supplyChainSubject = this.supplyChain;
    supplyChainSubject.supplyChain = supplyChain;
    supplyChainSubject.nodeMap = this.__createNodeDetailMap(supplyChain.PreviousNodesData, 'NodeDetailType');
    this._supplyChainSubject.next(supplyChainSubject);
    let selectedNode = supplyChain.PreviousNodesData[0];
    this.setCurrentNodeDetails(selectedNode.NodeId)
  }

  findSupplyChainNode(node_id) {
    let supplyChainSubject = this.supplyChain;
    let supplyChain = supplyChainSubject.supplyChain;
    let isPreviousNodesData = (n) => n.NodeId === node_id;
    return supplyChain.PreviousNodesData.find(isPreviousNodesData);
  }
  
  refreshSupplyChainData(bool, data) {
    let selectedNode = this.findSupplyChainNode(data.transferToNodeId);
    let nodeMap = this.__createNodeDetailMap(data.supplyChain.PreviousNodesData, 'NodeDetailType');
    this._supplyChainSubject.next({ supplyChain: !data.supplyChain ? null : data.supplyChain, nodeMap: nodeMap, selectedNode: null, selectedNodeLots: null, refreshingSupplyChainData: bool });
    this.setCurrentNodeDetails(selectedNode.NodeId)
  }

  setSupplyChainNodeMap(supplyChain) {
    let supplyChainSubject = this.supplyChain;
    supplyChainSubject.nodeMap = this.__createNodeDetailMap(supplyChain.PreviousNodesData, 'NodeDetailType');
    this._supplyChainSubject.next(supplyChainSubject);
  }

  setCurrentNodeDetails(node_id, message?) {
    let loadingMessage = !message ? 'Loading Node Details ...' : message;
    this.loadingSpinnerShow(loadingMessage);
    let supplyChainSubject = this.supplyChain;
    let node$ = this.nodeService.getNodeById(node_id).pipe(take(1));
    let nodeLots$ = this.nodeService.getNodeLotsV2(node_id).pipe(take(1));

    forkJoin(node$, nodeLots$).pipe(map((res) => ({node: res[0], nodeLots: res[1]})))
      .subscribe(
        res => {
          res.nodeLots.Lots.forEach((lot) => {
            let lotHolder;
            res.nodeLots['_activeLots'] = Array.isArray(res.nodeLots['_activeLots']) ? res.nodeLots['_activeLots'] : [];
            res.nodeLots['_inactiveLots'] = Array.isArray(res.nodeLots['_inactiveLots']) ? res.nodeLots['_inactiveLots'] : [];
            lotHolder = lot.LotStatus.Open ? res.nodeLots['_activeLots'] : res.nodeLots['_inactiveLots'];
            lotHolder.push(lot);
          });
          supplyChainSubject.selectedNode = res.node;
          supplyChainSubject.selectedNodeLots = res.nodeLots;
          this.nodeStore.setSupplyChainNode(res.node);
          this._supplyChainSubject.next(supplyChainSubject);
          this.loadingSpinnerHide();
          return;
        },
        error => {
          console.log('%c GET - FORKJOIN node and selectedNodeLots: ', 'background: #ff0000; color: #ffffff;', error);
          supplyChainSubject.selectedNode = undefined;
          supplyChainSubject.selectedNodeLots = undefined;
          this._supplyChainSubject.next(supplyChainSubject);
          this.loadingSpinnerHide();
          return;
        }
      );
  }

  loadingSpinnerShow(message?: string) {
    let loadingSubject = this.loading;
    loadingSubject.message = !message ? '' : message;
    loadingSubject.loading = true;
    this._loadingSubject.next(loadingSubject);
  }

  loadingSpinnerHide(message?: string) {
    let loadingSubject = this.loading;
    loadingSubject.message = !message ? '' : message;
    loadingSubject.loading = false;
    this._loadingSubject.next(loadingSubject);
  }

  private __compareValues(key, order = 'asc') {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'number') ? a[key] : a[key];
      const varB = (typeof b[key] === 'number') ? b[key] : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order == 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  private __createNodeDetailMap(nodes: NodeData[], type) {

    let nodeList = (prev, curr, i) => {
      prev[curr[type]] = (!prev[curr[type]] || !prev[curr[type]].length) ? [] : prev[curr[type]];
      prev[curr[type]].push(curr);
      console.log('NODE DETAIL MAP: ' + i + ' - ');
      return prev;
    }

    let nodeDetails = (prev, curr, i, arr) => {
      let o = {
        type: type,
        data: arr[curr]
      };
      prev.push(o);
      return prev;
    }


    return nodes
      .reduce(nodeList, {})
      .reduce(nodeDetails)
  }

}