import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'leaflet-heatmap',
  templateUrl: './leaflet-heatmap.component.html',
  styleUrls: ['./leaflet-heatmap.component.css']
})
export class LeafletHeatmapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
