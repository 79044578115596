import { Directive, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[bgImage]'
})

export class BackgroundImageDirective implements OnInit {

  @Input() bgImage: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${this.bgImage})`)
  }

}
