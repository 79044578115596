import { OrganizationRoutes } from '@app/core/api-routes.enum';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OrganizationService {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  getOrganizations(): Observable<any> {
    return this.http.get<any>(this.BASE_URL + OrganizationRoutes.BASE_URL);
  }

  deleteOrganization(organization): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + OrganizationRoutes.BASE_URL + '/' + organization.id);
  }

  addOrganization(organization): Observable<any> {
    return this.http.post<any>(this.BASE_URL + OrganizationRoutes.BASE_URL, organization);
  }

  editOrganization(organization): Observable<any> {
    return this.http.put<any>(this.BASE_URL + OrganizationRoutes.BASE_URL + '/v2/' + organization.id, organization)
  }

  getOrganization(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + OrganizationRoutes.BASE_URL + '/' + id)
  }

  searchOrganizationsByName(name): Observable<any> {
    return this.http.get<any>(this.BASE_URL + '/Organizations/name/' + name)
  }

  getVisiblePartnerOrganizations(): Observable<any> {
    return this.http.get<any>(this.BASE_URL + OrganizationRoutes.BASE_URL + '/partners')
  }

  acceptPartnerRequest(id): Observable<any> {
    return this.http.post(this.BASE_URL + OrganizationRoutes.BASE_URL + '/partnerrequestresponse/'+ id, {accepted: true})
  }

  denyPartnerRequest(id): Observable<any> {
    return this.http.post(this.BASE_URL + OrganizationRoutes.BASE_URL + '/partnerrequestresponse/'+ id, {accepted: false})
  }
}
