import { takeWhile, map, take, debounceTime, distinctUntilChanged, skipWhile, tap, mergeMap, catchError } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MouseEvent, LatLng, GoogleMapsAPIWrapper, AgmMap } from '@agm/core';
import { NodeService } from '@app/core/services/backend/node.service';
import { from, forkJoin, of } from 'rxjs';
import {
  trigger,
  state,
  style,
  animate,
  transition

  // ...
} from '@angular/animations';
import { ClusterStyle } from '@agm/js-marker-clusterer/services/google-clusterer-types';
import { MapTypeControlOptions, ControlPosition, LatLngBounds } from '@agm/core/services/google-maps-types';
import { MatCheckboxChange } from '@angular/material';
import { LotService, ImageService, DocumentService } from '@app/core-v2/services';

declare var google: any;

@Component({
  selector: 'google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
  animations: [
    trigger('drawerCollapsed', [
      state('open', style({
        transform: 'rotate(-180deg)'
      })),
      state('closed', style({
        transform: 'rotate(0)'
      })),
      transition('open => close', animate('400ms ease-in')),
      transition('close => open', animate('400ms ease-in'))
    ])
  ]
})
export class GoogleMapComponent implements OnInit {

  private _enableClearSearchButton = false;
  @Input('showClearSearchBarButton')
  set enableClearSearchButton(enabled: boolean | string) {
    this._enableClearSearchButton = (enabled.toString() === 'true') ? true : false;
  }
  get enableClearSearchButton() {
    return this._enableClearSearchButton;
  }

  @ViewChild('AgmMap') agmMap: AgmMap;
  @ViewChild('searchInput') searchInputEl: ElementRef;
  searchInputFocused = false;
  // zoom: number = 2;
  private alive = true;
  public markers: any[] = [];
  public sidenavOpen = false;
  defaultCenter = { lat: 12, lng: 0 }
  public displayedMarkers: any[];
  Location: any;
  Latitude: number;
  Longitude: number
  lat: number;
  lng: number;
  debounceTimer = null;
  searchText: any = '';
  term: any;
  bounds: any;
  filtersArr: string[];
  currentInfoWindowsOpen: any[] = [];

  checkboxFilters = [
    { section: 'node', label: 'Node Markers', show: true },
    { section: 'lot', label: 'Lot Markers', show: true }
  ];

  public mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.TOP_RIGHT,
  };

  clusterStyles: ClusterStyle[] = [
    {
      textColor: "#000000",
      url: "assets/img/AGM_Cluster_Icons/image11.png",
      height: 56,
      width: 56,
      textSize: 24
    }
  ];

  constructor(
    private nodeService: NodeService,
    private lotService: LotService,
    private imageService: ImageService,
    private documentService: DocumentService
  ) { }



  ngOnInit() {
    this.nodeService.getNodes()
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        // ([nodes, nodeLotsMarkers]) => {
        (nodes) => {

          console.log('%c nodes: ', 'background: #41ff6b; color: #ff4700;', nodes);
          // console.log('%(c nodeLotsMarkers: ', 'background: #41ff6b; color: #ff4700;', nodeLotsMarkers);
          let nodeMarkers = nodes.map((node) => {
            node.NodeData.Images.forEach((img) => {
              let imageUrlArr = img.ImageURL.split('.');
              let imageExtension = imageUrlArr.pop();
              img['ImageThumbnailURL'] = imageUrlArr.join('.') + '-thumbnail.' + imageExtension;
            });
            return node;
          });

          this.markers = [
            ...nodeMarkers,
            // ...nodeLotsMarkers.Lots
          ];
        }
      )
  }

  ngAfterViewInit(): void {
    this.agmMap.mapReady
      .pipe(
        skipWhile(() => this.markers.length > 0)
      )
      .subscribe(
        map => {

          const bounds: LatLngBounds = new google.maps.LatLngBounds();
          for (const mm of this.markers) {

            bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
          }
          this.bounds = bounds;
          console.log('bounds: ', bounds);
          this.updateBoundsMarkers(bounds);
        },
        error => {
          console.log('Google Map Ready - error: ', error);
        },
        () => console.log('%c GOOGLE MAP READY COMPLETE: ', 'background: #fae552; color: #323232;')
      );

  }




  setMapInstance(e) {
    // console.log('map instance: ', e);
    // console.log('AgmMap element: ', this.agmMap);
  }

  onCheckboxChange(e: MatCheckboxChange) {
    let target = e.source.value;
    let sections = (s) => {
      if (s.section === target['section']) s.show = e.checked;
      return s;
    }
    let updateCheckboxFilters = this.checkboxFilters
      .map(sections);

    let visibleMarker = (vm) => vm.show;
    let markerTypes = (m) => m.section;
    this.filtersArr = updateCheckboxFilters
      .filter(visibleMarker)
      .map(markerTypes)

    this.checkboxFilters = [...updateCheckboxFilters];
    console.log('FILTERS ARRAY: ', this.filtersArr);
    this.updateBoundsMarkers(this.bounds, this.filtersArr)
  }

  clearSearchBox(e) {
    e.stopPropagation();
    e.preventDefault();
    this.searchText = '';
    this.focusSearchInput();
  }

  get checkInputFocus() {
    return this.searchInputFocused;
  }

  focusSearchInput() {
    this.searchInputEl.nativeElement.focus()
  }

  isInfoWindowOpen(marker_id) {
    let markerOpen = (m) => m.id === marker_id;
    let marker = this.currentInfoWindowsOpen.some(markerOpen);
    return marker;
  }

  updateInfoWindowsOpenList(marker) {
    let markerOpen = (m) => m.id === marker.id;
    let markerIndex = this.currentInfoWindowsOpen.findIndex(markerOpen);
    if (markerIndex === -1) {
      this.currentInfoWindowsOpen = [...this.currentInfoWindowsOpen, marker];
    } else {
      this.currentInfoWindowsOpen.splice(markerIndex, 1)
    }
  }

  removeMarkerFromInfoWindowsOpenList(marker) {
    let markerToRemove = (m) => m.id !== marker.id;
    this.currentInfoWindowsOpen = this.currentInfoWindowsOpen.filter(markerToRemove);
  }

  markerClickEvent(event, marker, action?: string) {
    switch (marker.type) {
      case 'Node':
        this.updateInfoWindowsOpenList(marker);
        // console.log('facade marker click - action for type: ', 'NODE');
        this.markerReducer(action, marker, event);
        break;

      default:
        break;
    }
    this.sidenavOpen = true;
  }

  // markerReducer(action, node, event) {
  //   switch (action) {
  //     case 'ADD_CHILD':

  //       // this.nodeService.getNodeLotsGoogleMapMarkers(node.id)
  //       // .pipe(
  //       //   take(1),
  //       //   map((node: any) => node.Lots),
  //       //   map((lots: any) => from(lots.id)),
  //       //   tap(l => console.log('Node Lots inner observable: ', l)),
  //       //   mergeMap((nodeLots: any) => this.lotService.getLot(nodeLots.id)),
  //       //   tap(l => console.log('Lots after mergemap observable: ', l))
  //       // ).subscribe(
  //       //   res => console.log('%c GET lots from node lots: ', 'background: #41ff6b; color: #ff4700;', res),
  //       //   error => console.log('%c GET lots from node lots error: ', 'background: #ff0000; color: #ffffff;', error)
  //       // )

  //       this.nodeService.getNodeLotsGoogleMapMarkers(node.id)
  //         .pipe(
  //           take(1),
  //           map((node) => node.Lots)
  //         )
  //         .subscribe(
  //           res => {
  //             let lotMarkers = res.map((lot) => {
  //               lot['ParentId'] = node.id;
  //               lot['type'] = 'Lot';

  //               return lot;
  //             });
  //             // let uniqueMarkers = (m) => m.ParentId 
  //             this.markers = [...this.markers, ...lotMarkers];
  //             this.updateBoundsMarkers(this.bounds, this.filtersArr);
  //             console.log('GET node lots - success: ', res);
  //             console.log('updated markers: ', this.markers);
  //           },
  //           error => {
  //             console.log('GET node lots - error: ', error);
  //           }
  //         )
  //       break;

  //     default:
  //       break;
  //   }
  //   // console.log('reducer: ', arguments);
  // }

// THUMBNAIL IMPLEMENTATION

markerReducer(action, node, event) {
  switch (action) {
    case 'ADD_CHILD':

      // this.nodeService.getNodeLotsGoogleMapMarkers(node.id)
      // .pipe(
      //   take(1),
      //   map((node: any) => node.Lots),
      //   map((lots: any) => from(lots.id)),
      //   tap(l => console.log('Node Lots inner observable: ', l)),
      //   mergeMap((nodeLots: any) => this.lotService.getLot(nodeLots.id)),
      //   tap(l => console.log('Lots after mergemap observable: ', l))
      // ).subscribe(
      //   res => console.log('%c GET lots from node lots: ', 'background: #41ff6b; color: #ff4700;', res),
      //   error => console.log('%c GET lots from node lots error: ', 'background: #ff0000; color: #ffffff;', error)
      // )
      forkJoin([
        this.nodeService.getNodeLotsGoogleMapMarkers(node.id).pipe(catchError(err => of({Lots: []}))),
        this.documentService.getImageDocuments('node', node.id).pipe(catchError(err => of([])))
      ])
        .pipe(
          take(1),
          map(([node, images]: [any, any[]]) => {
            let nodeImages = images
              .reduce((p,c) => [...p, ...c.Images],[])
              .map((nodeImage) => {
                nodeImage['ImageThumbnailURL'] = this.imageService.getThumbnailUrl(nodeImage.ImageURL);
                return nodeImage;
              })
            return [node.Lots, nodeImages];
          })
        )
        .subscribe(
          ([nodeLots, nodeImages]: [any, any]) => {
            node.NodeData.Images = nodeImages;
            let lotMarkers = nodeLots.map((lot) => {
              lot['ParentId'] = node.id;
              lot['type'] = 'Lot';
              if (Array.isArray(lot.Images)) {
                lot.Images.forEach((img) => {
                  img['ImageThumbnailURL'] = this.imageService.getThumbnailUrl(img.ImageURL);
                });
              }
              return lot;
            });
            // let uniqueMarkers = (m) => m.ParentId 
            this.markers = [...this.markers, ...lotMarkers];
            // this.markers = [...this.markers, ...lotMarkers].filter((marker, i, arr) => arr.indexOf(marker.id) === i);
            this.updateBoundsMarkers(this.bounds, this.filtersArr);
            console.log('updated markers: ', this.markers);
          },
          error => {
            console.log('GET node lots - error: ', error);
          }
        )
      break;

    default:
      break;
  }
  // console.log('reducer: ', arguments);
}


  updateBoundsMarkers(bounds: any, filtersArr?: string[]): void {
    // if markers are present
    this.filtersArr = Array.isArray(filtersArr) ? filtersArr : this.filtersArr;
    this.bounds = bounds;
    // console.log('updatemarker bounds: ', bounds);
    if (this.markers) {
      // debounce timer prevents processing of marker filters until map stops moving for 200ms
      if (this.debounceTimer !== null) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = null;
      }
      this.debounceTimer = setTimeout(() => {

        let filteredMarkersList = []

        let markerWithinBounds = (m) => bounds.contains({ lat: m.Location.Latitude, lng: m.Location.Longitude });
        filteredMarkersList = this.markers
          .filter(markerWithinBounds)

        // let tempMarkerList = []; // tempMarkerList is equivilent to filteredMarkersList
        // for (let i = 0; i < this.markers.length; i++) {
        //   const m = this.markers[i];
        //   let markerInBounds = bounds.contains({ lat: m.Location.Latitude, lng: m.Location.Longitude });
        //   if (markerInBounds) {
        //     tempMarkerList.push(markerInBounds);
        //   }
        // }

        if (filtersArr) {
          // write next 2 lines as for loop
          let ifMarkerTypeMatch = (m) => filtersArr.indexOf(m.type.toLowerCase()) > -1;
          this.displayedMarkers = filteredMarkersList.filter(ifMarkerTypeMatch)

          // let ifMarkerTypeMatch = [];
          //   for(var i in filtersArr) {   
          //     if(filteredMarkersList.indexOf(filtersArr[i]) > -1){
          //         ifMarkerTypeMatch.push(filtersArr);
          //     } 
          //   }
          // return ifMarkerTypeMatch;
          // this.displayedMarkers = ifMarkerTypeMatch;
        }
        else {
          this.displayedMarkers = [...filteredMarkersList];
        }
        console.log('%c displayed markers: ', 'background: #fae552; color: #323232;', this.displayedMarkers);
      }, 200);
    }
  }

  // updateDisplayedMarkers( ) {

  // }

  ngOnDestroy() {
    this.alive = false;
  }

}

