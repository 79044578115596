import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'sa-lot-dto-tree',
  templateUrl: './lot-dto-tree.component.html',
  styleUrls: ['./lot-dto-tree.component.css']
})
export class LotDtoTreeComponent implements OnInit {
  tabs = [];
  selectedLot;
  @Input('selectedLot') get getSelectedLot() {
    return this.selectedLot;
  };
  @Input() lotHistoryDtos;
  @Input() index = 0;
  @Input() dataSource;
  @Input() treeControl;
  @Output() lotClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    for (var i = 0; i < this.index; i++) {
      this.tabs.push({});
    }

    if (this.index == 0 && this.lotHistoryDtos && this.lotHistoryDtos.length) {
      this.lotSelected(this.lotHistoryDtos[0]);
      this.selectedLot = this.lotHistoryDtos[0];
    }

  }

  set getSelectedLot(val) {
    this.selectedLot = val;
  }

  lotSelected(e) {
    this.lotClicked.emit(e);
    this.selectedLot = e;
  }
}
