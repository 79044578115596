import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';

@Component({
  selector: 'bext-sustainability-wizard',
  templateUrl: './sustainability-wizard.component.html',
  styleUrls: ['./sustainability-wizard.component.scss']
})
export class SustainabilityWizardComponent implements OnInit {

  @Output('onCloseDialog') onCloseDialog: EventEmitter<any> = new EventEmitter<any>();

  wizardModel$: Observable<any>;

  constructor(
    public dialogRef: MatDialogRef<SustainabilityWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log('this data: ', this.data);
  }

  closeDialog() {
    this.onCloseDialog.emit(true);
  }

}
