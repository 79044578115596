import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoGalleryComponent } from './photo-gallery.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { MouseWheelDirective } from './directives/mousewheel.directive';
import { PipesV2Module } from '@app/shared-v2/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    PipesV2Module
  ],
  declarations: [PhotoGalleryComponent, ClickOutsideDirective, MouseWheelDirective],
  exports: [PhotoGalleryComponent, ClickOutsideDirective, MouseWheelDirective]
})
export class PhotoGalleryModule { }
