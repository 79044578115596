export interface IGlobeLocation {
  lat?: number;
  lng?: number;
}

export class GlobeConfig {
		
  public location: IGlobeLocation = { "lat": 10, "lng": -80 };
  public quality: number = 3;
  public mapLandColor: string = "#44cc44";
  public mapSeaColor: string = "#3399ff";
  public mapBorderColor: string = "";
  public mapBorderWidth: number = 0.3;
  public mapStyles: string = "";
  public texture: string = "";
  public textureBlending: string = "source-over";
  public draggable: boolean = true;
  public grabCursor: boolean = true;
  public dragMomentum: number = 1;
  public dragDamping: number = 0.7;
  public dragPolarLimit: number = 0.3;
  public polarLimit: number = 0.3;
  public autoRotate: boolean = false;
  public autoRotateSpeed: number = 1;
  public autoRotateSpeedUp: number = 0;
  public autoRotateDelay: number = 1000;
  public autoRotateStart: number = 1000;
  public autoRotateEasing: string = "in-quad";
  public zoom: number = 1;
  public zoomable: boolean = false;
  public zoomMin: number = 0.5;
  public zoomMax: number = 1.25;
  public zoomSpeed: number = 1;
  public light: string = "simple";
  public lightAmbience: number = (this.light == 'none') ? 1 : 0.5;
  public lightIntensity: number = 0.5;
  public lightColor: string = "#FFFFFF";
  public lightGroundColor: string = "#999999";
  public sunLocation: IGlobeLocation = { "lat": 0, "lng": 0 };
  public shadows: boolean = (this.light == 'sun');
  public shininess: number = 0.1;
  public flatShading: boolean = false;
  public transparent: boolean = false;
  public paused: boolean = false;
  public showHotspots: boolean = false;
  public mapHitTest: boolean = false;
    
  constructor(
    fields?: IGlobeConfig
  )
  {
    if (fields) Object.assign(this, fields);
  }
}

export interface IGlobeConfig {
  location?: any;
  quality?: number;
  mapLandColor?: string;
  mapSeaColor?: string;
  mapBorderColor?: string;
  mapBorderWidth?: number;
  mapStyles?: string;
  texture?: string;
  textureBlending?: string;
  draggable?: boolean;
  grabCursor?: boolean;
  dragMomentum?: number;
  dragDamping?: number;
  dragPolarLimit?: number;
  polarLimit?: number;
  autoRotate?: boolean;
  autoRotateSpeed?: number;
  autoRotateSpeedUp?: number;
  autoRotateDelay?: number;
  autoRotateStart?: number;
  autoRotateEasing?: string;
  zoom?: number;
  zoomable?: boolean;
  zoomMin?: number;
  zoomMax?: number;
  zoomSpeed?: number;
  light?: string;
  lightAmbience?: number;
  lightIntensity?: number;
  lightColor?: string;
  lightGroundColor?: string;
  sunLocation?: IGlobeLocation;
  shadows?: string;
  shininess?: number;
  flatShading?: boolean;
  transparent?: boolean;
  paused?: boolean;
  showHotspots?: boolean;
  mapHitTest?: boolean;
}