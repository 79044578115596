import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { MarketPlaceService } from '@app/core-v2/services';

export class Options {
  tag: string;
  data: number;
}

export class OptionsResult {
  options: Options[];
  loadID: number;
  error: string;
}

@Injectable()
export class AsyncMatSelectService {

  private optionsSubject: BehaviorSubject<OptionsResult> = new BehaviorSubject<OptionsResult>(null);
  options: Observable<OptionsResult> = this.optionsSubject.asObservable();

  counter = 0;

  constructor(
    private marketPlaceService: MarketPlaceService
  ) { }

  getOptions(loadID: number, failureMessage: string) {
    setTimeout(() => {
      let ops: Options[] = [];
      for (let i = 0; i < 10; i++) {
        ops.push({ tag: "Option " + ++this.counter, data: this.counter });
      }
      this.counter -= 8;
      if (Math.random() < 0.1) {
        this.optionsSubject.next({ options: [], loadID: loadID, error: failureMessage });
      }
      else {
        this.optionsSubject.next({ options: ops, loadID: loadID, error: null });
      }
    }, 3000);
  }
}
