import { environment } from '@env/environment';
import { UploadRoutes } from '@app/core/api-routes.enum';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UploadService {

  BASE_URL = environment.baseApiUrl;
  UPLOAD_URL = "/uploads";
  UPLOADS_LOTS_URL = "/uploads/Lots";
  DELETE_URL = "/delete";

  constructor(private http: HttpClient) { }

  getUpload(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + UploadRoutes.BASE_URL + UploadRoutes.UPLOAD_URL + '/' + id);
  }

  uploadLotImage(id, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + this.UPLOAD_URL + '/' + id + '/lot', payload);
  }

  uploadNodeImage(id, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + this.UPLOADS_LOTS_URL + '/' + id + '/node', payload);
  }

  upload(payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + this.UPLOAD_URL + '/upload', payload);
  }

  deleteNodeImage(node_id, img_id): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + this.UPLOAD_URL + this.DELETE_URL + `/${node_id}/${img_id}`);
  }

  deleteImage(img_id): Observable<any> {
    return this.http.delete<any>(this.BASE_URL + this.UPLOAD_URL + this.DELETE_URL + `/${img_id}`);
  }

}
