import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { ArchwizardBuilderService, UtilitiesService } from '@app/core-v2/services';
import { FormStep } from '@app/shared-v2/models/archwizard/form-step.interface';
import { Option, Attribute } from '@app/shared-v2/models/archwizard/form-attribute.class';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'bext-archwizard-builder',
  templateUrl: './archwizard-builder.component.html',
  styleUrls: ['./archwizard-builder.component.scss'],
  providers: [ArchwizardBuilderService]
})
export class BextArchwizardBuilderComponent implements OnInit, AfterViewInit {

  private alive = true;
  
  private _wizardModel: FormStep[] = [];
  set wizardModel(model: FormStep[]) {
    this._wizardModel = model;
  }
  get wizardModel() {
    return this._wizardModel;
  }
  
  toolTip = "copy JSON"
  copyBtnIcon = "file_copy"
  dropZoneActive = false;
  success = false;
  selectedStepIndex;
  modelFields: Array<FormStep> = [];
  wizardSteps: FormStep[];
  newOptionValue: Option = {
    Label: "",
    Value: ""
  };

  buttonConfiguration = {
    subTypes: [
      { Label: 'submit', Value: 'CRUDOperation' },
      { Label: 'cancel and close', Value: 'CancelAndClose' }
    ],
    classNames: [
      { Label: 'White', Value: 'default' },
      { Label: 'Black', Value: 'primary' },
      { Label: 'Green', Value: 'accent' },
      { Label: 'Red', Value: 'warn' }
    ],
    methods: ['GET', 'POST', 'PUT', 'DELETE']
  }

  fileuploadConfiguration = {
    subTypes: [{ "Label": "Create Document", "Value": "CreateDocument" }, { "Label": "Show Existing Documents", "Value": "ShowExistingDocs" }]
  }
  
  constructor(
    private cd: ChangeDetectorRef,
    private archwizardBuilderService: ArchwizardBuilderService,
    private utilService: UtilitiesService
  ) {}

  ngOnInit() {
    // this.archwizardBuilderService.getWizardBuilderDraggableElementsData('assets/data/archwizard/draggable_elements.json')
    //   .pipe(
    //     takeWhile(() => this.alive)
    //   )
    //   .subscribe(
    //     res => {
    //       this.draggableElements = res;
    //       console.log('GET wizard data - response: ', res);
    //       return;
    //      },
    //     error => {
    //       console.log('GET wizard data - error: ', error);
    //       return;
    //     }
    //   );

    console.log('%c INITIATE BUILDER: ', 'background: #ff00ff; color: #ffffff;');
    this.addStep();
    console.log('%c add step: ', 'background: #ff00ff; color: #ffffff;', );
      
    this.archwizardBuilderService.wizardBuilderValueChanges
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        res => {
          console.log('wizard builder value changes - response: ', res);
          this.wizardModel = res;
          this.selectedStepIndex = this.wizardModel.length - 1;
          // console.log('%c selected step: ', 'background: #41ff6b; color: #ff4700;', this.selectedStepIndex,'\n' ,this.wizardModel[this.selectedStepIndex]);
          return;
         },
        error => {
          console.log('wizard builder value changes - error: ', error);
          return;
        }
      );
      
  }

  ngAfterViewInit(): void {
    // Force another change detection in order to fix the ngFor error
    this.cd.detectChanges();
  }

  addStep() {
    if (!this.selectedStepIndex) this.selectedStepIndex = 0
    return this.archwizardBuilderService.addStep();
  }

  removeStep(index) {
    return this.archwizardBuilderService.removeStep(index);
  }

  onDragStart(event: DragEvent) {
    console.log("drag started", JSON.stringify(event, null, 2));
  }

  onDragEnd(event: DragEvent) {
    this.dropZoneActive = false;
    console.log("drag ended", JSON.stringify(event, null, 2));
  }

  onDraggableCopied(event: DragEvent) {
    console.log("draggable copied", JSON.stringify(event, null, 2));
  }

  onDraggableLinked(event: DragEvent) {
    console.log("draggable linked", JSON.stringify(event, null, 2));
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDragCanceled(event: DragEvent) {
    console.log("drag cancelled", JSON.stringify(event, null, 2));
  }

  onDragover(event: DragEvent) {
    this.dropZoneActive = true;
    console.log("dragover", JSON.stringify(event, null, 2));
  }

  onDrop(event: DndDropEvent, list?: any[]) {

    console.log('drop: ', {event, list});
    
    let field: Attribute = event.data;

    if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {

      if (event.dropEffect === "copy") {
        field.Name = field.Type + '-' + new Date().getTime();
        field.Visible = true;
      }
      let index = event.index;
      if (typeof index === "undefined") {
        index = list.length;
      }

      list.splice(index, 0, field);
    }
  }

  addValue(values, item?: Attribute) {
    if (item && item.Type === 'checkbox') {
      this.newOptionValue.IsSelected = false;
    }
    values.push(this.newOptionValue);
    this.newOptionValue = { Label: "", Value: ""};
  }

  removeField(i, attributes) {
    attributes.splice(i, 1);
  }

  buttonSubTypeChange(e, item) {
    if (e === 'CancelAndClose') item.Action = null;
    else if (e === 'CRUDOperation') item.Action = { "ActionType": "modal", "ActionKey": "custom-form", "Method": "", "EndPoint": "", "Controller": "", "Response": { "UiAction": { "Type": "modal", "Value": "Sucessfully Added Category: {0}", "Duration": 2000 } } };
    console.log('%c item: ', 'background: #41ff6b; color: #ff4700;', item);
  }

  updateForm() {
    // let input = new FormData;
    // input.append('id', this.model._id);
    // input.append('name', this.model.name);
    // input.append('description', this.model.description);
    // input.append('bannerImage', this.model.theme.bannerImage);
    // input.append('bgColor', this.model.theme.bgColor);
    // input.append('textColor', this.model.theme.textColor);
    // input.append('attributes', JSON.stringify(this.model.attributes));

    // this.us.putDataApi('/admin/updateForm',input).subscribe(r=>{
    //   console.log(r);
    //   swal('Success','App updated successfully','success');
    // });
  }

  copyToClipboard(data) {
    this.toolTip = 'Copied';
    this.copyBtnIcon = "done";
    let val = typeof data === 'object' ? JSON.stringify(data) : data;
    this.utilService.copyToClipBoard(val);
    setTimeout(() => {
      this.toolTip = 'copy JSON';
      this.copyBtnIcon = "file_copy";
    }, 1500);
  }

  // initReport() {
  //   this.report = true;
  //   let input = {
  //     id: this.model._id
  //   }
    // this.us.getDataApi('/admin/allFilledForms',input).subscribe(r=>{
    //   this.reports = r.data;
    //   console.log('reports',this.reports);
    //   this.reports.map(records=>{
    //     return records.attributes.map(record=>{
    //       if(record.type=='checkbox'){
    //         record.value = record.values.filter(r=>r.selected).map(i=>i.value).join(',');
    //       }
    //     })
    //   });
    // });
  // }

  dropListItem(event: CdkDragDrop<string[]>, item) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      item.value = event.container.data;
    }
    console.log('%c drag drop list event and item: ', 'background: #fae552; color: #323232;', event, item);
  }

  toggleValue(item) {
    item.selected = !item.selected;
  }

  combineWizardStepAttributes(wizardSteps) {
    return wizardSteps.reduce((prev, curr) => {
      return [...prev, ...curr.Attributes];
    }, []);
  }

  submit() {
    let valid = true;
    let combinedWizardSteps = this.combineWizardStepAttributes(this.wizardSteps);
    console.log('combinedWizardSteps: ', combinedWizardSteps);
    let validationArray = JSON.parse(JSON.stringify(combinedWizardSteps));
    validationArray.reverse().forEach(field => {
      console.log(field.Label + '=>' + field.Required + "=>" + field.Value);
      if (field.Required && !field.Value && field.Type != 'checkbox') {
        // Swal('Error', 'Please enter ' + field.Label, 'error');
        console.log('Error', 'Please enter ' + field.Label, 'error');
        valid = false;
        return false;
      }
      if (field.Required && field.Regex) {
        let regex = new RegExp(field.Regex);
        if (regex.test(field.Value[0]) == false) {
          // Swal('Error', field.ErrorText, 'error');
          console.log('Error', field.ErrorText, 'error');
          valid = false;
          return false;
        }
      }
      if (field.Required && field.Type == 'checkbox') {
        if (field.Values.filter(r => r.selected).length == 0) {
          // Swal('Error', 'Please enterrr ' + field.Label, 'error');
          console.log('Error', 'Please enterrr ' + field.Label, 'error');
          valid = false;
          return false;
        }

      }
    });
    if (!valid) {
      return false;
    }
    console.log('Save', this.wizardSteps);
    // let input = new FormData;
    // input.append('formId', this.model._id);
    // input.append('attributes', JSON.stringify(this.model.attributes))
    // this.us.postDataApi('/user/formFill',input).subscribe(r=>{
    //   console.log(r);
    //   Swal('Success','You have contact sucessfully','success');
    //   this.success = true;
    // },error=>{
    //   Swal('Error',error.message,'error');
    // });
  }

  draggableElements = [
    {
      "Type": "text",
      "Icon": "fa fa-font",
      "Label": "Text",
      "Description": "Enter your name",
      "Placeholder": "Enter your name",
      "ClassName": "form-control",
      "SubType": "text",
      "Regex": "",
      "Min": 0,
      "Max": 150,
      "Value": [
        {
          "Label": "",
          "Value": ""
        }
      ]
    },
    {
      "Type": "email",
      "Icon": "fa fa-envelope",
      "Required": true,
      "Label": "Email",
      "Description": "Enter your email",
      "Placeholder": "Enter your email",
      "ClassName": "form-control",
      "SubType": "text",
      "Regex": "^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+)\\.([a-zA-Z]{2,5})$",
      "ErrorText": "Please enter a valid email",
      "Value": [
        {
          "Label": "",
          "Value": ""
        }
      ]
    },
    {
      "Type": "phone",
      "Icon": "fa fa-phone",
      "Label": "Phone",
      "Description": "Enter your phone",
      "Placeholder": "Enter your phone",
      "ClassName": "form-control",
      "SubType": "text",
      "Regex": "^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$",
      "ErrorText": "Please enter a valid phone number",
      "Value": [
        {
          "Label": "",
          "Value": ""
        }
      ]
    },
    {
      "Type": "number",
      "Label": "Number",
      "Icon": "fa fa-html5",
      "Description": "Age",
      "Placeholder": "Enter your age",
      "ClassName": "form-control",
      "Min": -9007199254740990,
      "Max": 99007199254740990,
      "Value": [
        {
          "Label": "",
          "Value": 20
        }
      ]
    },
    {
      "Type": "date",
      "Icon": "fa fa-calendar",
      "Label": "Date",
      "Placeholder": "Date",
      "ClassName": "form-control",
      "Value": [
        {
          "Label": "",
          "Value": ""
        }
      ]
    },
    {
      "Type": "datetime-local",
      "Icon": "fa fa-calendar",
      "Label": "DateTime",
      "Placeholder": "Date Time",
      "ClassName": "form-control",
      "Value": [
        {
          "Label": "",
          "Value": ""
        }
      ]
    },
    {
      "Type": "textarea",
      "Icon": "fa fa-text-width",
      "Label": "Textarea",
      "Value": [
        {
          "Label": "",
          "Value": ""
        }
      ]
    },
    {
      "Type": "paragraph",
      "Icon": "fa fa-paragraph",
      "Label": "Paragraph",
      "Placeholder": "Type your text to display here only"
    },
    {
      "Type": "html",
      "Icon": "fas fa-code",
      "Label": "HTML",
      "Placeholder": "<div><span>this is </span><span style='color: red; font-weight: bold;'>HTML </span><span style='font-style: italic; text-transform: uppercase; color: black;'>code</span></div>"
    },
    {
      "Type": "checkbox",
      "Required": true,
      "Label": "Checkbox",
      "Icon": "fa fa-list",
      "Description": "Checkbox",
      "Inline": true,
      "Options": [
        {
          "Label": "Option 1",
          "Value": "option-1",
          "IsSelected": false
        },
        {
          "Label": "Option 2",
          "Value": "option-2",
          "IsSelected": false
        }
      ],
      "Indeterminate": false,
      "SelectAllState": false,
      "Value": []
    },
    {
      "Type": "radio",
      "Icon": "fa fa-list-ul",
      "Label": "Radio",
      "Description": "Radio boxes",
      "Options": [
        {
          "Label": "Option 1",
          "Value": "option-1"
        },
        {
          "Label": "Option 2",
          "Value": "option-2"
        }
      ],
      "Inline": true,
      "Value": []
    },
    {
      "Type": "select",
      "MultiSelect": false,
      "Icon": "fa fa-bars",
      "Label": "Select",
      "Description": "Select",
      "Placeholder": "New item...",
      "ClassName": "form-control",
      "Options": [
        {
          "Label": "Option 1",
          "Value": "option-1"
        },
        {
          "Label": "Option 2",
          "Value": "option-2"
        },
        {
          "Label": "Option 3",
          "Value": "option-3"
        }
      ],
      "Value": []
    },
    {
      "Type": "chips",
      "Icon": "fas fa-ellipsis-h",
      "Label": "Chip List",
      "Placeholder": "Chip options...",
      "ClassName": "form-control",
      "Options": [
        { "Label": "first chip", "Value": "fc-1" }, 
        { "Label": "second", "Value": "fc-2" }, 
        { "Label": "🖥️", "Value": "fc-3" }, 
        { "Label": "🥑", "Value": "fc-4" }
      ],
      "Value": []
    },
    {
      "Type": "drag-drop-lists",
      "Icon": "fas fa-grip-lines-vertical",
      "Label": "Drag Drop Lists",
      "Description": "drag and drop values from one list to the other",
      "ClassName": "form-control",
      "DragDropLists": {
        "From": {
          "Label": "from list",
          "Options": [
            { "Label": "item 1", "Value": "some value"},
            { "Label": "item 2", "Value": "another value"},
            { "Label": "item 3", "Value": "too many values"},
            { "Label": "item 4", "Value": "last value"}
          ]
        },
        "To": {
          "Label": "to list",
          "Options": []
        }
      },
      "Value": []
    },
    {
      "Type": "fileupload",
      "Icon": "fa fa-file",
      "Label": "File Upload",
      "ClassName": "form-control",
      "SubType": "createDocument",
      "AcceptableFileTypes": "*",
      "AcceptsMultipleFiles": true,
      "Action": {
        "ActionType": "modal",
        "ActionKey": "custom-form",
        "Method": "",
        "EndPoint": "",
        "Controller": "",
        "Response": {
          "UiAction": {
            "Type": "modal",
            "Value": "Sucessfully Added Category: {0}",
            "Duration": 2000
          }
        },
        "Parameters": []
      },
      "Value": [
        {
          "Label": "",
          "Value": ""
        }
      ]
    },
    {
      "Type": "button",
      "Icon": "fas fa-vector-square",
      "Label": "Button",
      "ClassName": "accent",
      "SubType": "CRUDOperation",
      "Action" : {
        "ActionType": "modal",
        "ActionKey": "custom-form",
        "Method": "",
        "EndPoint": "",
        "Controller": "",
        "Response": {
          "UiAction": {
            "Type": "modal",
            "Value": "Sucessfully Added Category: {0}",
            "Duration": 2000
          }
        }
      }
    }
  ];

}
