import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditUserDialogComponent } from './edit-user-dialog.component';
import { MatDialogModule, MatButtonModule, MatIconModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [EditUserDialogComponent],
  entryComponents: [EditUserDialogComponent]
})
export class EditUserDialogModule { }
