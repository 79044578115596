import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ProductsService } from "@app/core/services/backend/product.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

@Component({
  selector: 'bext-create-requisition',
  templateUrl: './create-requisition.component.html',
  styleUrls: ['./create-requisition.component.scss']
})
export class CreateRequisitionComponent implements OnInit {

  public requisitionName: string = "";
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateRequisitionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productService: ProductsService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {
    this.form = this.fb.group({
      RequisitionName: new FormControl("", [
        Validators.required,
        Validators.minLength(2)
      ]) 
    });
  }

  ngOnInit() { }

  get requisitionNameControl() {
    return this.form.get("RequisitionName") as FormGroup;
  }

  onSubmit() {
    console.log(this.form.valid, this.form);
    if (this.form.valid) {
      this.productService.addRequisition(this.form.value).subscribe(res => {
        if (res) {
          this.dialogRef.close();
          this._snackBar.open("Product created successfully", "OK", {
            duration: 3000
          });
        }
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
