import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { LotService } from '@app/core/services/backend/lot.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'lot-tokens-v2',
  templateUrl: './lot-tokens.component.html',
  styleUrls: ['./lot-tokens.component.scss']
})
export class LotTokensV2Component {

    @Input() tokens;

    @Input() hideButtons = false;




  constructor(private lotService: LotService, public dialogRef: MatDialogRef<LotTokensV2Component>,@Inject(MAT_DIALOG_DATA) public data: any){}


  ngOnInit() {
    if (this.tokens && this.tokens !== null && (!this.data || !this.data['tokens'])) {
      this.data.tokens = this.tokens;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
