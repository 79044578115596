import { environment } from "@env/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { NodeRoutes } from "@app/core-v2/api-routes-v2.enum";

@Injectable()
export class NodeService {
  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) {}

  //New Routes for Dtos
  getNodeListDtos(): Observable<any> {
    return this.http.get(
      this.BASE_URL + NodeRoutes.BASE_URLV2 + NodeRoutes.NODE_LIST_DTOS
    );
  }

  getNodeById(id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + NodeRoutes.BASE_URL + "/" + id);
  }

  getNodes(): Observable<any> {
    return this.http.get(this.BASE_URL + NodeRoutes.BASE_URL);
  }

  getNodeSummaries(): Observable<any> {
    return this.http.get<any>(
      this.BASE_URL + NodeRoutes.BASE_URL + NodeRoutes.NODE_SUMMARIES
    );
  }

  getSupplyChainNodeSummaries(supply_chain_id): Observable<any> {
    return this.http.get<any>(
      this.BASE_URL +
        NodeRoutes.BASE_URL +
        NodeRoutes.NODE_SUMMARIES +
        "/supplychain/" +
        supply_chain_id
    );
  }

  getNodeUiData(node_id): Observable<any> {
    return this.http.get<any>(
      this.BASE_URL +
        NodeRoutes.BASE_URL +
        NodeRoutes.NODE_UIDATA +
        "/" +
        node_id
    );
  }

  getNodeRetailReportDisplay(node_id): Observable<any> {
    return this.http.get<any>(
      this.BASE_URL +
        NodeRoutes.BASE_URL +
        "/" +
        node_id +
        NodeRoutes.RETAIL_REPORT_DISPLAY_URL
    );
  }

  setNodeRetailReportDisplay(node_id, data): Observable<any> {
    return this.http.put<any>(
      this.BASE_URL +
        NodeRoutes.BASE_URL +
        "/" +
        node_id +
        NodeRoutes.RETAIL_REPORT_DISPLAY_URL,
      data
    );
  }

  getNodeLots(node_id): Observable<any> {
    return this.http.get<any>(
      this.BASE_URL + NodeRoutes.NODE_LOTS_URL + "/" + node_id
    );
  }

  getNodeLotsV2(node_id): Observable<any> {
    return this.http.get<any>(
      this.BASE_URL + NodeRoutes.NODE_LOTS_URL + "/v2/" + node_id
    );
  }

  editNode(node): Observable<any> {
    return this.http.put<any>(
      this.BASE_URL + NodeRoutes.BASE_URL + "/" + node.id,
      node
    );
  }

  addNode(node): Observable<any> {
    return this.http.post<any>(this.BASE_URL + NodeRoutes.BASE_URL, node);
  }

  addNodeV1(node): Observable<any> {
    return this.http.post<any>(
      this.BASE_URL + NodeRoutes.BASE_URL + NodeRoutes.CREATE_NODE_V1,
      node
    );
  }

  resetNodeLots(node_id): Observable<any> {
    return this.http.delete<any>(
      this.BASE_URL +
        NodeRoutes.BASE_URL +
        NodeRoutes.NODE_LOTS_URL +
        "/" +
        node_id
    );
  }

  getNodeTemplate(detailType): Observable<any> {
    return this.http.get<any>(
      this.BASE_URL + NodeRoutes.NODE_TEMPLATE_URL + "/" + detailType
    );
  }

  getAllNodeTemplates(): Observable<any> {
    return this.http.get<any>(this.BASE_URL + NodeRoutes.NODE_TEMPLATE_URL);
  }

  addMarkerAdd(id, marker): Observable<any> {
    return this.http.post<any>(
      this.BASE_URL + "/Nodes/AddMarker/" + id,
      marker
    );
  }

  getVerifiableMarkers(id) {
    return this.http.get(
      this.BASE_URL + NodeRoutes.BASE_URL + "/GetVerifiableMarkers/" + id
    );
  }

  deleteNode(id) {
    return this.http.delete(this.BASE_URL + NodeRoutes.BASE_URL + "/" + id);
  }

  getNodeProductTypes() {
    return this.http.get(this.BASE_URL + NodeRoutes.BASE_URL + "/product");
  }

  getNodeTypes() {
    return this.http.get(this.BASE_URL + NodeRoutes.BASE_URL + "/nodetype");
  }

  getNodeDetailTypes(product, type) {
    return this.http.get(
      this.BASE_URL +
        NodeRoutes.BASE_URL +
        "/nodedetailtype/" +
        product +
        "/" +
        type
    );
  }

  addNodeTemplate(template) {
    return this.http.post(
      this.BASE_URL + NodeRoutes.NODE_TEMPLATE_URL,
      template
    );
  }

  editNodeTemplate(template) {
    return this.http.put(
      this.BASE_URL + NodeRoutes.NODE_TEMPLATE_URL + "/" + template.id,
      template
    );
  }

  getTemplatesV2(): Observable<any> {
    return this.http.get(this.BASE_URL + NodeRoutes.BASE_URL + "/nodetemplate");
  }

  getNodeAndOrgName(id): Observable<any> {
    return this.http.get(
      this.BASE_URL + NodeRoutes.BASE_URL + "/nodeandorgname/" + id
    );
  }

  getNodeLotsGoogleMapMarkers(node_id: string): Observable<any> {
    return this.http.get(
      this.BASE_URL + NodeRoutes.NODE_LOTS_URL + "/v2/" + node_id + "/locations"
    );
  }
}
