import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadUserImageDialogComponent } from './upload-user-image-dialog.component';
import { MatDialogModule, MatButtonModule, MatIconModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [UploadUserImageDialogComponent],
  entryComponents: [UploadUserImageDialogComponent]
})
export class UploadUserImageDialogModule { }
