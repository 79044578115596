import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, CdkDropList, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'gandalf-inputs',
  templateUrl: './gandalf-inputs.component.html',
  styleUrls: ['./gandalf-inputs.component.css']
})
export class GandalfInputsComponent implements OnInit {

  @Input('cdkDropListConnectedTo') cdkDropListConnectedTo: (CdkDropList | string)[] | CdkDropList | string
  @Input('currentStep') currentStep: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    
  }

  inputs = [
    {
      label: 'date',
      type: 'date',
      icon: 'far fa-calendar-alt',
      optionsRequired: false
    },
    {
      label: 'datetime',
      type: 'datetime-local',
      icon: 'far fa-clock',
      optionsRequired: false
    },
    {
      label: 'upload',
      type: 'fileUpload',
      icon: 'fas fa-file-upload',
      optionsRequired: false
    },
    {
      label: 'number',
      type: 'number',
      icon: 'fas fa-superscript',
      optionsRequired: false
    },
    {
      label: 'radio',
      type: 'radio',
      icon: 'fas fa-dot-circle',
      optionsRequired: true
    },
    {
      label: 'select',
      type: 'select',
      icon: 'far fa-list-alt',
      optionsRequired: true
    },
    {
      label: 'text',
      type: 'text',
      icon: 'fab fa-goodreads-g',
      optionsRequired: false
    }
  ];

}
