import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UpsRoutes } from '../api-routes.enum';
import { UpsData } from '../data/ups';

@Injectable()
export class UpsService extends UpsData {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) {
    super();
  }

  getUpsData() {
    return this.http.get(this.BASE_URL + UpsRoutes);
  }

}
