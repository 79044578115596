import { Component, OnInit, Inject } from '@angular/core';
import { LotService } from '@app/core/services/backend/lot.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BlockchainService } from '@app/core-v2/services';
import { takeWhile, map, shareReplay, retryWhen, delayWhen, tap, take, catchError, delay, concatMap } from 'rxjs/operators';
import { timer, of, iif, throwError } from 'rxjs';
import { Blockchain } from '@app/shared-v2/models/blockchain/blockchain.interface';

@Component({
  selector: 'sa-blockchain-list',
  templateUrl: './blockchain-list.component.html',
  styleUrls: ['./blockchain-list.component.scss']
})
export class BlockchainListComponent implements OnInit {

  private alive = true;

  public blockchains;

  loadingMessage;

  constructor(
    private lotService:LotService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<BlockchainListComponent>,
    private blockchainService: BlockchainService
    ) { }

  ngOnInit() {
    this.blockchainService.getBlockchain(this.data.id)
      .pipe(
        takeWhile(() => this.alive),
        retryWhen(errors => 
          errors.pipe(
            // Use concat map to keep the errors in order and make sure they
            // aren't executed in parallel
            concatMap((e, i) => {
              // Executes a conditional Observable depending on the result
              // of the first argument
              this.loadingMessage = `Retry attempt ${i + 1}...`;
              return iif(
                () => i > 2,
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                of(e).pipe(delay(1000))
              )
            })
          )
        ),
        map((blockchain: Blockchain) => {
          blockchain.Blockchains.forEach((blkChn) => {
            blkChn.BlockchainData = JSON.parse(blkChn.BlockchainData);
            return blkChn; 
          });
          return blockchain;
        })
      )
      .subscribe(
        res => {
          this.blockchains = res;
          console.log('blockchains: ', this.blockchains);
        },
        error => {
          this.blockchains = [];
        }
      )
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}
