import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NespressoLayoutV2Component } from './app-layouts/nespresso-layout-v2.component';
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { HeaderModule } from "./header/header.module";
import { FooterComponent } from "./footer/footer.component";
import { NavigationModule } from "./navigation/navigation.module";
import { RibbonComponent } from "./ribbon/ribbon.component";
import { ShortcutComponent } from "./shortcut/shortcut.component";
import { ToggleActiveDirective } from "../utils/toggle-active.directive";
import { LayoutSwitcherComponent } from "./layout-switcher.component";
import { MainLayoutComponent } from './app-layouts/main-layout.component';
import { MainLayoutV2Component, SideTabScrollDirective } from './app-layouts/main-layout-v2.component';
import { EmptyLayoutComponent } from './app-layouts/empty-layout.component';
import { RouterModule } from "@angular/router";
import { AuthLayoutComponent } from './app-layouts/auth-layout.component';
import { TooltipModule, BsDropdownModule } from "ngx-bootstrap";
import { RouteBreadcrumbsComponent } from './ribbon/route-breadcrumbs.component';
import { UtilsModule } from "../utils/utils.module";
import { NespressoLayoutComponent } from "./app-layouts/nespresso-layout.component";
import { NovariLayoutComponent } from './app-layouts/novari-layout.component';

import { LayoutModule } from '@angular/cdk/layout';

import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from '@angular/material/icon';
import { ThemeModule } from '@app/@theme/theme.module';
import { HelpService } from "@app/core/services/backend/help.service";
import { FeedbackModule } from '@app/shared-v2/feedback/feedback.module';
import { MatSnackBarModule, MatBadgeModule, MatTooltipModule, MatFormFieldModule, MatChipsModule } from '@angular/material';
import { NotificationsModule } from '@app/shared-v2/notifications/notifications.module';
import { QuicklinksModule } from '@app/shared-v2/quicklinks/quicklinks.module';

const LAYOUT_DIRECTIVES = [
  SideTabScrollDirective
]

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    NavigationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UtilsModule,
    TooltipModule,
    BsDropdownModule,
    LayoutModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatChipsModule,
    ThemeModule,
    FeedbackModule,
    NotificationsModule,
    QuicklinksModule
  ],
  declarations: [
    ...LAYOUT_DIRECTIVES,
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,
    MainLayoutComponent,
    MainLayoutV2Component,
    NovariLayoutComponent,
    EmptyLayoutComponent,
    AuthLayoutComponent,
    NespressoLayoutComponent,
    NespressoLayoutV2Component,
    RouteBreadcrumbsComponent
  ],
  providers: [HelpService],
  exports:[
    HeaderModule,
    NavigationModule,
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent
  ]
})
export class SmartadminLayoutModule{

}
