import { UtilsModule } from '@app/shared/utils/utils.module';
import { BootstrapModule } from '@app/shared/bootstrap.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from "@app/shared/pipes/pipes.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageRotationDirective } from '@app/shared/utils/image-rotation.directive';
import { ImageGalleryComponent } from './image-gallery.component';
import { UploadService } from '@app/core/services/backend/uploads.service';
import { LocationService } from '@app/core/services/backend/locations.service';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    UtilsModule,
    ReactiveFormsModule,
    BootstrapModule,
  ],
  declarations: [ImageGalleryComponent],
  exports: [ImageGalleryComponent],
  providers: [
    UploadService,
    LocationService
  ]
})
export class ImageGalleryModule { }
