import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { WizardService } from '@app/core/services/backend/wizard.service';
import { CountrySelectionOptions } from '@app/shared/location/country-state/list';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'bext-gandalf',
  templateUrl: './gandalf.component.html',
  styleUrls: ['./gandalf.component.css']
})

export class GandalfComponent {

  wizardStepSequenceCounter = 0;
  @Input('disableJsonViewer') disableJsonViewer = false;
  @Output('innerModalOpened') innerModalOpened = new EventEmitter<any>();
  @ViewChild('addNewStepModal') addNewStepModal;
  @ViewChild('initWizardModal') initWizardModal;
  @ViewChild('deleteStepModal') deleteStepModal;
  @ViewChild('editStepModal') editStepModal;
  @ViewChild('createInputModal') createInputModal;
  @ViewChild('editInputModal') editInputModal;
  wizardForm: FormGroup;
  wizardsList: any;
  selectedWizardInstance: any;
  stepForm: FormGroup;
  inputForm: FormGroup;
  optionsForm: FormGroup;
  currentStepInputForm: FormGroup;
  currentStepInputEditForm: FormGroup;
  // currentStepInputEditAdditionalOptions: any[] = [];
  currentInputData: any;
  currentInputDateField: any;
  selectedStep: any;
  selectedStepInputs: any;
  inputTextFieldPreview: any;
  newStepForm: FormGroup;
  inputOptions: FormArray;
  inputOptionEnableDiffValue = false;
  wizardInstanceData: any;
  wizardUpdating = false;
  stepUpdating = false;
  inputFieldUpdating = false;
  simpleWizardInstanceData: any;
  isSimpleExample = false;
  currentTab = 'steps';
  confirmationModalBody = {
    title: {beginText: '',boldText: '',endText: ''},message: ''};

  readonly wizardInstanceInputs: any[] = []

  constructor(private fb: FormBuilder, private wizardService: WizardService) { }

  ngOnInit() {
    this.getWizardsList();
    this.wizardForm = this.createWizardForm();
    // console.log('wizard step datas array: ', this.wizardStepDatas);
    this.newStepForm = this.fb.group({
      'stepName': new FormControl('', [Validators.required]),
      'inputType': new FormControl('', [Validators.required]),
      'inputLabel': new FormControl('', [Validators.required]),
      'inputOptions': this.fb.array([])
    });

  }

  createWizardForm(): FormGroup {
    return this.fb.group({
      "WizardInstanceStatus": new FormControl(''),
      "type": new FormControl(''),
      "WizardFrontEnd": this.fb.group({
        "WizardUserAction": this.fb.group({
          "WizardActionType": new FormControl('TEST')
        }),
        "WizardMetaData": this.fb.group({
          "WizardName": new FormControl('', [Validators.required]),
          "WizardCurrentSequenceStep": new FormControl(0),
          "WizardIcon": new FormControl('TEST'),
          "WizardType": new FormControl('TEST')
        }),
        "WizardStepDatas": this.fb.array([])
      })
    });    
  }

  createStepForm(sequence, stepMetaData?, inputFields?, stepActions?): FormGroup {
    return this.fb.group({
      "WizardStepMetaData": this.fb.group({
        "Sequence": new FormControl(sequence),
        "StepName": new FormControl(stepMetaData ? stepMetaData.StepName : '', [Validators.required]),
        "StepIcon": new FormControl(stepMetaData ? stepMetaData.StepIcon : 'TEST'),
        "StepColor": new FormControl(stepMetaData ? stepMetaData.StepColor : 'TEST'),
        "StepDisplayStatus": new FormControl(stepMetaData ? stepMetaData.StepDisplayStatus : 'TEST'),
        "StepConditions": this.fb.array([])
      }),
      "WizardStepFields": this.fb.array([this.createStepFieldsForm(inputFields)]),
      "WizardStepActions": this.fb.array([
        this.fb.group({
          "Action": new FormControl(stepActions && stepActions.length === 2 ? stepActions[0].Action : 'PREV'),
          "WizardActionLocation": new FormControl(stepActions && stepActions.length === 2 ? stepActions[0].WizardActionLocation : 'LEFT'),
          "ActionClass": new FormControl(stepActions && stepActions.length === 2 ? stepActions[0].ActionClass : 'btn btn-sm btn-primary btn-prev'),
          "IconClass": new FormControl(stepActions && stepActions.length === 2 ? stepActions[0].IconClass : 'fa fa-arrow-left')
        }),
        this.fb.group({
          "Action": new FormControl(stepActions && stepActions.length === 2 ? stepActions[1].Action : 'NEXT'),
          "WizardActionLocation": new FormControl(stepActions && stepActions.length === 2 ? stepActions[1].WizardActionLocation : 'RIGHT'),
          "ActionClass": new FormControl(stepActions && stepActions.length === 2 ? stepActions[1].ActionClass : 'btn btn-sm btn-success btn-next'),
          "IconClass": new FormControl(stepActions && stepActions.length === 2 ? stepActions[1].IconClass : 'fa fa-arrow-right')
        })
      ])
    });
  }

  createConditionForm(dependentStepSequenceNumber): FormGroup {
    return this.fb.group({
      "StepSequence": new FormControl(dependentStepSequenceNumber),
      "StepConditionFields": this.fb.array([])
    })
  }

  createStepFieldsForm(fields?): FormGroup {

    if (!fields || !fields.length) {
      return this.fb.group({
        "Fields": this.fb.array([])
      });
    }
    else {
      let form = this.fb.group({"Fields": this.fb.array([])});
      let stepFieldsFormArray = form.get('Fields') as FormArray;

      fields.forEach(field => {
        let inputForm = this.createInputForm();
        if (field.Fields.length) {
          field.Fields.forEach(subField => {
            if (subField.options.length) {
              subField.options.forEach(option => {
                let inputOptions = inputForm.get('options') as FormArray;
                console.log('option: ', option);
                let optionForm = this.createOption(option);
                inputOptions.push(optionForm);
              });
            }
          });
        }
        inputForm.patchValue(field);
        stepFieldsFormArray.push(inputForm);
      });
      return form as FormGroup;
    }

  }

  createInputForm(): FormGroup {
    return this.fb.group({
      "name": new FormControl(''),
      "humanName": new FormControl(''),
      "label": new FormControl(''),
      "type": new FormControl(''),
      "value": new FormControl(''),
      "validation": this.fb.group({
        "create": this.fb.group({
          "required": new FormControl(false)
        }),
        "analysis": this.fb.group({
          "required": new FormControl(true)
        }),
        "transfer": this.fb.group({
          "required": new FormControl(true)
        })
      }),
      "visible": new FormControl(true),
      "options": this.fb.array([])
    })
  }

  createOption(option?): FormGroup {
    return this.fb.group({
      'label': new FormControl(option ? option.label : '', [Validators.required, Validators.minLength(1)]),
      'value': new FormControl(option ? option.value : '')
    });
  }
  
  createCondition() {
    return this.fb.group({
      "FieldName": new FormControl(''),
      "Value": new FormControl('')
    })
  }

  getWizardsList() {
    this.wizardService.getWizards().subscribe(
      res => {
        console.log('wizards: ', res);
        this.wizardsList = res;
        return;
      },
      error => {
        console.log('error: ', error);

        return;
      },
      () => {
        // console.log('--- subscription complete ---');

      }
    );
  }

  getExistingWizard(modalRef, wizard) {
    console.log('wizard obj: ', wizard);
    this.wizardService.getWizardById(wizard.id).subscribe(
      res => {
        console.log('response: ', res);
        this.selectedWizardInstance = res;
        let form = this.fb.group({
          "DateCreated": new FormControl(''),
          "DateModified": new FormControl(''),
          "Deleted": new FormControl(''),
          "LocationId": new FormControl(''),
          "OwnerOrganizationId": new FormControl(''),
          "UiData": new FormControl(''),
          "id": new FormControl(''),
          "WizardInstanceStatus": new FormControl(''),
          "type": new FormControl(''),
          "WizardFrontEnd": this.fb.group({
            "WizardUserAction": this.fb.group({
              "WizardActionType": new FormControl('')
            }),
            "WizardMetaData": this.fb.group({
              "WizardName": new FormControl(''),
              "WizardCurrentSequenceStep": new FormControl(''),
              "WizardIcon": new FormControl(''),
              "WizardType": new FormControl('')
            }),
            "WizardStepDatas": this.fb.array([])
          })
        });

        res.WizardFrontEnd.WizardStepDatas.forEach(step => {
          let wizardStepDatas = form.get('WizardFrontEnd').get('WizardStepDatas') as FormArray;
          wizardStepDatas.push(this.createStepForm(step.WizardStepMetaData.Sequence, step.WizardStepMetaData, step.WizardStepFields, step.WizardStepActions))
        });
        form.patchValue(res);
        this.wizardForm = form;
        this.wizardUpdating = true;
        this.wizardInstanceData = this.wizardForm.value;
        this.selectedStep = { name: this.wizardStepDatas.value.StepName, index: this.WizardCurrentSequenceStep.value, wizardFormSteps: this.wizardStepDatas }
        this.wizardUpdating = false;
        return;
       },
      error => {
        console.log('error: ', error);
        
        return;
      },
      () => {
        console.log('--- subscription complete ---');
        this.closeModal(modalRef);
      }
    );
    
  }

  get wizardMetaData() {
    return this.wizardForm.get('WizardFrontEnd').get('WizardMetaData') as FormGroup;
  }

  get WizardCurrentSequenceStep() {
    return this.wizardForm.get('WizardFrontEnd').get('WizardMetaData').get('WizardCurrentSequenceStep') as FormGroup;
  }

  get wizardStepDatas() {
    return this.wizardForm.get('WizardFrontEnd').get('WizardStepDatas') as FormArray;
  }

  get wizardStepDatasForDragDrop() {
    return this.wizardForm.get('WizardFrontEnd').get('WizardStepDatas') as any;
  }

  get selectedStepInputFields() {
    return this.selectedStep.wizardFormSteps.controls[this.selectedStep.index + ''].controls.WizardStepFields.controls[this.selectedStep.index + '']
  }

  get inputOptionsArray() {
    return this.newStepForm.get('inputOptions') as FormArray;
  }

  get wizardInstanceDataStepDatasArray() {
    return this.wizardInstanceData.WizardFrontEnd.WizardStepDatas;
  }

  openModal(modalRef, options?) {
    let opts = {
      ignoreBackdropClick: options ? options.ignoreBackdropClick : false,
      enableEscBtn: options ? options.enableEscBtn : true
    };
    modalRef.config.ignoreBackdropClick = opts.ignoreBackdropClick;
    modalRef.config.keyboard = opts.enableEscBtn;
    modalRef.show()
  }

  closeModal(modalRef) {
    modalRef.hide()
  }

  initializeWizard() {
    this.initWizardModal.show();
  }

  revertForm(modalRef?) {
    if (modalRef) {
      this.wizardForm.patchValue(this.wizardInstanceData);
      modalRef.hide();
    } else {
      this.wizardForm.patchValue(this.wizardInstanceData);
    }
  }

  inputReorderDrop(event: CdkDragDrop<string[]>) {
    // moveItemInArray(this.selectedStep.wizardFormSteps.controls[this.selectedStep.index].controls.WizardStepFields.controls['0'].controls.Fields.controls, event.previousIndex, event.currentIndex);
    
    console.log('wizard form step fields: ', this.selectedStep.wizardFormSteps.controls[this.selectedStep.index].controls.WizardStepFields.controls['0'].controls.Fields);
    console.log('wizard form: ', this.wizardForm);
    console.log('wizard form value: ', this.wizardForm.value);
    this.updateView(this.wizardForm.value);
  }

  inputDropped(event: CdkDragDrop<string[]>) {
    this.currentInputData = event.item.data;
    this.currentStepInputForm = this.createInputForm() as FormGroup;
    this.currentStepInputForm.get('type').setValue(this.currentInputData.input.type);
    let inputFormOptionsArray = <FormArray>this.currentStepInputForm.controls.options;

    if (this.currentInputData.input.type === 'select' || this.currentInputData.input.type === 'radio') {
      let initalizeOptions = this.createOption();
      inputFormOptionsArray.push(initalizeOptions);
    }

    // console.log('current step input form controls: ', this.currentStepInputForm);
    this.openModal(this.createInputModal, { ignoreBackdropClick: true, enableEscBtn: false });

    inputFormOptionsArray.valueChanges.subscribe(
      res => {
        res.forEach(option => {
          if (!this.inputOptionEnableDiffValue) {
            option.value = option.label
          }
        });
        return;
       },
      error => {
        // console.log('error: ', error);
        
        return;
      },
      () => {
        // console.log('--- subscription complete ---');
        
      }
    );
  }

  selectInput(inputFormGroup) {
    console.log('input form group: ', inputFormGroup);
  }

  deleteInput(idx, form) {
    var selectedInputForm = form as FormArray;
    console.log('form: ', selectedInputForm);
    selectedInputForm.removeAt(idx);
    this.updateWizardInstanceDataView();
  }

  removeInputStepOption(idx, formArray?) {
    if (!formArray) {
      var selectedInputForm = this.currentStepInputForm.get('options') as FormArray;
    }
    else {
      var selectedInputForm = formArray as FormArray;
    }
    selectedInputForm.removeAt(idx);
  }

  addInputStepOption(form) {
    let selectedInputForm = form as FormArray;
    let option = this.createOption();
    selectedInputForm.push(option);
  }

  addStepFieldInput() {
    this.currentStepInputForm['submitted'] = true;
    if (this.currentStepInputForm.invalid) {
      return;
    }
    else {
      let currentStepFieldsArray = this.wizardForm.get('WizardFrontEnd').get('WizardStepDatas').get(this.selectedStep.index + '').get('WizardStepFields').get('0').get('Fields') as FormArray;
      let humanName = this.currentStepInputForm.get('humanName') as FormGroup;
      this.currentStepInputForm.get('label').setValue(humanName.value);
      this.currentStepInputForm.get('name').setValue(humanName.value.split(' ').join('') + '.Measure');
      currentStepFieldsArray.push(this.currentStepInputForm);
      this.updateWizardInstanceDataView();
      this.closeModal(this.createInputModal);
      this.inputOptionEnableDiffValue = false;
    }
  }

  editStepFieldInput(form) {
    this.currentStepInputEditForm = form as FormGroup;
    this.inputFieldUpdating = true;
    this.openModal(this.editInputModal, { ignoreBackdropClick: true, enableEscBtn: false });
  }

  saveStepFieldInputChanges() {
    this.currentStepInputEditForm['submitted'] = true;
    if (this.currentStepInputEditForm.invalid) {
      return;
    } else {
      this.currentStepInputEditForm.get('label').setValue(this.currentStepInputEditForm.get('humanName').value);
      this.currentStepInputEditForm.get('name').setValue((this.currentStepInputEditForm.get('humanName').value.split(' ').join('') + '.Measure'));
      let options = this.currentStepInputEditForm.get('options') as FormArray;
      this.setOptionValueField(options.controls);
      this.updateWizardInstanceDataView();
      this.inputFieldUpdating = false;
      this.inputOptionEnableDiffValue = false;
      this.closeModal(this.editInputModal);
    }
  }

  setOptionValueField(formControlsArray) {
    formControlsArray.forEach((option) => {
      let label = option.controls.label as FormGroup;
      let value = option.controls.value as FormGroup;
      if (!value.value) {
        value.setValue(label.value);
      }
    });
    console.log('form controls array: ', formControlsArray);
  }

  cancelStepFieldInputChanges() {
    this.wizardForm.patchValue(this.wizardInstanceData);
    this.closeModal(this.editInputModal);
    this.inputOptionEnableDiffValue = false;
  }

  defaultMAF(modalRef, action, form?) {
    if (action === 'reset') {
      form.reset();
      this.closeModal(modalRef);
    }
    else if (action === 'revert') {
      this.wizardForm.patchValue(this.wizardInstanceData);
      this.closeModal(modalRef);
    } 
    else {
      this.wizardUpdating = true;
      // console.log('%c wizard form: ', 'background: #41ff6b; color: #ff4700;', form.value);
      this.updateView(form.value);
      this.closeModal(modalRef);
    }
  }

  addNewStepMAF(modalRef, action, form) {
    if (action !== 'submit') {
      form.reset();
      // this.stepForm = undefined;
      this.closeModal(modalRef);
    } else {
      this.wizardUpdating = true;
      this.wizardStepDatas.push(form)
      // console.log('wizard form: ', this.wizardForm);
      this.updateView(this.wizardForm.value);
      this.wizardStepSequenceCounter++;
      this.stepForm = undefined;
      if (this.wizardStepDatas.length === 1) {
        console.log('wizardStepDatas: ', this.wizardStepDatas);
        this.selectedStep = { name: this.wizardStepDatas.value.StepName, index: 0, wizardFormSteps: this.wizardStepDatas }
      }
      this.closeModal(modalRef);
    }
  }

  addNewStep() {
    this.stepForm = this.createStepForm(this.wizardStepSequenceCounter);
    this.addNewStepModal.show();
  }

  moveStep(direction, step) {
    console.log('wizardInstanceData: ', this.wizardInstanceData);
    console.log('step: ', step);
  }

  editStepMAF(modalRef, action, form) {
    if (action !== 'submit') {
      debugger;
      form.reset();
      this.closeModal(modalRef);
    } else {
      this.wizardUpdating = true;
      this.updateView(this.wizardForm.value);
      this.closeModal(modalRef);
    }
  }

  deleteStep(o) {
    this.closeModal(this.deleteStepModal)
    this.wizardStepDatas.removeAt(o.index);
    this.updateView(this.wizardForm.value, true, 0);
    this.wizardStepSequenceCounter--;
  }

  resetInputOptions() {
    while (this.inputOptionsArray.length !== 0) {
      this.inputOptionsArray.removeAt(0)
    }
  }

  sendAction(e) {
    console.log('%c send action $event: ', 'background: #41ff6b; color: #ff4700;', e);
  }

  setCurrentTab(tab) {
    this.currentTab = tab;
  }

  updateWizardInstanceDataView() {
    this.wizardUpdating = true;
    this.wizardInstanceData = this.wizardForm.value;
    this.wizardUpdating = false;
  }

  updateView(data, resetSequence?, currentIdx?) {
    // console.log('update wizard Instance data: ', data);
    this.wizardUpdating = true;
    this.wizardInstanceData = data;
    if (resetSequence) {
      this.wizardInstanceDataStepDatasArray.forEach((step,i) => {
        step.WizardStepMetaData.Sequence = i;
      });
      if (!this.wizardInstanceDataStepDatasArray.length) {
        this.selectedStep = undefined;
        this.wizardForm.patchValue(this.wizardInstanceData);
      }
      else {
        this.wizardInstanceData.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep = currentIdx;
        this.wizardForm.patchValue(this.wizardInstanceData);
        this.selectedStep = { name: this.wizardInstanceData.WizardFrontEnd.WizardStepDatas[currentIdx].WizardStepMetaData.StepName, index: this.wizardInstanceData.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep, data: this.wizardInstanceData.WizardFrontEnd.WizardStepDatas[currentIdx] }
      }
    }
    this.setLastStepActionToFinish(this.wizardInstanceData);
    this.wizardUpdating = false;
  }

  patchForm(wizardInstance) {
    this.wizardForm.patchValue(wizardInstance);
  }

  setLastStepActionToFinish(wid) {
    // console.log('wizard instance data: ', wid);
    wid.WizardFrontEnd.WizardStepDatas.forEach((step, i, arr) => {
      if (i == arr.length - 1) {
        step.WizardStepActions[1].Action = 'FINISH';
      }
      else {
        step.WizardStepActions[1].Action = 'NEXT';
      }
    });
    // console.log('wizard instance data: ', wid);
  }

  getStepBySeqeunceNumber(sn) {
    let obj = this.wizardInstanceDataStepDatasArray.find(step => sn === step.Sequence);
    console.log('step found: ', obj);
  }

  setWizardCurrentStepSequence(idx) {
    this.wizardInstanceData.WizardFrontEnd.WizardMetaData.WizardCurrentSequenceStep = idx;
    this.updateView(this.wizardInstanceData);
  }

  getTimeStamp() {
    // e.stopPropagation();
    let date = new Date();
    // console.log('%c get time stamp: ', 'background: #41ff6b; color: #ff4700;', this.date);
    this.currentInputDateField = date;
    // this.currentInputDateField = date.getFullYear() + '-' + ((date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1)) + '-' + ((date.getDate() < 10 ? '0' : '') + date.getDate()) + 'T' + (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + ((date.getMinutes() < 10 ? '0' : '') + date.getMinutes());
  }

  openConfirmationModal(modalRef, opts) {
    this.confirmationModalBody = {
      title: {
        beginText: opts.beginText,
        boldText: opts.boldText,
        endText: opts.endText
      },
      message: opts.message,
    };
    this.openModal(modalRef, {ignoreBackdropClick: true, enableEscBtn: true})
  }

  saveWizard() {
    let payload = {
      WizardFrontEnd: this.wizardForm.value.WizardFrontEnd
    }
    console.log('wizard payload: ', payload);
    this.wizardService.addWizard(this.wizardForm.value).subscribe(
      res => {
        console.log('POST wizard successful: ', res);
        
        return;
       },
      error => {
        console.log('error POST failed to create wizard: ', error);
        
        return;
      },
      () => {
        // console.log('--- subscription complete ---');
        
      }
    );
  }

  updateWizard() {
    let payload = {
      WizardFrontEnd: this.wizardForm.value.WizardFrontEnd
    }
    console.log('wizard payload: ', payload);
    // this.wizardService.addWizard(this.wizardForm.value).subscribe(
    //   res => {
    //     console.log('POST wizard successful: ', res);
        
    //     return;
    //    },
    //   error => {
    //     console.log('error POST failed to create wizard: ', error);
        
    //     return;
    //   },
    //   () => {
    //     // console.log('--- subscription complete ---');
        
    //   }
    // );
  }

  resetWizardInstance(modalRef) {
    this.getWizardsList();
    this.wizardForm = this.createWizardForm();
    this.newStepForm = this.fb.group({
      'stepName': new FormControl('', [Validators.required]),
      'inputType': new FormControl('', [Validators.required]),
      'inputLabel': new FormControl('', [Validators.required]),
      'inputOptions': this.fb.array([])
    });
    this.selectedWizardInstance = undefined;
    this.selectedStep = undefined;
    this.wizardInstanceData = undefined;
    this.closeModal(modalRef)
  }

}
