import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sa-nespresso-layout-v2',
  templateUrl: './nespresso-layout-v2.component.html',
  styles: ['body.smart-style-0.fixed-header.fixed-navigation.fixed-ribbon.menu-on-top.desktop-detected, body.smart-style-0.fixed-header.fixed-navigation.fixed-ribbon.menu-on-top.desktop-detected.mobile-view-activated, body, html {padding: 0 !important; margin: 0 !important; background: transparent !important;}'],
  encapsulation: ViewEncapsulation.None
})
export class NespressoLayoutV2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
