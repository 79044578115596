import { Injectable } from '@angular/core';
import * as _ from 'lodash';

export interface ColumnInfo {
  id: string;
  name: string;
  hidden: boolean;
  preventBeingHidden?: any;
}

const SAVED_REPORTS_LOCALSTOAGE_KEY = 'bcrs_column_definitions';

@Injectable()
export class ColumnSorterService {
  loadSavedColumnInfo(columnInfo: ColumnInfo[], saveName?: string): ColumnInfo[] {
    // Only load if a save name is passed in
    if (saveName) {
      if (!localStorage) {
        return;
      }

      const loadedInfo = localStorage.getItem(`${saveName}`);

      let missingInfo = _.differenceBy(columnInfo, JSON.parse(loadedInfo), 'id');

      if (loadedInfo && missingInfo.length) {
        let newColumnInfo = JSON.parse(loadedInfo).concat(missingInfo);
        console.log('new columnInfo: ', newColumnInfo);
        this.saveColumnInfo(newColumnInfo, saveName);
        return newColumnInfo;
      }
      else if (loadedInfo && !missingInfo.length) {
        console.log('old columnInfo: ', JSON.parse(loadedInfo));
        return JSON.parse(loadedInfo);
      }
      else {
        console.log('no previous local storage columnInfo - create new using: ', columnInfo);
        this.saveColumnInfo(columnInfo, saveName);
        return columnInfo;
      }

    }
  }

  saveColumnInfo(columnInfo: ColumnInfo[], saveName?: string): void {
    // Only save if a save name is passed in
    if (saveName) {
      if (!localStorage) {
        return;
      }
      let saved_reports: any = localStorage.getItem(SAVED_REPORTS_LOCALSTOAGE_KEY) !== null ? localStorage.getItem(SAVED_REPORTS_LOCALSTOAGE_KEY) : '[]';
      saved_reports = JSON.parse(saved_reports);
      if (!saved_reports.some(r => r === saveName)) {
        saved_reports.push(saveName);
      }
      localStorage.setItem(SAVED_REPORTS_LOCALSTOAGE_KEY, JSON.stringify(saved_reports))
      localStorage.setItem(`${saveName}`, JSON.stringify(columnInfo));
    }
  }

  clearColumnInfo() {
    let saved_reports: any = JSON.parse(localStorage.getItem(SAVED_REPORTS_LOCALSTOAGE_KEY));
    if (saved_reports === null) return;
    else {
      saved_reports.forEach((reportName) => {
        localStorage.removeItem(reportName)
      });
      return;
    }
  }
}