import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ReportRoutes } from '../api-routes.enum';
import { CapsuleReportData, CapsulesWithZipCode, CapsuleReports, CapsuleRecords } from '../data/capsule-report';
import { map } from 'rxjs/operators';

@Injectable()
export class CapsuleReportService extends CapsuleReportData {

  BASE_URL = environment.baseApiUrl;

  constructor(private http: HttpClient) {
    super();
  }

  getCapsulesWithZipCodes(): Observable<CapsulesWithZipCode[]> {
    return this.http.get<CapsulesWithZipCode[]>(`${this.BASE_URL}${ReportRoutes.BASE_URL}${ReportRoutes.NESPRESSO_CAPSULE_ZIP_CODE_REPORT_URL}`);
  }

  getCapsulesWithZipCodesV2(): Observable<CapsulesWithZipCode[]> {
    return this.http.get<CapsulesWithZipCode[]>(`${this.BASE_URL}${ReportRoutes.BASE_URL}${ReportRoutes.NESPRESSO_CAPSULE_ZIP_CODE_REPORT_URL}v2`);
  }

  getCapsuleReport(reportType, id): Observable<CapsuleReports[]> {
    return this.http.get<CapsuleReports[]>(`${this.BASE_URL}${ReportRoutes.BASE_URL}${ReportRoutes.NESPRESSO_CAPSULE_REPORT_URL}/${reportType}/${id}`)
  }

  getCapsuleReportV2(reportType, id): Observable<CapsuleReports[]> {
    return this.http.get<CapsuleReports[]>(`${this.BASE_URL}${ReportRoutes.BASE_URL}${ReportRoutes.NESPRESSO_CAPSULE_REPORT_URL}/${reportType}/${id}`)
  }

  getCapsuleRecords(reportType, id): Observable<CapsuleRecords[]> {
    return this.http.get<CapsuleRecords[]>(`${this.BASE_URL}${ReportRoutes.BASE_URL}${ReportRoutes.NESPRESSO_CAPSULE_RECORDS_URL}/${reportType}/${id}`)
  }

  getCapsuleRecordsV2(reportType, id): Observable<CapsuleRecords[]> {
    return this.http.get<CapsuleRecords[]>(`${this.BASE_URL}${ReportRoutes.BASE_URL}${ReportRoutes.NESPRESSO_CAPSULE_RECORDS_URL}/${reportType}/${id}`)
  }

}