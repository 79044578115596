import { Component, OnInit, Input } from '@angular/core';
import { SustainabilityService } from '@app/core/services/backend/sustainability.service';
import { NodeService } from '@app/core/services/backend/node.service';

@Component({
  selector: 'sus-group-builder-tree',
  templateUrl: './sus-group-tree.component.html',
  styleUrls: ['./sus-group-tree.component.css']
})
export class SusGroupBuilderTreeComponent implements OnInit {
  baseSet;
  @Input() baseGroup: any = { subGroups: [] }
  @Input() id = '';
  constructor(private susService: SustainabilityService, private nodeService: NodeService) { }


  ngOnInit() {

  }

  setBaseGroup(set) {
    this.baseSet = set;
  }

  addSpecification(set) {
    this.baseSet = set;
    this.baseSet.subGroups.push({
      name: '',
      level: '',
      description: '',
      specification: true
    });
  }

  addGroup(set) {
    this.baseSet = set;
    this.baseSet.subGroups.push(new SustainabilityGroup('', '', ''))
  }

  addLevel() {
    this.susService.addLevel(
      [{
        "SustainabilityGroupId": "34f80b59-7872-493e-a58e-221997c34f2c",
        "LevelName": "Alex SUS 1.1.1",
        "LevelType": "Indicator",
        "Description": "Alex New Level Description 1.1.1",
        "Level": "1.1.1",
        "ParentLevel": "1.1",
        SustainabilityMeasureGoals: []
      }]
    ).subscribe(res => {
      console.log(res);
    })
  }

  save(set) {
    this.susService.addLevel([
      {
        "SustainabilityGroupId": this.id,
        "LevelName": set.name,
        "LevelType": "Indicator Group",
        "Description": set.description,
        "Level": set.level,
        "ParentLevel": this.baseGroup.level
      }]
    ).subscribe(res => {
      console.log(res)
    })
  }

  addCriteria(set) {
    this.baseSet = set;
    let testPayload = [
      {
        "SustainabilityGroupId": this.id,
        "LevelType": "Specification",
        "Description": set.description,
        "Level": set.level,
        "ParentLevel": this.baseGroup.level
      }
    ];
    this.susService.addCriteria(testPayload).subscribe((res) => {
      console.log(res);
    })
  }
}


export class SustainabilityGroup {
  public name;
  public description;
  public level;
  public products;
  public subGroups = [];
  constructor(name: string, description: string, level: string, products?: Array<any>) {
    this.name = name;
    this.description = description;
    this.level = level;
    this.products = products || ['All'];
  }
}
