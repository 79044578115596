import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatOptionModule,
  MatFormFieldModule,
  MatRadioModule
} from '@angular/material';

import { FeedbackDirective } from './feedback.directive';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { FeedbackRectangleComponent } from './feedback-rectangle/feedback-rectangle.component';
import { FeedbackToolbarComponent } from './feedback-toolbar/feedback-toolbar.component';

const FEEDBACK_COMPONENTS = [
  FeedbackDialogComponent,
  FeedbackRectangleComponent,
  FeedbackToolbarComponent
];

const FEEDBACK_DIRECTIVES = [
  FeedbackDirective
];

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    MatRadioModule
  ],
  declarations: [
    ...FEEDBACK_COMPONENTS,
    ...FEEDBACK_DIRECTIVES
  ],
  exports: [
    ...FEEDBACK_DIRECTIVES
  ],
  entryComponents: [
    FeedbackDialogComponent
  ]
})
export class FeedbackModule { }
