import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NodeImagesComponentV2 } from './images/images.component';
import { SharedModule } from '@app/shared/shared.module';
import {
  MatDialogModule,
  MatCardModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatButtonModule,
  MatTreeModule,
  MatStepperModule,
  MatIconModule,
  MatSelectModule,
  MatCheckboxModule,
  MatGridListModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatListModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { NodeDocumentsComponentV2 } from './documents/documents.component';
import { DocumentService } from '@app/core/services/backend/document.service';
import { WizardService } from '@app/core/services/backend/wizard.service';
import { CreateNodeTemplateV2Component } from './templates/create-node-template/create-node-template-v2.component';
import {HelpService} from "@app/core/services/backend/help.service";
import {ProductsService} from "@app/core/services/backend/product.service";
import {DynamicFormModule} from "@app/shared-v2/dynamic-form/dynamic-form.module";

@NgModule({
  declarations: [NodeImagesComponentV2, NodeDocumentsComponentV2, CreateNodeTemplateV2Component],
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
    MatCardModule,
    NgxSpinnerModule,
    MatCardModule,
    MatButtonModule,
    MatTreeModule,
    MatStepperModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatGridListModule,
    MatInputModule,
    MatRadioModule,
    MatFormFieldModule,
    MatTreeModule,
    MatListModule,
      MatProgressSpinnerModule,
      DynamicFormModule
  ],
  entryComponents: [NodeImagesComponentV2, NodeDocumentsComponentV2],
  providers: [WizardService, {provide: MatDialogRef, useValue: {}}, {provide: MAT_DIALOG_DATA, useValue: {}}, NgxSpinnerService, DocumentService, HelpService, ProductsService],
  exports: [NodeImagesComponentV2, NodeDocumentsComponentV2, CreateNodeTemplateV2Component]
})
export class NodesSharedV2Module { }
