import { Component, OnInit, AfterContentInit } from '@angular/core';
import { LocationService } from '@app/core/services/backend/locations.service';
import { AppUserService } from '@app/core/services/backend/app-user.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { OrganizationService } from '@app/core/services/backend/organization.service';
import { MatDialogRef, MatSnackBar } from "@angular/material";
import { Overlay } from "@angular/cdk/overlay";
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'bext-add-user',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})

export class AddUserComponent implements OnInit {
  private alive = true;
  public form: any;
  public organizations: any = [];
  public appUserPermissions = [{
    "Action": "AppUsersGet",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "AppUsersAdd",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "AppUsersUpdate",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "AppUsersDelete",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "LocationsGet",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "LocationsAdd",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "LocationsUpdate",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "LocationsDelete",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "OrganizationsGet",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "OrganizationsAdd",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "OrganizationsUpdate",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "OrganizationsDelete",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "SupplyChainsGet",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "SupplyChainsAdd",
    "Description": null,
    "Permission": 2,
    "read": true
  },
  {
    "Action": "SupplyChainsDelete",
    "Description": null,
    "Permission": 2,
    "read": true
  }, {
    "Action": "Base",
    "Description": null,
    "Permission": 2,
    "read": true
  }];

  isCreatingNewUser = false;
  isLoading = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private appUserService: AppUserService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddUserComponent>
  ) { }
  // remove brackets?

  get addFormGroup() {
    return this.form.get('addForm') as FormGroup;
  }

  ngOnInit() {
    // const overlayRef = this.overlay.create({
    //     height: '400px',
    //     width: '600px',
    // });
    this.isLoading = true;

    this.organizationService.getOrganizations().subscribe(res => {
      console.log('%c organizations: ', 'background: #41ff6b; color: #ff4700;', res);
      this.organizations = res;
      this.isLoading = false;
    });

    this.form = this.fb.group({
      addForm: this.fb.group({
        Name: this.fb.control('', [Validators.required]),
        Password: this.fb.control('',
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
              hasSpecialCharacters: true
            }),
            Validators.minLength(8)
          ])
        ),
        AppUserRole: this.fb.control('Admin', [Validators.required]),
        EmailAddress: this.fb.control('', [Validators.required, Validators.email]),
        OrganizationIds: this.fb.control('', [Validators.required])
      })
    });

  }


  addNewUser() {
    this.isCreatingNewUser = true;
    this.isLoading = true;
    const form = this.form.value.addForm;
    let orgIds = [form.OrganizationIds.id];
    form.OrganizationIds = orgIds;

    this.appUserPermissions = this.appUserPermissions.filter((permission, index) => {
      return permission.read;
    });

    form.AppUserPermissions = this.appUserPermissions;
    form.AppUserSupplyChainPermissions = [];

    console.log('%c AppUserForm: ', 'background: #41ff6b; color: #ff4700;', this.form);

    if (this.form.valid) {
      this.appUserService.addUser(form).subscribe(res => {
        if (res === null || !res) {
          console.log('%c add user POST success but server responsed with : ', 'background: #ff0000; color: #ffffff', res);
        }
        console.log('%c add user POST successful: ', 'background: #41ff6b; color: #ff4700;', res);

        this.isLoading = false;

        setTimeout(() => {
          this.dialogRef.close();
          this.router.navigateByUrl('/v2/settings');
        }, 300);

        this._snackBar.open('Success! User created successfully.', null, { duration: 3000 });
      }, error => {
        console.log('%c error - add user POST failed: ', 'background: #ff0000; color: #ffffff', error);
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.alive = false;
  }
}

class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    }
  }
}