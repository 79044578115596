import { Component, OnInit } from "@angular/core";
import { Observable, of, Subscription } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';
import { routerTransition } from "@app/shared/utils/animations";
import { BextAuthenticationService } from '@app/core/services';
import { AppUserService } from "@app/core/services/backend/app-user.service";
import { OrganizationService } from "@app/core/services/backend/organization.service";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styles: [`header { max-width:1665px !important; }`],
  animations: [routerTransition]
})

export class MainLayoutComponent implements OnInit {
  private alive = true;
  appUser: any;
  msalUserObj: any;
  ownerOrganization: any;

  constructor(
    private authService: BextAuthenticationService,
    private appUserService: AppUserService,
    private organizationService: OrganizationService
  ) {
    
  }

  ngOnInit() {
    this.msalUserObj = this.authService.getUser();
    this.authService.getUserOrganization(this.msalUserObj)
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        res => {
          this.authService.setAppUser({
            username: this.msalUserObj.idToken.emails[0],
            email: this.msalUserObj.idToken.emails[0],
            name: this.msalUserObj.name,
            appUserId: this.msalUserObj.idToken.oid,
            organizationName: res.OrganizationName,
            organization: res,
            loggedIn: true,
            sessionExpired: false
          });
          return;
        },
        error => { console.log('error GET org and set app user: ', error) },
        () => {
          
        }
      );

    this.authService.AppUserState$
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
      res => {
        // console.log('%c appUser: ', 'background: #41ff6b; color: #ff4700;', res);
        this.appUser = res;
      }
    );
  }

  getState(outlet) {
    if(!outlet.activatedRoute) return;
    let ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.alive = false;
  }

}
