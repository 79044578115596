import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdgImageGalleryComponent } from './sdg-image-gallery.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SdgImageGalleryComponent],
  exports: [SdgImageGalleryComponent]
})
export class SdgImageGalleryModule { }
