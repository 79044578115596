import { Injectable } from '@angular/core';
import { environment } from '@env/environment';


@Injectable()
export class LocalStorageService {

  constructor(

  ) { }


  get(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  set(key, value){
    if (key && value) {
      localStorage.setItem(key, value);
      return this.get(key);
    }
    else {
      return undefined;
    }
  }

  remove(key){
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

}