import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbDummyAuthStrategy } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  StateService,
  LayoutService,
  BextAuthenticationHttpInterceptor,
  BextAuthenticationService,

} from './utils';
import { UserData } from './data/users';
import { UserService } from './mock/users.service';
import { RecyclingActivityData } from './data/recycling-activity';
import { RecyclingActivityService } from './mock/recycling-activity.service';
import { MockDataModule } from './mock/mock-data.module';
import { ElectricityData } from './data/electricity';
import { ElectricityService } from './mock/electricity.service';
import { BoutiquesListData } from './data/boutiques-list';
import { BoutiquesListService } from './mock/boutiques-list.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UpsService } from './mock/ups.service';
import { UpsData } from './data/ups';
import { SecurityCamerasService } from './mock/security-cameras.service';
import { SecurityCamerasData } from './data/security-cameras';
import { CapsulesService } from './utils/capsules.service';
import { MapDataService } from './mock/map-data.service';
import { CapsuleReportService } from './mock/capsule-report.service';
import { CapsuleReportData } from './data/capsule-report';

const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'socicon-github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'socicon-facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'socicon-twitter',
  },
];

const DATA_SERVICES = [
  { provide: HTTP_INTERCEPTORS, useClass: BextAuthenticationHttpInterceptor, multi: true },
  { provide: UserData, useClass: UserService },
  { provide: RecyclingActivityData, useClass: RecyclingActivityService },
  { provide: ElectricityData, useClass: ElectricityService },
  { provide: BoutiquesListData, useClass: BoutiquesListService },
  { provide: UpsData, useClass: UpsService },
  { provide: SecurityCamerasData, useClass: SecurityCamerasService },
  { provide: CapsulesService, useClass: CapsulesService },
  { provide: CapsuleReportData, useClass: CapsuleReportService },
  { provide: MapDataService, useClass: MapDataService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbDummyAuthStrategy.setup({
        name: 'email',
        delay: 3000,
      }),
    ],
    forms: {
      login: {
        socialLinks: socialLinks,
      },
      register: {
        socialLinks: socialLinks,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  // {
  //   provide: HTTP_INTERCEPTORS, useClass: BextAuthenticationHttpInterceptor, multi: true,
  // },
  // AuthGuard,
  BextAuthenticationService,
  AnalyticsService,
  LayoutService,
  StateService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class Core2Module {
  constructor(@Optional() @SkipSelf() parentModule: Core2Module) {
    throwIfAlreadyLoaded(parentModule, 'Core2Module');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: Core2Module,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
