

import {NgModule, ModuleWithProviders} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoginInfoComponent} from "./login-info/login-info.component";
import {LogoutComponent} from "./logout/logout.component";
import {AddUserComponent} from "@app/shared/user/add/add.component";
import {EditUserComponent} from "@app/shared/user/edit/edit.component";
import {AdminUserFormComponent} from "@app/shared/user/form/form.component";
import {AccordionModule} from "ngx-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "@app/shared/angular-material.module";
import {OverlayModule} from '@angular/cdk/overlay';

@NgModule({
  imports: [CommonModule, AccordionModule, FormsModule, ReactiveFormsModule, MaterialModule,OverlayModule],
  declarations: [LoginInfoComponent, LogoutComponent, AddUserComponent, EditUserComponent, AdminUserFormComponent],
  exports: [LoginInfoComponent, LogoutComponent, AddUserComponent, EditUserComponent, AdminUserFormComponent],
  entryComponents: [AddUserComponent]
})
export class UserModule{}
