import * as _ from 'lodash';
import { FieldConfig } from '@app/shared-v2/models/field-config.interface';
import { UiData, UiDataFieldsEntity } from '@app/core-v2/utils/transfer-utils.interface';
import { Validators } from '@angular/forms';

const TransformUiDataToDynamicFormField = (uiDataField: UiDataFieldsEntity) => {
  switch (uiDataField.type) {
    case 'text':
    case 'number':
    case 'email':
    case 'password':
      
      let inputRequired = uiDataField.validation.analysis.required || uiDataField.validation.create.required || uiDataField.validation.transfer.required;
      let newInputField: FieldConfig = {
        type: 'input',
        label: uiDataField.label,
        inputType: null,
        name: uiDataField.name
      };
      if (inputRequired && uiDataField.type === 'text') {
        newInputField.inputType = 'text';
        newInputField.validations = [
          { name: 'required', validator: Validators.required, message: newInputField.label + ' is required'},
          // this regex was not working 102819 did not allow spaces
          // { name: 'pattern', validator: Validators.pattern("^[a-zA-Z0-9\_\-\ \]+$"), message: "Accepts only alphanumerics, -, and _" }
          // this one was deployed and did not work on 112519 so went with the above
          // { name: 'pattern', validator: Validators.pattern("^\s+"), message: "Cannot have a space as the first character" }
          { name: 'pattern', validator: Validators.pattern("^[a-zA-Z]+[a-zA-Z0-9-_ \.]*[a-zA-Z0-9]$"), message: "Accepts only alphanumerics, period, space, - and _" }
        ]
      }
      else if (inputRequired && uiDataField.type === 'number') {
        newInputField.inputType = 'text';
        newInputField.validations = [
          { name: 'required', validator: Validators.required, message: newInputField.label + ' is required' },
          { name: 'pattern', validator: Validators.pattern("^[0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)?$"), message: "Accepts only formatted number (1,000 or 1,000.0 or 1,000,25 or 0.25)" }
        ]
      }
      else if (inputRequired) {
        newInputField.inputType = uiDataField.type;
        newInputField.validations = [
          { name: 'required', validator: Validators.required, message: newInputField.label + ' is required' }
        ];
      }
      if (uiDataField.value) newInputField.value = uiDataField.value;
      return newInputField;

    case 'fileUpload':
      
      let newFileUploadField: FieldConfig = {
        type: 'fileupload',
        label: uiDataField.label,
        inputType: 'file',
        name: uiDataField.name
      };
      if (uiDataField.value) newFileUploadField.value = uiDataField.value;
      return newFileUploadField;
      
    case 'radio':

      // let radioRequired = uiDataField.validation.analysis.required || uiDataField.validation.create.required || uiDataField.validation.transfer.required;
      let newRadioField: FieldConfig = {
        type: 'radiobutton',
        label: uiDataField.label,
        name: uiDataField.name,
        options: uiDataField.options
      };
      // if (radioRequired) newRadioField.validations = [{ name: 'required', validator: Validators.required, message: newRadioField.label + ' is required' }]
      if (uiDataField.value) newRadioField.value = uiDataField.value;
      return newRadioField;
      
    case 'select':

      // let selectRequired = uiDataField.validation.analysis.required || uiDataField.validation.create.required || uiDataField.validation.transfer.required;
      let newSelectField: FieldConfig = {
        type: 'select',
        label: uiDataField.label,
        name: uiDataField.name,
        options: uiDataField.options
      };
      // if (selectRequired) newSelectField.validations = [{ name: 'required', validator: Validators.required, message: newSelectField.label + ' is required' }]
      if (uiDataField.value) newSelectField.value = uiDataField.value;
      return newSelectField;
      
    case 'date':
    case 'datetime-local':

      let dateRequired = uiDataField.validation.analysis.required || uiDataField.validation.create.required || uiDataField.validation.transfer.required;
      let newDateField: FieldConfig = {
        type: uiDataField.type,
        label: uiDataField.label,
        name: uiDataField.name
      };
      if (dateRequired) newDateField.validations = [
        { name: 'required', validator: Validators.required, message: newDateField.label + ' is required' },
      
      ]
      if (uiDataField.value) newDateField.value = uiDataField.value;
      return newDateField;
      
    case 'checkbox':

      // let checkboxRequired = uiDataField.validation.analysis.required || uiDataField.validation.create.required || uiDataField.validation.transfer.required;
      let newCheckboxField: FieldConfig = {
        type: 'checkbox',
        label: uiDataField.label,
        name: uiDataField.name
      };
      // if (checkboxRequired) newCheckboxField.validations = [{ name: 'required', validator: Validators.required, message: newCheckboxField.label + ' is required' }]
      if (uiDataField.value) newCheckboxField.value = uiDataField.value;
      return newCheckboxField;
      
    case 'button':
      
      let newButton: FieldConfig = {
        type: 'button',
        label: uiDataField.label || uiDataField.name
      };
      if (uiDataField.value) newCheckboxField.value = uiDataField.value;
      return newButton;
  
    default:
      console.log('%c COULD NOT MAP FIELD TYPE TO FORM: ', 'background: #ff0000; color: #ffffff;');
      break;
  }
  return uiDataField;
}

class uiDataUtils {
  convertUiDataToNewFormart(uiData: UiData[]) {
    let Sections = uiData[0].Sections;
    let sectionAccumulator = [];
    Sections.reduce((prev, curr) => {
      prev['sectionName'] = curr.SectionName;
      prev['fields'] = curr.Fields.filter(field => field.visible).map(TransformUiDataToDynamicFormField);
      if (prev['fields'].length) sectionAccumulator.push(prev);
      return ({});
    },{})
    return sectionAccumulator;
  }
}

export const UiDataUtils = new uiDataUtils();

class TransferLotEntity {
  constructor(
    public LotTransferMetaDataDto: {
      "TransferType": string;
      "ForwardTransfer": boolean;
      "FullTransfer": boolean;
      "CloseTransferredLots": boolean;
    },
    public TransferLotV1Dtos: [
      {
        "AbsorbedFromLot": {
          "LotMetaData": {
            "LotId": string;
            "SupplyChainId": string;
            "NodeId": string;
            "OwnerOrganizationId": string;
          }
        },
        "TransferredToLot": any | null;
      }
    ]
  ) {}
}

export const OneToOneTransferPayload = new TransferLotEntity(
  {
  "TransferType": "OneLotToOneNewLot",
  "ForwardTransfer": true,
  "FullTransfer": true,
  "CloseTransferredLots": true
},
  [
    {
      "AbsorbedFromLot": {
        "LotMetaData": {
          "LotId": "",
          "SupplyChainId": "",
          "NodeId": "",
          "OwnerOrganizationId": ""
        }
      },
      "TransferredToLot": null
    }
  ]
);

class TransferExistingLotEntity {
  
  LotTransferMetaDataDto;
  TransferLotV1Dtos;

  constructor(
    LotTransferMetaDataDto: {
      "TransferType": string | null;
      "ForwardTransfer": boolean | null;
      "FullTransfer": boolean | null;
      "CloseTransferredLots": boolean | null;
    },
    TransferLotV1Dtos: any[]
  ) {
    this.LotTransferMetaDataDto = LotTransferMetaDataDto;
    this.TransferLotV1Dtos = TransferLotV1Dtos;
  }

  addTransferLot(lot, node?, absorbedFromLot?) {
      console.log(`%c addTrnsferLot class method - argument: `, 'background: #fae552; color: #323232;', arguments);
    let transferLot = {
      "LotMetaData": {
        "LotName": "",
        "LotType": "",
        "LotDetailType": "",
        "NodeId": "",
        "SupplyChainId": "",
        "OrganizationId": "",
        "LotBeginDateTime": "",
        "LotEndDateTime": "",
        "LotStartWeight": {
          "AttributeTypeId": 0,
          "Type": "",
          "Label": "",
          "Key": "",
          "Default": false,
          "Name": "",
          "Method": "",
          "Group": "",
          "Unit": "",
          "Measure": "",
          "StartMeasureTime": "",
          "EndMeasureTime": "",
          "MeasureTime": "",
          "StartMeasureTimeDt": "",
          "EndMeasureTimeDt": "",
          "MeasureTimeDt": ""
        },
        "LotEndWeight": {
          "AttributeTypeId": 0,
          "Type": "",
          "Label": "",
          "Key": "",
          "Default": false,
          "Name": "",
          "Method": "",
          "Group": "",
          "Unit": "",
          "Measure": "",
          "StartMeasureTime": "",
          "EndMeasureTime": "",
          "MeasureTime": "",
          "StartMeasureTimeDt": "",
          "EndMeasureTimeDt": "",
          "MeasureTimeDt": ""
        },
        "QualityScore": {
          "AttributeTypeId": 0,
          "Type": "",
          "Label": "",
          "Key": "",
          "Default": false,
          "Name": "",
          "Method": "",
          "Group": "",
          "Unit": "",
          "Measure": "",
          "StartMeasureTime": "",
          "EndMeasureTime": "",
          "MeasureTime": "",
          "StartMeasureTimeDt": "",
          "EndMeasureTimeDt": "",
          "MeasureTimeDt": ""
        },
        "Tier": ""
      },
      "LotDatas": [],
      "Notes": ""
    };
    if (!this.TransferLotV1Dtos.length && node && absorbedFromLot) {
      let firstTransferLot = {
        "AbsorbedFromLot": {
          "LotMetaData": {
            "LotId": absorbedFromLot.id,
            "SupplyChainId": "",
            "NodeId": node.id,
            "OwnerOrganizationId": node.OwnerOrganizationId
          }
        },
        "TransferredToLot": transferLot
      };
      this.TransferLotV1Dtos.push(firstTransferLot);
    } else {
      this.TransferLotV1Dtos.push(transferLot);
    }
  };

  removeTransferLot(lot) {
    let lotMatch = (transferLotDto) => lot.LotName === transferLotDto.TransferredToLot.LotMetaData.LotName;
    let transferLotDto = this.TransferLotV1Dtos.find(lotMatch);
    console.log('remove transfer lot: ', transferLotDto);
  }

}


export const OneExistingToManyExistingTransferPayload = new TransferExistingLotEntity(
  {
  "TransferType": "OneExistingToManyExistingTransferPayload",
  "ForwardTransfer": true,
  "FullTransfer": true,
  "CloseTransferredLots": true
  },
  []
);